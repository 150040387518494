import React, { Component } from "react";
import logo_social from "../../../Assets/imgReceipt/logo_social.png";

export default class FormatReceiptPornor extends Component {
  constructor(props) {
    super(props);
    this.setState({})
  }
  render() {
    return (
      <div
        style={{
          fontFamily: "Kanit",
          fontSize: "13px",
          position: "relative",
          marginTop: "5px",
          marginBottom: "5px",
          marginLeft: "10px",
          marginRight: "10px",
        }}
        className="page-break"
      >
        <center className="text-center" style={{ fontSize: "15px", marginTop: "10px" }}>
          <div style={{ marginTop: "50px" }}>
            <b>ใบเสร็จรับเงิน&nbsp;{this.props.supplies.receiptCode}</b>
          </div>
        </center>
        <div>
          วันที่ {this.props.supplies.date}/{this.props.supplies.month}/{this.props.supplies.year + 543}&nbsp;เวลา&nbsp;
          {this.props.supplies.time}&nbsp;น.
        </div>
        <div>
          <b>ผู้ส่งชื่อ :&nbsp;&nbsp;{this.props.supplies.sender_name}</b>
        </div>
        <hr />
        {this.props.supplies.printLebel.map((value, index) => (
          <div key={index}>
            <div>
              <b>
                {index + 1}. ผู้รับชื่อ :&nbsp;&nbsp;{value.receiver_name}
              </b>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <div>
                - น้ำหนัก :&nbsp;&nbsp;{parseInt(value.weight).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                &nbsp;&nbsp;กิโลกรัม
              </div>
              <div>
                ราคา&nbsp;&nbsp;{parseInt(value.shipment_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </div>
            </div>
            {parseInt(value.cod_cost) !== 0 ? (
              <div>
                <b>COD : {parseInt(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })} บาท</b>
              </div>
            ) : (
              <div></div>
            )}
            <div>
              <b>เลขที่พัสดุฯ/ไปรษณีย์ : {value.awb_code}</b>
            </div>
          </div>
        ))}
        <hr />
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <div>ค่าขนส่งไปรษณีย์ :</div>
          <div>{parseInt(this.props.supplies.shipmentPrice).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
        </div>
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <div>ค่าบริการอื่นๆ :</div>
          <div>{parseInt(this.props.supplies.serviceOrter).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
        </div>
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <div>
            <b>รวมเงิน</b>
          </div>
          <div><b>{parseInt(this.props.supplies.freightPrice).toLocaleString(undefined, { minimumFractionDigits: 2 })}</b></div>
        </div>
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <div>รับเงิน</div>
          <div>{parseInt(this.props.supplies.getMoney).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
        </div>
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <div>ทอนเงิน</div>
          <div>{parseInt(this.props.supplies.changePrice).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
        </div>
        <hr />
        <center>
          <div>ติดต่อเรา Tel. 1423</div>
          <div>Email : service@porlor.co.th</div>
        </center>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            marginTop: "10px",
          }}
        >
          <div></div>
          <div></div>
          <div>
            <center>
              <img alt="" src={logo_social} width="80" height="50" className="d-inline-block align-top" />
            </center>
          </div>
          <div>
            <div style={{ fontSize: "20px", marginTop: "10px" }}>@Porlor</div>
          </div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}
