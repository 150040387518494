import HubBodyLayout from '../../../Layouts/Hub/components/hub-body-layout';
import GenericTable from '../Shared/GenericTable';
import { HubPresentation } from '../Shared/HubPresentation';
import HubPageTitle from '../Shared/HubPageTitle';
import { HubTableTitle } from '../Shared/HubTableTitle';
import EmployeesList from '../Shared/EmployeesList';
import { useHistory } from 'react-router';
import {useTranslation} from "react-i18next";
import useGetHubZip from "../../mis/HubUsers/hooks/use-get-hub-zip";
import {Spinner} from "react-bootstrap";
import React, {useEffect} from "react";

const metadata = [
  {
    text: 'Zip',
    width: '100%',
    render: (data) => data.zip,
  }
];

const employeesMetaData = [
  {
    text: 'name',
    width: '100%',
    render: (data) => <span className='w-100 text-center d-block'>{data.name}</span>,
  }
];

const HubGeneral = () => {
  const {t} = useTranslation();
  const {data,loading,errorMessage, fetch} = useGetHubZip();
  useEffect(() => {
    void fetch(localStorage.getItem('id'))
  }, []);

  const leftContent = (
    <>
      <HubPresentation />
      <HubPageTitle className="">{t('hub.general.hub_range')}</HubPageTitle>
      <HubTableTitle className="text-center w-100 pt-1 pb-1" style={{color: 'var(--dark-gray)'}}>{t('hub.general.postal_codes')} : </HubTableTitle>
      {data && <GenericTable
        metadata={metadata}
        data={data}
        keyExtractor={(item) => item.id}
      />}
    </>
  );


  const rightContent = (
      <>
        {data && !loading && <EmployeesList hubZip={data} />}
      </>
  );
  return (
    <>
      {!loading && <HubBodyLayout leftContent={leftContent} rightContent={rightContent}/>}
      {loading && (
          <div className="spinner-global-content">
            <Spinner variant="success" animation="border" />
          </div>
      )}
    </>
  )
}
export default HubGeneral;
