import React from "react";
import Line from "../../../Assets/images/contactus/line_th.png";
import Qrcode from "../../../Assets/images/contactus/qrcode.png";
import Icon from "../../../Assets/icons/icons";
import { connect } from "react-redux";

function ContactDetail(props) {
  const { lang } = props;
  return lang.language === "th" ? <ContactDetailTH /> : <ContactDetailEN />;
}

const mapStateToProps = (state) => ({
  lang: state.LanguageAction,
});

export default connect(mapStateToProps)(ContactDetail);

function ContactDetailTH() {
  return (
    <div className="contactus-detail">
      <div>
        <h5>
          <Icon icon="home" size={32} color="#e68a00" />
          บริษัท ปอลอ เอ็กซ์เพรส จำกัด
        </h5>
        <p>
          เลขที่ 99/153 หมู่ที่ 15 แขวงสะพานสูง <br />
          เขตสะพานสูง กรุงเทพฯ 10250
        </p>
      </div>
      <div>
        <h5>
          <Icon icon="clock" size={32} color="#e68a00" />
          เวลาทำการ
        </h5>
        <p>จันทร์–เสาร์: 8.30-18.00 น.</p>
      </div>
      <div>
        <h5>
          <Icon icon="telephone" size={32} color="#e68a00" />
          โทรศัพท์
        </h5>
        <p>สายด่วน 1423</p>
      </div>
      <div>
        <h5>
          <Icon icon="email" size={32} color="#e68a00" />
          อีเมล
        </h5>
        <p>
          service@porlor.co.th, <br />
          express@porlor.co.th, <br />
          023682111@porlor.co.th
        </p>
      </div>

      <div>
        <h5>
          <Icon icon="facebook" size={32} color="#e68a00" />
          Facebook
        </h5>
        <p>
          <a
            href="https://www.facebook.com/ปอลอ-เอ็กเพรส-Porlor-express-101558221518814"
            rel="noopener noreferrer"
            target="_blank"
          >
            ปอลอ เอ็กเพรส porlor express
          </a>
        </p>
      </div>
      <div>
        <img src={Line} alt="line" />
        <br />
        <img src={Qrcode} alt="qrcode" />
      </div>
    </div>
  );
}

function ContactDetailEN() {
  return (
    <div className="contactus-detail">
      <div>
        <h5>
          <Icon icon="home" size={32} color="#e68a00" />
          Porlor Express Limited.
        </h5>
        <p>
          99/153 Moo 15 High Bridge Road, <br />
          High Bridge area, Bangkok 10250
        </p>
      </div>
      <div>
        <h5>
          <Icon icon="clock" size={32} color="#e68a00" />
          Office Hour
        </h5>
        <p>Mon - Sat 8.30-17.30 am</p>
      </div>
      <div>
        <h5>
          <Icon icon="telephone" size={32} color="#e68a00" />
          Tel.
        </h5>
        <p>1423</p>
      </div>
      <div>
        <h5>
          <Icon icon="email" size={32} color="#e68a00" />
          E-mail
        </h5>
        <p>
          service@porlor.co.th, <br />
          express@porlor.co.th, <br />
          023682111@porlor.co.th
        </p>
      </div>

      <div>
        <h5>
          <Icon icon="facebook" size={32} color="#e68a00" />
          Facebook
        </h5>
        <p>
          <a
            href="https://www.facebook.com/ปอลอ-เอ็กเพรส-Porlor-express-101558221518814"
            rel="noopener noreferrer"
            target="_blank"
          >
            ปอลอ เอ็กเพรส porlor express
          </a>
        </p>
      </div>
      <div>
        <img src={Line} alt="line" />
        <br />
        <img src={Qrcode} alt="qrcode" />
      </div>
    </div>
  );
}
