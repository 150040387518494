import React, {useEffect, useMemo, useState} from 'react'
import HubBodyLayout from '../../../Layouts/Hub/components/hub-body-layout';
import GenericTable from '../Shared/GenericTable';
import { HubPresentation } from '../Shared/HubPresentation';
import HubPageTitle from '../Shared/HubPageTitle';
import { HubTableTitle } from '../Shared/HubTableTitle';
import EmployeesList from '../Shared/EmployeesList';
import {useHistory, useParams} from 'react-router';
import useGetHubZip from "../../mis/HubUsers/hooks/use-get-hub-zip";
import {Spinner} from "react-bootstrap";
import useHubAssignmentManagement from "./hooks/useHubAssignmentManagement";
import {format, parse} from "date-fns";

const HubGeneralAssignment = () => {

  const history = useHistory();
    const params = useParams();
    const {data: rightData,loading,errorMessage, fetch} = useGetHubZip();
    const {employeesZip: leftData, loading: leftDataLoading, fetchEmployeesZip, deleteEmployeeZip,addEmployeeZip} = useHubAssignmentManagement();
    useEffect(() => {
        void fetch(localStorage.getItem('id'))
    }, []);

    useEffect(() => {
        if(params.id){
            void fetchEmployeesZip(params.id);
        }
    }, [params]);


    const freeSpace = useMemo(() => {
        if(!rightData){
            return undefined;
        }
        const searchResult =  (rightData || []).filter((row) => !(leftData || []).find((leftRow) => leftRow.id_zipcode_hub === row.id));
        return searchResult;
    }, [rightData, leftData]);

    
    const leftMetaData = [
        {
            text: 'Zip',
            width: '42%',
            render: (data) => data.zip,
        },
        {
            text: 'ได้รับมอบหมายตั้งแต่',
            width: '42%',
            render: (data) => format(parse(data.created_at, 'yyyy-MM-dd HH:mm:ss',  new Date()), 'dd/MM/yyyy'),
        },
        {
            text: ' ',
            width: '15%',
            render: (data) => (
                <>
                    <button onClick={() => {
                        void deleteEmployeeZip(params.id, data.id);
                    }} className={'btn btn-primary w-100'}>
                        <span style={{fontSize: '30px', lineHeight: '30px'}}> - </span>
                    </button>
                </>
            ),
        }
    ];
    
    const rightMetaData = [
        {
            text: 'Zip',
            width: '85%',
            render: (data) => data.zip,
        },
        {
            text: ' ',
            width: '15%',
            render: (data) => (
                <>
                    <button onClick={() => {
                        void addEmployeeZip(params.id,data.id);
                    }} className={'btn btn-primary w-100'}>
                       <span style={{fontSize: '30px', lineHeight: '30px'}}> + </span>
                    </button>
                </>
            ),
        }
    ];

  const leftContent = (
    <>
      <HubPresentation />
      <HubPageTitle className="">ช่วงของฮับ</HubPageTitle>
          <div className='row'>
                  <div className='col-12 col-md-6'>
                    <HubTableTitle className="text-left w-100 pt-1 pb-1" style={{color: 'var(--dark-gray)'}}>พื้นที่ที่ได้รับมอบหมาย : </HubTableTitle>
                    {leftData && <GenericTable
                        metadata={leftMetaData}
                        data={leftData}
                        keyExtractor={(item) => item.id}
                    />}
                      {leftDataLoading && (
                          <div className="spinner-global-content">
                            <Spinner variant="success" animation="border" />
                          </div>)
                      }
                  </div>
                  <div className='col-12 col-md-6'>
                    <HubTableTitle className="text-left w-100 pt-1 pb-1" style={{color: 'var(--dark-gray)'}}>พื้นที่ว่าง : </HubTableTitle>
                      {freeSpace?.length > 0 && <GenericTable
                        metadata={rightMetaData}
                        data={freeSpace}
                        keyExtractor={(item) => item.id}
                      />}
                      {loading && (
                          <div className="spinner-global-content">
                              <Spinner variant="success" animation="border" />
                          </div>)
                      }
                  </div>
        </div>
    </>
  );
  const rightContent = (
    <>
        <EmployeesList />
    </>
  );
    return (
      <>
        <button className='btn btn-primary m-2' onClick={() => history.goBack()}>{"<- Go back"}</button>
              <HubBodyLayout leftContent={leftContent} rightContent={rightContent}/>
      </>
  )
}
export default HubGeneralAssignment;
