import React from 'react'

export const HubBodyLayout = ({leftContent, rightContent}) => {
  const onlyOneColumn = !!leftContent ^ !!rightContent;
  return (
      <div className='row'>
          {leftContent && <div className={`col-12 col-md-${onlyOneColumn ? 12 : 8}`}>
                <div className='container h-100 mb-2 mt-2 shadow-sm overflow-auto'>
                {leftContent}
                </div>
          </div>}
          {rightContent && <div className={`col-12 col-md-${onlyOneColumn ? 12 : 4} align-self-stretch`}>
            <div style={{background: 'var(--sky-blue)', paddingTop: '10px'}}  className='container h-100 mb-2 mt-2 shadow-sm overflow-auto'>
                    {rightContent}
            </div>
          </div>}
      </div>
  )
}

export default HubBodyLayout;