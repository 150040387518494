import React from "react";
import Nav from "react-bootstrap/Nav";
import { useHistory } from "react-router";
import { NavDropdown } from "react-bootstrap";

export default function OperatorMenu() {
  const history = useHistory();

  return (
    <>
      <Nav.Item>
        <Nav.Link
          eventKey="/home"
          onClick={(e) => {
            history.push("MGBarcodes");
          }}
        >
          จัดการพัสดุ
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
      <NavDropdown title="ใส่ที่อยู่ผู้รับ" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={(e) => {
            history.push("ParcelCollect");
          }}
        >
          รับพัสดุฯ
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("CollectList");
            }}
          >
            รายการรับพัสดุฯ
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("importExcelOP");
            }}
          >
            อัพโหลดข้อมูลพัสดุ
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item>
    </>
  );
}
