import React from "react";

export default function PublicLayout(props) {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {props.children}
    </div>
  );
}
