import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { address } from "../../../../Data/addressData.json";
import { saveShopsService } from "../../../../Service/service.Shops";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { bank } from "../../../../Data/bank";
import { uploadSignatureShop } from "../../../../Service/service.Shops";

export default function FormShop() {
  const history = useHistory();
  const [id, setId] = useState("");
  const [typeShop, setTypeShop] = useState("");
  const [shopCode, setShopCode] = useState("");
  const [shopName, setShopName] = useState("");
  const [shopEngName, setShopEngName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [numberId, setNumberId] = useState("");
  const [addres, setAddress] = useState("");
  const [subdistrict, setSubdistrict] = useState("");
  const [district, setDistrict] = useState("");
  const [province, setProvince] = useState("");
  const [postCode, setPostCode] = useState("");
  const [addresEng, setAddressEng] = useState("");
  const [subdistrictEng, setSubdistrictEng] = useState("");
  const [districtEng, setDistrictEng] = useState("");
  const [provinceEng, setProvinceEng] = useState("");
  const [postCodeEng, setPostCodeEng] = useState("");
  const [password, setPassword] = useState("");
  const [checkpassword, setCheckPassword] = useState("");
  const [status, setStatus] = useState("1");
  const [showShipmentCost, setShowShipmentCost] = useState(false);
  const [showAddressReceiver, setShowAddressReceiver] = useState(false);
  const [showConote, setShowConote] = useState(false);
  const [requireDimension, setRequireDimension] = useState(true);
  const [showAds, setShowAds] = useState(true);
  const [showReturn, setShowReturn] = useState(true);
  const [showImage, setShowImage] = useState(true);
  const [showPickUp, setShowPickUp] = useState(true);
  const [generateConote, setGenerateConote] = useState(false);
  const [payment_type,setPaymentType] = useState('');
  const [payer,setPayer] = useState('');
  //////เช็ค Error Messege//////
  const [errorTypeShop, setErrorTypeShop] = useState(1);
  const [errorShopCode, setErrorShopCode] = useState(1);
  const [errorShopName, setErrorShopName] = useState(1);
  const [errorShopEngName, setErrorShopEngName] = useState(1);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(1);
  const [errorNumberId, setErrorNumberId] = useState(1);
  const [errorAddres, setErrorAddres] = useState(1);
  const [errorAddresEng, setErrorAddresEng] = useState(1);
  const [errorSubdistrict, setErrorSubdistrict] = useState(1);
  const [errorDistrict, setErrorDistrict] = useState(1);
  const [errorProvince, setErrorProvince] = useState(1);
  const [errorPostCode, setErrorPostCode] = useState(1);
  const [errorPassword, setErrorPassword] = useState(1);
  const [errorCheckPassword, setErrorCheckPassword] = useState(1);
  const [errorStatus, setErrorStatus] = useState(1);
  const [errorPayer, setErrorPayer] = useState(1);
  const [fileSignature, setFileSignature] = useState("");
  const [bank_id, setBank_id] = useState("");
  const [bookbank_code, setBookbank_code] = useState("");
  const [bookbank_name, setBookbank_name] = useState("");
  const [rate, setRate] = useState("");
  const [rate2, setRate2] = useState("");

  ///////////////////////////////
  const [showAll, setShowAll] = useState(10);
  const [search, setSearch] = useState("");
  const [loading, Loading] = useState(0);
  const param = useLocation();
  const [showPass, setShowPass] = useState(1);
  const [userErorr, setUserErorr] = useState("");
  const formatInput = new RegExp("^[a-zA-Z0-9]+$");
  const formatInputAlphaNum = new RegExp("^[a-zA-Z0-9#//-]+$");
  const formatInputNumber = new RegExp("^[0-9]+$");
console.log(address)
  useEffect(() => {
    if (param.state !== undefined) {
      console.log("param", param);
      setId(param.state.value.id);
      setTypeShop(param.state.value.shop_type);
      setShopCode(param.state.value.shop_code);
      setShopName(param.state.value.shop_name);
      setShopEngName(param.state.value.shop_name_eng);
      setPhoneNumber(param.state.value.phone);
      setNumberId(param.state.value.tax_id);
      setAddress(param.state.value.address);
      setSubdistrict(param.state.value.district);
      setDistrict(param.state.value.amphur_code);
      setProvince(param.state.value.province_code);
      setPostCode(param.state.value.zipcode);
      setAddressEng(param.state.value.address_eng);
      setSubdistrictEng(param.state.value.district_name_eng);
      setDistrictEng(param.state.value.amphur_name_eng);
      setProvinceEng(param.state.value.province_name_eng);
      setPostCodeEng(param.state.value.zipcode);
      setStatus(param.state.value.is_used);
      setPassword(param.state.value.pass);
      setCheckPassword(param.state.value.pass);
      setShowShipmentCost(
        param.state.value.show_shipment_cost === "1" ? true : false
      );
      setShowAddressReceiver(
        param.state.value.show_address_receiver === "1" ? true : false
      );
      setShowConote(param.state.value.show_conote === "1" ? true : false);
      setRequireDimension(param.state.value.require_dimension === "1" ? true : false);
      setShowAds(param.state.value.show_ads === "1" ? true : false);
      setShowReturn(param.state.value.show_return === "1" ? true : false);
      setShowImage(param.state.value.show_image === "1" ? true : false);
      setShowPickUp(param.state.value.show_pickup === "1" ? true : false);
      setPayer(param.state.value.payer);
      setPaymentType(param.state.value.payment_type);
      setBank_id(param.state.value.bank_id);
      setBookbank_code(param.state.value.bookbank_code);
      setBookbank_name(param.state.value.bookbank_name);
      setRate(param.state.value.rate);
      setRate2(param.state.value.rate2);
      setGenerateConote(param.state.value.show_generate_ref_no==="1"? true : false);
    }
  }, []);

  async function uploadSignature(value){
    const resultUpdate = await uploadSignatureShop(value);
    if (resultUpdate.res[0].status == 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    } else {
      swal({
        title: "อัพเดทไม่สำเร็จ",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
    // alert(JSON.stringify(resultUpdate));
  }

  async function SaveData(value) {
    // console.log("save data:" + JSON.stringify(value));
    Loading(1);
    const res = await saveShopsService(value);
    console.log("save data:", res);
    if (res.status === 1) {
      Loading(0);
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      history.push("/mis/Shops");
    } else if (res.status === 2) {
      swal("แจ้งเตือน", res.message, "warning");
      Loading(0);
    } else {
      swal("แจ้งเตือน", res.message, "warning");
      Loading(0);
    }
  }

  const validatePassword = (password) => {
    if(id && password === ""){
        setErrorCheckPassword(1);
        return true;
    }
    if(!password){
      setErrorPassword(0);
      return false;
    }
    if(!formatInput.test(password)){
      setErrorPassword(5);
      return false;
    }
    if(password.length < 6){
      setErrorPassword(2);
      return false;
    }
    setErrorPassword(1);
    if(checkpassword !== password){
      setErrorCheckPassword(0);
      return false;
    }
    setErrorCheckPassword(1);
    return true;
  }

  const handleSubmit = () => {
    ///เริ่ม///เช็ค Error Messege//////
    typeShop === "" ? setErrorTypeShop(0) : setErrorTypeShop(1);
    shopCode === ""
      ? setErrorShopCode(0)
      : formatInputAlphaNum.test(shopCode) === false
      ? setErrorShopCode(5)
      : shopCode.length < 4
      ? setErrorShopCode(2)
      : shopCode.length > 10
      ? setErrorShopCode(3)
      : setErrorShopCode(1);
    shopName === ""
      ? setErrorShopName(0)
      : shopName.length < 4
      ? setErrorShopName(2)
      : shopName.length > 50
      ? setErrorShopName(3)
      : setErrorShopName(1);
    shopEngName === ""
      ? setErrorShopEngName(0)
      : shopEngName.length < 4
      ? setErrorShopEngName(2)
      : shopEngName.length > 50
      ? setErrorShopEngName(3)
      : setErrorShopEngName(1);
    phoneNumber === ""
      ? setErrorPhoneNumber(0)
      : formatInputNumber.test(phoneNumber) === false
      ? setErrorPhoneNumber(5)
      : phoneNumber.length < 9 || phoneNumber.length > 10
      ? setErrorPhoneNumber(2)
      : setErrorPhoneNumber(1);
    numberId === ""
      ? setErrorNumberId(0)
      : formatInputNumber.test(numberId) === false
      ? setErrorNumberId(5)
      : numberId.length !== 13
      ? setErrorNumberId(2)
      : setErrorNumberId(1);
    addres === "" ? setErrorAddres(0) : setErrorAddres(1);
    addresEng === "" ? setErrorAddresEng(0) : setErrorAddresEng(1);
    subdistrict === "" ? setErrorSubdistrict(0) : setErrorSubdistrict(1);
    district === "" ? setErrorDistrict(0) : setErrorDistrict(1);
    province === "" ? setErrorProvince(0) : setErrorProvince(1);
    postCode === "" ? setErrorPostCode(0) : setErrorPostCode(1);
    const validPassword = validatePassword(password);
    status === "" ? setErrorStatus(0) : setErrorStatus(1);
    ///////////////////////////////
    // payer === "" ? setErrorPayer(0) : setErrorPayer(1);
    // payment_type === "" ? setErrorPaymentType(0) : setErrorPaymentType(1);
    /////////////////////////////
    if (
      typeShop !== "" &&
      shopCode !== "" &&
      shopCode.length >= 4 &&
      shopCode.length <= 10 &&
      formatInputAlphaNum.test(shopCode) === true &&
      shopName !== "" &&
      shopName.length >= 4 &&
      shopName.length <= 50 &&
      shopEngName !== "" &&
      shopEngName.length >= 4 &&
      shopEngName.length <= 50 &&
      phoneNumber !== "" &&
      phoneNumber.length >= 9 &&
      phoneNumber.length <= 10 &&
      formatInput.test(phoneNumber) === true &&
      numberId !== "" &&
      numberId.length === 13 &&
      formatInput.test(numberId) === true &&
      addres !== "" &&
      addresEng !== "" &&
      subdistrict !== "" &&
      district !== "" &&
      province !== "" &&
      postCode !== "" &&
      validPassword &&
      status !== "" 
    ){
      const formData = new FormData();
      formData.append('file',fileSignature);
      formData.append('shop_code',shopCode);

      if(fileSignature!==''){
        uploadSignature(
          formData
        ).then(()=>{
          SaveData({
            id: id !== "" ? id : "",
            shop_type: typeShop !== "" ? typeShop : "",
            shop_code: shopCode !== "" ? shopCode : "",
            shop_name: shopName !== "" ? shopName : "",
            shop_name_eng: shopEngName !== "" ? shopEngName : "",
            phone: phoneNumber !== "" ? phoneNumber : "",
            tax_id: numberId !== "" ? numberId : "",
            address: addres !== "" ? addres : "",
            subdistrict: subdistrict !== "" ? subdistrict : "",
            district: district !== "" ? district : "",
            province: province !== "" ? province : "",
            zipcode: postCode !== "" ? postCode : "",
            address_eng: addresEng !== "" ? addresEng : "",
            district_name_eng: subdistrictEng !== "" ? subdistrictEng : "",
            amphur_name_eng: districtEng !== "" ? districtEng : "",
            province_name_eng: provinceEng !== "" ? provinceEng : "",
            password: password !== "" ? password : "",
            show_shipment_cost: showShipmentCost !== "" ? showShipmentCost : "",
            show_generate_ref_no: generateConote !== "" ? generateConote : "",
            show_address_receiver:
              showAddressReceiver !== "" ? showAddressReceiver : "",
            show_conote: showConote,
            require_dimension: requireDimension,
            show_ads: showAds,
            show_return: showReturn,
            show_image: showImage,
            show_pickup: showPickUp,
            is_used: status !== "" ? status : "",
            payer: payer !=="" ? payer:"",
            payment_type: payment_type !=="" ? payment_type:"",
            bank_id: bank_id !=="" ? bank_id:"",
            bookbank_code: bookbank_code !=="" ? bookbank_code:"",
            bookbank_name: bookbank_name !=="" ? bookbank_name:"",
            rate: rate !=="" ? rate:"",
            rate2: rate2 !=="" ? rate2:""
          });
        });
      }
      else{
        SaveData({
          id: id !== "" ? id : "",
          shop_type: typeShop !== "" ? typeShop : "",
          shop_code: shopCode !== "" ? shopCode : "",
          shop_name: shopName !== "" ? shopName : "",
          shop_name_eng: shopEngName !== "" ? shopEngName : "",
          phone: phoneNumber !== "" ? phoneNumber : "",
          tax_id: numberId !== "" ? numberId : "",
          address: addres !== "" ? addres : "",
          subdistrict: subdistrict !== "" ? subdistrict : "",
          district: district !== "" ? district : "",
          province: province !== "" ? province : "",
          zipcode: postCode !== "" ? postCode : "",
          address_eng: addresEng !== "" ? addresEng : "",
          district_name_eng: subdistrictEng !== "" ? subdistrictEng : "",
          amphur_name_eng: districtEng !== "" ? districtEng : "",
          province_name_eng: provinceEng !== "" ? provinceEng : "",
          password: password !== "" ? password : "",
          show_shipment_cost: showShipmentCost !== "" ? showShipmentCost : "",
          show_generate_ref_no: generateConote !== "" ? generateConote : "",
          show_address_receiver:
            showAddressReceiver !== "" ? showAddressReceiver : "",
          show_conote: showConote,
          require_dimension: requireDimension,
          show_ads: showAds,
          show_return: showReturn,
          show_image: showImage,
          show_pickup: showPickUp,
          is_used: status !== "" ? status : "",
          payer: payer !=="" ? payer:"",
          payment_type: payment_type !=="" ? payment_type:"",
          bank_id: bank_id !=="" ? bank_id:"",
          bookbank_code: bookbank_code !=="" ? bookbank_code:"",
          bookbank_name: bookbank_name !=="" ? bookbank_name:"",
          rate: rate !=="" ? rate:"",
          rate2: rate2 !=="" ? rate2:""
        });
      }


    }
  };

  function clear() {
    setTypeShop("");
    setShopName("");
    setShopEngName("");
    setPhoneNumber("");
    setNumberId("");
    setAddress("");
    setSubdistrict("");
    setDistrict("");
    setProvince("");
    setPostCode("");
    setAddressEng("");
    setSubdistrictEng("");
    setDistrictEng("");
    setProvinceEng("");
    setPostCodeEng("");
    setPassword("");
    setCheckPassword("");
    setStatus("");
    setErrorTypeShop(1);
    setErrorShopCode(1);
    setErrorShopName(1);
    setErrorShopEngName(1);
    setErrorPhoneNumber(1);
    setErrorNumberId(1);
    setErrorAddres(1);
    setErrorAddresEng(1);
    setErrorSubdistrict(1);
    setErrorDistrict(1);
    setErrorProvince(1);
    setErrorPostCode(1);
    setErrorPassword(1);
    setErrorCheckPassword(1);
    setErrorStatus(1);
    setPayer("");
    setPaymentType("");
    if (id === "") {
      setShopCode("");
    }
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb" style={{ backgroundColor: "#ffffff" }}>
          <li className="breadcrumb-item">
            <buton
              type="button"
              className="btn-link"
              style={{ color: "#000000" }}
              onClick={(e) => {
                history.push("Shops");
              }}
            >
              ข้อมูลร้านค้า/ลูกค้า
            </buton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            จัดการข้อมูลร้านค้า/ลูกค้า
          </li>
        </ol>
      </nav>
      <>
        <div className="card">
          <div className="card-body">
            <div className="container-fluid mt-2 mb-2">
              <div className="text-center text-title-color">
                <h2>จัดการข้อมูลร้านค้า/ลูกค้า</h2>
              </div>
              <div>
                <b>ประเภท</b>
                <br />
                <div
                  style={
                    errorTypeShop === 0
                      ? { marginTop: "5px", color: "#f80000" }
                      : { marginTop: "5px" }
                  }
                >
                  <div className="form-check form-check-inline">
                    <input
                      checked={typeShop === "4" ? true : false}
                      style={{ width: "20px", height: "20px" }}
                      className="form-check-input"
                      type="radio"
                      name="inlineRadio2"
                      id="inlineRadio2"
                      value="4"
                      onChange={(e) => {
                        setTypeShop(e.target.value);
                      }}
                    />
                    <label className="form-check-label" for="inlineRadio1">
                      ลูกค้าเครดิต
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      checked={typeShop === "1" ? true : false}
                      style={{ width: "20px", height: "20px" }}
                      className="form-check-input"
                      type="radio"
                      name="inlineRadio3"
                      id="inlineRadio3"
                      value="1"
                      onChange={(e) => {
                        setTypeShop(e.target.value);
                      }}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      หน้าร้าน
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      checked={typeShop === "2" ? true : false}
                      style={{ width: "20px", height: "20px" }}
                      className="form-check-input"
                      type="radio"
                      name="inlineRadio4"
                      id="inlineRadio4"
                      value="2"
                      onChange={(e) => {
                        setTypeShop(e.target.value);
                      }}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      หน้าร้าน(ศูนย์กระจายสินค้า)
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      checked={typeShop === "3" ? true : false}
                      style={{ width: "20px", height: "20px" }}
                      className="form-check-input"
                      type="radio"
                      name="inlineRadio5"
                      id="inlineRadio5"
                      value="3"
                      onChange={(e) => {
                        setTypeShop(e.target.value);
                      }}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      แฟรนไซส์
                    </label>
                  </div>
                </div>
                {errorTypeShop === 0 ? (
                  <font color="red" size="2">
                    {" "}
                    กรุณาเลือกประเภท{" "}
                  </font>
                ) : (
                  ""
                )}
                <br />
                <div className="row">
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>รหัสร้านค้า</b>
                    <input
                      style={id !== "" ? { backgroundColor: "#ffff" } : {}}
                      readOnly={id !== "" ? true : false}
                      value={shopCode}
                      type="text"
                      className={
                        errorShopCode === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="รหัสร้านค้า"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setShopCode(e.target.value);
                      }}
                    />
                    {errorShopCode === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกรหัสร้านค้า{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorShopCode === 2 ? (
                      <font color="red" size="2">
                        {" "}
                        รหัสร้านค้าต้องมากกว่า 4 ตัว{" "}
                      </font>
                    ) : errorShopCode === 3 ? (
                      <font color="red" size="2">
                        {" "}
                        รหัสร้านค้าต้องน้อยกว่า 30 ตัว{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorShopCode === 4 ? (
                      <font color="red" size="2">
                        {" "}
                        {userErorr}{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorShopCode === 5 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกข้อมูลเป็น a-z, A-Z, 0-9 เท่านั้น{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-3 px-md-1 mt-1">
                    <b>ชื่อร้านค้าภาษาไทย</b>
                    <input
                      value={shopName}
                      type="text"
                      className={
                        errorShopName === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="ชื่อร้านค้าภาษาไทย"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setShopName(e.target.value);
                      }}
                    />
                    {errorShopName === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกชื่อร้านค้า{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorShopName === 2 ? (
                      <font color="red" size="2">
                        {" "}
                        ชื่อร้านค้าต้องมากกว่า 4 ตัว{" "}
                      </font>
                    ) : errorShopName === 3 ? (
                      <font color="red" size="2">
                        {" "}
                        ชื่อร้านค้าต้องน้อยกว่า 50 ตัว{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-3 px-md-1 mt-1">
                    <b>ชื่อร้านค้าภาษาอังกฤษ</b>
                    <input
                      value={shopEngName}
                      type="text"
                      className={
                        errorShopEngName === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="ชื่อร้านค้าภาษาอังกฤษ"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setShopEngName(e.target.value);
                      }}
                    />
                    {errorShopEngName === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกชื่อร้านค้า{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorShopEngName === 2 ? (
                      <font color="red" size="2">
                        {" "}
                        ชื่อร้านค้าต้องมากกว่า 4 ตัว{" "}
                      </font>
                    ) : errorShopEngName === 3 ? (
                      <font color="red" size="2">
                        {" "}
                        ชื่อร้านค้าต้องน้อยกว่า 50 ตัว{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>เบอร์โทร</b>
                    <input
                      value={phoneNumber}
                      type="text"
                      className={
                        errorPhoneNumber === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="เบอร์โทร"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                    {errorPhoneNumber === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกเบอร์โทร{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorPhoneNumber === 2 ? (
                      <font color="red" size="2">
                        {" "}
                        เบอร์โทรต้องเป็นตัวเลข 9-10 ตัว{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorPhoneNumber === 5 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกข้อมูลเป็นตัวเลข 0-9 เท่านั้น{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>เลขผู้เสียภาษี/เลขบัตรประชาชน</b>
                    <input
                      value={numberId}
                      type="text"
                      className={
                        errorNumberId === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="เลขผู้เสียภาษี/เลขบัตรประชาชน"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setNumberId(e.target.value);
                      }}
                    />
                    {errorNumberId === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกเลขผู้เสียภาษี/เลขบัตรประชาชน{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorNumberId === 2 ? (
                      <font color="red" size="2">
                        {" "}
                        เลขผู้เสียภาษี/เลขบัตรประชาชนต้องเท่ากับ 13 ตัว{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorNumberId === 5 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกข้อมูลเป็นตัวเลข 0-9 เท่านั้น{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="col-sm-12 px-md-1 mt-1">
                    <b>ค้นหาที่อยู่</b>
                    <input
                      value={search}
                      id="demoaa"
                      placeholder="ค้นหาตำบล, อำเภอ, จังหวัด, รหัสไปรษณีย์"
                      className="form-control"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <div
                      data-bs-spy="scroll"
                      class="list-group table-wrapper-scroll-y my-custom-scrollbar"
                      style={
                        search !== "" && search.length >= 3
                          ? {
                              position: "absolute",
                              width: "99.5%  ",
                              zIndex: "1",
                              overflow: "Auto",
                            }
                          : { display: "none" }
                      }
                    >
                      {address
                        .filter(
                          (value, i) =>
                            (value.subdistrictName.includes(search) ||
                              value.districtName.includes(search) ||
                              value.provinceName.includes(search) ||
                              value.postCode.toString().includes(search)) &&
                            search.length >= 3
                        )
                        .slice(0, showAll)
                        .map((value, i) => (
                          <button
                            style={{ height: "40px" }}
                            type="button"
                            class="list-group-item list-group-item-action"
                            onClick={() => {
                              setSearch("");
                              setSubdistrict(value.subdistrictName);
                              setDistrict(value.districtName);
                              setProvince(value.provinceName);
                              setPostCode(value.postCode);
                              /////////////////////////////////////
                              setSubdistrictEng(value.district_en);
                              setDistrictEng(value.amphoe_en);
                              setProvinceEng(value.province_en);
                              setPostCodeEng(value.postCode);
                              ////////////////////////////////////
                              document.getElementById("demoaa").value =
                                value.subdistrictName +
                                " " +
                                value.districtName +
                                " " +
                                value.provinceName +
                                " " +
                                value.postCode;
                            }}
                          >
                            {value.subdistrictName +
                              ", " +
                              value.districtName +
                              ", " +
                              value.provinceName +
                              ", " +
                              value.postCode}
                          </button>
                        ))}
                      <button
                        style={{ height: "40px" }}
                        type="button"
                        class="list-group-item list-group-item-action"
                        onClick={() => {
                          if (showAll === 10) {
                            setShowAll();
                          } else {
                            setShowAll(10);
                          }
                        }}
                      >
                        {showAll === 10
                          ? "-- แสดงทั้งหมด --"
                          : "-- แสดงน้อยลง --"}
                      </button>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-sm-3 px-md-1 mt-1">
                    <b>ที่อยู่/เลขที่(ไทย)</b>
                    <input
                      value={addres}
                      type="text"
                      className={
                        errorAddres === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="ที่อยู่/เลขที่"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                    {errorAddres === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกที่อยู่/เลขที่{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>ตำบล(ไทย)</b>
                    <input
                      style={
                        errorSubdistrict === 1
                          ? { backgroundColor: "#ffff" }
                          : {}
                      }
                      readOnly
                      value={subdistrict}
                      type="text"
                      className={
                        errorSubdistrict === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="ตำบล"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setSubdistrict(e.target.value);
                      }}
                    />
                    {errorSubdistrict === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกตำบล{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>อำเภอ(ไทย)</b>
                    <input
                      style={
                        errorSubdistrict === 1
                          ? { backgroundColor: "#ffff" }
                          : {}
                      }
                      readOnly
                      value={district}
                      type="text"
                      className={
                        errorDistrict === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="อำเภอ"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setDistrict(e.target.value);
                      }}
                    />
                    {errorDistrict === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกอำเภอ{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>จังหวัด(ไทย)</b>
                    <input
                      style={
                        errorSubdistrict === 1
                          ? { backgroundColor: "#ffff" }
                          : {}
                      }
                      readOnly
                      value={province}
                      type="text"
                      className={
                        errorProvince === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="จังหวัด"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setProvince(e.target.value);
                      }}
                    />
                    {errorProvince === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกจังหวัด{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-3 px-md-1 mt-1">
                    <b>รหัสไปรษณีย์(ไทย)</b>
                    <input
                      style={
                        errorSubdistrict === 1
                          ? { backgroundColor: "#ffff" }
                          : {}
                      }
                      readOnly
                      value={postCode}
                      type="text"
                      className={
                        errorPostCode === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="รหัสไปรษณีย์"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setPostCode(e.target.value);
                      }}
                    />
                    {errorPostCode === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกรหัสไปรษณีย์{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-sm-3 px-md-1 mt-1">
                    <b>ที่อยู่/เลขที่(อังกฤษ)</b>
                    <input
                      value={addresEng}
                      type="text"
                      className={
                        errorAddresEng === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="ที่อยู่/เลขที่"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setAddressEng(e.target.value);
                      }}
                    />
                    {errorAddresEng === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกที่อยู่/เลขที่{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>ตำบล(อังกฤษ)</b>
                    <input
                      style={
                        errorSubdistrict === 1
                          ? { backgroundColor: "#ffff" }
                          : {}
                      }
                      readOnly
                      value={subdistrictEng}
                      type="text"
                      className={
                        errorSubdistrict === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="ตำบล"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setSubdistrictEng(e.target.value);
                      }}
                    />
                    {errorSubdistrict === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกตำบล{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>อำเภอ(อังกฤษ)</b>
                    <input
                      style={
                        errorSubdistrict === 1
                          ? { backgroundColor: "#ffff" }
                          : {}
                      }
                      readOnly
                      value={districtEng}
                      type="text"
                      className={
                        errorDistrict === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="อำเภอ"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setDistrictEng(e.target.value);
                      }}
                    />
                    {errorDistrict === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกอำเภอ{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>จังหวัด(อังกฤษ)</b>
                    <input
                      style={
                        errorSubdistrict === 1
                          ? { backgroundColor: "#ffff" }
                          : {}
                      }
                      readOnly
                      value={provinceEng}
                      type="text"
                      className={
                        errorProvince === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="จังหวัด"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setProvinceEng(e.target.value);
                      }}
                    />
                    {errorProvince === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกจังหวัด{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-3 px-md-1 mt-1">
                    <b>รหัสไปรษณีย์(อังกฤษ)</b>
                    <input
                      style={
                        errorSubdistrict === 1
                          ? { backgroundColor: "#ffff" }
                          : {}
                      }
                      readOnly
                      value={postCodeEng}
                      type="text"
                      className={
                        errorPostCode === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="รหัสไปรษณีย์"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setPostCodeEng(e.target.value);
                      }}
                    />
                    {errorPostCode === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกรหัสไปรษณีย์{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>ค่าขนส่งพัสดุ</b>
                    <br />
                    <input
                      className="checkbox-custom"
                      type="checkbox"
                      name="isShipmentCost"
                      checked={showShipmentCost}
                      onChange={() => setShowShipmentCost(!showShipmentCost)}
                    />{" "}
                    <label htmlFor="isShipmentCost">แสดงราคา</label>
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>ที่อยู่ของผู้รับพัสดุ</b>
                    <br />
                    <input
                      className="checkbox-custom"
                      type="checkbox"
                      name="isAddressReceiver"
                      checked={showAddressReceiver}
                      onChange={() =>
                        setShowAddressReceiver(!showAddressReceiver)
                      }
                    />{" "}
                    <label htmlFor="isAddressReceiver">แสดงข้อมูลที่อยู่</label>
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>ปริ้นco note</b>
                    <br />
                    <input
                        className="checkbox-custom"
                        type="checkbox"
                        name="showConote"
                        checked={showConote}
                        onChange={() =>
                            setShowConote(!showConote)
                        }
                    />{" "}
                    <label htmlFor="showConote">แสดงข้อมูลco note</label>
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>บังคับใส่ขนาด</b>
                    <br />
                    <input
                        className="checkbox-custom"
                        type="checkbox"
                        name="requireDimension"
                        checked={requireDimension}
                        onChange={() =>
                          setRequireDimension(!requireDimension)
                        }
                    />{" "}
                    <label htmlFor="requireDimension">บังคับใส่ขนาด</label>
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>โฆษณา</b>
                    <br />
                    <input
                        className="checkbox-custom"
                        type="checkbox"
                        name="showAds"
                        checked={showAds}
                        onChange={() =>
                          setShowAds(!showAds)
                        }
                    />{" "}
                    <label htmlFor="showAds">โฆษณา</label>
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>แสดงพัสดุฯตีกลับ</b>
                    <br />
                    <input
                        className="checkbox-custom"
                        type="checkbox"
                        name="showReturn"
                        checked={showReturn}
                        onChange={() =>
                          setShowReturn(!showReturn)
                        }
                    />{" "}
                    <label htmlFor="showReturn">แสดงพัสดุฯตีกลับ</label>
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>แสดงรูปแนบ</b>
                    <br />
                    <input
                        className="checkbox-custom"
                        type="checkbox"
                        name="showImage"
                        checked={showImage}
                        onChange={() =>
                          setShowImage(!showImage)
                        }
                    />{" "}
                    <label htmlFor="showImage">แสดงรูปแนบ</label>
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>ใช่ชื่อผู้รับ</b>
                    <br />
                    <input
                        className="checkbox-custom"
                        type="checkbox"
                        name="showPickUp"
                        checked={showPickUp}
                        onChange={() =>
                          setShowPickUp(!showPickUp)
                        }
                    />{" "}
                    <label htmlFor="showPickUp">ใช่ชื่อผู้รับ</label>
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>Generate CONOTE</b>
                    <br />
                    <input
                        className="checkbox-custom"
                        type="checkbox"
                        name="generateConote"
                        checked={generateConote}
                        onChange={() =>
                          setGenerateConote(!generateConote)
                        }
                    />{" "}
                    <label htmlFor="generateConote">generateConote</label>
                  </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 px-md-1 mt-1">
                    <label>
                      <b>วิธีการชำระเงิน</b>
                    </label>
                      <select
                        value={payment_type}
                        onChange={(e) => {
                          setPaymentType(e.target.value);
                        }}
                        className={`form-control dropdown-custom`}
                      >
                        <option value={''}>--วิธีการชำระเงิน--</option>
                        <option value={'1'}>เงินสด</option>
                        <option value={'2'}>เครดิต</option>
                      </select>
                    </div>
                    <div className="col-sm-2 px-md-1 mt-1">
                    <label>
                      <b>ผู้ชำระ</b>
                    </label>
                      <select
                        value={payer}
                        onChange={(e) => {
                            setPayer(e.target.value);
                        }}
                        className={`form-control dropdown-custom`}
                                >
                        <option value={''}>--ผู้ชำระ--</option>
                        <option value={'1'}>ผู้ส่ง</option>
                        <option value={'2'}>ผู้รับ</option>
                      </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 px-md-1 mt-1">
                      <label><b>ธนาคาร</b></label>
                      <select
                        className="form-control"
                        value={bank_id}
                        key={bank_id}
                        onChange={(e) => {
                          setBank_id(e.target.value);
                        }}
                      >
                        <option value={""}>{"--กรุณาเลือกธนาคาร--"}</option>
                        {bank.map((value) => (
                          <option value={value.bank_id} key={value.bank_id}>
                            {value.bankName}
                          </option>
                        ))}
                      </select> 
                    </div>
                    <div className="col-sm-2 px-md-1 mt-1">
                        <label><b>ชื่อบัญชี</b></label>
                        <input
                          value={bookbank_name}
                          onChange={(e) => {
                            setBookbank_name(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                          placeholder="ชื่อบัญชี"
                        />
                    </div>
                    <div className="col-sm-2 px-md-1 mt-1">
                      <label><b>เลขบัญชี</b></label>
                        <input
                          value={bookbank_code}
                          onChange={(e) => {
                            setBookbank_code(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                          placeholder="เลขบัญชี"
                        /> 
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 px-md-1 mt-1">
                        <label><b>เปอร์เซ็นที่ได้รับ</b></label>
                        <input
                          value={rate}
                          onChange={(e) => {
                            setRate(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                        />
                    </div>
                    <div className="col-sm-2 px-md-1 mt-1">
                        <label><b>เปอร์เซ็นที่ได้รับ {">"}50ชิ้น</b></label>
                        <input
                          value={rate2}
                          onChange={(e) => {
                            setRate2(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                        />
                    </div>
                    <div className="col-sm-2 px-md-1 mt-1">
                      <label> <b> อัพโหลดลายเซ็น </b></label>
                      <input
                        id="files3"
                        type="file"
                        style={{}}
                        className="btn btn-secondary"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          // const formData = new FormData();
                          // formData.append('file',file_receipt);
                          // formData.append('code',parcel_code);
                          setFileSignature(e.target.files[0]);
                          // uploadSignature({
                          //   shop_code: shopCode,
                          //   file: e.target.files[0]
                          // });

                          //Upload file function
                          // handleClose2(e);
                          // ShipmentStatus("");
                        }}
                      />
                    </div>   
                </div>
                <br/>
                {/* <div className="col-sm-2">
                      <label> <b> อัพโหลดลายเซ็น </b></label>
                      <img className="image-border-barcode" 
                      // src={`${printLabelSingle.image}?${nanoid()}`} 
                      width="200" height="200" alt="" 
                      // onError={hideImg}
                        />
                    <div className="pt-4 pb-2 pr-4 pl-4">
                      <label className="btn btn-info pr-5 pl-5 rounded-pill btn-candidate">
                        เลือกไฟล์ <input type="file" hidden 
                        // onChange={handleFileChange} 
                        name="image" accept="image/*" />
                      </label>
                    </div>
                  <div>
                    <span>
                    </span>{' '}
                  </div>
                  <small className="text-muted">รองรับไฟล์ *image เท่านั้น</small>
                </div> */}
                <br />
                <b style={{ fontSize: "22px" }}>ข้อมูลผู้ใช้งาน</b>
                <div className="row">
                  <div className="col-sm-3 px-md-1 mt-1">
                    <br />
                    <b>รหัสผ่าน</b>
                    <input
                      value={password}
                      type={showPass === 1 ? "password" : "text"}
                      className={
                        errorPassword === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="รหัสผ่าน"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    {errorPassword === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกรหัสผ่าน{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorPassword === 2 ? (
                      <font color="red" size="2">
                        {" "}
                        รหัสผ่านต้องมากกว่า 6 ตัว{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorPassword === 5 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกข้อมูลเป็น a-z, A-Z, 0-9 เท่านั้น{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-3 px-md-1 mt-1">
                    <br />
                    <b>ยืนยันรหัสผ่าน</b>
                    <input
                      disabled={password !== "" ? false : true}
                      value={checkpassword}
                      type={showPass === 1 ? "password" : "text"}
                      className={
                        errorCheckPassword === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="ยืนยันรหัสผ่าน"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setCheckPassword(e.target.value);
                      }}
                    />
                    {errorCheckPassword === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        รหัสผ่านไม่ตรงกัน{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-1 px-md-1 mt-1">
                    <br />
                    <br />
                    <button
                      className="btn btn-secondary"
                      onClick={(e) => {
                        if (showPass === 1) {
                          setShowPass(0);
                        } else {
                          setShowPass(1);
                        }
                      }}
                    >
                      {showPass === 1 ? <Icon.EyeSlash /> : <Icon.Eye />}
                    </button>
                  </div>
                  <div className="col-sm-5 px-md-5 mt-1">
                    <br />
                    <b>สถานะ</b>
                    <div
                      style={
                        errorStatus === 0
                          ? { marginTop: "5px", color: "#ff0000" }
                          : { marginTop: "5px" }
                      }
                    >
                      <div className="form-check form-check-inline">
                        <input
                          checked={status === "1" ? true : false}
                          style={{ width: "20px", height: "20px" }}
                          className="form-check-input"
                          type="radio"
                          name="inlineRadio6"
                          id="inlineRadio6"
                          value="1"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="inlineRadio0">
                          ใช้งาน
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          checked={status === "0" ? true : false}
                          style={{ width: "20px", height: "20px" }}
                          className="form-check-input"
                          type="radio"
                          name="inlineRadio7"
                          id="inlineRadio7"
                          value="0"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="inlineRadio1">
                          ปิดใช้งาน
                        </label>
                      </div>
                    </div>
                    {errorStatus === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณาเลือกสถานะ{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <br />
                <br />
                <center>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    {loading === 1 ? (
                      <center>
                        <div
                          style={{ width: "20px", height: "20px" }}
                          className="spinner-border"
                          role="status"
                        ></div>
                      </center>
                    ) : (
                      <div>
                        <Icon.Save /> บันทึก
                      </div>
                    )}
                  </button>{" "}
                  <button className="btn btn-secondary" onClick={clear}>
                    <Icon.ArrowClockwise /> ล้างค่า
                  </button>
                </center>
              </div>
            </div>
          </div>
        </div>
      </>
      <br />
      <br />
    </div>
  );
}
