
import Instance from '../../../../Helper/axios'



export const getHubUsers = (keyword, page, perPages) => {
    const params = {
        keyword,
        page,
        perPages
    }
    return Instance.get('/settings/HubEmployees/employees', {
        params
    })
}

export const updateHubUsers = (id,name,username, password) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('username',username)
    if(password){
        formData.append('password',password);
    }
    return Instance.post(`/settings/HubEmployees/addOrUpdate${id ? `/${id}` : ''}`, formData);
}

export const getHubUser = (id) => {
    return Instance.get(`/settings/HubEmployees/get/${id}`);
}

export const deleteHubUser = (id) => {
    return Instance.delete(`/settings/HubEmployees/delete/${id}`);
}

export const addHubZip = (hubId,zip) => {
    return Instance.put(`/hub/HubController/addZipCodeToHub`,{
        hub: hubId,
        zip
    });
}

export const updateHubZip = (id,newZip) => {
    return Instance.put(`/hub/HubController/updateZipCode`,{
        zipId: id,
        newZip
    });
}

export const getHubZips = (hubId) => {
    return Instance.get(`/hub/HubController/hubZipCodes?hub=${hubId}`)
}

export const deleteHubZip = (zipId) => {
    return Instance.get(`/hub/HubController/removeHubZip?zipId=${zipId}`)
}