import * as Icon from "react-bootstrap-icons";
import React,{useState, useCallback} from "react";

const HubUsersSearch = React.memo(({onSearch, loading, children}) => {
    const [keyword, setKeyword] = useState("");
    const clear = useCallback(
        () => {
            setKeyword('');
            onSearch('');
        },
        [onSearch],
    );

    const filter = useCallback(
        () => {
            onSearch(keyword);
        },
        [onSearch, keyword]
    );

    return (
        <div className="row" style={{marginLeft: "5px", marginRight: "5px"}}>
            <div className="col-sm-4 px-md-1 mt-1">
                <span>ค้นหา</span>
                <input
                    value={keyword}
                    onChange={(e) => {
                        setKeyword(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="ชื่อผู้ใช้"
                    aria-label="ชื่อผู้ใช้"
                    aria-describedby="basic-addon1"
                />
            </div>
            <div className="col-sm-2 px-md-3 mt-1">
                <br/>
                <button
                    type="button"
                    disabled={!!loading}
                    className={"btn btn-primary"}
                    onClick={filter}
                >
                    <Icon.Search/> ค้นหา
                </button>
                {" "}
                <button type="reset" className="btn btn-secondary" onClick={clear}>
                    <Icon.ArrowClockwise/> ล้างค่า
                </button>
            </div>
            <div className="col-sm-4 px-md-1 mt-1" align="right">
                {children}
            </div>
        </div>
    )
});

export default HubUsersSearch;