import React, { useState} from "react";
import Nav from "react-bootstrap/Nav";
import { NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router";
import AdsModal from "../../../Components/AdsModal";
import { ProgressModal } from "../../../Components/ProgressModal";
import ReactDOM from "react-dom";
import { uploadFile } from "../../../Service/service.upload";
import { dataURIToBase64 } from "../../../Helper/DataFormat";
import {nanoid} from "nanoid";
import { AdsService } from "../../../Service/service.ads";
import { useTranslation } from "react-i18next";
import { swalSuccess, swalError } from "../../../Components/sweetAlert";

export default function CreditCustMenu({ handleShow }) {
  const history = useHistory();
  const [showAds, setShowAds] = useState(false);
  const [progress, setProgress] = useState(null);
  const [currentAd, setCurrentAd] = useState(null);

  const { t } = useTranslation();
  const handleFileUpload = (uri) => {
    return uploadFile(uri, `${nanoid()}.png`, (progressEvent) => {
      setProgress({
        current: progressEvent.loaded,
        total: progressEvent.total
      });
    });
  };
  const handleSubmission = async (results) => {
    try {
      let url = null;
      if (results.image === currentAd?.logo_url) {
        url = currentAd?.logo_url;
      }
      else {
        const apiresults = await handleFileUpload(dataURIToBase64(results.image));
        url = apiresults.data.data;
      }
      const adAPIresults = await AdsService.add({
        logo_url: url,
        store_name: results.store_name,
        contact: results.hyperlink,
        product_detail: results.details,
        product_price: results.price,
      }, localStorage.getItem("id"));
      setProgress(null);
      setShowAds(false);
      swalSuccess({
        text: t(adAPIresults.data.message),
      });
    }
    catch (error) {
      setProgress(null);
      error.response?.data?.message && swalError({
        text: t(error.error.message),
      })
    }
  };
  return (
    <>
      <Nav.Item>
        <NavDropdown title="ส่งพัสดุ" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={(e) => {
              history.push("Parcel");
            }}
          >
            ส่งพัสดุ
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("PrintLabel");
            }}
          >
            ตรวจสอบการส่งพัสดุ
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => {
              history.push("ImportExcel");
            }}
          >
            อัพโหลดข้อมูลพัสดุ
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item>
      {/* <Nav.Item>
        <Nav.Link
          onClick={(e) => {
            history.push("Receipt");
          }}
        >
          ประวัติการพิมพ์ใบเสร็จ
        </Nav.Link>
      </Nav.Item> */}
      
      {localStorage.getItem('showPickUp') === '1' ? (
        <Nav.Item>
      <NavDropdown title="ใส่ที่อยู่ผู้รับ" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={(e) => {
              history.push("ParcelPickUp");
            }}
          >
            ใส่ที่อยู่ผู้รับ
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("PickUpList");
            }}
          >
            รายชื่อผู้รับ
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("ImportExcelParcelPickUp");
            }}
          >
            อัพโหลดข้อมูลพัสดุ
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item>
      ) : ''}
      
      <Nav.Item>
        <NavDropdown title="รายงาน" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={() => {
              history.push("TrackingReport");
            }}
          >
            รายงานติดตามพัสดุ
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("ReportStockIn");
            }}
          >
            พิมพ์รายงานทะเบียนคุม
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/myCOD"
          onClick={(e) => {
            history.push("myCOD");
          }}
        >
          เก็บเงินปลายทาง
        </Nav.Link>
      </Nav.Item>
      {localStorage.getItem("showAds") === "1" ? 
      (
        <Nav.Item>
          <Nav.Link
            eventKey="/home"
            onClick={async (e) => {
              try {
                const result = await AdsService.load();
                setCurrentAd(result.data.data);
                setShowAds(true);
              }
              catch (error) {
                console.log(error.response);
                if (error.response.status === 404) {
                  setCurrentAd(null);
                  setShowAds(true);
                }
                else {
                  error.response?.data?.message && swalError({
                    text: t(error.error.message),
                  });
                }
              }
            }}
          >
            การจัดการโฆษณา
          </Nav.Link>
        </Nav.Item>
      ):""}
      
      {/* <Nav.Item>
        <Nav.Link
          eventKey="/Size"
          onClick={(e) => {
            history.push("Size");
          }}
        >
          เพิ่มขนาด
        </Nav.Link>
      </Nav.Item> */}
      {localStorage.getItem("showReturn") === "1" ? (
        <Nav.Item>
          <Nav.Link
            eventKey="/home"
            onClick={(e) => {
              handleShow();
            }}
          >
            พัสดุถูกส่งกลับ
          </Nav.Link>
        </Nav.Item>
      ):""}
      
      {showAds && <AdsModal currentAd={currentAd} onSubmit={(results) => {
          handleSubmission(results);
      }} show={true} onHide={() => setShowAds(false)} />}
      {progress && ReactDOM.createPortal(
        <ProgressModal show={true} current={progress.current} total={progress.total} label={'ความคืบหน้าในการอัปโหลด'}  />,
        document.getElementById('root'),
      )}
    </>
  );
}
