import React, {useState, useEffect, useRef} from 'react'
import * as Yup from 'yup';
import { Formik, Form, FieldArray, ErrorMessage, getIn } from 'formik';
import { Modal } from 'react-bootstrap';
import { parcelsUpdate } from '../../../Service/service.Barcode';
import { Spinner } from "react-bootstrap";
import { swalError } from '../../../Components/sweetAlert';

const schema = Yup.object().shape({
    barcodes: Yup.array().of(
        Yup.object().shape({
            ref_no: Yup.string(),
            invoice: Yup.string(),
            weight: Yup
                .number(),
                // .required("กรุณากรอก น้ำหนัก")
                // .min(0.1, "กรุณากรอก น้ำหนักมากกว่า หรือเท่ากับ 0.1")
                // .max(30, "ขออภัย พัสดุฯมีน้ำหนักเกิน30กิโลกรัม"),
            width: Yup
                .number(),
                // .required("กรุณากรอก ขนาดความกว้าง ")
                // .min(1, "กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1"),
            height: Yup
                .number(),
                // .required("กรุณากรอก ขนาดความสูง ")
                // .min(1, "กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1"),
            length: Yup
                .number(),
                // .required("กรุณากรอก ขนาดความยาว"),
                // .min(1, "กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1"),
            size: Yup
                .number(),
                // .required("กรุณาใส่ขนาด")
        })
    )
});
export default function MultipleEditModal({
    barcodes,
    onFinish,
    onClose,
}) {
    const [initialValues, setInitialValues] = useState(null);
    const [loading, setLoading] = useState(false);
    console.log(initialValues);
    const formRef = useRef(null);
    useEffect(() => {
        // console.log("barcodes :"+ JSON.stringify(barcodes));
        setInitialValues({
            barcodes: barcodes.map(row => ({
                ref_no: row.ref_no,
                invoice: row.invoice,
                height: row.height,
                length: row.length,
                size: row.size,
                width: row.width,
                weight: row.weight,
                awb_code: row.awb_code,
                code: row.code,
                province: row.receiver_province,
                amphur: row.receiver_amphur,
                cod_cost: row.cod_cost
            })),
        })
    }, [barcodes]
  );
    const handleUpdate = async (result) => {
      setLoading(true);
        try {
          console.log('updating');
         
          const resultat = await parcelsUpdate(result.barcodes);
          onFinish();
      }
      catch (e) {
                console.log(e);
                swalError({
                    text: e.error.message
                });
      }
      finally {
          setLoading(false);
      }
  };
  
  const handleUpdateSize = (index, width, height, length, setFieldValue) => {
      setFieldValue(`barcodes[${index}].size`, (width !== '' && height !== '' && length !== '') ? 
      (parseFloat(width) + parseFloat(height) + parseFloat(length)) : '');
    };
    const formBody = (
       <Formik
                    validationSchema={schema}
                    initialValues={initialValues}
                      enableReinitialize={true}
                      innerRef={formRef}
                      onSubmit={(value, { setFieldValue, resetForm }) => {
                          console.log('submitted');
                        handleUpdate(value);
                    }}
                >
                    {({ errors, touched, handleBlur, setFieldValue, values, handleSubmit }) => (
                          <Form>
                              <div style={{ maxHeight: '500px', overflowY: 'auto', width: '100%' }}>
                                {values.barcodes.map((row,index) => (
                                    <div style={{ borderRadius: '10px', padding: '10px', marginBottom: '5px', border: '1px solid rgba(0,0,0,.125)'}} key={row.key} >
                                        <h4>{row.awb_code}</h4>
                                        <div class="row">
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor={`barcodes[${index}].weight`}>น้ำหนัก (กก.)</label>
                                                    <input
                                                        value={row.weight}
                                                        type="number"
                                                        name={`barcodes[${index}].weight`}
                                                        className={'text-right form-control'}
                                                        // className={'text-right form-control  ' + (getIn(errors, `barcodes[${index}].weight`) ? 'is-invalid' : 'is-valid')}
                                                        onBlur={handleBlur}
                                                        onChange={async (e) => {
                                                            setFieldValue(`barcodes[${index}].weight`, e.target.value);
                                                        }}
                                                    />
                                                    <ErrorMessage component="div" name={`barcodes[${index}].weight`} className="invalid-feedback" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor={`barcodes[${index}].width`}>ความกว้าง (ซม.)</label>
                                                    <input
                                                        value={row.width}
                                                        type="number"
                                                        name={`barcodes[${index}].width`}
                                                        // className={'text-right form-control  ' + (getIn(errors, `barcodes[${index}].width`) ? 'is-invalid' : 'is-valid')}
                                                        className={'text-right form-control  '}
                                                        onBlur={handleBlur}
                                                        onChange={async (e) => {
                                                            setFieldValue(`barcodes[${index}].width`, e.target.value);
                                                            handleUpdateSize(index, e.target.value, row.height,row.length, setFieldValue);
                                                        }}
                                                    />
                                                    <ErrorMessage component="div" name={`barcodes[${index}].width`} className="invalid-feedback" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                    <div className="form-group">
                                                    <label htmlFor={`barcodes[${index}].height`}>ความสูง (ซม.)</label>
                                                    <input
                                                        value={row.height}
                                                        type="number"
                                                        name={`barcodes[${index}].height`}
                                                        // className={'text-right form-control  ' + (getIn(errors, `barcodes[${index}].height`) ? 'is-invalid' : 'is-valid')}
                                                        className={'text-right form-control'}
                                                        onBlur={handleBlur}
                                                        onChange={async (e) => {
                                                            setFieldValue(`barcodes[${index}].height`, e.target.value);
                                                            handleUpdateSize(index, row.width, e.target.value,row.length, setFieldValue);
                                                        }}
                                                    />
                                                    <ErrorMessage component="div" name={`barcodes[${index}].height`} className="invalid-feedback" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor={`barcodes[${index}].length`}>ความยาว (ซม.)</label>
                                                    <input
                                                        value={row.length}
                                                        type="number"
                                                        name={`barcodes[${index}].length`}  
                                                        // className={'text-right form-control  ' + (getIn(errors, `barcodes[${index}].length`) ? 'is-invalid' : 'is-valid')}
                                                        className={'text-right form-control'}
                                                        onBlur={handleBlur}
                                                        onChange={async (e) => {
                                                            setFieldValue(`barcodes[${index}].length`, e.target.value);
                                                            handleUpdateSize(index, row.width, row.height,e.target.value, setFieldValue);
                                                        }}
                                                    />
                                                    <ErrorMessage component="div" name={`barcodes[${index}].length`} className="invalid-feedback" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                    <div className="form-group">
                                                    <label htmlFor={`barcodes[${index}].size`}>กว้าง + ยาว + สูง (ซม.)</label>
                                                    <input
                                                        value={row.size}
                                                        // disabled={true}
                                                        type="number"
                                                        name={`barcodes[${index}].size`}
                                                        // className={'text-right form-control  ' + (getIn(errors, `barcodes[${index}].size`) ? 'is-invalid' : 'is-valid')}
                                                        className={'text-right form-control'}
                                                        onBlur={handleBlur}
                                                        onChange={async (e) => {
                                                            setFieldValue(`barcodes[${index}].size`, e.target.value);
                                                        }}
                                                    />
                                                    <ErrorMessage component="div" name={`barcodes[${index}].size`} className="invalid-feedback" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor={`barcodes[${index}].ref_no`}>สำหรับลูกค้า / เลขที่co note</label>
                                                    <input
                                                        value={row.ref_no}
                                                        type="text"
                                                        name={`barcodes[${index}].ref_no`}
                                                        className={'form-control'}
                                                        onBlur={handleBlur}
                                                        onChange={async (e) => {
                                                            setFieldValue(`barcodes[${index}].ref_no`, e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor={`barcodes[${index}].invoice`}>หมายเลขinvoice</label>
                                                    <input
                                                        type="text"
                                                        className={'form-control'}
                                                        aria-describedby={`barcodes[${index}].invoice`}
                                                        name={`barcodes[${index}].invoice`}
                                                        value={row.invoice}
                                                        onChange={(e) => {
                                                            setFieldValue(`barcodes[${index}].invoice`, e.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                              </div>
                              <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '5px'}}>
                                <button type='submit' 
                                    disabled={formRef.current && !formRef.current.isValid} 
                                    className={formRef.current && formRef.current.isValid ? 'btn btn-success mb-1 ' : 'btn btn-secondary mb-1'}>
                                    บันทึก
                                </button>
                              </div>
                        </Form>
                    )}
       </Formik>
    );
  return (
      <>
          <Modal onHide={() => onClose()} show={true} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>แก้ไขข้อมูลพัสดุฯ</Modal.Title>
            </Modal.Header>
              <Modal.Body>
                  {!loading && initialValues && formBody}
                  {loading && (
                    <div className="spinner-global-content">
                            <Spinner variant="success" animation="border" />
                    </div>
                  )}
              </Modal.Body>
          </Modal>
      </>
  )
}
