import React, {useEffect, useState} from "react";
import UpdateHistoryShowData from "./ui/update-history-show-data";
import useUpdateHistoryData from "./hooks/use-update-history-data";
import {useParams} from "react-router";
import Pagination from "@material-ui/lab/Pagination";
import {Spinner} from "react-bootstrap";

const UpdateHistory = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const {data,error,loading,fetchData} = useUpdateHistoryData();
    const params = useParams();
    useEffect(() => {
            fetchData(params.id,currentPage - 1)
    }, [currentPage]);

    const handleChangePage = (event,newPage) => {
      setCurrentPage(newPage);
    };
    return (
        <>
            <h1>แก้ไขข้อมูล</h1>
            <div style={{ overflow: 'auto', width: '100%' }}>
                {!loading && data && (
                    <>
                        <UpdateHistoryShowData data={data.result} />
                        <Pagination
                            count={data.maxPage}
                            page={currentPage}
                            onChange={handleChangePage}
                            color="primary"
                            size="small"
                            defaultPage={1}
                            siblingCount={1}
                        />
                    </>
                )}
                {loading && <div className="spinner-global-content">
                    <Spinner variant="success" animation="border" />
                </div> }
            </div>
        </>
    )
}
export default UpdateHistory;