import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import * as Icon from 'react-bootstrap-icons';
import swal from 'sweetalert';

export default function ShowData({ data, page, perPage, history, loading, errorMassNoData, handleChangePage, pageNo, Del }) {
  return (
    <>
      <div style={{ overflow: 'Auto' }}>
        <table className="table table-bordered">
          <thead className="table-blue">
            <tr>
              <th>ลำดับ</th>
              <th>ชื่อผู้สมัคร</th>
              <th>เบอร์โทรติดต่อ</th>
              <th>อีเมล</th>
              <th>ตำแหน่งที่สมัคร</th>
              <th>จัดการ</th>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={index}>
                <td>{(parseInt(page.current_page) - 1) * perPage + (index + 1)}</td>
                <td>{value.title + ' ' + value.names + ' ' + value.surname}</td>
                <td>{value.phone_number}</td>
                <td>{value.email}</td>
                <td>{value.name}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-info mr-1"
                    onClick={(e) => {
                      history.push('/mis/Detail', { code: value.code });
                    }}
                  >
                    <Icon.Eye style={{ color: '#ffff' }} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger ml-1"
                    onClick={(e) => {
                      swal({
                        title: 'ต้องการลบรายการนี้ใช่หรือไม่',
                        icon: 'warning',
                        buttons: true,
                        dangerMode: true,
                      }).then((type) => {
                        if (type) {
                          Del({
                            code: value.code,
                          });
                        }
                      });
                    }}
                  >
                    <Icon.Trash style={{ color: '#ffff' }} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <center>
          <div className={errorMassNoData === 1 && loading !== 1 ? 'm-5' : 'hidden-ct'}>
            <div>
              <br />
              <font color="red" size="3">
                ไม่พบข้อมูล!
              </font>
            </div>
          </div>
        </center>
      </div>
      <center>
        <div className={loading === 1 ? 'm-5' : 'hidden-ct'}>
          <div className="text-center">
            <div className="spinner-border  text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            {' ...'}
            กำลังโหลดข้อมูล
          </div>
        </div>
      </center>
      <div className="d-flex justify-content-between">
        <div>จำนวนทั้งหมด {page.total} รายการ</div>
        <div>
          <Pagination count={parseInt(page.last_page)} page={pageNo} onChange={handleChangePage} color="primary" size="small" defaultPage={6} siblingCount={1} />
        </div>
      </div>
      <br />
    </>
  );
}
