import Instance from '../Helper/axios';
import { URL, urlLocal } from '../Helper/baseURL';
import _ from 'lodash';
import { dataURIToBase64 } from '../Helper/DataFormat';

export async function BarcodeService(data) {
  // console.log("data:" + JSON.stringify(data));
  try {
    const response = await Instance.post(URL + 'RecoveryBarcode/getBarcodeList', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function GetRecoveryBarcode(param) {
  try {
    const formData = new FormData();
    Object.entries(param).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/getRecoveryBarcode', formData);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}
export function getIndividualConoteData(ref_no) {
  return Instance.get(URL + `printLabel/PrintLabel/oneConote?ref_no=${ref_no}&cust_code=${localStorage.getItem('username')}`);
}
export function getMultipleConoteData(ref_no) {
  return Instance.post(URL + `printLabel/PrintLabel/multipleConotes`, {
    ref_no,
    cust_code: localStorage.getItem('username')
  });
}

/*
export async function BarcodeService(data) {
    try {
      const response = await Instance.post(URL + "recoveryBarcode/getBarcodeList",data );
      return await response.data;
    } catch (error) {
      console.log("error", error);
    }
  }
  */

export async function BarcodeUpdateService(data) {
  //อัพเดทสถานะบาร์โค้ด
  try {
    const response = await Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/saveRecoveryBarcode', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function CODUpdateService(data) {
  //อัพเดท COD
  try {
    const response = await Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/updateCOD', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function UpdateCustService(data) {
  //อัพเดท cust_code
  try {
    const response = await Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/updateCustCode', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function CustomerTypeService(data) {
  try {
    const response = await Instance.post(URL + 'RecoveryBarcode/getCustCodeList', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function UploadImage(data) {
  try {
    const response = await Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/upload', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function HistoryParcel(data) {
  try {
    const response = await Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/ScanHistoryParcel', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function deliverImage(data) {
  try {
    const response = await Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/porlorStaffDeliverImages', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export function deleteDeliveryImage(parcelId, imageId){
    return Instance.put(URL + 'managerBarcodeV2/ManagerBarcodeV2/porlorStaffDeliverImagesDelete', {
      parcelId,
      imageId
    });
}

export async function deliverFailImgService(data) {
  try {
    const response = await Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/deliverFailImg', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function deleteImageService(awb_code,data) {
  try {
    const response = await Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/deleteImage/'+awb_code,data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function retrieveImageService(awb_code,data) {
  try {
    const response = await Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/reverseDeleteImage/'+awb_code,data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export function parcelsUpdate(rows) {
  return Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/parcelsUpdate', {
    rows,
    cust_code: localStorage.getItem('username')
  });
}

export function parcelsRef_noGroupUpdate(rows) {
  return Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/parcelRefNoGroupUpdate', {
    rows,
    cust_code: localStorage.getItem('username')
  });
}

export function uploadProofImages(images,code, onProgress) {
  const formData = new FormData();
  images.forEach(image => formData.append('file[]', dataURIToBase64(image), `${_.random()}.jpg`));
  formData.append('code', code);
  return Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/parcelProofImages', formData, {
    onUploadProgress: onProgress
  });
}

export function scanStatusCheck(awb_codes, shipment_status){
  return Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/driver_scan_status', {
    awb_codes,
    shipment_status,
  });
}

export function scanToReceived(awb_codes,signatureURL, imageURLS, description){
  const formData = new FormData();
  awb_codes.forEach((element, index) => {
    formData.append(`awb_code[${index}]`, element);
  });
  formData.append('shipment_status', '5');
  formData.append('cust_code', localStorage.getItem('username'));
  formData.append('device_type', '1');
  formData.append('signature[]', signatureURL);
  formData.append('description', description);
  imageURLS.forEach(image => formData.append('image[]', image));
  return Instance.post(URL + 'managerBarcodeV2/ManagerBarcodeV2/driverScanBarCode', formData);
}