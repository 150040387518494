import React, {useState} from 'react'
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';
import { editModal } from "./style.module.css";
import {swalError, swalSuccess} from "../../../../Components/sweetAlert";
import { updateParcelsRef_nos } from "../../../../Service/service.Parcels";
import { Spinner } from "react-bootstrap";

export default function EditRefNo({ data, onClose }) {
  console.log(data);
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
      setIsLoading(true);
      try {
          const result = await updateParcelsRef_nos(data.map(row => row.code), text);
          onClose(true);
          result.data?.message && swalSuccess({
                text: t(result.data.data.message),
          });
      }
      catch (error) {
          console.log(error);
          error.response?.data?.message && swalError({
              text: t(error.error.message),
          });
      }
      finally {
          setIsLoading(false);
      }
  };
  return (
      <Modal className={editModal} style={{ padding: '15px' }} show={true} >
        <Modal.Header>
              <Modal.Title>แก้ไขCo note</Modal.Title>
        </Modal.Header>
          <Modal.Body>
            {isLoading ? 
                (
                  <div className="spinner-global-content">
                        <Spinner variant="success" animation="border" />
                  </div>
                  )
                  :
                  (
                      <>
                          <div className="form-group mb-2">
                              <label>{t('common.ref_no')}</label>
                                <input
                                    value={text}
                                    onChange={(e) => {
                                        setText(e.target.value);
                                    }}
                                    type="text"
                                    class={`form-control ${text === '' ? 'is-invalid' : 'is-valid'}`}
                                />
                          </div> 
                        <button
                            style={{ width: '100%'}}
                            type="button"
                            
                            className={text === "" ? 'btn btn-secondary mb-1' : 'btn btn-primary mb-1'}
                            disabled={text === "" ? true : false}
                            onClick={() => handleSubmit()}>
                            {t('common.submit')}
                        </button>
                        <button
                            style={{ width: '100%'}}
                            type="button"
                            className={"btn btn-danger"}
                            onClick={() => onClose()}>
                            {t('common.close')}
                        </button>
                      </>
                  )
            }
        </Modal.Body>
      </Modal>
  )
}
