import React from "react";
import { nanoid } from "nanoid";
import printJS from "print-js";
import FormatLabel from "../formatLabel";
import FormatLabelCod from "../formatLabelCod";
import FormatLabelAddress from "../formatLabelAddress";
import FormatLabelAddressCod from "../formatLabelAddressCod";
import FormatLabelPornor from "../formatLabelPornor/formatLabelPornor";
import FormatLabelAddressPornor from "../formatLabelPornor/formatLabelAddressPornor";

function LabelForm({ data }) {
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();

  return (
    <div>
      <>
        <div style={{ display: "none" }}>
          <div id="labelNormal">
            <div key={nanoid()}>
              {data.platform === "5" ? (
                <FormatLabelPornor
                  key={nanoid()}
                  supplies={{
                    cust_code: data.cust_code,
                    /////////////////
                    cod_cost: data.cod_cost,
                    /////////////////
                    awb_code: data.awb_code,
                  }}
                />
              ) : (
                data.cod_cost === "0" ? (
                  <FormatLabel
                    key={nanoid()}
                    supplies={{
                      custCode: data.cust_code,
                      number: 1,
                      receiverName: data.receiver_name,
                      awbCode: data.awb_code,
                      date: date,
                      month: month,
                      year: year,
                      weight: data.weight,
                      box_height:data.box_height,
                        box_length: data.box_length,
                        box_size: data.box_size ,
                        box_width: data.box_width,
                      codCost: data.cod_cost,
                      deposit_type: data.deposit_type,
                      deposit_fullname: data.deposit_fullname,
                      deposit_phone: data.deposit_phone,
                      ref_no: date.ref_no,
                    invoice: date.invoice,
                    return_doc: date.return_doc,
                    num_return_doc: date.num_return_doc,
                    ref_no2: date.ref_no2,
                    receiver_zipcode: data.receiver_zipcode,
                    item_desc: data.item_desc,
                    }}
                  />
                ) : (
                  <FormatLabelCod
                    key={nanoid()}
                    supplies={{
                      custCode: data.cust_code,
                      number: 1,
                      receiverName: data.receiver_name,
                      awbCode: data.awb_code,
                      date: date,
                      month: month,
                      year: year,
                      weight: data.weight,
                      box_height:data.box_height,
                        box_length: data.box_length,
                        box_size: data.box_size ,
                        box_width: data.box_width,
                      codCost: data.cod_cost,
                      deposit_type: data.deposit_type,
                      deposit_fullname: data.deposit_fullname,
                      deposit_phone: data.deposit_phone,
                      ref_no: date.ref_no,
                    invoice: date.invoice,
                    return_doc: date.return_doc,
                    num_return_doc: date.num_return_doc,
                    ref_no2: date.ref_no2,
                    receiver_zipcode: data.receiver_zipcode,
                    item_desc: data.item_desc,
                    }}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </>
      <>
        <div className="contentPrint2" style={{ display: "none" }}>
          <div id="labelAddress">
            <div key={nanoid()}>
              {data.platform === "5" ? (
                <FormatLabelAddressPornor
                  key={nanoid()}
                  supplies={{
                    cust_code: data.cust_code,
                    /////////////////
                    receiver_name: data.receiver_name,
                    receiver_phone: data.receiver_phone,
                    receiver_address: data.receiver_address,
                    receiver_amphur: data.receiver_amphur,
                    receiver_district: data.receiver_district,
                    receiver_province: data.receiver_province,
                    receiver_zipcode: data.receiver_zipcode,
                    /////////////////
                    sender_name: data.sender_name,
                    sender_phone: data.sender_phone,
                    sender_address: data.sender_address,
                    sender_amphoe: data.sender_amphoe,
                    sender_province: data.sender_province,
                    sender_zipcode: data.sender_zipcode,
                    /////////////////
                    cod_cost: data.cod_cost,
                    /////////////////
                    awb_code: data.awb_code,
                    ref_no: date.ref_no,
                    invoice: date.invoice,
                    return_doc: date.return_doc,
                    num_return_doc: date.num_return_doc,
                    ref_no2: date.ref_no2,
                    item_desc: data.item_desc,
                           
                  }}
                />
              ) : (
                data.cod_cost === "0" ? (
                  <FormatLabelAddress
                    key={nanoid()}
                    supplies={{
                      receiver_name: data.receiver_name,
                      receiver_address: data.receiver_address,
                      receiver_amphur: data.receiver_amphur,
                      receiver_district: data.receiver_district,
                      receiver_province: data.receiver_province,
                      receiver_phone: data.receiver_phone,
                      cust_Code: data.cust_code,
                      number: 1,
                      awb_code: data.awb_code,
                      date: date,
                      month: month,
                      year: year,
                      weight: data.weight,
                      box_height:data.box_height,
                        box_length: data.box_length,
                        box_size: data.box_size ,
                        box_width: data.box_width,
                      codCost: data.cod_cost,
                      deposit_type: data.deposit_type,
                      deposit_fullname: data.deposit_fullname,
                      deposit_phone: data.deposit_phone,
                      sender_name: data.sender_name,
                      ref_no: date.ref_no,
                      invoice: date.invoice,
                      return_doc: date.return_doc,
                      num_return_doc: date.num_return_doc,
                      ref_no2: date.ref_no2,
                      receiver_zipcode: data.receiver_zipcode,
                      item_desc: data.item_desc,
                    }}
                  />
                ) : (
                  <FormatLabelAddressCod
                    key={nanoid()}
                    supplies={{
                      receiver_name: data.receiver_name,
                      receiver_address: data.receiver_address,
                      receiver_amphur: data.receiver_amphur,
                      receiver_district: data.receiver_district,
                      receiver_province: data.receiver_province,
                      receiver_phone: data.receiver_phone,
                      cust_Code: data.cust_code,
                      number: 1,
                      awb_code: data.awb_code,
                      date: date,
                      month: month,
                      year: year,
                      weight: data.weight,
                      box_height:data.box_height,
                        box_length: data.box_length,
                        box_size: data.box_size ,
                        box_width: data.box_width,
                      codCost: data.cod_cost,
                      deposit_type: data.deposit_type,
                      deposit_fullname: data.deposit_fullname,
                      deposit_phone: data.deposit_phone,
                      sender_name: data.sender_name,
                      ref_no: date.ref_no,
                    invoice: date.invoice,
                    return_doc: date.return_doc,
                    num_return_doc: date.num_return_doc,
                    ref_no2: date.ref_no2,
                    receiver_zipcode: data.receiver_zipcode,
                    item_desc: data.item_desc,
                    }}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default LabelForm;
