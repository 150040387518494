import HubBodyLayout from '../../../Layouts/Hub/components/hub-body-layout';
import { HubPresentation } from '../Shared/HubPresentation';
import HubPageTitle from '../Shared/HubPageTitle';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import useParcelStatus from "./hooks/useParcelStatus";
import {Spinner} from "react-bootstrap";
import {ParcelStatusFilters} from "./component/parcel-status-filters";
import {swalError} from "../../../Components/sweetAlert";
import {ParcelsStatusDisplay} from "./component/parcels-status-display";
import * as ExcelJS from "exceljs";
import {ExportWithoutPicsWorksheetConstants} from "./constants/export-with-pics.constants";
import {getExportStatus, handleImageFetch} from "./utils/export-with-pics.utils";
import {ExportProgress} from "../../../Components/ExportProgress/ExportProgress";
import { saveAs } from "file-saver";
import {HistoryParcel} from "../../../Service/service.Barcode";
import ModalsHistory from "../../mis/manageBarcodesV2/ModalsHistory";

const initialFilters = {
    keyword: '',
    shipment_status: '',
    dateFilters: {
        startDate: new Date(),
        endDate: new Date(),
    },
    cod: '',
};

const initialProgression = {
    total: 0,
    current: 0,
    totalPages: 0,
    currentPage: 0,
};

const NO_EXPORT_TYPE = null;
const EXPORT_WITHOUT_PICS = 1;
const EXPORT_WITH_PICS = 2;

const EXPORT_PIC_PER_PAGES = 240;


const HubStatus = () => {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [historyData, setHistoryData] = useState(null);
  const [historyLoading, setHistoryLoading] = useState(false);
    const [modalHis, setModalHis] = useState(false);

  const [exportProgression, setExportProgression] = useState(initialProgression);
  const [isExporting , setIsExporting] = useState(false);
  const [exportType, setExportType] = useState(null);

  const {data: parcelData, fetch,loading} = useParcelStatus((error) => {
    void swalError({
        text: error.message,
    })
  });

    const {data: exportData, fetch: fetchExportData,loading: ExportDataLoading, flush} = useParcelStatus((error) => {
        void swalError({
            text: error.message,
        })
    });

    const excelObjectRef = useRef(null);

    async function History(value) {
        setModalHis(true);
        setHistoryLoading(true);
        try{
            setHistoryData([]);
            const res = await HistoryParcel(value);
            if (res !== undefined) {
                if (res.length > 0) {
                    setHistoryData(res);
                }
            }
        }
        catch(error){
            swalError({
                text: error.message,
            });
            setModalHis(false);
        }
        finally {
            setHistoryLoading(false);
        }
    }

    const formattedExportData = useMemo(() => {
        if(!exportData){
            return undefined;
        }
        let targetData = undefined;
        if(exportType === EXPORT_WITHOUT_PICS){
            targetData = exportData;
        }
        else {
            targetData = exportData.result;
        }
        const result = targetData ? targetData.map(value => ({
            'วันเปิดบิล':value.created_date.substring(0, 10),
            'รหัสลูกค้า':value.cust_code,
            'conote': value.ref_no === null || value.ref_no === '' || value.ref_no === "0"  ? "" : value.ref_no,
            'ref_no2': value.ref_no2,
            'เลขลาเบล':value.awb_code,
            'ผู้ส่ง':value.sender_name,
            'ผู้รับ':value.receiver_name,
            'ผู้รับที่อยู่': value.receiver_address,
            'district': value.receiver_district,
            'amphur': value.receiver_amphur,
            'province': value.receiver_province,
            'จำนวน(ชิ้น)': value.num_parcel,
            'ค่าขนส่ง':parseFloat(value.shipment_cost).toLocaleString(undefined, { minimumFractionDigits: 2 }),
            'cod_cost' : parseFloat(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 }),
            'ค่าบริการCOD': parseFloat(value.service_cod).toLocaleString(undefined, { minimumFractionDigits: 2 }),
            'ยอดสุทธิ(บาท)': (parseFloat(value.shipment_cost) + parseFloat(value.service_cod)).toLocaleString(undefined, { minimumFractionDigits: 2 }),
            'bank_id' : value.bank_id,
            'bank_name' : value.bookbank_name,
            'bank_code' : value.bookbank_code,
            'สถานะ': value.shipment_name,
            'latest_scan': value.transportWorker,
            'scan_name': value.scanner_name,
            'หมายเหตุ': value.remarkMG,
            'item_sku': value.item_sku,
            'item_desc': value.item_desc,
            'invoice': value.invoice,
            'return_doc': value.num_return_doc,
            'weight': value.weight,
            'box_size': value.box_size,
            'box_width': value.box_width,
            'box_length': value.box_length,
            'box_height': value.box_height,
        })) : undefined;
        console.log({
            result
        });
        return result;
    }, [exportData, exportType]);

    useEffect(() => {
        ( async () => {
            if (formattedExportData && exportType === EXPORT_WITH_PICS) {
                if (!excelObjectRef.current) {
                    const workBook = new ExcelJS.Workbook();
                    const worksheet = workBook.addWorksheet('จัดการพัสดุ');
                    excelObjectRef.current = {
                        workBook,
                        worksheet,
                    };
                    worksheet.columns = ExportWithoutPicsWorksheetConstants;
                }
                await handleImageFetch(excelObjectRef.current.workBook,excelObjectRef.current.worksheet,formattedExportData, setExportProgression);
                setExportProgression((state) => ({
                    ...state,
                    currentPage: state.currentPage + 1,
                }));
                if (parseInt(exportData.pageNo) !== exportData.maxPage) {
                    void fetchExportData(parseInt(exportData.pageNo) + 1, filters, false, EXPORT_PIC_PER_PAGES);
                }
                else {
                    setExportProgression((state) => ({
                        ...state,
                        currentPage: state.totalPages,
                    }));
                    const result = await excelObjectRef.current.workBook.xlsx.writeBuffer();
                    const blob = new Blob([result], { type: "application/xlsx" });
                    flush();
                    saveAs(blob, `จัดการพัสดุ.xlsx`);
                    setIsExporting(false);
                }
        }
        })();
    }, [formattedExportData, exportType]);

  const fetchData = (targetPage, targetFilters) => {
      setPage(targetPage);
      void fetch(targetPage,targetFilters)
  };

    useEffect(() => {
        fetchData(1, filters)
    }, []);


  const {t} = useTranslation();

  const  isLoading = loading || ExportDataLoading || historyLoading;

  const leftContent = (
    <>
      <HubPresentation />
      <HubPageTitle>{t('hub.status.title')}</HubPageTitle>
      {isLoading &&
          <div className="spinner-global-content">
            <Spinner variant="success" animation="border" />
          </div>
      }
      {historyData && <ModalsHistory modalHis={modalHis} setModalHis={setModalHis} historyData={historyData} />}
      {isExporting && <ExportProgress status={getExportStatus(exportData)} progression={exportProgression} />}
      {!isLoading &&
          (
            <>
                <ParcelStatusFilters
                    initialFilters={filters}
                    setFilters={(filters) => {
                        setFilters(filters);
                        fetchData(1, filters);
                    }}
                />
                {
                    parcelData && ( <ParcelsStatusDisplay
                            exportData={exportType === EXPORT_WITHOUT_PICS && formattedExportData}
                            onPageChange={(targetPage) => void fetch(targetPage, filters)}
                            onExport={() => {
                                setExportType(EXPORT_WITHOUT_PICS);
                                void fetchExportData(1, filters, true);
                            }}
                            onExportWithPic={() => {
                                setIsExporting(true);
                                setExportType(EXPORT_WITH_PICS);
                                void fetchExportData(1, filters, false, EXPORT_PIC_PER_PAGES);
                            }}
                            onHistoryDisplay={History}
                            data={parcelData}
                        />
                    )
                }
            </>
          )
      }
    </>
  );

  return (
    <>
      <HubBodyLayout leftContent={leftContent}/>
    </>
  )
}
export default HubStatus;
