import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import {useTranslation} from "react-i18next";

export const BarcodeInput = ({
    value,
    setValue,
    placeholder,
    submit,
}) => {
    const {t} = useTranslation();
    return (
        <div className='row'>
            <div className='col-12 col-md-9'>
                <input
                    className="form-control w-100 mb-3"
                    value={value}
                    placeholder={placeholder}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                />
            </div>
            <div className='col-12 col-md-3'>
                <button disabled={value === ""} onClick={() => submit()} className={'btn btn-primary w-100'}>
                    <Icon.Save /> {t('common.save')}
                </button>
            </div>
      </div>
  )
}
export default BarcodeInput;