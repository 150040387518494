import React, { Fragment } from 'react';
import DateTH from '../../../Helper/date-thV2';
import Asset from '../../../Assets/images/logo/Asset.png';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Carousel from "react-bootstrap/Carousel";
import { useState, useEffect } from "react";

function TrackingDetail({ value }) {
  function Date(params) {
    let values = value.porlor_staff_deliver.find((a) => a.shipment_name === params);
    return values !== undefined ? values.Receiver_Date + ' ' + values.Receiver_Time : '';
  }
  function Name(params) {
    let values = value.porlor_staff_deliver.find((a) => a.shipment_name === params);
    return values !== undefined ? values.name : '';
  }
  const [isReturned, setIsReturned] = useState(0);
  return (
    <>
      <div className="row">
        <div className="col-sm-4">
          <div className="rt-container container">
            <div className="col-rt-12">
              <div className="Scriptcontent">
                <Fragment>
                  <div className={'step-vt ' + (value.shipment_name === 'ขนส่งเข้ารับพัสดุฯ' ? 'step-active-vt' : '')}>
                    <div>
                      <div className="circle">1</div>
                    </div>
                    <div>
                      <div className="title text-heard">ขนส่งเข้ารับพัสดุฯ</div>
                      <div className="caption text-heard">
                        <DateTH date={Date('ขนส่งเข้ารับพัสดุฯ') === '' ? value.stockin_date : Date('ขนส่งเข้ารับพัสดุฯ')} />
                      </div>
                      <div className={Name('ขนส่งเข้ารับพัสดุฯ') !== '' && Name('ขนส่งเข้ารับพัสดุฯ') !== null ? 'caption text-heard' : 'hidden-ct'}>{'>> ' + Name('ขนส่งเข้ารับพัสดุฯ')}</div>
                    </div>
                  </div>
                  { value.shipment_name === 'ศูนย์กระจายคัดแยกสินค้า' || value.shipment_name === 'อยู่ระหว่างขนส่ง' 
                  || value.shipment_name === 'พัสดุถูกส่งกลับต้นทาง' || value.shipment_name === "ลูกค้าปฏิเสธรับ"
                  || value.shipment_name === "ติดต่อผู้รับไม่ได้" || value.shipment_name === "ลูกค้าเลื่อนรับ"
                  || value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ||
                  value.shipment_name === 'รับแล้ว' || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว"? (
                    <div className={'step-vt ' + (value.shipment_name === 'ศูนย์กระจายคัดแยกสินค้า' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">2</div>
                      </div>
                      <div>
                        <div className="title text-heard">ศูนย์กระจายคัดแยกสินค้า</div>
                        <div className={Date('ศูนย์กระจายคัดแยกสินค้า') === '' ? 'hidden-ct' : 'caption text-heard'}>
                          <DateTH date={Date('ศูนย์กระจายคัดแยกสินค้า')} />
                        </div>
                        <div className={Name('ศูนย์กระจายคัดแยกสินค้า') !== '' && Name('ศูนย์กระจายคัดแยกสินค้า') !== null ? 'caption text-heard' : 'hidden-ct'}>{'>> ' + Name('ศูนย์กระจายคัดแยกสินค้า')}</div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {value.shipment_name === 'อยู่ระหว่างขนส่ง' || value.shipment_name === 'พัสดุถูกส่งกลับต้นทาง' 
                  || value.shipment_name === "ติดต่อผู้รับไม่ได้" || value.shipment_name === "ลูกค้าเลื่อนรับ"
                  || value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" || value.shipment_name === "ลูกค้าปฏิเสธรับ" ||
                  value.shipment_name === 'รับแล้ว' || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว" ? (
                    <div className={'step-vt ' + (value.shipment_name === 'อยู่ระหว่างขนส่ง' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">3</div>
                      </div>
                      <div>
                        <div className="title text-heard">อยู่ระหว่างขนส่ง</div>
                        <div className={Date('อยู่ระหว่างขนส่ง') === '' ? 'hidden-ct mt-5' : 'caption text-heard'}>
                          <DateTH date={Date('อยู่ระหว่างขนส่ง')} />
                        </div>
                        <div className={Name('อยู่ระหว่างขนส่ง') !== '' && Name('อยู่ระหว่างขนส่ง') !== null ? 'caption text-heard' : 'hidden-ct'}>{'>> ' + Name('อยู่ระหว่างขนส่ง')}</div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {value.shipment_name === 'พัสดุถูกส่งกลับต้นทาง' || Date('พัสดุถูกส่งกลับต้นทาง') !== '' ? (
                    <div className={'step-vt ' + (value.shipment_name === 'อยู่ระหว่างขนส่ง' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">X</div>
                      </div>
                      <div>
                        <div className="title text-heard">พัสดุถูกส่งกลับต้นทาง</div>
                        <div className={Date('พัสดุถูกส่งกลับต้นทาง') === '' ? 'hidden-ct mt-5' : 'caption text-heard'}>
                          <DateTH date={Date('พัสดุถูกส่งกลับต้นทาง')} />
                        </div>
                        <div className={Name('พัสดุถูกส่งกลับต้นทาง') !== '' && Name('พัสดุถูกส่งกลับต้นทาง') !== null ? 'caption text-heard' : 'hidden-ct'}>{'>> ' + Name('พัสดุถูกส่งกลับต้นทาง')}</div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {value.shipment_name === "ติดต่อผู้รับไม่ได้" || Date('ติดต่อผู้รับไม่ได้') !== '' ? (
                    <div className={'step-vt ' + (value.shipment_name === 'อยู่ระหว่างขนส่ง' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">X</div>
                      </div>
                      <div>
                        <div className="title text-heard">ติดต่อผู้รับไม่ได้</div>
                        <div className={Date('ติดต่อผู้รับไม่ได้') === '' ? 'hidden-ct mt-5' : 'caption text-heard'}>
                          <DateTH date={Date('ติดต่อผู้รับไม่ได้')} />
                        </div>
                        <div className={Name('ติดต่อผู้รับไม่ได้') !== '' && Name('ติดต่อผู้รับไม่ได้') !== null ? 'caption text-heard' : 'hidden-ct'}>{'>> ' + Name('ติดต่อผู้รับไม่ได้')}</div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" || Date('ที่อยู่/เบอร์โทรไม่ถูกต้อง') !== '' ? (
                    <div className={'step-vt ' + (value.shipment_name === 'ที่อยู่/เบอร์โทรไม่ถูกต้อง' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">X</div>
                      </div>
                      <div>
                        <div className="title text-heard">ที่อยู่/เบอร์โทรไม่ถูกต้อง</div>
                        <div className={Date('ที่อยู่/เบอร์โทรไม่ถูกต้อง') === '' ? 'hidden-ct mt-5' : 'caption text-heard'}>
                          <DateTH date={Date('ที่อยู่/เบอร์โทรไม่ถูกต้อง')} />
                        </div>
                        <div className={Name('ที่อยู่/เบอร์โทรไม่ถูกต้อง') !== '' && Name('ที่อยู่/เบอร์โทรไม่ถูกต้อง') !== null
                         ? 'caption text-heard' : 'hidden-ct'}>{'>> ' + Name('ติดต่อผู้รับไม่ได้')}</div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {value.shipment_name === "ลูกค้าปฏิเสธรับ" || Date('ลูกค้าปฏิเสธรับ') !== '' ? (
                    <div className={'step-vt ' + (value.shipment_name === 'ลูกค้าปฏิเสธรับ' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">X</div>
                      </div>
                      <div>
                        <div className="title text-heard">ลูกค้าปฏิเสธรับ</div>
                        <div className={Date('ลูกค้าปฏิเสธรับ') === '' ? 'hidden-ct mt-5' : 'caption text-heard'}>
                          <DateTH date={Date('ลูกค้าปฏิเสธรับ')} />
                        </div>
                        <div className={Name('ลูกค้าปฏิเสธรับ') !== '' && Name('ลูกค้าปฏิเสธรับ') !== null
                         ? 'caption text-heard' : 'hidden-ct'}>{'>> ' + Name('ลูกค้าปฏิเสธรับ')}</div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {value.shipment_name === "ลูกค้าเลื่อนรับ" || Date('ลูกค้าเลื่อนรับ') !== '' ? (
                    <div className={'step-vt ' + (value.shipment_name === 'ลูกค้าเลื่อนรับ' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">X</div>
                      </div>
                      <div>
                        <div className="title text-heard">ลูกค้าเลื่อนรับ</div>
                        <div className={Date('ลูกค้าเลื่อนรับ') === '' ? 'hidden-ct mt-5' : 'caption text-heard'}>
                          <DateTH date={Date('ลูกค้าเลื่อนรับ')} />
                        </div>
                        <div className={Name('ลูกค้าเลื่อนรับ') !== '' && Name('ลูกค้าเลื่อนรับ') !== null
                         ? 'caption text-heard' : 'hidden-ct'}>{'>> ' + Name('ลูกค้าเลื่อนรับ')}</div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {value.shipment_name === 'รับแล้ว' ? (
                    <div className={'step-vt ' + (value.shipment_name === 'รับแล้ว' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">4</div>
                      </div>
                      <div>
                        <div className="title text-heard">รับแล้ว </div>
                        <div className={Date('รับแล้ว') === '' ? 'hidden-ct mt-5' : 'caption text-heard'}>
                          <DateTH date={value.receiver_date} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  { value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว" ? (
                    <div className={'step-vt ' + (value.shipment_name === 'ผู้ส่งรับพัสดุฯแล้ว' ? 'step-active-vt' : '')}>
                      <div>
                        <div className="circle">4</div>
                      </div>
                      <div>
                        <div className="title text-heard">ผู้ส่งรับพัสดุฯแล้ว </div>
                        <div className={Date('ผู้ส่งรับพัสดุฯแล้ว') === '' ? 'hidden-ct mt-5' : 'caption text-heard'}>
                          <DateTH date={value.receiver_date} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </Fragment>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className={value.shipment_name === 'รับแล้ว' || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว" ? 'img-tack' : 'hidden-ct'}>
            <Zoom>
              <img src={value.image} width="150" height="100" alt={value.image} />
            </Zoom>
          </div>
        </div>
        {/* <div className="col-sm-4">
          <div className={value.shipment_name === 'ติดต่อผู้รับไม่ได้' ? 'img-tack' : 'hidden-ct'}>
          {value.deliver_fail_img.map((value1, index) => (
                <Carousel>
                    {value1.image.map((valueImg,indexImg)=> (
                        <Carousel.Item key={indexImg}>
                            <img src={valueImg} height="250" alt={valueImg} />
                        </Carousel.Item>
                    ))}
                </Carousel>
                ))}
            
          </div> */}
        {/* </div> */}
        <div className="col-sm-4">
          <div className="img-background">
            <img src={Asset} width="200" height="300" alt={value.image} />
          </div>
        </div>
      </div>
    </>
  );
}

export default TrackingDetail;
