import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import LongDateTH from "../../../utility/LongDateTH";
import * as Icon from "react-bootstrap-icons";

function showData({
  data,
  page,
  loading,
  errorMassNoData,
  pageNo,
  handleChangePage,
  perPage,
  Del,
  swal,
  Reprint,
  handleShow,
  handleShow2,
  setReceiptCode,
  setRemarkError,
  setFreightPrice,
}) {
  return (
    <>
      <div style={{ overflow: "Auto" }}>
        <table className="table table-bordered">
          <thead className="table-blue">
            <tr>
              <th>ลำดับ</th>
              <th>รหัสร้านค้า</th>
              <th>หมายเลขใบเสร็จ</th>
              <th>ชื่อผู้ส่ง</th>
              <th>ราคารวม (บาท)</th>
              <th>วันที่พิมพ์</th>
              <th>จัดการ</th>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={index}>
                <td>{(page.current_page - 1) * perPage + (index + 1)}</td>
                <td>{value.cust_code}</td>
                <td>{value.receipt_code}</td>
                <td>{value.sender_name}</td>
                <td className="text-center">{value.total}</td>
                <td>{LongDateTH(value.create_date)[0]}</td>
                <td>
                  <button
                    type="button"
                    className={value.status === "1" ? "btn btn-info" : "hidden-ct"}
                    onClick={() => {
                      // Reprint({receipt_code: value.receipt_code})
                      handleShow();
                      setReceiptCode(value.receipt_code);
                      setRemarkError(0);
                    }}
                  >
                    <Icon.Printer /> พิมพ์ใบเสร็จใหม่
                  </button>
                  <button
                    type="button"
                    className={value.status === "0" ? "btn btn-info" : "hidden-ct"}
                    onClick={() => {
                      // Reprint({receipt_code: value.receipt_code})
                      handleShow2();
                      setReceiptCode(value.receipt_code);
                      setRemarkError(0);
                      setFreightPrice(parseInt(value.total));
                    }}
                  >
                    <Icon.Printer /> พิมพ์ใบเสร็จ
                  </button>{" "}
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      swal({
                        className: "bg-modal-red",
                        icon: "warning",
                        dangerMode: true,
                        text: "คุณต้องการยกเลิกใบเสร็จนี้ใช่หรือไม่?",
                        buttons: {
                          cancel: "ยกเลิก",
                          confirm: {
                            text: "ใช่",
                            value: value.receipt_code,
                          },
                        },
                      }).then((value) => {
                        if (value !== null) {
                          Del({
                            receipt_code: value,
                          });
                        }
                      });
                    }}
                  >
                    ยกเลิกใบเสร็จ
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <center>
          {errorMassNoData === 1 && loading !== 1 ? (
            <font color="red" size="3">
              <br /> ไม่พบข้อมูล!{" "}
            </font>
          ) : (
            ""
          )}
        </center>
      </div>
      <center>
        {loading === 1 ? (
          <div className="m-5">
            <div className="text-center">
              <div className="spinner-border  text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              {" ..."}
              กำลังโหลดข้อมูล
            </div>
          </div>
        ) : (
          ""
        )}
      </center>
      <div className="d-flex justify-content-between">
        <div>จำนวนทั้งหมด {page.total} รายการ</div>
        <div>
          <Pagination
            count={parseInt(page.last_page)}
            page={pageNo}
            onChange={handleChangePage}
            color="primary"
            size="small"
            defaultPage={6}
            siblingCount={1}
          />
        </div>
      </div>
    </>
  );
}

export default showData;
