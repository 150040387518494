import HubCOD from "../../../Views/Hub/COD/hub-cod";
import HubGeneral from "../../../Views/Hub/General/hub-general";
import HubScanIn from "../../../Views/Hub/Scan_in/hub-scan-in";
import HubScanOut from "../../../Views/Hub/Scan_Out/hub-scan-out";
import HubStatus from "../../../Views/Hub/Status/hub-status";
import HubGeneralAssignment from "../../../Views/Hub/General/hub-general-assignment";
const HubMenuRoutes =  {
    'General': {
        path: 'General',
        text: 'hub.menu.general',
        protect: 1,
        element: HubGeneral,
        translate: true,
    },
    'GeneralAssignment': {
        path: 'General/:id',
        text: undefined,
        protect: 1,
        element: HubGeneralAssignment,
    },
    'COD': {
        path: 'COD',
        text: 'hub.menu.COD',
        protect: 1,
        element: HubCOD,
        translate: true,
    },
    'Status': {
        path: 'Status',
        text: 'hub.menu.status',
        protect: 1,
        element: HubStatus,
        translate: true,
    },
    'Scan In': {
        path: 'Scan_In',
        text: 'hub.menu.scan_in',
        protect: 1,
        element: HubScanIn,
        translate: true,
    },
    'Scan Out': {
        path: 'Scan_Out',
        text: 'hub.menu.scan_out',
        protect: 1,
        element: HubScanOut,
        translate: true,
    }
};

export default HubMenuRoutes;