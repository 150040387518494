import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { shipment_status } from '../../../Data/shipment_status';
import { Formik, Form, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup';

function ModalsStatus({ data, printLabelSingle, dataSelect, UpdateBarcode, UpdateCOD, modalStatus, setModalStatus }) {
  return (
    <>
      {/* <-------------------------------------------------อัพเดทสถานะพัสดุหลายรายการ---------------------------------------------> */}
      <Modal
        show={modalStatus}
        onHide={() => {
          setModalStatus(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>อัพเดทสถานะพัสดุหลายรายการ</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '19px' }}>
          <Formik
            initialValues={{
              shipment_status: '',
            }}
            enableReinitialize={true}
            onSubmit={(value) => {
              if (value.shipment_status !== '' && dataSelect.length !== 0) {
                var i;
                for (i = 0; i < dataSelect.length; i++) {
                  dataSelect[i].shipment_status = value.shipment_status;
                }
                UpdateBarcode(dataSelect);
                setModalStatus(false);
              }
            }}
          >
            {({ errors, touched, handleBlur, setFieldValue, values }) => (
              <Form>
                <div className="form-check">
                  <input
                    style={{ width: '20px', height: '20px' }}
                    value={'C'}
                    onChange={(e) => {
                      setFieldValue('shipment_status', e.target.value);
                    }}
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefaultC"
                    disabled = {localStorage.getItem("superkey") ==="operator"}
                  />
                  &nbsp; &nbsp;
                  <label className="form-check-label" htmlFor="flexRadioDefaultC">
                    1. ยกเลิก
                  </label>
                </div>
                {/* <div className="form-check">
                      <input
                        checked={values.shipment_status === '0' ? true : false}
                        style={{ width: '20px', height: '20px' }}
                        value={'0'}
                        onChange={(e) => {
                          setFieldValue('shipment_status', e.target.value);
                        }}
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefaultC"
                        // disabled={printLabelSingle.shipment_status === "5"}
                        disabled = {localStorage.getItem("superkey") ==="operator"}
                      />
                      &nbsp; &nbsp;
                      <label className="form-check-label" htmlFor="flexRadioDefaultC" >
                        0. back to 0
                      </label>
                    </div> */}
                {shipment_status.map((item, index) => (
                  <div className="form-check" key={index}>
                    <input
                      style={{ width: '20px', height: '20px' }}
                      value={item.id}
                      onChange={(e) => {
                        setFieldValue('shipment_status', e.target.value);
                      }}
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id={'flexRadioDefault' + index}
                      disabled = {localStorage.getItem("superkey") ==="operator"}
                    />
                    &nbsp; &nbsp;
                    <label className="form-check-label" htmlFor={'flexRadioDefault' + index}>
                      {index + 2  + '. ' + item.StatusName}
                    </label>
                  </div>
                ))}
                <hr />
                <div align="right">
                  <button
                    type="button"
                    className="btn btn-danger ml-1"
                    onClick={(e) => {
                      setModalStatus(false);
                    }}
                  >
                    ยกเลิก
                  </button>
                  <button type="submit" className={'btn btn-primary ml-1'} onClick={(e) => {}}>
                    บันทึก
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalsStatus;
