import Instance from "../Helper/axios";
import { URL, URLTEST, urlLocal } from "../Helper/baseURL";

export async function GetRecoveryBarcode(param) {
  try {
    const formData = new FormData();
    Object.entries(param).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await Instance.post(URL + "trackingReport/TrackingReport/getRecoveryBarcode", formData);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function GetDeliverFailImg(awb_code) {
  try {
    const response = await Instance.post(URL + "trackingReport/TrackingReport/deliverFailImg", {
      awb_code: awb_code,
    });
    return await response.data;
  } catch (error) {}
}

export async function GetDeliverImg(awb_code) {
  try {
    const response = await Instance.post(URL + "trackingReport/TrackingReport/porlorStaffDeliverImages", {
      awb_code: awb_code,
    });
    return await response.data;
  } catch (error) {}
}
