import { useState } from "react"
import { uploadProofImages } from "../../../../Service/service.Barcode";

export default function useProofImagesUpload() {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const upload = async (files, code) => {
    setLoading(true);
    setError(null);
    setMessage(null);
    try{
        const result = await uploadProofImages(files, code, (progressEvent) => {
            setProgress(progressEvent.loaded * 100 / progressEvent.total);
        });
        setMessage(result.data.message);
    }   
    catch (error) {
        console.log(error);
        if (error.response?.data?.message) {
            setError(error.response.data.message);
        } else {
            setError('เกิดข้อผิดพลาด');
        }
    }
    finally {
        setLoading(false);
    }
  };
  return {
        loading,
        progress,
        error,
        upload,
        message
  }
}
