import React from "react";
import * as Icon from "react-bootstrap-icons";
import TrackingDetail from "./TrackingDetail";
import DateTH from "../../../Helper/date-thV2";

function ShowData({ data }) {

  function Date(value,params) {
    let values = value.porlor_staff_deliver.find((a) => a.shipment_name === params);
    return values !== undefined ? values.Receiver_Date + ' ' + values.Receiver_Time : '';
  }

  function step1(value) {
    let step = (value.shipment_name === 'ขนส่งเข้ารับพัสดุฯ' || 
    value.shipment_name === "รับเข้าระบบ" ||
      value.shipment_name === "ศูนย์กระจายคัดแยกสินค้า" ||
      value.shipment_name === "อยู่ระหว่างขนส่ง" ||
      value.shipment_name === "ติดต่อผู้รับไม่ได้" ||
      value.shipment_name === "รับแล้ว" ||  
      value.shipment_name === "พัสดุถูกส่งกลับต้นทาง" ||
      value.shipment_name === "ติดต่อผู้รับไม่ได้" ||
      value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ||
      value.shipment_name === "ลูกค้าปฏิเสธรับ" || value.shipment_name === "ลูกค้าเลื่อนรับ"
      || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step2(value) {
    let step = (value.shipment_name === "ศูนย์กระจายคัดแยกสินค้า" ||
      value.shipment_name === "อยู่ระหว่างขนส่ง" ||
      value.shipment_name === "ติดต่อผู้รับไม่ได้" ||
      value.shipment_name === "รับแล้ว" ||
      value.shipment_name === "พัสดุถูกส่งกลับต้นทาง" ||
      value.shipment_name === "ติดต่อผู้รับไม่ได้" ||
      value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ||
      value.shipment_name === "ลูกค้าปฏิเสธรับ" || value.shipment_name === "ลูกค้าเลื่อนรับ"
      || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3(value) {
    let step = (value.shipment_name === "อยู่ระหว่างขนส่ง" ||
      value.shipment_name === "รับแล้ว"||
      value.shipment_name === "พัสดุถูกส่งกลับต้นทาง" ||
      value.shipment_name === "ติดต่อผู้รับไม่ได้" ||
      value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ||
      value.shipment_name === "ลูกค้าปฏิเสธรับ" || value.shipment_name === "ลูกค้าเลื่อนรับ"
      || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3Returned(value) {
    let step = (
      value.shipment_name === "รับแล้ว" ||
      value.shipment_name === "พัสดุถูกส่งกลับต้นทาง"
      || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3FailedCall(value) {
    let step = (value.shipment_name === "ติดต่อผู้รับไม่ได้" ||
      value.shipment_name === "รับแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3InvalidAddress(value) {
    let step = (value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ||
      value.shipment_name === "รับแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3Refused(value) {
    let step = (value.shipment_name === "ลูกค้าปฏิเสธรับ" ||
      value.shipment_name === "รับแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3Postponed(value) {
    let step = (value.shipment_name === "ลูกค้าปฏิเสธรับ" ||
      value.shipment_name === "รับแล้ว"  || value.shipment_name === "ลูกค้าเลื่อนรับ"
      ? " completed"
      : "")
    return step;
  }

  function step5(value) {
    let step = (value.shipment_name === "รับแล้ว" || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว" ? " completed" : "")
    return step;
  }

  return (
    <>
      {data.map((value, index) => (
        <div className="container padding-bottom-3x mb-3" key={"detail" + index}>
          <div className="text-title-color">
            <h3>
              <Icon.Box /> # {value.awb_code === "" || value.awb_code === null ? value.ref_no : value.awb_code}
            </h3>
          </div>
          <div className="row container text-heard">
            <div className="col-lg-6">
              <b>ผู้ส่ง : </b>
              <span>{value.sender_name}</span>
            </div>
            <div className="col-lg-6">
              <b>ที่อยู่ผู้ส่ง : </b>
              <span>{value.sender_address === "" ? "-" : value.sender_address}</span>
            </div>
            <div className="col-lg-6">
              <b>ผู้รับ : </b>
              <span>{value.receiver_name}</span>
            </div>
            <div className="col-lg-6">
              <b>ที่อยู่ผู้รับ : </b>
              <span>{value.receiver_address}</span>
            </div>
            <div className="col-lg-6">
              <b>จำนวนพัสดุ : </b>
              <span>{value.num_parcel}</span>
              <b> ชิ้น</b>
            </div>
            <div className="col-lg-6">
              <b>น้ำหนัก : </b>
              <span>{value.weight}</span>
              <b> Kg.</b>
            </div>
            <div className="col-lg-6">
              <b>ประเภทสินค้า : </b>
              <span>{"กล่องพัสดุ"}</span>
            </div>
            <div className="col-lg-6">
              <b>COD : </b>
              <span>{parseInt(value.cod).toLocaleString("en")}</span>
              <b> บาท</b>
            </div>
            <div className="col-lg-6">
              <b>วันที่เปิดบิล : </b>
              <span><DateTH date={value.created_date} /></span>
            </div>
            <div className="col-lg-6">
              <b>สำหรับลูกค้า / เลขที่co note : </b>
              <span>{value.ref_no}</span>
            </div>
          </div>
          <hr style={{ borderBottom: "3px solid #d8d8d8" }} />
          <br />
          <br />
          <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
            <div
              className={"step " + step1(value) + (value.shipment_name === "ขนส่งเข้ารับพัสดุฯ" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.Clipboard size="70%" />
                </div>
              </div>
              <h4 className="step-title">ขนส่งเข้ารับพัสดุฯ</h4>
            </div>
            <div className={"line-ct" + step2(value)}>&nbsp;</div>
            <div
              className={"step " + step2(value) + (value.shipment_name === "ศูนย์กระจายคัดแยกสินค้า" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.Box size="70%" />
                </div>
              </div>
              <h4 className="step-title">ศูนย์กระจายคัดแยกสินค้า</h4>
            </div>
            <div className={"line-ct" + step3(value)}>&nbsp;</div>
            <div
              className={"step " + step3(value) + (value.shipment_name === "อยู่ระหว่างขนส่ง" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.Truck size="70%" />
                </div>
              </div>
              <h4 className="step-title">อยู่ระหว่างขนส่ง</h4>
            </div>
            {value.shipment_name === "พัสดุถูกส่งกลับต้นทาง" || Date(value,'พัสดุถูกส่งกลับต้นทาง') !== '' ? (
              <div
              className={"step " + step3Returned(value) + (value.shipment_name === "พัสดุถูกส่งกลับต้นทาง" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.Person size="70%" />
                </div>
              </div>
              <h4 className="step-title">พัสดุถูกส่งกลับต้นทาง</h4>
            </div>    
            ) : "" }
            {value.shipment_name === "ติดต่อผู้รับไม่ได้" || Date(value,'ติดต่อผู้รับไม่ได้') !== '' ? (
              <div
              className={"step " + step3FailedCall(value) + (value.shipment_name === "อยู่ระหว่างขนส่ง" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.PhoneVibrate size="70%" />
                </div>
              </div>
              <h4 className="step-title">ติดต่อผู้รับไม่ได้</h4>
            </div>    
            ) : "" }
            {value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" || Date(value,'ที่อยู่/เบอร์โทรไม่ถูกต้อง') !== '' ? (
              <div
              className={"step " + step3InvalidAddress(value) + (value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.PersonX size="70%" />
                </div>
              </div>
              <h4 className="step-title">ที่อยู่/เบอร์โทรไม่ถูกต้อง</h4>
            </div>    
            ) : "" }
            {value.shipment_name === "ลูกค้าปฏิเสธรับ" || Date(value,'ลูกค้าปฏิเสธรับ') !== '' ? (
              <div
              className={"step " + step3Refused(value) + (value.shipment_name === "ลูกค้าปฏิเสธรับ" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.PersonX size="70%" />
                </div>
              </div>
              <h4 className="step-title">ลูกค้าปฏิเสธรับ</h4>
            </div>    
            ) : "" }
            {value.shipment_name === "ลูกค้าเลื่อนรับ" || Date(value,'ลูกค้าเลื่อนรับ') !== '' ? (
              <div
              className={"step " + step3Postponed(value) + (value.shipment_name === "ลูกค้าเลื่อนรับ" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.PersonX size="70%" />
                </div>
              </div>
              <h4 className="step-title">ลูกค้าเลื่อนรับ</h4>
            </div>    
            ) : "" }
            <div className={"line-ct" + step5(value)}>&nbsp;</div>
            <div className={"step " + step5(value) + (value.shipment_name === "รับแล้ว" ? " now" : "")}  >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.House size="70%" />
                </div>
              </div>
              <h4 className="step-title">รับแล้ว</h4>
            </div>
          </div>
          <br />
          <TrackingDetail value={value} />
        </div>
      ))}
      <br />
    </>
  );
}

export default ShowData;
