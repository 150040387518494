import React from 'react'
import { getIn } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

function FormReactSelect({ touched, errors, data, values, onChange, name, labelKey, keyValue, errorsMess, placeholder }) {


    return (
        <div>
            <Typeahead
                isInvalid={(getIn(touched, name)
                    ? getIn(errors, name)
                        ? true
                        : false
                    : "")}
                isValid={(getIn(touched, name)
                    ? getIn(errors, name)
                        ? false
                        : true
                    : "")}
                defaultSelected={data.filter(a => a[keyValue] === values)}
                id={name}
                name={name}
                labelKey={labelKey}
                onChange={(e) => {
                    if (e.length > 0) {
                        let value = e[0][keyValue]
                        onChange(value);
                    }
                }}
                onInputChange={(text, e) => { onChange(text); }}
                options={data}
                placeholder={placeholder}
                selected={values === "" ? [] : data.filter(a => a[keyValue] === values)}
            />
            <div
                className={
                    (getIn(touched, name)
                        ? getIn(errors, name)
                            ? "error-invalid-feedback"
                            : "hidden-ct"
                        : "hidden-ct")
                }
            >
                {errorsMess}
            </div>
        </div>
    )
}

export default FormReactSelect
