import React, { Component } from "react";
import GenberCode from "../genberCode";
import emsLogoV2 from "../../../Assets/images/logo/emsLogoV2.png";

export default class FormatLabelPornor extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        style={{
          fontFamily: "Kanit",
          fontSize: "13px",
          position: "relative",
          marginTop: "1px",
          marginBottom: "1px",
        }}
        className="page-break"
      >
        <div style={{ marginTop: "35px" }}>
          <div style={{ textAlign: "right", marginRight: "20px" }}>{this.props.supplies.cust_code}</div>
          <div style={{ border: "2px solid #000", textAlign: "center", marginLeft: "20px", marginRight: "20px" }}>
            <label style={{ fontSize: "10px" }}>บริการจัดส่งสินค้าเพื่อธุรกิจ e-Commerce (บริการ e-Parcel)</label>
            <br />
            <label>
              ใบอนุญาติสำหรับลูกค้าธุรกิจ <b>เลขที่ ขล. 18/2564</b>
            </label>
            <br />
            <label style={{ fontSize: "10px" }}>ชำระค่าบริการตามที่ ปณท กำหนด</label>
          </div>
          <div style={{ textAlign: "center", marginTop: "5px" }}>
            {parseInt(this.props.supplies.cod_cost) !== 0 ? (
              <b>EMS COD :&nbsp;&nbsp;{parseInt(this.props.supplies.cod_cost).toLocaleString()}&nbsp;&nbsp;บาท</b>
            ) : (
              ""
            )}
          </div>
          <div style={{ textAlign: "center" }}>
            <GenberCode awbCode={this.props.supplies.awb_code} />
          </div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              <b style={{ fontSize: "15px" }}>&nbsp;&nbsp;DROP OFF</b>
            </div>
            <div>
              <img alt="" src={emsLogoV2} width="120" height="30" className="d-inline-block align-top" />
              &nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
    );
  }
}
