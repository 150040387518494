import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import LongDateTH from "../../../utility/LongDateTH";
import { useHistory, useLocation } from "react-router-dom";
import { GetApplyDetail } from "../../../Service/service.Career.js";

export default function Detail() {
  const history = useHistory();
  const param = useLocation();
  const [applicants, setApplicants] = useState([]);
  const [family, setFamily] = useState([]);
  const [experienc, setExperienc] = useState([]);
  const [Language, setLanguage] = useState([]);
  const [loading, Loading] = useState(0);

  useEffect(() => {
    fetchData({
      code: param.state.code,
    });
  }, []);

  async function fetchData(data) {
    Loading(1);
    setApplicants([]);
    let res = await GetApplyDetail(data);
    if (res !== false) {
      setApplicants(res.applicants);
      setExperienc(res.app_experience);
      setFamily(res.app_family);
      setLanguage(res.app_language);
    } else {
      alert("เกิดข้อผิดพลาด");
    }
    Loading(0);
  }

  return (
    <div className="container" style={{ overflow: "Auto" }}>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb" style={{ backgroundColor: "#ffffff" }}>
          <li className="breadcrumb-item">
            <p
              type="button"
              className="btn-link"
              style={{ color: "#000000" }}
              onClick={(e) => {
                history.push("/mis/ApplyData");
              }}
            >
              ข้อมูลสมัครงาน
            </p>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            รายละเอียดข้อมูลสมัครงาน
          </li>
        </ol>
      </nav>
      <div align="right">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            var printContents =
              document.getElementById("contentPrint").innerHTML;
            var originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            window.location.reload();
          }}
        >
         <Icon.Printer/> พิมพ์
        </button>
      </div>
      <br />

      {applicants.map((value, index) => (
        <div class="card page-A4" id="contentPrint">
          <div
            class="card-body"
            // type="button"
            style={{
              marginTop: "70px",
              marginBottom: "70px",
              marginLeft: "60px",
              marginRight: "60px",
            }}
            // onClick={() => {
            //   history.push("/mis/EditAndDetail", {
            //     code: value.code,
            //     applicants: value,
            //     value: value,
            //     family: family,
            //     experienc: experienc,
            //     Language: Language,
            //     path: param.pathname,
            //     text: "รายละเอียดข้อมูลสมัครงาน",
            //   });
            // }}
          >
            <div className="text-center">
              <h5>APPLICATION FOR EMPLOYMENT</h5>
              <h5>ใบสมัครงาน</h5>
            </div>
            <br />
            <br />
            <div>
              <div className="row">
                <div className="col-sm-12 px-md-3 mt-1">
                  <b>ชื่อ :</b>
                  <span className="mg-30-ct">
                    {value.title + value.name + " " + value.surname}
                  </span>
                </div>
              </div>

              <div className="row mgTop-ct">
                <div className="col-sm-6 px-md-3 mt-1">
                  <b>ตำแหน่งที่สมัคร :</b>
                  <span className="mg-30-ct">{value.positionName}</span>
                </div>
                <div className="col-sm-6 px-md-3 mt-1">
                  <b>เงินเดือนที่คาดหวัง :</b>
                  <span className="mg-30-ct">{value.salary}</span>
                  <b>บาท/เดือน</b>
                </div>
              </div>

              <br />
              <div className="mgTop-ct">
                <h6>
                  <b> Personal information (ประวัติส่วนตัว)</b>
                </h6>
              </div>

              <div class="card">
                <div class="card-body">
                  <div className="row mgTop-ct">
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>ที่อยู่ปัจจุบันเลขที่ :</b>
                      <span className="mg-30-ct">{value.house_no}</span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>ถนน :</b>
                      <span className="mg-30-ct">{value.Road}</span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>ตำบล/แขวง :</b>
                      <span className="mgl-30-ct">{value.sub_district}</span>
                    </div>
                  </div>

                  <div className="row mgTop-ct">
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>อำเภอ/เขต :</b>
                      <span className="mg-30-ct">{value.district}</span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>จังหวัด :</b>
                      <span className="mg-30-ct">{value.province}</span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>รหัสไปรษณีย์ :</b>
                      <span className="mgl-30-ct">{value.postal_code}</span>
                    </div>
                  </div>

                  <div className="row mgTop-ct">
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>โทรศัพท์ :</b>
                      <span className="mg-30-ct">{value.phone_number}</span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>ไลน์ไอดี :</b>
                      <span className="mg-30-ct">{value.line_id}</span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>อีเมล :</b>
                      <span className="mgl-30-ct">{value.email}</span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mgTopButton-ct">
                    <div>
                      <b>การเป็นอยู่อาศัย :</b>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          value.habitation === "อาศัยกับครอบครัว" ? true : false
                        }
                      />
                      <span class="form-check-label">อาศัยกับครอบครัว</span>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          value.habitation === "บ้านตัวเอง" ? true : false
                        }
                      />
                      <span class="form-check-label">บ้านตัวเอง</span>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={value.habitation === "บ้านเช่า" ? true : false}
                      />
                      <span class="form-check-label">บ้านเช่า</span>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={value.habitation === "หอพัก" ? true : false}
                      />
                      <span class="form-check-label">หอพัก</span>
                    </div>
                  </div>

                  <div className="row mgTop-ct">
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>วันเกิด :</b>
                      <span className="mg-30-ct">
                        {LongDateTH(value.birthday)[0]}
                      </span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>อายุ :</b>
                      <span className="mg-30-ct">{value.age}</span>
                      <b>ปี</b>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>บัตรประชาชน :</b>
                      <span className="mgl-30-ct">{value.citizen_id}</span>
                    </div>
                  </div>

                  <div className="row mgTop-ct">
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>เชื้อชาติ :</b>
                      <span className="mgl-30-ct">{value.ethnicity}</span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>สัญชาติ :</b>
                      <span className="mg-30-ct">{value.nationality}</span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>ศาสนา :</b>
                      <span className="mgl-30-ct">{value.religion}</span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mgTopButton-ct">
                    <div>
                      <b>ภาวะทางทหาร :</b>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          value.military_status === "ได้รับการยกเว้น"
                            ? true
                            : false
                        }
                      />
                      <span class="form-check-label">ได้รับการยกเว้น</span>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          value.military_status === "ปลดเป็นทหารกองหนุน"
                            ? true
                            : false
                        }
                      />
                      <span class="form-check-label">ปลดเป็นทหารกองหนุน</span>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          value.military_status === "ยังไม่ได้รับการเกณฑ์"
                            ? true
                            : false
                        }
                      />
                      <span class="form-check-label">ยังไม่ได้รับการเกณฑ์</span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mgTop-ct">
                    <div>
                      <b>สถานภาพ :</b>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={value.marital_status === "โสด" ? true : false}
                      />
                      <span class="form-check-label">โสด</span>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          value.marital_status === "แต่งงาน" ? true : false
                        }
                      />
                      <span class="form-check-label">แต่งงาน</span>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          value.marital_status === "หม้าย" ? true : false
                        }
                      />
                      <span class="form-check-label">หม้าย</span>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          value.marital_status === "หย่าล้าง" ? true : false
                        }
                      />
                      <span class="form-check-label">หย่าล้าง</span>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className="mgTop-ct">
                <h6>
                  <b> ประวัติครอบครัว (Family history)</b>
                </h6>
              </div>

              <div class="card">
                <div class="card-body">
                  <div className="row mgTop-ct">
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>บิดา ชื่อ :</b>
                      <span className="mg-30-ct">
                        {value.father_name + " " + value.father_surname}
                      </span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>อายุ :</b>
                      <span className="mg-30-ct">{value.father_age}</span>
                      <b>ปี</b>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>อาชีพ :</b>
                      <span className="mg-30-ct">
                        {value.father_occupation}
                      </span>
                    </div>
                  </div>

                  <div className="row mgTop-ct">
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>มารดา ชื่อ :</b>
                      <span className="mg-30-ct">
                        {value.mother_name + " " + value.mother_surname}
                      </span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>อายุ :</b>
                      <span className="mg-30-ct">{value.mother_age}</span>
                      <b>ปี</b>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>อาชีพ :</b>
                      <span className="mg-30-ct">
                        {value.mother_occupation}
                      </span>
                    </div>
                  </div>

                  <div className="row mgTop-ct">
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>ภรรยา-สามี ชื่อ :</b>
                      <span className="mg-30-ct">
                        {value.wife_husband_name +
                          " " +
                          value.wife_husband_surname}
                      </span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>สถานที่ทำงาน :</b>
                      <span className="mg-30-ct">
                        {value.wife_husband_workplace_name}
                      </span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>ตำแหน่ง :</b>
                      <span className="mg-30-ct">
                        {value.wife_husband_position}
                      </span>
                    </div>
                  </div>

                  <div className="row mgTop-ct">
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>พี่น้องรวมผู้สมัคร :</b>
                      <span className="mg-30-ct">{value.members_family}</span>
                      <b>คน</b>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>เป็นบุตรคนที่ :</b>
                      <span className="mg-30-ct">{value.my_number}</span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1"></div>
                  </div>

                  <div
                    className={
                      value.members_family > 1 ? "mgTop-ct" : "hidden-ct"
                    }
                  >
                    <b>ข้อมูลพี่น้อง</b>
                  </div>

                  {value.members_family > 1
                    ? family.map((valuefamily, index) => (
                        <div className="row mgTop-ct">
                          <div className="col-sm-4 px-md-3 mt-1">
                            {index + 1 + ". "}&nbsp;&nbsp;
                            {valuefamily.titleName +
                              valuefamily.name +
                              " " +
                              valuefamily.lastName}
                          </div>
                          <div className="col-sm-4 px-md-3 mt-1">
                            <b>อายุ :</b>
                            <span className="mg-30-ct">{valuefamily.age}</span>
                            <b>ปี</b>
                          </div>
                          <div className="col-sm-4 px-md-3 mt-1">
                            <b>อาชีพ :</b>
                            <span className="mg-30-ct">
                              {valuefamily.career}
                            </span>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
              </div>

              <br />
              <div className="mgTop-ct">
                <h6>
                  <b> การศึกษา (Education)</b>
                </h6>
              </div>

              <div class="card">
                <div class="card-body">
                  <div className="row mgTop-ct">
                    <div className="col-sm-6 px-md-3 mt-1">
                      <b>ระดับการศึกษาสูงสุด :</b>
                      <span className="mg-30-ct">
                        {value.educational_level_code}
                      </span>
                    </div>
                    <div className="col-sm-6 px-md-3 mt-1">
                      <b>สถาบันการศึกษา :</b>
                      <span className="mg-30-ct">{value.institution}</span>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className="mgTop-ct">
                <h6>
                  <b> ประสบการณ์ (Experience)</b>
                </h6>
              </div>

              <div class="card">
                <div class="card-body">
                  {experienc.map((experienc, index) => (
                    <div>
                      <div className="row mgTop-ct">
                        <div className="col-sm-4 px-md-3 mt-1">
                          {index + 1 + ". "}&nbsp;&nbsp;
                          {experienc.company}
                        </div>
                        <div className="col-sm-4 px-md-3 mt-1">
                          <b>ปี่ทำงาน :</b>
                          <span className="mg-30-ct">
                            {experienc.start_year + "-" + experienc.end_year}
                          </span>
                        </div>
                        <div className="col-sm-4 px-md-3 mt-1">
                          <b>ตำแหน่ง :</b>
                          <span className="mg-30-ct">{experienc.position}</span>
                        </div>
                      </div>
                      <div className="row mgTop-ct">
                        <div className="col-sm-4 px-md-3 mt-1">
                          <b>เงินเดือน :</b>
                          <span className="mg-30-ct">{experienc.salary}</span>
                        </div>
                        <div className="col-sm-8 px-md-3 mt-1">
                          <b>ลักษณะงาน :</b>
                          <span className="mg-30-ct">
                            {experienc.jobDescription}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <br />
              <div className="mgTop-ct">
                <h6>
                  <b> ภาษา (language)</b>
                </h6>
              </div>

              <div class="card">
                <div class="card-body">
                  {Language.map((Language, index) => (
                    <div className="row mgTop-ct">
                      <div className="col-sm-3 px-md-3 mt-1">
                        {index + 1 + ". "}&nbsp;&nbsp;
                        {Language.language}
                      </div>
                      <div className="col-sm-3 px-md-3 mt-1">
                        <b>การพูด :</b>
                        <span className="mg-30-ct">
                          {Language.speaking === "1"
                            ? "พอใช้"
                            : Language.speaking === "2"
                            ? "ปานกลาง"
                            : "ดี"}
                        </span>
                      </div>
                      <div className="col-sm-3 px-md-3 mt-1">
                        <b>การอ่าน :</b>
                        <span className="mg-30-ct">
                          {Language.read === "1"
                            ? "พอใช้"
                            : Language.read === "2"
                            ? "ปานกลาง"
                            : "ดี"}
                        </span>
                      </div>
                      <div className="col-sm-3 px-md-3 mt-1">
                        <b>การเขียน :</b>
                        <span className="mg-30-ct">
                          {Language.write === "1"
                            ? "พอใช้"
                            : Language.write === "2"
                            ? "ปานกลาง"
                            : "ดี"}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <br />
              <div className="mgTop-ct">
                <h6>
                  <b> ความสามารถ (Ability)</b>
                </h6>
              </div>

              <div class="card">
                <div class="card-body">
                  <div className="row mgTop-ct">
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>ใช้คอมพิวเตอร์ :</b>
                      <span className="mg-30-ct">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={value.use_computer === "1" ? true : false}
                          />
                          <span class="form-check-label">ได้</span>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={value.use_computer === "0" ? true : false}
                          />
                          <span class="form-check-label">ไม่ได้</span>
                        </div>
                      </span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>ขับรถยนต์ :</b>
                      <span className="mg-30-ct">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={value.driving === "1" ? true : false}
                          />
                          <span class="form-check-label">ได้</span>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={value.driving === "0" ? true : false}
                          />
                          <span class="form-check-label">ไม่ได้</span>
                        </div>
                      </span>
                    </div>
                    <div
                      className={
                        value.driving === "0"
                          ? "hidden-ct"
                          : "col-sm-4 px-md-3 mt-1"
                      }
                    >
                      <b>เลขที่ใบขับขี่ :</b>
                      <span className="mg-30-ct">
                        {value.driving_license_no}
                      </span>
                    </div>
                    <div
                      className={
                        value.driving === "0"
                          ? "col-sm-4 px-md-3 mt-1"
                          : "col-sm-4 px-md-3 mt-3"
                      }
                    >
                      <b>ปฏิบัติงานต่างจังหวัด :</b>
                      <span className="mg-30-ct">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={
                              value.work_upcountry === "1" ? true : false
                            }
                          />
                          <span class="form-check-label">ได้</span>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={
                              value.work_upcountry === "0" ? true : false
                            }
                          />
                          <span class="form-check-label">ไม่ได้</span>
                        </div>
                      </span>
                    </div>
                    <div
                      className={
                        value.otherAbilities !== ""
                          ? value.driving === "0"
                            ? "col-sm-12 px-md-3 mt-3"
                            : "col-sm-8 px-md-3 mt-3"
                          : "hidden-ct"
                      }
                    >
                      <b>ความสามารถด้านอื่นๆ :</b>
                      <span className="mg-30-ct">{value.otherAbilities}</span>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className="mgTop-ct">
                <h6>
                  <b>อื่น ๆ (Other)</b>
                </h6>
              </div>

              <div class="card">
                <div class="card-body">
                  <div className="row mgTop-ct">
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>ติดต่อฉุกเฉิน ชื่อ :</b>
                      <span className="mg-30-ct">
                        {value.emergency_contact +
                          " " +
                          value.emergency_contact_surname}
                      </span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>โทรศัพท์ :</b>
                      <span className="mg-30-ct">{value.emergency_phone}</span>
                    </div>
                    <div className="col-sm-4 px-md-3 mt-1">
                      <b>ความสัมพันธ์ :</b>
                      <span className="mg-30-ct">{value.relationship}</span>
                    </div>
                  </div>

                  <div className="row mgTop-ct">
                    <div
                      className={
                        value.contagious === "0"
                          ? "col-sm-12 px-md-3 mt-1"
                          : "col-sm-6 px-md-3 mt-1"
                      }
                    >
                      <b>ป่วยหนักหรือเป็นโรคติดต่อร้ายแรง :</b>
                      <span className="mg-30-ct">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={value.contagious === "1" ? true : false}
                          />
                          <span class="form-check-label">เคย</span>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={value.contagious === "0" ? true : false}
                          />
                          <span class="form-check-label">ไม่เคย</span>
                        </div>
                      </span>
                    </div>
                    <div
                      className={
                        value.contagious === "0"
                          ? "hidden-ct"
                          : "col-sm-6 px-md-3 mt-1"
                      }
                    >
                      <b>โรคหรืออาการป่วย :</b>
                      <span className="mg-30-ct">
                        {value.contagious_detail}
                      </span>
                    </div>
                    <div
                      className={
                        value.used_to_work === "0"
                          ? "col-sm-12 px-md-3 mt-3"
                          : "col-sm-4 px-md-3 mt-3"
                      }
                    >
                      <b>ทำงานกับบริษัทนี้มาก่อน :</b>
                      <span className="mg-30-ct">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={value.used_to_work === "1" ? true : false}
                          />
                          <span class="form-check-label">เคย</span>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={value.used_to_work === "0" ? true : false}
                          />
                          <span class="form-check-label">ไม่เคย</span>
                        </div>
                      </span>
                    </div>
                    <div
                      className={
                        value.used_to_work === "0"
                          ? "hidden-ct"
                          : "col-sm-4 px-md-3 mt-3"
                      }
                    >
                      <b>ปีที่ทำงาน :</b>
                      <span className="mg-30-ct">{value.years_work}</span>
                    </div>
                    <div
                      className={
                        value.used_to_work === "0"
                          ? "hidden-ct"
                          : "col-sm-4 px-md-3 mt-3"
                      }
                    >
                      <b>ตำแหน่ง :</b>
                      <span className="mg-30-ct">{value.position_work}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* ////////////////////////////////////////////////////////////// */}
            </div>
          </div>
        </div>
      ))}

      <center>
        <div className={loading === 1 ? "m-5" : "hidden-ct"}>
          <div className="text-center">
            <div className="spinner-border  text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            {" ..."}
            กำลังโหลดข้อมูล
          </div>
        </div>
      </center>
      <br />
    </div>
  );
}
