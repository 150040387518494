import Pagination from "@material-ui/lab/Pagination";
import DateTH from '../../../Helper/date-th';
import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import ReactExport from "react-data-export";
import PrintContent from "./printContent";
import printJS from "print-js";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ShowData({
  data,
  page,
  loading,
  errorMassNoData,
  pageNo,
  handleChangePage,
  perPage,
  fetchDataExport,
  user,
  shipmentStatus,
  startdate1,
  startdate2,
  startdate3,
  enddate1,
  enddate2,
  enddate3,
  exportData
}) {
  
  async function LoadDataExport(action) {
    await fetchDataExport({
      isManage: "",
      search: "",
      conote_status: shipmentStatus,
      start_date: startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
      end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
      page: pageNo,
      number_list: perPage,
      cust_code: user.username,
      cod_status: "",
      search_name: "",
      national_id: "",
      sender_phone: ""
    });
    if (action === "downloadEx")  {
      document.getElementById("downloadEx").click();
    } 
    else if (action === "downloadExShop") {
      document.getElementById("downloadExShop").click();
    }
    else {
      printJS({
        printable: "printReport",
        type: "html",
        scanStyles: false,
        css: [
          window.location.protocol+"//" + window.location.host + "/static/printerReport.css",
          "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
        ],
        style: "@page { size: A4 Landscape !important; margin: 2.5mm !important; } ",
      });
    }
  }
  return (
    <>
      <div className="d-flex justify-content-between mb-2">
          <div>
            <div style={{ display: "none" }}>
              <div id="printReport">
                <PrintContent
                  data={exportData}
                  branchs={user.username}
                  startDate={startdate1 + "-" + startdate2 + "-" + startdate3}
                  endDate={enddate1 + "-" + enddate2 + "-" + enddate3}
                />
              </div>
            </div>
            
            <ExcelFile
              element={
                <button style={{ display: "none" }} id="downloadEx">
                  Download Data
                </button>
              }
              filename="ทะเบียนคุมสินค้า"
            >
              <ExcelSheet data={exportData} name={"ทะเบียนคุมสินค้า"}>
                <ExcelColumn label="รหัส ID" value="cust_code" width={{ wpx: 80 }} />
                <ExcelColumn label="CONOTE" value="ref_no" />
                <ExcelColumn label="บาร์โค้ด/สำหรับลูกค้า" value="awb_codes" />
                <ExcelColumn label="เวลาเก็บเข้าระบบ" value={(row) => (row.conote_status==="1" || row.conote_status==='1'? "รับเข้าระบบแล้ว":"ไม่มีในระบบ")} />
                <ExcelColumn label="หมายเหตุ" value="date_conote_status" />
              </ExcelSheet>
            </ExcelFile>
            <button
              type="button"
              className="btn btn-info mr-2"
              onClick={() => {
                LoadDataExport("btnPrint");
              }}
            >
              <Icon.Printer /> พิมพ์
            </button>
            <button
              type="button"
              className="btn btn btn-warning"
              onClick={() => {
                
                  LoadDataExport("downloadEx")
                
              }}
            >
              <Icon.FileEarmarkArrowUp /> export
            </button>
          </div>
          <div>
            <>
              {/* <select
                value={pageSizes}
                className="form-control"
                aria-label="Default select example"
                onChange={(e) => {
                  setcurrentPage(1);
                  PageSizes(e.target.value);
                  loadData({
                    pageNo: 1,
                    perPage: e.target.value,
                    custCode: values.branch,
                    stockType: values.stockType,
                    startDate: values.startYear + "-" + values.startMonth + "-" + values.startDay,
                    startTime: values.startTime,
                    endDate: values.endYear + "-" + values.endMonth + "-" + values.endDay,
                    endTime: values.endTime,
                    transportWorker: values.transportWorker,
                    custType: values.category,
                    action: "show",
                    businessType: values.businessType,
                  });
                }}
              >
                <option value="5" key="5">
                  5
                </option>
                <option value="10" key="10">
                  10
                </option>
                <option value="20" key="20">
                  20
                </option>
                <option value="50" key="50">
                  50
                </option>
                <option value="100" key="100">
                  100
                </option>
              </select> */}
            </>
          </div>
        </div>
      <div style={{ overflow: "Auto" }}>
        <table className="table table-bordered">
          <thead className="table-blue">
            <tr>
                  <td>รหัสลูกค้า</td>
                  <td>CONOTE</td>
                  <td>บาร์โค้ด/สำหรับลูกค้า</td>
                  <td>เวลาเก็บเข้าระบบ</td>
                  <td></td>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={index}>
              
                    <td>{value.cust_code}</td>
                    <td>
                      <p>{value.ref_no === null || value.ref_no === '0' ? " " : value.ref_no}</p>
                      <p>{value.parcel_number} parcels</p>
                    </td>
                    <td>{value.array_awb_codes.map((value2)=> (
                      <p>{value2}</p>
                ))}</td>
                    <td>
                      {value.conote_status==="1" || value.conote_status==='1'?(
                        <p align="center" style={{ backgroundColor: "#51bf08" }}>รับเข้าระบบแล้ว</p>
                      ):(
                        <p align="center" style={{ backgroundColor: "yellow" }}>ไม่มีในระบบ</p>
                      ) }
                    </td>
                    <td>
                    {value.date_conote_status && value.date_conote_status !== '' ? (
                      <DateTH date={value.date_conote_status} />
                    ):""}
                      
                    </td>          
                
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <center>
          {errorMassNoData === 1 && loading !== 1 ? (
            <font color="red" size="3">
              {" "}
              ไม่พบข้อมูล!{" "}
            </font>
          ) : (
            ""
          )}
        </center>
      </div>
      <center>
        {loading === 1 ? (
          <div className="m-5">
            <div className="text-center">
              <div className="spinner-border  text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              {" ..."}
              กำลังโหลดข้อมูล
            </div>
          </div>
        ) : (
          ""
        )}
      </center>
      <div className="d-flex justify-content-between">
        <div>จำนวนทั้งหมด {page.total} รายการ</div>
        <div>
          <Pagination
            count={parseInt(page.last_page)}
            page={pageNo}
            onChange={handleChangePage}
            color="primary"
            size="small"
            defaultPage={6}
            siblingCount={1}
          />
        </div>
      </div>
      <br />
      <br />
    </>
  );
}
