import React, {useCallback, useEffect, useState} from "react";
import HubUsersSearch from "../components/hub-users-search";
import * as Icon from "react-bootstrap-icons";
import {useHistory} from "react-router-dom";
import HubUsersList from "../components/hub-users-list";
import useHubUserList from "../hooks/use-hub-user-list";
import useDidMountEffect from "../../../../Helper/use-did-mount-effect";
import Pagination from "@material-ui/lab/Pagination";
import useHubUserDelete from "../hooks/use-hub-user-delete";
import {swalError} from "../../../../Components/sweetAlert";

const pageSizes = [10, 20, 50, 100];

const HubUsersListPage = () => {
    const history = useHistory();
    const [keyword, setKeyword] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const {loading, data, error, fetch} = useHubUserList();
    const onDelete = useCallback(() => {
        setPage(1);
        void fetch(keyword, 1, perPage)
    }, [fetch, keyword, perPage]);
    const {deleteItem, data: deleteData,loading: deleteLoading,errorMessage} = useHubUserDelete(onDelete);


    useDidMountEffect(() => {
        setPage(1);
        void fetch(keyword, 1, perPage)
    }, [perPage]);

    useDidMountEffect(() => {
        void fetch(keyword, page, perPage)
    }, [page]);

    useEffect(() => {
        setPage(1);
        void fetch(keyword, 1, perPage)
    }, [keyword]);

    useEffect(() => {
        if(errorMessage){
            swalError({
                title: errorMessage
            })
        }
        if(error){
            swalError({
                title: error
            })
        }
    }, [errorMessage, error]);

    const onSearch = useCallback((text) => {
        setKeyword(text);
    }, []);

    const handleChangePage = useCallback(
        (e,newPage) => {
            e.preventDefault();
            setPage(newPage);
        },
        [],
    );

    return (
        <div>
            <div className="text-center text-title-color">
                <h2>ข้อมูลพนักงานฮับ</h2>
            </div>
            <HubUsersSearch onSearch={onSearch} loading={loading || deleteLoading}>
                <div className="col-sm-4 px-md-1 mt-1" align="right">
                    <br />
                    <button
                        className="btn btn-primary"
                        onClick={(e) => {
                            history.push("/mis/AddHubEmployee");
                        }}
                    >
                        <Icon.Plus /> เพิ่มข้อมูล
                    </button>
                </div>
            </HubUsersSearch>
            <div className="d-flex justify-content-between mb-2">
                <div></div>
                <div>
                    <select
                        id="ddlPageSize"
                        className="form-control"
                        onChange={(e) => setPerPage(e.target.value)}
                        value={perPage}
                    >
                        {pageSizes.map((size) => (
                            <option key={size} value={size}>
                                {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div>
                {data &&
                    <>
                    <HubUsersList data={data?.result} page={page} perPage={perPage} loading={loading || deleteLoading}
                       onUpdate={(row) => {
                           history.push(`/mis/EditHubEmployees/${row}`);
                       }}
                       onDelete={(id) => {
                           deleteItem(id);
                       }}/>
                    <div className="d-flex justify-content-between">
                        <div>จำนวนทั้งหมด {data.numRowsAll} รายการ</div>
                        <div>
                            <Pagination
                                count={parseInt(data.maxPage)}
                                page={page}
                                onChange={handleChangePage}
                                color="primary"
                                size="small"
                                defaultPage={6}
                                siblingCount={1}
                            />
                        </div>
                    </div>
                    </>
                }
                <center>
                    {(loading || deleteLoading) ? (
                        <div className="m-5">
                            <div className="text-center">
                                <div className="spinner-border  text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                {" ..."}
                                กำลังโหลดข้อมูล
                            </div>
                        </div>
                    ) : <></>}
                </center>
            </div>
        </div>
    )
}

export default HubUsersListPage;