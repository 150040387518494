export function AUTHEN(username, roleType, fullname, province_code, amphur_code, show_shipment_cost, show_receiver_address) {
  return { type: "AUTHEN", username, roleType, fullname, province_code, amphur_code, show_shipment_cost, show_receiver_address };
}

export function UAUTHEN() {
  return { type: "UAUTHEN" };
}

export function USERINFO() {
  return { type: "USERINFO" };
}

export function CHANGE_LANGUAGE(language) {
  return { type: "CHANGE_LANGUAGE", language };
}

export function SELECT_LANGUAGE() {
  return { type: "SELECT_LANGUAGE" };
}
