import React, {useState, useMemo, useEffect} from "react";
import useAddHubZip from "../hooks/use-add-hub-zip";
import {Spinner} from "react-bootstrap";
import {swalError, swalSuccess} from "../../../../Components/sweetAlert";
import useGetHubZip from "../hooks/use-get-hub-zip";
import useDeleteHubZip from "../hooks/use-delete-hub-zip";
import * as Icon from "react-bootstrap-icons";

const HubUserZipEdit = ({HubId}) => {
    const [zipcode, setZipcode] = useState('');
    const [currentZip, setCurrentZip] = useState(null);
    const {add, update,errorMessage,loading} = useAddHubZip(() => {
        if(currentZip){
            swalSuccess({
                text: 'รหัสไปรษณีย์ได้รับการอัปเดตเรียบร้อยแล้ว',
            });
            setCurrentZip(null);
        }
        else {
            swalSuccess({
                text: 'เพิ่มรหัสไปรษณีย์สำเร็จแล้ว',
            });
        }
        setZipcode('');
        fetch(HubId);
    });
    const {fetch, loading: getLoading, errorMessage: getErrorMessage, data } = useGetHubZip();

    const {remove, loading: removeLoading, errorMessage: removeErrorMessage} = useDeleteHubZip(() => {
        swalSuccess({
            text: 'ลบรหัสไปรษณีย์เรียบร้อยแล้ว',
        });
        fetch(HubId);
    });

    const isInvalidZipCode = useMemo(() => {
        return zipcode.length!==5 || !new RegExp('^[0-9]*$').test(zipcode);
    }, [zipcode]);

    useEffect(() => {
            fetch(HubId);
    },[HubId]);

    useEffect(() => {
        if(errorMessage){
            swalError({
                text: 'รหัสไปรษณีย์มีอยู่แล้วสำหรับฮับนี้'
            });
        }
        if(removeErrorMessage){
            swalError({
                text: 'An error occured'
            });
        }
    }, [errorMessage,removeErrorMessage]);

    const isLoading = loading || getLoading || removeLoading;
    return (
        <div className="w-full">
            {!isLoading &&
                <>
                    <form onSubmit={() => {
                        currentZip ? update(currentZip.id,zipcode) : add(HubId,zipcode);
                    }} className="w-full">
                        <h3>{currentZip ? `อัปเดตรหัสไปรษณีย์ (${currentZip.zip})` : 'เพิ่มรหัสไปรษณีย์'}</h3>
                        <div className="row mb-3">
                            <div className="col-4 d-flex flex-column">
                                <label htmlFor="zip">เพิ่มรหัสไปรษณีย์</label>
                                <input name="zip" value={zipcode} onChange={(e) => setZipcode(e.target.value)}/>
                            </div>
                            <div className="col-2 d-flex align-items-end">
                                <button disabled={isInvalidZipCode} className="btn btn-primary">{currentZip ? 'อัปเดต' : 'เพิ่ม'}</button>
                            </div>
                            {isInvalidZipCode && <p className="col-12 error-invalid-feedback">รหัสไปรษณีย์ควรเป็นตัวเลข 5 ตัว</p>}
                        </div>
                    </form>
                    {data && <div className="w-full">
                        <table className="table table-bordered">
                            <thead className="table-blue">
                                <th>ซิป</th>
                                <th>&nbsp;</th>
                            </thead>
                            <tbody>
                                {data.map(({id,zip}) => (<tr>
                                    <td key={id}>{zip}</td>
                                    <td>
                                        <button onClick={() => {
                                            setCurrentZip({
                                                id,
                                                zip
                                            });
                                            setZipcode(zip);
                                        }} className="btn btn-primary mr-1">
                                            <Icon.PencilSquare style={{ color: "#ffff" }} />
                                        </button>
                                        <button onClick={(e) => {
                                            void remove(id);
                                        }} className="btn btn-danger">
                                            <Icon.TrashFill />
                                        </button>
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>}
                </>
            }
            {isLoading &&
                <div className="spinner-global-content">
                    <Spinner variant="success" animation="border" />
                </div>
            }
        </div>
    );
};

export default HubUserZipEdit;