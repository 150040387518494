import * as Yup from 'yup';

const Schema = Yup.object().shape({
  startYear: Yup.string().test('is-year-start', 'กรุณาระบุ ปี', function (value) {
    const { search, brand, shipment_status, cod_status } = this.parent;
    if (search !== undefined || brand !== undefined || shipment_status !== undefined || cod_status !== undefined) {
      return true;
    } else if (value === undefined) {
      return false;
    } else {
      return true;
    }
  }),
  startMonth: Yup.string().test('is-month-start', 'กรุณาระบุ เดือน', function (value) {
    const { search, brand, shipment_status, cod_status } = this.parent;
    if (search !== undefined || brand !== undefined || shipment_status !== undefined || cod_status !== undefined) {
      return true;
    } else if (value === undefined) {
      return false;
    } else {
      return true;
    }
  }),
  startDay: Yup.string().test('is-day-start', 'กรุณาระบุ วัน', function (value) {
    const { search, brand, shipment_status, cod_status } = this.parent;
    if (search !== undefined || brand !== undefined || shipment_status !== undefined || cod_status !== undefined) {
      return true;
    } else if (value === undefined) {
      return false;
    } else {
      return true;
    }
  }),
  endYear: Yup.string().test('is-year-end', 'กรุณาระบุ ปี', function (value) {
    const { search, brand, shipment_status, cod_status } = this.parent;
    if (search !== undefined || brand !== undefined || shipment_status !== undefined || cod_status !== undefined) {
      return true;
    } else if (value === undefined) {
      return false;
    } else {
      return true;
    }
  }),
  endMonth: Yup.string().test('is-month-end', 'กรุณาระบุ เดือน', function (value) {
    const { search, brand, shipment_status, cod_status } = this.parent;
    if (search !== undefined || brand !== undefined || shipment_status !== undefined || cod_status !== undefined) {
      return true;
    } else if (value === undefined) {
      return false;
    } else {
      return true;
    }
  }),
  endDay: Yup.string().test('is-day-end', 'กรุณาระบุ วัน', function (value) {
    const { search, brand, shipment_status, cod_status } = this.parent;
    if (search !== undefined || brand !== undefined || shipment_status !== undefined || cod_status !== undefined) {
      return true;
    } else if (value === undefined) {
      return false;
    } else {
      return true;
    }
  }),
});

export default Schema;
