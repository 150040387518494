import React from "react";
import { nanoid } from "nanoid";
import LOGO from "../../../Assets/images/logo/logoNavbar.png";
import {connect} from "react-redux";

function PrintContent({ data, startDate, endDate, branchs, user }) {
  // const hideCost = localStorage.getItem('show_shipment_cost') === "0" && localStorage.getItem('superkey') === 'credit';
  // const service = localStorage.getItem('superkey') === 'service';
  var date = new Date();
  var amont = 0;
  var shipmentSum = 0;
  var codSum = 0;
  var servicSum = 0;
  var sumDue =0;
  var sumDue2 =0;
  var rate = 0;
  var rate2 = 0;
  data.map((value) => {
    rate = parseFloat(value.rate);
    rate2 = parseFloat(value.rate2);
    amont = amont + parseInt(value.num_parcel);
    shipmentSum = shipmentSum + parseInt(value.shipment_cost);
    codSum = parseInt(value.cod_cost) === 0 || parseInt(value.cod_cost) > 0 ? codSum + parseInt(value.cod_cost) : codSum + 0;
    servicSum = servicSum + parseInt(value.service_cod);
    sumDue = sumDue + (((parseInt(value.shipment_cost) +parseInt(value.service_cod))*(100-(parseFloat(value.rate)))/100));
    sumDue2 = sumDue2 + (((parseInt(value.shipment_cost) +parseInt(value.service_cod))*(100-(parseFloat(value.rate2)))/100))
  });

  return (
    <div style={{ margin: "15px" }}>
      <div style={{ textAlign: "center" }}>
        <h2>Invoice report</h2>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <div style={{ justifyContent: "start" }}>
          <b>วันที่พิมพ์ : </b>{" "}
          {(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
            "/" +
            (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
            "/" +
            date.getFullYear()}{" "}
          <br />
          <b>วันที่รับสินค้า : </b> {startDate} ถึงวันที่ {endDate}
          <br />
          <b>รหัสลูกค้า : </b> {branchs}
          <br />
          <b>เปอร์เซ็นที่ได้รับ : </b> {amont >= 50 && rate2 != '0' ? (rate2):(rate) }
          
        </div>
        <div style={{ justifyContent: "end" }}>
          <img src={LOGO} alt="logo porlor" style={{ width: "150px", height: "70px" }} />
          <div style={{ marginTop: "10px" }}>บริษัท​ ปอ​ลอ เอ็กซ์เพ​รส​ จํากัด</div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>ลำดับ</th>
            <th style={{ width: "15%", textAlign: "center" }}>เลข​ที่​ใบขน​ส่ง​/Order/สําหรับลูกค้า</th>
            <th style={{ textAlign: "center" }}>เวลา</th>
            <th style={{ width: "15%", textAlign: "center" }}>ชื่อผู้รับ</th>
            <th style={{ textAlign: "center" }}>เขต/อำเภอ</th>
            <th style={{ textAlign: "center" }}>จังหวัด</th>
            <th style={{ textAlign: "center" }}>ชิ้น</th>
            <th style={{ width: "100px", textAlign: "center" }}>ค่าขนส่ง</th>
            <th style={{ width: "100px", textAlign: "center" }}>กำหนดชำระเงิน</th>
          </tr>
        </thead>
        <tbody>
          {data.map((value, index) => (
            <tr key={nanoid()}>
              <td>{index + 1}</td>
              <td style={{ textAlign: "center" }}>
              {value.awb_code}
              </td>
              <td>{value.created_date.substring(11, 16)}</td>
              <td>{value.receiver_name}</td>
              <td>{value.receiver_amphur}</td>
              <td>{value.receiver_province}</td>
              <td style={{ textAlign: "center" }}>{value.num_parcel}</td>
              <td style={{ textAlign: "right" }}>
                {(parseFloat(value.service_cod) + parseFloat(value.shipment_cost)).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                </td>
                {
                  amont >= 50 && value.rate2 != '0' ? (
                    <td style={{ textAlign: "right" }}>
                      {((parseInt(value.shipment_cost) +parseInt(value.service_cod))
                      *(100-(parseFloat(value.rate2)))/100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </td>
                  ):(
                    <td style={{ textAlign: "right" }}>
                      {((parseInt(value.shipment_cost) +parseInt(value.service_cod))
                      *(100-(parseFloat(value.rate)))/100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </td>
                  )
                }

            </tr>
          ))}
          <tr>
            <td colspan="6" style={{ textAlign: "center" }}>
              ยอดรวม
            </td>
            <td style={{ textAlign: "center" }}>{amont}</td>
            <td style={{ textAlign: "right" }}>{(servicSum + shipmentSum).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
            <td style={{ textAlign: "right" }}>{(sumDue).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
          </tr>
        </tbody>
      </table> 
      <div style={{ display: "flex", justifyContent: "center", marginTop: "70px" }}>
        <div style={{ marginRight: "50%", textAlign: "center" }}>
          ลงชื่อ​ผู้ส่ง​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.
          <p>........../........../..........</p>
        </div>
        <div style={{ textAlign: "center" }}>
          ลงชื่อ​ผู้​ขน​ส่ง​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.
          <p>........../........../..........</p>​
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(PrintContent);
