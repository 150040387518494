import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import * as Icon from "react-bootstrap-icons";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        {/* <div className="footer-blue">
          <div className="container">
            <div className="row footer-contact">
              <div className="col-12 col-sm-4 col-lg-4 col-xl-4">
                <h3 className="title-footer">ปอลอ เอ็กซ์เพรส</h3>
                <p>ตรงเวลา ปลอดภัย ทั่วไทย</p>
                <p> จันทร์–เสาร์: 8.30-18.00 น.</p>
                <div className=" form-group">
                  <Button
                    variant="primary-one"
                    onClick={(e) => {
                      this.props.history.push("Tracking");
                    }}
                    className="radius"
                  >
                    ตรวจสอบสถานะการจัดส่ง
                  </Button>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-lg-4 col-xl-4">
                <h3 className="title-footer">ข้อมูลของเรา</h3>
                <ul>
                  <li>บริการของเรา</li>
                  <li>อัตราบริการ</li>
                  <li>เกี่ยวกับเรา</li>
                </ul>
              </div>
              <div className="col-12 col-sm-4 col-lg-4 col-xl-4">
                <h3 className="title-footer">ติดต่อเรา</h3>
                <p>
                  เลขที่ 99/153 ซอยกรุงเทพกรีฑา 39 แขวงทับช้าง <br />{" "}
                  เขตสะพานสูง กรุงเทพมหานคร
                </p>
                <p>
                  <Icon.Headset style={{ fontSize: "22px" }} />
                  &nbsp;&nbsp; 1423
                </p>
                <p>
                  <Icon.Envelope style={{ fontSize: "22px" }} />
                  &nbsp;&nbsp; service@porlor.co.th <br />
                  <Icon.Envelope style={{ fontSize: "22px" }} />
                  &nbsp;&nbsp; express@porlor.co.th <br />{" "}
                  <Icon.Envelope style={{ fontSize: "22px" }} />
                  &nbsp;&nbsp; 023682111@porlor.co.th
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-detail">
          Copyright © 2018 PorLor Express. All Rights Reserved.{" "}
        </div>
      </footer>
    );
  }
}
