import React, { useState, useEffect } from "react";
import logoNavbar from "../../../Assets/images/logo/logoNavbar.png";
import { PublicLoginService } from "../../../Service/service.Authentication";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AUTHEN, USERINFO } from "../../../actions/authen";
import {URLTEST, urlLocal} from "../../../Helper/baseURL";

function PublicLogin(Authentication) {
  const { lang } = Authentication;
  const history = useHistory();
  const [user, username] = useState("");
  const [pass, password] = useState("");
  const [userType, SetUserType] = useState("");
  const [setErrorUser, SetErrorUser] = useState("form-control");
  const [setErrorMassUser, SetErrorMassUser] = useState(0);
  const [setErrorPass, SetErrorPass] = useState("form-control");
  const [setErrorMassPass, SetErrorMassPass] = useState(0);
  const [setErrorUserType, SetErrorUserType] = useState(0);
  const [loading, Loading] = useState(0);
  //ทดสอบ
  useEffect(() => {
    document.body.style.backgroundColor = "#193b6b";
    return () => {
      document.body.style.backgroundColor = "#ffff";
    };
  }, []);

  async function publicLogin(value) {
    Loading(1);
    const result = await PublicLoginService(value);
    if (result.statusCode === "001") {
      Loading(0);
      document.body.style.backgroundColor = "#ffff";
      Authentication.AUTHEN(
        result.data["username"],
        result.data["role_type"],
        result.data["fullname"],
        result.data["province_code"],
        result.data["amphur_code"],
        "1",
        "1"
      );
      Authentication.USERINFO();
      if (result.data["role_type"] === "6" || result.data["role_type"] === "3") {
        localStorage.setItem("superkey","1947");
        console.log(result.data["bid"]);
        localStorage.setItem("bid",result.data["bid"]);
        localStorage.setItem("bbk_c",result.data["bbk_c"]);
        localStorage.setItem("bbk_n",result.data["bbk_n"]);
        localStorage.setItem("citizen_id",result.data["citizen_id"]);
        localStorage.setItem("username",result.data["username"]);
        localStorage.setItem("fullname",result.data["fullname"]);
        history.push("/mis/Parcel");
      }
    } else {
      Loading(0);
      swal({
        title: "เข้าสู่ระบบไม่สำเร็จ",
        text: "ชื่อผู้ใช้ หรือรหัสผ่านไม่ถูกต้อง",
        icon: "warning",
        button: "ปิด",
        //timer: 1500,
      });
    }
  }

  const handleSubmit = () => {
    SetErrorPass("form-control");
    SetErrorMassPass(0);
    SetErrorUser("form-control");
    SetErrorMassUser(0);
    if (user !== "" && pass !== "") {
      publicLogin({
        username: user !== "" ? user : "",
        password: pass !== "" ? pass : ""
      });
    }
    if (pass === "") {
      SetErrorPass("form-control is-invalid");
      SetErrorMassPass(1);
    }
    if (user === "") {
      SetErrorUser("form-control is-invalid");
      SetErrorMassUser(1);
    }
  };

  return (
    <div className="dg-login">
      <div className="container ">
        <div className="row " align="center">
          <div className="container">
            <div className="col-sm-6 px-md-1 mt-1">
              <div
                className="card"
                style={{
                  borderTop: "4px solid #ff9900",
                  boxShadow: "0 4px 8px 0 #131212",
                  transition: "0.3s",
                }}
                align="left"
              >
                <div className="card-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="row justify-content-md-center">
                      <div className="col-sm-9 px-md-1 mt-1">
                        <div align="center">
                          <a href = {"/Home"}>
                          <img alt="" src={logoNavbar} width="140" className="d-inline-block align-top" />
                          </a>
                        </div>
                        {/* <h3>เข้าสู่ระบบ</h3> */}
                        <br />
                        <div className="form-group">
                          <label htmlFor="usernamePolor">
                          {lang.language === "th" ? "หมายเลขโทรศัพท์" : "Mobile Number"}</label>
                          <input
                            type="text"
                            name="usernamePolor"
                            id="usernamePolor"
                            onChange={(e) => username(e.target.value)}
                            className={setErrorUser}
                          />
                          {setErrorMassUser === 1 ? (
                            <font color="red" size="2">
                              {" "}
                              กรุณากรอกชื่อผู้ใช้{" "}
                            </font>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="passwordPolor">
                          {lang.language === "th" ? "รหัสผ่าน" : "Password"}
                          </label>
                          <input
                            type="password"
                            onChange={(e) => password(e.target.value)}
                            className={setErrorPass}
                            id="passwordPolor"
                            name="passwordPolor"
                          />
                          {setErrorMassPass === 1 ? (
                            <font color="red" size="2">
                              {" "}
                              กรุณากรอกรหัสผ่าน{" "}
                            </font>
                          ) : (
                            ""
                          )}
                        </div>

                        <button
                          disabled={loading === 1 ? true : false}
                          type="submit"
                          className={
                            loading === 1 ? "btn btn-secondary btn-lg btn-block" : "btn btn-primary btn-lg btn-block"
                          }
                        >
                          {/* {loading === 1 ? (
                            <center>
                              <div className="spinner-border" role="status"></div>
                            </center>
                          ) : (
                            "เข้าสู่ระบบ"
                          )} */}
                          {lang.language === "th" ? "เข้าสู่ระบบ" : "Log in"}  
                        </button>
                        <div align = 'center'>
                          <p></p> 
                          <p> <a href = ''> {lang.language === "th" ? "ลืมรหัสผ่าน?" : "Forgot password?"} </a> </p>
                          <p> {lang.language === "th" ? "ยังไม่มีบัญชี?" : "Do not have an account?"} <a href = {"/signUp"}> {lang.language === "th" ? " สมัครตอนนี้" : "Sign up now"}</a></p>
                        </div>
                        <br />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  lang: state.LanguageAction,
});

const mapDispatchToProps = (dispatch) => {
  return {
    AUTHEN: (username, roleType, fullname, province_code, amphur_code) =>
      dispatch(AUTHEN(username, roleType, fullname, province_code, amphur_code)),
    USERINFO: () => dispatch(USERINFO()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicLogin);
