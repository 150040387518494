import React, { useState, useEffect } from "react";
import { AreaService } from "../../../Service/service.Area";
import * as Icon from "react-bootstrap-icons";
import Pagination from "@material-ui/lab/Pagination";
import { province } from "../../../Data/amphoe-province.json";
import { Card } from "react-bootstrap";

export default function Branch() {
  var number = 0;
  var province_name = "";
  const [setData, SetData] = useState([]);
  const [setPage, SetPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });
  const [currentPage, SetcurrentPage] = useState(1);
  const [provinces, Setprovince] = useState("");
  const [setError, SetError] = useState("form-control");
  const [setErrorMass, SetErrorMass] = useState(0);
  const [setErrorMassNoData, SetErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);

  async function fetchData(value) {
    Loading(1);
    const res = await AreaService(value);
    //console.log("data"+JSON.stringify(res));
  
    if (res.numRowsAll > 0) {
        SetData(res.result);
        SetPage({
          current_page: res.pageNo,
          last_page: res.maxPage,
          total: res.numRowsAll
        });

        SetError("form-control");
        SetErrorMass(0);
        SetErrorMassNoData(0);

      }else{
        SetcurrentPage(1);
        SetPage({
          current_page: 1,
          last_page: 1,
          total: 0,
        });
        SetErrorMassNoData(1);
      }
    Loading(0);
  }

  useEffect(() => {
    SetcurrentPage(1);
    fetchData({
      province: "",
      page: 1,
    });
  }, []);

  function clear() {
    SetData([]);
    Loading(0);
    SetcurrentPage(1);
    SetErrorMassNoData(0);
    SetError("form-control");
    SetErrorMass(0);
    Setprovince("");
    SetPage({
      current_page: 1,
      last_page: 1,
      total: 0,
    });
    fetchData({
      province: "",
      page: 1,
    });
  }

  const handleChangePage = (event, newPage) => {
    SetData([]);
    SetcurrentPage(newPage);
    fetchData({
      province: provinces != "" ? provinces : "",
      page: newPage,
    });
  };

  const handleSubmit = () => {
    SetErrorMass(0);
    SetErrorMassNoData(0);
    SetError("form-control");

    if (provinces != "") {
      SetcurrentPage(1);
      SetData([]);
      fetchData({
        province: provinces != "" ? provinces : "",
        page: 1,
      });
    }

    if (provinces == "") {
      SetErrorMass(1);
      SetError("form-control is-invalid");
    }
  };

  return (
    <div className="container">
      <br />
      <br />
      <div className="row">
        <div class="container">
          <div class="card" style={{ width: "100%" }}>
            <div class="card-body">
              <div className="text-center text-title-color">
                <h2>พื้นที่ให้บริการ</h2>
              </div>
              <br />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div class="row justify-content-md-center">
                  <div class="col-sm-4 px-md-1 mt-1">
                    <input
                      onChange={(e) => {
                        Setprovince(e.target.value);
                      }}
                      value={provinces}
                      class={setError}
                      list="province"
                      placeholder="-- กรุณาเลือกจังหวัด --"
                      autoComplete="off"
                    />
                    <datalist id="province" style={{ height: "50px" }}>
                      <option value={""}>{"ทั้งหมด"}</option>
                      {province.map((item) => (
                        <option value={item.ProvinceName}>{item.ProvinceName}</option>
                      ))}
                    </datalist>
                    {setErrorMass == 1 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณาเลือกจังหวัด{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-sm-3 px-md-1 mt-1">
                    <i class="bi bi-search"></i>
                    <button type="submit" class="btn btn-primary">
                      <Icon.Search /> ค้นหา
                    </button>
                    <span>&nbsp;</span>
                    <button type="button" class="btn btn-secondary" onClick={clear}>
                      <Icon.ArrowClockwise /> ล้างค่า
                    </button>
                  </div>
                </div>

                <br />
                <br />
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {setData.length != 0 ? (
          <div class="container">
            <div class="row">
              <div className="col-sm-12 px-md-1 mt-1">
                <br />

                <Card
                  style={{
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderTop: "3px solid #ff9900",
                  }}
                >
                  <Card.Body>
                    {setData.map((item, index) => (
                      <Card.Text style={{ color: "#545b62" }}>
                        {province_name != setData[index].province ? (
                          <Card.Title style={{ color: "#385792", fontSize: "28px" }}>
                            {index > 0 ? <hr /> : ""}
                            {setData[index].province}
                          </Card.Title>
                        ) : (
                          ""
                        )}

                        <div>
                          <strong>
                            {province_name != setData[index].province ? (number = 1) : (number += 1)}
                            {"."} {item.shop_name}{" "}
                          </strong>{" "}
                          <br />
                          {item.address}
                        </div>
                        <div hidden>{(province_name = setData[index].province)}</div>
                      </Card.Text>
                    ))}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {loading == 1 ? (
        <center>
          <br />
          <div class="spinner-border" role="status"></div>
        </center>
      ) : (
        ""
      )}
      <center>
        <br />
        {setErrorMassNoData == 1 ? (
          <font color="red" size="3">
            {" "}
            ไม่พบข้อมูล!{" "}
          </font>
        ) : (
          ""
        )}
      </center>

      {setData.length != 0 ? (
        <div class="d-flex justify-content-between">
          <div>จำนวนทั้งหมด {setPage.total} รายการ</div>
          <div>
            <Pagination
              count={parseInt(setPage.last_page)}
              page={currentPage}
              onChange={handleChangePage}
              color="primary"
              size="small"
              defaultPage={6}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <br />
      <br />
    </div>
  );
}
