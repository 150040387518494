import Modal from "react-bootstrap/Modal";
import {ProgressBar} from "react-bootstrap";

export const MultipleUploadProgress = ({states}) => {
    return (
        <Modal size="lg" show={true}>
            <Modal.Body>
                <div style={styles.container}>
                    {states.map((state,index) => (
                        <div style={styles.content} key={index}>
                            <h3 style={styles.container}>ภาพ {index}</h3>
                            <ProgressBar style={styles.container} now={state.progression} />
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    )
}

const styles = {
    container: {
        width: '100%'
    },
    content: {
        width: '100%',
        padding: '15px'
    }
};