import {useEffect, useState} from "react";
import Instance from "../../../../Helper/axios";
import {format, parse} from "date-fns";

const useParcelStatus = ({onError}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetch = async (page,filters, no_pagination = false, perpages = null) => {
        setData(null);
        setLoading(true);
        try{
            const data = {
                search: filters.keyword,
                shipment_status: filters.shipment_status,
                cod_status: filters.cod,
                no_pagination,
                dateFilters: {
                    startDate: format(filters.dateFilters.startDate, 'yyyy-MM-dd 00:00:00'),
                    endDate: format(filters.dateFilters.endDate, 'yyyy-MM-dd 23:59:00'),
                }
            };
            if(perpages !== null){
                data['perPages'] = perpages;
            }
            const result = await Instance.post(`/hub/HubStatus/getParcelStatuses/${localStorage.getItem('id')}/${page}`, data);
            setData(result.data.data);
        }
        catch (error){
            onError(error);
        }
        finally {
            setLoading(false);
        }
    }

    const flush = () => {
        setLoading(false);
        setData(null);
    }

    return {
        data,
        loading,
        fetch,
        flush
    }
}

export default useParcelStatus;