import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import * as Icon from "react-bootstrap-icons";
import LongDateTH from "../../../utility/LongDateTH";
import swal from "sweetalert";

export default function ShowData({
  data,
  page,
  perPage,
  history,
  loading,
  errorMassNoData,
  handleChangePage,
  Del,
  pageNo,
}) {
  return (
    <>
      <div style={{ overflow: "Auto" }}>
        <table className="table table-bordered">
          <thead className="table-blue">
            <tr>
              <th>ลำดับ</th>
              <th>ตำแหน่งที่เปิดรับสมัคร</th>
              <th>วันที่ประกาศ</th>
              <th>สถานที่ปฏิบัติงาน</th>
              <th>คุณสมบัติ</th>
              <th>รายละเอียด</th>
              <th>จัดการ</th>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={index}>
                <td>
                  {(parseInt(page.current_page) - 1) * perPage + (index + 1)}
                </td>
                <td>{value.positionName}</td>
                <td>{LongDateTH(value.ano_date)[0]}</td>
                <td>{value.work_location}</td>
                <td>
                  <p
                    class="card-subtitle mb-2  card-text-custom text-subtitle-property"
                    dangerouslySetInnerHTML={{
                      __html: value.qualification_description,
                    }}
                  ></p>
                </td>
                <td>
                  <p
                    class="card-subtitle mb-2  card-text-custom text-subtitle-property"
                    dangerouslySetInnerHTML={{
                      __html: value.job_description,
                    }}
                  ></p>
                </td>
                <td>
                  <button
                    className="btn btn-warning"
                    onClick={(e) => {
                      history.push("/mis/FormRecruit", { value: value });
                    }}
                  >
                    <Icon.PencilSquare style={{ color: "#ffff" }} />
                  </button>{" "}
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      swal({
                        className: "bg-modal-red",
                        icon: "warning",
                        dangerMode: true,
                        text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                        buttons: {
                          cancel: "ยกเลิก",
                          confirm: {
                            text: "ใช่",
                            value: value.code,
                          },
                        },
                      }).then((value) => {
                        if (value !== null) {
                          Del({
                            code: value,
                          });
                        }
                      });
                    }}
                  >
                    <Icon.Trash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <center>
          <div
            className={
              errorMassNoData === 1 && loading !== 1 ? "m-5" : "hidden-ct"
            }
          >
            <div>
              <br />
              <font color="red" size="3">
                ไม่พบข้อมูล!
              </font>
            </div>
          </div>
        </center>
      </div>
      <center>
        <div className={loading === 1 ? "m-5" : "hidden-ct"}>
          <div className="text-center">
            <div className="spinner-border  text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            {" ..."}
            กำลังโหลดข้อมูล
          </div>
        </div>
      </center>
      <div className="d-flex justify-content-between">
        <div>จำนวนทั้งหมด {page.total} รายการ</div>
        <div>
          <Pagination
            count={parseInt(page.last_page)}
            page={pageNo}
            onChange={handleChangePage}
            color="primary"
            size="small"
            defaultPage={6}
            siblingCount={1}
          />
        </div>
      </div>
      <br />
    </>
  );
}
