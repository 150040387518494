import Instance from "../../../../Helper/axios";
import {URL} from "../../../../Helper/baseURL";

export const getHubEmployees = (keyword, page) => {
    return Instance.get(URL + 'hub/HubController/getHubEmployees/'+ localStorage.getItem('id'), {
        params: {
            keyword,
            page
        }
    });
};