import React, { useState, useEffect } from "react";
import FilterData from "./filterData";
import ShowData from "./showData";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { InsertData } from "../../../Service/service.goods";
import { swalSuccess, swalError } from "../../../Components/sweetAlert";
import { GetData } from "../../../Service/service.goods";
import { useTranslation } from "react-i18next";

function SaveGoods(props) {
  const { user } = props;
  const schema = Yup.object().shape({
    awbCode: Yup.string()
        .required("กรุณาระบุเลขบาร์โค้ด")
        // .min(16, 'ความยาวของบาร์โค้ดควรเป็น 16')
        // .max(16, 'ความยาวของบาร์โค้ดควรเป็น 16'),
  });
  const [shipmentStatus, setShipmentStatus] = useState("1");
  const [perPage, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });
  const [pageNo, setCurrentPage] = useState(1);
  const [loading, Loading] = useState(0);
  const [errorMassNoData, setErrorMassNoData] = useState(0);

  useEffect(() => {
    fetchData({
      shipmentStatus: shipmentStatus,
      empCode: user.username,
      pageNo: pageNo,
      perPage: perPage,
    });
  }, []);

  async function fetchData(params) {
    Loading(1);
    let res = await GetData(params);
    if (res.numRowsAll > 0) {
      // const dataMerged = 
      // alert(res.result.map(item1 => ({ ...item1, ...res.sizes.find(item2 => item2.awb_code === item1.awb_code) })))
      // setData(res.result.map(item1 => ({ ...item1, ...res.sizes.find(item2 => item2.awb_code === item1.AWB_CODE) })));
      setData(res.result);
      setPage({
        current_page: res.pageNo,
        last_page: res.maxPage,
        total: res.numRowsAll,
      });
      setErrorMassNoData(0);
      Loading(0);
    } else {
      setCurrentPage(1);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      setErrorMassNoData(1);
      Loading(0);
    }
  }

  const handleChangeShipmentStatus = (e) => {
    setData([]);
    setErrorMassNoData([])
    setShipmentStatus(e);
    fetchData({
      shipmentStatus: e,
      empCode: user.username,
      pageNo: pageNo,
      perPage: perPage,
    });
  };

  const handleChangePageSize = (e) => {
    setData([]);
    setErrorMassNoData([])
    setPageSize(e);
    fetchData({
      shipmentStatus: shipmentStatus,
      empCode: user.username,
      pageNo: pageNo,
      perPage: e,
    });
  };

  const handleChangePage = (e, newPage) => {
    setData([]);
    setErrorMassNoData([])
    setCurrentPage(newPage);
    fetchData({
      shipmentStatus: shipmentStatus,
      empCode: user.username,
      pageNo: newPage,
      perPage: perPage,
    });
  };
  const { t } = useTranslation();
  async function insertData(params) {
    console.log("params", params)
      try {
        let result = await InsertData(params);
        if (result.status === 0) {
          swalError();
        } else {
          setData([]);
          setErrorMassNoData([])
          fetchData({
            shipmentStatus: shipmentStatus,
            empCode: user.username,
            pageNo: pageNo,
            perPage: perPage,
          });
      }
    }
    catch (e) {
      swalError({
        text: t(e.response.data.message),
      });
    }
  }

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          awbCode: "",
          shipmentStatus: shipmentStatus,
          empCode: user.username,
        }}
        onSubmit={(value) => {
          value.empCode = user.username;
          insertData(value);
          value.awbCode = ""
          document.getElementById("awbCode").value = "";
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className="card mb-5">
              <div className="card-body">
                <div className="container-fluid mt-2 mb-2">
                  <div className="text-center text-title-color">
                    <h2>บันทึกการรับสินค้า</h2>
                  </div>
                  <br />
                  <div className="row ">
                    <div className="col-12 col-md-7">
                      {" "}
                      <div className="form-group ">
                        <label>สถานะพัสดุ</label>
                        <br />
                        <div className="form-check form-check-inline ">
                          <input
                            style={{ width: "20px", height: "20px" }}
                            className="form-check-input"
                            type="radio"
                            name="shipment_status"
                            id="shipment_status1"
                            defaultValue="1"
                            checked={shipmentStatus === "1" ? true : false}
                            onChange={(e) => {
                              setFieldValue("shipmentStatus", e.target.value);
                              handleChangeShipmentStatus(e.target.value);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="shipment_status1"
                          >
                            1. รับเข้าระบบ
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            style={{ width: "20px", height: "20px" }}
                            className="form-check-input"
                            type="radio"
                            name="shipment_status"
                            id="shipment_status2"
                            defaultValue="2"
                            checked={shipmentStatus === "2" ? true : false}
                            onChange={(e) => {
                              setFieldValue("shipmentStatus", e.target.value);
                              handleChangeShipmentStatus(e.target.value);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="shipment_status2"
                          >
                            2. ศูนย์กระจายคัดแยกสินค้า
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            style={{ width: "20px", height: "20px" }}
                            className="form-check-input"
                            type="radio"
                            name="shipment_status"
                            id="shipment_status3"
                            defaultValue="3"
                            checked={shipmentStatus === "3" ? true : false}
                            onChange={(e) => {
                              setFieldValue("shipmentStatus", e.target.value);
                              handleChangeShipmentStatus(e.target.value);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="shipment_status3"
                          >
                            3. อยู่ระหว่างขนส่ง
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            style={{ width: "20px", height: "20px" }}
                            className="form-check-input"
                            type="radio"
                            name="shipment_status"
                            id="shipment_status4"
                            defaultValue="4"
                            checked={shipmentStatus === "4" ? true : false}
                            onChange={(e) => {
                              setFieldValue("shipmentStatus", e.target.value);
                              handleChangeShipmentStatus(e.target.value);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="shipment_status4"
                          >
                            4. ส่งภายในวันนี้
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="form-group">
                        <label htmlFor="awbCode">เลขบาร์โค้ด</label>
                        <input
                          value={values.awbCode}
                          type="text"
                          name="awbCode"
                          id="awbCode"
                          onBlur={handleBlur}
                          className={
                            "form-control " +
                            (getIn(touched, "awbCode")
                              ? getIn(errors, "awbCode")
                                ? "is-invalid"
                                : "is-valid"
                              : "") +
                            ""
                          }
                          autoComplete="offf"
                          onChange={(e) => {
                            setFieldValue("awbCode", e.target.value);
                          }}
                        />
                        <ErrorMessage
                          component="div"
                          name="awbCode"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-1 text-center mt-2">
                      <div className="form-group mt-4 ">
                        <button className="btn btn-success" type="submit">
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>

                  <hr className="mt-4 mb-4" />
                  <h4>รายการรับสินค้า</h4>
                  <FilterData
                    perPage={perPage}
                    handleChangePageSize={handleChangePageSize}
                  />
                  <ShowData
                    data={data}
                    page={page}
                    loading={loading}
                    errorMassNoData={errorMassNoData}
                    pageNo={pageNo}
                    handleChangePage={handleChangePage}
                    perPage={perPage}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(SaveGoods);
