import React from "react";
import { useTranslation } from "react-i18next";
import style from "./style.module.css";

const OneAd = ({ data }) => {
    const {
        logo_url,
        store_name,
        product_detail,
        product_price,
        contact,
    } = data;
    const { t } = useTranslation();
    return (
        <div className={`${style['body']} p-3`}>
            <div className={`${style['image-container']}`} style={{backgroundImage: `url(${logo_url})`}}>
            </div>
            <div className={style['text-content']}>
                <p>{store_name}</p>
                <div className={style['product-description']}
                    dangerouslySetInnerHTML={{
                        __html: product_detail,
                    }} />
                
                <h3>{t('common.contact')} {contact}</h3>
                <div className={style['price-show']}>
                    <p style={{ textAlign: 'left' }}>{t('common.price')}</p>
                    <p>{product_price} {t('common.baht')}</p>
                </div>
            </div>
        </div>
    ); 
};
export default OneAd;