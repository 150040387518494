import React, {useEffect, useCallback,useState} from "react";
import {useHistory, useParams} from "react-router";
import useHubUserAdd from "../hooks/use-hub-user-add";
import useHubUserGet from "../hooks/use-hub-user-get";
import {ErrorMessage, Formik, getIn} from "formik";
import * as Icon from "react-bootstrap-icons";
import getHubUserSchema from "../schemas/hub-user-schema";
import {swalError, swalSuccess} from "../../../../Components/sweetAlert";
import HubUserZipEdit from "../components/hub-user-zip-edit";
import {id} from "date-fns/locale";


const defaultValues = {
    username: '',
    password: '',
    confirmPassword: '',
    name: ''
};
const HubUsersEditPage = ({props}) => {
    const params = useParams();
    const history = useHistory();
    const {loading: getLoading,data, errorMessage: getHubUserErrorMessage ,get} = useHubUserGet();

    const {loading,errorMessage,update} = useHubUserAdd(() => {
        swalSuccess({
            title: params.id ? 'อัปเดตผู้ใช้เรียบร้อยแล้ว' : 'เพิ่มผู้ใช้เรียบร้อยแล้ว'
        });
        history.push('/mis/HubEmployees');
    });

    const [formInitialValues, setFormInitialValues] = useState(defaultValues);
    const [displayPassword, setDisplayPassword] = useState(false);
    const [validationSchema, setFormValidationSchema] = useState(null);
    const [status, setStatus] = useState("1");

    useEffect(() => {
        if(params.id){
            void get(params.id);
        }
        else {
            setFormInitialValues(defaultValues);
        }
        console.log(
            {schemaUpdate: true}
        );
        setFormValidationSchema(getHubUserSchema(!!params.id));
    }, [params]);

    useEffect(() => {
        if(data){
            setFormInitialValues({
                ...defaultValues,
                username: data.username,
                name: data.name,
            })
        }
    }, [data]);


    useEffect(() => {
        return () => {
            if (errorMessage) {
                console.log(errorMessage);
                swalError({
                    text: errorMessage
                })
            }
            if(getHubUserErrorMessage){
                swalError({
                    text: errorMessage
                })
            }
        };
    }, [errorMessage,getHubUserErrorMessage]);

    function clear() {
        setFormInitialValues(defaultValues);
      }

    return (
        <>
            {loading || getLoading ?  (
                <div className="m-5">
                    <div className="text-center">
                        <div className="spinner-border  text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        {" ..."}
                        กำลังโหลดข้อมูล
                    </div>
                </div>
            ) : (<div className="text-center">
                <h2 className="text-title-color">{params.id ? 'อัพเดตผู้ใช้ฮับ' : 'เพิ่มผู้ใช้ฮับใหม่'}</h2>
                {validationSchema && <Formik validationSchema={validationSchema} initialValues={formInitialValues}>
                    {({errors,touched,handleBlur,setFieldValue,values}) => (
                        <>
                            <div className="row">
                                <div className="col-6 col-md-6">
                                    <div className="form-group">
                                        <b>ชื่อผู้ใช้งาน</b>
                                        <span className="important-ct">*</span>
                                        <input type="text" value={values.username}
                                               name="username"
                                               autoComplete={"off"}
                                               className={'form-control ' + (getIn(touched, `username`) ? (getIn(errors, `username`) ? 'is-invalid' : 'is-valid') : '')}
                                               onChange={async (e) => {
                                                   e.preventDefault();
                                                   setFieldValue('username',e.target.value);
                                               }}
                                               aria-label="username"
                                               onBlur={handleBlur}
                                        />
                                        <ErrorMessage component="div" name="username" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className="form-group">
                                        <b>ชื่อ-นามสกุล</b>
                                        <span className="important-ct">*</span>
                                        <input type="text" value={values.name}
                                                autoComplete={"off"}
                                                name="name"
                                                className={'form-control ' + (getIn(touched, `name`) ? (getIn(errors, `name`) ? 'is-invalid' : 'is-valid') : '')}
                                                onChange={async (e) => {
                                                    e.preventDefault();
                                                    setFieldValue('name',e.target.value);
                                                }}
                                                aria-label="name"
                                                onBlur={handleBlur}
                                        />
                                        <ErrorMessage component="div" name="name" className="invalid-feedback" />
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-sm-4 mt-1">
                                    <div className="form-group">
                                                <b>รหัสผ่าน</b>
                                                <span className="important-ct">*</span>
                                                <input type={displayPassword ? "text": "password"} value={values.password}
                                                       name="password"
                                                       autoComplete={"off"}
                                                       className={'form-control ' + (getIn(touched, `password`) ? (getIn(errors, `password`) ? 'is-invalid' : 'is-valid') : '')}
                                                       onChange={async (e) => {
                                                           e.preventDefault();
                                                           setFieldValue('password',e.target.value);
                                                       }}
                                                       aria-label="password"
                                                       onBlur={handleBlur}
                                                />
                                                <ErrorMessage component="div" name="password" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 mt-1">
                                    <div className="form-group">
                                        <b>ยืนยันรหัสผ่าน</b>
                                        <span className="important-ct">*</span>
                                        <input type={displayPassword ? "text": "password"} value={values.confirmPassword}
                                               name="confirmPassword"
                                               autoComplete={"off"}
                                               className={'form-control ' + (getIn(touched, `confirmPassword`) ? (getIn(errors, `confirmPassword`) ? 'is-invalid' : 'is-valid') : '')}
                                               onChange={async (e) => {
                                                   e.preventDefault();
                                                   setFieldValue('confirmPassword',e.target.value);
                                               }}
                                               aria-label="confirmPassword"
                                               onBlur={handleBlur}
                                        />
                                        <ErrorMessage component="div" name="confirmPassword" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="col-6 col-sm-1 px-md-1 mt-1 
                                d-flex justify-content-start align-items-center">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={(e) => {
                                                setDisplayPassword((value) => !value);
                                        }}
                                    >
                                        {displayPassword ? <Icon.EyeSlash /> : <Icon.Eye />}
                                    </button>
                                </div>
                                <div class="col-sm-3 px-md-1 mt-1 ">
                                    
                                    <b>สถานะ</b>
                                    <br></br>
                                    <div
                                        className="row d-flex align-items-center"
                                        style={{ marginTop: "5px", justifyContent: 'space-evenly' }}>
                                        <div class="form-check form-check-inline">
                                            <input
                                                checked={status === "1" ? true : false}
                                                style={{ width: "20px", height: "20px" }}
                                                class="form-check-input"
                                                type="radio"
                                                name="inlineRadio6"
                                                id="inlineRadio6"
                                                value="1"
                                                onChange={(e) => {
                                                    setStatus(e.target.value);
                                                }}
                                            />
                                            <label class="form-check-label" for="inlineRadio0">
                                            ใช้งาน
                                            </label>
                                        </div>
                                        <div  class="form-check form-check-inline">
                                            <input
                                                checked={status === "0" ? true : false}
                                                style={{ width: "20px", height: "20px"  }}
                                                class="form-check-input"
                                                type="radio"
                                                name="inlineRadio7"
                                                id="inlineRadio7"
                                                value="0"
                                                onChange={(e) => {
                                                    setStatus(e.target.value);
                                                }}
                                            />
                                            <label class="form-check-label" for="inlineRadio1">
                                            ปิดใช้งาน
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 d-flex justify-content-center">
                                <button type="button" 
                                disabled={Object.keys(errors).length !== 0 || Object.keys(touched).length === 0} 
                                onClick={() => {
                                    console.log({
                                        errors,
                                        touched
                                    });
                                    void update(params.id,{
                                        name: values.name,
                                        username: values.username,
                                        password: values.password
                                    })
                                }}  className={'btn btn-primary m-2'}>
                                    <Icon.Save /> {params.id ? 'อัปเดต' : 'สร้าง'}
                                </button>
                                {"  "} {"  "}
                                <button className="btn btn-secondary m-2" onClick={clear}>
                                    <Icon.ArrowClockwise /> ล้างค่า
                                </button>
                            </div>
                        </>
                    )}
                </Formik>}
            </div>)}
            {params.id && <HubUserZipEdit HubId={params.id}/>}
        </>
    )
}

export default HubUsersEditPage;