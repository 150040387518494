import React, {useEffect} from "react";
import * as Yup from "yup";
import { useFormik, getIn} from 'formik';
import { useFilePicker } from "use-file-picker";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from "react-bootstrap/Button";

const adsModalSchema = Yup.object().shape({
    image: Yup.string().required('ช่องที่ต้องกรอก'),
    store_name: Yup.string().required('ช่องที่ต้องกรอก'),
    hyperlink: Yup.string().required('ช่องที่ต้องกรอก'),
    details: Yup.string().required('ช่องที่ต้องกรอก'),
    price: Yup.number().required('ช่องที่ต้องกรอก').min(1,'ช่องที่ต้องกรอก, ค่าต่ำสุดคือ1'),
});

const AdsForm = ({ onHide, show, onSubmit, currentAd }) => {

    const [openFileSelector, { filesContent, loading, clear }] = useFilePicker({
        readAs: "DataURL",
        accept: ['.jpg', '.jpeg', '.png'],
        multiple: false,
    });
    const {
        errors, touched, handleBlur, setFieldValue, handleSubmit, values, isValid, setTouched
    } = useFormik({
     initialValues: {
        image: currentAd ? currentAd.logo_url : '',
        store_name: currentAd? currentAd.store_name : '',
        hyperlink: currentAd? currentAd.contact : '',
        details: currentAd? currentAd.product_detail : '',
        price: currentAd? currentAd.product_price : 0,
     },
     validationSchema: adsModalSchema,
     onSubmit: values => {
       onSubmit(values);
     },
   });
    useEffect(() => {
        if (filesContent && filesContent.length > 0) {
            console.log(filesContent);
            setFieldValue('image', filesContent[0].content);
        }
    }, [filesContent, setFieldValue]);
    
    return (
        <Modal onHide={onHide} show={show} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>การจัดการโฆษณา</Modal.Title>
                </Modal.Header>
                        <div className="row">
                            <div className="col-sm-12 col-lg-4  d-flex flex-column justify-content-center align-items-center p-4">
                            <div style={styles.filePicker} onClick={(e) => {
                                    setTouched({...touched, image: true}, true);
                                    openFileSelector();
                            }}
                            className={`d-flex justify-content-center align-items-center`}>
                            {values.image !== '' ? <div style={{...styles.image, backgroundImage: `url(${values.image})`}} />
                                : <Icon.CloudUploadFill size={64} color="white" />}
                            </div>
                            {(getIn(touched, "image") && 
                                getIn(errors, "image")
                                && (
                                    <div className="invalid-feedback text-center" style={{display: 'block'}}>
                                        {errors.image}
                                    </div>
                                )
                            )}
                            </div>
                            <div className="col-sm-12 col-lg-8 p-4">
                                <div className="row">
                                        <div className="col-sm-12 col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="store_name">ชื่อร้าน</label>
                                                <input
                                                value={values.store_name}
                                                type="text"
                                                name="store_name"
                                                onBlur={handleBlur}
                                                className={
                                                    "form-control " +
                                                    (getIn(touched, "store_name")
                                                    ? getIn(errors, "store_name")
                                                        ? "is-invalid"
                                                        : "is-valid"
                                                    : "") +
                                                    ""
                                                }
                                                onChange={(e) => {
                                                    setFieldValue("store_name", e.target.value);
                                                }}
                                                autoComplete="offf"
                                            />
                                            {(getIn(touched, "store_name") && 
                                                     getIn(errors, "store_name")
                                                        && (
                                                            <div className="invalid-feedback">
                                                                {errors.store_name}
                                                            </div>
                                                        )
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="hyperlink">ไฮเปอร์ลิงก์</label>
                                                <input
                                                value={values.hyperlink}
                                                onBlur={handleBlur}
                                                type="text"
                                                name="hyperlink"
                                                className={
                                                    "form-control " +
                                                    (getIn(touched, "hyperlink")
                                                    ? getIn(errors, "hyperlink")
                                                        ? "is-invalid"
                                                        : "is-valid"
                                                    : "") +
                                                    ""
                                                }
                                                onChange={(e) => {
                                                    setFieldValue("hyperlink", e.target.value);
                                                }}
                                                autoComplete="offf"
                                            />
                                            {(getIn(touched, "hyperlink") && 
                                                        getIn(errors, "hyperlink")
                                                        && (
                                                            <div className="invalid-feedback">
                                                                {errors.hyperlink}
                                                            </div>
                                                        )
                                            )}
                                            </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <div className="form-group">
                                        <label htmlFor="price">ราคา</label>
                                            <input
                                                value={values.price}
                                                onBlur={handleBlur}
                                                type="number"
                                                name="price"
                                                className={
                                                    "form-control " +
                                                    (getIn(touched, "price")
                                                    ? getIn(errors, "price")
                                                        ? "is-invalid"
                                                        : "is-valid"
                                                        : "") +
                                                    ""
                                                }
                                                style={styles.ta_right}
                                                onChange={(e) => {
                                                    setFieldValue("price", e.target.value);
                                                }}
                                                autoComplete="offf"
                                        />
                                        {(getIn(touched, "price") && 
                                                    getIn(errors, "price")
                                                    && (
                                                        <div className="invalid-feedback">
                                                            {errors.price}
                                                        </div>
                                                    )
                                        )}
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="details">รายละเอียด</label>
                                            <ReactQuill
                                                className={
                                                        (getIn(touched, "details")
                                                        ? getIn(errors, "details")
                                                            ? "is-invalid"
                                                            : "is-valid"
                                                        : "") +
                                                        ""
                                                }
                                                onBlur={() => {
                                                    setTouched({...touched, details: true});
                                                }}
                                                theme="snow"
                                                value={values.details}
                                                onChange={(e) => {
                                                    setFieldValue("details", e);
                                                }} />
                                                {(getIn(touched, "details") && 
                                                            getIn(errors, "details")
                                                            && (
                                                                <div className="invalid-feedback">
                                                                    {errors.details}
                                                                </div>
                                                            )
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4" style={styles.w_100}>
                            <Button
                                style={styles.w_100}
                                disabled={!isValid}
                                variant={"primary-one"}
                                onClick={(e) => {
                                    isValid && handleSubmit();
                                }}
                                className="radius">
                                สร้าง
                            </Button> 
                        </div>
                </Modal>
    )
};

const styles = {
    filePicker: {
        height: '16rem',
        maxHeight: '16rem',
        width: '96%',
        maxWidth: '96%',
        borderRadius: '2rem',
        backgroundColor: 'rgb(235, 244, 255)',
        cursor: "pointer",
        transition: "all .3s ease-in-out",
        position: 'relative',
    },
    w_100: {
        width: '100%',
    },
    ta_right: {
        textAlign: 'right',
    },
    image: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: "100%",
        height: "100%",
        backgroundSize: 'contain',
    },
};

export default AdsForm;