import React from "react";

export const scanInOutMetadata = (t) =>  [
    {
        text: t('hub.scannin.barcode'),
        width: '30%',
        render: (data) => <p className='mb-0' style={{fontWeight: 'bold', color: 'var(--blue)'}}>{data.awb_code}</p>,
    },
    {
        text: t('hub.scannin.receiver_name'),
        width: '30%',
        render: (data) => data.receiver_name,
    },
    {
        text: t('hub.scannin.receiver_address'),
        width: '30%',
        render: (data) => data.receiver_address,
    },
    {
        text: t('hub.scannin.receiver_zipcode'),
        width: '30%',
        render: (data) => data.receiver_zipcode,
    },
    {
        text: t('hub.scannin.hub_date'),
        width: '30%',
        render: (data) => data.hub_date,
    }
];