import React, {useCallback} from "react";
import DateTH from "../../../../Helper/date-th";

const UpdateHistoryShowData = React.memo(({data}) => {

    const getInfoDisplay = useCallback(
        (old_value,new_value) => {
            return (
                old_value === new_value ? <>{old_value}</> : (
                    <div className="d-flex flex-space-between w-100">
                        <div className="d-flex flex-column" style={{gap: '5px'}}>
                            <p style={{marginBottom: 0}}>{old_value ?? ''}</p>
                            <p style={{marginBottom: 0}}>(เก่า)</p>
                        </div>
                        <p>-></p>
                        <div className="d-flex flex-column" style={{gap: '5px'}}>
                            <p style={{marginBottom: 0}}>{new_value ?? ''}</p>
                            <p style={{marginBottom: 0}}>(ใหม่)</p>
                        </div>
                    </div>
                )
            );
        },
        [],
    );

    return (
        <table className="table table-bordered">
            <thead style={{ backgroundColor: '#3f75c1' }}>
            <tr style={{ color: '#FFFFFF' }}>
                <td style={{ width: '7%' }}>บาร์โค้ด</td>
                <td style={{ width: '7%' }}>วันที่ปรับปรุง</td>
                <td style={{ width: '7%' }}>บรรณาธิการ</td>
                <td style={{width: '7%'}}>น้ำหนัก</td>
                <td style={{width: '7%'}}>ความยาว</td>
                <td style={{width: '7%'}}>ความสูง</td>
                <td style={{width: '7%'}}>ความกว้าง</td>
                <td style={{width: '7%'}}>ขนาด</td>
                <td style={{width: '7%'}}>หมายเลขอ้างอิง</td>
                <td style={{width: '7%'}}>ใบแจ้งหนี้</td>
            </tr>
            </thead>
            <tbody>
                {data.map(row => (
                    <tr>
                        <td>{row.awb_code}</td>
                        <td><DateTH date={row.created_at}/></td>
                        <td>{row.cust_code}</td>
                        <td>{getInfoDisplay(row.old_weight,row.new_weight)}</td>
                        <td>{getInfoDisplay(row.old_length, row.new_length)}</td>
                        <td>{getInfoDisplay(row.old_height, row.new_height)}</td>
                        <td>{getInfoDisplay(row.old_width, row.new_width)}</td>
                        <td>{getInfoDisplay(row.old_size, row.new_size)}</td>
                        <td>{getInfoDisplay(row.old_refno, row.new_refno)}</td>
                        <td>{getInfoDisplay(row.old_invoice, row.new_invoice)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
})

export default UpdateHistoryShowData;