import React from "react";
import Nav from "react-bootstrap/Nav";
import { useHistory } from "react-router";

export default function DriverMenu() {
  const history = useHistory();

  return (
    <>
      <Nav.Item>
        <Nav.Link
          eventKey="/home"
          onClick={(e) => {
            history.push("StockIn");
          }}
        >
          รับสินค้า
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/home"
          onClick={(e) => {
            history.push("ReportStockOut");
          }}
        >
          พิมพ์ทะเบียนคุม (ขาออก)
        </Nav.Link>
      </Nav.Item>
    </>
  );
}
