import Modal from "react-bootstrap/Modal";
import {ExportProgressStatus} from "../../enums/ExportProgressStatus";
import "./exportProgress.css";
import { useState, useEffect } from 'react';

export const ExportProgress = ({ status, progression }) => {
    
    const [internalProgress, setInternalProgress] = useState({
                total: 0,
                totalPages: 0,
                current: 0,
                currentPage: 0, 
            });

    let text = '';
    switch (status){
        case ExportProgressStatus.DATA_DOWNLOADING:
            text = 'กำลังดาวน์โหลดข้อมูลการส่งออก';
            break;
        case ExportProgressStatus.PROCESSING_GENERATION:
            text = 'การสร้างรายงาน excel';
            break;
    }
    useEffect(() => {
        if (progression) {
        setInternalProgress({
            ...progression,
        });   
            document.getElementById('excel_progression_2').innerHTML = `รายการปัจจุบัน : ${progression.current}/${progression.total}`;
            document.getElementById('excel_progression_1').innerHTML = `หน้าปัจจุบัน : ${progression.currentPage}/${progression.totalPages}`;
        }
        else {
            setInternalProgress({
                total: 0,
                totalPages: 0,
                current: 0,
                currentPage: 0, 
            });
        }
    }, [progression]);
    const progressWidth = progression.total === 0 ? 0 : (progression.current * 100) / progression.total;
    const pageProgressWidth = progression.total === 0 ? 0 : (progression.currentPage * 100) / progression.totalPages;
    const current = internalProgress.current;
    const currentPage = internalProgress.currentPage;
    return (
        <Modal size="lg" onHide={() => {}} show={true} className="ExportProgressModal">
            <p style={{ width: '100%', textAlign: 'center', color: '#17a2b8' }}>{text}</p>
            <p id='excel_progression_1' style={{marginTop: '5px', marginBottom: '5px', color: '#17a2b8', fontWeight: 'bold'}}></p>
            <div className="progress">
                <div className={`progress-bar`}
                     style={{width: `${pageProgressWidth}%`}}
                     role="progressbar"
                     aria-valuenow={progression.currentPage}
                     aria-valuemin={0}
                     aria-valuemax={progression.totalPages} />
            </div>
            <p id='excel_progression_2' style={{marginTop: '5px', marginBottom: '5px', color: '#17a2b8', fontWeight: 'bold'}}>หน้าปัจจุบัน : {currentPage}/{progression.total}</p>
            <div className="progress">
                <div className={`progress-bar`}
                     style={{width: `${progressWidth}%`}}
                     role="progressbar"
                     aria-valuenow={progression.current}
                     aria-valuemin={0}
                     aria-valuemax={progression.total} />
            </div>
        </Modal>
    );
};
