import React, { useState, useEffect } from "react";
import FilterData from "./filterData";
import ShowData from "./showData";
import PdfPrint from "./pdfPrint";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { InsertData } from "../../../Service/service.goods";
import { swalSuccess, swalError } from "../../../Components/sweetAlert";
import { GetData } from "../../../Service/service.goods";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker, DatePicker,
} from '@material-ui/pickers';
import login from "../login";
import * as Icon from "react-bootstrap-icons";
import {addYears, format, getDate, getMonth, getYear, isAfter, isBefore, isSameDay} from "date-fns";
import {getStockOut} from "../../../Service/service.StockOut";
import "./style.css";
import ExcelFile from "react-data-export/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-data-export/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-data-export/dist/ExcelPlugin/elements/ExcelColumn";
import printJS from "print-js";
function ReportStockOut(props) {
    const { user } = props;
    const schema = Yup.object().shape({
        interval1: Yup.date().default(() => new Date()),
        interval2: Yup.date().default(() => new Date()),
    });
    const [shipmentStatus, setShipmentStatus] = useState(null);
    const [perPage, setPageSize] = useState(10);
    const [data, setData] = useState([]);
    const [page, setPage] = useState({
        current_page: 0,
        last_page: 1,
        total: 0,
    });
    const [pageNo, setCurrentPage] = useState(1);
    const [loading, Loading] = useState(0);
    const [errorMassNoData, setErrorMassNoData] = useState(0);
    const [excelData, setExcelData] = useState(null);
    useEffect(() => {
        fetchData({
            shipmentStatus: shipmentStatus,
            empCode: user.username,
            pageNo: pageNo,
            perPage: perPage,
            interval1: new Date(),
            interval2: new Date(),
        });
    }, []);

    async function fetchData(params) {
        params.interval1 = !!params.interval1 ? format(params.interval1, 'yyyy-MM-dd') : null;
        params.interval2 = !!params.interval2 ? format(params.interval2, 'yyyy-MM-dd') : null;
        Loading(1);
        let originalres = (await getStockOut(params)).data;
        let res = originalres?.paginated;
        if (res?.numRowsAll > 0) {
            setData(res.result);
            setPage({
                current_page: res.pageNo,
                last_page: res.maxPage,
                total: res.numRowsAll,
            });
            setErrorMassNoData(0);
            Loading(0);
            setExcelData(originalres.excel.map((row,index ) => ({
                ...row,
                index: index+1,
            })));
        } else {
            setCurrentPage(1);
            setPage({
                current_page: 1,
                last_page: 1,
                total: 0,
            });
            setErrorMassNoData(1);
            Loading(0);
            setExcelData(null);
        }
    }

    const handleChangeShipmentStatus = (e, values) => {
        setData([]);
        setErrorMassNoData([])
        setShipmentStatus(e);
        fetchData({
            shipmentStatus: e,
            empCode: user.username,
            pageNo: pageNo,
            perPage: perPage,
            interval1: values.interval1,
            interval2: values.interval2,
        });
    };

    const handleChangePageSize = (e, values) => {
        setData([]);
        setErrorMassNoData([])
        setPageSize(e);
        fetchData({
            shipmentStatus: shipmentStatus,
            empCode: user.username,
            pageNo: pageNo,
            perPage: e,
            interval1: values.interval1,
            interval2: values.interval2,
        });
    };

    const handleChangePage = (e, newPage, values) => {
        setData([]);
        setErrorMassNoData([])
        setCurrentPage(newPage);
        fetchData({
            shipmentStatus: shipmentStatus,
            empCode: user.username,
            pageNo: newPage,
            perPage: perPage,
            interval1: values.interval1,
            interval2: values.interval2,
        });
    };

    const handleChangeDateFilter = ({interval1,interval2}) => {
        setData([]);
        setErrorMassNoData([]);
        setCurrentPage(1);
        fetchData({
            shipmentStatus,
            empCode: user.username,
            pageNo: pageNo,
            perPage: perPage,
            interval1,
            interval2
        });
    };
    let timerId = null;
    const yearsToAdd = 543;
    const refreshYears = (date) => {
        timerId = setTimeout(() => {
            const rootElement = document.querySelector('.MuiPickersCalendarHeader-switchHeader');
            if(!rootElement){
                return;
            }
            const element = rootElement
                .querySelector('p');
            const text = element.textContent.split(' ');
            const year = (date ? getYear(date) : parseInt(text[1])) + yearsToAdd;
            element.textContent = `${text[0]} ${year}`;
        }, 50);
    };
    const dateFormat = (date) => {
        if(!date){
            return "";
        }
        const newDate = addYears(date,yearsToAdd);
        return format(newDate, 'MM/dd/yyyy');
    };
    const onYearChange = (date) => {
        refreshYears(date);
    };
    const onHandleDatePickerFocus = () => {
        refreshYears();
    };
    const style = {
        alignment: { wrapText: true}
    };
    const excelExport = (
        <>
            <ExcelFile
                element={
                    <button type="button" className="btn btn-primary" style={{marginRight: '5px',background: '#5765D9', border: 'none'}}>
                        <Icon.FileExcel /> พิมพ์
                    </button>
                }
                filename={`ทะเบียนคุมขาออก${user.username}${format(new Date(), 'yyyyMMdd')}`}
            >
                <ExcelSheet data={excelData} name={"WorkBook"}>
                    <ExcelColumn label="ลำดับ" value="index"/>
                    <ExcelColumn label="วันที่เปิดบิล" value="created_date" width={{ wpx: 250 }}/>
                    <ExcelColumn label="วันที่สแกนพัสดุ" value={(row) => `${row.Receiver_Date || ''} ${row.Receiver_Time }`} width={{ wpx: 250 }} />
                    <ExcelColumn label="เลขที่ใบขนส่ง/Order/สำหรับลูกค้า" value="awb_code" width={{ wpx: 200 }} />
                    <ExcelColumn label="ชื่อผู้รับ" value="receiver_name" width={{ wpx: 200 }} />
                    <ExcelColumn label="ที่อยู่" width={{ wpx: 250 }} value={(row) => `${row.receiver_address} ${row.receiver_amphoe || ''} ${row.receiver_province}`} />
                    <ExcelColumn label="เบอร์โทรผู้รับ" width={{ wpx: 160 }} value="receiver_phone" />
                    <ExcelColumn label="ชิ้น" value={(row) => 1} />
                    <ExcelColumn label="COD (บาท)" value="cod_cost" />
                    {/* <ExcelColumn label="หมายเหตุ" width={{ wpx: 250 }} value="remarkMG" /> */}
                    <ExcelColumn label="สถานะล่าสุด" value="shipment_status_name" />
                    <ExcelColumn label="แสกนสถานะ" value="scanned_status" />  
                </ExcelSheet>
            </ExcelFile>
        </>
    );
    const handlePrintData = () => {
        printJS({
            printable: "printStockOutReport",
            type: "html",
            scanStyles: false,
            css: [
                window.location.protocol+"//" + window.location.host + "/static/printerReport.css",
                "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
            ],
            style: "@page { size: 10cm 15cm !important; margin: 2mm !important; } ",
        });
    };
    const printBody = (
        <>
            <div style={{ display: "none" }}>
            {/* <div > */}
                <div id="printStockOutReport">
                    <PdfPrint data={excelData}  />
                </div>
            </div>
        </>
    )
    return (
        <>
            <Formik
                validationSchema={schema}
                initialValues={{
                    shipmentStatus: shipmentStatus,
                    empCode: user.username,
                    interval1: new Date(),
                    interval2: new Date(),
                }}
                onSubmit={(value) => {
                    value.empCode = user.username;
                }}
            >
                {({ errors, touched, handleBlur, setFieldValue, values }) => {
                    console.log({values})
                    console.log(addYears(new Date(), 543))
                    return (
                        <Form>
                            <div className="card mb-5">
                                <div className="card-body">
                                    <div className="container-fluid mt-2 mb-2">
                                        <div className="text-center text-title-color">
                                            <h2>พิมพ์ทะเบียนคุม (ขาออก)</h2>
                                        </div>
                                        <br />
                                        <div className="row ">
                                            <div className="col-12 col-md-6">
                                                {" "}
                                                <div className="form-group ">
                                                    <label>สถานะพัสดุ</label>
                                                    <br />
                                                    <div className="form-check form-check-inline ">
                                                        <input
                                                            style={{ width: "20px", height: "20px" }}
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="shipment_status"
                                                            id="shipment_status1"
                                                            defaultValue={null}
                                                            checked={shipmentStatus === null}
                                                            onChange={(e) => {
                                                                setFieldValue("shipmentStatus", null);
                                                                handleChangeShipmentStatus(null, values);
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="shipment_status1"
                                                        >
                                                            ทั้งหมด
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline ">
                                                        <input
                                                            style={{ width: "20px", height: "20px" }}
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="shipment_status"
                                                            id="shipment_status1"
                                                            defaultValue="1"
                                                            checked={shipmentStatus === "1" ? true : false}
                                                            onChange={(e) => {
                                                                setFieldValue("shipmentStatus", e.target.value);
                                                                handleChangeShipmentStatus(e.target.value, values);
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="shipment_status1"
                                                        >
                                                            1. รับเข้าระบบ
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            style={{ width: "20px", height: "20px" }}
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="shipment_status"
                                                            id="shipment_status2"
                                                            defaultValue="2"
                                                            checked={shipmentStatus === "2" ? true : false}
                                                            onChange={(e) => {
                                                                setFieldValue("shipmentStatus", e.target.value);
                                                                handleChangeShipmentStatus(e.target.value, values);
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="shipment_status2"
                                                        >
                                                            2. ศูนย์กระจายคัดแยกสินค้า
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            style={{ width: "20px", height: "20px" }}
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="shipment_status"
                                                            id="shipment_status3"
                                                            defaultValue="3"
                                                            checked={shipmentStatus === "3" ? true : false}
                                                            onChange={(e) => {
                                                                setFieldValue("shipmentStatus", e.target.value);
                                                                handleChangeShipmentStatus(e.target.value, values);
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="shipment_status3"
                                                        >
                                                            3. อยู่ระหว่างขนส่ง
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            style={{ width: "20px", height: "20px" }}
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="shipment_status"
                                                            id="shipment_status4"
                                                            defaultValue="4"
                                                            checked={shipmentStatus === "4" ? true : false}
                                                            onChange={(e) => {
                                                                setFieldValue("shipmentStatus", e.target.value);
                                                                handleChangeShipmentStatus(e.target.value, values);
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="shipment_status4"
                                                        >
                                                            4. ส่งภายในวันนี้
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="row" style={{width: '100%'}}>
                                                    <div className="col-md-6" style={{display: 'flex', alignItems: 'center', paddingLeft: 0}}>
                                                        <DatePicker
                                                            disableToolbar={true}
                                                            variant="inline"
                                                            margin="normal"
                                                            label="วันที่เริ่มต้น สแกนพัสดุ"
                                                            value={values.interval1}
                                                            onOpen={() => onHandleDatePickerFocus()}
                                                            onClose={() => clearTimeout(timerId)}
                                                            onChange={(date) => {
                                                                setFieldValue('interval1', date)
                                                            }}
                                                            onYearChange={onYearChange}
                                                            onMonthChange={onYearChange}
                                                            labelFunc={dateFormat}
                                                        />
                                                        {/* <button
                                                            style={{marginLeft: '5px'}}
                                                            onClick={() => setFieldValue('interval1', null)}
                                                            className="btn btn-danger">
                                                            <Icon.Trash/>
                                                        </button> */}
                                                    </div>
                                                    <div className="col-md-6" style={{display: 'flex', alignItems: 'center', padding: 0}}>
                                                        <DatePicker
                                                            disableToolbar={true}
                                                            variant="inline"
                                                            margin="normal"
                                                            id="date-picker-2"
                                                            label="วันที่สิ้นสุด สแกนพัสดุ"
                                                            onYearChange={onYearChange}
                                                            onMonthChange={onYearChange}
                                                            onOpen={() => onHandleDatePickerFocus()}
                                                            onClose={() => clearTimeout(timerId)}
                                                            value={values.interval2}
                                                            onChange={(date) => setFieldValue('interval2', date)}
                                                            labelFunc={dateFormat}
                                                        />
                                                        {/* <button
                                                            style={{marginLeft: '5px'}}
                                                            onClick={() => setFieldValue('interval2', null)}
                                                            className="btn btn-danger">
                                                            <Icon.Trash/>
                                                        </button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12" style={{display:'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                                <button type="button" onClick={() => {
                                                    if(values.interval1 && values.interval2){
                                                        if(isAfter(values.interval2, values.interval1) || isSameDay(values.interval2, values.interval1)){
                                                            handleChangeDateFilter(values);
                                                        }
                                                        else{
                                                            swalError({
                                                                text: 'เส้นขอบของช่วงเวลาแรกควรอยู่ก่อนช่วงที่สอง',
                                                            });
                                                        }
                                                    }
                                                    else{
                                                        handleChangeDateFilter(values);
                                                    }
                                                }} className="btn btn-primary" style={{marginRight: '5px',background: '#34BFA3', border: 'none'}}>
                                                    <Icon.Search /> ค้นหา
                                                </button>
                                                <button type="button"
                                                        onClick={() => handlePrintData()}
                                                        className="btn btn-primary" style={{marginRight: '5px',background: '#5867DD', border: 'none'}}>
                                                    <Icon.Printer /> พิมพ์ PDF
                                                </button>
                                                {excelData && excelExport}
                                            </div>
                                        </div>
                                        <hr className="mt-4 mb-4" />
                                        <h4>รายการรับสินค้า</h4>
                                        <FilterData
                                            perPage={perPage}
                                            handleChangePageSize={(pagesize) => handleChangePageSize(pagesize, values)}
                                        />
                                        <ShowData
                                            data={data}
                                            page={page}
                                            loading={loading}
                                            errorMassNoData={errorMassNoData}
                                            pageNo={pageNo}
                                            handleChangePage={(e,page) => handleChangePage(e ,page,values)}
                                            perPage={perPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {excelData && printBody}
        </>
    );
}

const mapStateToProps = (state) => ({
    user: state.Authentication,
});

export default connect(mapStateToProps)(ReportStockOut);
