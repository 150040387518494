import { BrowserRouter, Switch } from "react-router-dom";
import SwitchLayout from "./Layouts/SwitchLayout";
import Routes from "./Routes/Routes";
import { USERINFO } from "./actions/authen";
import { connect } from "react-redux";
import Error from "./Views/error/error";

function App(props) {
  const { username } = { ...props.Authentication };
  const { roleType } = { ...props.Authentication };
  return (
    <BrowserRouter>
      <Switch>
        {Routes.map((value) =>
          value.children
            .filter(
              (f) =>
                (username === null || username === "undefined" ? 0 : 1) >=
                f.protect
            )
            .map((chil) => (
              <SwitchLayout
                exact
                path={value.path + chil.path}
                component={chil.element}
                layout={value.layout}
                // key={nanoid()}
              />
            ))
        )}

        {/* {Routes.map((value) =>
          username === null || username === "undefined"
            ? value.children
                .filter((f) => (username === null || username === "undefined" ? 0 : 1) >= f.protect)
                .map((chil) => (
                  <SwitchLayout exact path={value.path + chil.path} component={chil.element} layout={value.layout} />
                ))
            : value.children
                .filter((f) => f.role.map((v) => v == roleType))
                .map((chil) => (
                  <SwitchLayout exact path={value.path + chil.path} component={chil.element} layout={value.layout} />
                ))
        )} */}
      </Switch>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => {
  return {
    USERINFO: () => dispatch(USERINFO()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
