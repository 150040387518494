import Instance from "../Helper/axios";
import {URL,URLTEST} from '../Helper/baseURL';

export async function AreaService(data) {
  console.log("data", data)
    try {
      const response = await Instance.post(URL + "ServiceArea/getServiceArea", data);
      return await response.data;
    } catch (error) {
      console.log("error", error);
    }
  }