import React from "react";
import Partsv from "./PartService"
import ImgCarousel from "./ImgCarousel"
import AdList from "../../mis/Ads/features/adList";

export default function Home() {
    return ( <div>
                <ImgCarousel/>
                <Partsv/>
                {/* <AdList /> */}
            </div>
    )
}