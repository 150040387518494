import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import * as Yup from "yup";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import { datacentral } from "../../../../Data/empData.json";
import { monthTH } from "../../../../Data/month-th.json";
import {
  GetPosition,
  GetCental,
  SaveJobAnnouncement,
} from "../../../../Service/service.Career";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormReactSelect from "../../../../Components/FormReactSelect"

export default function FormRecruit() {
  const history = useHistory();
  const param = useLocation();
  const [data, setData] = useState([]);
  const [dataCental, setDataCental] = useState([]);
  var year = new Date().getFullYear();
  var years = Array.from(new Array(60), (e, index) => year - index);
  const [loading, Loading] = useState(0);
  const schema = Yup.object().shape({
    position_code: Yup.string().required("กรุณาระบุ ตำแหน่ง"),
    amount: Yup.string().required("กรุณากรอก จำนวน"),
    work_location: Yup.string().required("กรุณากรอก สถานที่ปฏิบัติงาน"),
    ano_year: Yup.string().required("กรุณาระบุ ปี"),
    ano_month: Yup.string().required("กรุณาระบุ เดือน"),
    ano_day: Yup.string().required("กรุณาระบุ วันที่"),
    qualification_description: Yup.string().required("กรุณากรอก คุณสมบัติ"),
    job_description: Yup.string().required("กรุณากรอก รายละเอียด"),
    ano_status: Yup.string().required("กรุณาระบุ สถานะ"),
  });

  useEffect(() => {
    fetchData();
    fetchDataCentel();
  }, []);

  async function fetchData() {
    setData([]);
    let res = await GetPosition();
    if (res.statusCode === 1) {
      setData(res.data);
    } else {
      setData([]);
    }
  }

  async function fetchDataCentel() {
    setDataCental([]);
    let res = await GetCental();
    if (res.statusCode === 1) {
      setDataCental(res.data);
    } else {
      setDataCental([]);
    }
  }

  async function SaveData(data) {
    Loading(1);
    console.log("dataSave", data);
    let res = await SaveJobAnnouncement(data);
    console.log("resSave", res);
    if (res.status === 1) {
      swal({
        title: res.message,
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      history.push("/mis/FilterData");
    } else {
      swal({
        title: res.message,
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
    Loading(0);
  }

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          code: param.state !== undefined ? param.state.value.code : "",
          position_code:
            param.state !== undefined ? param.state.value.position_code : "",
          amount: param.state !== undefined ? param.state.value.amount : "",
          work_location:
            param.state !== undefined ? param.state.value.work_location : "",
          ano_year: param.state !== undefined ? param.state.value.ano_year : "",
          ano_month:
            param.state !== undefined
              ? (param.state.value.ano_month < 9 ? "0" : "") +
              param.state.value.ano_month
              : "",
          ano_day:
            param.state !== undefined
              ? (param.state.value.ano_day < 9 ? "0" : "") +
              param.state.value.ano_day
              : "",
          qualification_description:
            param.state !== undefined
              ? param.state.value.qualification_description
              : "",
          job_description:
            param.state !== undefined ? param.state.value.job_description : "",
          ano_status:
            param.state !== undefined ? param.state.value.ano_status : "1",
        }}
        onSubmit={(value) => {
          SaveData({
            code: value.code,
            position_code: value.position_code,
            amount: value.amount,
            work_location: value.work_location,
            ano_date:
              value.ano_year + "-" + value.ano_month + "-" + value.ano_day,
            qualification_description: value.qualification_description,
            job_description: value.job_description,
            ano_status: value.ano_status,
          });
          console.log(value);
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div>
              <nav aria-label="breadcrumb">
                <ol
                  className="breadcrumb"
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <li className="breadcrumb-item">
                    <buton
                      type="button"
                      className="btn-link"
                      style={{ color: "#000000" }}
                      onClick={(e) => {
                        history.push("FilterData");
                      }}
                    >
                      ข้อมูลเปิดรับสมัครงาน
                    </buton>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    จัดการข้อมูลเปิดรับสมัครงาน
                  </li>
                </ol>
              </nav>
              <>
                <div className="card">
                  <div className="card-body">
                    <div className="container-fluid mt-2 mb-2">
                      <div className="text-center text-title-color">
                        <h2>จัดการข้อมูลเปิดรับสมัครงาน</h2>
                      </div>
                      <br />
                      <div
                        className="row"
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                      >
                        <div class="col-sm-4 px-md-1 mt-1">
                          <b>ตำแหน่ง</b>
                          <select
                            value={values.position_code}
                            onBlur={handleBlur}
                            className={
                              "form-control  " +
                              (getIn(touched, "position_code")
                                ? getIn(errors, "position_code")
                                  ? "is-invalid"
                                  : "is-valid"
                                : "")
                            }
                            name="position_code"
                            onChange={(e, v) => {
                              setFieldValue("position_code", e.target.value);
                            }}
                            aria-label="Default select example"
                          >
                            <option value="">-- ตำแหน่ง --</option>
                            {data.map((value) => (
                              <option value={value.code}>{value.name}</option>
                            ))}
                          </select>
                          <ErrorMessage
                            component="div"
                            name={"position_code"}
                            className="invalid-feedback"
                          />
                        </div>
                        <div class="col-sm-2 px-md-1 mt-1">
                          <b>จำนวน (ตำแหน่ง)</b>
                          <input
                            type="text"
                            value={values.amount}
                            onBlur={handleBlur}
                            className={
                              "form-control  " +
                              (getIn(touched, "amount")
                                ? getIn(errors, "amount")
                                  ? "is-invalid"
                                  : "is-valid"
                                : "")
                            }
                            name="amount"
                            onChange={(e) => {
                              setFieldValue("amount", e.target.value);
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            name={"amount"}
                            className="invalid-feedback"
                          />
                        </div>
                        <div class="col-sm-2 px-md-1 mt-1">
                          <b>สถานที่ปฏิบัติงาน</b>
                          {/* <select
                            value={values.work_location}
                            onBlur={handleBlur}
                            className={
                              "form-control  " +
                              (getIn(touched, "work_location")
                                ? getIn(errors, "work_location")
                                  ? "is-invalid"
                                  : "is-valid"
                                : "")
                            }
                            name="work_location"
                            onChange={(e) => {
                              setFieldValue("work_location", e.target.value);
                            }}
                            aria-label="Default select example"
                          >
                            <option value="">-- สถานที่ปฏิบัติงาน --</option>
                            {dataCental.map((value) => (
                              <option value={value.name}>{value.name}</option>
                            ))}
                          </select>
                          <ErrorMessage
                            component="div"
                            name={"work_location"}
                            className="invalid-feedback"
                          /> */}
                          <FormReactSelect
                            touched={touched}
                            errors={errors}
                            data={dataCental}
                            values={values.work_location}
                            onChange={(value) => {
                              setFieldValue(`work_location`, value);
                            }}
                            name="work_location"
                            labelKey="name"
                            keyValue="name"
                            errorsMess="กรุณาเลือกสถานที่ปฏิบัติงาน"
                            placeholder="-- กรุณาเลือกสถานที่ปฏิบัติงาน --"
                          />
                        </div>
                        <div className="col-sm-4 px-md-3 mt-1">
                          <b>วันที่เผยแพร่</b>
                          <div className="row">
                            <div className="col-sm-4 px-md-1">
                              <select
                                onBlur={handleBlur}
                                className={
                                  "form-control  dropdown-custom2  " +
                                  (getIn(touched, "ano_year")
                                    ? getIn(errors, "ano_year")
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "") +
                                  ""
                                }
                                name={"ano_year"}
                                onChange={(e) => {
                                  setFieldValue("ano_year", e.target.value);
                                }}
                                value={values.ano_year}
                              >
                                <option value={""} key={"123"}>
                                  {" "}
                                  -- ระบุปี --{" "}
                                </option>
                                {years.map((year, index) => (
                                  <option key={index} value={year}>
                                    {year + 543}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                component="div"
                                name={"ano_year"}
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-sm-4 px-md-1">
                              <select
                                onBlur={handleBlur}
                                className={
                                  "form-control  dropdown-custom2  " +
                                  (getIn(touched, "ano_month")
                                    ? getIn(errors, "ano_month")
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "") +
                                  ""
                                }
                                name={"ano_month"}
                                onChange={(e) => {
                                  setFieldValue("ano_month", e.target.value);
                                }}
                                value={values.ano_month}
                              >
                                <option value={""} key={"151"}>
                                  {" "}
                                  -- ระบุเดือน --{" "}
                                </option>
                                {monthTH.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.monthName}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                component="div"
                                name={"ano_month"}
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-sm-4 px-md-1">
                              <select
                                onBlur={handleBlur}
                                className={
                                  "form-control  dropdown-custom2  " +
                                  (getIn(touched, "ano_day")
                                    ? getIn(errors, "ano_day")
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "") +
                                  ""
                                }
                                name={"ano_day"}
                                onChange={(e) => {
                                  setFieldValue("ano_day", e.target.value);
                                }}
                                value={values.ano_day}
                              >
                                <option value={""}> -- ระบุวัน -- </option>
                                {Array.from(Array(31), (e, i) => (
                                  <option
                                    value={(i < 9 ? "0" : "") + (i + 1)}
                                    key={i}
                                  >
                                    {(i < 9 ? "0" : "") + (i + 1)}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                component="div"
                                name={"ano_day"}
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <br />

                        <div className="col-sm-6 px-md-1 mt-3">
                          <b>คุณสมบัติ</b>
                          <div
                            className={
                              (getIn(touched, "qualification_description")
                                ? getIn(errors, "qualification_description")
                                  ? "is-invalid border-ct"
                                  : "is-valid is-valid-ct"
                                : "") + ""
                            }
                          >
                            <CKEditor
                              helperText={
                                touched.qualification_description &&
                                errors.qualification_description
                              }
                              name="qualification_description"
                              editor={ClassicEditor}
                              data={values.qualification_description}
                              onChange={(event, editor) => {
                                setFieldValue(
                                  "qualification_description",
                                  editor.getData()
                                );
                              }}
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name={"qualification_description"}
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-sm-6 px-md-1 mt-3">
                          <b>รายละเอียดงาน</b>
                          <div
                            className={
                              (getIn(touched, "job_description")
                                ? getIn(errors, "job_description")
                                  ? "is-invalid border-ct"
                                  : "is-valid is-valid-ct"
                                : "") + ""
                            }
                          >
                            <CKEditor
                              helperText={
                                touched.job_description &&
                                errors.job_description
                              }
                              name="job_description"
                              editor={ClassicEditor}
                              data={values.job_description}
                              onChange={(event, editor) => {
                                setFieldValue(
                                  "job_description",
                                  editor.getData()
                                );
                              }}
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="job_description"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-sm-12 px-md-1 mt-1">
                          <br />
                          <b>สถานะเปิดรับสมัคร</b>
                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                checked={
                                  values.ano_status === "1" ? true : false
                                }
                                style={{ width: "20px", height: "20px" }}
                                className={
                                  "form-check-input " +
                                  (getIn(touched, "ano_status")
                                    ? getIn(errors, "ano_status")
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "") +
                                  ""
                                }
                                type="radio"
                                name="ano_status"
                                id="inlineRadio1"
                                value="1"
                                onChange={(e) => {
                                  setFieldValue("ano_status", e.target.value);
                                }}
                              />
                              <label
                                className="form-check-label"
                                for="inlineRadio1"
                              >
                                เปิดรับสมัคร
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                checked={
                                  values.ano_status === "0" ? true : false
                                }
                                style={{ width: "20px", height: "20px" }}
                                className={
                                  "form-check-input " +
                                  (getIn(touched, "ano_status")
                                    ? getIn(errors, "ano_status")
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "") +
                                  ""
                                }
                                type="radio"
                                name="ano_status"
                                id="inlineRadio0"
                                value="0"
                                onChange={(e) => {
                                  setFieldValue("ano_status", e.target.value);
                                }}
                              />
                              <label
                                className="form-check-label"
                                for="inlineRadio0"
                              >
                                ปิดรับสมัคร
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <center>
                        <button type="submit" className="btn btn-primary">
                          {loading === 1 ? (
                            <center>
                              <div
                                style={{ width: "20px", height: "20px" }}
                                className="spinner-border"
                                role="status"
                              ></div>
                            </center>
                          ) : (
                            <div>
                              <Icon.Save /> บันทึก
                            </div>
                          )}
                        </button>{" "}
                        <button
                          type="reset"
                          className="btn btn-secondary"
                          onClick={() => {
                            setFieldValue("position_code", "");
                            setFieldValue("amount", "");
                            setFieldValue("work_location", "");
                            setFieldValue("ano_year", "");
                            setFieldValue("ano_month", "");
                            setFieldValue("ano_day", "");
                            setFieldValue("ano_status", "1");
                          }}
                        >
                          <Icon.ArrowClockwise /> ล้างค่า
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </Form>
        )}
      </Formik>
      <br />
      <br />
    </>
  );
}
