import React from "react";
import { FieldArray, ErrorMessage, getIn } from "formik";
import { nanoid } from "nanoid";
import * as Icon from "react-bootstrap-icons";

export default function Tap2({
  errors,
  touched,
  handleBlur,
  setFieldValue,
  values,
  param,
  step,
  Del,
}) {
  return (
    <>
      <div className={step === 2 ? "" : "hidden-ct"}>
        <br />
        <div align="left" className={"text-title-color"}>
          <b>ประวัติครอบครัว (Family history)</b>
        </div>
        <br />
        <div className="card">
          <div className="card-body px-md-3">
            <div>
              <span>ข้อมูลบิดา</span>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 px-md-2 mt-1">
                      <span>ชื่อ</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.father.name}
                        type="text"
                        name={"familyHistory.father.name"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.father.name")
                            ? getIn(errors, "familyHistory.father.name")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "familyHistory.father.name",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.father.name"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-6 px-md-2 mt-1">
                      <span>นามสกุล</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.father.lastName}
                        type="text"
                        name={"familyHistory.father.lastName"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.father.lastName")
                            ? getIn(errors, "familyHistory.father.lastName")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "familyHistory.father.lastName",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.father.lastName"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-2 px-md-2 mt-1">
                      <span>อายุ (ปี)</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.father.age}
                        type="text"
                        name={"familyHistory.father.age"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.father.age")
                            ? getIn(errors, "familyHistory.father.age")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "familyHistory.father.age",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.father.age"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-5 px-md-2 mt-1">
                      <span>อาชีพ</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.father.career}
                        type="text"
                        name={"familyHistory.father.career"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.father.career")
                            ? getIn(errors, "familyHistory.father.career")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "familyHistory.father.career",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.father.career"}
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ////////////////////////////// */}
            <br />
            <div className="mt-1">
              <span>ข้อมูลมารดา</span>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 px-md-2 mt-1">
                      <span>ชื่อ</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.mother.name}
                        type="text"
                        name={"familyHistory.mother.name"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.mother.name")
                            ? getIn(errors, "familyHistory.mother.name")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "familyHistory.mother.name",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.mother.name"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-6 px-md-2 mt-1">
                      <span>นามสกุล</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.mother.lastName}
                        type="text"
                        name={"familyHistory.mother.name"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.mother.lastName")
                            ? getIn(errors, "familyHistory.mother.lastName")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "familyHistory.mother.lastName",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.mother.lastName"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-2 px-md-2 mt-1">
                      <span>อายุ (ปี)</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.mother.age}
                        type="text"
                        name={"familyHistory.mother.age"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.mother.age")
                            ? getIn(errors, "familyHistory.mother.age")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "familyHistory.mother.age",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.mother.age"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-5 px-md-2 mt-1">
                      <span>อาชีพ</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.mother.career}
                        type="text"
                        name={"familyHistory.mother.career"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.mother.career")
                            ? getIn(errors, "familyHistory.mother.career")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "familyHistory.mother.career",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.mother.career"}
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ////////////////////////////////////////// */}
            <br />
            <div classNae="mt-1">
              <span>ข้อมูลพี่น้อง </span>
              <div class="card">
                <div class="card-body">
                  <div className="row">
                    <div className="col-sm-3 px-md-2 mt-1">
                      <span>จำนวนพี่น้อง (รวมผู้สมัคร) </span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.brethrenAmont}
                        type="text"
                        name={"familyHistory.brethrenAmont"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.brethrenAmont")
                            ? getIn(errors, "familyHistory.brethrenAmont")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "familyHistory.brethrenAmont",
                            e.target.value
                          );
                          if (
                            e.target.value > 1 &&
                            values.familyAmont.length < 1
                          ) {
                            values.familyAmont.push({
                              key: nanoid(),
                              code: "",
                              titleName: "",
                              name: "",
                              lastName: "",
                              age: "",
                              career: "",
                            });
                          }
                          else {
                            setFieldValue("familyAmont", [])
                          }
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.brethrenAmont"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-3 px-md-2 mt-1">
                      <span>ชาย (คน)</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.male}
                        type="text"
                        name={"familyHistory.male"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.male")
                            ? getIn(errors, "familyHistory.male")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("familyHistory.male", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.male"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-3 px-md-2 mt-1">
                      <span>หญิง (คน)</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.female}
                        type="text"
                        name={"familyHistory.female"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.female")
                            ? getIn(errors, "familyHistory.female")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("familyHistory.female", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.female"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-3 px-md-2 mt-1">
                      <span>เป็นบุตรคนที่ (คน)</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.familyHistory.personWho}
                        type="text"
                        name={"familyHistory.personWho"}
                        className={
                          "form-control  " +
                          (getIn(touched, "familyHistory.personWho")
                            ? getIn(errors, "familyHistory.personWho")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "familyHistory.personWho",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"familyHistory.personWho"}
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* /////////////////////////////////////////////////////////////////////////////// */}
            <div
              className={
                values.familyHistory.brethrenAmont > 1
                  ? "row px-md-2"
                  : "hidden-ct"
              }
            >
              <div className="col-sm-12 px-md-2">
                <FieldArray name="familyAmont">
                  {({ push, remove }) =>
                    values.familyAmont.map((value, index) => (
                      <div key={value.key}>
                        <br />
                        <span>
                          พี่น้องคนที่
                          {" " + (index + 1)}
                        </span>
                        <div className="card">
                          <div className="card-body">
                            <div>
                              <div className="row">
                                <div className="col-sm-2 px-md-2 mt-1">
                                  <span>คำนำหน้าชื่อ</span>
                                  <span className="important-ct">*</span>
                                  <select
                                    value={value.titleName}
                                    name={`familyAmont[${index}].titleName`}
                                    className={
                                      "form-control  " +
                                      (getIn(
                                        touched,
                                        `familyAmont[${index}].titleName`
                                      )
                                        ? getIn(
                                            errors,
                                            `familyAmont[${index}].titleName`
                                          )
                                          ? "is-invalid"
                                          : "is-valid"
                                        : "")
                                    }
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `familyAmont[${index}].titleName`,
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value="">-- คำนำหน้าชื่อ --</option>
                                    <option value="นาย">นาย</option>
                                    <option value="นาง">นาง</option>
                                    <option value="นางสาว">นางสาว</option>
                                  </select>
                                  <ErrorMessage
                                    component="div"
                                    name={`familyAmont[${index}].titleName`}
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="col-sm-5 px-md-2 mt-1">
                                  <span>ชื่อ</span>
                                  <span className="important-ct">*</span>
                                  <input
                                    value={value.name}
                                    type="text"
                                    name={`familyAmont[${index}].name`}
                                    className={
                                      "form-control  " +
                                      (getIn(
                                        touched,
                                        `familyAmont[${index}].name`
                                      )
                                        ? getIn(
                                            errors,
                                            `familyAmont[${index}].name`
                                          )
                                          ? "is-invalid"
                                          : "is-valid"
                                        : "")
                                    }
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `familyAmont[${index}].name`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name={`familyAmont[${index}].name`}
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="col-sm-5 px-md-2 mt-1">
                                  <span>นามสกุล</span>
                                  <span className="important-ct">*</span>
                                  <input
                                    value={value.lastName}
                                    type="text"
                                    className={
                                      "form-control  " +
                                      (getIn(
                                        touched,
                                        `familyAmont[${index}].lastName`
                                      )
                                        ? getIn(
                                            errors,
                                            `familyAmont[${index}].lastName`
                                          )
                                          ? "is-invalid"
                                          : "is-valid"
                                        : "")
                                    }
                                    onBlur={handleBlur}
                                    name={`familyAmont[${index}].lastName`}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `familyAmont[${index}].lastName`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name={`familyAmont[${index}].lastName`}
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="col-sm-2 px-md-2 mt-1">
                                  <span>อายุ (ปี)</span>
                                  <span className="important-ct">*</span>
                                  <input
                                    value={value.age}
                                    type="text"
                                    className={
                                      "form-control  " +
                                      (getIn(
                                        touched,
                                        `familyAmont[${index}].age`
                                      )
                                        ? getIn(
                                            errors,
                                            `familyAmont[${index}].age`
                                          )
                                          ? "is-invalid"
                                          : "is-valid"
                                        : "")
                                    }
                                    onBlur={handleBlur}
                                    name={`familyAmont[${index}].age`}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `familyAmont[${index}].age`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name={`familyAmont[${index}].age`}
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="col-sm-5 px-md-2 mt-1">
                                  <span>อาชีพ</span>
                                  <span className="important-ct">*</span>
                                  <input
                                    value={value.career}
                                    type="text"
                                    className={
                                      "form-control  " +
                                      (getIn(
                                        touched,
                                        `familyAmont[${index}].career`
                                      )
                                        ? getIn(
                                            errors,
                                            `familyAmont[${index}].career`
                                          )
                                          ? "is-invalid"
                                          : "is-valid"
                                        : "")
                                    }
                                    onBlur={handleBlur}
                                    name={`familyAmont[${index}].career`}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `familyAmont[${index}].career`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name={`familyAmont[${index}].career`}
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div
                                  className="col-sm-5 px-md-2 mt-1"
                                  align="right"
                                >
                                  <br />
                                  <button
                                    className="btn btn-success"
                                    type="button"
                                    onClick={() => {
                                      push({
                                        key: nanoid(),
                                        code: "",
                                        titleName: "",
                                        name: "",
                                        lastName: "",
                                        age: "",
                                        career: "",
                                      });
                                    }}
                                  >
                                    <Icon.PlusCircleFill />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => {
                                      if (value.code === "") {
                                        remove(index);
                                      } else {
                                        remove(index);
                                        Del({
                                          code: value.code,
                                          table: "applicants_family",
                                        });
                                      }
                                    }}
                                    disabled={values.familyAmont.length === 1}
                                  >
                                    <Icon.TrashFill />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
              </div>
            </div>
            <br />
            <div
              className={
                values.information.status === "แต่งงาน" ? "mt-1" : "hidden-ct"
              }
            >
              <span>ข้อมูลภรรยา/สามี </span>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    {/* <div className="col-sm-2 px-md-2 mt-1">
                      <span>คำนำหน้าชื่อ</span>
                      <span className="important-ct">*</span>
                      <select
                        value={values.information.titleNameSpouse}
                        name={"information.titleNameSpouse"}
                        className={
                          "form-control  " +
                          (getIn(touched, "information.titleNameSpouse")
                            ? getIn(errors, "information.titleNameSpouse")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "information.titleNameSpouse",
                            e.target.value
                          );
                        }}
                      >
                        <option selected>-- คำนำหน้าชื่อ --</option>
                        <option value="นาย">นาย</option>
                        <option value="นาง">นาง</option>
                        <option value="นางสาว">นางสาว</option>
                      </select>
                      <ErrorMessage
                        component="div"
                        name={"information.titleNameSpouse"}
                        className="invalid-feedback"
                      />
                    </div> */}
                    <div className="col-sm-6 px-md-2 mt-1">
                      <span>ชื่อ</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.information.nameSpouse}
                        type="text"
                        name={"information.nameSpouse"}
                        className={
                          "form-control  " +
                          (getIn(touched, "information.nameSpouse")
                            ? getIn(errors, "information.nameSpouse")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "information.nameSpouse",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"information.nameSpouse"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-6 px-md-2 mt-1">
                      <span>นามสกุล</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.information.lastNameSpouse}
                        type="text"
                        name={"information.lastNameSpouse"}
                        className={
                          "form-control  " +
                          (getIn(touched, "information.lastNameSpouse")
                            ? getIn(errors, "information.lastNameSpouse")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "information.lastNameSpouse",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"information.lastNameSpouse"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-4 px-md-2 mt-1">
                      <span>ชื่อสถานที่ทำงาน</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.information.workplaceSpouse}
                        type="text"
                        name={"information.workplaceSpouse"}
                        className={
                          "form-control  " +
                          (getIn(touched, "information.workplaceSpouse")
                            ? getIn(errors, "information.workplaceSpouse")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "information.workplaceSpouse",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"information.workplaceSpouse"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-4 px-md-2 mt-1">
                      <span>ตำแหน่ง</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.information.positionSpouse}
                        type="text"
                        name={"information.positionSpouse"}
                        className={
                          "form-control  " +
                          (getIn(touched, "information.positionSpouse")
                            ? getIn(errors, "information.positionSpouse")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "information.positionSpouse",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"information.positionSpouse"}
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-sm-4 px-md-2 mt-1">
                      <span>มีบุตร (คน)</span>
                      <span className="important-ct">*</span>
                      <input
                        value={values.information.childAmontSpouse}
                        type="text"
                        name={"information.childAmontSpouse"}
                        className={
                          "form-control  " +
                          (getIn(touched, "information.childAmontSpouse")
                            ? getIn(errors, "information.childAmontSpouse")
                              ? "is-invalid"
                              : "is-valid"
                            : "")
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "information.childAmontSpouse",
                            e.target.value
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        name={"information.childAmontSpouse"}
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
