import React, { useState, useEffect, createRef } from 'react';
import porlorIMG from '../../../Assets/images/logo/logoNavbar.png';
import newLogoNavbar from '../../../Assets/images/logo/newLogoNavbar.png';
import ponorIMG from '../../../Assets/images/logo/thailand_post.png';
import Porlor from './porlor';
import Pornor from './pornor';
import swal from 'sweetalert';
import {
  ParcelService,
  UpdatePrintStatus,
  UpdateReceiptPrint,
  CheckCitizen,
  CheckReceiver,
  CheckSize
} from '../../../Service/service.Parcels';
import FormatLabel from '../formatLabel';
import FormatLabelCod from '../formatLabelCod';
import FormatLabelAddress from '../formatLabelAddress';
import FormatLabelAddressCod from '../formatLabelAddressCod';
import FormatReceipt from '../formatLabel/formatReceipt';
import FormatLabelPornor from '../formatLabelPornor/formatLabelPornor';
import FormatLabelAddressPornor from '../formatLabelPornor/formatLabelAddressPornor';
import FormatReceiptPornor from '../formatLabelPornor/formatReceiptPornor';
import PrintLabel from '../printLabel/printLabel';
import { nanoid } from 'nanoid';
import printJS from 'print-js';
import Modal from 'react-bootstrap/Modal';
import {ConotePrintModel} from "../../../Components/ConotePrintModel/ConotePrintModel";
import {conoteBarcodes, update_ref_no_status} from "../printLabel/conoteModal/conoteService";
import PrintContent from "../printLabel/conoteModal/printContentsOLD";
import {swalError} from "../../../Components/sweetAlert";


export default function Parcel() {
  const [typeSender, setTypeSender] = useState('0');
  var year = new Date().getFullYear();
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  var today = new Date(),
    time = today.getHours() + ':' + today.getMinutes();
  const [loading, Loading] = useState(0);
  const [printLebel, setPrintLebel] = useState([]);
  var data = [];
  const [citizens,setCitizens] = useState([]);
  const [receiverList,setReceiverList] = useState([]);
  const [showModalCitizen,setShowModalCitizen] = useState(false);
  const [showModalReceiver,setShowModalReceiver] = useState(false);
  const [citizen,setCitizen] = useState("");
  const [valuesReady,setValuesReady] = useState(false);
  const [indexCitizen,setIndexCitizen] = useState(0);
  const [statusPrint, setStatusPrint] = useState(0);
  const [dataArray, setDataArray] = useState('');
  const [shipmentPrice, setShipmentPrice] = useState(0);
  const [serviceOrter, setServiceOrter] = useState(0);
  const [freightPrice, setFreightPrice] = useState(0);
  const [getMoney, setGetMoney] = useState('');
  const [changePrice, setChangePrice] = useState(0);
  const [receiptCode, setReceiptCode] = useState('');
  const [barcode, Barcode] = useState("");
  const [codCost, setCodCost] = useState(0);
  const [serviceCod, setServiceCod] = useState(0);
  const [bankId, setBankId] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [typeLabel, setTypeLabel] = useState(0);
  const [conotePrintShow,setConotePrintShow] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [conoteShow, setConoteShow] = useState(false);
  const [printShow, setPrintShow] = useState(false);
  const handlePrintClose = () => {
    setPrintShow(false);
    if(localStorage.getItem('superkey') === 'credit' && localStorage.getItem('show_conote') === '1'){
      setConoteShow(true);
    }
  }
  const handlePrintShow = () => setPrintShow(true);
  const handleConotePrintShow = (value) => setConotePrintShow(value);
  const [conoteData, setConoteData]= useState(null);
  const conoteRef = createRef();

  async function launchConote(ref_no){
    if(localStorage.getItem('superkey') === 'credit' && ref_no!==''){
      const conote = await conoteBarcodes(ref_no, true);
      conote.data.data.res[0].groupData = conote.data.conoteData;
      setConoteData(conote.data.data.res[0]);
    }
  }
  async function SaveData(value) {
    Loading(1);
    try {
      const res = await ParcelService(value.dataArray);
      await launchConote(value.dataArray.sender.ref_no);
      // await launchConote(value.dataArray.recipient[0].conote);
      if (res.status === 1) {
        setPrintLebel(res.data);
        setReceiptCode(res.receipt_code);
        Barcode(res.data.map(x => x["awb_code"]).join(','));
        
        handleClose();
        UpdatePrint({
          typeLabel: value.labelType,
        });
        res.data.filter((x) => x.bank_id !== '').map((value) => setBankId(value.bank_id));
        res.data.filter((x) => x.bookbank_code !== '').map((value) => setBankCode(value.bookbank_code));
        res.data.filter((x) => x.bookbank_name !== '').map((value) => setBankName(value.bookbank_name));

        var cod = 0;
        res.data.map((value) => (cod = cod + parseInt(value.cod_cost)));
        setCodCost(cod);

        var serCod = 0;
        res.data.map((value) => (serCod = serCod + value.service_cod));
        setServiceCod(serCod);

        var total = 0;
        if (localStorage.getItem('superkey') === 'credit' && localStorage.getItem('requireDimension') === '0'){
          res.data.map((value) => (total = 0));
          setFreightPrice(total);

          var shipmentPrice = 0;
          res.data.map((value) => (shipmentPrice = 0));
          setShipmentPrice(shipmentPrice);

          var serviceOrter = 0;
          res.data.map((value) => (serviceOrter =  0));
          setServiceOrter(serviceOrter);  
        }
        else {
          res.data.map((value) => (total = total + (value.shipment_cost + value.service_cod) + (value.cust_type === '2' ? value.shipment_cost * 0.1 : 0)));
          setFreightPrice(total);

          var shipmentPrice = 0;
          res.data.map((value) => (shipmentPrice = shipmentPrice + value.shipment_cost));
          setShipmentPrice(shipmentPrice);

          var serviceOrter = 0;
          res.data.map((value) => (serviceOrter = serviceOrter + value.service_cod + (value.cust_type === '2' ? value.shipment_cost * 0.1 : 0)));
          setServiceOrter(serviceOrter);
        }
        
      }
      if (res.status === 0) {
        swal({
          title: res.message,
          text: 'อำเภอ : ' + res.amphoe + ', จังหวัด : ' + res.province,
          icon: 'warning',
          button: 'ปิด',
        });
      }
    } catch (e) {
      console.log(e);
      alert('เกิดข้อผิดพลาด เซิร์ฟเวอร์มีปัญหา');
    }

    Loading(0);
  }

  async function SaveData2(value) {
    Loading(1);
    const res = await ParcelService(value.dataArray);
    await launchConote(value.dataArray.sender.ref_no);
    // await launchConote(value.dataArray.recipient[0].conote);
    try {
      if (res.status === 1) {
        setPrintLebel(res.data);
        setReceiptCode(res.receipt_code);
        Barcode(res.data.map(x => x["awb_code"]).join(','));
        handleClose();

        res.data.filter((x) => x.bank_id !== '').map((value) => setBankId(value.bank_id));
        res.data.filter((x) => x.bookbank_code !== '').map((value) => setBankCode(value.bookbank_code));
        res.data.filter((x) => x.bookbank_name !== '').map((value) => setBankName(value.bookbank_name));

        var cod = 0;
        res.data.map((value) => (cod = cod + parseInt(value.cod_cost)));
        setCodCost(cod);

        var serCod = 0;
        res.data.map((value) => (serCod = serCod + value.service_cod));
        setServiceCod(serCod);

        var total = 0;
        res.data.map((value) => (total = total + (value.shipment_cost + value.service_cod) + (value.cust_type === '2' ? value.shipment_cost * 0.1 : 0)));
        setFreightPrice(total);

        var shipmentPrice = 0;
        res.data.map((value) => (shipmentPrice = shipmentPrice + value.shipment_cost));
        setShipmentPrice(shipmentPrice);

        var serviceOrter = 0;
        res.data.map((value) => (serviceOrter = serviceOrter + value.service_cod + (value.cust_type === '2' ? value.shipment_cost * 0.1 : 0)));
        setServiceOrter(serviceOrter);
      }
      if (res.status === 0) {
        swal({
          title: res.message,
          text: 'อำเภอ : ' + res.amphoe + ', จังหวัด : ' + res.province,
          icon: 'warning',
          button: 'ปิด',
        });
      }
    } catch (e) {
      alert('เกิดข้อผิดพลาด เซิร์ฟเวอร์มีปัญหา');
    }

    Loading(0);
  }

  async function UpdatePrint(value) {
    const res = await UpdatePrintStatus(value);
    if (res.status === 1) {
      if (value.typeLabel === 1) {
        printJS({
          printable: 'labelNormal',
          type: 'html',
          scanStyles: false,
          css: [window.location.protocol + '//' + window.location.host + '/static/PrinterCOD.css', 'https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap'],
          style: '@page {size: 10cm 7cm;margin: 0cm;}',
          onLoadingEnd: () => {
            console.log('loadingEnd');
            if(localStorage.getItem('superkey') === 'credit'){
              setConoteShow(true);
            }
          }
        });
      } else {
        printJS({
          printable: 'labelAddress',
          type: 'html',
          scanStyles: false,
          css: [window.location.protocol + '//' + window.location.host + '/static/Printer.css', 'https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap'],
          style: '@page {size: 10cm 15cm;margin: 0.3cm;}',
          onLoadingEnd: () => {
            console.log('loadingEnd');
            if(localStorage.getItem('superkey') === 'credit'){
              setConoteShow(true);
            }
          }
        });
      }
      setStatusPrint(1);
      setDataArray('');
      if (localStorage.getItem('superkey') !== '1947' && localStorage.getItem('superkey') !== 'credit') {
        handleShow();
      }
    } else {
      alert('เกิดข้อผิดพลาด');
    }
  }

  async function receiptPrintUpdateStatus() {
    var daraArray = {
      receiptCode: receiptCode,
      freightPrice: freightPrice,
      getMoney: getMoney,
      changePrice: changePrice,
      awb_codes: barcode,
    };
    const res = await UpdateReceiptPrint(daraArray);
    if (res.status === 1) {
      receiptPrint();
    } else {
      alert('เกิดข้อผิดพลาด');
    }
  }

  async function receiptPrint() {
    printJS({
      printable: 'printReceipt',
      type: 'html',
      scanStyles: false,
      css: [window.location.protocol + '//' + window.location.host + '/static/Printer.css', 'https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap'],
      style: '@page {size: 8cm 30cm;margin: 0cm;}',
      onLoadingEnd: () => {
        if(localStorage.getItem('superkey') === 'credit'){
          setConoteShow(true);
        }
      }
    });
    setPrintLebel([]);
    setStatusPrint(0);
    handleClose();
    setFreightPrice('');
    setGetMoney('');
    setChangePrice(0);
  }

  async function checkCitizen(value) {
    const res = await CheckCitizen(value);
    if (res.status === 1) {
      setCitizens(res.data);
      return res.data[0];
    } else {
      return {};
    }
  }

  async function checkCitizenMultiple(value) {
    const res = await CheckCitizen(value);
    if (res.status === 1) {
      setCitizens(res.data);
      return res.data;
    } else {
      return [];
    }
  }

  async function checkReceiver(phone){
    const res = await CheckReceiver(phone);
    if (res.status === 1) {
      return res.data[0];
    } else {
      return {};
    }
  }
  async function checkReceiverMultiple(phone){
    const res = await CheckReceiver(phone);
    if (res.status === 1) {
      return res.data;
    } else {
      return {};
    }
  }

  async function checkSize(cust_code,size_name){
    const res = await CheckSize(cust_code,size_name);
    if (res.status === 1) {
      return res.data[0];
    } else {
      return {};
    }
  }

  useEffect(() => {
     if(conotePrintShow && conoteRef){
       printJS({
         printable: conoteRef.current,
         type: 'html',
         scanStyles: true,
         targetStyles: ['*'],
         css: [
           window.location.protocol + '//' + window.location.host + '/static/Conote.css',
           'https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap'],
         onLoadingEnd: () => {
           setConotePrintShow(false);
         },
         style: '@page {margin: 0cm;}',
       });
     }
  }, [conotePrintShow, conoteRef]);

  return (
    <>
      <div className="mb-5">
        <div className="row">
          <div className="col-12  col-md-12">
            <div className="card-sender">
              <div className="text-center text-title-color mb-4">
                <h2>ส่งพัสดุ</h2>
              </div>
              <div className="mb-5 tabs">
                {/* <div className={localStorage.getItem('superkey') !== '1947' && localStorage.getItem('superkey') !== 'credit' ? 'tab' : 'hidden-ct'}> */}
                <div className= "hidden-ct">
                  <div
                    className={'tab-header' + (typeSender === '0' ? ' active ' : '')}
                    onClick={() => {
                      setTypeSender('0');
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <img src={newLogoNavbar} alt="porlor logo" height={50} className="mr-3" />
                      <p className="mt-3">ปอลอ เอ็กซ์เพรส</p>
                    </div>
                  </div>
                  <div
                    className={'tab-header' + (typeSender === '1' ? '  active ' : ' ')}
                    onClick={() => {
                      setTypeSender('1');
                    }}
                    // disabled={true}
                    // className={"tab-header-disabled"}
                    // disabled
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <img src={ponorIMG} alt=" ไปรษณีย์ไทย" height={50} width={60} className="mr-3" />
                      <p className="mt-3">ไปรษณีย์ไทย</p>
                    </div>
                  </div>
                </div>

                <div className={'tab-contents' + (typeSender !== '0' ? '  hidden-ct ' : ' ')}>
                  <Porlor
                      SaveData={SaveData}
                      printLebel={printLebel}
                      loading={loading}
                      handleShow={handleShow}
                      setDataArray={setDataArray}
                      setStatusPrint={setStatusPrint}
                      checkReceiver={checkReceiver}
                      checkReceiverMultiple={checkReceiverMultiple}
                      checkCitizen={checkCitizen} 
                      checkSize = {checkSize}
                      checkCitizenMultiple = {checkCitizenMultiple}
                      setShowModalCitizen = {setShowModalCitizen}
                      indexCitizen = {indexCitizen}
                      valuesReady = {valuesReady}
                      setValuesReady = {setValuesReady}
                      citizen = {citizen}
                      showModalCitizen = {showModalCitizen}
                      citizenList = {citizens}
                      showModalReceiver = {showModalReceiver}
                      setShowModalReceiver = {setShowModalReceiver}
                      receiverList = {receiverList}
                      setReceiverList = {setReceiverList}

                      />
                </div>
                <div className={'tab-contents' + (typeSender !== '1' ? '  hidden-ct ' : ' ')}>
                  <Pornor SaveData={SaveData} printLebel={printLebel} loading={loading} handleShow={handleShow} setDataArray={setDataArray} setStatusPrint={setStatusPrint} checkCitizen={checkCitizen} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* /////////////////////////ใช้สำหรับพิมพ์ลาเบลเป็นชุด//////////////////////////////// */}
        <div style={{ display: 'none' }}>
          <div id="labelNormal">
            {printLebel.map((value, index) => (
              <div key={nanoid()}>
                {value.cust_type === '1' ? (
                  value.cod_cost === '0' ? (
                    <FormatLabel
                      key={nanoid()}
                      supplies={{
                        custCode: value.cust_code,
                        number: index + 1,
                        receiverName: value.receiver_name,
                        awbCode: value.awb_code,
                        date: date,
                        month: month,
                        year: year,
                        weight: value.weight,
                        box_height:value.box_height,
                        box_length: value.box_length,
                        box_size: value.box_size ,
                        box_width: value.box_width,
                        codCost: value.cod_cost,
                        deposit_type: value.deposit_type,
                        deposit_fullname: value.deposit_fullname,
                        deposit_phone: value.deposit_phone,
                        ref_no: value.ref_no,
                        invoice: value.invoice,
                        return_doc: value.return_doc,
                        num_return_doc: value.num_return_doc,
                        ref_no2: value.ref_no2,
                        receiver_zipcode: value.receiver_zipcode,
                        item_desc: value.item_desc,
                      }}
                    />
                  ) : (
                    <FormatLabelCod
                      key={nanoid()}
                      supplies={{
                        custCode: value.cust_code,
                        number: index + 1,
                        receiverName: value.receiver_name,
                        awbCode: value.awb_code,
                        date: date,
                        month: month,
                        year: year,
                        weight: value.weight,
                        box_height:value.box_height,
                        box_length: value.box_length,
                        box_size: value.box_size ,
                        box_width: value.box_width,
                        codCost: value.cod_cost,
                        deposit_type: value.deposit_type,
                        deposit_fullname: value.deposit_fullname,
                        deposit_phone: value.deposit_phone,
                        ref_no: value.ref_no,
                        invoice: value.invoice,
                        return_doc: value.return_doc,
                        num_return_doc: value.num_return_doc,
                        ref_no2: value.ref_no2,
                        receiver_zipcode: value.receiver_zipcode,
                        item_desc: value.item_desc,
                      }}
                    />
                  )
                ) : (
                  <FormatLabelPornor
                    key={nanoid()}
                    supplies={{
                      cust_code: value.cust_code,
                      /////////////////
                      cod_cost: value.cod_cost,
                      /////////////////
                      awb_code: value.awb_code,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        {/* ////////////////////ใช้สำหรับพิมพ์ลาเบล/ที่อยู่เป็นชุด//////////////////// */}
        <div className="contentPrint2" style={{ display: 'none' }}>
          <div id="labelAddress">
            {printLebel.map((value, index) => (
              <div key={nanoid()}>
                {value.cust_type === '1' ? (
                  value.cod_cost === '0' ? (
                    <FormatLabelAddress
                      key={nanoid()}
                      supplies={{
                        receiver_name: value.receiver_name,
                        receiver_address: value.receiver_address,
                        receiver_district: value.receiver_district,
                        receiver_amphur: value.receiver_amphur,
                        receiver_province: value.receiver_province,
                        receiver_phone: value.receiver_phone,
                        cust_Code: value.cust_code,
                        number: index + 1,
                        awb_code: value.awb_code,
                        date: date,
                        month: month,
                        year: year,
                        weight: value.weight,
                        box_height:value.box_height,
                        box_length: value.box_length,
                        box_size: value.box_size ,
                        box_width: value.box_width,
                        codCost: value.cod_cost,
                        deposit_type: value.deposit_type,
                        deposit_fullname: value.deposit_fullname,
                        deposit_phone: value.deposit_phone,
                        sender_name: value.sender_name,
                        ref_no: value.ref_no,
                        invoice: value.invoice,
                        return_doc: value.return_doc,
                        num_return_doc: value.num_return_doc,
                        ref_no2: value.ref_no2,
                        receiver_zipcode: value.receiver_zipcode,
                        item_desc: value.item_desc,
                      }}
                    />
                  ) : (
                    <FormatLabelAddressCod
                      key={nanoid()}
                      supplies={{
                        receiver_name: value.receiver_name,
                        receiver_address: value.receiver_address,
                        receiver_district: value.receiver_district,
                        receiver_amphur: value.receiver_amphur,
                        receiver_province: value.receiver_province,
                        receiver_phone: value.receiver_phone,
                        cust_Code: value.cust_code,
                        number: index + 1,
                        awb_code: value.awb_code,
                        date: date,
                        month: month,
                        year: year,
                        weight: value.weight,
                        box_height:value.box_height,
                        box_length: value.box_length,
                        box_size: value.box_size ,
                        box_width: value.box_width,
                        codCost: value.cod_cost,
                        deposit_type: value.deposit_type,
                        deposit_fullname: value.deposit_fullname,
                        deposit_phone: value.deposit_phone,
                        sender_name: value.sender_name,
                        ref_no: value.ref_no,
                        invoice: value.invoice,
                        return_doc: value.return_doc,
                        num_return_doc: value.num_return_doc,
                        ref_no2: value.ref_no2,
                        receiver_zipcode: value.receiver_zipcode,
                        item_desc: value.item_desc,
                      }}
                    />
                  )
                ) : (
                  <FormatLabelAddressPornor
                    key={nanoid()}
                    supplies={{
                      cust_code: value.cust_code,
                      /////////////////
                      receiver_name: value.receiver_name,
                      receiver_phone: value.receiver_phone,
                      receiver_address: value.receiver_address,
                      receiver_district: value.receiver_district,
                      receiver_amphur: value.receiver_amphur,
                      receiver_province: value.receiver_province,
                      receiver_zipcode: value.receiver_zipcode,
                      /////////////////
                      sender_name: value.sender_name,
                      sender_phone: value.sender_phone,
                      sender_address: value.sender_address,
                      sender_amphoe: value.sender_amphoe,
                      sender_province: value.sender_province,
                      sender_zipcode: value.sender_zipcode,
                      /////////////////
                      cod_cost: value.cod_cost,
                      /////////////////
                      awb_code: value.awb_code,
                      weight: value.weight,
                      box_height:value.box_height,
                      box_length: value.box_length,
                      box_size: value.box_size ,
                      box_width: value.box_width,
                      ref_no: value.ref_no,
                      invoice: value.invoice,
                      return_doc: value.return_doc,
                      num_return_doc: value.num_return_doc,
                      ref_no2: value.ref_no2,
                      item_desc: value.item_desc,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        {/* ////////////////////ใช้สำหรับพิมพ์ใบเสร็จ//////////////////// */}
        <div className="contentPrint2" style={{ display: 'none' }}>
          <div id="printReceipt">
            <div key={nanoid()}>
              {printLebel.length > 0 ? (
                printLebel[0].cust_type === '1' ? (
                  <FormatReceipt
                    key={nanoid()}
                    supplies={{
                      receiptCode: receiptCode,
                      date: date,
                      month: month,
                      year: year,
                      time: time,
                      sender_name: printLebel.length > 0 ? printLebel[0].sender_name : '',
                      sender_phone: printLebel.length > 0 ? printLebel[0].sender_phone : '',
                      amont_parcel: printLebel.length > 0 ? printLebel.length : '',
                      freightPrice: freightPrice,
                      awb_codes: barcode,
                      getMoney: getMoney,
                      changePrice: changePrice,
                      printLebel: printLebel,
                      /////////////////
                      cod_cost: codCost,
                      service_cod: serviceCod,
                      bank_id: bankId,
                      bookbank_name: bankName,
                      bookbank_code: bankCode,
                    }}
                  />
                ) : (
                  <FormatReceiptPornor
                    key={nanoid()}
                    supplies={{
                      receiptCode: receiptCode,
                      date: date,
                      month: month,
                      year: year,
                      time: time,
                      sender_name: printLebel.length > 0 ? printLebel[0].sender_name : '',
                      receiver_name: '',
                      weight: '',
                      shipment_cost: '',
                      freightPrice: freightPrice,
                      awb_codes: barcode,
                      getMoney: getMoney,
                      changePrice: changePrice,
                      shipmentPrice: shipmentPrice,
                      serviceOrter: serviceOrter,
                      printLebel: printLebel,
                    }}
                  />
                )
              ) : (
                <div>ไม่สามารถสร้างใบเสร็จได้</div>
              )}

              <div name="modalPrintLabel">
                <Modal show={printShow} onHide={handlePrintClose} size="xl" dialogClassName="modal-content">
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <div
                      // className={typeLabel === "2" ? "" : "hidden-ct"}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '90%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    ></div>
                    {printLebel.map((value, index) => (
                      <div key={nanoid()}>
                        {value.cust_type === '1' ? (
                          value.cod_cost === '0' ? (
                            <FormatLabelAddress
                              key={nanoid()}
                              supplies={{
                                receiver_name: value.receiver_name,
                                receiver_address: value.receiver_address,
                                receiver_district: value.receiver_district,
                                receiver_amphur: value.receiver_amphur,
                                receiver_province: value.receiver_province,
                                receiver_phone: value.receiver_phone,
                                cust_Code: value.cust_code,
                                number: index + 1,
                                awb_code: value.awb_code,
                                date: date,
                                month: month,
                                year: year,
                                weight: value.weight,
                                box_height:value.box_height,
                                box_length: value.box_length,
                                box_size: value.box_size ,
                                box_width: value.box_width,
                                codCost: value.cod_cost,
                                deposit_type: value.deposit_type,
                                deposit_fullname: value.deposit_fullname,
                                deposit_phone: value.deposit_phone,
                                sender_name: value.sender_name,
                                ref_no: value.ref_no,
                                invoice: value.invoice,
                                return_doc: value.return_doc,
                                num_return_doc: value.num_return_doc,
                                ref_no2: value.ref_no2,
                                receiver_zipcode: value.receiver_zipcode,
                                item_desc: value.item_desc,
                              }}
                            />
                          ) : (
                            <FormatLabelAddressCod
                              key={nanoid()}
                              supplies={{
                                receiver_name: value.receiver_name,
                                receiver_address: value.receiver_address,
                                receiver_district: value.receiver_district,
                                receiver_amphur: value.receiver_amphur,
                                receiver_province: value.receiver_province,
                                receiver_phone: value.receiver_phone,
                                cust_Code: value.cust_code,
                                number: index + 1,
                                awb_code: value.awb_code,
                                date: date,
                                month: month,
                                year: year,
                                weight: value.weight,
                                box_height:value.box_height,
                                box_length: value.box_length,
                                box_size: value.box_size ,
                                box_width: value.box_width,
                                codCost: value.cod_cost,
                                deposit_type: value.deposit_type,
                                deposit_fullname: value.deposit_fullname,
                                deposit_phone: value.deposit_phone,
                                sender_name: value.sender_name,
                                ref_no: value.ref_no,
                                invoice: value.invoice,
                                return_doc: value.return_doc,
                                num_return_doc: value.num_return_doc,
                                ref_no2: value.ref_no2,
                                receiver_zipcode: value.receiver_zipcode,
                                item_desc: value.item_desc,
                                
                              }}
                            />
                          )
                        ) : (
                          <FormatLabelAddressPornor
                            key={nanoid()}
                            supplies={{
                              cust_code: value.cust_code,
                              /////////////////
                              receiver_name: value.receiver_name,
                              receiver_phone: value.receiver_phone,
                              receiver_address: value.receiver_address,
                              receiver_district: value.receiver_district,
                              receiver_amphur: value.receiver_amphur,
                              receiver_province: value.receiver_province,
                              receiver_zipcode: value.receiver_zipcode,
                              /////////////////
                              sender_name: value.sender_name,
                              sender_phone: value.sender_phone,
                              sender_address: value.sender_address,
                              sender_amphoe: value.sender_amphoe,
                              sender_province: value.sender_province,
                              sender_zipcode: value.sender_zipcode,
                              /////////////////
                              cod_cost: value.cod_cost,
                              /////////////////
                              awb_code: value.awb_code,
                              ////////////////
                              weight: value.weight,
                              box_height:value.box_height,
                              box_length: value.box_length,
                              box_size: value.box_size ,
                              box_width: value.box_width,
                              ref_no: value.ref_no,
                              invoice: value.invoice,
                              return_doc: value.return_doc,
                              num_return_doc: value.num_return_doc,
                              ref_no2: value.ref_no2,
                              item_desc: value.item_desc,
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="text-center">
            <br />
            <div className={dataArray === '' ? 'hidden-ct' : 'row'}>
              <div className="col-sm-12">
                <button
                  disabled={dataArray === '' || loading === 1 ? true : false}
                  className="btn btn-success btn-lg mgTop-ct-20"
                  style={{ width: '100%' }}
                  onClick={() => {
                    SaveData({
                      dataArray: dataArray,
                      labelType: 2,
                    });
                  }}
                >
                  {loading === 1 ? (
                    <center>
                      <div className="spinner-border" role="status"></div>
                    </center>
                  ) : (
                    'พิมพ์ลาเบล/ที่อยู่'
                  )}
                </button>
              </div>
              <div className={`col-sm-12 ${localStorage.getItem('superkey') === '1947' ? 'hidden-ct' : ''}`}>
                <button
                  disabled={dataArray === '' || loading === 1 ? true : false}
                  className="btn btn-warning btn-lg mgTop-ct-20"
                  style={{ width: '100%' }}
                  onClick={() => {
                    SaveData({
                      dataArray: dataArray,
                      labelType: 1,
                    });
                  }}
                >
                  {loading === 1 ? (
                    <center>
                      <div className="spinner-border" role="status"></div>
                    </center>
                  ) : (
                    'พิมพ์ลาเบล'
                  )}
                </button>
              </div>

              <div className={`col-sm-12 ${localStorage.getItem('superkey') === '1947' || localStorage.getItem('superkey') === 'credit' ? '' : 'hidden-ct'}`}>
                <button
                  disabled={dataArray === '' || loading === 1 ? true : false}
                  className="btn btn-warning btn-lg mgTop-ct-20"
                  style={{ width: '100%' }}
                  onClick={() => {
                    SaveData2({
                      dataArray: dataArray,
                      labelType: 1,
                    });
                    handlePrintShow();
                  }}
                >
                  {loading === 1 ? (
                    <center>
                      <div className="spinner-border" role="status"></div>
                    </center>
                  ) : (
                    'พิมพ์ลาเบล (mobile)'
                  )}
                </button>
              </div>
              {/* <div className={`col-sm-12`}>
                <button
                    disabled={loading === 1 ? true : false}
                    className="btn btn-warning btn-lg mgTop-ct-20"
                    style={{ width: '100%' }}
                    onClick={() => {
                      console.log('handle show')
                      handleConotePrintShow(true);
                    }}
                >
                  {loading === 1 ? (
                      <center>
                        <div className="spinner-border" role="status"></div>
                      </center>
                  ) : (
                      'ความหมาย'
                  )}
                </button>
              </div> */}
            </div>
            <div className={statusPrint === 1 && localStorage.getItem('superkey') !== '1947' && localStorage.getItem('superkey') !== 'credit' ? 'row' : 'hidden-ct'}>
              <div className="col-sm-12 mt-3">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">รวมค่าขนส่</label>
                  <div class="col-sm-9">
                    <input readOnly value={freightPrice} type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mt-3">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">รับเงิน</label>
                  <div class="col-sm-9">
                    <input
                      autoComplete="off"
                      value={getMoney}
                      type="number"
                      class="form-control"
                      onChange={(e) => {
                        setGetMoney(e.target.value);
                        if (e.target.value >= freightPrice && e.target.value !== '') {
                          setChangePrice(e.target.value - freightPrice);
                        } else {
                          setChangePrice(0);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mt-3">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">ทอนเงิน</label>
                  <div class="col-sm-9">
                    <input value={changePrice} readOnly type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <button
                  disabled={getMoney !== '' && getMoney >= freightPrice && statusPrint === 1 ? false : true}
                  className={getMoney !== '' && getMoney >= freightPrice && statusPrint === 1 ? 'btn btn-info btn-lg mgTop-ct-20' : 'btn btn-secondary btn-lg mgTop-ct-20'}
                  style={{ width: '100%' }}
                  onClick={() => {
                    receiptPrintUpdateStatus();
                  }}
                >
                  พิมพ์ใบเสร็จ
                </button>
              </div>
              <div className="col-sm-6">
                <button
                  disabled={statusPrint === 1 ? false : true}
                  className={statusPrint === 1 ? 'btn btn-danger btn-lg mgTop-ct-20' : 'btn btn-secondary btn-lg mgTop-ct-20'}
                  style={{ width: '100%' }}
                  onClick={() => {
                    handleClose();
                    setFreightPrice('');
                    setGetMoney('');
                    setChangePrice(0);
                  }}
                >
                  ไม่รับใบเสร็จ
                </button>
              </div>
            </div>
            <br />
            <br />
          </div>
        </Modal.Body>
      </Modal>
      {conoteData && localStorage.getItem('show_conote') === '1' && conoteShow && <Modal show={true}
         onHide={() => {
        setConoteData(null);
        setConoteShow(false);
      }} keyboard={false}>
        <Modal.Body>
          <div className="text-center">
            <div className="row">
              <div className="col-sm-12">
                <button
                    className="btn btn-success btn-lg mgTop-ct-20"
                    style={{ width: '100%' }}
                    onClick={() => {
                      try{
                        printJS({
                          printable: "printReport",
                          type: "html",
                          scanStyles: false,
                          css: [
                            window.location.protocol+"//" + window.location.host + "/static/Conote.css",
                            "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
                          ],
                          style: "@page { size: 20cm 14cm; margin:0cm; } ",
                          onLoadingStart: () => {

                            update_ref_no_status([conoteData.ref_no])
                                .then(result => {
                                  if(result.data.res){
                                    setConoteData(null);
                                    setConoteShow(false);
                                  }
                                  else{
                                    swalError({
                                      text: "เกิดข้อผิดพลาดระหว่างการอัปเดตสถานะบันทึกร่วม"
                                    })
                                  }
                                  }).catch(error => {
                                    swalError({
                                      text: "เกิดข้อผิดพลาดระหว่างการอัปเดตสถานะบันทึกร่วม"
                                    })
                                });
                          }
                        });
                      }catch(ex){
                        alert(ex);
                      }

                        
                    }}
                >
                  ปริ้นใบ co-note
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>}
      {conoteData &&
        (<div style={{display: 'none'}}>
          <div id="printReport">
            <PrintContent
              data={conoteData}
              conoteGroups={conoteData.groupData}
            />
          </div>
        </div>)
      }
      <div style={{display: 'none'}}>
        {conotePrintShow && <ConotePrintModel ref={conoteRef} /> }
      </div>
      </>
  );
}
