import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router";

export default function AdminMenu({ notification }) {
  const history = useHistory();

  return (
    <>
      <Nav.Item>
        <Nav.Link
          eventKey="/home"
          onClick={(e) => {
            history.push("/mis/MGBarcodes");
          }}
        >
          จัดการพัสดุ
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          onClick={(e) => {
            history.push("/mis/myReceipt");
          }}
        >
          การพิมพ์ใบเสร็จ
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <NavDropdown title="รายงาน" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={(e) => {
              history.push("/mis/ReportStockIn");
            }}
          >
            พิมพ์รายงานทะเบียนคุม
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item>
      <Nav.Item>
        <NavDropdown title="ตั้งค่า" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={(e) => {
              history.push("/mis/Shops");
            }}
          >
            - ข้อมูลร้านค้า/ลูกค้า (Shop)
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("/mis/Employees");
            }}
          >
            - ข้อมูลพนักงาน
          </NavDropdown.Item>
            <NavDropdown.Item
                onClick={(e) => {
                    history.push("/mis/HubEmployees");
                }}
            >
                - ผู้ใช้ฮับ
            </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("/mis/Size");
            }}
          >
            - เพิ่มขนาด
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("/mis/FilterData");
            }}
          >
            - ข้อมูลเปิดรับสมัครงาน
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("/mis/applyData");
            }}
          >
            - ข้อมูลสมัครงาน
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item>
      <Nav.Item>
        <NavDropdown title="บัญชี" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={(e) => {
              history.push("/mis/historyCOD");
            }}
          >
            - ประวัติCOD
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("/mis/manageCOD");
            }}
          >
            - จัดการCOD
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item>
      {/* <Nav.Item>
        <Nav.Link
          eventKey="/home"
          onClick={(e) => {
            history.push("CallCar");
          }}
        >
          เรียกรถเข้ารับพัสดุ
          <b
            style={{
              position: "absolute",
              Zindex: "1",
              color: "#ffff",
              backgroundColor: "#f88e2a",
              width: "20px",
              border: "2px solid #ffffff",
              borderRadius: "50px",
              fontSize: "10px",
              marginRight: "50px",
            }}
            onClick={(e) => {
              history.push("CallCar");
            }}
          ><center>{notification}</center></b>
        </Nav.Link>
      </Nav.Item> */}
    </>
  );
}
