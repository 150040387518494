import React, {useCallback, useState, useEffect} from 'react';
import { remarkHistoryList } from '../../../Service/service.Parcels';
import { swalError } from '../../../Components/sweetAlert';
import { Modal, Spinner, Pagination } from 'react-bootstrap';
import DateTH from '../../../Helper/date-th';

const RemarkHistory = ({parcel_id, onClose}) => {
    const [data, setData] = useState(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const fetchRemarks = useCallback(async (page) => {
                try {
                    setLoading(true);
                    const result = await remarkHistoryList(parcel_id, page);
                    setData(result?.data.data);
                    setPage(page);
                }
                catch (error) {
                    console.error(error);
                    swalError({
                        text: 'เกิดข้อผิดพลาด',
                    });
                }
                finally {       
                    setLoading(false);
                }
    }, [parcel_id]);

    useEffect(() => {
        console.log('on use effect');
        fetchRemarks(1);
    }, [fetchRemarks]);

    return (
        <Modal onHide={() => onClose()} show={true} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>ประวัติข้อสังเกต</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                  {loading && (
                    <div className="spinner-global-content">
                            <Spinner variant="success" animation="border" />
                    </div>
                  )}
                {!loading && data && (
                    <>
                        <table className="table table-bordered">
                            <thead style={{ backgroundColor: "#3f75c1" }}>
                                <tr style={{ color: "#FFFFFF" }}>
                                    <td>หมายเหตุ</td>
                                    <td>ชื่อผู้ใช้งาน</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {data.result.map(row => (
                                    <tr key={row.id}>
                                        <td>{row.remarkMg}</td>
                                        <td>{row.cust_code}</td>
                                        <td> <DateTH date={row.created_at}/> </td> 
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            count={parseInt(data.maxPage)}
                            page={page}
                            onChange={() => fetchRemarks(page)}
                            color="primary"
                            size="small"
                        />
                    </>
                )}
                {!loading && !data && <p>ไม่มีผลลัพธ์</p>}
            </Modal.Body>
       </Modal>
    )
}

export default RemarkHistory;
