import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import swal from "sweetalert";
import {
  ShowShopsService,
  UpdateStatusShopsService,
} from "../../../Service/service.Shops";

export default function Shops() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });

  const pageSizes = [10, 20, 50, 100];
  const [setErrorMassNoData, SetErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);
  const [shopType, setShopType] = useState("");
  const [status, setStatus] = useState("1");

  const [pageNo, setCurrentPage] = useState(1);
  const [perPage, setPageSize] = useState(10);
  const [keyword, setSearch] = useState("");

  useEffect(() => {
    fetchData();
  }, [pageNo, perPage]);

  async function fetchData() {
    let criteria = {
      keyword: keyword !== "" ? keyword : "",
      shopType: shopType !== "" ? shopType : "",
      status: status !== "" ? status : "",
      pageNo: pageNo !== "" ? pageNo : "",
      perPage: perPage !== "" ? perPage : "",
    };

    Loading(1);
    let res = await ShowShopsService(criteria);
    if (res.numRowsAll > 0) {
      setData(res.result);
      setPage({
        current_page: res.pageNo,
        last_page: res.maxPage,
        total: res.numRowsAll,
      });
      SetErrorMassNoData(0);
      Loading(0);
    } else {
      setCurrentPage(1);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      SetErrorMassNoData(1);
      Loading(0);
    }
  }

  const handleChangePage = (e, newPage) => {
    e.preventDefault();
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
  };

  const Del = async (value) => {
    let res = await UpdateStatusShopsService(value);
    if (res.status === 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      fetchData();
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };

  const filter = (e) => {
    e.preventDefault();
    setData([]);
    fetchData();
  };

  const clear = (e) => {
    e.preventDefault();
    setSearch("");
    setShopType("");
    setStatus("1");
    setPageSize(10);
    setCurrentPage(1);
    fetchData();
  };

  return (
    <div>
      <div className="text-center text-title-color">
        <h2>ข้อมูลร้านค้า/ลูกค้า</h2>
      </div>
      <br />
      
      <div className="row" style={{ marginLeft: "5px", marginRight: "5px" }}>
        <div className="col-sm-4 px-md-1 mt-1">
          <span>ค้นหา</span>
          <input
            value={keyword}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            type="text"
            className="form-control"
            placeholder="รหัสร้านค้า/ชื่อร้านค้า"
            aria-label="รหัสร้านค้า/ชื่อร้านค้า"
            aria-describedby="basic-addon1"
          />
        </div>
        <div className="col-sm-2 px-md-1 mt-1">
          <span>ประเภทลูกค้า</span>
          <select
            value={shopType}
            className="form-control"
            aria-label="Default select example"
            onChange={(e) => {
              setShopType(e.target.value);
            }}
          >
            <option value="0">ทั้งหมด</option>
            <option value="4">ลูกค้าเครดิต</option>
            <option value="1">หน้าร้าน</option>
            <option value="2">หน้าร้าน(ศูนย์กระจายสินค้า)</option>
            <option value="3">แฟรนไซส์</option>
          </select>
        </div>
        <div className="col-sm-2 px-md-1 mt-1">
          <span>สถานะ</span>
          <select
            value={status}
            className="form-control"
            aria-label="Default select example"
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="1">ใช้งาน</option>
            <option value="0">ปิดใช้งาน</option>
          </select>
        </div>
        <div className="col-sm-2 px-md-3 mt-1">
          <br />
          <button
            name="search"
            disabled={loading === 0 ? false : true}
            className={"btn btn-primary"}
            onClick={filter}
          >
            <Icon.Search /> ค้นหา
          </button>{" "}
          <button
            name="clear"
            className="btn btn-secondary"
            onClick={clear}
            disabled={
              keyword !== "" || shopType !== "" || status !== "" ? false : true
            }
          >
            <Icon.ArrowClockwise /> ล้างค่า
          </button>
        </div>
        <div className="col-sm-2 px-md-1 mt-1" align="right">
          <br />
          <button
            className="btn btn-primary"
            onClick={(e) => {
              history.push("/mis/FormShop");
            }}
          >
            <Icon.Plus /> เพิ่มข้อมูล
          </button>
        </div>
      </div>
      <br />
      <div className="d-flex justify-content-between mb-2">
        <div className="col-md-11">
          <strong>คำอธิบาย</strong>
          <br />
          <Icon.EyeFill color="royalblue" size={26} /> คือ{" "}
          <label>
            <span className="badge badge-success">อนุญาตให้ลูกค้า</span>{" "}
            ดูราคาค่าขนส่ง หรือ ที่อยู่ของผู้รับพัสดุได้
          </label>
          <br />
          <Icon.EyeSlashFill color="gray" size={26} /> คือ{" "}
          <label>
            {" "}
            <span className="badge badge-danger">ไม่อนุญาตให้ลูกค้า</span>{" "}
            ดูราคาค่าขนส่ง หรือที่อยู่ของผู้รับพัสดุได้
          </label>
        </div>
        <div className="col-md-1">
          <select
            className="form-control"
            onChange={handlePageSizeChange}
            value={perPage}
          >
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <div style={{ overflow: "Auto" }}>
          <table className="table table-bordered">
            <thead style={{ backgroundColor: "#3f75c1" }}>
              <tr style={{ color: "#FFFFFF" }}>
                <td>ลำดับ</td>
                <td>รหัสร้านค้า</td>
                <td style={{ width: "20%" }}>ชื่อร้านค้า</td>
                <td>ประเภทลูกค้า</td>
                <td>เบอร์โทร</td>
                <td>ค่าขนส่ง</td>
                <td>ที่อยู่ผู้รับพัสดุ</td>
                <td>ปริ้นco note</td>
                <td>บังคับใส่ขนาด</td>
                <td>โฆษณา</td>
                <td>แสดงพัสดุฯตีกลับ</td>
                <td>แสดงรูปแนบ</td>
                <td>ใช่ชื่อผู้รับ</td>
                <td>generate CONOTE</td>
                <td>จัดการ</td>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr key={value.id}>
                  <td>{(page.current_page - 1) * perPage + (index + 1)}</td>
                  <td>{value.shop_code}</td>
                  {value.rate === "" || value.rate === null || value.rate === '0.00' ? 
                    (
                      <td>
                        <strong>{value.shop_name}</strong>
                        <br />
                        {value.address}
                        <br/>
                        {value.credit_signature !== null ? (
                          <img src={value.image} width="100" height="100" alt={value.image} />) : ""
                        } 
                      </td>
                    ) : (
                      <td>
                        <strong>{value.shop_name}</strong>
                        <br />
                        {value.address}
                        <br/>
                        เปอร์เซ็นที่ได้รับ: {(parseFloat(value.rate)).toLocaleString(undefined, { minimumFractionDigits: 2 })} %
                        {value.rate2 === "" || value.rate2 === null || value.rate2 === '0.00' ? '':
                        (
                          <div>
                          เปอร์เซ็นที่ได้รับ {">"} 50ชิ้น: {(parseFloat(value.rate2)).toLocaleString(undefined, { minimumFractionDigits: 2 })} %
                          </div>
                        )}
                        {value.credit_signature !== null ? ( 
                          <img src={value.image} width="100" height="100" alt={value.image} />) : ""
                        } 
                        
                      </td>
                    )}
                  
                  <td>{value.shop_type_name}</td>
                  <td>{value.phone}</td>
                  <td className="text-center">
                    {value.show_shipment_cost === "1" ? (
                      <Icon.EyeFill color="royalblue" size={26} />
                    ) : (
                      <Icon.EyeSlashFill color="gray" size={26} />
                    )}
                  </td>
                  <td className="text-center">
                    {value.show_address_receiver === "1" ? (
                      <Icon.EyeFill color="royalblue" size={26} />
                    ) : (
                      <Icon.EyeSlashFill color="gray" size={26} />
                    )}
                  </td>
                  <td className="text-center">
                    {value.show_conote === "1" ? (
                      <Icon.EyeFill color="royalblue" size={26} />
                    ) : (
                      <Icon.EyeSlashFill color="gray" size={26} />
                    )}
                  </td>
                  <td className="text-center">
                    {value.require_dimension === "1" ? (
                      <Icon.EyeFill color="royalblue" size={26} />
                    ) : (
                      <Icon.EyeSlashFill color="gray" size={26} />
                    )}
                  </td>
                  <td className="text-center">
                    {value.show_ads === "1" ? (
                      <Icon.EyeFill color="royalblue" size={26} />
                    ) : (
                      <Icon.EyeSlashFill color="gray" size={26} />
                    )}
                  </td>
                  <td className="text-center">
                    {value.show_return === "1" ? (
                      <Icon.EyeFill color="royalblue" size={26} />
                    ) : (
                      <Icon.EyeSlashFill color="gray" size={26} />
                    )}
                  </td>
                  <td className="text-center">
                    {value.show_image === "1" ? (
                      <Icon.EyeFill color="royalblue" size={26} />
                    ) : (
                      <Icon.EyeSlashFill color="gray" size={26} />
                    )}
                  </td>
                  <td className="text-center">
                    {value.show_pickup === "1" ? (
                      <Icon.EyeFill color="royalblue" size={26} />
                    ) : (
                      <Icon.EyeSlashFill color="gray" size={26} />
                    )}
                  </td>
                  <td className="text-center">
                    {value.show_generate_ref_no === "1" ? (
                      <Icon.EyeFill color="royalblue" size={26} />
                    ) : (
                      <Icon.EyeSlashFill color="gray" size={26} />
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-warning"
                      onClick={(e) => {
                        history.push("/mis/FormShop", { value: value });
                      }}
                    >
                      <Icon.PencilSquare style={{ color: "#ffff" }} />
                    </button>{" "}
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        swal({
                          className: "bg-modal-red",
                          icon: "warning",
                          dangerMode: true,
                          text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                          buttons: {
                            cancel: "ยกเลิก",
                            confirm: {
                              text: "ใช่",
                              value: value.id,
                            },
                          },
                        }).then((value) => {
                          if (value !== null) {
                            Del({
                              id: value,
                            });
                          }
                        });
                      }}
                    >
                      <Icon.Trash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <center>
            {setErrorMassNoData === 1 ? (
              <font color="red" size="3">
                {" "}
                ไม่พบข้อมูล!{" "}
              </font>
            ) : (
              ""
            )}
          </center>
        </div>
        <center>
          {loading === 1 ? (
            <div className="m-5">
              <div className="text-center">
                <div className="spinner-border  text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                {" ..."}
                กำลังโหลดข้อมูล
              </div>
            </div>
          ) : (
            ""
          )}
        </center>
        <div className="d-flex justify-content-between">
          <div>จำนวนทั้งหมด {page.total} รายการ</div>
          <div>
            <Pagination
              count={parseInt(page.last_page)}
              page={pageNo}
              onChange={handleChangePage}
              color="primary"
              size="small"
              defaultPage={6}
              siblingCount={1}
            />
          </div>
        </div>
      </div>

      <br />
      <br />
    </div>
  );
}
