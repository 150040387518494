import React from "react";
import FilterData from "./filterData";

export default function ReportStockIn() {
  return (
    <>
      <div className="text-center text-title-color">
        <h2>พิมพ์ทะเบียนคุมสินค้า</h2>
      </div>
      <div className="mt-3 mb-2">
        <FilterData />
      </div>
    </>
  );
}
