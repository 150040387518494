import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup';
import { CustomerTypeService } from '../../../Service/service.Barcode';
import { Typeahead } from 'react-bootstrap-typeahead';

function ModalsShopCode({ data, printLabelSingle, dataSelect, UpdateCust, modalShopCode, setModalShopCode }) {
  const [customers, setCustomers] = useState([]);
  const [customer_type, setCustomer_type]= useState('');
  const SchemaShop = Yup.object().shape({
    cust_code: Yup.string().required('กรุณาระบุข้อมูล'),
  });

  async function CustomerType(data) {
    const result = await CustomerTypeService(data);
    if (data !== '') {
      setCustomers(result.data);
    }
  }
  return (
    <>
      {/* <-------------------------------------------------เปลี่ยนรหัสร้าน---------------------------------------------> */}
      <Modal
        show={modalShopCode}
        onHide={() => {
          setModalShopCode(false);
          setCustomer_type("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>ย้ายรหัสลูกค้า</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '19px' }}>
          <Formik
            validationSchema={SchemaShop}
            initialValues={{
              cust_code: '',
              customer_type:''
            }}
            enableReinitialize={true}
            onSubmit={(value) => {
              if (value.cust_code !== '' && dataSelect.length !== 0) {
                UpdateCust({ 
                  cust_code: value.cust_code, 
                  customer_type: customer_type,
                  data: dataSelect });
                setModalShopCode(false);
              }
            }}
          >
            {({ errors, touched, handleBlur, setFieldValue, values }) => (
              <Form>
                <div className="form-row justify-content-md-center">
                  <div className="form-group col-sm-12 px-md-1 mt-1">
                    <label>ประเภทลูกค้า</label>
                    <select
                      value={values.customerType}
                      onChange={(e) => {
                        CustomerType({ customer_type: e.target.value });
                        setCustomer_type(e.target.value);
                        setFieldValue('customerType', e.target.value);
                      }}
                      className="form-control dropdown-custom"
                    >
                      <option value={''}>-- กรุณาระบุประเภทลูกค้า --</option>
                      <option value={'4'}>ลูกค้าเครดิต</option>
                      <option value={'1'}>หน้าร้าน</option>
                      <option value={'2'}>หน้าร้าน(ศูนย์กระจายสินค้า)</option>
                      <option value={'3'}>แฟรนไซส์</option>
                      <option value={'6'}>ลูกค้าทั่วไป</option>
                    </select>
                  </div>
                  <div className="form-group col-sm-12 px-md-1 mt-1">
                    <label>สาขา</label>
                    <Typeahead
                      isInvalid={getIn(touched, 'cust_code') ? (getIn(errors, 'cust_code') ? true : false) : ''}
                      defaultSelected={customers.filter((a) => a.cust_code === values.cust_code)}
                      id="cust_code"
                      name="cust_code"
                      labelKey={(option) => `${option.cust_code} ${option.name}`}
                      onChange={(e) => {
                        if (e.length > 0) {
                          let value = e[0].cust_code;
                          setFieldValue('cust_code', value);
                        }
                      }}
                      onInputChange={(text, e) => {
                        setFieldValue('cust_code', '');
                      }}
                      options={customers}
                      placeholder="-- กรุณาระบุสาขา --"
                      selected={values.cust_code === '' ? [] : customers.filter((a) => a.cust_code === values.cust_code)}
                    />
                    <div className={getIn(touched, 'cust_code') ? (getIn(errors, 'cust_code') ? 'error-invalid-feedback' : 'hidden-ct') : 'hidden-ct'}>กรุณาระบุรหัสลูกค้า</div>
                  </div>
                </div>
                <hr />
                <div align="right">
                  <button
                    type="button"
                    className="btn btn-danger ml-1"
                    onClick={(e) => {
                      setModalShopCode(false);
                    }}
                  >
                    ยกเลิก
                  </button>
                  <button type="submit" className={'btn btn-primary ml-1'} onClick={(e) => {}}>
                    บันทึก
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalsShopCode;
