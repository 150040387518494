import Instance from "../Helper/axios";
import { URL, urlLocal , URLTEST } from "../Helper/baseURL";

export async function myCODService(data){
  try {
    const response = await Instance.post(
      URL + "ManageCOD/getCODList",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function CODService(data) {
  // console.log("data:" + JSON.stringify(data));
  try {
    const response = await Instance.post(
      URL + "ManageCOD/getParcels",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function uploadExcel(data){
  try {
    const response = await Instance.post(
      URL + "ManageCOD/uploadExcel",
      data
    );
    return await response.data;

  } catch (error) {
    alert("error : ", JSON.stringify(error));
  }

}
export async function UploadFileForMultiple(data){
  try {
    
    const response = await Instance.post(
      URL + "ManageCOD/uploadFileForMultiple",
      data
    );
    return await response.data;

  } catch (error) {
    alert("error : ", JSON.stringify(error));
  }

}
export async function UploadFile(data){
  try {
    const response = await Instance.post(
      URL + "ManageCOD/uploadFile",
      data
    );
    return await response.data;
    // fetch(
		// 	urlLocal + "ManageCOD/uploadFile2",
		// 	{
		// 		method: 'POST',
		// 		body: data,
		// 	}
		// )
		// 	.then((response) => alert("1111"+response.json()))
		// 	.then((result) => {
		// 		alert('Success:', JSON.stringify(result));
		// 	})
		// 	.catch((error) => {
		// 		alert('Error:', error);
		// 	});

  } catch (error) {
    alert("error : ", JSON.stringify(error));
  }

}
export async function UpdateRemarkService(data) {
  // console.log("data:" + JSON.stringify(data));

  try {
    const response = await Instance.post(
      URL + "ManageCOD/updateRemark",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateTransferReceipt(data) {
  // console.log("data:" + JSON.stringify(data));

  try {
    const response = await Instance.post(
      URL + "ManageCOD/UpdateBankReceiptCOD",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateMultipleTransferReceipt(data) {
  try {
    const response = await Instance.post(
      URL + "ManageCOD/UpdateMultipleBankReceiptCOD",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}


export async function BarcodeUpdateService(data) {
  //อัพเดทสถานะบาร์โค้ด
  try {
    const response = await Instance.post(
      URL + "RecoveryBarcode/saveRecoveryBarcode",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function CODUpdateService(data) {
  //อัพเดท COD
  try {
    const response = await Instance.post(
      URL + "web/ParcelsCOD/updateCOD",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function CustomerTypeService(data) {
  try {
    const response = await Instance.post(
      URL + "RecoveryBarcode/getCustCodeList",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
