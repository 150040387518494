var initialState = {
    username: localStorage.getItem("username"),
    roleType: localStorage.getItem("roleType"),
    fullname: localStorage.getItem("fullname"),
    province_code: localStorage.getItem("province_code"),
    amphur_code: localStorage.getItem("amphur_code"),
};

export default  function Authentication(state = initialState, action) {
  switch (action.type) {
    case "AUTHEN":
      localStorage.setItem("username", action.username);
      localStorage.setItem("roleType", action.roleType);
      localStorage.setItem("fullname", action.fullname);
      localStorage.setItem("province_code", action.province_code);
      localStorage.setItem("amphur_code", action.amphur_code);
      return {
        ...state,
        username: localStorage.getItem("username"),
        roleType: localStorage.getItem("roleType"),
        fullname: localStorage.getItem("fullname"),
        province_code: localStorage.getItem("province_code"),
        amphur_code: localStorage.getItem("amphur_code"),
        show_shipment_cost: action.show_shipment_cost,
        show_address_receiver: action.show_receiver_address
      };
    case "UAUTHEN":
      localStorage.removeItem("username");
      localStorage.removeItem("roleType");
      localStorage.removeItem("fullname");
      localStorage.removeItem("province_code");
      localStorage.removeItem("amphur_code");
      return {
        ...state,
        username: localStorage.getItem("username"),
        roleType: localStorage.getItem("roleType"),
        fullname: localStorage.getItem("fullname"),
        province_code: localStorage.getItem("province_code"),
        amphur_code: localStorage.getItem("amphur_code"),
        show_shipment_cost: localStorage.getItem("show_shipment_cost"),
        show_address_receiver: localStorage.getItem("show_address_receiver"),
      };
    case "USERINFO":
      return {
        ...state,
        username: localStorage.getItem("username"),
        roleType: localStorage.getItem("roleType"),
        fullname: localStorage.getItem("fullname"),
        province_code: localStorage.getItem("province_code"),
        amphur_code: localStorage.getItem("amphur_code"),
        show_shipment_cost: localStorage.getItem("show_shipment_cost"),
        show_address_receiver: localStorage.getItem("show_address_receiver"),
      };
    default:
      return {
        ...state,
        username: localStorage.getItem("username"),
        roleType: localStorage.getItem("roleType"),
        fullname: localStorage.getItem("fullname"),
        province_code: localStorage.getItem("province_code"),
        amphur_code: localStorage.getItem("amphur_code"),
        show_shipment_cost: localStorage.getItem("show_shipment_cost"),
        show_address_receiver: localStorage.getItem("show_address_receiver"),
      };
  }
}
