import React from "react";
import { useBarcode } from "react-barcodes";

export default function GenberCode({ awbCode }) {
  const { inputRef } = useBarcode({
    value: awbCode !== "" ? awbCode : "0",
    options: { format: "CODE128", height: 40, fontSize: 13 },
  });

  return <svg ref={inputRef} />;
}
