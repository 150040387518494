import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import GenericTable from "../../Shared/GenericTable";
import DateTH from "../../../../Helper/date-thV2";
import {nanoid} from "nanoid";
import i18n from "i18next";
import {ExportWithoutPictures} from "../../../mis/manageBarcodesV2/ExportWithoutPictures";
import * as Icon from "react-bootstrap-icons";
import Pagination from "@material-ui/lab/Pagination";

export const ParcelsStatusDisplay = ({
     onExport,
     onExportWithPic,
     exportData,
     data,
     onPageChange,
     onHistoryDisplay,
}) => {
    const translation = useTranslation();
    const {t} = translation;
    const metadata = [
        {
            text: t('hub.status.table.creation_date'),
            width: '7%',
            render: (row) => (
                <p>{translation.i18n.language === "th" ? <DateTH date={row.created_date} /> : row.created_date}</p>
            ),
        },

        {
            text: t('hub.status.table.customer_code'),
            width: '7%',
            render: (row) => (
                <p>{row.cust_code}</p>
            ),
        },
        {
            text: t('hub.status.table.barcode'),
            width: '8%',
            render: (row) => (
                <>
                    <b
                        type="button"
                        onClick={() => {
                            onHistoryDisplay({ awb_code: row.awb_code, ref_no: row.ref_no === null || row.ref_no === '0' ? " " : row.ref_no});
                        }}
                    >
                        {row.ref_no === null || row.ref_no === '0' ? " " : row.ref_no}
                        <br/>
                        {row.awb_code !== null ? row.awb_code : " "}
                    </b>
                    <b>
                        {row.ref_no === null || row.ref_no === '0' ? " " : row.ref_no}
                        <br/>
                        {row.awb_code !== null ? row.awb_code : " "}
                    </b>
                    <hr/>
                    {row.ref_no2 === null || row.ref_no2 === "" ? " ": ( <p>{t('hub.status.table.number_for_customer')} 1: {row.ref_no2}</p>)}
                    {row.invoice === null || row.invoice === "" ? " ": ( <p>{t('hub.status.table.invoice')}: {row.invoice}</p>)}
                    {row.item_desc === null || row.item_desc === "" ? " ": ( <p>{t('hub.status.table.number_for_customer')} 2: {row.item_desc}</p>)}
                    {row.product_code === null || row.product_code === "" ? " ": ( <p>{t('hub.status.table.product_code')}: {row.product_code}</p>)}
                </>
            ),
        },
        {
            text: t('hub.status.table.sender'),
            width: '6%',
            render: (row) => (
                <>
                    <p>{row.sender_name}</p>
                    <p>{row.sender_phone}</p>
                </>
            ),
        },
        {
            text: t('hub.status.table.address'),
            render: (row) => (
                <>
                    <p>{row.receiver_name}</p>
                    <p>{row.receiver_address}</p>
                    <p>{row.receiver_district!== null ? row.receiver_district : ""} {' '+ row.receiver_amphur + ', ' + row.receiver_province}</p>
                    <p>{row.receiver_phone}</p>
                </>
            ),
        },
        {
            text: t('hub.status.table.shipping_cost'),
            width: '8%',
            render: (row) => (
                <p>{parseFloat(row.shipment_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
            ),
        },
        {
            text: t('hub.status.table.COD'),
            width: '8%',
            render: (row) => (
                <>
                    <p>COD({t('common.baht')}): {parseFloat(row.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                    <p>{t('common.service_fee')} COD: {parseFloat(row.service_cod).toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                </>
            ),
        },
        {
            text: t('hub.status.table.net_amount'),
            width: '10%',
            render: (row) => (
                <>
                    {(parseFloat(row.shipment_cost) + parseFloat(row.service_cod)).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                </>
            ),
        },
        {
            text: t('hub.status.table.remark'),
            width: '11%',
            render: (row) => (
                <p>
                    {
                        row.remarkMG !== null ? (
                            <p>{row.remarkMG}</p>) : (
                            <p>{t('common.no_remarks')}</p>
                        )
                    }
                </p>
            ),
        },
        {
            text: t('hub.status.table.status'),
            width: '13%',
            render: (row) => (
                <>
                    {row.shipment_status === '5' ? (
                        <center>
                            <img src={`${row.image}?${nanoid()}`} width="100" height="100" alt={row.image} />
                            <br />
                            {row.receiver_date !== null && row.receiver_date !== '' && row.receiver_date !== undefined ?(
                                i18n.language === 'th' ? <DateTH date={row.receiver_date} /> : row.receiver_date
                            ) : ''}
                        </center>
                    ) : ('')}
                    <br/>
                    {row.received_proof && row.received_proof > 0 && true && row.received_proof !== '' ?
                        (<font color="red" size="2">
                            <p>{t('common.evidence_picture')}</p>
                        </font>) : ""}
                    <br/>
                    {row.shipment_status === '10' ? (
                        <center>
                            {row.new_receiver_date !== null && row.new_receiver_date !== '' && row.new_receiver_date !== undefined
                                ? <DateTH date={row.new_receiver_date.substring(0, 10)} /> : ''}
                        </center>
                    ) : ('')}
                    <br/>
                    {row.transportWorker !== "" && row.transportWorker !== null && row.scanner_name != ''
                    && row.scanner_name != null ? (
                        <p>{t('hub.status.table.employee_code')}: "{row.transportWorker}"
                            <br/>{t('hub.status.table.transport_employee_name')}: {row.scanner_name}
                        </p>
                    ):""}
                </>
            ),
        }
    ];

    useEffect(() => {
        if(exportData){
            setTimeout(() => {
                document.getElementById("ExportWithoutPicturesClick").click();
            }, 0)
        }
    }, [exportData]);

    return (
        <>
            <div className="toolbar w-full d-flex" style={{gap: '2px'}}>
                <button
                    type="button"
                    // style={{ width: '100px' }}
                    className="btn btn btn-info mb-1 mt-2"
                    onClick={onExport}>
                    <Icon.FileEarmarkArrowUp /> {t('common.export')}
                </button>

                <button
                    type="button"
                    // style={{ width: '100px' }}
                    className="btn btn btn-info mb-1 mt-2"
                    onClick={onExportWithPic}>
                    <Icon.FileEarmarkArrowUp /> {t('common.export')}/{t('common.pic')}
                </button>
            </div>
            {exportData && <ExportWithoutPictures exportData2={exportData} />}
            <GenericTable
                data={data.result}
                keyExtractor={(data) => data.code}
                metadata={metadata} />
            <div className="w-full d-flex justify-content-center align-items-center">
                <Pagination
                    count={parseInt(data.maxPage)}
                    page={parseInt(data.pageNo,10)}
                    onChange={(event,page) => onPageChange(page)}
                    color="primary"
                    size="small"
                    defaultPage={6}
                    siblingCount={1}
                />
            </div>
        </>
    );
};