import Instance from '../Helper/axios';
import { URL, URLTEST, urlLocal } from '../Helper/baseURL';

export async function porlorPrice() {
  try {
    const response = await Instance.post(URL + 'priceCalculation/PriceCalculation/porlorPrice');
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function porlorFarmersPrice() {
  try {
    const response = await Instance.post(URL + 'priceCalculation/PriceCalculation/porlorFarmersPrice');
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function thailandPostPrice() {
  try {
    const response = await Instance.post(URL + 'priceCalculation/PriceCalculation/thailandPostPrice');
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}
