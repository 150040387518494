import axios from "axios";
import { URL } from "./baseURL";

export default axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic cG9ybG9yOjEyMzQ1',
  },
});
