import Instance from "../Helper/axios";
import { URL, URLTEST, urlLocal } from "../Helper/baseURL";

export async function ShowSizeService(data) {
  try {
    const res = await Instance.post(URL + "settings/Credit/getSize", data);
    return await res.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateStatusSizeService(data) {
  try {
    const response = await Instance.post(
      URL + "settings/Credit/deleteSize",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function SaveSizeService(data) {
  try {
    const response = await Instance.post(
      URL + "settings/Credit/saveSizeEdit",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

// ดึงข้อมูลพนักงานขนส่ง สามารถส่ง cust_code ได้
export async function GetAllSizeService(cust_code) {
  try {
    const response = await Instance.get(
      URL + "/settings/Credit/getAllSize?cust_code=" + cust_code
    );
    return await response.data;
  } catch (error) {}
}
