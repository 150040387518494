import Instance from "../Helper/axios";
import { URLTEST } from "../Helper/baseURL";

//บันทึก ข้อมูลบันทึกรับพัสดุ
export async function InsertData(params) {
    let data = new FormData();
    data.append("awbCode", params.awbCode);
    data.append("shipmentStatus", params.shipmentStatus);
    data.append("empCode", params.empCode);

    const respons = await Instance.post(URLTEST + "transport/StockIn/saveStockIn", data);
    return await respons.data;
}


export async function GetData(param) {
  try {
    const formData = new FormData();
    Object.entries(param).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await Instance.post(
      URLTEST + "transport/StockIn/getStockIn",
      formData
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}