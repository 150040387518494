import React, {useEffect, useState,useRef } from "react";
import * as Icon from "react-bootstrap-icons";
import Pagination from "@material-ui/lab/Pagination";
import DateTH from "../../../Helper/date-th";
import { getPrintReceipt, GetMyReceipt, reprintReceipt,GetParcelsFromReceipt,updateParcel} from "../../../Service/service.Receipt";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { monthTH } from "../../../Data/month-th.json";
import FormatReceiptPornor from "../formatLabelPornor/formatReceiptPornor";
import { nanoid } from "nanoid";
import ReactExport from "react-data-export";
import printJS from 'print-js';
import { connect } from "react-redux";
import swal from "sweetalert";
import FormatReceipt from "../formatLabel/formatReceipt";
import Modal from "react-bootstrap/Modal";
import { PriceCalculation } from '../../../Components/PriceCalculation';
import {calculatedPrice, CheckReceiver} from "../../../Service/service.Parcels";
import {ErrorMessage} from "formik";
import {Typeahead} from "react-bootstrap-typeahead";
import {CustomerTypeService} from "../../../Service/service.Barcode";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const userInfo = localStorage.getItem("userInfo");


const InvalidMessage = ({text}) => (
    <div className="invalid-feedback">
      <font style={{verticalAlign : 'inherit'}}>
        <font style={{verticalAlign : 'inherit'}}>{text}</font>
      </font>
    </div>
);

export function MyReceipt(props) {
  const { user, lang } = props;
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  var today = new Date(),
    time = today.getHours() + ":" + today.getMinutes();
  var [data2, Data2] = useState([]);
  const [setData, SetData] = useState([]);
  const [setData2, SetData2] = useState([]);
  const [setPage, SetPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });


  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const [pageNo, PageNo] = useState(1);
  const [pageSizes, PageSizes] = useState(10);
  const [customer_type, Customer_type] = useState("");
  const [cust_code, Cust_code] = useState([]);
  const [cust, Cust] = useState([]);
  const [barcode, Barcode] = useState("");
  const [barcodes, Barcodes] = useState("");
  const [receiptCode, ReceiptCode] = useState("");
  const [receipt_code, setReceiptCode] = useState("");
  const [sender_name, setSender_name] = useState("");
  // const [shipStatus, SetStatus] = useState("10");
  const [startdate1, SetStart1] = useState("");
  const [startdate2, SetStart2] = useState("");
  const [startdate3, SetStart3] = useState("");
  const [enddate1, SetEnd1] = useState("");
  const [enddate2, SetEnd2] = useState("");
  const [enddate3, SetEnd3] = useState("");
  const [custCodeFilter,setCustCodeFilter] = useState({
    custCode: '',
    filter: '',
    custCodes: [],
  });
  useEffect(() => {
    if(custCodeFilter.filter !== ''){
      CustomerTypeService({customer_type : custCodeFilter.filter})
          .then(result => {
            if(result !== ''){
              setCustCodeFilter({
                ...custCodeFilter,
                custCodes: result.data,
              })
            }
          });
    }
    else{
      setCustCodeFilter({
        ...custCodeFilter,
        custCodes: [],
      });
    }
  },[custCodeFilter.filter]);
  const [cod, COD] = useState("");
  const [status, setStatus] = useState("");
  const [search_name, Search_name] = useState("");
  const [setError, SetError] = useState("form-control");
  const [setErrorMass, SetErrorMass] = useState(0);
  const [setErrorMassNoData, SetErrorMassNoData] = useState(0);
  const [errorStartdate, SetErrorStart] = useState("form-control ");
  const [errorEnddate, SetErrorEnd] = useState("form-control ");
  const [errorStartMass, SetErrorStartMass] = useState(0);
  const [errorEndMass, SetErrorEndMass] = useState(0);
  const [errorMassSta, ErrorMassSta] = useState(0);
  const [loading, Loading] = useState(0);
  const [dateNow, setDateNow] = useState(1);
  const [printLebel, SetPrintLebel] = useState([]);
  const [sender_phone,Sender_phone] = useState("");
  const [national_id,National_id] = useState("");
  const [freightPrice, setFreightPrice] = useState(0);
  const [bankId, setBankId] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankCode, setBankCode] = useState("");

  const [getMoney, setGetMoney] = useState("");
  const [changePrice, setChangePrice] = useState(0);
  const [remark, setRemark] = useState("");
  const [weight,setWeight] = useState("");
  const [box_width, setBox_width]= useState("");
  const [box_length, setBox_length] = useState("");
  const [box_height, setBox_height] = useState("");
  const [box_size, setBox_size] = useState("");
  const [shipment_cost,setShipment_cost] = useState("");
  const [cod_cost, setCod_cost] = useState("");
  const [awb_code,setAwb_code] = useState("");
  const [receiver_province, setReceiver_province] = useState("");
  const [receiver_amphur,setReceiver_amphur] = useState("");
  const [sender_type,setSender_type] = useState("");
  const [total, setTotal] = useState(0);
  const [service_cod,setService_cod] = useState(0);
  const [dataEdit, setDataEdit] = useState({});
  const [handleBlur,setHandleBlur] = useState(false);
  const [dataParcel,setDataParcel] = useState([]);
  const [indexDataParcel,setIndexDataParcel] = useState(0);


  const [dataReceipt, setDataReceipt] = useState({
    freightPrice: "",
    getMoney: "",
    changePrice: "",
    amont: "",
  });
  const [dataArrayReceipt, setDataArrayReceipt] = useState([]);

  const [exportData, SetExportData] = useState([]);
  const elementRef = useRef(null);

  async function fetchData(value,callback = () => {}) {
    SetData([]);
    Loading(1);
    const result = await GetMyReceipt(value);
    if (result !== false) {
      if (result.result.length !== 0) {
        SetData(result.result);
        SetPage({
          current_page: result.pageNo,
          last_page: result.maxPage,
          total: result.numRowsAll,
        });
        callback();
      }
      if (result.result.length !== 0) {
        SetError("form-control");
        SetErrorMass(0);
        SetErrorMassNoData(0);
      }
      if (result.result.length === 0) {
        PageNo(1);
        SetPage({
          current_page: 1,
          last_page: 1,
          total: 0,
        });
        SetErrorMassNoData(1);
      }
      // if (result != null) {
      //   SetData(result.result);
      // }
    }
    else if(result === undefined){
     alert("Server Error");
    }
     else {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorMassNoData(1);
    }
    Loading(0);
  }

  async function fetchData2(value) {
    // Loading(1);
    // const result = await CODService(value);
    // if (result != undefined) {
    //   if (result.data.length !== 0) {
    //     SetData2(result.data);
    //     document.getElementById("downloadEx1").click();

    //     //document.getElementById("downloadEx1").click();
    //     // SetPage({
    //     //   current_page: result.pg[0].current_page,
    //     //   last_page: result.pg[0].last_page,
    //     //   total: result.pg[0].total,
    //     // });
    //   }
    //   if (result.data.length !== 0) {
    //     SetError("form-control");
    //     SetErrorMass(0);
    //     SetErrorMassNoData(0);
    //   }
    //   if (result.data.length === 0) {
    //     PageNo(1);
    //     // SetPage({
    //     //   current_page: 1,
    //     //   last_page: 1,
    //     //   total: 0,
    //     // });
    //     SetErrorMassNoData(1);
    //   }
    //   if (result != null) {
    //     SetData2(result.data);

    //   }
    // } else {
    //   alert("Server Error");
    // }
    // Loading(0);
  }

  const isWeightInvalid = () => {
    if(weight === undefined || weight === ""){
      return "กรุณากรอก น้ำหนัก";
    }
    if(weight>30){
      return "ขออภัย พัสดุฯมีน้ำหนักเกิน30กิโลกรัม";
    }
    return null;
  };

  const isTotalSizeInvalid = () => {
    if(box_width === '' || box_height === '' || box_length === ''){
      return "กรุณากรอกขนาด";
    }
    if(box_size > 210){
      return "ขออภัย ขนาดพัสดุฯเกิน210ซม ";
    }
    return null;
  };

  function clear() {
    ReceiptCode("");

    setDateNow(1);
    ErrorMassSta(0);
    SetErrorStartMass(0);
    SetErrorEndMass(0);
    SetErrorEnd("form-control");
    SetErrorStart("form-control");
    SetErrorMassNoData(0);
    SetStart1("");
    SetStart2("");
    SetStart3("");
    SetEnd1("");
    SetEnd2("");
    SetEnd3("");
    SetError("form-control");
    SetErrorMass(0);
    SetErrorMassNoData(0);
    SetData([]);
    Data2([]);
    Cust_code([]);
    Cust([]);
    SetPrintLebel([]);
    Customer_type("");
    COD("");
    Barcode("");
    setStatus("");
    PageNo(1);
    PageSizes(10);
    SetPage({
      current_page: 1,
      last_page: 1,
      total: 0,
    });
  }

  /** ค้นหาข้อมูล */
  const handleSubmit = () => {
    SetPrintLebel([]);
    Data2([]);
    PageNo(1);
    SetData([]);
    SetError("form-control");
    SetErrorMass(0);

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate ไม่เท่ากับ ""
    if (startdate1 != "" && startdate2 != "" && startdate3 != "" && enddate1 != "" && enddate2 != "" && enddate3 != "") {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStartMass(0);
      fetchData({
        search: receiptCode != "" ? receiptCode : "",
        awb_code: barcode != "" ? barcode : "",
        cust_code: custCodeFilter.custCode === '' ? user.username : custCodeFilter.custCode,
        pageNo: 1,
        perPage: pageSizes,
        status: status != "" ? status : "",
        citizen_id: national_id ? national_id : "",
        sender_phone: sender_phone ? sender_phone : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : ""
      });
    }

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate เท่ากับ ""
    else if (
      startdate1 != "" &&
      startdate2 != "" &&
      startdate3 != "" &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData({
        search: receiptCode != "" ? receiptCode : "",
        awb_code: barcode != "" ? barcode : "",
        cust_code: custCodeFilter.custCode === '' ? user.username : custCodeFilter.custCode,
        pageNo: 1,
        perPage: pageSizes,
        status: status != "" ? status : "",
        sender_phone: sender_phone ? sender_phone : "",
        citizen_id: national_id ? national_id : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : ""
      });
    }

    //ค้นหาเมื่อ EndDate ไม่เท่ากับ "" และ startDate เท่ากับ ""
    else if (
      enddate1 != "" &&
      enddate2 != "" &&
      enddate3 != "" &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      fetchData({
        search: receiptCode != "" ? receiptCode : "",
        awb_code: barcode != "" ? barcode : "",
        cust_code: custCodeFilter.custCode === '' ? user.username : custCodeFilter.custCode,
        sender_phone: sender_phone ? sender_phone : "",
        pageNo: 1,
        perPage: pageSizes,
        status: status != "" ? status : "",
        citizen_id: national_id ? national_id : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : ""
      });
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ "" และ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      (enddate1 != "" || enddate2 != "" || enddate3 != "")
    ) {
      SetData([]);
      if (startdate1 == "" || startdate2 == "" || startdate3 == "") {
        SetErrorStart("dropdown-custom form-control is-invalid");
        SetErrorStartMass(1);
      }
      if (enddate1 == "" || enddate2 == "" || enddate3 == "") {
        SetErrorEnd("dropdown-custom form-control is-invalid");
        SetErrorEndMass(1);
      }
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ EndDate เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetData([]);
      SetErrorStart("dropdown-custom form-control is-invalid");
      SetErrorStartMass(1);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
    }

    //แสดง validation เมื่อ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ startDate เท่ากับ ""
    else if (
      (enddate1 != "" || enddate2 != "" || enddate3 != "") &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetData([]);
      SetErrorEnd("dropdown-custom form-control is-invalid");
      SetErrorEndMass(1);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
    } else {
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData({
        search: receiptCode != "" ? receiptCode : "",
        awb_code: barcode != "" ? barcode : "",
        cust_code: custCodeFilter.custCode === '' ? user.username : custCodeFilter.custCode,
        sender_phone: sender_phone ? sender_phone : "",
        pageNo: 1,
        perPage: pageSizes,
        status: status != "" ? status : "",
        citizen_id: national_id ? national_id : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : ""
      });
    }
  };

  async function receiptPrintUpdateStatus(dataArray) {
    // var daraArray = {
    //   receiptCode: receiptID,
    //   freightPrice: freightPrice,
    //   getMoney: getMoney,
    //   changePrice: changePrice,
    // };
    console.log(dataArray);
    const res = await getPrintReceipt(dataArray);
    if (res.status === 1) {
      setDataReceipt(res.data);
      setDataArrayReceipt(res.dataArray);

      res.dataArray.filter((x) => x.bank_id !== "").map((value) => setBankId(value.bank_id));
      res.dataArray.filter((x) => x.bookbank_code !== "").map((value) => setBankCode(value.bookbank_code));
      res.dataArray.filter((x) => x.bookbank_name !== "").map((value) => setBankName(value.bookbank_name));
      fetchData({
        search: receiptCode != "" ? receiptCode : "",
        awb_code: barcode != "" ? barcode : "",
        cust_code: custCodeFilter.custCode === '' ? user.username : custCodeFilter.custCode,
        sender_phone: sender_phone ? sender_phone : "",
        pageNo: setPage.current_page,
        perPage: pageSizes,
        status: status != "" ? status : "",
        citizen_id: national_id ? national_id : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : ""
         },() => {
          setGetMoney("");
          setChangePrice(0);
              setTimeout(receiptPrint,300);
         });
      // setFreightPrice(0);
      //setStatus("1");
      // setSearch("");
      // handleClose2();
      // setTimeout(receiptPrint,300);
    } else {
      swal({
        title: "ไม่สามารถพิมพ์ได้!",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
  }
  async function updateParcelInfo(data){
    // alert(JSON.stringify(data));
    let res = await updateParcel(data);
    // alert(JSON.stringify(res));
    // alert(res.status);
    // alert(JSON.stringify(res.res));
    if (res.res[0].status === 1) {
      swal({
        title: "success",
        text: "",
        icon: "success",
        showConfirmButton: true,
        button: "ปิด",
        timer: 15000,
      });
    } else {
      swal({
        title: "ไม่สามารถพิมพ์ได้!",
        text: res.message,
        icon: "warning",
        showConfirmButton: true,
        button: "ปิด",
        timer: 15000,
      });
    }
  }

  async function getParcelInfo(data){
    let res = await GetParcelsFromReceipt(data);
    if(res !== false){
      setDataParcel(res);
      setAwb_code(res[0].awb_code);
      setDataEdit(res[0]);
      setWeight(res[0].weight);
      setBox_width(res[0].box_width);
      setBox_length(res[0].box_length);
      setBox_height(res[0].box_height);
      setBox_size(res[0].box_size);
      setShipment_cost(res[0].shipment_cost);
      setCod_cost(res[0].cod_cost);
      setReceiver_province(res[0].receiver_province);
      setReceiver_amphur(res[0].receiver_amphur);
      setSender_type(res[0].sender_type);
      setService_cod(res[0].service_cod);
    }
    // alert(JSON.stringify(res));
  }

  async function Reprint(data) {
    let res = await reprintReceipt(data);
    if (res.status === 1) {
      setDataReceipt(res.data);
      setDataArrayReceipt(res.dataArray);
      res.dataArray.filter((x) => x.bank_id !== "").map((value) => setBankId(value.bank_id));
      res.dataArray.filter((x) => x.bookbank_code !== "").map((value) => setBankCode(value.bookbank_code));
      res.dataArray.filter((x) => x.bookbank_name !== "").map((value) => setBankName(value.bookbank_name));
      setRemark("");
      receiptPrint();
    } else {
      swal({
        title: "ไม่สามารถพิมพ์ได้!",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
  }

  async function receiptPrint() {
    printJS({
      printable: elementRef.current,
      type: "html",
      scanStyles: false,
      css: [
        window.location.protocol + "//" + window.location.host + "/newporlor/static/Printer.css",
        "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
      ],
      style: "@page {size: 8cm 30cm;margin: 0cm;}",
      onError: (error) => {
        console.log(error);
        alert('Error found.');
        console.log({
    ref: elementRef.current,
  });
      }
    });
  }

  async function handleSubmit2Excel(){
    SetPrintLebel([]);
    Data2([]);
    PageNo(1);
    SetData2([]);
    SetError("form-control");
    SetErrorMass(0);

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate ไม่เท่ากับ ""
    if (startdate1 != "" && startdate2 != "" && startdate3 != "" && enddate1 != "" && enddate2 != "" && enddate3 != "") {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStartMass(0);
      fetchData2({
        search: barcode != "" ? barcode : "",
        // shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        // cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        cust_code : sender_phone != "" ? sender_phone : ""
      });
    }

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate เท่ากับ ""
    else if (
      startdate1 != "" &&
      startdate2 != "" &&
      startdate3 != "" &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData2({
        search: barcode != "" ? barcode : "",
        // shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        // cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        cust_code : sender_phone != "" ? sender_phone : ""
      });
    }

    //ค้นหาเมื่อ EndDate ไม่เท่ากับ "" และ startDate เท่ากับ ""
    else if (
      enddate1 != "" &&
      enddate2 != "" &&
      enddate3 != "" &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      fetchData2({
        search: barcode != "" ? barcode : "",
        // shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        // cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        cust_code : sender_phone != "" ? sender_phone : ""
      });
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ "" และ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      (enddate1 != "" || enddate2 != "" || enddate3 != "")
    ) {
      SetData2([]);
      if (startdate1 == "" || startdate2 == "" || startdate3 == "") {
        SetErrorStart("dropdown-custom form-control is-invalid");
        SetErrorStartMass(1);
      }
      if (enddate1 == "" || enddate2 == "" || enddate3 == "") {
        SetErrorEnd("dropdown-custom form-control is-invalid");
        SetErrorEndMass(1);
      }
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ EndDate เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetData2([]);
      SetErrorStart("dropdown-custom form-control is-invalid");
      SetErrorStartMass(1);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
    }

    //แสดง validation เมื่อ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ startDate เท่ากับ ""
    else if (
      (enddate1 != "" || enddate2 != "" || enddate3 != "") &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetData2([]);
      SetErrorEnd("dropdown-custom form-control is-invalid");
      SetErrorEndMass(1);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
    } else {
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData2({
        search: barcode != "" ? barcode : "",
        // shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        // cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        cust_code : sender_phone != "" ? sender_phone : ""
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    SetData([]);
    PageNo(newPage);
    fetchData({
      search: receiptCode != "" ? receiptCode : "",
        awb_code: barcode != "" ? barcode : "",
        cust_code: custCodeFilter.custCode === '' ? user.username : custCodeFilter.custCode,
        sender_phone: sender_phone ? sender_phone : "",
        pageNo: newPage,
        perPage: pageSizes,
        status: status != "" ? status : "",
        citizen_id: national_id ? national_id : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : ""
    });
  };

  function pageSize(data) {
    SetData([]);
    PageNo(1);
    PageSizes(data);
    fetchData({
      search: receiptCode != "" ? receiptCode : "",
        awb_code: barcode != "" ? barcode : "",
        cust_code: custCodeFilter.custCode === '' ? user.username : custCodeFilter.custCode,
        sender_phone: sender_phone ? sender_phone : "",
        pageNo: 1,
        perPage: data,
        status: status != "" ? status : "",
        citizen_id: national_id ? national_id : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : ""
    });
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="container-fluid mt-2 mb-2">
            <div className="text-center text-title-color">
              <h2>{lang.language === "th" ? "การพิมพ์ใบเสร็จ" : "Receipt"} </h2>
            </div>
            <br />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                SetData([]);
                handleSubmit();
              }}
            >
            {localStorage.getItem("superkey") !== '1947' ? ( 
                <div className="row ">
                  <div className="col-12 col-lg-4 px-md-1 mt-1">
                    <label>ประเภทลูกค้า</label>
                    {localStorage.getItem("superkey") === 'service' ? ( 
                        <select
                          value={custCodeFilter.filter}
                          onChange={(e) => {
                            setCustCodeFilter({
                              ...custCodeFilter,
                              custCode: "",
                              filter: e.target.value,
                            })
                          }}
                          className="form-control dropdown-custom"
                      >
                          <option value={''}>-- กรุณาระบุประเภทลูกค้า --</option>
                          
                          <option value={'2'}>เครดิต</option>
                          <option value={'3'}>หน้าร้าน</option>
                          <option value={'6'}>ลูกค้าทั่วไป</option>
                        </select>   
                        ):(
                          <select
                          value={custCodeFilter.filter}
                          onChange={(e) => {
                            setCustCodeFilter({
                              ...custCodeFilter,
                              custCode: "",
                              filter: e.target.value,
                            })
                          }}
                          className="form-control dropdown-custom"
                      >
                          <option value={''}>-- กรุณาระบุประเภทลูกค้า --</option>
                          <option value={'6'}>ลูกค้าทั่วไป</option>
                        </select>   
                        )}
                    
                  </div>
                  <div className="col-12 col-lg-4 px-md-1 mt-1">
                    <label>สาขา</label>
                    <Typeahead
                        defaultSelected={custCodeFilter.custCodes.filter((a) => a.cust_code === custCodeFilter.custCode)}
                        id="brand"
                        name="brand"
                        labelKey={(option) => `${option.cust_code} ${option.name}`}
                        onChange={(e) => {
                          if (e.length > 0) {
                            let value = e[0].cust_code;
                            setCustCodeFilter({
                              ...custCodeFilter,
                              custCode: value,
                            });
                          }
                        }}
                        onInputChange={(text, e) => {
                          setCustCodeFilter({
                            ...custCodeFilter,
                            custCode: '',
                          })
                        }}
                        options={custCodeFilter.custCodes}
                        placeholder="-- กรุณาระบุสาขา --"
                        selected={custCodeFilter.custCode === '' ? [] : custCodeFilter.custCodes.filter((a) => a.cust_code === custCodeFilter.custCode)}
                    />
                  </div>
                  <div className="col-12 col-lg-4 px-md-1 mt-1">
                  <label>{lang.language === "th" ? "หมายเลขโทรศัพท์มือถือผู้ส่ง / ชื่อผู้ส่ง" : "Search by sender Phone or name"}</label>
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      value={sender_phone}
                      onChange={(e) => Sender_phone(e.target.value)}
                      id="clear"
                      placeholder={lang.language === "th" ? "หมายเลขโทรศัพท์มือถือผู้ส่ง / ชื่อผู้ส่ง" : "Search by sender Phone or name"}
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>

                </div>) : ""}
            {localStorage.getItem('superkey') !== '1947' ? (
              <div className="row ">
                <div className="col-12 col-lg-4 px-md-1 mt-1">
                  <label>{lang.language === "th" ? "บาร์โค้ด" : "Search by barcode"}</label>
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      value={barcode}
                      onChange={(e) => Barcode(e.target.value)}
                      id="clear"
                      placeholder={lang.language === "th" ? "บาร์โค้ด" : "Search by barcode"}
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 col-lg-4 px-md-1 mt-1">
                  <label> {lang.language === "th" ? "หมายเลขใบเสร็จ" : "Search by receipt Code"} </label>
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      value={receiptCode}
                      onChange={(e) => ReceiptCode(e.target.value)}
                      id="clear"
                      placeholder={lang.language === "th" ? "หมายเลขใบเสร็จ" : "Search by receipt Code"}
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12 col-lg-4 px-md-1 mt-1">
                  <label>{lang.language === "th" ? "หมายเลขบัตรประชาชน" : "Search by national ID"}</label>
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      value={national_id}
                      onChange={(e) => National_id(e.target.value)}
                      id="clear"
                      placeholder={lang.language === "th" ? "หมายเลขบัตรประชาชน" : "Search by national ID"}
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ):
            (<div className="row justify-content-md-center">
                <div className="col-sm-6 col-md-6 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      value={barcode}
                      onChange={(e) => Barcode(e.target.value)}
                      id="clear"
                      placeholder={lang.language === "th" ? "บาร์โค้ด" : "Search by barcode"}
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-sm-6 col-md-6 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      value={receiptCode}
                      onChange={(e) => ReceiptCode(e.target.value)}
                      id="clear"
                      placeholder={lang.language === "th" ? "หมายเลขใบเสร็จ" : "Search by receipt code"}
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
            </div>
             )}
              <br />
              <div className="row justify-content-md-center">
                <div className="col-12 col-lg-2">
                  <br />
                  <center style={{ marginTop: "10px" }}>
                    <div className="form-check">
                      <input
                        checked={dateNow === 1 ? false : true}
                        style={{ width: "15px", height: "15px" }}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={(e) => {
                          if (dateNow === 1) {
                            setDateNow(0);
                            SetStart1(year);
                            SetStart2(("0" + month).slice(-2));
                            SetStart3(date);
                            SetEnd1(year);
                            SetEnd2(("0" + month).slice(-2));
                            SetEnd3(date);
                          } else {
                            setDateNow(1);
                            SetStart1("");
                            SetStart2("");
                            SetStart3("");
                            SetEnd1("");
                            SetEnd2("");
                            SetEnd3("");
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="flexCheckDefault" style={{ marginLeft: "10px" }}>
                        วันที่ปัจจุบัน
                      </label>
                    </div>
                  </center>
                </div>
                <div className="col-12 col-lg-4">
                  <span>ตั้งแต่วันที่เปิดบิล</span>
                  <div className="row">
                    <div className="col-12 col-sm-4 col-md-4 px-md-1 mt-1">
                      <select
                        className={errorStartdate}
                        value={startdate1}
                        onChange={(e) => {
                          SetStart1(e.target.value);
                        }}
                      >
                        <option value=""> -- ปี -- </option>
                        {years.map((year, index) => (
                          <option key={`year${index}`} value={year}>
                            {year + 543}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                      <select
                        className={errorStartdate}
                        value={startdate2}
                        onChange={(e) => {
                          SetStart2(e.target.value);
                        }}
                      >
                        <option value=""> -- เดือน -- </option>
                        {monthTH.map((item, index) => (
                          <option value={item.id} key={item.id}>
                            {item.monthName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                      <select
                        className={errorStartdate}
                        value={startdate3}
                        onChange={(e) => {
                          SetStart3(e.target.value);
                        }}
                      >
                        <option value=""> -- วัน -- </option>
                        {Array.from(Array(31), (e, i) => (
                          <option key={i} value={i + 1}>{i + 1}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {errorStartMass == 1 && errorMassSta != 1 && errorMassSta != 2 && errorMassSta != 3 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณาเลือก ปี, เดือน, วัน{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 col-lg-4">
                  <span>ถึงวันที่เปิดบิล</span>
                  <div className="row">
                    <div className="col-12 col-sm-4 px-md-1 mt-1">
                      <select
                        className={errorEnddate}
                        value={enddate1}
                        onChange={(e) => {
                          SetEnd1(e.target.value);
                        }}
                      >
                        <option value=""> -- ปี -- </option>
                        {years.map((year, index) => (
                          <option key={`year${index}`} value={year}>
                            {year + 543}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4  px-md-1 mt-1">
                      <select
                        className={errorEnddate}
                        value={enddate2}
                        onChange={(e) => {
                          SetEnd2(e.target.value);
                        }}
                      >
                        <option value=""> -- เดือน -- </option>
                        {monthTH.map((item, index) => (
                          <option value={item.id} key={item.id}>
                            {item.monthName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4  px-md-1 mt-1">
                      <select
                        className={errorEnddate}
                        value={enddate3}
                        onChange={(e) => {
                          SetEnd3(e.target.value);
                        }}
                      >
                        <option value=""> -- วัน -- </option>
                        {Array.from(Array(31), (e, i) => (
                          <option value={i + 1} key={i}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {errorEndMass == 1 && errorMassSta != 1 && errorMassSta != 2 && errorMassSta != 3 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณาเลือก ปี, เดือน, วัน{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
                
                <div className="col-12 col-lg px-md-1 mt-0" style={{ display: "none" }}>
                  <br />
                  <center style={{ marginTop: "10px" }}>
                    <div className="form-check form-check-inline">
                      <input
                        checked={true}
                        readOnly
                        style={{ width: "15px", height: "15px" }}
                        className="form-check-input"
                        type="checkbox"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="1"
                      />
                      <label className="form-check-label" htmlFor="inlineRadio1">
                        มี COD
                      </label>
                    </div>
                  </center>
                </div>
              </div>
              <br />
              <center>
                <i className="bi bi-search"></i>
                <button
                  disabled={loading == 1 ? true : false}
                  type="submit"
                  className={loading == 1 ? "btn btn-secondary" : "btn btn-primary"}
                >
                  <Icon.Search /> ค้นหา
                </button>
                <span>&nbsp;</span>
                <button type="button" className="btn btn-secondary" onClick={clear}>
                  <Icon.ArrowClockwise /> ล้างค่า
                </button>
              </center>
            </form>
          </div>
        </div>
      </div>
      <div id="showData">
        {setData.length !== 0 || setErrorMassNoData === 1 ? (
          <div className="mt-4">
            <div className="d-flex justify-content-between mb-2">
          {/* <div>
            <span>
                  <button className="btn btn-info mr-2" onClick={()=>{
                    printJS({
                        printable: "printTable",
                        type: "html",
                      });
                  }}><Icon.Printer /> พิมพ์</button>
            </span>
            <span>
            <button className="btn btn btn-info" onClick={ async (e)=>{
                    await handleSubmit2Excel();

                  }}><Icon.FileEarmarkArrowUp /> export</button>
            </span>
          </div> */}
          {/* <ExcelFile
                element={
                  // <button style={{ display: "none" }} id="downloadEx">
                  <button style={{display: "none"}} className="btn btn-primary" id="downloadEx1">
                    Download Data
                  </button>
                }
                filename="COD History"
              >
                <ExcelSheet data={setData2} name={"COD History"}>
                  <ExcelColumn label="หมายเลขโทรศัพท์" value="sender_phone" width={{ wpx: 80 }} />
                  <ExcelColumn label="ว/ด/ป ที่โอนเงินคืนลูกค้า" value="created_date" />
                  <ExcelColumn label="รหัสลูกค้า" value="cust_code" />
                  <ExcelColumn label="ข้อมูลผู้ส่ง" value="sender_name" />
                  <ExcelColumn label="หมายเลขบัญชี" value="bookbank_code" />
                  <ExcelColumn label="ธนาคาร" value="bookbank_name" />
                  <ExcelColumn label="เลขพัสดุฯ" value="awb_code" />
                  <ExcelColumn label="ข้อมูลผู้รับ" value="receiver_name" />
                  <ExcelColumn label="ที่อยู่" value="receiver_amphur" />
                  <ExcelColumn label="จังหวัด" value="receiver_province" />
                  <ExcelColumn label="จำนวนเงินCOD" value="cod_cost" />
                  <ExcelColumn label="เลขที่โอน" value="bank_transfer_receipt" />
                  <ExcelColumn label="หมายเหตุ" value="remarks" />
                </ExcelSheet>
              </ExcelFile> */}
              <div className = "col-1 mb-2">
                <select
                  value={pageSizes}
                  className="form-control"
                  aria-label="Default select example"
                  onChange={(e) => {
                    pageSize(e.target.value);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
          </div>
            <div className="contentPrint2" style={{ display: "none" }}>
          <div id="printReceipt" ref={elementRef}>
            <div key={nanoid()}>
              {dataArrayReceipt.length > 0 ? (
                <FormatReceipt
                    key={nanoid()}
                    supplies={{
                      receiptCode: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].receipt_code : "",
                      date: date,
                      month: month,
                      year: year,
                      time: time,
                      sender_name: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].sender_name : "",
                      sender_phone: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].sender_phone : "",
                      amont_parcel: dataReceipt.amont,
                      freightPrice: localStorage.getItem('superkey')==='1947'? freightPrice : dataReceipt.freightPrice,
                      getMoney: dataReceipt.getMoney,
                      changePrice: dataReceipt.changePrice,
                      printLebel: dataArrayReceipt,
                      /////////////////
                      cod_cost: dataReceipt.sumCod,
                      service_cod: dataReceipt.sumSerCod,
                      bank_id: bankId,
                      bookbank_name: bankName,
                      bookbank_code: bankCode,
                    }}
                  />
              ) : (
                <div id="contentPrintEmpty">ไม่สามารถสร้างใบเสร็จได้</div>
              )}
            </div>
          </div>
        </div>
            <div className="contentPrint2" style={{ display: "none" }}>
          <div id="printReceipt2">
            <div key={nanoid()}>
              {dataArrayReceipt.length > 0 ? (
                <FormatReceipt
                    key={nanoid()}
                    supplies={{
                      receiptCode: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].receipt_code : "",
                      date: date,
                      month: month,
                      year: year,
                      time: time,
                      sender_name: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].sender_name : "",
                      sender_phone: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].sender_phone : "",
                      amont_parcel: dataReceipt.amont,
                      freightPrice: freightPrice,
                      getMoney: getMoney,
                      changePrice: changePrice,
                      printLebel: dataArrayReceipt,
                      /////////////////
                      cod_cost: dataReceipt.sumCod,
                      service_cod: dataReceipt.sumSerCod,
                      bank_id: bankId,
                      bookbank_name: bankName,
                      bookbank_code: bankCode,
                    }}
                  />
              ) : (
                <div id="contentPrintEmpty">ไม่สามารถสร้างใบเสร็จได้</div>
              )}
            </div>
          </div>
        </div>
            <div id="tableRow">
              <div style={{ overflow: "Auto" }} id="printTable">
              <table  className="table table-bordered">
                <thead style={{ backgroundColor: "#3f75c1" }}>
                  <tr style={{ color: "#FFFFFF" }}>
                    <th>{lang.language === "th" ? "วันที่" : "Date"}</th>
                    <th>{lang.language === "th" ? "รหัสร้านค้า" : "Username"}</th>
                    <th>{lang.language === "th" ? "หมายเลขใบเสร็จ" : "Receipt number"}</th>
                    <th>{lang.language === "th" ? "ชื่อผู้ส่ง" : "Sender name"}</th>
                    <th>{lang.language === "th" ? "บาร์โค้ด" : "Barcodes"}</th>
                    <th>{lang.language === "th" ? "รวมค่าส่งพัสดุฯ (บาท)" : "Total price (baht)"}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody key={nanoid()}>
                  {setData ? setData.map((value, index) => (
                    <tr key={nanoid()}>
                      <td >
                        {" "}
                        <DateTH date={value.create_date.substring(0, 10)} />
                      </td>
                      <td>{value.cust_code}</td>
                      <td>{value.receipt_code}</td>
                      <td>{value.sender_name}</td>
                      <td>{value.awb_code.split(',').map((valueAwb_code, indexAwb_code)=> (
                        <p> {valueAwb_code}</p>
                      ))}</td>
                      <td> {value.total}</td>
                      <td>
                      <center>
                      <button
                      type="button"
                      className= "btn btn-primary"
                      onClick={() => {
                        setReceiptCode(value.receipt_code);
                        if (value.status==="1"){

                          setFreightPrice(parseInt(value.total));
                            Reprint({
                              receipt_code: value.receipt_code,
                              remark: remark,
                              cust_code: value.cust_code,
                            });
                        }
                        else{
                          if (localStorage.getItem('superkey') === '1947'){

                            setFreightPrice(parseInt(value.total));
                            var dataArray = {
                              receiptCode: value.receipt_code,
                              freightPrice: parseInt(value.total),
                              getMoney: getMoney,
                              changePrice: changePrice,
                              awb_codes: value.awb_code,
                            };
                            receiptPrintUpdateStatus(dataArray);
                          }
                          else{
                            setReceiptCode(value.receipt_code);
                            setFreightPrice(parseInt(value.total));
                            Barcodes(value.awb_code)
                            handleShow2();
                          }
                        }
                      }}
                    >
                      <Icon.Printer />
                    </button>{" "}
                    {/* <button
                          type="button"
                          //disabled={value.awb_code !== '' && value.awb_code !== null ? false : true}
                          className={localStorage.getItem('superkey') !== '1947' ? 'btn btn-info' : 'hidden-ct'}
                          //className = 'btn btn-info'
                          onClick={() => {
                            getParcelInfo({
                              awb_codes : value.awb_code,
                            });
                            handleShowEdit();
                          }}
                        >
                          <Icon.PencilSquare />
                        </button> */}
                        </center>
                      </td>
                    </tr>
                  )) : <div/>}
                </tbody>
              </table>
              <center>
                {setErrorMassNoData == 1 ? (
                  <font color="red" size="3">
                    {" "}
                    ไม่พบข้อมูล!{" "}
                  </font>
                ) :
                    (
                  <div className="d-flex justify-content-between">
                  <div id="total">จำนวนทั้งหมด {setPage.total} รายการ</div>
                    <div id="pagination">
                      <Pagination
                        count={parseInt(setPage.last_page)}
                        page={pageNo}
                        onChange={handleChangePage}
                        color="primary"
                        size="small"
                        defaultPage={6}
                        siblingCount={1}
                      />
                    </div>
                  </div>
                )}
              </center>
            </div>
            </div>
            <br />
          </div>
        ) : loading === 1 ? (
          <div className="m-5">
            <div className="text-center">
              <div className="spinner-border  text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              {" ..."}
              กำลังโหลดข้อมูล
            </div>
          </div>
        ) : (
          <div />
        )}

      </div>


      <br />
      <br />
      {showEdit ? <Modal show={showEdit} onHide={handleCloseEdit} size="lg">
        <Modal.Header closeButton>
        <Modal.Title>
        แก้ไขข้อมูล
        </Modal.Title>
        </Modal.Header>
           <Modal.Body style={{ fontSize: '19px' }}>
              <div className="row" style={{ justifyContent: 'center', margin: '10px' }}>
                <h2>{awb_code}</h2>
              </div>
              <div className="row" style={{ justifyContent: 'center', margin: '10px' }}>
                <div className="col col-sm-3 px-md-1 mt-1">
                  <div className="form-group">
                    <label>น้ำหนัก (กก.)</label>
                    <input
                        // autocomplete="off"
                        value={weight}
                        type="number"
                        name={'weight'}
                        className={`form-control ${isWeightInvalid() ?'is-invalid':'is-valid' }`}
                        onBlur={handleBlur}
                        placeholder={weight}
                        onChange={async (e) => {
                          setWeight(e.target.value);
                          // setFieldValue('weight', e.target.value);
                          if (e.target.value > 0) {
                            var totalvar = await PriceCalculation(box_size, e.target.value, receiver_province,receiver_amphur, sender_type);
                            setShipment_cost(totalvar);
                            // setFieldValue('shipment_cost', total);
                            setTotal(parseFloat(totalvar)+parseFloat(service_cod));
                            // setFieldValue('total', total + values.service_cod);
                          }
                        }}
                      />
                    {isWeightInvalid() && <InvalidMessage text={isWeightInvalid()} /> }
                  </div>
                </div>
                <div className="col col-sm-3 px-md-1 mt-1">
                  <div className="form-group">
                    <label>ความกว้าง (ซม.)</label>
                    <input
                      className={'form-control '}
                      type="number"
                      name="box_width"
                      onBlur={handleBlur}
                      value={box_width}
                      onChange={async (e) => {
                        setBox_width(e.target.value);
                        // setFieldValue('box_width', e.target.value);
                        if (e.target.value !== '' && box_length !== '' && box_height !== '') {
                          var sizeTotal = 0;
                          sizeTotal = parseInt(e.target.value) + parseInt(box_length) + parseInt(box_height);
                          setBox_size(sizeTotal);
                          // setFieldValue('box_size', sizeTotal);
                          var totalvar = await PriceCalculation(sizeTotal, weight, receiver_province, receiver_amphur, sender_type);
                          setShipment_cost(totalvar);
                          // setFieldValue('shipment_cost', total);
                          setTotal(parseFloat(totalvar) +parseFloat(service_cod));
                          // setFieldValue('total', totalvar + values.service_cod);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col col-sm-3 px-md-1 mt-1">
                  <div className="form-group">
                    <label>ความยาว (ซม.)</label>
                    <input
                      className={'form-control ' }
                      type="number"
                      name="box_length"
                      onBlur={handleBlur}
                      value={box_length}
                      onChange={async (e) => {
                        setBox_length(e.target.value);
                        // setFieldValue('box_length', e.target.value);
                        if (e.target.value !== '' && box_width !== '' && box_height !== '') {
                          var sizeTotal = 0;
                          sizeTotal = parseInt(e.target.value) + parseInt(box_width) + parseInt(box_height);
                          setBox_size(sizeTotal);
                          // setFieldValue('box_size', sizeTotal);
                          var totalvar = await PriceCalculation(sizeTotal, weight, receiver_province, receiver_amphur, sender_type);
                          setShipment_cost(totalvar);
                          // setFieldValue('shipment_cost', totalvar);
                          setTotal(parseFloat(totalvar)+parseFloat(service_cod));
                          // setFieldValue('total', total + values.service_cod);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col col-sm-3 px-md-1 mt-1">
                  <div className="form-group">
                    <label>ความสูง (ซม.)</label>
                    <input
                      className={'form-control ' }
                      type="number"
                      name="box_height"
                      onBlur={handleBlur}
                      value={box_height}
                      onChange={async (e) => {
                        setBox_height(e.target.value);
                        // setFieldValue('box_height', e.target.value);
                        if (e.target.value !== '' && box_width !== '' && box_length !== '') {
                          var sizeTotal = 0;
                          sizeTotal = parseInt(e.target.value) + parseInt(box_width) + parseInt(box_length);
                          setBox_size(sizeTotal);
                          // setFieldValue('box_size', sizeTotal);
                          var totalvar = await PriceCalculation(sizeTotal, weight, receiver_province, receiver_amphur, sender_type);
                          setShipment_cost(totalvar);
                          // setFieldValue('shipment_cost', total);
                          setTotal(parseFloat(totalvar)+parseFloat(service_cod));
                          // setFieldValue('total', total + values.service_cod);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col col-sm-4 px-md-1 mt-1">
                  <sppan>กว้าง + ยาว + สูง (ซม.)</sppan>
                  <input className={`form-control ${isTotalSizeInvalid() ? 'is-invalid' : 'is-valid'}`} value={box_size} readOnly />
                  {isTotalSizeInvalid() && <InvalidMessage text={isTotalSizeInvalid()} /> }
                </div>
                <div className="col col-sm-4 px-md-1 mt-1">
                  <sppan>ค่าขนส่ง (บาท)</sppan>
                  <input className="form-control" value={shipment_cost} readOnly />
                </div>
                <div className="col col-sm-4 px-md-1 mt-1">
                  <sppan>ยอดสุทธิ (บาท)</sppan>
                  <input className="form-control" value={total} readOnly />
                </div>
              </div>
              <br />
              <center>
              {
                  dataParcel.length>1 && indexDataParcel>0 ? (
                    <button className="btn btn-info" type="submit" onClick={()=>{
                      if(indexDataParcel>0){
                        setIndexDataParcel(indexDataParcel-1);
                        setAwb_code(dataParcel[indexDataParcel].awb_code);
                        setDataEdit(dataParcel[indexDataParcel]);
                        setWeight(dataParcel[indexDataParcel].weight);
                        setBox_width(dataParcel[indexDataParcel].box_width);
                        setBox_length(dataParcel[indexDataParcel].box_length);
                        setBox_height(dataParcel[indexDataParcel].box_height);
                        setBox_size(dataParcel[indexDataParcel].box_size);
                        setShipment_cost(dataParcel[indexDataParcel].shipment_cost);
                        setCod_cost(dataParcel[indexDataParcel].cod_cost);
                        setReceiver_province(dataParcel[indexDataParcel].receiver_province);
                        setReceiver_amphur(dataParcel[indexDataParcel].receiver_amphur);
                        setSender_type(dataParcel[indexDataParcel].sender_type);
                        setService_cod(dataParcel[indexDataParcel].service_cod);
                  }

                }}>
                  <Icon.ArrowLeft/>
                </button>

                  ):(
                    ""
                  )
                }
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button disabled={isWeightInvalid() || isTotalSizeInvalid()} className="btn btn-primary" type="submit" onClick={()=>{
                  //updateMultipleParcelsInfo
                  updateParcelInfo({
                    "awb_code":awb_code,
                    "weight":weight,
                    "box_size":box_size,
                    "box_length":box_length,
                    "box_width":box_width,
                    "box_height":box_height,
                    "shipment_cost":shipment_cost,
                    "cod_cost":cod_cost!=="" && cod_cost!==null?cod_cost:"",
                    "total":total,
                    "receipt_code":receipt_code
                  });
                  handleCloseEdit();
                }}>
                  บันทึก
                </button>
                &nbsp;&nbsp;
                <button className="btn btn-secondary" type="button" onClick={handleCloseEdit}>
                  ยกเลิก
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {
                  indexDataParcel<dataParcel.length-1 ? (
                    <button className="btn btn-info" type="submit" onClick={()=>{
                      if(indexDataParcel<dataParcel.length){
                        setIndexDataParcel(indexDataParcel+1);
                        setAwb_code(dataParcel[indexDataParcel].awb_code);
                        setDataEdit(dataParcel[indexDataParcel]);
                        setWeight(dataParcel[indexDataParcel].weight);
                        setBox_width(dataParcel[indexDataParcel].box_width);
                        setBox_length(dataParcel[indexDataParcel].box_length);
                        setBox_height(dataParcel[indexDataParcel].box_height);
                        setBox_size(dataParcel[indexDataParcel].box_size);
                        setShipment_cost(dataParcel[indexDataParcel].shipment_cost);
                        setCod_cost(dataParcel[indexDataParcel].cod_cost);
                        setReceiver_province(dataParcel[indexDataParcel].receiver_province);
                        setReceiver_amphur(dataParcel[indexDataParcel].receiver_amphur);
                        setSender_type(dataParcel[indexDataParcel].sender_type);
                        setService_cod(dataParcel[indexDataParcel].service_cod);
                  }

                }}>
                  <Icon.ArrowRight/>
                </button>

                  ):(
                    ""
                  )
                }

              </center>
              <br />
         </Modal.Body>
      </Modal> : <div/>}

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>พิมพ์ใบเสร็จ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <br />
            <div className="row">
              <div className="col-sm-12 mt-3">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">รวมค่าขนส่ง</label>
                  <div class="col-sm-9">
                    <input readOnly value={freightPrice} type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mt-3">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">รับเงิน</label>
                  <div class="col-sm-9">
                    <input
                      autoComplete="off"
                      value={getMoney}
                      type="number"
                      class="form-control"
                      onChange={(e) => {
                        setGetMoney(e.target.value);
                        if (e.target.value >= freightPrice && e.target.value !== "") {
                          setChangePrice(e.target.value - freightPrice);
                        } else {
                          setChangePrice(0);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mt-3">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">ทอนเงิน</label>
                  <div class="col-sm-9">
                    <input value={changePrice} readOnly type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <button
                  disabled={getMoney !== "" && getMoney >= freightPrice ? false : true}
                  className={
                    getMoney !== "" && getMoney >= freightPrice
                      ? "btn btn-info btn-lg mgTop-ct-20"
                      : "btn btn-secondary btn-lg mgTop-ct-20"
                  }
                  style={{ width: "100%" }}
                  onClick={() => {
                    var dataArray = {
                            receiptCode: receipt_code,
                            freightPrice: freightPrice,
                            getMoney: getMoney,
                            changePrice: changePrice,
                            awb_codes: barcodes, 
                          };
                          //setFreightPrice(parseInt(value.total));
                          receiptPrintUpdateStatus(dataArray);
                          handleClose2();
                    // receiptPrintUpdateStatus();
                  }}
                >
                  พิมพ์ใบเสร็จ
                </button>
              </div>
              <div className="col-sm-6">
                <button
                  // disabled={statusPrint === 1 ? false : true}
                  className="btn btn-secondary btn-lg mgTop-ct-20"
                  style={{ width: "100%" }}
                  onClick={() => {
                    handleClose2();
                    setFreightPrice(0);
                    setGetMoney("");
                    setChangePrice(0);
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
            <br />
            <br />
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}
const mapStateToProps = (state) => ({
  user: state.Authentication,
  lang: state.LanguageAction,
});

export default connect(mapStateToProps)(MyReceipt);
