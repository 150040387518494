import * as Yup from "yup";
// import { checkCitizen } from "../../../Helper/shareFunction";

const parcelPornorSchema = Yup.object().shape({
  sender: Yup.object().shape({
    citizenID: Yup.string().when("customerGroup", (value, schema) => {
      if (value === "1") {
        return schema
          .required("กรุณากรอก หมายเลขบัตรประชาชน")
          .test("is-citizenID", "กรุณาตรวจสอบเลขบัตรประชาชนอีกครั้ง", function checkCitizen(value) {
            if (value === undefined) {
              return false;
            }
            if (value.length !== 13) {
              return false;
            }
            let sum = 0;
            for (let i = 0; i < 12; i++) {
              sum += parseInt(value.charAt(i)) * (13 - i);
            }
            let mod = sum % 11;
            let check = (11 - mod) % 10;
            if (check === parseInt(value.charAt(12))) {
              return true;
            } else {
              return false;
            }
          });
      } else {
        return schema.required("กรุณากรอก หมายเลขบัตรประชาชน");
      }
    }),

    fullName: Yup.string()
      .required("กรุณากรอก ชื่อ-นามสกุล")
      .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
    phoneNumber: Yup.string()
      .required("กรุณากรอก เบอร์โทรศัพท์")
      .matches(/^[0-9]{4,10}$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง"),
    homeNumber: Yup.string().when("typeSender", (value, schema) => {
      if (value === "2") {
        return schema
          .required("กรุณากรอก เลขที่อยู่")
          .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "กรุณาตรวจสอบ เลขที่อยู่ อีกครั้ง");
      } else {
        return schema.notRequired();
      }
    }),
    district: Yup.string().when("typeSender", (value, schema) => {
      if (value === "2") {
        return schema.required("กรุณากรอก ตำบล");
      } else {
        return schema.notRequired();
      }
    }),
    amphoe: Yup.string().when("typeSender", (value, schema) => {
      if (value === "2") {
        return schema.required("กรุณากรอก อำเภอ");
      } else {
        return schema.notRequired();
      }
    }),
    province: Yup.string().when("typeSender", (value, schema) => {
      if (value === "2") {
        return schema.required("กรุณากรอก จังหวัด");
      } else {
        return schema.notRequired();
      }
    }),
    zipcode: Yup.string().when("typeSender", (value, schema) => {
      if (value === "2") {
        return schema.required("กรุณากรอก รหัสไปรษณีย์");
      } else {
        return schema.notRequired();
      }
    }),
    typeSender: Yup.string().required("กรุณาระบุ"),
    customerGroup: Yup.string().when("typeSender", (value, schema) => {
      if (value === "1") {
        return schema.required("กรุณาระบุ กลุ่มลูกค้า");
      } else {
        return schema.notRequired();
      }
    }),
  }),
  recipient: Yup.array().of(
    Yup.object().shape({
      fullName: Yup.string()
        .required("กรุณากรอก ชื่อ-นามสกุล")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      phoneNumber: Yup.string()
        .required("กรุณากรอก เบอร์โทรศัพท์ ")
        .matches(/^[0-9]{9,10}$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง"),
      homeNumber: Yup.string()
        .required("กรุณากรอก เลขที่อยู่")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "กรุณาตรวจสอบ เลขที่อยู่ อีกครั้ง"),

      deposit_type: Yup.string().required("กรุณาระบุ ประเภทการส่ง"),
      deposit_fullname: Yup.string().when("deposit_type", (value, schema) => {
        if (value === "2" || value === "3") {
          return schema
            .required("กรุณากรอก ชื่อ-นามสกุล")
            .max(20, "กรุณากรอกตัวอักษรน้อยกว่า 20 ตัว")
            .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ");
        } else {
          return schema.notRequired();
        }
      }),
      // .required("กรุณากรอก ชื่อ-นามสกุล")
      // .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      // deposit_phone: Yup.string().when("deposit_type", (value, schema) => {
      //   if (value === "2" || value === "3") {
      //     return schema.required("กรุณากรอก เบอร์โทรศัพท์ ").matches(/^[0-9]{9,10}$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง");
      //   } else {
      //     return schema.notRequired();
      //   }
      // }),
      // .required("กรุณากรอก เบอร์โทรศัพท์ ")
      // .matches(/^[0-9]{9,10}$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง"),

      district: Yup.string().required("กรุณากรอก ตำบล "),
      amphoe: Yup.string().required("กรุณากรอก อำเภอ "),
      province: Yup.string().required("กรุณากรอก จังหวัด "),
      zipcode: Yup.string().required("กรุณากรอก รหัสไปรษณีย์ "),
      materialWeight: Yup.number().required("กรุณากรอก น้ำหนัก ")
          .min(0.1, "กรุณากรอก น้ำหนักมากกว่า หรือเท่ากับ 0.1")
          .max(20,"โปรดป้อนน้ำหนักน้อยกว่าหรือเท่ากับ 20"),
      // materialSizeWide: Yup.number().required("กรุณากรอก ขนาดความกว้าง ").min(1, "กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1"),
      // materialSizeLong: Yup.number().required("กรุณากรอก ขนาดความยาว ").min(1, "กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1"),
      // materialSizeHigh: Yup.number().required("กรุณากรอก ขนาดความสูง ").min(1, "กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1"),
      // materialSize: Yup.number()
      //   .required("กรุณากรอก ขนาด ")
      //   .min(1, "กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1"),

      materialAccountName: Yup.string().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณากรอก ชื่อบัญชี ");
        } else {
          return schema.notRequired();
        }
      }),
      materialAccountNumber: Yup.string().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณากรอก เลขที่บัญชี ").matches(/^\d{9,18}$/, "กรุณาตรวจสอบ เลขที่บัญชี");
        } else {
          return schema.notRequired();
        }
      }),
      materialPriceCode: Yup.number().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณากรอก จำนวนเงิน COD ").min(1, "กรุณาระบุ จำนวนเงิน COD มากกว่า หรือเท่ากับ 1");
        } else {
          return schema.notRequired();
        }
      }),
      bankName: Yup.string().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณาระบุบ ธนาคาร");
        } else {
          return schema.notRequired();
        }
      }),
      // total: Yup.string().required("กรุณาระบุ จำนวนเงิน"),
    })
  ),
});
export default parcelPornorSchema;
