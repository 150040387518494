import Instance from "../Helper/axios";
import { URL } from '../Helper/baseURL';

export async function TrackingService(dataTracking) {
  try {
    const response = await Instance.post(URL + "Trackingweb/tracking", dataTracking);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function TrackingV2Service(param) {
  try {
    const formData = new FormData();
    Object.entries(param).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await Instance.post(URL + "trackingV2/TrackingV2/getTracking", formData);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
