import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router";

export default function ShopMenu({ handleShow }) {
  const history = useHistory();
  
  return (
    <>
      <Nav.Item>
        <NavDropdown title="ส่งพัสดุ" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={(e) => {
              history.push("Parcel");
            }}
          >
            ส่งพัสดุ
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("PrintLabel");
            }}
          >
            ตรวจสอบการส่งพัสดุ
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => {
              history.push("ImportExcel");
            }}
          >
            อัพโหลดข้อมูลพัสดุ
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          
          onClick={(e) => {
            history.push("myReceipt");
          }}
        >
          การพิมพ์ใบเสร็จ
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <NavDropdown title="รายงาน" id="basic-nav-dropdown">
          <NavDropdown.Item
            onClick={() => {
              history.push("TrackingReport");
            }}
          >
            รายงานติดตามพัสดุ
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={(e) => {
              history.push("ReportStockIn");
            }}
          >
            พิมพ์รายงานทะเบียนคุม
          </NavDropdown.Item>
          {/* <NavDropdown.Item
            onClick={(e) => {
              history.push("InvoiceReport");
            }}
          >
           Invoice Report
          </NavDropdown.Item> */}
        </NavDropdown>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/home"
          onClick={(e) => {
            handleShow();
          }}
        >
          พัสดุถูกส่งกลับ
        </Nav.Link>
      </Nav.Item>
    </>
  );
}
