import React from "react";
import FormServiceCust from "../contactus/FormServiceCust";
import ContactDetail from "../contactus/ContactDetail";

export default function Contactus() {
  return (
    <div>
        <div className="contactus">
            <div className="img-head-page"></div>
            <div className="container div-content">
                <div className="row">
                    <div className="col-md-6">
                        <ContactDetail/>
                    </div>
                    <div className="col-md-6">
                        <FormServiceCust/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}