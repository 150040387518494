import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import DateTH from '../../../Helper/date-th';

export default function ShowData({
  data,
  page,
  loading,
  errorMassNoData,
  pageNo,
  handleChangePage,
  perPage,
}) {
  return (
    <>
      <div style={{ overflow: "Auto" }}>
        <table className="table table-bordered">
          <thead className="table-blue">
            <tr>
              <th>ลำดับ</th>
              <th>ลาเบล</th>
              <th>สถานะ</th>
              {/* <th>น้ำหนัก ความสูง-ความกว้าง-ความยาว</th> */}
              <th>วันที่</th>
              <th>เวลา</th>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={index}>
                <td>{(page.current_page - 1) * perPage + (index + 1)}</td>
                <td>
                  {value.AWB_CODE !== null ? value.AWB_CODE : ""}
                </td>
                <td>
                  {value.shipment_status === "1"
                    ? "รับเข้าระบบ"
                    : value.shipment_status === "2"
                    ? "ศูนย์กระจายคัดแยกสินค้า"
                    : value.shipment_status === "3"
                    ? "อยู่ระหว่างขนส่ง"
                    : value.shipment_status === "4"
                    ? "ส่งภายในวันนี้"
                    : ""}
                </td>
                {/* <td>
                  <p>{value.weight+"Kg"}</p>
                  <p>{value.box_length + "cm * " +value.box_width + "cm * " + value.box_height + "cm"}</p></td> */}
                <td><DateTH date={value.Receiver_Date} /></td>
                <td>{value.Receiver_Time}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <center>
          {errorMassNoData === 1 && loading !== 1 ? (
            <font color="red" size="3">
              {" "}
              ไม่พบข้อมูล!{" "}
            </font>
          ) : (
            ""
          )}
        </center>
      </div>
      <center>
        {loading === 1 ? (
          <div className="m-5">
            <div className="text-center">
              <div className="spinner-border  text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              {" ..."}
              กำลังโหลดข้อมูล
            </div>
          </div>
        ) : (
          ""
        )}
      </center>
      <div className="d-flex justify-content-between">
        <div>จำนวนทั้งหมด {page.total} รายการ</div>
        <div>
          <Pagination
            count={parseInt(page.last_page)}
            page={pageNo}
            onChange={handleChangePage}
            color="primary"
            size="small"
            defaultPage={6}
            siblingCount={1}
          />
        </div>
      </div>
      <br />
      <br />
    </>
  );
}
