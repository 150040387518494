export default function LongDateTH(value) {
  if (value === undefined || value == "0000-00-00 00:00:00") {
    return "";
  } else {
    var day = value.substring(8, 10);
    var month = value.substring(5, 7);
    var year = value.substring(0, 4);
    var time = value.substring(11, 16);

    var monthTH = "";
    if (month === "01") {
      monthTH = "มกราคม";
    } else if (month === "02") {
      monthTH = "กุมภาพันธ์";
    } else if (month === "03") {
      monthTH = "มีนาคม";
    } else if (month === "04") {
      monthTH = "เมษายน";
    } else if (month === "05") {
      monthTH = "พฤษภาคม";
    } else if (month === "06") {
      monthTH = "มิถุนายน";
    } else if (month === "07") {
      monthTH = "กรกฎาคม";
    } else if (month === "08") {
      monthTH = "สิงหาคม";
    } else if (month === "09") {
      monthTH = "กันยายน";
    } else if (month === "10") {
      monthTH = "ตุลาคม";
    } else if (month === "11") {
      monthTH = "พฤศจิกายน";
    } else if (month === "12") {
      monthTH = "ธันวาคม";
    }

    if (time != "") {
      time = "เวลา " + time + " น.";
    } else {
      time = "";
    }

    var strDate = day + " " + monthTH + " " + (parseInt(year) + 543);
    var strTime = time;
    return [strDate, strTime];
  }
}
