import Instance from "../../../../Helper/axios";
import {URL} from "../../../../Helper/baseURL";

export const CONOTE_PER_PAGES = 10;

export const conoteList = (cust_code,search,pageNo,perPages) => {
    const formData = new FormData();
    Object.entries({
        search,pageNo,perPages,cust_code
    }).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return Instance.post(URL + 'printLabel/PrintLabel/conoteList', formData);
};
export const conoteBarcodes = (ref_no, only_one = false) => {
    const formData = new FormData();
    formData.append('ref_no', ref_no);
    formData.append('only_one', only_one);
    formData.append('cust_code', localStorage.getItem('username'));
    return Instance.post(URL + 'printLabel/PrintLabel/findByRefNo', formData);
};
export const conoteBarcodes2 = (ref_no, only_one = false) => {
    const formData = new FormData();
    formData.append('ref_no', ref_no);
    formData.append('only_one', only_one);
    formData.append('cust_code', localStorage.getItem('username'));
    return Instance.post(URL + 'printLabel/PrintLabel/findLabelByRefNo', formData);
};
export const update_ref_no = (ref_no,new_ref_no) => {
    return Instance.post(URL + 'printLabel/PrintLabel/update_ref_no', {
        ref_no,
        new_ref_no,
        cust_code: localStorage.getItem('username'),
    })
};
export const update_ref_no_status = (ref_nos) => {
    return Instance.post(URL + 'printLabel/PrintLabel/update_multiple_ref_no_status', {
        ref_nos,
        cust_code: localStorage.getItem('username'),
    })
};

export const conoteParcelGroups = (ref_nos,cust_code) => {
    return Instance.post(URL + 'printLabel/PrintLabel/conoteParcelGroups', {
        ref_nos: ref_nos,
        cust_code: cust_code
    });
};