import React from "react";
import Nav from "react-bootstrap/Nav";
import { useHistory } from "react-router";

export default function FinanceMenu() {
  const history = useHistory();

  return (
    <>
      <Nav.Item>
        <Nav.Link
          eventKey="/home"
          onClick={(e) => {
            history.push("HistoryCOD");
          }}
        >
          ประวัติ COD
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/home"
          onClick={(e) => {
            history.push("ManageCOD");
          }}
        >
          จัดการ COD
        </Nav.Link>
      </Nav.Item>
    </>
  );
}
