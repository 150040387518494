import b2b from "../../../Assets/images/service/B2B.png";
import b2c from "../../../Assets/images/service/B2C.png";
import c2c from "../../../Assets/images/service/C2C.png";
import { connect } from "react-redux";

function Services(props) {
  const { lang } = props;
  return lang.language === "th" ? <ServicesTH /> : <ServicesEN />;
}

const mapStateToProps = (state) => ({
  lang: state.LanguageAction,
});

export default connect(mapStateToProps)(Services);

function ServicesTH() {
  return (
    <div className="container">
      <br />
      <br />
      <div className="col-12">
        <div className="text-center text-title-color">
          <h2>B2B ธุรกิจถึงธุรกิจ</h2>
        </div>
        <div>
          <img src={b2b} width="100%" />
        </div>
        <div>
          <br />
          <h5 className="text-subtitle">Business to Business (Manufacturer, Industries, Companies)</h5>
          <p>
            ปอลอ เอ็กเพรส เป็นบริษัทในเครื่อเดียวกับบริษัท RFE service จำกัด ซึ่งให้บริการ B2B
            แก่บริษัทชั้นนำของประเทศไทยกว่า 400 บริษัท ดังนั้นจึงมีบริการส่งพัสดุทางด้านB2Bได้ดีและแม่นยำที่สุดในประเทศไทย
            โดยมีกระบบลำเลียงขนส่งสินค้าที่ทันสมัย และเทคโนโลยีการติดตามสถานะ 24 ชั่วโมง เพื่อจะส่งสินค้าให้ผู้รับได้ตรงเวลา
            ปลอดภัย ทั่วไทย
          </p>
        </div>
      </div>

      <div className="col-12">
        <div className="text-center text-title-color">
          <h2>C2C บุคคลถึงบุคคล</h2>
        </div>
        <div>
          <img src={c2c} width="100%" />
        </div>
        <div>
          <br />
          <h5 className="text-subtitle">Customer to Customer (agancies/franchise, ebay, shoppee,lazada,pantip market)</h5>
          <p>
            การจัดส่งแบบบุคคลถึงบุคคลบริษัทปอลอ เอ็กซ์เพรส
            มีระบบการขนส่งที่รวดเร็วและมีประสิทธิภาพสูงได้รับการรับรองจากบริษัท RFE service limited
            ซึ่งมีประสบการณืในการขนส่งในประเทศมากนานกว่า 20ปี จึงมั่นใจได้ว่าผู้รับจะได้รับพัสดุฯตรงเวลา ปลอดภัย ทั่วไทย ปอลอ
            เอ็กซ์เพรสมีช็อปอยู่ทั่วทุกภาคในประเทศไทย โดยในอนาคตจะเพิ่มช็อปไปถึงทุกจังหวัดทั่วประเทศ
          </p>
        </div>
      </div>

      <div className="col-12">
        <div className="text-center text-title-color">
          <h2>B2C ธุรกิจถึงบุคคล </h2>
        </div>
        <div>
          <img src={b2c} width="100%" />
        </div>
        <div>
          <br />
          <h5 className="text-subtitle">Business to Customers (E-commerce,Home shopping)</h5>
          <p>
            ปอลอ เอ็กเพรส ให้บริการส่งพัสดุทางด้านE-commerce และการขายผ่านสื่อโทรทัศน์ในประเทศไทย
            โดยมีกระบบลำเลียงขนส่งสินค้าที่ทันสมัย และเทคโนโลยีการติดตามสถานะ 24 ชั่วโมง เพื่อจะส่งสินค้าให้ผู้รับได้ตรงเวลา
            ปลอดภัย ทั่วไทย มีบริการเก็บเงินปลายทางทั่วประเทศ เลือกวิธีชำระเงินได้หลากหลายช่องทาง เช่น เงินสด
            หรือสแกนบาร์โค้ด
          </p>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

function ServicesEN() {
  return (
    <div className="container">
      <br />
      <br />
      <div className="col-12">
        <div className="text-center text-title-color">
          <h2>B2B Business to Business</h2>
        </div>
        <div>
          <img src={b2b} width="100%" />
        </div>
        <div>
          <br />
          <h5 className="text-subtitle">Business to Business (Manufacturer, Industries, Companies)</h5>
          <p>
          B2B (Business-to-Business) was the very first business model Porlor 
          Express and RFE adopted for our logistics services. 
          Our loyal customers consisted of manufacturers, wholesalers, 
          and retailers who need their goods delivered safely and promptly. 
          Our B2B services are, as the name suggests, explicitly offered to 
          businesses and corporate entities, not individual consumers.
          </p>
        </div>
      </div>

      <div className="col-12">
        <div className="text-center text-title-color">
          <h2>C2C Customer to Customer</h2>
        </div>
        <div>
          <img src={c2c} width="100%" />
        </div>
        <div>
          <br />
          <h5 className="text-subtitle">Customer to Customer (agancies/franchise, ebay, shoppee,lazada,pantip market)</h5>
          <p>
          As e-commerce becomes more and more popular, Porlor Express offers C2C (Consumer-to-Consumer) 
          services to facilitate the process of selling goods online for individual sellers. 
          Thanks to our well-developed delivery and COD (Cash on Delivery) system, 
          the products online sellers send with us reach their customers on time and safe, 
          while the COD goes right back to the seller with punctuality.
          </p>
        </div>
      </div>

      <div className="col-12">
        <div className="text-center text-title-color">
          <h2>B2C Business to Customers</h2>
        </div>
        <div>
          <img src={b2c} width="100%" />
        </div>
        <div>
          <br />
          <h5 className="text-subtitle">Business to Customers (E-commerce,Home shopping)</h5>
          <p>
          At Porlor Express, we also provide B2C (Business-to-Consumer) services.
          With our accumulated 20 years of experience in logistics, 
          and our optimized delivery systems, we offer express services for your everyday 
          parcel delivery needs, for very affordable rates. 
          Our B2C is specifically tailored to individual consumer’s needs.
          </p>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}
