import React, { Component } from "react";

export default class TdCustom extends Component {
    
    constructor(props) {
    super(props);

    if(props.idcode =='1' || props.idcode ===1 || props.idcode =="1"){
        this.state = {
            color: "#FF3333"
        }
    }
    else if(props.idcode =='5' || props.idcode ==5 || props.idcode =="5"){
        this.state = {
            color: "#FFFF11"
        }
    }
    else if(props.idcode =='10' || props.idcode ==10 || props.idcode =="10"){
        this.state = {
            color: "#55EE00"
        }
    }
    else{
        this.state = {
            color: "#FFFFFF"
        }
    }


  }
  render() {
    return (
        <td align={"center"} style={{
            
        }}>
        <br></br>
        <div align={"center"} style={{
            backgroundColor: this.state.color,
            borderRadius: 5
        }}>
            {this.props.text}
        </div>
        </td>
    );
  }
}
