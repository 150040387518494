import React from 'react'

export const RemainingParcels = ({text,total, remaining, unit = '', ...rest}) => {
  return (
      <div className='d-flex align-items-center' style={{gap: '5px', ...rest.style}}>
          <h6 className='mb-0' style={{ color: 'var(--blue)' }}>{ text} : </h6>
          <p className='p-2 mb-0' style={{ fontWeight: 'bold', color: 'var(--blue)', backgroundColor: 'white', borderRadius: '15px' }}>{remaining}/{total}</p>
          <p className='mb-0'>{unit}</p>
      </div>
  )
}
