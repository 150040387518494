import React from "react";
import PagSize from "../../../Data/pagSize.json";
import { connect } from "react-redux";

function FilterData({perPage, handleChangePageSize}) {

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-8 ">
          <div className="row float-left">
            <div className="col-12 col-md-8 ">
              {/* <div className="form-group">
                <input
                  type="text"
                  className="form-control  "
                  placeholder="ค้นหาข้อมูล"
                  onChange={(e) => {
                    //setKeyword(e.target.value);
                  }}
                />
              </div> */}
            </div>
            <div className="col-12 col-md-4">
              {/* <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary col-12"
                  onClick={(e) => {
                    //setEnterSh(nanoid());
                  }}
                >
                  ค้าหา
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-group float-right  ">
            <select
              name="pageSize"
              id="pageSize"
              className="form-control col-12"
              value={perPage}
              onChange={(e) => {
                handleChangePageSize(e.target.value);
              }}
            >
              {PagSize.map((v) => (
                <option value={v.key} key={v.key}>
                  {v.value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(FilterData);
