import {useState, useCallback} from 'react';
import {deleteHubUser, getHubUser} from "../services/hub-users.service";
const useHubUserDelete = (onSuccess) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const deleteItem = useCallback(
        async (id) => {
            setLoading(true);
            setErrorMessage(null);
            try {
                const data = await deleteHubUser(id);
                onSuccess();
            }
            catch(error){
                setErrorMessage(error.message);
            }
            finally {
                setLoading(false);
            }
        },
        [onSuccess],
    );

    return {
        deleteItem,
        loading,
        errorMessage,
        data,
    }
}

export default useHubUserDelete;