import { connect } from "react-redux";
import React, { useState } from "react";
import { PriceCalculation } from '../../../Components/PriceCalculation';
import {calculatedPrice} from "../../../Service/service.Parcels";

function PriceEstimation(props) {
  const { lang } = props;
  const [weight,setWeight] = useState("");
  const [width,setWidth] = useState("");
  const [length,setLength] = useState("");
  const [height,setHeight] = useState("");
  const [box_size,setBox_size] = useState("");
  const [shipment_cost,setShipment_cost] = useState("0.00");
  const [zipCode, setZipCode] = useState("");
  const receiver_province = "กระบี่"; 
  const receiver_amphur = "เกาะลันตา";
  const sender_type = "1";
  const [errorWeight,setErrorWeight] = useState("");
  const [errorBox_size,setErrorBox_size] = useState("");

  return (
    <>
      <div className="container">
              {/* <div
                className="card"
                style={{
                  borderTop: "4px solid #ff9900",
                  boxShadow: "0 4px 8px 0 #131212",
                  transition: "0.3s",
                }}
                // align="left"
              > */}
                <div className="card-body">
                  
                    {/* <div className="container-fluid mt-2 mb-2"> */}
                      <br />    
                      <div className="text-center text-title-color">
                        <h2>
                        {lang.language === "th" ? "คำนวณค่าจัดส่งพัสดุ" : "Price estimation"}
                        </h2>
                      </div>
                      <div>
                        <h4 className="text-center text-subtitle">
                        {lang.language === "th" ? "คำนวนค่าขนส่งพัสดุฯได้อย่างง่ายดาย" : "Easily determine your delivery fee"} </h4>
                      </div>
                      <div className="row" style={{ justifyContent: 'center', margin: '10px' }}>
                        <div className="col-md-3 col-sm-8 px-md-1">
                          <div className={"form-group "+errorWeight}>
                            <span>{lang.language === "th" ? "น้ำหนัก (สูงสุด 30 กก.)" : "Weight (max 30kg)"}</span>
                            <input
                                className="form-control"
                                value={weight}
                                type="number"
                                name={'weight'} 
                                min = {0}
                                max = {30}
                                onChange={async(e) => {
                                  if(e.target.value>=0 && e.target.value<=30){
                                    setWeight(e.target.value);
                                    setErrorWeight("");
                                  }else{
                                    setErrorWeight("is-invalid");
                                  }

                                  if (e.target.value > 0 && e.target.value <= 30 && box_size > 0){
                                    var result = await calculatedPrice(box_size, e.target.value, undefined, zipCode);
                                    setShipment_cost(parseFloat(result.data.price) + parseFloat(result.data.zipCode_fee));
                                  }
                                }}
                              />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-8 px-md-1 mt-1">
                          <span>{lang.language === "th" ? "ความกว้าง (ซม.)" : "Width (cm)"}</span>
                          <input
                              className="form-control "
                              type="number"
                              name="width"
                              value={width}
                              onChange={ async(e) => {
                                setWidth(e.target.value);
                                if (e.target.value !== '' && length !== '' && height !== '') {
                                  var sizeTotal = 0;
                                  if((parseInt(e.target.value) + parseInt(length) + parseInt(height))>210 || (parseInt(e.target.value) + parseInt(length) + parseInt(height))<0.01 ){
                                    setErrorBox_size("is-invalid");
                                    sizeTotal = parseInt(e.target.value) + parseInt(length) + parseInt(height);
                                    setBox_size(sizeTotal);
                                  }
                                  else{
                                    setErrorBox_size("");
                                    sizeTotal = parseInt(e.target.value) + parseInt(length) + parseInt(height);
                                    setBox_size(sizeTotal);
                                    if (sizeTotal <= 210 ){
                                      const result = await calculatedPrice(sizeTotal, weight, undefined, zipCode);
                                      setShipment_cost(parseFloat(result.data.price) + parseFloat(result.data.zipCode_fee));
                                    }
                                  }
                                }
                              }}
                            />
                        </div>
                        <div className="col-md-3 col-sm-8 px-md-1 mt-1">
                          <span>{lang.language === "th" ? "ความยาว (ซม.)" : "Length (cm)"}</span>
                          <input
                              className="form-control"
                              type="number"
                              name="length"
                              value={length}
                              onChange={async(e) => {
                                setLength(e.target.value)
                                if (e.target.value !== '' && width !== '' && height !== '') {
                                  var sizeTotal = 0;
                                  if((parseInt(e.target.value) + parseInt(width) + parseInt(height))>210 || (parseInt(e.target.value) + parseInt(width) + parseInt(height))<0.01){
                                    setErrorBox_size("is-invalid");
                                    sizeTotal = parseInt(e.target.value) + parseInt(width) + parseInt(height);
                                    setBox_size(sizeTotal);
                                  }
                                  else{
                                    setErrorBox_size("");
                                    sizeTotal = parseInt(e.target.value) + parseInt(width) + parseInt(height);
                                    setBox_size(sizeTotal);
                                    if (sizeTotal <= 210 ){
                                        const result = await calculatedPrice(sizeTotal, weight, undefined, zipCode);
                                        setShipment_cost(parseFloat(result.data.price) + parseFloat(result.data.zipCode_fee));
                                    }
                                  }
                                }
                              }}
                            />
                        </div>
                        <div className="col-md-3 col-sm-8 px-md-1 mt-1">
                          <span>{lang.language === "th" ? "ความสูง (ซม.)" : "Height (cm)"}</span>
                          <input
                              className="form-control "
                              type="number"
                              name="height"
                              value={height}
                              onChange={async(e) =>{ 
                                setHeight(e.target.value)
                                if (e.target.value !== '' && length !== '' && width !== '') {
                                  var sizeTotal = 0;
                                  if((parseInt(e.target.value) + parseInt(length) + parseInt(width))>210 || (parseInt(e.target.value) + parseInt(length) + parseInt(width))<0.01){
                                    setErrorBox_size("is-invalid");
                                    sizeTotal = parseInt(e.target.value) + parseInt(length) + parseInt(width);
                                    setBox_size(sizeTotal);
                                  }
                                  else{
                                    setErrorBox_size("");
                                    sizeTotal = parseInt(e.target.value) + parseInt(length) + parseInt(width);
                                    setBox_size(sizeTotal);
                                    if (sizeTotal <= 210 ){
                                        const result = await calculatedPrice(sizeTotal, weight, undefined, zipCode);
                                        setShipment_cost(parseFloat(result.data.price) + parseFloat(result.data.zipCode_fee));
                                    }
                                  }
                                }
                              }}
                            />
                        </div>
                          <div className="col-md-3 col-sm-8 px-md-1 mt-1">
                              <span>{lang.language === "th" ? "อำเภอ" : "District"}</span>
                              <input
                                  className={"form-control "}
                                  name="zipCode"
                                  onChange={async (e) => {
                                      const zip = e.target.value;
                                      setZipCode(zip);
                                      const sizeTotal = parseInt(height) + parseInt(length) + parseInt(width);
                                      if (sizeTotal <= 210 ) {
                                          const result = await calculatedPrice(sizeTotal, weight, undefined, zip);
                                          setShipment_cost(parseFloat(result.data.price) + parseFloat(result.data.zipCode_fee));
                                      }
                                  }}
                                  // className={'form-control ' + (getIn(touched, `box_height`) ? (getIn(errors, `box_size`) ? 'is-invalid' : 'is-valid') : '')}
                                  value={zipCode}
                                   />
                          </div>
                          <div className="col-md-3 col-sm-8 px-md-1 mt-1">
                          <span>{lang.language === "th" ? "กว้าง + ยาว + สูง (สูงสุด 210ซม.)" : "Size (Max 210cm)"}</span>
                          <input 
                              className={"form-control "+errorBox_size}
                              name="box_size"
                              min = {0}
                              max = {210}
                              // className={'form-control ' + (getIn(touched, `box_height`) ? (getIn(errors, `box_size`) ? 'is-invalid' : 'is-valid') : '')}
                              value={box_size} 
                              readOnly />
                        </div>
                        <div className="col-md-6 col-sm-8 px-md-1 mt-1">
                          <span>{lang.language === "th" ? "ค่าขนส่ง (บาท)" : "Cost (baht)"}</span>
                          <input 
                          className={"form-control "+errorBox_size} 
                          value={shipment_cost}
                          readOnly />
                        </div>
                      </div>
                </div>
              {/* </div> */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  lang: state.LanguageAction,
});

export default connect(mapStateToProps)(PriceEstimation);