import React, {useEffect} from 'react'
import {useTranslation} from "react-i18next";
import useHubById from "../General/hooks/hubById";
import {Spinner} from "react-bootstrap";

export const HubPresentation = () => {
  const {t} = useTranslation();
  const {loading,data,errorMessage,fetch} = useHubById();
  useEffect(() => {
      fetch(localStorage.getItem('id'));
  }, []);
  return (
    <div className='w-100 d-flex flex-column p-3 align-items-center justify-content-center'>
        {loading && <div className="spinner-global-content">
            <Spinner variant="success" animation="border" />
        </div>}
        {data && <p>
            <span>{t('hub.presentation.manager')} : </span>
            <span>{data.username}</span>
            <h2 style={{color: 'var(--blue)'}}>{data.name}</h2>
        </p>}
      <hr/>
    </div>
  )
}
