import React, {useEffect, useState} from 'react';
import { Formik, Form, FieldArray, ErrorMessage, getIn } from 'formik';
import { nanoid } from 'nanoid';
import * as Icon from 'react-bootstrap-icons';
import InputAutocompleteV2 from '../../../Components/inputAutocompleteV2';
import { bank } from '../../../Data/bank.json';
import parcelSchema from './parcelValidate';
import FormReactSelect from '../../../Components/FormReactSelect';
import {calculatedPrice as calculatedPriceAPI} from "../../../Service/service.Parcels";
import {connect} from "react-redux";
import {correspondingSender} from "../../../Service/service.PorlorSender";
import Modal from 'react-bootstrap/Modal';
import {CustomerTypeService} from '../../../Service/service.Barcode';
import { Typeahead } from 'react-bootstrap-typeahead';
import DateTH from '../../../Helper/date-th';
import Pagination from '@material-ui/lab/Pagination';
import ReactExport from 'react-data-export';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Porlor({
  showModalPickUp,
  setShowModalPickUp,
  parcelsPickUpList,
  setParcelsPickUpList, 
  getParcelsPickUp,
  fetchDataExcel,
  SaveData2,
  printLebel,
  loading,
  handleShow,
  setDataArray,
  setStatusPrint,
  checkCitizen,
  checkReceiver,
  checkReceiverMultiple,
  checkSize,
  dataCheck,
  user,
  checkCitizenMultiple,
  setShowModalCitizen,
  showModalCitizen,
  indexCitizen,
  citizen,
  valuesReady,
  setValuesReady,
  citizenList,
  showModalReceiver,
  setShowModalReceiver,
  receiverList,
  setReceiverList,
  page,
  pageSizes,
  setPageSizes,
  currentPage,
  setcurrentPage,
  setDataExport,
  dataExport,
}) {
  console.log('re rendering = ');
  const [indexReceiver,setIndexReceiver] = useState(0);
  const isCreditCustomer = localStorage.getItem('superkey') === 'credit';
  const isShop = localStorage.getItem('superkey') === 'shop';
  const isPublicUser = localStorage.getItem('superkey')  === '1947';
  const hideCost =localStorage.getItem('show_shipment_cost')  === '0';
  const cust_code = localStorage.getItem("username");
  const [customers, setCustomers] = useState([]);

  const [ref_no,setRef_no]=useState("");
  const [ref_no2,setRef_no2]=useState("");
  const [invoice,setInvoice]=useState("");
  const [conote,setConote]=useState("");
  const [item_desc,setItem_desc]=useState("");
  const [item_sku,setItem_sku]=useState("");
  const [return_doc,setReturn_doc]=useState(0);

  const [num_return_doc,setNum_return_doc]=useState('0');
  const [citizenID,setCitizenID]=useState("");
  const [sender_name,setSender_name]=useState("");
  const [phone,setPhone]=useState("");
  const [search,setSearch]=useState("");
  const [custCode,setCustCode]=useState("");

  const dataExportWithIndex = dataExport.map((row, index) => ({
    ...row,
    index: index + 1, // Add 1 to start the index from 1 instead of 0
  }));
  
                              

  
  // let phone = "";
  // if(localStorage.getItem('superkey') === '1947'){
  //      phone = localStorage.getItem("username");
  // }
  // else if(localStorage.getItem('superkey') === 'credit'){
  //      phone = localStorage.getItem('phone');
  // }

  const initialValues = {
        sender: {
            collected_by: localStorage.getItem('username'),
            idPickUp:'',
            ref_no: ref_no,
            return_doc: return_doc,
            num_return_doc: num_return_doc,
            citizenID: citizenID,
            fullName: sender_name,
            phoneNumber:phone,
            district: '',
            amphoe: '',
            province: '',
            zipcode: '',
            typeSender: '1',
            customerGroup: '1',
            homeNumber: '',
            custCode: '',
            province_shop: localStorage.getItem('province_code') === 'กรุงเทพฯ' ? 'กรุงเทพมหานคร' : localStorage.getItem('province_code'),
            amphur_shop: localStorage.getItem('amphur_code'),
            userType: localStorage.getItem('superkey')  === 'credit' ? localStorage.getItem('superkey') :  localStorage.getItem('superkey')  === '1947'? "public" :"",
            materialAccountName: '',
            materialAccountNumber: '',
            bankName: '',
        },
        recipient: [
            {
                key: nanoid(),
                ref_no: ref_no,
                ref_no2: '',
                parcel_size: '',
                item_desc: '',
                item_sku: '',
                invoice: '',
                conote:'',
                return_doc: false,
                num_return_doc: '0',
                fullName: '',
                phoneNumber: '',
                homeNumber: '',
                deposit_type: '1',
                deposit_fullname: '',
                deposit_phone: '',
                district: '',
                amphoe: '',
                province: '',
                zipcode: '',
                materialSizeWide: '',
                materialSizeLong: '',
                materialSizeHigh: '',
                materialSize: '',
                materialWeight: '',
                materialCode: false,
                materialAccountName: '',
                materialAccountNumber: '',
                materialPriceCode: '0',
                bankName: '',
                total: 0,
                serviceCod: 0,
                totalNet: 0,
            },
        ],
    };

    async function CustomerType(data) {
      const result = await CustomerTypeService(data);
      if (data !== '') {
        setCustomers(result.data);
      }
    }
  const [customInitialValues,setCustomInitialValues] = useState(initialValues);
  const [initiallyHasBank, setInitiallyHasBank] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);

  const calculatedPrice = async (size, weight, cod, district) => {
    setPriceLoading(true);
    try {
      const result = await calculatedPriceAPI(size, weight, cod, district);
      return result;
    }
    catch (error) {
      throw error;
    }
    finally {
      setPriceLoading(false);
    }
  };
  const handleUpdateAllReceiversBanks = (bankData,recipients, setFieldValue) => {
      recipients.forEach((recipient, index) => {
            if(recipient.materialCode){
                if(bankData.hasOwnProperty('id')){
                    setFieldValue(`recipient[${index}].bankName`, bankData['id']);
                }
                if(bankData.hasOwnProperty('code')){
                    setFieldValue(`recipient[${index}].materialAccountNumber`, bankData['code']);
                }
                if(bankData.hasOwnProperty('name')){
                    setFieldValue(`recipient[${index}].materialAccountName`, bankData['name']);
                }
            }
        });
  };
    const refetchInitialData = () => {
        if(isPublicUser){
            correspondingSender(localStorage.getItem('username'),localStorage.getItem('citizen_id'))
                .then(result => {
                    if(result.data.data?.hasOwnProperty('bank_id')){
                        console.log('corresponding sender result = ',result.data.data);
                        setCustomInitialValues({
                            sender: {
                                ...initialValues.sender,
                                materialAccountName: result.data.data.bookbank_name,
                                materialAccountNumber: result.data.data.bookbank_code,
                                bankName: result.data.data.bank_id,
                            },
                            recipient: [
                                ...initialValues.recipient,
                            ]
                        });
                    }
                });
        }
        else if(isCreditCustomer){
            correspondingSender(localStorage.getItem('phone'),localStorage.getItem('citizen_id'))
                .then(result => {
                    if(result.data.data?.hasOwnProperty('bank_id') && result.data.data?.bank_id !== null && result.data.data?.bank_id !== ""){
                        setInitiallyHasBank(true);
                        setCustomInitialValues({
                            sender: {
                                ...initialValues.sender,
                                materialAccountName: result.data.data.bookbank_name ?? '',
                                materialAccountNumber: result.data.data.bookbank_code ?? '',
                                bankName: result.data.data.bank_id ?? '',
                            },
                            recipient: [
                                ...initialValues.recipient,
                            ]
                        });
                    }
                });
        }
    };
    useEffect(() => {
        refetchInitialData();
  }, []);

    return (
    <>
      <div>
        <ExcelFile
          element={
            <button type="button" style={{ display: 'none' }} id="downloadCredit">
              Download Data
            </button>
          }
          filename={'ปริ้นลาเบล'}
        >
          <ExcelSheet data={dataExportWithIndex} name={'Receiver Information'}>
            <ExcelColumn label="ลำดับที่" value={"index"} />
            <ExcelColumn label="Code/id" value="code" />
            <ExcelColumn label="รหัสลกค้า" value="cust_code" />
            <ExcelColumn label="วันเปิดบิล" value="created_date" />
            <ExcelColumn label="เลขลาเบล" value="awb_code" />
            <ExcelColumn label="หมายเลขinvoice" value="invoice" />
            <ExcelColumn label="ชื่อผู้รับ" value="receiver_name" />
            <ExcelColumn label="เบอร์โทรผู้รับ" value="receiver_phone" />
            <ExcelColumn label="ที่อยู่" value="receiver_address" />
            <ExcelColumn label="อำเภอ" value="receiver_amphur" />
            <ExcelColumn label="จังหวัด" value="receiver_province" /> 
            <ExcelColumn label="รหัสไปรษณีย์" value="receiver_zipcode" />
            <ExcelColumn label="COD (บาท)" value={(row) => parseInt(row.cod_cost)} />
            <ExcelColumn label="เลขสำหรับลูกค้า 1" value="ref_no2" />
            <ExcelColumn label="เลขสำหรับลูกค้า 2" value="item_desc" />
            <ExcelColumn label="เลขสำหรับลูกค้า 3" value="item_sku" />
            <ExcelColumn label="จำนวนเอกสารนำกลับ" value="num_return_doc" />
          </ExcelSheet>
        </ExcelFile>
      </div>
      <Formik
        validationSchema={parcelSchema}
        enableReinitialize={true}
        initialValues={customInitialValues}
        onSubmit={(value, { setFieldValue, resetForm }) => {
          SaveData2({
                      dataArray: value,
                      labelType: 2,
                    });
          value.recipient.forEach(row => row.ref_no = value.sender.ref_no);
          console.log(value);
          setDataArray(value);
          // handleShow();
          resetForm();
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className={`card-supplies mb-3 px-md-1 ${isCreditCustomer?'hidden-ct':''}`}>
              <div className="card-body">
                <b className="mb-5 ">ข้อมูลผู้ส่ง</b>
                <div className="form-row justify-content-md-left">
                    <div className="form-group col-sm-2 px-md-1 mt-1">
                      <label>ประเภทลูกค้า</label>
                      <select
                        value={values.customerType}
                        onChange={(e) => {
                          CustomerType({ customer_type: e.target.value });
                          setFieldValue('customerType', e.target.value);
                        }}
                        className="form-control dropdown-custom"
                      >
                        <option value={''}>-- กรุณาระบุประเภทลูกค้า --</option>
                        <option value={'4'}>ลูกค้าเครดิต</option>
                        {/* <option value={'1'}>หน้าร้าน</option>
                        <option value={'2'}>หน้าร้าน(ศูนย์กระจายสินค้า)</option>
                        <option value={'3'}>แฟรนไซส์</option>
                        <option value={'6'}>ลูกค้าทั่วไป</option> */}
                      </select>
                    </div>
                    <div className="form-group col-sm-3 px-md-1 mt-1">
                      <label>สาขา</label>
                      <Typeahead
                        defaultSelected={customers.filter((a) => a.cust_code === values.custCode)}
                        id="custCode"
                        name="custCode"
                        labelKey={(option) => `${option.cust_code} ${option.name}`}
                        onChange={async (e) => {
                          if (e.length > 0) {
                            let value = e[0].cust_code;
                            setCustCode(e[0].cust_code);
                            setFieldValue('custCode', value);
                            setFieldValue(`sender.custCode`, e[0].cust_code);
                            let parcelsPU= await getParcelsPickUp({
                              cust_code: e[0].cust_code,
                              search: '',
                              pageNo: 1,
                              perPage: pageSizes
                            });
                            let listExport= await getParcelsPickUp({ 
                              cust_code: custCode, 
                              pageNo: '',
                              perPage: '',
                              search: search 
                            });
                            setDataExport(listExport);
                            setParcelsPickUpList(parcelsPU);
                            setShowModalPickUp(true)
                            //getPickUpParcel
                          }
                        }}
                        onInputChange={(text, e) => {
                          setFieldValue('custCode', '');
                        }}
                        options={customers}
                        placeholder="-- กรุณาระบุสาขา --"
                        selected={values.custCode === '' ? [] : customers.filter((a) => a.cust_code === values.custCode)}
                        
                      />
                    </div>
                  </div>
                <div className="row mt-1">
                  <div className="col-md-2 col-12 px-md-1 hidden-ct ">
                    <label>กลุ่มลูกค้า</label>
                    <span className="important-ct">*</span>
                    <div className="form-check">
                      <input
                        // autocomplete="off"
                        className={'form-check-input  ' + (getIn(touched, `sender.customerGroup`) ? (getIn(errors, `sender.customerGroup`) ? 'is-invalid' : 'is-valid') : '')}
                        onChange={(e) => {
                          setFieldValue(`sender.customerGroup`, e.target.value);
                          values.recipient.map(async (value, index) => {
                            var result = await calculatedPrice(value.materialSize, value.materialWeight, 0, value.amphoe );
                            const totalPrice = result.data.price + result.data.zipCode_fee;
                            setFieldValue(`recipient[${index}].total`, totalPrice);
                            setFieldValue(`recipient[${index}].totalNet`, parseFloat(totalPrice)  + parseFloat(value.serviceCod) );
                          });
                        }}
                        type="radio"
                        name="type"
                        id="type1"
                        value="1"
                        checked={values.sender.customerGroup === '1' ? true : false}
                      />
                      <label className="form-check-label mr-5" htmlFor="type1">
                        ทั่วไป
                      </label>
                      <ErrorMessage component="div" name="sender.customerGroup" className="invalid-feedback" />
                    </div>
                  </div>
                        

                  <div className="col-md-4 col-12  px-md-1 ">
                  <Modal
                    show={showModalCitizen}
                    onHide={() => {
                      setShowModalCitizen(false);
                    }}
                    size="lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>โปรดเลือกรายละเอียด</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ fontSize: '15px' }}>
                      <div style={{ overflow: 'auto' }}>
                        <table className="table table-bordered">
                          <thead style={{ backgroundColor: '#3f75c1' }}>
                            <tr style={{ color: '#FFFFFF' }}>
                              <td >เลขประจำตัวประชาชน</td>
                              <td >ชื่อ-นามสกุล</td>
                              <td >เบอร์โทรศัพท์</td>
                            </tr>
                          </thead>
                          <tbody>
                            {citizenList.map((valueCitizen, indexCitizenModal) => (
                              <tr key={indexCitizenModal + 'Key' + valueCitizen.id}>
                                <td>{valueCitizen.citizenID}</td>
                                <td>{valueCitizen.sender_name}</td>
                                <td>{valueCitizen.sender_phone}</td>
                                <td>
                                    <button className='btn btn-primary'
                                        onClick={async ()=>{
                                          setFieldValue(`sender.fullName`, valueCitizen.sender_name);
                                          setFieldValue(`sender.citizenID`, valueCitizen.citizenID);
                                          setFieldValue(`sender.phoneNumber`, valueCitizen.sender_phone);
                                          setFieldValue(`sender.materialAccountName`, valueCitizen.bookbank_name);
                                          setFieldValue(`sender.materialAccountNumber`, valueCitizen.bookbank_code);
                                          setFieldValue(`sender.bankName`, valueCitizen.bank_id);
                                          handleUpdateAllReceiversBanks({
                                              name: valueCitizen.bookbank_name,
                                              id: valueCitizen.bank_id,
                                              code: valueCitizen.bookbank_code
                                          },values.recipient, setFieldValue);
                                          setShowModalCitizen(false);
                                        }}
                                    >
                                    เลือก

                                    </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {citizenList.length === 0 ? <div style={{ color: 'red', textAlign: 'center', padding: '50px' }}>empty</div> : ''}
                      </div>
                    </Modal.Body>
                  </Modal>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <FieldArray name="recipient">
                {({ push, remove }) =>
                  values.recipient.map((value, index) => (
                    <div className="col-12 col-md-12  mb-3" key={value.key}>
                      <div className="card-supplies">
                        <div className="card-body">
                          <div className="flex-space-between">
                            <b>
                              ข้อมูลผู้รับ
                              {values.recipient.length === 1 ? ' ' : ' ' + (index + 1)}
                            </b>
                            <div>
                              <div className=" text-right">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                  <button
                                    className="btn btn-success"
                                    type="button"
                                    onClick={() => {
                                      push({
                                        key: nanoid(),
                                        ref_no: '',
                                        ref_no2: '',
                                        parcel_size: '',
                                        item_desc: '',
                                        item_sku: '',
                                        invoice: '',
                                        conote:'',
                                        return_doc: false,
                                        num_return_doc: '',
                                        fullName: '',
                                        phoneNumber: '',
                                        homeNumber: '',
                                        deposit_type: '1',
                                        deposit_fullname: '',
                                        deposit_phone: '',
                                        district: '',
                                        amphoe: '',
                                        province: '',
                                        zipcode: '',
                                        materialSizeWide: '',
                                        materialSizeLong: '',
                                        materialSizeHigh: '',
                                        materialSize: '',
                                        materialWeight: '',
                                        materialCode: false,
                                        materialAccountName: '',
                                        materialAccountNumber: '',
                                        materialPriceCode: '0',
                                        bankName: '',
                                        total: 0,
                                        serviceCod: 0,
                                        totalNet: 0,
                                      });
                                    }}
                                  >
                                    <Icon.PlusCircleFill /> สร้างพัสดุฯใหม่
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                      push({
                                        key: nanoid(), 
                                        ref_no: '',
                                        ref_no2: values.recipient[index].ref_no2,
                                        parcel_size: '',
                                        item_desc: values.recipient[index].item_desc,
                                        item_sku: values.recipient[index].item_sku,
                                        invoice: values.recipient[index].invoice,
                                        conote: values.recipient[index].conote,
                                        return_doc: false,
                                        num_return_doc: '0',
                                        fullName: values.recipient[index].fullName,
                                        phoneNumber: values.recipient[index].phoneNumber,
                                        homeNumber: values.recipient[index].homeNumber,
                                        deposit_type: values.recipient[index].deposit_type,
                                        deposit_fullname: values.recipient[index].deposit_fullname,
                                        deposit_phone: values.recipient[index].deposit_phone,
                                        district: values.recipient[index].district,
                                        amphoe: values.recipient[index].amphoe,
                                        province: values.recipient[index].province,
                                        zipcode: values.recipient[index].zipcode,
                                        materialSizeWide: '',
                                        materialSizeLong: '',
                                        materialSizeHigh: '',
                                        materialSize: '',
                                        materialWeight: '',
                                        materialCode: values.recipient[index].materialCode,
                                        materialAccountName: values.recipient[index].materialAccountName,
                                        materialAccountNumber: values.recipient[index].materialAccountNumber,
                                        materialPriceCode: '0',
                                        bankName: values.recipient[index].bankName,
                                        total: 0,
                                        serviceCod: 0,
                                        totalNet: 0,
                                      });
                                    }}
                                  >
                                    <Icon.FilePlusFill /> คัดลอกข้อมูลพัสดุฯ
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                    disabled={values.recipient.length === 1}
                                  >
                                    <Icon.TrashFill />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 col-12 px-md-1 ">
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].fullName`}>ชื่อ -นามสกุล</label>
                                <span className="important-ct">*</span>

                                <input
                                  // autocomplete="off"
                                  value={value.fullName}
                                  type="text"
                                  className={'form-control  ' +  (getIn(touched, `recipient[${index}].fullName`) ? (getIn(errors, `recipient[${index}].fullName`) ? 'is-invalid' : 'is-valid') : '')}
                                  aria-describedby={`recipient[${index}].fullName`}
                                  name={`recipient[${index}].fullName`}
                                  defaultValue={value.fullName}
                                  onChange={ async (e) => {
                                    const {value: fullname} = e.target;
                                    setFieldValue(`recipient[${index}].fullName`, e.target.value);
                                    if(fullname !== '' && fullname.match(/^[ก-๏a-zA-Z0-9./()_-\s]+$/)){
                                        setDisableSubmit(true);
                                        const result = await checkReceiverMultiple(fullname);
                                        if(Object.keys(result).length === 1){
                                            setFieldValue(`recipient[${index}].homeNumber`, result[0].receiver_address);
                                            setFieldValue(`recipient[${index}].receiver_address`, result[0].recipient_name);
                                            setFieldValue(`recipient[${index}].district`, result[0].receiver_district);
                                            setFieldValue(`recipient[${index}].province`, result[0].receiver_province);
                                            setFieldValue(`recipient[${index}].zipcode`, result[0].receiver_zipCode);
                                            setFieldValue(`recipient[${index}].amphoe`, result[0].receiver_amphur);
                                            setFieldValue(`recipient[${index}].phoneNumber`,result[0].recipient_phone);
                                            try{
                                                var totalResult = await calculatedPrice(value.materialSize, value.materialWeight, 0, value.amphoe);
                                                const total = totalResult.data.price + totalResult.data.zipCode_fee;
                                                setFieldValue(`recipient[${index}].total`, total);
                                                setFieldValue(`recipient[${index}].totalNet`, parseFloat(total)  + parseFloat(value.serviceCod));
                                            }
                                            catch (e) {
                                              console.log(e);
                                            }
                                        }
                                        if(Object.keys(result).length > 1){
                                          setReceiverList(result);
                                          await setShowModalReceiver(true);
                                          
                                        }
                                        setDisableSubmit(false);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].fullName`} className="invalid-feedback" />
                              </div>
                            </div>

                            <div className="col-md-2 col-12 px-md-1 ">
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].phoneNumber`}>เบอร์โทรศัพท์</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.phoneNumber}
                                  type="text"
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].phoneNumber`) ? 
                                  (getIn(errors, `recipient[${index}].phoneNumber`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  aria-describedby={`recipient[${index}].phoneNumber`}
                                  name={`recipient[${index}].phoneNumber`}
                                  defaultValue={value.phoneNumber}
                                  onChange={async (e) => {
                                      const phoneNumber = e.target.value;
                                      setFieldValue(`recipient[${index}].phoneNumber`, phoneNumber);
                                      if(phoneNumber.match(/^(?=[0-9]*$)(?:.{9,10}|.{30})$/)){
                                          setDisableSubmit(true);
                                          console.log('in condition');
                                          try{
                                              console.log('before checkReceiver');
                                              const result = await checkReceiverMultiple(phoneNumber);
                                              console.log('after checkReceiver');
                                              if(Object.keys(result).length === 1){
                                                console.log('another condition');
                                                setFieldValue(`recipient[${index}].fullName`, result[0].recipient_name);
                                                setFieldValue(`recipient[${index}].homeNumber`, result[0].receiver_address);
                                                setFieldValue(`recipient[${index}].receiver_address`, result[0].recipient_name);
                                                setFieldValue(`recipient[${index}].district`, result[0].receiver_district);
                                                setFieldValue(`recipient[${index}].province`, result[0].receiver_province);
                                                setFieldValue(`recipient[${index}].zipcode`, result[0].receiver_zipCode);
                                                setFieldValue(`recipient[${index}].amphoe`, result[0].receiver_amphur);
                                                try{
                                                    var totalResult = await calculatedPrice(value.materialSize, value.materialWeight, 0, value.amphoe);
                                                    const total = totalResult.data.price + totalResult.data.zipCode_fee;
                                                    setFieldValue(`recipient[${index}].total`, total);
                                                    setFieldValue(`recipient[${index}].totalNet`, parseFloat(total)  + parseFloat(value.serviceCod));
                                                }
                                                catch(e){
                                                    console.log('error 1', e);
                                                }
                                                finally{
                                                  setDisableSubmit(false);
                                                }
                                              }
                                              if(Object.keys(result).length > 1){
                                                setReceiverList(result);
                                                await setShowModalReceiver(true);
                                                
                                              }

                                              }
                                              catch (e) {
                                                  console.log('error 2', e);
                                              }finally{
                                                setDisableSubmit(false);
                                              }

                                      }
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].phoneNumber`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-6 col-12 px-md-3 ">
                              <div className="form-group checkbox-control mt-4" style={getIn(touched, `recipient[${index}].deposit_type`) ? (getIn(errors, `recipient[${index}].deposit_type`) ? { color: '#ee0808' } : { color: '#28a745' }) : { color: '#1a1818' }}>
                                <input
                                  type="checkbox"
                                  name={`recipient[${index}].deposit_type1`}
                                  id={`recipient[${index}].deposit_type1`}
                                  className={'checkbox-custom '}
                                  checked={value.deposit_type === '1' ? true : false}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].deposit_type`, '1');
                                    setFieldValue(`recipient[${index}].deposit_fullname`, '');
                                    setFieldValue(`recipient[${index}].deposit_phone`, '');
                                  }}
                                  onBlur={handleBlur}
                                />
                                <label htmlFor={`recipient[${index}].deposit_type1`} className="mr-5">
                                  ส่งตรงตัว
                                </label>
                                <input
                                  type="checkbox"
                                  name={`recipient[${index}].deposit_type2`}
                                  id={`recipient[${index}].deposit_type2`}
                                  // className="checkbox-custom"
                                  className={'checkbox-custom '}
                                  checked={value.deposit_type === '2' ? true : false}
                                  onChange={(e) => {
                                    // setFieldValue(`recipient[${index}].deposit_type`, '2');
                                  }}
                                  onBlur={handleBlur}
                                />
                                <label htmlFor={`recipient[${index}].deposit_type2`} className="mr-5">
                                  ฝาก
                                </label>
                                <input
                                  type="checkbox"
                                  name={`recipient[${index}].deposit_type3`}
                                  id={`recipient[${index}].deposit_type3`}
                                  // className="checkbox-custom"
                                  className={'checkbox-custom '}
                                  checked={value.deposit_type === '3' ? true : false}
                                  onChange={(e) => {
                                    // setFieldValue(`recipient[${index}].deposit_type`, '3');
                                  }}
                                  onBlur={handleBlur}
                                />
                                <label htmlFor={`recipient[${index}].deposit_type3`} className="mr-3">
                                  ส่งตรงตัว/ฝาก
                                </label>
                                <ErrorMessage component="div" name={`recipient[${index}].deposit_type`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-4 col-12 px-md-1 ">
                              <Modal
                                show={showModalReceiver}
                                onHide={() => {
                                  setShowModalReceiver(false);
                                }}
                                size="lg"
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>โปรดเลือกรายละเอียด</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{ fontSize: '15px' }}>
                                  <div style={{ overflow: 'auto' }}>
                                    <table className="table table-bordered">
                                      <thead style={{ backgroundColor: '#3f75c1' }}>
                                        <tr style={{ color: '#FFFFFF' }}>
                                          <td >ชื่อ-นามสกุล</td>
                                          <td >เบอร์โทรศัพท์</td>
                                          <td >ที่อยู่</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {receiverList?.map((valueReceiver, indexReceiverModal) => (
                                          <tr key={indexReceiverModal + 'Key' + valueReceiver.id}>
                                            <td>{valueReceiver.recipient_name}</td>
                                            <td>{valueReceiver.recipient_phone}</td>
                                            <td>
                                              <p>{valueReceiver.receiver_address}</p>
                                              <p>{valueReceiver.receiver_district}</p>
                                              <p>{valueReceiver.receiver_amphur}</p>  
                                              <p>{valueReceiver.receiver_province}</p>  
                                              <p>{valueReceiver.receiver_zipCode}</p>  
                                            </td>
                                            <td>
                                                <button className='btn btn-primary'
                                                    onClick={async ()=>{
                                                      setFieldValue(`recipient[${index}].homeNumber`, valueReceiver.receiver_address);
                                                        setFieldValue(`recipient[${index}].receiver_address`, valueReceiver.recipient_name);
                                                        setFieldValue(`recipient[${index}].district`, valueReceiver.receiver_district);
                                                        setFieldValue(`recipient[${index}].province`, valueReceiver.receiver_province);
                                                        setFieldValue(`recipient[${index}].zipcode`, valueReceiver.receiver_zipCode);
                                                        setFieldValue(`recipient[${index}].amphoe`, valueReceiver.receiver_amphur);
                                                        setFieldValue(`recipient[${index}].phoneNumber`,valueReceiver.recipient_phone);
                                                        try{
                                                            var totalResult = await calculatedPrice(value.materialSize, value.materialWeight, 0, value.amphoe);
                                                            const total = totalResult.data.price + totalResult.data.zipCode_fee;
                                                            setFieldValue(`recipient[${index}].total`, total);
                                                            setFieldValue(`recipient[${index}].totalNet`, parseFloat(total)  + parseFloat(value.serviceCod));
                                                        }
                                                        catch (e) {
                                                          console.log(e);
                                                        }
                                                        finally{
                                                          setShowModalReceiver(false);
                                                        }
                                                    }}
                                                >
                                                เลือก

                                                </button>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                    {receiverList?.length === 0 ? <div style={{ color: 'red', textAlign: 'center', padding: '50px' }}>empty</div> : ''}
                                  </div>
                                </Modal.Body>
                              </Modal>   
                            </div>
                            <Modal
                            style={{ overflow: 'scroll'}}
                            id="modalPickUp"
                              show={showModalPickUp}
                              onHide={() => {
                                setShowModalPickUp(false);
                              }}
                              size="lg"
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>โปรดเลือกรายละเอียด</Modal.Title>
                              </Modal.Header>
                              <Modal.Body style={{ fontSize: '15px' }}>
                              <div className="container-fluid mt-2 mb-2">
                                <div className='row'>
                                  <div className='col-md-8 col-12 px-md-1 '>
                                    <div className="form-group mt-2">
                                    {/* <label>ค้นหา ลาเบล,สำหรับลูกค้า,หมายเลขinvoice,เบอร์โทร</label> */}
                                      <input 
                                        value={search} 
                                        className={'form-control'} 
                                        onChange={(e) => { 
                                          setSearch(e.target.value) 
                                        }} 
                                        onBlur={handleBlur}
                                        placeholder={'ค้นหา ลาเบล,สำหรับลูกค้า,หมายเลขinvoice,เบอร์โทร'}
                                          
                                        /> 
                                    </div>
                                  </div>
                                  <div className='col-md-4 col-12 px-md-1'>
                                    <div className={"form-group text-center mt-2"}>
                                      <button className="btn btn-primary" 
                                      type="submit" onClick={async () => {
                                        let parcelsPU= await getParcelsPickUp({ 
                                          cust_code: custCode, 
                                          search: search,
                                          pageNo: 1,
                                          perPage: pageSizes
                                        });
                                        setParcelsPickUpList(parcelsPU);
                                        let listExport= await getParcelsPickUp({ 
                                            cust_code: custCode, 
                                            pageNo: '',
                                            perPage: '',
                                            search: search 
                                          });
                                          setDataExport(listExport);
                                      }}>
                                        <Icon.Search />  ค้นหา
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button
                                  type="button"
                                  className="btn btn-info"
                                  onClick={() => {
                                    fetchDataExcel({
                                      search: search,
                                      printStatus: values.printStatus,
                                      printType: values.printType,
                                      pageNo: '',
                                      perPage: '',
                                      cust_code: custCode,
                                    }).then(
                                      (value)=>{
                                        document.getElementById('downloadCredit').click();
                                      }
                                    );
                                  }}
                                >
                                  <Icon.FileEarmarkArrowUp /> export
                              </button>                                
                                <div style={{ overflow: 'auto' }}>
                                  <table className="table table-bordered">
                                    <thead style={{ backgroundColor: '#3f75c1' }}>
                                      <tr style={{ color: '#FFFFFF' }}>
                                        <td>วันเปิดบิล</td>
                                        <td >ชื่อ-นามสกุล / เลขสำหรับลูกค้า</td>
                                        <td >เบอร์โทรศัพท์</td>
                                        <td >ที่อยู่</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {parcelsPickUpList.map((valuePickUp, indexPickUp) => (
                                        <tr key={indexPickUp + 'Key' + valuePickUp.id}>
                                          <td><DateTH date={valuePickUp.created_date.substring(0, 10)} /></td>
                                          <td>{valuePickUp.receiver_name}
                                          <p>{valuePickUp.conote}</p>
                                          <p>{valuePickUp.invoice}</p>
                                          <p>{valuePickUp.item_sku}</p>
                                          <p>{valuePickUp.item_desc}</p>
                                          <p>{valuePickUp.ref_no2}</p>
                                          </td>
                                            <td>{valuePickUp.receiver_phone}</td>
                                            <td>
                                              <p>{valuePickUp.receiver_address}</p>
                                              <p>{valuePickUp.receiver_district}</p>
                                              <p>{valuePickUp.receiver_amphur}</p>  
                                              <p>{valuePickUp.receiver_province}</p>  
                                              <p>{valuePickUp.receiver_zipCode}</p>  
                                            </td>
                                          <td>
                                              <button className='btn btn-primary'
                                                  onClick={async ()=>{
                                                    //modal parcelsPickUp action
                                                    setFieldValue(`sender.idPickUp`, valuePickUp.code)
                                                    // setFieldValue(`sender.ref_no`, valuePickUp.ref_no2)
                                                    setFieldValue(`sender.fullName`, valuePickUp.sender_name);
                                                    setFieldValue(`sender.citizenID`, valuePickUp.citizenID);
                                                    setFieldValue(`sender.phoneNumber`, valuePickUp.sender_phone);
                                                    setFieldValue(`sender.materialAccountName`, valuePickUp.bookbank_name);
                                                    setFieldValue(`sender.materialAccountNumber`, valuePickUp.bookbank_code);
                                                    setFieldValue(`sender.bankName`, valuePickUp.bank_id);
                                                    setFieldValue(`sender.return_doc`, valuePickUp.return_doc=='1'? true:false);
                                                    setFieldValue(`sender.num_return_doc`, valuePickUp.num_return_doc);
                                                      
                                                    
                                                    // for(let i=0;i<=index;i++){
                                                      setFieldValue(`recipient[${index}].invoice`, valuePickUp.invoice);
                                                      setFieldValue(`recipient[${index}].conote`, valuePickUp.conote);
                                                      setFieldValue(`recipient[${index}].ref_no2`, valuePickUp.ref_no2);
                                                      setFieldValue(`recipient[${index}].item_sku`, valuePickUp.item_sku);
                                                      setFieldValue(`recipient[${index}].item_desc`, valuePickUp.item_desc);
                                                      setFieldValue(`recipient[${index}].fullName`, valuePickUp.receiver_name);
                                                      
                                                      setFieldValue(`recipient[${index}].homeNumber`, valuePickUp.receiver_address);
                                                      setFieldValue(`recipient[${index}].receiver_address`, valuePickUp.receiver_address);
                                                      setFieldValue(`recipient[${index}].district`, valuePickUp.receiver_district);
                                                      setFieldValue(`recipient[${index}].province`, valuePickUp.receiver_province);
                                                      setFieldValue(`recipient[${index}].zipcode`, valuePickUp.receiver_zipcode);
                                                      setFieldValue(`recipient[${index}].amphoe`, valuePickUp.receiver_amphur);
                                                      setFieldValue(`recipient[${index}].phoneNumber`,valuePickUp.receiver_phone);
                                                      
                                                      setFieldValue(`recipient[${index}].materialCode`, parseFloat(valuePickUp.cod_cost)>0?true:false);
                                                      setFieldValue(`recipient[${index}].materialPriceCode`, parseFloat(valuePickUp.cod_cost));
                                                      setFieldValue(`recipient[${index}].serviceCod`, valuePickUp.service_cod);

                                                      setFieldValue(`recipient[${index}].materialAccountName`, valuePickUp.bookbank_name);
                                                      setFieldValue(`recipient[${index}].materialAccountNumber`, valuePickUp.bookbank_code);
                                                      setFieldValue(`recipient[${index}].bankName`, valuePickUp.bank_id);
                                                      

                                                    // }
                                                    
                                                    handleUpdateAllReceiversBanks({
                                                        name: valuePickUp.bookbank_name,
                                                        id: valuePickUp.bank_id,
                                                        code: valuePickUp.bookbank_code
                                                    },values.recipient, setFieldValue);
                                                    setShowModalPickUp(false);
                                                  }}
                                              >
                                              เลือก

                                              </button>
                                          </td>
                                        </tr>
                                      ))}
                                      <Pagination
                                        count={parseInt(page.last_page)}
                                        page={currentPage}
                                        onChange={async (e, newPage) => {
                                          let list= await getParcelsPickUp({ 
                                            cust_code: custCode, 
                                            pageNo: newPage,
                                            perPage: pageSizes,
                                            search: search 
                                          });
                                          let listExport= await getParcelsPickUp({ 
                                            cust_code: custCode, 
                                            pageNo: '',
                                            perPage: '',
                                            search: search 
                                          });
                                          setDataExport(listExport);
                                          setParcelsPickUpList(list);
                                          setcurrentPage(newPage);
                                          // fetchData({
                                          //   search: values.search,
                                          //   printStatus: values.printStatus,
                                          //   printType: values.printType,
                                          //   pageNo: newPage,
                                          //   perPage: pageSizes,
                                          //   cust_code: localStorage.getItem('username'),
                                          // });
                                        }}
                                        color="primary"
                                        size="small"
                                        defaultPage={6}
                                        siblingCount={1}
                                      />
                                    </tbody>
                                  </table>
                                  {citizenList.length === 0 ? <div style={{ color: 'red', textAlign: 'center', padding: '50px' }}>empty</div> : ''}
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                          <div className="row">
                            <div className={value.deposit_type === '2' || value.deposit_type === '3' ? 'col-md-4 px-md-1' : ' hidden-ct'}>
                              <div className="form-group">
                                <label>ชื่อผู้รับฝาก</label>
                                <span className="important-ct">*</span>
                                <input
                                  value={value.deposit_fullname}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].deposit_fullname`) ? (getIn(errors, `recipient[${index}].deposit_fullname`) ? 'is-invalid' : 'is-valid') : '')}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].deposit_fullname`, e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].deposit_fullname`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={value.deposit_type === '2' || value.deposit_type === '3' ? 'col-md-2 px-md-1' : ' hidden-ct'}>
                              <div className="form-group">
                                <label>เบอร์โทรศัพท์ผู้รับฝาก</label>
                                <input
                                  value={value.deposit_phone}
                                  className={'form-control  '}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].deposit_phone`, e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].deposit_phone`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={value.deposit_type !== '2' && value.deposit_type !== '3' ? 'col-md-12 px-md-1 ' : 'col-md-6 px-md-1 '}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].district`}>ค้นหาที่อยู่ (ตำบล อำเภอ จังหวัด รหัสไปรณีย์)</label>
                                <InputAutocompleteV2
                                  id={`recipient[${index}].district ${1}`}
                                  retrunValue={async (v) => {
                                    setFieldValue(`recipient[${index}].district`, v.subdistrictName);
                                    setFieldValue(`recipient[${index}].amphoe`, v.districtName);
                                    setFieldValue(`recipient[${index}].province`, v.provinceName);
                                    setFieldValue(`recipient[${index}].zipcode`, v.postCode);
                                    setFieldValue(`recipient[${index}].materialWeight`, '0');
                                    setFieldValue(`recipient[${index}].materialSize`, '0');
                                      setDisableSubmit(true);
                                      try{
                                          var result = await calculatedPrice(value.materialSize, value.materialWeight, 0, v.districtName);
                                          const total = result.data.price + result.data.zipCode_fee;
                                          setFieldValue(`recipient[${index}].total`, total);
                                          setFieldValue(`recipient[${index}].totalNet`, parseFloat( total )  + parseFloat(value.serviceCod));
                                      }
                                      catch(e){
                                        console.log(e);
                                      }
                                      setDisableSubmit(false);
                                  }}
                                  retrunValueOnBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                  className={'form-control'}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row"></div>
                          <div className="row" style={{ justifyContent: 'left' }}>
                            <div className="col-md-4 col-12 px-md-1 ">
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].homeNumber`}>เลขที่อยู่/ซอย/หมู่บ้าน</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.homeNumber}
                                  // disabled = "true"
                                  type="text"
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].homeNumber`) ? (getIn(errors, `recipient[${index}].homeNumber`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  aria-describedby="homeNumber"
                                  name={`recipient[${index}].homeNumber`}
                                  defaultValue={value.homeNumber}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].homeNumber`, e.target.value);
                                  }}
                                />
                                <p style={{ color: 'red'}}>กรุณากรอกให้ครบถ้วนเพื่อความสะดวกในการจัดส่ง</p>
                                <ErrorMessage component="div" name={`recipient[${index}].homeNumber`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2 px-md-1 ">
                              <div className="form-group">
                                <label>ตำบล</label>
                                <span className="important-ct">*</span>
                                <input value={value.district}
                                  // disabled = "true"
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].district`) ? (getIn(errors, `recipient[${index}].district`) ? 'is-invalid' : 'is-valid') : '')} onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].district`, e.target.value);
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].district`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2 px-md-1 ">
                              <div className="form-group">
                                <label>อำเภอ</label>
                                <span className="important-ct">*</span>
                                <input 
                                value={value.amphoe} 
                                // readOnly
                                className={'form-control  ' + (getIn(touched, `recipient[${index}].amphoe`) ? (getIn(errors, `recipient[${index}].amphoe`) ? 'is-invalid' : 'is-valid') : '')} 
                                onBlur={handleBlur} 
                                onChange={(e) => {
                                    setFieldValue(`recipient[${index}].amphoe`, e.target.value);
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].amphoe`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2 px-md-1 ">
                              <div className="form-group">
                                <label>จังหวัด</label>
                                <span className="important-ct">*</span>
                                <input 
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].province`) ? (getIn(errors, `recipient[${index}].province`) ? 'is-invalid' : 'is-valid') : '')} 
                                  onBlur={handleBlur} 
                                  value={value.province}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].province`, e.target.value);
                                  }} 
                                // readOnly
                                 />
                                <ErrorMessage component="div" name={`recipient[${index}].province`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2 px-md-1 ">
                              <div className="form-group">
                                <label>รหัสไปรณีย์</label>
                                <span className="important-ct">*</span>
                                <input 
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].zipcode`) ? (getIn(errors, `recipient[${index}].zipcode`) ? 'is-invalid' : 'is-valid') : '')} 
                                  onBlur={handleBlur} 
                                  value={value.zipcode}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].zipcode`, e.target.value);
                                  }}
                                //  readOnly
                                  />
                                <ErrorMessage component="div" name={`recipient[${index}].zipcode`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2 px-md-1 "></div>
                          </div>
                            <div className="row" style={{ justifyContent: 'left' }}>
                            <div className={`col-md-2 px-md-1 ${isShop ? 'hidden-ct' : ''}` }>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialWeight`}>น้ำหนัก (กก.)</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialWeight}
                                  type="number"
                                  name={`recipient[${index}].materialWeight`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialWeight`) ? (getIn(errors, `recipient[${index}].materialWeight`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialWeight`, e.target.value);
                                    if (e.target.value > 0) {
                                        setDisableSubmit(true);
                                        try{
                                            var result = await calculatedPrice(value.materialSize, e.target.value, 0, value.amphoe);
                                            const total = result.data.price + result.data.zipCode_fee;
                                            setFieldValue(`recipient[${index}].total`, total);
                                            setFieldValue(`recipient[${index}].totalNet`, parseFloat( total )  + parseFloat(value.serviceCod));                                        }
                                        catch (e) {
                                          console.log(e);
                                        }
                                        setDisableSubmit(false);
                                    }
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialWeight`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={`col-md-2 px-md-1 ${isShop ? 'hidden-ct' : ''}` }>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialSizeWide`}>ความกว้าง (ซม.)</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialSizeWide}
                                  type="number"
                                  name={`recipient[${index}].materialSizeWide`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialSizeWide`) ? (getIn(errors, `recipient[${index}].materialSizeWide`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialSizeWide`, e.target.value);
                                    if (e.target.value !== '' && value.materialSizeLong !== '' && value.materialSizeHigh !== '') {
                                      var sizeTotal = 0;
                                      sizeTotal = parseFloat(e.target.value) + parseFloat(value.materialSizeLong) + parseFloat(value.materialSizeHigh);
                                      setFieldValue(`recipient[${index}].materialSize`, sizeTotal);
                                        setDisableSubmit(true);
                                        try{
                                            var result = await calculatedPrice(sizeTotal, value.materialWeight, 0, value.amphoe);
                                            const total = result.data.price + result.data.zipCode_fee;
                                            setFieldValue(`recipient[${index}].total`, total);
                                            setFieldValue(`recipient[${index}].totalNet`, parseFloat( total )  + parseFloat(value.serviceCod));
                                        }
                                        catch(e){
                                          console.log(e);
                                        }
                                      setDisableSubmit(false);
                                    }
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialSizeWide`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={`col-md-2 px-md-1 ${isShop ? 'hidden-ct' : ''}`}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialSizeLong`}>ความยาว (ซม.)</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialSizeLong}
                                  type="number"
                                  name={`recipient[${index}].materialSizeLong`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialSizeLong`) ? (getIn(errors, `recipient[${index}].materialSizeLong`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialSizeLong`, e.target.value);
                                    if (e.target.value !== '' && value.materialSizeWide !== '' && value.materialSizeHigh !== '') {
                                        setDisableSubmit(true);
                                      var sizeTotal = 0;
                                      sizeTotal = parseFloat(e.target.value) + parseFloat(value.materialSizeWide) + parseFloat(value.materialSizeHigh);
                                      setFieldValue(`recipient[${index}].materialSize`, sizeTotal);
                                      try{
                                          var result = await calculatedPrice(sizeTotal, value.materialWeight, 0, value.amphoe);
                                          const total = result.data.price + result.data.zipCode_fee;
                                          setFieldValue(`recipient[${index}].total`, total);
                                          setFieldValue(`recipient[${index}].totalNet`, parseFloat( total )  + parseFloat(value.serviceCod));
                                      }
                                      catch(e){
                                        console.log(e);
                                      }
                                      setDisableSubmit(false);
                                    }
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialSizeLong`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={`col-md-2 px-md-1 ${isShop ? 'hidden-ct' : ''}`}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialSizeHigh`}>ความสูง (ซม.)</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialSizeHigh}
                                  type="number"
                                  name={`recipient[${index}].materialSizeHigh`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialSizeHigh`) ? (getIn(errors, `recipient[${index}].materialSizeHigh`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialSizeHigh`, e.target.value);
                                    if (e.target.value !== '' && value.materialSizeWide !== '' && value.materialSizeLong !== '') {
                                        setDisableSubmit(true);
                                      var sizeTotal = 0;
                                      sizeTotal = parseFloat(e.target.value) + parseFloat(value.materialSizeWide) + parseFloat(value.materialSizeLong);
                                      setFieldValue(`recipient[${index}].materialSize`, sizeTotal);
                                      try{
                                          var result = await calculatedPrice(sizeTotal, value.materialWeight, 0, value.amphoe);
                                          const total = result.data.price + result.data.zipCode_fee;
                                          setFieldValue(`recipient[${index}].total`, total);
                                          setFieldValue(`recipient[${index}].totalNet`, parseFloat( total )  + parseFloat(value.serviceCod));                                      }
                                      catch (e) {
                                        console.log(e);
                                      }
                                      setDisableSubmit(false);
                                    }
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialSizeHigh`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={`col-md-2 px-md-1 ${isShop ? 'hidden-ct' : ''}`}>
                            <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialSize`}>กว้าง + ยาว + สูง (ซม.)</label>
                                <input value={value.materialSize.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                       readOnly={true}
                                       type="text"
                                       name={`recipient[${index}].materialSize`}
                                       className={'form-control  ' + (getIn(touched, `recipient[${index}].materialSize`) ? (getIn(errors, `recipient[${index}].materialSize`) ? 'is-invalid' : 'is-valid') : '')}/>
                                <ErrorMessage component="div" name={`recipient[${index}].materialSize`} className="invalid-feedback" />
                              </div>
                            </div>
                            
                            <div className={`col-md-2 hidden-ct`}>
                              <div className="form-group checkbox-control mt-4">
                                <input
                                  // autocomplete="off"
                                  type="checkbox"
                                  name={`recipient[${index}].materialCode`}
                                  id={`recipient[${index}].materialCode`}
                                  className={`checkbox-custom ${isShop ? 'hidden-ct' : ''}`}
                                  checked={value.materialCode === true ? true : false}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialCode`, e.target.checked);
                                    setFieldValue(`recipient[${index}].bankName`, values.sender.bankName);
                                    setFieldValue(`recipient[${index}].materialAccountName`, values.sender.materialAccountName);
                                    setFieldValue(`recipient[${index}].materialAccountNumber`, values.sender.materialAccountNumber);
                                    setFieldValue(`recipient[${index}].materialPriceCode`, '0');
                                    setFieldValue(`recipient[${index}].serviceCod`, 0);
                                    setDisableSubmit(true);
                                    try{
                                        var result = await calculatedPrice(value.materialSize, value.materialWeight, 0, value.amphoe);
                                        const total = result.data.price + result.data.zipCode_fee;
                                        setFieldValue(`recipient[${index}].total`, total);
                                        setFieldValue(`recipient[${index}].totalNet`, parseFloat( total )  + 0);
                                    }
                                    catch (e) {
                                      console.log(e);
                                    }
                                      setDisableSubmit(false);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <label htmlFor="cod">COD (เก็บเงินปลายทาง)</label>
                              </div>
                            </div>
                            </div>
                          
                          <div className="row" style={{ justifyContent: 'left' }}>
                              <div className={` col-md-2 col-12 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                  {!initiallyHasBank &&
                                      <>
                                        <label htmlFor={`recipient[${index}].bankName`}>ธนาคาร</label>
                                        <span className="important-ct">*</span>
                                        <FormReactSelect
                                          touched={touched}
                                          errors={errors}
                                          data={bank}
                                          values={value.bankName}
                                          onChange={(id) => {
                                            setFieldValue('sender.bankName',id);
                                            handleUpdateAllReceiversBanks({
                                                id,
                                            },values.recipient,setFieldValue);
                                          }}
                                          name={`recipient[${index}].bankName`}
                                          labelKey="bankName"
                                          keyValue="bank_id"
                                          errorsMess="กรุณาเลือกธนาคาร"
                                          placeholder="-- กรุณาเลือกธนาคาร --"
                                        />
                                      </>
                                  }
                                  {initiallyHasBank && value.bankName && (
                                      <>
                                          <label htmlFor={`recipient[${index}].bankName`}>ธนาคาร</label>
                                          <input className="form-control" value={bank.find(row => row.bank_id === value.bankName).bankName} disabled={true} />
                                      </>
                                  )}
                              </div>
                           </div>
                           <div className={` col-md-2 col-12 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor="materialAccountName">ชื่อบัญชี</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialAccountName}
                                  type="text"
                                  disabled={initiallyHasBank}
                                  name={`recipient[${index}].materialAccountName`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialAccountName`) ? (getIn(errors, `recipient[${index}].materialAccountName`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(`sender.materialAccountName`, e.target.value);
                                    handleUpdateAllReceiversBanks({
                                        name: e.target.value,
                                    },values.recipient,setFieldValue);
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialAccountName`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={` col-md-2 col-12 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor="materialAccountNumber">เลขที่บัญชี</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  disabled={initiallyHasBank}
                                  value={value.materialAccountNumber}
                                  type="text"
                                  name={`recipient[${index}].materialAccountNumber`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialAccountNumber`) ? (getIn(errors, `recipient[${index}].materialAccountNumber`) ? 'is-invalid' : 'is-valid') : '')}
                                  onChange={(e) => {
                                    setFieldValue(`sender.materialAccountNumber`, e.target.value);
                                    handleUpdateAllReceiversBanks({
                                        code: e.target.value,
                                    },values.recipient,setFieldValue);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialAccountNumber`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={` col-md-2 col-12 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialPriceCode`}>จำนวนเงิน COD</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialPriceCode === '0' ? '' : value.materialPriceCode}
                                  type="number"
                                  name={`recipient[${index}].materialPriceCode`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialPriceCode`) ? (getIn(errors, `recipient[${index}].materialPriceCode`) ? 'is-invalid' : 'is-valid') : '')}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].materialPriceCode`, e.target.value);
                                    var serviceCod = 0;
                                    serviceCod = Math.round((e.target.value / 100) * 3);
                                    if (serviceCod < 5) {
                                      serviceCod = 5;
                                    }
                                    setFieldValue(`recipient[${index}].serviceCod`, serviceCod);
                                    if (localStorage.getItem('requireDimension') === '0' && isCreditCustomer){
                                      setFieldValue(`recipient[${index}].totalNet`, serviceCod);
                                    }
                                    else{
                                      setFieldValue(`recipient[${index}].totalNet`, parseFloat(serviceCod) + parseFloat(value.total));
                                    }
                                    
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialPriceCode`} className="invalid-feedback" />
                              </div>
                            </div>

                            {hideCost && isCreditCustomer ? "":(
                              <div className={` col-md-2 col-12 px-md-1 ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].serviceCod`}>ค่าบริการ COD</label>
                                <input name={`recipient[${index}].serviceCod`} value={value.serviceCod.toLocaleString(undefined, { minimumFractionDigits: 2 })} className={'form-control'} readOnly={true} />
                              </div>
                            </div>
                            )}
                            {hideCost && isCreditCustomer ? "":(
                              <div className={` col-md-2 col-12 px-md-1`}>
                                <label htmlFor={`recipient[${index}].total`}>ค่าขนส่ง</label>
                                <input name={`recipient[${index}].total`} value={value.total.toLocaleString(undefined, { minimumFractionDigits: 2 })} className={'form-control'} readOnly={true} />
                            </div>
                            )}
                            {hideCost && isCreditCustomer ? "":(
                              <div className={`col-md-2 col-12 px-md-1 `}>
                                <label htmlFor={`recipient[${index}].totalNet`}>ยอดสุทธิ (บาท)</label>
                                <input
                                    name={`recipient[${index}].totalNet`} 
                                    value={value.totalNet.toLocaleString(undefined, { minimumFractionDigits: 2 })} className={'form-control'} readOnly={true} />
                            </div>
                              )}
                           </div>
                           <div className="row" style={{ justifyContent: 'left' }}>
                              
                              <div className="col-md-2 px-md-1">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].ref_no2`}>เลขสำหรับลูกค้า 1</label>
                                  <input
                                    type="text"
                                    className={'form-control'}
                                    // disabled = "true"
                                    aria-describedby={`recipient[${index}].ref_no2`}
                                    name={`recipient[${index}].ref_no2`}
                                    value={values.recipient[index].ref_no2}
                                    onChange={(e) => {
                                      setFieldValue(`recipient[${index}].ref_no2`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2 px-md-1">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].item_desc`}>เลขสำหรับลูกค้า 2</label>
                                  <input
                                    type="text"
                                    // disabled = "true"
                                    className={'form-control  '}
                                    aria-describedby={`recipient[${index}].item_desc`}
                                    name={`recipient[${index}].item_desc`}
                                    value={values.recipient[index].item_desc}
                                    onChange={(e) => {
                                      setFieldValue(`recipient[${index}].item_desc`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {/* <ErrorMessage component="div" name={`sender.ref_no`} className="invalid-feedback" /> */}
                                </div>
                              </div>
                              <div className="col-md-2 px-md-1">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].item_sku`}>เลขสำหรับลูกค้า 3</label>
                                  <input
                                    type="text"
                                    className={'form-control  '}
                                    // disabled = "true"
                                    aria-describedby={`recipient[${index}].item_sku`}
                                    name={`recipient[${index}].item_sku`}
                                    value={values.recipient[index].item_sku}
                                    onChange={(e) => {
                                      setFieldValue(`recipient[${index}].item_sku`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {/* <ErrorMessage component="div" name={`sender.ref_no`} className="invalid-feedback" /> */}
                                </div>
                              </div>
                              <div className="col-md-2 px-md-1">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].invoice`}>หมายเลขinvoice</label>
                                  <input
                                    type="text"
                                    className={'form-control  '}
                                    // disabled = "true"
                                    aria-describedby={`recipient[${index}].invoice`}
                                    name={`recipient[${index}].invoice`}
                                    value={values.recipient[index].invoice}
                                    onChange={(e) => {
                                      setFieldValue(`recipient[${index}].invoice`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {/* <ErrorMessage component="div" name={`sender.ref_no`} className="invalid-feedback" /> */}
                                </div>
                              </div>
                              <div className="col-md-2 px-md-1">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].conote`}>เลขที่co note</label>
                                  <input
                                    type="text"
                                    // disabled = "true"
                                    className={'form-control  '}
                                    aria-describedby={`recipient[${index}].conote`}
                                    name={`recipient[${index}].conote`}
                                    value={values.recipient[index].conote}
                                    onChange={(e) => {
                                      setFieldValue(`recipient[${index}].conote`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {/* <ErrorMessage component="div" name={`sender.ref_no`} className="invalid-feedback" /> */}
                                </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-md-4 col-12 px-md-1 align-left ">
                                <div className="form-group">
                                  <label htmlFor={`recipient[${index}].ref_no`}>
                                      Product code
                                  </label>
                                  <span className="important-ct">*</span>
                                  <input
                                    type="text"
                                    className={'form-control  ' + (getIn(touched, `recipient[${index}].ref_no`) ? 
                                  (getIn(errors, `recipient[${index}].ref_no`) ? 'is-invalid' : 'is-valid') : '')}
                                    aria-describedby={`recipient[${index}].ref_no`}
                                    name={`recipient[${index}].ref_no`}
                                    value={values.recipient[index].ref_no}
                                    onChange={(e) => {
                                      // setFieldValue(`sender.ref_no`, e.target.value);
                                      setFieldValue(`recipient[${index}].ref_no`, e.target.value);
                                      setFieldValue(`recipient[${index}].product_code`, e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  <ErrorMessage component="div" name={`recipient[${index}].ref_no`} className="invalid-feedback" />
                                </div>
                              </div>
                              <>
                                      <div className="col-md-2 hidden-ct" style={{display: 'flex', alignItems: 'center'}}>
                                          <div className="form-group checkbox-control mt-4" style={{display: 'flex',alignItems: 'center', marginTop: '1rem'}}>
                                              <input type="checkbox"
                                                     name="sender.return_doc"
                                                     checked={values.sender.return_doc}
                                                     value={values.sender.return_doc}
                                                     onChange={(e) => {
                                                       setFieldValue('sender.return_doc', e.target.checked);
                                                       setFieldValue(`recipient[${index}].return_doc`, e.target.checked);
                                                     }}
                                                     id="return_doc" className="checkbox-custom" />
                                              <label style={{marginBottom: 0}} htmlFor="return_doc">มีเอกสารนำกลับ</label>
                                          </div>
                                      </div>
                                      { <div className="col-md-4 col-12 px-md-1 align-left hidden-ct">
                                          <div className="form-group">
                                              <label htmlFor={`sender.num_return_doc`}>มีเอกสารนำกลับ..............ใบ</label>
                                              <input
                                                  // autocomplete="off"
                                                  type="text"
                                                  value={values.sender.num_return_doc??""}
                                                  className={'form-control  ' + (getIn(touched, `sender.num_return_doc`) ? (getIn(errors, `sender.num_return_doc`) ? 'is-invalid' : 'is-valid') : '')}
                                                  aria-describedby={`sender.num_return_doc`}
                                                  name={`sender.num_return_doc`}
                                                  onChange={(e) => {
                                                      setFieldValue(`sender.num_return_doc`, e.target.value);
                                                      setFieldValue(`recipient[${index}].num_return_doc`, e.target.value);
                                                  }}
                                                  onBlur={handleBlur}
                                              />
                                              <ErrorMessage component="div" name={`sender.num_return_doc`} className="invalid-feedback" />
                                          </div>
                                      </div>}
                                  </>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
            </div>
            <div className={"text-center mt-2 " + disableSubmit }>
              <button
                className="btn btn-success"
                type="submit"
                disabled={disableSubmit || priceLoading}
                onClick={() => {
                  console.log('errors = ', {
                      errors,
                      values,
                  });
                  setStatusPrint(0);
                }}
              >
                ส่งพัสดุ
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
const mapStateToProps = (state) => ({
    user: state.Authentication,
});
export default connect(mapStateToProps)(Porlor);
