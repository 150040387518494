
export const ExportWithoutPicsWorksheetConstants = [
    { header: 'วันเปิดบิล', key: 'วันเปิดบิล', width: 10 },
    { header: 'รหัสลูกค้า', key: 'รหัสลูกค้า', width: 20 },
    { header: 'เลขลาเบล', key: 'เลขลาเบล', width: 50 },
    { header: 'สำหรับลูกค้า / เลขที่co note', key: 'เลขที่conote', width: 50 },
    { header: 'ผู้ส่ง', key: 'ผู้ส่ง', width: 50 },
    { header: 'ผู้รับ', key: 'ผู้รับ', width: 50 },
    { header: 'จำนวน(ชิ้น)', key: 'จำนวน(ชิ้น)', width: 50 },
    { header: 'เบอร์โทรผู้รับ', key: 'เบอร์โทรผู้รับ', width: 50 },
    { header: 'ผู้รับที่อยู่', key: 'ผู้รับที่อยู่', width: 50 },
    { header: 'ตำบล', key: 'ตำบล', width: 50 },
    { header: 'อำเภอ', key: 'อำเภอ', width: 50 },
    { header: 'จังหวัด', key: 'จังหวัด', width: 50 },
    // { header: 'ผู้รับที่อยู่', key: 'ผู้รับที่อยู่', width: 50 },
    { header: 'น้ำหนัก', key: 'น้ำหนัก', width: 50 },
    { header: 'ความกว้าง (ซม.)', key: 'ความกว้าง (ซม.)', width: 50 },
    { header: 'ความยาว (ซม.)', key: 'ความยาว (ซม.)', width: 50 },
    { header: 'ความสูง (ซม.)', key: 'ความสูง (ซม.)', width: 50 },
    { header: 'ขนาด(ซ.ม.)', key: 'ขนาด(ซ.ม.)', width: 50 },
    { header: 'COD (บาท)', key: 'COD (บาท)', width: 50 },
    { header: 'ค่าขนส่ง(บาท)', key: 'ค่าขนส่ง(บาท)', width: 50 },
    { header: 'ชื่อธนาคาร', key: 'ชื่อธนาคาร', width: 50 },
    { header: 'ชื่อบัญชี', key: 'ชื่อบัญชี', width: 50 },
    { header: 'เลขที่บัญชี', key: 'เลขที่บัญชี', width: 50 },
    { header: 'เลขสำหรับลูกค้า 1', key: 'เลขสำหรับลูกค้า 1', width: 50 },
    { header: 'เลขสำหรับลูกค้า 2', key: 'เลขสำหรับลูกค้า 2', width: 50 },
    { header: 'เลขสำหรับลูกค้า 3', key: 'เลขสำหรับลูกค้า 3', width: 50 },
    { header: 'จำนวนเอกสารนำกลับ', key: 'จำนวนเอกสารนำกลับ', width: 50 },
    { header: 'สถานะ', key: 'สถานะ', width: 50 },
    { header: 'รหัสพนักงานขนส่ง', key: 'รหัสพนักงานขนส่ง', width: 50 },
    { header: 'ชื่อพนักงานขนส่ง', key: 'ชื่อพนักงานขนส่ง', width: 50 },
    { header: 'วันที่เซ็นรับ', key: 'วันที่เซ็นรับ', width: 50 },
    { header: 'ลายเซ็น', key: 'ลายเซ็น', width: 125 },
];