import Modal from "react-bootstrap/Modal";
import React, {useState, useEffect, useRef} from "react";
import * as Icon from "react-bootstrap-icons";
import "./style.css";
import {CONOTE_PER_PAGES, conoteBarcodes2,conoteParcelGroups, conoteList, update_ref_no, update_ref_no_status} from "./conoteService";
import swal from "sweetalert";
import {Pagination} from "@material-ui/lab";
import {Spinner} from "react-bootstrap";
import LabelForm from "../labelForm";
import printJS from "print-js";
import {ConotePrintModel} from "../../../../Components/ConotePrintModel/ConotePrintModel";
import {useReactToPrint} from "react-to-print";
import PrintContent from "./printContents";
import {isEmptyArray} from "formik";
import {swalError, swalSuccess} from "../../../../Components/sweetAlert";
import login from "../../login";
import {format} from "date-fns";
import { useMemo } from "react";


export const ConoteModal = ({children,onModalHide}) => {
    const [data, setData] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [loading,setLoading] = useState(false);
    const [labelLoading,setLabelLoading] = useState(false);
    const [labelData, setLabelData] = useState(null);
    const [pageData, setPageData] = useState({
        last_page: null,
        total: null,
    });
    const [currentConotePrint,setCurrentConotePrint] = useState(null);
    const [noLabelPrint,setNoLabelPrint] = useState(null);
    const [printData,setPrintData] = useState("");
    const [currentRef_no_to_edit,setCurrentRef_no_to_edit] = useState(null);
    const [ref_no_edit, setRef_no_edit] = useState(null);
    const [ref_no_edit_loading,setRef_no_edit_loading] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [multipleConotePrint, setMultipleConotePrint] = useState(false);
    const [conoteGroupData, setConoteGroupData] = useState(null);
    const fetchData = (searchText, pageNo) => {
        setCheckedList([]);
        setLoading(true);
        setPage(pageNo);
        setSearch(searchText);
        conoteList(localStorage.getItem('username'),searchText,pageNo,CONOTE_PER_PAGES)
            .then(result => {
                if(!isEmptyArray(result.data)){
                    setPageData({
                        last_page: result.data.maxPage,
                        total: result.data.numRowsAll,
                    });
                    console.log(result.data);
                    setData(result.data);
                }
                else{
                    setData(false);
                    setPageData({
                        last_page: null,
                        total: null,
                    });
                }
                setLoading(false);
            }, error => {
                console.log('error = ', error);
                swal({
                    title: '',
                    text: 'เกิดข้อผิดพลาดกับเซิร์ฟเวอร์',
                    icon: 'warning',
                    button: 'ปิด',
                });
                onModalHide();
            });
    };
    const handlePrintBarcodes = (ref_no) => {
        setLabelLoading(true);
        setLabelData(null);
        conoteBarcodes2(ref_no)
        .then(result => {
            console.log('resultat = ', result);
            setLabelData(result.data.data.res);
            setLabelLoading(false);
        }, error => {
            console.log("error = ", error);
            swal({
                title: '',
                text: 'เกิดข้อผิดพลาดกับเซิร์ฟเวอร์',
                icon: 'warning',
                button: 'ปิด',
            });
            setLabelLoading(false);
        });
    };
    useEffect(() => {
        fetchData("",1);
    }, []);
    let printMultipleContent = <></>;
    const customID = "customPrintLabelConote";
    if(labelData){
        printMultipleContent = (
            <LabelForm customID={customID} printLebel={labelData} />
        );
    }
    let conoteContent = <></>;
    const conotePrintRef = useRef(null);
    const multipleConotePrintRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => conotePrintRef.current,
        onAfterPrint: () => {
            setCurrentConotePrint(null);
        },
        onPrintError: (error) => {
            setCurrentConotePrint(null);
        },
    });
    const tickRefNo = (row) => {
        setCheckedList(existsInlist(row.ref_no) ? [...checkedList.filter(value => value.ref_no !== row.ref_no)] : [...checkedList,row]);
    };
    const existsInlist = (ref_no) => {
        return !!checkedList.find(value => value.ref_no === ref_no);
    };
    const handleCheckActionAll = (target) => {
        setCheckedList(target.checked ? [...data.result]:[]);
    };
    

    if(currentConotePrint){
        conoteContent = (
            <ConotePrintModel ref={conotePrintRef} />
        );
    }
    async function handleConoteDataGroup(row) {
        setLoading(true);
        try {
            setConoteGroupData(null);
            const result = await conoteParcelGroups([row.ref_no],localStorage.getItem('username'));
            setConoteGroupData(result.data.data);
            LoadDataExport(row);
        }
        catch (error) {
            if (error.response?.data?.message) {
                swalError({
                    text: error.response?.data?.message
                });
            }
            setLoading(false);
        }
    }
    async function LoadDataExport(row) {
        setPrintData(row);
        setTimeout(() => {
            setLoading(false);
            printJS({
                printable: "printReport",
                type: "html",
                scanStyles: false,
                css: [
                  window.location.protocol+"//" + window.location.host + "/static/Conote.css",
                  "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
                ],
                style: "@page { size: 20cm 14cm; margin:0cm; } ",
                onLoadingStart: () => {
                    if(data.conote_print_status == 1){
                        return;
                    }
                    update_ref_no_status([row.ref_no])
                        .then(result => {
                            if(result.data.res){
                                data.result.forEach(datarow => {
                                   if(row.ref_no === datarow.ref_no){
                                       datarow.conote_print_date = format(new Date(), 'yyyy-MM-dd');
                                       datarow.conote_print_status = "1";
                                   }
                                });
                                setData({
                                    ...data,
                                    result: data.result,
                                });
                            }
                        }, error => {
                            console.log({error});
                        });
                }
              });
          },2000);
        // }
      }

    useEffect(() => {
        if(labelData){
            console.log('printing label data')
                printJS({
                    printable:  customID,
                    type: 'html',
                    scanStyles: false,
                    css: [
                        window.location.protocol + '//' + window.location.host + '/static/Printer.css',
                        'https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap',
                    ],
                    style: '@page {size: 10cm 15cm;margin: 0.3cm;}',
                    onLoadingEnd: () => {
                        setLabelData(null);
                    },
                });
        }
    }, [labelData]);

    useEffect(() => {
        if(conotePrintRef.current && currentConotePrint){
            console.log({
                currentConotePrint,
                component: conotePrintRef,
                currentComponent: conotePrintRef.current,
                handlePrint,
            })
            handlePrint();
        }
    }, [conotePrintRef, currentConotePrint, handlePrint]);

    const handlePrintConote = (row) => {
        setCurrentConotePrint(row);
    };

    const handleMultipleConoteGroups = async () => {
        const ref_nos = checkedList.map(row => row.ref_no);
        setLoading(true);
        try {
            const results = await conoteParcelGroups(ref_nos,localStorage.getItem('username'));
            checkedList.forEach(value => {
                value.groupData = results.data.data.filter(groupData => groupData.ref_no === value.ref_no);
            });
            setLoading(false);
            setCheckedList([...checkedList]);
        }
        catch (error) {
            if (error.response?.data?.message) {
                swalError({
                    text: error.response?.data?.message
                });
            }
            setLoading(false);
        }
    };
    
    const handlePrintConoteMultiple = () => {
        printJS({
            printable: "printReportMultiple",
            type: "html",
            scanStyles: false,
            css: [
                window.location.protocol + "//" + window.location.host + "/static/Conote.css",
                "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
            ],
            style: "@page { page-break-before: always;size: 20cm 14cm; margin:0 !important;border: none !important; padding: 0 !important; } ",
            onLoadingStart: () => {
                update_ref_no_status(checkedList.filter(row => row.conote_print_status == "0")
                    .map(checked => checked.ref_no))
                    .then(result => {
                        if (result.data.res) {
                            data.result.forEach(datarow => {
                                if (existsInlist(datarow.ref_no)) {
                                    datarow.conote_print_date = format(new Date(), 'yyyy-MM-dd');
                                    datarow.conote_print_status = "1";
                                }
                            });
                            setData({
                                ...data,
                                result: data.result,
                            });
                        }
                    }).catch(error => {
                        swalError({
                            text: "เกิดข้อผิดพลาดระหว่างการอัปเดตสถานะบันทึกร่วม"
                        })
                    })
            },
            onPrintDialogClose: () => {
                setMultipleConotePrint(false);
            },
        })
    };
    useEffect(() => {
        if (multipleConotePrint && checkedList && checkedList.some(row => row.groupData)) {
            handlePrintConoteMultiple();
        }
    }, [checkedList, multipleConotePrint]);
    const content = (
        <>
            <div className="row searchbar" style={{marginBottom: '5px'}}>
                <span>ค้นหาเลขลาเบล/เลขบุ๊คกิ้ง/ชื่อผู้รับ/ชื่อผู้ส่ง</span>
                <input
                    className="form-control"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
            </div>
            <div className="d-flex justify-content-between mb-2">
                <div>
                        <span>
                          <button
                              name="search"
                              disabled={loading}
                              className={"btn btn-primary"}
                              onClick={() => {
                                  fetchData(search,1);
                              }}
                          >
                            <Icon.Search /> ค้นหา
                          </button>
                        </span>
                    <span>
                           <button
                               style={{marginLeft: '5px', marginRight: '5px'}}
                               type="button"
                               disabled={loading}
                               className={loading ? 'btn btn-secondary' : 'btn btn-warning'}
                               onClick={() => {
                                   fetchData('',1);
                               }}
                           >
                            <Icon.ArrowClockwise />
                          </button>
                        </span>
                    <span>
                        <button
                           style={{marginLeft: '5px', marginRight: '5px'}}
                           type="button"
                           disabled={loading || checkedList.length === 0}
                           className={checkedList.length === 0 ? 'btn btn-secondary' : 'btn btn-primary'}
                            onClick={() => {
                               setMultipleConotePrint(true);
                               handleMultipleConoteGroups();
                               }}
                       >
                            <Icon.Printer />  ปริ้นco note หลายใบ
                      </button>
                    </span>
                </div>
            </div>
            { pageData.total !== null ? (
                <div>
                    <div style={{ overflow: 'Auto' }}>
                        <table className="table table-bordered">
                            <thead style={{ backgroundColor: '#3f75c1' }}>
                                <tr style={{ color: '#FFFFFF' }}>
                                    <td style={{textAlign: 'center'}}>
                                        <input type="checkbox"
                                               onChange={(e) => {
                                                   handleCheckActionAll(e.target);
                                               }}
                                               checked={data?.result && checkedList.length === data.result.length}
                                               style={{width: '18px',height: '18px'}} />
                                    </td>
                                    <td style={{textAlign: 'right'}}>เลขสำหรับลูกค้า</td>
                                    <td style={{textAlign: 'right'}}>จำนวน(ชิ้น)</td>
                                    <td style={{textAlign: 'center'}}>ลาเบล</td>
                                    {/* <td style={{textAlign: 'center'}}>พิมพ์วันที่</td>
                                    <td style={{textAlign: 'center'}}>สถานะการพิมพ์</td> */}
                                </tr>
                            </thead>
                            <tbody>
                            {data && data.result.map((row, index) => (
                                <tr key={index}>
                                    <td style={{textAlign: 'center'}}>
                                        <input type="checkbox"
                                               style={{width: '18px',height: '18px'}}
                                               checked={existsInlist(row.ref_no)}
                                               onChange={(value) => tickRefNo(row)}
                                               value={row.ref_no}/>
                                    </td>
                                    <td style={{textAlign: 'right'}}>{row.ref_no}</td>
                                    <td style={{textAlign: 'right'}}>{row.pcs}</td>
                                    <td>
                                        <ul style={{marginBottom: 0,maxHeight: '100px',overflow: 'auto'}}>
                                            {row.awb_codes.split(',').map(awb_code => (
                                                <li>{awb_code}</li>
                                            ))}
                                        </ul>
                                    </td>
                                    {/* <td style={{textAlign: 'center'}}>{row.conote_print_date || '-'}</td>
                                    <td style={{textAlign: 'center'}}>{row.conote_print_status === "0" ? 'ไม่พิมพ์': 'พิมพ์'}</td> */}
                                    <td style={{textAlign: 'center'}}>
                                        {/* <button
                                            onClick={() => handlePrintConote(row)}
                                            className="btn btn-primary">
                                            <Icon.Printer />
                                        </button> */}
                                        <button
                                            onClick={(e) => {
                                                setRef_no_edit(row.ref_no);
                                                setCurrentRef_no_to_edit(row.ref_no);
                                            }}
                                            className="btn btn-warning">
                                            <Icon.Pencil />
                                        </button>
                                        <button
                                            onClick={() => {
                                                handleConoteDataGroup(row)
                                            }}
                                            style={{marginLeft: "5px", marginRight: "5px"}}
                                            className="btn btn-primary">
                                            <Icon.Printer /> ปริ้นใบ co-note
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                handlePrintBarcodes(row.ref_no);
                                            }}
                                            style={{marginLeft: "5px", marginRight: "5px"}}
                                            type="button"
                                            className="btn btn-info">
                                            <Icon.Printer/> พิมพ์ลาเบล/ที่อยู่เป็นชุด
                                        </button>
                                        
                                    </td>
                                </tr>
                            )) }
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>จำนวนทั้งหมด {pageData.total} รายการ</div>
                        {data && <div>
                            <Pagination
                                count={parseInt(pageData.last_page)}
                                page={page}
                                onChange={(e, newPage) => {
                                    fetchData(search, newPage);
                                }}
                                color="primary"
                                size="small"
                                defaultPage={1}
                                siblingCount={1}
                            />
                        </div>}
                    </div>
                </div>
                
            ): (
                <div style={{textAlign: 'center'}}>
                    <font color="red" size="4">
                        <br />
                        ไม่พบข้อมูล!
                        <br />
                    </font>
                </div>
                
            )}
            
            <div style={{display: "none"}}>{printMultipleContent}</div>
            <div style={{display: "none"}}>{conoteContent}</div>
            <div
            style={{display: "none"}}
            >
                <div id="printReport">
                    <PrintContent
                        data={printData}
                        conoteGroups={conoteGroupData}
                    />
                </div>
            </div>
            {checkedList.length !== 0 &&
                <div
                    style={{display: "none"}}
                >
                    <div ref={multipleConotePrintRef} id="printReportMultiple" style={{width: '20cm',margin: 0, padding: 0}}>
                        {checkedList.map((checked,index) => (
                                <PrintContent
                                    additionalStyles={{width: '20cm', height: '13.5cm',pageBreakAfter: 'always'}}
                                    data={checked}
                                    conoteGroups={checked.groupData}
                                />
                        ))}
                    </div>
                </div>
            }
        </>
    );
    const handleEditRefNo = () => {
        setRef_no_edit_loading(true);
        update_ref_no(currentRef_no_to_edit,ref_no_edit)
            .then(result => {
                setRef_no_edit_loading(false);
                if(result.data.result){
                    handleEditHide();
                    fetchData(search,1);
                }
                else{
                    swalError({title: 'เกิดข้อผิดพลาด', text: 'ไม่สามารถอัปเดตหมายเลขอ้างอิงได้'});
                }
            })
            .catch(error => {
                setRef_no_edit_loading(false);
                console.log({
                    error
                });
                swalError({title: 'เกิดข้อผิดพลาด', text: 'ไม่สามารถอัปเดตหมายเลขอ้างอิงได้'});
            });
    };
    const handleEditHide = () => {
            setCurrentRef_no_to_edit(null);
            setRef_no_edit(null);
    };
    const editModal = (
        <Modal className="editmodal" style={{padding: '15px'}} show={true} >
                <div className="form-group">
                    <label for="ref_no_edit">
                    แก้ไขข้อมูล
                    <br/>
                    เลขที่co note</label>
                    <input id="ref_no_edit" disabled={ref_no_edit_loading} className="form-control" value={ref_no_edit} onChange={(e) => setRef_no_edit(e.target.value)} />
                </div>
                <div className="footer" style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                    <button disabled={ref_no_edit === '' || ref_no_edit_loading}
                            onClick={handleEditRefNo}
                            className="btn btn-primary">
                        บันทึก
                    </button>
                    <button
                            disabled={ref_no_edit_loading}
                            style={{marginLeft: '5px'}}
                            onClick={() => handleEditHide()}
                            className="btn btn-secondary">
                        ยกเลิก
                    </button>
                </div>
        </Modal>
    );
    return (
        <Modal className="ConoteModal" show={true} onHide={onModalHide}>
                {!loading && content}
                {loading && (
                    <div className="spinner-content">
                        <Spinner variant="success" animation="border" />
                    </div>)
                }
                {currentRef_no_to_edit && editModal}
        </Modal>
    );
};
