import React, { useState, useEffect } from "react";
import { nanoid } from "nanoid";
import * as Icon from "react-bootstrap-icons";
import "react-bootstrap-typeahead/css/Typeahead.css";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import applySchema from "./validationApply";
import Tap1 from "../manager/formTap1";
import Tap2 from "../manager/formTap2";
import Tap3 from "../manager/formTap3";
import Tap4 from "../manager/formTap4";
import Tap5 from "../manager/formTap5";
import { SaveCareer, DeleteCareer } from "../../../../Service/service.Career";

export default function FormApply({ code }) {
  const history = useHistory();
  const [loading, Loading] = useState(0);
  const param = useLocation();
  const [step, setStep] = useState(1);
  const [accept, setAccept] = useState(1);
  const [position, setPosition] = useState("");
  const [applicants, setApplicants] = useState({});

  async function Del(value) {
    Loading(1);
    const res = await DeleteCareer(value);
    if (res.status === 1) {
      Loading(0);
      swal({
        title: res.message,
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      // history.push(param.state.path);
    } else if (res.status === 0) {
      swal("แจ้งเตือน", res.message, "warning");
      Loading(0);
    } else {
      swal("แจ้งเตือน", "เกิดข้แผิดพลาด", "warning");
      Loading(0);
    }
  }

  async function SaveData(value) {
    Loading(1);
    const res = await SaveCareer(value);
    console.log("res", res);
    if (res.status === 1) {
      Loading(0);
      swal({
        title: res.message,
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      history.push(param.state.path, {
        value: position,
        code: applicants.code,
      });
    } else if (res.status === 2) {
      swal("แจ้งเตือน", res.message, "warning");
      Loading(0);
    } else {
      swal("แจ้งเตือน", res.message, "warning");
      Loading(0);
    }
  }

  useEffect(() => {
    if (param.state.value !== undefined) {
      setPosition(param.state.value);
      setApplicants(param.state.value);
    }
  }, []);

  return (
    <div className="mt-5">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb" style={{ backgroundColor: "#ffffff" }}>
            <li className="breadcrumb-item">
              <p
                type="button"
                className="btn-link"
                style={{ color: "#000000" }}
                onClick={(e) => {
                  history.push(param.state.path, {
                    value: position,
                    code: applicants.code,
                  });
                }}
              >
                {param.state.text}
              </p>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              สมัครงาน
            </li>
          </ol>
        </nav>

        <>
          <Formik
            validationSchema={applySchema}
            // enableReinitialize
            initialValues={{
              information: {
                titleName:
                  param.state.value.title !== undefined
                    ? param.state.value.title
                    : "",
                name:
                  param.state.value.name !== undefined
                    ? param.state.value.name
                    : "",
                lastName:
                  param.state.value.surname !== undefined
                    ? param.state.value.surname
                    : "",
                salary:
                  param.state.value.salary !== undefined
                    ? param.state.value.salary
                    : "",
                addresNum:
                  param.state.value.house_no !== undefined
                    ? param.state.value.house_no
                    : "",

                road:
                  param.state.value.Road !== undefined
                    ? param.state.value.Road
                    : "",

                subdistrict:
                  param.state.value.sub_district !== undefined
                    ? param.state.value.sub_district
                    : "",
                district:
                  param.state.value.district !== undefined
                    ? param.state.value.district
                    : "",
                province:
                  param.state.value.province !== undefined
                    ? param.state.value.province
                    : "",
                postCode:
                  param.state.value.postal_code !== undefined
                    ? param.state.value.postal_code
                    : "",
                mobilePhone:
                  param.state.value.phone_number !== undefined
                    ? param.state.value.phone_number
                    : "",
                lineID:
                  param.state.value.line_id !== undefined
                    ? param.state.value.line_id
                    : "",
                email:
                  param.state.value.email !== undefined
                    ? param.state.value.email
                    : "",
                liveWith:
                  param.state.value.habitation !== undefined
                    ? param.state.value.habitation
                    : "",
                birthDay:
                  param.state.value.birth_day !== undefined
                    ? (param.state.value.birth_day < 9 ? "0" : "") +
                      param.state.value.birth_day
                    : "",
                birthMonth:
                  param.state.value.birth_month !== undefined
                    ? (param.state.value.birth_month < 9 ? "0" : "") +
                      param.state.value.birth_month
                    : "",
                birthYear:
                  param.state.value.birth_year !== undefined
                    ? param.state.value.birth_year
                    : "",
                age:
                  param.state.value.age !== undefined
                    ? param.state.value.age
                    : "",
                race:
                  param.state.value.ethnicity !== undefined
                    ? param.state.value.ethnicity
                    : "",

                nationality:
                  param.state.value.nationality !== undefined
                    ? param.state.value.nationality
                    : "",

                religion:
                  param.state.value.religion !== undefined
                    ? param.state.value.religion
                    : "",

                thaiID:
                  param.state.value.citizen_id !== undefined
                    ? param.state.value.citizen_id
                    : "",
                exDate_ThaiDay:
                  param.state.value.exp_day !== undefined
                    ? (param.state.value.exp_day < 9 ? "0" : "") +
                      param.state.value.exp_day
                    : "",
                exDate_ThaiMonth:
                  param.state.value.exp_month !== undefined
                    ? (param.state.value.exp_month < 9 ? "0" : "") +
                      param.state.value.exp_month
                    : "",
                exDate_ThaiYear:
                  param.state.value.exp_year !== undefined
                    ? param.state.value.exp_year
                    : "",
                military:
                  param.state.value.military_status !== undefined
                    ? param.state.value.military_status
                    : "",
                status:
                  param.state.value.marital_status !== undefined
                    ? param.state.value.marital_status
                    : "",
                nameSpouse:
                  param.state.value.wife_husband_name !== undefined
                    ? param.state.value.wife_husband_name
                    : "",
                lastNameSpouse:
                  param.state.value.wife_husband_surname !== undefined
                    ? param.state.value.wife_husband_surname
                    : "",
                workplaceSpouse:
                  param.state.value.wife_husband_workplace_name !== undefined
                    ? param.state.value.wife_husband_workplace_name
                    : "",
                positionSpouse:
                  param.state.value.wife_husband_position !== undefined
                    ? param.state.value.wife_husband_position
                    : "",
                childAmontSpouse:
                  param.state.value.children_number !== undefined
                    ? param.state.value.children_number
                    : "",
              },

              familyHistory: {
                father: {
                  name:
                    param.state.value.father_name !== undefined
                      ? param.state.value.father_name
                      : "",
                  lastName:
                    param.state.value.father_surname !== undefined
                      ? param.state.value.father_surname
                      : "",
                  age:
                    param.state.value.father_age !== undefined
                      ? param.state.value.father_age
                      : "",
                  career:
                    param.state.value.father_occupation !== undefined
                      ? param.state.value.father_occupation
                      : "",
                },
                mother: {
                  name:
                    param.state.value.mother_name !== undefined
                      ? param.state.value.mother_name
                      : "",
                  lastName:
                    param.state.value.mother_surname !== undefined
                      ? param.state.value.mother_surname
                      : "",
                  age:
                    param.state.value.mother_age !== undefined
                      ? param.state.value.mother_age
                      : "",
                  career:
                    param.state.value.mother_occupation !== undefined
                      ? param.state.value.mother_occupation
                      : "",
                },

                brethrenAmont:
                  param.state.value.members_family !== undefined
                    ? param.state.value.members_family
                    : "1",
                male:
                  param.state.value.male_family !== undefined
                    ? param.state.value.male_family
                    : "",
                female:
                  param.state.value.female_family !== undefined
                    ? param.state.value.female_family
                    : "",
                personWho:
                  param.state.value.my_number !== undefined
                    ? param.state.value.my_number
                    : "",
              },

              familyAmont:
                param.state.family === undefined || param.state.family === ""
                  ? [
                      // {
                      //   key: nanoid(),
                      //   code: "",
                      //   titleName: "",
                      //   name: "",
                      //   lastName: "",
                      //   age: "",
                      //   career: "",
                      // },
                    ]
                  : param.state.family,

              education: {
                educationLevel:
                  param.state.value.educational_level_code !== undefined
                    ? param.state.value.educational_level_code
                    : "",
                institution:
                  param.state.value.institution !== undefined
                    ? param.state.value.institution
                    : "",
              },
              experience:
                param.state.experienc === undefined
                  ? [
                      {
                        key: nanoid(),
                        code: "",
                        company: "",
                        start_year: "",
                        end_year: "",
                        position: "",
                        jobDescription: "",
                        salary: "",
                        reasonsOfResignation: "",
                      },
                    ]
                  : param.state.experienc,

              languages:
                param.state.Language === undefined
                  ? [
                      {
                        key: nanoid(),
                        code: "",
                        language: "",
                        speaking: "",
                        read: "",
                        write: "",
                      },
                    ]
                  : param.state.Language,
              ability: {
                useComputer:
                  param.state.value.use_computer !== undefined
                    ? param.state.value.use_computer
                    : "",
                driveCar:
                  param.state.value.driving !== undefined
                    ? param.state.value.driving
                    : "",
                driverLicense:
                  param.state.value.driving_license_no !== undefined
                    ? param.state.value.driving_license_no
                    : "",
                workingProvinces:
                  param.state.value.work_upcountry !== undefined
                    ? param.state.value.work_upcountry
                    : "",
                AbilityOther:
                  param.state.value.otherAbilities !== undefined
                    ? param.state.value.otherAbilities
                    : "",
              },

              other: {
                emergencyContact: {
                  name:
                    param.state.value.emergency_contact !== undefined
                      ? param.state.value.emergency_contact
                      : "",
                  lastName:
                    param.state.value.emergency_contact_surname !== undefined
                      ? param.state.value.emergency_contact_surname
                      : "",
                  phone:
                    param.state.value.emergency_phone !== undefined
                      ? param.state.value.emergency_phone
                      : "",
                  relationship:
                    param.state.value.relationship !== undefined
                      ? param.state.value.relationship
                      : "",
                },
                sick:
                  param.state.value.contagious !== undefined
                    ? param.state.value.contagious
                    : "",
                sickDetail:
                  param.state.value.contagious_detail !== undefined
                    ? param.state.value.contagious_detail
                    : "",
                applyThisCompany:
                  param.state.value.used_to_work !== undefined
                    ? param.state.value.used_to_work
                    : "",
                applyDate:
                  param.state.value.years_work !== undefined
                    ? param.state.value.years_work
                    : "",
                applyPosition:
                  param.state.value.position_work !== undefined
                    ? param.state.value.position_work
                    : "",
              },
            }}
            onSubmit={(value) => {
              var data = {
                code: param.state.code !== undefined ? param.state.code : "",
                title: value.information.titleName,
                name: value.information.name,
                surname: value.information.lastName,
                birthday:
                  value.information.birthYear +
                  "-" +
                  value.information.birthMonth +
                  "-" +
                  value.information.birthDay,
                age: value.information.age,
                citizen_id: value.information.thaiID,
                expiration_date:
                  value.information.exDate_ThaiYear +
                  "-" +
                  value.information.exDate_ThaiMonth +
                  "-" +
                  value.information.exDate_ThaiDay,
                ethnicity: value.information.race,
                nationality: value.information.nationality,
                religion: value.information.religion,
                phone_number: value.information.mobilePhone,
                line_id: value.information.lineID,
                email: value.information.email,
                position: position.positionCode,
                work_location: position.work_location,
                salary: value.information.salary,
                house_no: value.information.addresNum,
                Road: value.information.road,
                sub_district: value.information.subdistrict,
                district: value.information.district,
                province: value.information.province,
                postal_code: value.information.postCode,
                habitation: value.information.liveWith,
                military_status: value.information.military,
                marital_status: value.information.status,
                wife_husband_name: value.information.nameSpouse,
                wife_husband_surname: value.information.lastNameSpouse,
                wife_husband_workplace_name: value.information.workplaceSpouse,
                wife_husband_position: value.information.positionSpouse,
                children_number: value.information.childAmontSpouse,
                father_name: value.familyHistory.father.name,
                father_surname: value.familyHistory.father.lastName,
                father_age: value.familyHistory.father.age,
                father_occupation: value.familyHistory.father.career,
                mother_name: value.familyHistory.mother.name,
                mother_surname: value.familyHistory.mother.lastName,
                mother_age: value.familyHistory.mother.age,
                mother_occupation: value.familyHistory.mother.career,
                members_family: value.familyHistory.brethrenAmont,
                male_family: value.familyHistory.male,
                female_family: value.familyHistory.female,
                my_number: value.familyHistory.personWho,
                educational_level_code: value.education.educationLevel,
                institution: value.education.institution,
                use_computer: value.ability.useComputer,
                driving: value.ability.driveCar,
                driving_license_no: value.ability.driverLicense,
                work_upcountry: value.ability.workingProvinces,
                otherAbilities: value.ability.AbilityOther,
                emergency_contact: value.other.emergencyContact.name,
                emergency_contact_surname:
                  value.other.emergencyContact.lastName,
                emergency_phone: value.other.emergencyContact.phone,
                relationship: value.other.emergencyContact.relationship,
                contagious: value.other.sick,
                contagious_detail: value.other.sickDetail,
                used_to_work: value.other.applyThisCompany,
                years_work: value.other.applyDate,
                position_work: value.other.applyPosition,

                app_family: value.familyAmont,
                app_experience: value.experience,
                app_language: value.languages,
              };
              SaveData(data);
              console.log("data", data);
            }}
          >
            {({ errors, touched, handleBlur, setFieldValue, values }) => (
              <Form>
                {console.log("errors", errors)}
                <div className="container">
                  <div className="text-center text-title-color">
                    <h2>สมัครงาน</h2>
                  </div>
                  <br />
                  <div className="row text-center">
                    <div className="col-sm-6">
                      <b>ตำแหน่ง : </b>
                      <span>{position.positionName}</span>
                    </div>
                    <div className="col-sm-6">
                      <b>สถานที่ปฏิบัติงาน : </b>
                      <span>{position.work_location}</span>
                    </div>
                  </div>
                  <br />
                  <br />
                  {/* ////////////////////////////step/////////////////////////////////// */}
                  <div className="stepwizard">
                    <div className="stepwizard-row setup-panel">
                      <div className="stepwizard-step col-xs-3">
                        <button
                          type="button"
                          className={
                            step === 1
                              ? "btn btn-success btn-circle"
                              : "btn btn-secondary btn-circle"
                          }
                          onClick={() => {
                            setStep(1);
                          }}
                        >
                          1
                        </button>
                        <p>
                          <small>ประวัติส่วนตัว</small>
                        </p>
                      </div>
                      <div className="stepwizard-step col-xs-3">
                        <button
                          type="button"
                          className={
                            step === 2
                              ? "btn btn-success btn-circle"
                              : "btn btn-secondary btn-circle"
                          }
                          onClick={() => {
                            setStep(2);
                          }}
                        >
                          2
                        </button>
                        <p>
                          <small>ประวัติครอบครัว</small>
                        </p>
                      </div>
                      <div className="stepwizard-step col-xs-3">
                        <button
                          type="button"
                          className={
                            step === 3
                              ? "btn btn-success btn-circle"
                              : "btn btn-secondary btn-circle"
                          }
                          onClick={() => {
                            setStep(3);
                          }}
                        >
                          3
                        </button>
                        <p>
                          <small>การศึกษา/ประสบการณ์</small>
                        </p>
                      </div>
                      <div className="stepwizard-step col-xs-3">
                        <button
                          type="button"
                          className={
                            step === 4
                              ? "btn btn-success btn-circle"
                              : "btn btn-secondary btn-circle"
                          }
                          onClick={() => {
                            setStep(4);
                          }}
                        >
                          4
                        </button>
                        <p>
                          <small>ภาษา/ความสามารถ</small>
                        </p>
                      </div>
                      <div className="stepwizard-step col-xs-3">
                        <button
                          type="button"
                          className={
                            step === 5
                              ? "btn btn-success btn-circle"
                              : "btn btn-secondary btn-circle"
                          }
                          onClick={() => {
                            setStep(5);
                          }}
                        >
                          5
                        </button>
                        <p>
                          <small>อื่น ๆ</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* /////////////////////////////////////////////////////////////// */}
                  <Tap1
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    values={values}
                    position={position}
                    step={step}
                  />
                  <Tap2
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    values={values}
                    step={step}
                    Del={Del}
                  />
                  <Tap3
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    values={values}
                    step={step}
                    Del={Del}
                  />
                  <Tap4
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    values={values}
                    step={step}
                    Del={Del}
                  />
                  <Tap5
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    values={values}
                    step={step}
                    setAccept={setAccept}
                    accept={accept}
                  />
                  {/* /////////////////////////////////////////////////////////////// */}
                  <br />
                  <div className="text-center">
                    <button
                      type="button"
                      className={step !== 1 ? "btn btn-primary" : "hidden-ct"}
                      onClick={() => {
                        setStep(step - 1);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <Icon.CaretLeft /> ก่อนหน้า
                    </button>{" "}
                    <button
                      type="button"
                      className={step !== 5 ? "btn btn-secondary" : "hidden-ct"}
                      onClick={() => {
                        setStep(step + 1);
                        window.scrollTo(0, 0);
                      }}
                    >
                      ถัดไป <Icon.CaretRight />
                    </button>
                    <button
                      type="submit"
                      className={step === 5 ? "btn btn-success" : "hidden-ct"}
                      disabled={accept === 1 ? true : false}
                    >
                      <Icon.Save /> บันทึก
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
