import React from "react";
import { NavLink } from "react-router-dom";
export default function Error() {
  return (
    <div className="bg-img" style={{ height: "100vh" }}>
      <div className="text-center">
          <NavLink to="/" className="btn btn-primary b-center-page">กลับสู่หน้าหลัก</NavLink>
      </div>
    </div>
  );
}
