import React from "react";

export default function misLayout(props) {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "100%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {props.children}
    </div>
  );
}
