/* eslint-disable no-undef */
import React, { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import Pagination from "@material-ui/lab/Pagination";
import DateTH from "../../../Helper/date-th";
//import { BarcodeService, BarcodeUpdateService, CODUpdateService, CustomerTypeService } from "../../../Service/service.Barcode";
import { CODService, BarcodeUpdateService, CODUpdateService, CustomerTypeService, UpdateRemarkService , UpdateTransferReceipt, UploadFile, UpdateMultipleTransferReceipt , UploadFileForMultiple , uploadExcel } from "../../../Service/service.CashOnDelivery";
import { cod_status_list } from "../../../Data/cod_status";
import { bank } from "../../../Data/bank";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { monthTH } from "../../../Data/month-th.json";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import FormatLabel from "../formatLabel";
import FormatLabelCod from "../formatLabelCod";
import FormatLabelAddress from "../formatLabelAddress";
import FormatLabelAddressCod from "../formatLabelAddressCod";
import { nanoid } from "nanoid";
import IMPORT_DATA from '../../../Assets/excel/updateCOD.xlsx';
import printJS from "print-js";
import TdCustom from "../../component/tdCustom";
import reactBootstrapIcons from 'react-bootstrap-icons';
import ReactExport from "react-data-export";
//import { CODService } from "../../../Service/service.CashOnDelivery";

export default function ManageCOD() {
  const history = useHistory();
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  var [data2, Data2] = useState([]);
  var [list_check,List_check] = useState([]);
  const [setData, SetData] = useState([]);
  const [setPage, SetPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });
  const [isManage, setIsManage] = useState(true);
  const [currentPage, SetcurrentPage] = useState(1);
  const [pageSizes, PageSizes] = useState(10);
  const [customer_type, Customer_type] = useState("");
  const [cust_code, Cust_code] = useState([]);
  const [cust, Cust] = useState([]);
  const [barcode, Barcode] = useState("");
  const [shipStatus, SetStatus] = useState("");
  const [startdate1, SetStart1] = useState("");
  const [startdate2, SetStart2] = useState("");
  const [startdate3, SetStart3] = useState("");
  const [enddate1, SetEnd1] = useState("");
  const [enddate2, SetEnd2] = useState("");
  const [enddate3, SetEnd3] = useState("");
  const [cod, COD] = useState("");
  const [awbCodeSta, setCodStatus] = useState("");
  const [barcodeNumber, BarcodeNumber] = useState("");
  const [bankTransferReceiptNumber, BankTransferReceiptNumber] = useState("");
  const [remark, Remark] = useState("");
  const [shipmentStatus, ShipmentStatus] = useState("");
  const [cod_cost, Cod_cost] = useState("");
  const [bank_id, Bank_id] = useState("");
  const [bookbank_code, Bookbank_code] = useState("");
  const [bookbank_name, Bookbank_name] = useState("");
  const [search_name, Search_name] = useState("");
  const [sender_name, Sender_name] = useState("");
  const [parcel_code, Parcel_Code] = useState("");
  const [setError, SetError] = useState("form-control");
  const [setErrorMass, SetErrorMass] = useState(0);
  const [setErrorMassNoData, SetErrorMassNoData] = useState(0);
  const [errorStartdate, SetErrorStart] = useState("form-control ");
  const [errorEnddate, SetErrorEnd] = useState("form-control ");
  const [errorStartMass, SetErrorStartMass] = useState(0);
  const [errorEndMass, SetErrorEndMass] = useState(0);
  const [errorMassSta, ErrorMassSta] = useState(0);
  const [loading, Loading] = useState(0);
  const [dateNow, setDateNow] = useState(1);
  const [printLebel, SetPrintLebel] = useState([]);
  const [receipt_number, Receipt_number] = useState("");
  const [file_receipt, File_receipt] = useState("");
  const [transfer_date,Transfer_date] = useState("");
  const [due_date,Due_date] = useState("");
  const [sender_phone,Sender_phone] = useState("");
  const [national_id,National_id] = useState("");
  const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
  const [tmp_text,Tmp_text] = useState("");
  const [files_receipt,Files_receipt] = useState([]);
  const [setData2, SetData2] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  async function fetchData(value) {
    Loading(1);
    // eslint-disable-next-line no-undef
    const result = await CODService(value);

    if (result != undefined) {
      if (result.data.length !== 0) {
      SetData(result.data);
        SetPage({
          current_page: result.pg[0].current_page??0,
          last_page: result.pg[0].last_page??0,
          total: result.pg[0].total??0,
        });
      }
      if (result.data.length !== 0) {
        SetError("form-control");
        SetErrorMass(0);
        SetErrorMassNoData(0);
      }
      if (result.data.length === 0) {
        SetcurrentPage(1);
        SetPage({
          current_page: 1,
          last_page: 1,
          total: 0,
        });
        SetErrorMassNoData(1);
      }
      if (result != null) {
        SetData(result.data);
      }
    } else {
      alert("Server Error");
    }
    Loading(0);
  }
  async function fetchData2(value) {
    Loading(1);
    // eslint-disable-next-line no-undef
    const result = await CODService(value);

    if (result != undefined) {
      if (result.data.length !== 0) {
        SetData2(result.data);
        document.getElementById("downloadEx1").click();        
      }
      if (result.data.length !== 0) {
        SetError("form-control");
        SetErrorMass(0);
        SetErrorMassNoData(0);
      }
      if (result.data.length === 0) {
        
        SetErrorMassNoData(1);
      }
      if (result != null) {
        SetData2(result.data);
      }
    } else {
      alert("Server Error");
    }
    Loading(0);
  }

  async function CustomerType(data) {
    const result = await CustomerTypeService(data);
    if (data != null) {
      Cust(result.data);
    }
  }
  async function uploadFileExcel(data){
    const resultUpdate = await uploadExcel(data);
    if (resultUpdate.res[0].status === 1 || resultUpdate.res[0].status === "1") {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      SetData([]);
      fetchData({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: 1,
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
      Data2([]);
      ShipmentStatus("");
    } else {
      swal({
        title: "อัพเดทไม่สำเร็จ",
        text: resultUpdate.res[0].message,
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 10000,
      });
    }
  }
  async function UploadReceiptFile(value){
    const resultUpdate = await UploadFile(value);
    if (resultUpdate.res[0].status == 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      SetData([]);
      fetchData({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: 1,
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
      Data2([]);
      ShipmentStatus("");
    } else {
      swal({
        title: "อัพเดทไม่สำเร็จ",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
    // alert(JSON.stringify(resultUpdate));
  }
  async function UploadMultipleReceiptFile(value){
    const resultUpdate = await UploadFileForMultiple(value);
    if (resultUpdate.res[0].status == 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      SetData([]);
      fetchData({
        isManage: true,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: 1,
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
      Data2([]);
      ShipmentStatus("");
    } else {
      swal({
        title: "อัพเดทไม่สำเร็จ",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
    //alert(JSON.stringify(resultUpdate));
  }
  async function UpdateTransferReceiptCOD(value){
    const resultUpdate = await UpdateTransferReceipt(value);
    if (resultUpdate.res[0].status == 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      SetData([]);
      fetchData({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: 1,
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
      Data2([]);
      ShipmentStatus("");
    } else {
      swal({
        title: "อัพเดทไม่สำเร็จ",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }

  }
  async function UpdateMultipleTransferReceiptCOD(value){
    const resultUpdate = await UpdateMultipleTransferReceipt(value);
    if (resultUpdate.res[0].status == 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      SetData([]);
      fetchData({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: 1,
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
      Data2([]);
      ShipmentStatus("");
    } else {
      swal({
        title: "อัพเดทไม่สำเร็จ",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }

  }
  async function UpdateRemark(value){
    const resultUpdate = await UpdateRemarkService(value);
    //alert(JSON.stringify(resultUpdate));
    if (resultUpdate.res[0].status == 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      SetData([]);
      fetchData({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: 1,
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
      Data2([]);
      ShipmentStatus("");
    } else {
      swal({
        title: "อัพเดทไม่สำเร็จ",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
  }

  async function UpdateBarcode(value) {
    const resultUpdate = await BarcodeUpdateService(value);
    if (resultUpdate.res[0].status == 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      SetData([]);
      fetchData({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: 1,
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
      Data2([]);
      ShipmentStatus("");
    } else {
      swal({
        title: "อัพเดทไม่สำเร็จ",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
  }

  async function UpdateCOD(value) {
    const resultUpdate = await CODUpdateService(value);
    if (resultUpdate.res[0].status == 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      SetData([]);
      fetchData({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: 1,
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    } else {
      swal({
        title: "อัพเดทไม่สำเร็จ",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
  }

  const SubmitUpdateCOD = () => {
    if (cod_cost != "" && bank_id != "" && bookbank_code != "" && bookbank_name != "") {
      UpdateCOD({
        awb_code: barcodeNumber,
        cod_cost: cod_cost,
        bank_id: bank_id,
        bookbank_code: bookbank_code,
        bookbank_name: bookbank_name,
      });
    }
  };

  async function handleSubmit2Excel(){
    SetPrintLebel([]);
    Data2([]);
    SetcurrentPage(1);
    SetData2([]);
    SetError("form-control");
    SetErrorMass(0);

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate ไม่เท่ากับ ""
    if (startdate1 != "" && startdate2 != "" && startdate3 != "" && enddate1 != "" && enddate2 != "" && enddate3 != "") {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStartMass(0);
      fetchData2({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate เท่ากับ ""
    else if (
      startdate1 != "" &&
      startdate2 != "" &&
      startdate3 != "" &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData2({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //ค้นหาเมื่อ EndDate ไม่เท่ากับ "" และ startDate เท่ากับ ""
    else if (
      enddate1 != "" &&
      enddate2 != "" &&
      enddate3 != "" &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      fetchData2({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ "" และ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      (enddate1 != "" || enddate2 != "" || enddate3 != "")
    ) {
      SetData2([]);
      if (startdate1 == "" || startdate2 == "" || startdate3 == "") {
        SetErrorStart("dropdown-custom form-control is-invalid");
        SetErrorStartMass(1);
      }
      if (enddate1 == "" || enddate2 == "" || enddate3 == "") {
        SetErrorEnd("dropdown-custom form-control is-invalid");
        SetErrorEndMass(1);
      }
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ EndDate เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetData2([]);
      SetErrorStart("dropdown-custom form-control is-invalid");
      SetErrorStartMass(1);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
    }

    //แสดง validation เมื่อ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ startDate เท่ากับ ""
    else if (
      (enddate1 != "" || enddate2 != "" || enddate3 != "") &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetData2([]);
      SetErrorEnd("dropdown-custom form-control is-invalid");
      SetErrorEndMass(1);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
    } else {
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData2({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: "",
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }
  };

  function clear() {
    setDateNow(1);
    ErrorMassSta(0);
    SetErrorStartMass(0);
    SetErrorEndMass(0);
    SetErrorEnd("form-control");
    SetErrorStart("form-control");
    SetErrorMassNoData(0);
    SetStart1("");
    SetStart2("");
    SetStart3("");
    SetEnd1("");
    SetEnd2("");
    SetEnd3("");
    SetError("form-control");
    SetErrorMass(0);
    SetData([]);
    Data2([]);
    Cust_code([]);
    Cust([]);
    SetPrintLebel([]);
    Customer_type("");
    COD("");
    Barcode("");
    SetStatus("");
    SetcurrentPage(1);
    PageSizes(10);
    SetPage({
      current_page: 1,
      last_page: 1,
      total: 0,
    });
  }

  /** ค้นหาข้อมูล */
  const handleSubmit = () => {
    SetPrintLebel([]);
    Data2([]);
    SetcurrentPage(1);
    SetData([]);
    SetError("form-control");
    SetErrorMass(0);

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate ไม่เท่ากับ ""
    if (startdate1 != "" && startdate2 != "" && startdate3 != "" && enddate1 != "" && enddate2 != "" && enddate3 != "") {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStartMass(0);
      fetchData({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //ค้นหาเมื่อ startDate ไม่เท่ากับ "" และ EndDate เท่ากับ ""
    else if (
      startdate1 != "" &&
      startdate2 != "" &&
      startdate3 != "" &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorStart("form-control");
      SetErrorMassNoData(0);
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //ค้นหาเมื่อ EndDate ไม่เท่ากับ "" และ startDate เท่ากับ ""
    else if (
      enddate1 != "" &&
      enddate2 != "" &&
      enddate3 != "" &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetError("form-control");
      SetErrorMass(0);
      SetErrorEnd("form-control");
      SetErrorMassNoData(0);
      SetErrorEndMass(0);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      fetchData({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ "" และ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      (enddate1 != "" || enddate2 != "" || enddate3 != "")
    ) {
      SetData([]);
      if (startdate1 == "" || startdate2 == "" || startdate3 == "") {
        SetErrorStart("dropdown-custom form-control is-invalid");
        SetErrorStartMass(1);
      }
      if (enddate1 == "" || enddate2 == "" || enddate3 == "") {
        SetErrorEnd("dropdown-custom form-control is-invalid");
        SetErrorEndMass(1);
      }
    }

    //แสดง validation เมื่อ startDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ EndDate เท่ากับ ""
    else if (
      (startdate1 != "" || startdate2 != "" || startdate3 != "") &&
      enddate1 == "" &&
      enddate2 == "" &&
      enddate3 == ""
    ) {
      SetData([]);
      SetErrorStart("dropdown-custom form-control is-invalid");
      SetErrorStartMass(1);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
    }

    //แสดง validation เมื่อ EndDate ตัวไดตัวหนึ่ง ไม่เท่ากับ ""  และ startDate เท่ากับ ""
    else if (
      (enddate1 != "" || enddate2 != "" || enddate3 != "") &&
      startdate1 == "" &&
      startdate2 == "" &&
      startdate3 == ""
    ) {
      SetData([]);
      SetErrorEnd("dropdown-custom form-control is-invalid");
      SetErrorEndMass(1);
      SetErrorStart("form-control");
      SetErrorStartMass(0);
    } else {
      SetErrorStart("form-control");
      SetErrorStartMass(0);
      SetErrorEnd("form-control");
      SetErrorEndMass(0);
      fetchData({
        isManage: isManage,
        search: barcode != "" ? barcode : "",
        shipment_status: shipStatus != "" ? shipStatus : "",
        start_date:
          startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
        page: "1",
        number_list: pageSizes,
        cust_code: cust_code != "" ? cust_code[0].cust_code : "",
        cod_status: cod != "" ? cod : "",
        search_name: search_name != "" ? search_name : "",
        national_id: national_id ? national_id : "",
        sender_phone: sender_phone != "" ? sender_phone : ""
      });
    }
  };

  const SubmitUpdateStatus = () => {
    if (shipmentStatus != "") {
      var data = [
        {
          awb_code: barcodeNumber,
          shipment_status: shipmentStatus,
        },
      ];
      UpdateBarcode(data);
    }
  };

  const handleChangePage = (event, newPage) => {
    SetData([]);
    SetcurrentPage(newPage);
    fetchData({
      isManage: isManage,
      search: barcode != "" ? barcode : "",
      shipment_status: shipStatus != "" ? shipStatus : "",
      start_date:
        startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
      end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
      page: newPage,
      number_list: pageSizes,
      cust_code: cust_code != "" ? cust_code[0].cust_code : "",
      cod_status: cod != "" ? cod : "",
      search_name: search_name != "" ? search_name : "",
      national_id: national_id ? national_id : "",
      sender_phone: sender_phone != "" ? sender_phone : ""
    });
  };

  const checked = () => {
    data2.map((item, i) => {
      if (document.getElementById("flexCheckDefault" + item.awb_code) != null) {
        document.getElementById("flexCheckDefault" + item.awb_code).checked = true;
      }
    });
  };

  function pageSize(data) {
    SetData([]);
    SetcurrentPage(1);
    PageSizes(data);
    fetchData({
      isManage: isManage,
      search: barcode != "" ? barcode : "",
      shipment_status: shipStatus != "" ? shipStatus : "",
      start_date:
        startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
      end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
      page: "1",
      number_list: data,
      cust_code: cust_code != "" ? cust_code[0].cust_code : "",
      cod_status: cod != "" ? cod : "",
      search_name: search_name != "" ? search_name : "",
      national_id: national_id ? national_id : "",
      sender_phone: sender_phone != "" ? sender_phone : ""
    });
  }

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [showMenager, setShowMenager] = useState(false);
  const handleCloseMenager = () => setShowMenager(false);
  const handleShowMenager = () => setShowMenager(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [showTransfer, setShowTransfer] = useState(false);
  const handleCloseTransfer = () => setShowTransfer(false);
  const handleShowTransfer = () => setShowTransfer(true);

  const [showDeadline, setShowDeadline] = useState(false);
  const handleCloseDeadline = () => setShowDeadline(false);
  const handleShowDeadline = () => setShowDeadline(true);

  const [showNotTransfered, setShowNotTransfered] = useState(false);
  const handleCloseNotTransfered = () => setShowNotTransfered(false);
  const handleShowNotTransfered = () => setShowNotTransfered(true);

  const [showMultiple, setShowMultiple] = useState(false);
  const handleCloseMultiple = () => setShowMultiple(false);
  const handleShowMultiple = () => setShowMultiple(true);


  //use for cod_status button
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  function dataArray(data) {
    let check = document.getElementById("flexCheckDefault" + data).checked;
    if (check) {
      Data2((t) =>
        t.concat({
          code: data,
          receipt_number: receipt_number,
          bookbank_code: bookbank_code,
          transfer_date: transfer_date,
          cod_cost: cod_cost,
        })
      );
    } else {
      let array = data2;
      Data2([]);
      array.map((row, index) => {
        if (row.code !== data) {
          Data2((t) =>
            t.concat({
              code: parcel_code,
              receipt_number: receipt_number,
              bookbank_code: bookbank_code,
              transfer_date: transfer_date,
              cod_cost: cod_cost,
            })
          );
        }
      });
    }
  }

  const SubmitUpdateStatusMuti = () => {
    if (shipmentStatus != "" && data2.length != 0) {
      var i;
      for (i = 0; i < data2.length; i++) {
        data2[i].shipment_status = shipmentStatus;
      }
      UpdateBarcode(data2);
    }
  };

  function addPrints(value) {
    if (printLebel.length === 0 || printLebel.filter((a) => a.awb_code === value.awb_code).length === 0) {
      let data = [...printLebel, { ...value }];
      SetPrintLebel(data);
    } else {
      let data = [...printLebel];
      SetPrintLebel([...data.filter((a) => a.awb_code !== value.awb_code)]);
    }
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="container-fluid mt-2 mb-2">
            <div className="text-center text-title-color">
              <h2>จัดการCOD</h2>
            </div>
            <br />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                SetData([]);
                handleSubmit();
              }}
            >
              <div className="row justify-content-md-center">
                <div className="col-sm-3 col-md-3 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <select
                      value={customer_type}
                      onChange={(e) => {
                        Customer_type(e.target.value);
                        CustomerType({ customer_type: e.target.value });
                      }}
                      className={"dropdown-custom2" + " " + setError}
                    >
                      {" "}
                      <option value={""}>{"ประเภทลูกค้า"}</option>
                      <option value={"2"}>{"เครดิต"}</option>
                      <option value={"3"}>{"หน้าร้าน"}</option>
                    </select>
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-3 col-md-3 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      onChange={(e, v) => {
                        Cust_code(e);
                      }}
                      options={cust}
                      getOptionLabel={(item) => item.name}
                      placeholder="ชื่อ"
                      selected={cust_code}
                      value={cust_code}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="col-sm-4 col-md-3 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      value={search_name}
                      onChange={(e) => Search_name(e.target.value)}
                      id="clear"
                      placeholder="ค้นหาจากชื่อ"
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div> */}

                <div className="col-sm-4 col-md-3 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      value={barcode}
                      onChange={(e) => Barcode(e.target.value)}
                      id="clear"
                      placeholder="ค้นหาบาร์โค้ด"
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-3 col-md-3 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <select
                      value={shipStatus}
                      onChange={(e) => {
                        SetStatus(e.target.value);
                      }}
                      className={"dropdown-custom2" + " " + setError}
                      placeholder="-- กรุณาเลือกสถานะ --"
                    >
                      <option style={{ color: "#000000" }} value={""}>
                        {"ทั้งหมด"}
                      </option>
                      {cod_status_list.map((item) => (
                        <option value={item.idcode} key={nanoid()}>
                          {item.id  + "." + " " + item.StatusName}
                        </option>
                      ))}
                      <option value='15' key={nanoid()}>
                          {"4. จ่ายแล้วแต่ไม่มีลายชื่อเซ็นรับ" }
                      </option>
                      <option value='20' key={nanoid()}>
                          {"5. อยู่ระหว่างขนส่ง" }
                      </option>
                    </select>
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <br />
              <br />
              <div className="row justify-content-md-center">
              <div className="col-sm-6 col-md-6 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                    //   mila ovaina ny name an attribute eto
                      value={national_id}
                      onChange={(e) => National_id(e.target.value)}
                      id="clear"
                      placeholder="หาจากเลขบัตรประชาชน"
                    //   search by national ID
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6 col-md-6 px-md-1 mt-1">
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      //   mila ovaina ny name an attribute eto
                      value={sender_phone}
                      onChange={(e) => Sender_phone(e.target.value)}
                      id="clear"
                      placeholder="หาจากเบอร์โทรศัพท์"
                    //   SEARCH BY PHONE NUM
                      className={setError}
                    />
                  </div>
                  {setErrorMass == 1 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณากรอกข้อมูล{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>
              </div>
                <br />

              <div className="row justify-content-md-center">
                <div className="col-12 col-lg-2">
                  <br />
                  <center style={{ marginTop: "10px" }}>
                    
                    <div className="form-check">
                      <input
                        checked={isManage ? false : true}
                        style={{ width: "15px", height: "15px" }}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={(e) => {
                          if (!isManage) {
                            setIsManage(true);
                          } else {
                            setIsManage(false);
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="flexCheckDefault" style={{ marginLeft: "10px" }}>
                      ค้นหาโดยวันที่สร้างพัสดุฯ
                      </label>
                    </div>
                  </center>
                </div>
                <div className="col-12 col-lg-2">
                  <br />
                  <center style={{ marginTop: "10px" }}>
                    
                    <div className="form-check">
                      <input
                        checked={dateNow === 1 ? false : true}
                        style={{ width: "15px", height: "15px" }}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={(e) => {
                          if (dateNow === 1) {
                            setDateNow(0);
                            SetStart1(year);
                            SetStart2(("0" + month).slice(-2));
                            SetStart3(date);
                            SetEnd1(year);
                            SetEnd2(("0" + month).slice(-2));
                            SetEnd3(date);
                          } else {
                            setDateNow(1);
                            SetStart1("");
                            SetStart2("");
                            SetStart3("");
                            SetEnd1("");
                            SetEnd2("");
                            SetEnd3("");
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="flexCheckDefault" style={{ marginLeft: "10px" }}>
                        วันที่ปัจจุบัน
                      </label>
                    </div>
                  </center>
                </div>
                <div className="col-12 col-lg-4">
                  <span>ตั้งแต่วันที่เปิดบิล</span>
                  <div className="row">
                    <div className="col-12 col-sm-4 col-md-4 px-md-1 mt-1">
                      <select
                        className={errorStartdate}
                        value={startdate1}
                        onChange={(e) => {
                          SetStart1(e.target.value);
                        }}
                      >
                        <option value=""> -- ปี -- </option>
                        {years.map((year, index) => (
                          <option key={`year${index}`} value={year}>
                            {year + 543}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                      <select
                        className={errorStartdate}
                        value={startdate2}
                        onChange={(e) => {
                          SetStart2(e.target.value);
                        }}
                      >
                        <option value=""> -- เดือน -- </option>
                        {monthTH.map((item, index) => (
                          <option value={item.id} key={item.id}>
                            {item.monthName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                      <select
                        className={errorStartdate}
                        value={startdate3}
                        onChange={(e) => {
                          SetStart3(e.target.value);
                        }}
                      >
                        <option value=""> -- วัน -- </option>
                        {Array.from(Array(31), (e, i) => (
                          <option value={i + 1}>{i + 1}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {errorStartMass == 1 && errorMassSta != 1 && errorMassSta != 2 && errorMassSta != 3 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณาเลือก ปี, เดือน, วัน{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 col-lg-4">
                  <span>ถึงวันที่ปิดบิล</span>
                  <div className="row">
                    <div className="col-12 col-sm-4 px-md-1 mt-1">
                      <select
                        className={errorEnddate}
                        value={enddate1}
                        onChange={(e) => {
                          SetEnd1(e.target.value);
                        }}
                      >
                        <option value=""> -- ปี -- </option>
                        {years.map((year, index) => (
                          <option key={`year${index}`} value={year}>
                            {year + 543}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4  px-md-1 mt-1">
                      <select
                        className={errorEnddate}
                        value={enddate2}
                        onChange={(e) => {
                          SetEnd2(e.target.value);
                        }}
                      >
                        <option value=""> -- เดือน -- </option>
                        {monthTH.map((item, index) => (
                          <option value={item.id} key={item.id}>
                            {item.monthName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-4  px-md-1 mt-1">
                      <select
                        className={errorEnddate}
                        value={enddate3}
                        onChange={(e) => {
                          SetEnd3(e.target.value);
                        }}
                      >
                        <option value=""> -- วัน -- </option>
                        {Array.from(Array(31), (e, i) => (
                          <option value={i + 1} key={i}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {errorEndMass == 1 && errorMassSta != 1 && errorMassSta != 2 && errorMassSta != 3 ? (
                    <font color="red" size="2">
                      {" "}
                      กรุณาเลือก ปี, เดือน, วัน{" "}
                    </font>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 col-lg px-md-1 mt-0" style={{ display: "none" }}>
                  <br />
                  <center style={{ marginTop: "10px;" }}>
                    <div className="form-check form-check-inline">
                      <input
                        checked={true}
                        style={{ width: "15px", height: "15px" }}
                        className="form-check-input"
                        type="checkbox"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="1"
                        // onChange={(e) => {
                        //   if (cod !== "1" ? true : false) {
                        //     COD(e.target.value);
                        //   } else {
                        //     COD("");
                        //   }
                        // }}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio1">
                        มี COD
                      </label>
                    </div>
                  </center>
                </div>
              </div>
              <br />
              <center>
                <i className="bi bi-search"></i>
                <button
                  disabled={loading == 1 ? true : false}
                  type="submit"
                  className={loading == 1 ? "btn btn-secondary" : "btn btn-primary"}
                >
                  <Icon.Search /> ค้นหา
                </button>
                <span>&nbsp;</span>
                <button type="button" className="btn btn-secondary" onClick={clear}>
                  <Icon.ArrowClockwise /> ล้างค่า
                </button>
              </center>
            </form>
          </div>
        </div>
      </div>
      <div>
        <>
          <div style={{ display: "none" }}>
            <div id="labelNormal">
              {printLebel.map((value, index) => (
                ////ใช้สำหรับพิมพ์ลาเบลเป็นชุด
                <div key={nanoid()}>
                  {value.cod_cost === "0" ? (
                    <FormatLabel
                      key={nanoid()}
                      supplies={{
                        custCode: value.cust_code,
                        number: index + 1,
                        receiverName: value.receiver_name,
                        awbCode: value.awb_code,
                        date: date,
                        month: month,
                        year: year,
                        weight: value.weight,
                        codCost: value.cod_cost,
                        deposit_type: value.deposit_type,
                        deposit_fullname: value.deposit_fullname,
                        deposit_phone: value.deposit_phone,
                        ref_no: value.ref_no,
                      }}
                    />
                  ) : (
                    <FormatLabelCod
                      key={nanoid()}
                      supplies={{
                        custCode: value.cust_code,
                        number: index + 1,
                        receiverName: value.receiver_name,
                        awbCode: value.awb_code,
                        date: date,
                        month: month,
                        year: year,
                        weight: value.weight,
                        codCost: value.cod_cost,
                        deposit_type: value.deposit_type,
                        deposit_fullname: value.deposit_fullname,
                        deposit_phone: value.deposit_phone,
                        ref_no: value.ref_no,
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
        <>
          <div className="contentPrint2" style={{ display: "none" }}>
            <div id="labelAddress">
              {printLebel.map(
                (
                  value,
                  index ////ใช้สำหรับพิมพ์ลาเบล/ที่อยู่เป็นชุด
                ) => (
                  <div key={nanoid()}>
                    {value.cod_cost === "0" ? (
                      <FormatLabelAddress
                        key={nanoid()}
                        supplies={{
                          receiver_name: value.receiver_name,
                          receiver_address: value.receiver_address,
                          receiver_amphur: value.receiver_amphur,
                          receiver_province: value.receiver_province,
                          receiver_phone: value.receiver_phone,
                          cust_Code: value.cust_code,
                          number: index + 1,
                          awb_code: value.awb_code,
                          date: date,
                          month: month,
                          year: year,
                          weight: value.weight,
                          codCost: value.cod_cost,
                          deposit_type: value.deposit_type,
                          deposit_fullname: value.deposit_fullname,
                          deposit_phone: value.deposit_phone,
                          sender_name: value.sender_name,
                          ref_no: value.ref_no,
                        }}
                      />
                    ) : (
                      <FormatLabelAddressCod
                        key={nanoid()}
                        supplies={{
                          receiver_name: value.receiver_name,
                          receiver_address: value.receiver_address,
                          receiver_amphur: value.receiver_amphur,
                          receiver_province: value.receiver_province,
                          receiver_phone: value.receiver_phone,
                          cust_Code: value.cust_code,
                          number: index + 1,
                          awb_code: value.awb_code,
                          date: date,
                          month: month,
                          year: year,
                          weight: value.weight,
                          codCost: value.cod_cost,
                          deposit_type: value.deposit_type,
                          deposit_fullname: value.deposit_fullname,
                          deposit_phone: value.deposit_phone,
                          sender_name: value.sender_name,
                          ref_no: value.ref_no, 
                        }}
                      />
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </>
      </div>
      {setData.length !== 0 || setErrorMassNoData === 1 ? (
        <div className="mt-4">
          <div className="displayFlex justify-content-between">
            <div className="form-group">
              {/* <span> */}
                <button
                  type="button"
                  className={data2.length == 0 ? "btn btn-secondary" : "btn btn-primary"}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={(e) => {
                    handleShowMultiple(e);
                    ShipmentStatus("");
                  }}
                  disabled={data2.length == 0 ? true : false}
                >
                  จัดการสถานะหลายรายการ
                </button> {" "}
                
                <a className="btn btn-primary mr-2" href={IMPORT_DATA} download="updateCOD.xlsx">
                  <Icon.CloudDownloadFill className="mr-2" size="15" />
                  ดาวน์โหลดเทมเพลด
                </a>
                <button
                  type="button"
                  className="btn btn-info mx-1"
                  onClick={(e) =>{
                    document.getElementById("upfile2").click();                    //excel import
                  }}
                >
                  <Icon.CloudUploadFill className="mr-2" size="15" />
                  อัพโหลดข้อมูล
                </button>
                <input
                  hidden
                  type="file"
                  className="btn btn-success mx-1"
                  name="upfile2"
                  id="upfile2"
                  onChange={
                    (e)=>{
                      const formData = new FormData();
                      formData.append('submit','Upload');
                      formData.append('uploadFile',e.target.files[0]);
                      uploadFileExcel(formData);
                    }
                  }
                />
              {/* </span> */}
              <span>
                <button className="btn btn btn-success mx-1" 
                      onClick={ async (e)=>{
                        await handleSubmit2Excel();

                      }}>
                      <Icon.FileEarmarkArrowUp /> export</button>
                </span> {" "}
            </div>
            <div>
              <select
                value={pageSizes}
                className="form-control "
                aria-label="Default select example"
                onChange={(e) => {
                  pageSize(e.target.value);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          <ExcelFile
              element={
                // <button style={{ display: "none" }} id="downloadEx">
                <button style={{display: "none"}} className="btn btn-primary" id="downloadEx1">
                  Download Data
                </button>
              }
              filename="CODรายงาน"
            >
              <ExcelSheet data={setData2} name={"CODรายงาน"}>
                <ExcelColumn label="เลขลาเบล" value="awb_code" />
                <ExcelColumn label="สำหรับลูกค้า" value="ref_no" />
                <ExcelColumn label="ว/ด/ป ที่โอนเงินคืนลูกค้า" value="created_date" width={{ wpx: 300 }}/>
                <ExcelColumn label="รหัสลูกค้า" value="cust_code" />
                <ExcelColumn label="ข้อมูลผู้ส่ง" value="sender_name" />
                <ExcelColumn label="หมายเลขโทรศัพท์" value="sender_phone" width={{ wpx: 80 }} /> 
                <ExcelColumn label="จำนวนเงินCOD" value="cod_cost" /> 
                <ExcelColumn label="ธนาคาร" value="bank_id" />
                <ExcelColumn label="หมายเลขบัญชี" value="bookbank_code" />
                <ExcelColumn label="ชื่อบัญชี" value="bookbank_name" />
                <ExcelColumn label="ข้อมูลผู้รับ" value="receiver_name" />
                <ExcelColumn label="เบอร์โทรผู้รับ" width={{ wpx: 160 }} value="receiver_phone" />
                <ExcelColumn label="ที่อยู่" value="receiver_address" />
                <ExcelColumn label="อำเภอ" value="receiver_amphur" />
                <ExcelColumn label="จังหวัด" value="receiver_province" />
                <ExcelColumn label="วันที่จัดส่งพัสดุฯสำเร็จ" value="receiver_date"/>
                <ExcelColumn label="ครบกำหนด" value="due_date" />  
                <ExcelColumn label="สถานะ COD" value="cod_transaction_status_name" />
                <ExcelColumn label="หมายเหตุ" value="remarkMg" />
              </ExcelSheet>
            </ExcelFile>

          <div style={{ overflow: "Auto" }}>
            <table className="table table-bordered">
              <thead style={{ backgroundColor: "#3f75c1" }}>
                <tr style={{ color: "#FFFFFF" }}>
                  <td>#</td>
                  <td>หมายเลขโทรศัพท์</td>
                  <td>วันที่ได้รับ</td>
                  <td>ข้อมูลผู้ส่ง</td>
                  <td>บาร์โค้ด/สำหรับลูกค้า</td>
                  <td>จำนวนเงินCOD</td>
                  <td>หมายเหตุ</td>
                  <td>สถานะ COD</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {setData.map((value, index) => (
                  <tr key={index+value.awb_code+"data"}>
                    <td>
                      <center>
                        <div className="form-check">
                          <input
                            disabled={value.awb_code == null ? true : false}
                            style={{ width: "18px", height: "18px" }}
                            onChange={(e) => {
                              if (value.code != null) {
                                dataArray(value.code);
                                addPrints(value);
                              }
                               else {
                                dataArray(value.code);
                                addPrints(value);
                              }
                            }}
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id={
                              "flexCheckDefault" + value.code
                            }
                          />
                        </div>
                      </center>
                    </td>
                    <td>{value.sender_phone}</td>
                    <td>
                    {value.receiver_date !== '' ? (
                      <DateTH date={value.receiver_date} />
                    ):""}
                      
                    </td>
                    <td>
                      <p>{value.bookbank_name}</p>
                      {bank.map((data) => (
                        data.bank_id === value.bank_id ? " "+data.bankName+" ":""
                    ))}
                      <p>{value.bookbank_code}</p>
                      
                    </td>
                    <td>
                      <p>{value.ref_no === null || value.ref_no === '0' ? " " : value.ref_no}</p>
                      <p>{value.awb_code}</p>
                    </td>
                    <td>{value.cod_cost}</td>
                    <td>{value.remarkMg}</td>
                    <td align="center">
                      <br />
                      {value.due_date===null?(
                                    <span>
                                    {"รอจัดส่ง"}
                                    </span>
                                ):("")
                                }
                      {value.shipment_status==="5" && (value.cod_transaction_status === "10" || value.cod_transaction_status === 10)  ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={(e) => {
                            // handleClose2(e);
                            // ShipmentStatus("");
                            Due_date(value.due_date);
                            Parcel_Code(value.code);
                            Sender_name(value.sender_name);
                            Bookbank_code(value.bookbank_code);
                            Bookbank_name(value.bookbank_name);
                            Bank_id(value.bank_id);
                            Transfer_date(value.bank_transfer_date);
                            Cod_cost(value.cod_cost);
                            Receipt_number(value.bank_transfer_receipt);
                            Remark(value.remarks);
                            handleShowTransfer(e);
                            if (value.awb_code != null) {
                              BarcodeNumber(value.awb_code);
                              setCodStatus("1");
                            } else {
                              BarcodeNumber(value.ref_no);
                              setCodStatus("");
                            }

                          }}
                        >
                          โอนแล้ว
                        </button>

                      ) : (
                        ""
                      )}
                      {value.shipment_status!=="3" && Date.parse(value.due_date) >= Date.now() && value.cod_transaction_status !== "10"  ? (
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={(e) => {
                            // handleClose2(e);
                            // ShipmentStatus("");
                            handleShowDeadline(e);
                            Parcel_Code(value.code);
                            Remark(value.remarks);
                            Due_date(value.due_date);
                            Sender_name(value.sender_name);
                            Bookbank_code(value.bookbank_code);
                            Bookbank_name(value.bookbank_name);
                            Bank_id(value.bank_id);
                            Transfer_date(value.bank_transfer_date);
                            Cod_cost(value.cod_cost);
                            if (value.awb_code != null) {
                              BarcodeNumber(value.awb_code);
                              setCodStatus("1");
                            } else {
                              BarcodeNumber(value.ref_no);
                              setCodStatus("");
                            }
                          }}
                        >
                          ถึงกำหนดโอน
                        </button>

                      ) : (
                        ""
                        )}
                      { //paid but in transit
                        value.shipment_status!=="5" 
                        && value.cod_transaction_status === "10" ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={(e) => {
                            
                          }}
                        >
                          จ่ายแล้วแต่ไม่มีลายชื่อเซ็นรับ
                        </button>

                      ) : (
                        ""
                      )}
                      { //not paid but in transit
                        value.shipment_status==="3" 
                        && value.cod_transaction_status !== "10" ? (
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={(e) => {
                            
                          }}
                        >
                          อยู่ระหว่างขนส่ง
                        </button>

                      ) : (
                        ""
                      )}
                        {value.shipment_status!=="3" && (value.cod_transaction_status == "1" || (Date.parse(value.due_date) < Date.now() && value.cod_transaction_status != "10") )? (
                          <button
                          type="button"
                          className="btn btn-danger"
                          onClick={(e) => {
                            // handleClose2(e);
                            // ShipmentStatus("");

                            Due_date(value.due_date);
                            Parcel_Code(value.code);
                            Remark(value.remarks);
                            Sender_name(value.sender_name);
                            Bookbank_code(value.bookbank_code);
                            Bookbank_name(value.bookbank_name);
                            Bank_id(value.bank_id);
                            Transfer_date(value.bank_transfer_date);
                            Cod_cost(value.cod_cost);
                            handleShowNotTransfered(e);
                            if (value.awb_code != null) {
                              BarcodeNumber(value.awb_code);
                              setCodStatus("1");
                            } else {
                              BarcodeNumber(value.ref_no);
                              setCodStatus("");
                            }
                          }}
                        >
                          ยังไม่ได้โอน
                        </button>

                      ) : (
                        ""
                        )}
                      </td>
                    <td align="center" style={{ width: "170px" }}>
                      
                      <br />
                      <button
                        style={{ width: "100%" }}
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => {
                          Remark(value.remarks);
                          Parcel_Code(value.code);
                          Receipt_number(value.bank_transfer_receipt);
                          BankTransferReceiptNumber(value.bank_transfer_receipt);
                          Sender_name(value.sender_name);
                          handleShowMenager(e);
                          ShipmentStatus(value.shipment_status);
                          Cod_cost(value.cod_cost);
                          Bank_id(value.bank_id);
                          Bookbank_code(value.bookbank_code);
                          Bookbank_name(value.bookbank_name);
                          if (value.awb_code != null) {
                            BarcodeNumber(value.awb_code);
                            setCodStatus("1");
                          } else {
                            BarcodeNumber(value.ref_no);
                            setCodStatus("");
                          }
                        }}
                      >
                        จัดการ
                      </button>
                    </td>
                    {setData.length == index + 1 ? checked() : ""}
                  </tr>
                ))}
              </tbody>
            </table>
            <center>
              {setErrorMassNoData == 1 ? (
                <font color="red" size="3">
                  {" "}
                  ไม่พบข้อมูล!{" "}
                </font>
              ) : (
                ""
              )}
            </center>
          </div>
          <div className="d-flex justify-content-between">
            <div>จำนวนทั้งหมด {setPage.total} รายการ</div>
            <div>
              <Pagination
                count={parseInt(setPage.last_page)}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
                size="small"
                defaultPage={6}
                siblingCount={1}
              />
            </div>
          </div>
          <br />
        </div>
      ) : loading === 1 ? (
        <div className="m-5">
          <div className="text-center">
            <div className="spinner-border  text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            {" ..."}
            กำลังโหลดข้อมูล
          </div>
        </div>
      ) : (
        ""
      )}

      <br />
      <br />

      {/* Modal for transfered */}
      <Modal show={showTransfer} onHide={handleCloseTransfer}>
        <Modal.Header closeButton>
          <Modal.Title> หลักฐานการโอน <br /> บาร์โค้ด : {barcodeNumber} </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "19px" }}>
          <div className="form-check">
            โอนไปที่: {bookbank_name}
            <br />
            เลขบัญชี: {bookbank_code}
            <br />
            ธนาคาร: {bank.map((value) => (
                        value.bank_id === bank_id ? " "+value.bankName+" ":""
                    ))}
            <br /><br />
            เลขที่โอน: {receipt_number}
            <br />
            วันที่: {transfer_date}
          </div>
        </Modal.Body>
      </Modal>

       {/* Modal for deadline */}
       <Modal show={showDeadline} onHide={handleCloseDeadline}>
        <Modal.Header closeButton>
          <Modal.Title> หลักฐานการโอน <br /> บาร์โค้ด : {barcodeNumber} </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "19px" }}>
          <div className="form-check">
            ครบกำหนด: {due_date.substring(0,10)}
            <br />
            ชื่อบัญชี: {bookbank_name}
            <br />
            เลขบัญชี: {bookbank_code}
            <br />
            ธนาคาร: {bank.map((value) => (
                        value.bank_id === bank_id ? " "+value.bankName+" ":""
                    ))}
            <br />
            เก็บเงินปลายทาง: {cod_cost}
          </div>
          <div align="right">
            <button
                  // disabled={shipmentStatus == "" ? true : false}
                  type="button"
                  className={"btn btn-primary"}
                  onClick={(e) => {
                    handleShow3(e);
                  }}
                >
                  อัพโหลดหลักฐานการโอน
                </button>

          </div>
        </Modal.Body>
      </Modal>

      {/* Modal for Not tansfered */}
        <Modal show={showNotTransfered} onHide={handleCloseNotTransfered}>
        <Modal.Header closeButton>
          <Modal.Title> หลักฐานการโอน <br /> บาร์โค้ด : {barcodeNumber} </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "19px" }}>
          <div className="form-check">
            ครบกำหนด:    <span style={{color:'red'}}>{due_date.substring(0,10)}</span>
            <br />
            ชื่อบัญชี: {bookbank_name}
            <br />
            เลขบัญชี: {bookbank_code}
            <br />
            ธนาคาร: {bank.map((value) => (
                        value.bank_id === bank_id ? " "+value.bankName+" ":""
                    ))}
            <br />
            เก็บเงินปลายทาง: {cod_cost}
            <br />
            <div className="">
                  <label style={{color:'red'}}>หมายเหตุ:</label>
                  <br/>
                  <input type={"hidden"}
                    name={"code"}
                    value={parcel_code}
                  />
                  <textarea style={{
                    width: "100%",
                  }}
                  name={"remark"}
                  onChange={(e) => {
                      Remark(e.target.value);
                    }}>
                   {remark}
                  </textarea>
                </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
                <button
                  type="button"
                  className={
                    "btn btn-primary"
                  }
                  onClick={(e) => {

                    UpdateRemark({
                      code: parcel_code,
                      remark: remark
                    });
                    const formData = new FormData();
                    formData.append('file',file_receipt);
                    formData.append('code',parcel_code);
                    formData.append('employee',localStorage.getItem('employee_number'));
                    UploadReceiptFile(
                      formData
                    );

                  }}
                >
                  บันทึก
                </button>
                <button
                  // disabled={shipmentStatus == "" ? true : false}
                  type="button"
                  className={"btn btn-primary"}
                  onClick={(e) => {
                    handleShow3(e);

                    // handleCloseMenager(e);
                    // SubmitUpdateStatus();
                  }}
                >
                  อัพโหลดหลักฐานการโอน
                </button>

        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
      <Modal.Header closeButton>
          <Modal.Title>
          หลักฐานการโอน <br /> บาร์โค้ด : {barcodeNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "19px" }}>
        {localStorage.getItem('role_type')=="1" || localStorage.getItem('role_type')=='1'?(
          <form>
          <div className="form-group">
            <label>เลขที่โอน</label>
            <input
              value={receipt_number}
              onChange={(e) => {
                Receipt_number(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="เลขบัญชี"
            />
          </div>
          {/* tsy miova  */}
          <div className="form-group">
            <label>เลขบัญชี</label>
            <input
              value={bookbank_code}
              onChange={(e) => {
                Bookbank_code(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="เลขบัญชี"
            />
          </div>
          <div className="form-group">
            <label>วันที่โอน</label>
            <input
              defaultValue={transfer_date}
              value={transfer_date}
              onChange={(e) => {
                Transfer_date(e.target.value);
              }}
              type="date"
              className="form-control"
              placeholder="วันที่โอน"
            />
          </div>
          <div className="form-group">
            <label>จำนวนเงิน</label>
            <input
              value={cod_cost}
              onChange={(e) => {
                Cod_cost(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="จำนวนเงิน"
            />
          </div>
        </form>
          ):("")
        }
        {localStorage.getItem('role_type')=="21" || localStorage.getItem('role_type')=='21'?(
          <form>
                <div className="form-group">
                  <label>เลขที่โอน</label>
                  <p style = {{border: "1px solid #ced4da", borderRadius: "5px", padding: "5px"}}>
                    {receipt_number + " "}
                  </p>
                </div>
                <div className="form-group">
                  <label>วันที่โอน</label>
                  <p style = {{border: "1px solid #ced4da", borderRadius: "5px", padding: "5px"}}>
                    {transfer_date + " "}
                  </p>
                </div>
                <div className="form-group">
                  <label>จำนวนเงิน</label>
                  <p style = {{border: "1px solid #ced4da", borderRadius: "5px", padding: "5px"}}>
                    {cod_cost + " "}
                  </p>
                </div>

              </form>
          ):("")
        }
        </Modal.Body>
        {localStorage.getItem('role_type')=="1" || localStorage.getItem('role_type')=='1'?(
          <Modal.Footer>
          <button
            // disabled={localStorage.getItem('role_type')=="21" || localStorage.getItem('role_type')=='21'?"true":"false"}
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              //update bank transfer receipt
              UpdateTransferReceiptCOD({
                code: parcel_code,
                bookbank_code: bookbank_code,
                transfer_receipt_number: receipt_number,
                cod_cost: cod_cost,
                bank_transfer_date: transfer_date
              });
              const formData = new FormData();
              formData.append('file',file_receipt);
              formData.append('code',parcel_code);
              formData.append('employee',localStorage.getItem('employee_number'));
              UploadReceiptFile(
                formData
              );

              // handleClose2(e);
              // ShipmentStatus("");
            }}
          >
          {/* save */}
            บันทึก
          </button>
          <input
            id="files3"
            type="file"
            style={{}}
            className="btn btn-primary"
            accept="image/png, image/jpeg"
            onChange={(e) => {
              File_receipt(e.target.files[0]);
              Files_receipt(e.target.files);

              // UploadReceiptFile({
              //   code: parcel_code,
              //   file: e.target.files[0]
              // });

              //Upload file function
              // handleClose2(e);
              // ShipmentStatus("");
            }}
          />



         {/* upload pic */}
          {/* อัพโหลดรูป */}
      </Modal.Footer>

        ):("")

        }
        {localStorage.getItem('role_type')=="21" || localStorage.getItem('role_type')=='21'?(
          <Modal.Footer>
          <button
            // disabled={localStorage.getItem('role_type')=="21" || localStorage.getItem('role_type')=='21'?"true":"false"}
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              // finance upload file
              // ShipmentStatus("");
            }}
          >
          {/* save */}
            บันทึก
          </button>
          <input
            disabled="true"
            id="files3"
            type="file"
            style={{
              backgroundColor: "#193b6b",
              borderColor: "#193b6b"
            }}
            className="btn btn-primary"
            accept="image/png, image/jpeg"
          />



         {/* upload pic */}
          {/* อัพโหลดรูป */}
      </Modal.Footer>

        ):("")

        }
      </Modal>

      {/* manage multiple cod */}
      <Modal show={showMultiple} onHide={handleCloseMultiple}>
      <Modal.Header closeButton>
          <Modal.Title>
          จัดการสถานะหลายรายการ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "19px" }}>
          <form>
            <div className="form-group">
              <label>เลขที่โอน</label>
              <input
                value={receipt_number}
                onChange={(e) => {
                  Receipt_number(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="เลขบัญชี"
              />
            </div>
            {/* tsy miova  */}
            <div className="form-group">
              <label>เลขบัญชี</label>
              <input
                value={bookbank_code}
                onChange={(e) => {
                  Bookbank_code(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="เลขบัญชี"
              />
            </div>
            <div className="form-group">
              <label>วันที่โอน</label>
              <input
                value={transfer_date}
                onChange={(e) => {
                  Transfer_date(e.target.value);
                }}
                type="date"
                className="form-control"
                placeholder="วันที่โอน"
              />
            </div>
            <div className="form-group">
              <label>จำนวนเงิน</label>
              <input
                value={cod_cost}
                onChange={(e) => {
                  Cod_cost(e.target.value);
                }}
                type="number"
                className="form-control"
                placeholder="จำนวนเงิน"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={ async  (e) => {
              UpdateMultipleTransferReceiptCOD({
                codes: data2,
                bookbank_code: bookbank_code,
                transfer_receipt_number: bankTransferReceiptNumber,
                cod_cost: cod_cost,
                bank_transfer_date: transfer_date
              });
              let formData = new FormData();
              formData.append('file',file_receipt);

              await data2.map((value,index) => {
                formData.append('codes[]',value.code);
              });
              formData.append('employee',localStorage.getItem('employee_number'));
//              formData.append('codes',data2);
              UploadMultipleReceiptFile(
                formData
              );
              //multiple update
              handleCloseMultiple(e);
              // ShipmentStatus("");
            }}
          >
          {/* save */}
            บันทึก
          </button>
          <input
              id="files"
              type="file"
              style={{}}
              className="btn btn-primary"
              //accept="image/png, image/jpeg"
              onChange={(e) => {
                File_receipt(e.target.files[0]);
                Files_receipt(e.target.files);

                // UploadReceiptFile({
                //   code: parcel_code,
                //   file: e.target.files[0]
                // });

                //Multiple Upload file function
                // handleClose2(e);
                // ShipmentStatus("");
              }}
              อัพโหลดรูป

            />
           {/* upload pic */}

          {/* <button
            disabled={shipmentStatus == "" ? true : false}
            type="button"
            className={shipmentStatus == "" ? "btn btn-secondary" : "btn btn-primary"}
            onClick={(e) => {
              // handleClose2();
              // SubmitUpdateStatusMuti();
            }}
          >
            บันทึก
          </button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={showMenager} onHide={handleCloseMenager}
      size = "lg">
        <Modal.Header closeButton>
          <Modal.Title>
          หลักฐานการโอน <br /> บาร์โค้ด : {barcodeNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "17px", color: "#000" }}>
          <Tabs defaultActiveKey="1" id="uncontrolled-tab-example">
            <Tab eventKey="1" title=" อัพเดทสถานะ">
              <br />
              <div className="form-check">
                <input
                  style={{ width: "20px", height: "20px" }}
                  value={"C"}
                  onChange={(e) => {
                    ShipmentStatus(e.target.value);
                  }}
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefaultC"
                  checked={shipmentStatus === "C" ? true : false}
                />
                &nbsp; &nbsp;
                <label className="form-check-label" htmlFor="flexRadioDefaultC">
                  {"โอนแล้ว"}
                </label>
                <div align="right">
                <button
                  disabled={shipmentStatus == "" ? true : false}
                  type="button"
                  className={shipmentStatus == "" ? "btn btn-secondary" : "btn btn-primary"}
                  onClick={(e) => {
                    handleShow3(e);

                    // handleCloseMenager(e);
                    // SubmitUpdateStatus();
                  }}
                >
                  อัพโหลดหลักฐานการโอน
                </button>
                </div>
              </div>
              {/* <hr /> */}
              {/* <div align="right">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => {
                    handleCloseMenager(e);
                    ShipmentStatus("");
                  }}
                >
                  ยกเลิก
                </button>{" "}
                <button
                  disabled={shipmentStatus == "" ? true : false}
                  type="button"
                  className={shipmentStatus == "" ? "btn btn-secondary" : "btn btn-primary"}
                  onClick={(e) => {
                    handleCloseMenager(e);
                    SubmitUpdateStatus();
                  }}
                >
                  บันทึก
                </button>
              </div> */}
            </Tab>
            <Tab eventKey="2" title="COD">
              <br />
              { localStorage.getItem('role_type')=="1" || localStorage.getItem('role_type')=='1' ? (
                <form>
                <div className="form-group">
                  <label>เลขบัญชี</label>
                  <input
                    value={bookbank_code}
                    onChange={(e) => {
                      Bookbank_code(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="เลขบัญชี"
                  />
                </div>
                <div className="form-group">
                  <label>ชื่อบัญชี</label>
                  <input
                    value={bookbank_name}
                    onChange={(e) => {
                      Bookbank_name(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="ชื่อบัญชี"
                  />
                </div>
                <div className="form-group">
                  <label>ธนาคาร</label>
                  <select
                    className="form-control"
                    value={bank_id}
                    onChange={(e) => {
                      Bank_id(e.target.value);
                    }}
                  >
                    <option value={""}>{"--กรุณาเลือกธนาคาร--"}</option>
                    {bank.map((value) => (
                      <option value={value.bank_id} key={value.bank_id}>
                        {value.bankName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>จำนวนเงิน</label>
                  <input
                    value={cod_cost}
                    onChange={(e) => {
                      Cod_cost(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="จำนวนเงิน"
                  />
                </div>
              </form>
              ) : "" }
              { localStorage.getItem('role_type')=="21" || localStorage.getItem('role_type')=='21' ? (
                <form>
                <div className="form-group">
                  <label>เลขบัญชี</label>
                  <p style = {{border: "1px solid #ced4da", borderRadius: "5px", padding: "5px"}}>
                    {bookbank_code + " "}
                  </p>
                </div>
                <div className="form-group">
                  <label>ชื่อบัญชี</label>
                  <p style = {{border: "1px solid #ced4da", borderRadius: "5px", padding: "5px"}}>
                    {bookbank_name + " "}
                  </p>
                </div>
                <div className="form-group">
                  <label>ธนาคาร</label>
                  <p style = {{border: "1px solid #ced4da", borderRadius: "5px", padding: "5px"}}>
                  {bank.map((value) => (
                        value.bank_id === bank_id ? value.bankName+" ":""
                    ))}
                  </p>
                </div>
                <div className="form-group">
                  <label>จำนวนเงิน</label>
                  <p style = {{border: "1px solid #ced4da", borderRadius: "5px", padding: "5px"}}>
                  {cod_cost + " "}
                  </p>
                </div>
              </form>
              ) : "" }
              <hr />
              <div align="right">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => {
                    handleCloseMenager(e);
                  }}
                >
                  ยกเลิก
                </button>{" "}
                <button
                  disabled={
                    bank_id == null || bookbank_code == null || bookbank_name == null || cod_cost == null ? true : false
                  }
                  type="button"
                  className={
                    bank_id == null || bookbank_code == null || bookbank_name == null || cod_cost == null
                      ? "btn btn-secondary"
                      : "btn btn-primary"
                  }
                  onClick={(e) => {
                    handleCloseMenager();
                    SubmitUpdateCOD();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </Tab>
            {/* <Tab eventKey="3" title="พิมพ์ลาเบล">
              <br />
              <div>
                {setData
                  .filter((a) => a.awb_code == barcodeNumber || a.ref_no == barcodeNumber)
                  .map((value, index) => (
                    <div id="contentPrint" key={nanoid()}>
                      {value.cod_cost === "0" ? (
                        <FormatLabel
                          key={nanoid()}
                          supplies={{
                            custCode: value.cust_code,
                            number: index + 1,
                            receiverName: value.receiver_name,
                            awbCode: value.awb_code,
                            date: date,
                            month: month,
                            year: year,
                            weight: value.weight,
                            codCost: value.cod_cost,
                            deposit_type: value.deposit_type,
                            deposit_fullname: value.deposit_fullname,
                            deposit_phone: value.deposit_phone,
                          }}
                        />
                      ) : (
                        <FormatLabelCod
                          key={nanoid()}
                          supplies={{
                            custCode: value.cust_code,
                            number: index + 1,
                            receiverName: value.receiver_name,
                            awbCode: value.awb_code,
                            date: date,
                            month: month,
                            year: year,
                            weight: value.weight,
                            codCost: value.cod_cost,
                            deposit_type: value.deposit_type,
                            deposit_fullname: value.deposit_fullname,
                            deposit_phone: value.deposit_phone,
                          }}
                        />
                      )}
                    </div>
                  ))}
                <hr />
                <div align="right">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={(e) => {
                      handleCloseMenager(e);
                    }}
                  >
                    ยกเลิก
                  </button>{" "}
                  <button
                    disabled={awbCodeSta === "" ? true : false}
                    type="button"
                    className={awbCodeSta === "" ? "btn btn-secondary" : "btn btn-primary"}
                    onClick={() => {
                      printJS({
                        printable: "contentPrint",
                        type: "html",
                        scanStyles: false,
                        css: [
                          "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
                        ],
                        style: "@page {size: 10cm 7cm;margin: 0cm;}",
                      });
                    }}
                  >
                    พิมพ์
                  </button>
                </div>
              </div>
            </Tab> */}
            <Tab eventKey="3" title="ข้อมูลขนส่ง">
              <br />
              <div>
                {setData
                  .filter((a) => a.awb_code == barcodeNumber || a.ref_no == barcodeNumber)
                  .map((value, index) => (
                    <div id="contentPrint2" key={nanoid()}>
                      {value.cod_cost === "0" ? (
                        <FormatLabelAddress
                          key={nanoid()}
                          supplies={{
                            receiver_name: value.receiver_name,
                            receiver_address: value.receiver_address,
                            receiver_amphur: value.receiver_amphur,
                            receiver_province: value.receiver_province,
                            receiver_phone: value.receiver_phone,
                            cust_Code: value.cust_code,
                            number: index + 1,
                            awb_code: value.awb_code,
                            date: date,
                            month: month,
                            year: year,
                            weight: value.weight,
                            codCost: value.cod_cost,
                            deposit_type: value.deposit_type,
                            deposit_fullname: value.deposit_fullname,
                            deposit_phone: value.deposit_phone,
                            sender_name: value.sender_name,
                          }}
                        />
                      ) : (
                        <FormatLabelAddressCod
                          key={nanoid()}
                          supplies={{
                            receiver_name: value.receiver_name,
                            receiver_address: value.receiver_address,
                            receiver_amphur: value.receiver_amphur,
                            receiver_province: value.receiver_province,
                            receiver_phone: value.receiver_phone,
                            cust_Code: value.cust_code,
                            number: index + 1,
                            awb_code: value.awb_code,
                            date: date,
                            month: month,
                            year: year,
                            weight: value.weight,
                            codCost: value.cod_cost,
                            deposit_type: value.deposit_type,
                            deposit_fullname: value.deposit_fullname,
                            deposit_phone: value.deposit_phone,
                            sender_name: value.sender_name,
                          }}
                        />
                      )}
                    </div>
                  ))}
                {/* <hr /> */}
                {/* <div align="right"> */}
                  {/* <button
                    type="button"
                    className="btn btn-danger"
                    onClick={(e) => {
                      handleCloseMenager(e);
                    }}
                  >
                    ยกเลิก
                  </button>{" "}
                  <button
                    disabled={awbCodeSta === "" ? true : false}
                    type="button"
                    className={awbCodeSta === "" ? "btn btn-secondary" : "btn btn-primary"}
                    onClick={() => {
                      printJS({
                        printable: "contentPrint2",
                        type: "html",
                        scanStyles: false,
                        css: [
                          "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
                        ],
                        style: "@page {size: 10cm 15cm;margin: 0cm;}",
                      });
                    }}
                  >
                    พิมพ์
                  </button> */}
                {/* </div> */}
              </div>
            </Tab>
            <Tab eventKey="4" title="หมายเหตุ">
              <form>
                <div className="">
                  <label>กรุณาเขียนหมายเหตุ</label>
                  <br/>
                  <input type={"hidden"}
                    name={"code"}
                    value={parcel_code}
                  />
                  <textarea style={{
                    width: "100%",
                  }}
                  name={"remark"}
                  onChange={(e) => {
                      Remark(e.target.value);
                    }}>
                   {remark}
                  </textarea>
                </div>

                <div align="right">
                <button
                  type="button"
                  className={
                    "btn btn-primary"
                  }
                  onClick={(e) => {

                    UpdateRemark({
                      code: parcel_code,
                      remark: remark
                    });

                  }}
                >
                  บันทึก
                </button>
              </div>
              </form>
              <hr />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
}
