import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { welfare } from "../../../Data/career.json";
import LongDateTH from "../../../utility/LongDateTH";

export default function Apply() {
  const history = useHistory();
  const param = useLocation();

  const [data, setData] = useState({});
  const [property, setProperty] = useState([]);
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    if (param.state.value !== undefined) {
      setData(param.state.value);
      setProperty(param.state.value.property);
      setDetail(param.state.value.detail);
    }
  }, []);

  return (
    <div>
      <div className="container">
        <br />
        <br />
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style={{ backgroundColor: "#ffffff" }}>
            <li class="breadcrumb-item">
              <div
                type="button"
                class="btn-link"
                style={{ color: "#000000" }}
                onClick={(e) => {
                  history.push("Career");
                }}
              >
                ตำแหน่งที่เปิดรับสมัคร
              </div>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              รายละเอียด
            </li>
          </ol>
        </nav>
        <div>
          <div className="text-center text-title-color">
            <h2>รายละเอียด</h2>
          </div>
          <br />
          <h2 className="text-subtitle">
            <b>{data.position}</b>
          </h2>
          <h4 className="text-subtitle">
            {data.positionName}
          </h4>
          <div className="row">
            <div className="col-sm-6">
              <h6 className="card-subtitle mb-2 text-subtitle">
                <Icon.GeoAlt size="25px" />
                &nbsp;สถานที่ปฏิบัติงาน : {data.work_location}
              </h6>
            </div>
            <div class="col-sm-6">
              <h6 className="card-subtitle mb-2 text-subtitle" align="right">
                <Icon.CalendarEvent size="25px" />
                &nbsp;&nbsp;วันที่ประกาศ : {LongDateTH(data.ano_date)[0]}
              </h6>
            </div>
          </div>
          <hr />

          <div class="row">
            <div class="col-sm-8">
              <div>
                <h4 class="card-subtitle text-title-color mt-custom">
                  คุณสมบัติ
                </h4>
                {/* {property.map((value, index) => (
                  <p class="card-subtitle mb-2  card-text-custom text-subtitle-property">
                    {index + 1}. {value.clause}
                  </p>
                ))} */}
                <p
                  class="card-subtitle mb-2  card-text-custom text-subtitle-property"
                  dangerouslySetInnerHTML={{
                    __html: data.qualification_description,
                  }}
                ></p>
              </div>
            </div>
            <div class="col-sm-4">
              <div align="right">
                <button
                  class="btn btn-primary"
                  onClick={() => {
                    history.push("/FormApply", {
                      value: data,
                      path: param.pathname,
                      text: "รายละเอียดงาน",
                    });
                  }}
                >
                  สมัครออนไลน์
                </button>
                &nbsp;&nbsp;
                <button class="btn btn-primary" onClick={() => {}}>
                  ดาวโหลดใบสมัครงาน
                </button>
              </div>
            </div>
          </div>

          <hr />
          <div>
            <h4 class="card-subtitle text-title-color mt-custom">รายละเอียด</h4>
            {/* {detail.map((value, index) => (
              <p class="card-subtitle mb-2  card-text-custom text-subtitle-property">
                {index + 1}. {value.clause}
              </p>
            ))} */}
            <div
              class="card-subtitle mb-2  card-text-custom text-subtitle-property"
              dangerouslySetInnerHTML={{
                __html: data.job_description,
              }}
            ></div>
          </div>
          <hr />
          <div class="card-detail-work">
            <div class="card-body card-detail-work">
              <div className="row">
                <div className="col-sm">
                  <h4 class="card-subtitle text-title-color mt-custom">
                    สวัสดิการที่ได้รับ
                  </h4>
                  {welfare.map((value, index) => (
                    <p class="card-subtitle mb-2 card-text-custom">
                      {index + 1}. {value.clause}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
