import Instance from "../Helper/axios";
import {URLTEST} from "../Helper/baseURL";

export function getStockOut(params){
    const formData = new FormData();
    Object.entries(params)
        .filter(([key, value]) => value !== null && value!== undefined)
        .forEach(([key, value]) => {
        formData.append(key, value);
    });
    return Instance.post(
        URLTEST + "transport/StockIn/getStockOut",
        formData
    );
}