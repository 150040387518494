export const barcodeHasComputedErrors = (validationData) => {
        return validationData.filter(
            row => !row.existing || row.already_received || row.already_picked || row.not_yet_picked_up,
        ).length !== 0;
};

export const getValidationRowStatus = (item) => {
        if (!item.existing) {
                return {
                        color: 'red',
                        text: 'ไม่มีอยู่',
                };
        }
        else if(item.not_yet_picked_up){
                return {
                        color: 'red',
                        text: 'พัสดุฯยังไม่ถูกรับเข้าระบบ',
                };
        }
        else if (item.already_received) {
                return {
                        color: '#01004c',
                        text: 'ได้รับแล้ว',
                };
        } else if (item.same_status) {
                return {
                        color: '#DE9F3E',
                        text: 'พัสดุฯถูกรับเข้าระบบไปแล้ว',
                };
        } else if (item.already_picked) {
                return {
                        color: 'red',
                        text: 'หยิบแล้ว',
                };
        } else {
                return {
                        color: 'black',
                        text: 'ตกลง',
                };
        }
};