import React, {useEffect, useState} from "react";
import HubBodyLayout from '../../../Layouts/Hub/components/hub-body-layout';
import { HubPresentation } from '../Shared/HubPresentation';
import BarcodeInput from '../Shared/BarcodeInput';
import {Spinner} from "react-bootstrap";
import {useScanStatusUpdate} from "../Shared/hooks/use-scan-status-update";
import {swalError, swalSuccess} from "../../../Components/sweetAlert";
import {useTranslation} from "react-i18next";
import {format, parse} from "date-fns";
import {useGetScanInOut} from "../Shared/hooks/use-get-scan-in-out";
import {ScanInOut} from "../Shared/ScanInOut";


const getDateFilters = (dates) => {
    return {
        startDate: parse(format(dates.startDate, 'yyyy/MM/dd 00:00:00'), 'yyyy/MM/dd HH:mm:ss', new Date()),
        endDate: parse(format(dates.endDate, 'yyyy/MM/dd 23:59:00'), 'yyyy/MM/dd HH:mm:ss', new Date())
    }
};

const getAPIDates = (dates) => {
    return {
        startDate: format(dates.startDate, 'yyyy-MM-dd 00:00:00'),
        endDate: format(dates.endDate, 'yyyy-MM-dd 23:59:00')
    }
}

const HubScanIn = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [awb_code, setAwb_code] = useState('');
    const [dateFilters, setDateFilters] = useState(getDateFilters({
        startDate: new Date(),
        endDate: new Date()
    }));

    const  {loading: dataLoading, data, fetch} = useGetScanInOut((error) => {
        void swalError({
            text: error
        })
    });

    const initialFetch = () => {
        setCurrentPage(1);
        fetch(1, 1, getAPIDates(dateFilters));
    }

    useEffect(() => {
        initialFetch();
    }, []);

    useEffect(() => {
        console.log(dateFilters);
    }, [dateFilters]);

  const {t} = useTranslation();
  const {loading: updateStatusLoading, updateStatus} = useScanStatusUpdate(
      1,
      (error) => {
          void swalError({
              text: error
          })
      },
      (message) => {
            void swalSuccess({
                text: message,
            });
          initialFetch();
      }
  );

  const loading = dataLoading || updateStatusLoading;



  const handleDateFiltersChanged = (filters) => {
      setDateFilters(filters);
      setCurrentPage(1);
      void fetch(currentPage, 1, getAPIDates(filters))
  }

  const handleChangePage = (event,page) => {
      setCurrentPage(page);
      void fetch(page, 1, getAPIDates(dateFilters))
  }

  const leftContent = (
    <>
      <HubPresentation />
        {loading &&
            <div className="spinner-global-content">
                <Spinner variant="success" animation="border" />
            </div>
        }
        {!loading && (
            <>
                <BarcodeInput
                    value={awb_code}
                    setValue={setAwb_code}
                    placeholder={t('hub.scan.barcode_input')}
                    submit={updateStatus.bind(null,awb_code)} />
                <ScanInOut
                    dateFilters={dateFilters}
                    setDateFilters={handleDateFiltersChanged}
                    loading={loading}
                    data={data}
                    handleChangePage={handleChangePage}
                />
            </>
        )}
    </>
  );

  return (
    <HubBodyLayout leftContent={leftContent}/>
  )
}
export default HubScanIn;
