import HubLayout from "../HubLayout";
import HubMenuRoutes from "./hub-menu-route.enum";

const HubMenuRoute = {
    path: "/hub/",
    layout: HubLayout,
    role: 0,
    children: Object.values(HubMenuRoutes),
    protect: 0,
};

export default HubMenuRoute;