import React from 'react';
import * as Icon from 'react-bootstrap-icons';

export const SearchInput = ({
    value,
    setValue,
    placeholder,
    submit,
}) => {
    return (
        <div className='row'>
            <div className='col-12 col-md-12'>
                <input
                    className="form-control w-100 mb-3"
                    value={value}
                    placeholder={placeholder}
                    onChange={(e) => {
                    setValue(e);
                    }}
                />
            </div>
      </div>
  )
}
export default SearchInput;