import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import swal from "sweetalert";
import DateTH from "../../../Helper/date-th";
import { usertype } from "../../../Data/empData.json";
import {
  ShowEmpService,
  UpdateStatusEmpService,
} from "../../../Service/service.Employee";

export default function Employees() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });

  const pageSizes = [10, 20, 50, 100];
  const [pageNo, setCurrentPage] = useState(1);
  const [perPage, setPageSize] = useState(10);
  const [setErrorMassNoData, SetErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);
  const [keyword, setSearch] = useState("");
  const [roleType, setRoleType] = useState("");

  useEffect(fetchData, [pageNo, perPage]);

  async function fetchData() {
    let criteria = {
      keyword: keyword !== "" ? keyword : "",
      roleType: roleType !== "" ? roleType : "",
      pageNo: pageNo !== "" ? pageNo : "",
      perPage: perPage !== "" ? perPage : "",
    };

    Loading(1);
    const res = await ShowEmpService(criteria);
    if (res.numRowsAll > 0) {
      setData(res.result);
      setPage({
        current_page: res.pageNo,
        last_page: res.maxPage,
        total: res.numRowsAll,
      });
      SetErrorMassNoData(0);
      Loading(0);
    } else {
      setCurrentPage(1);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      SetErrorMassNoData(1);
      Loading(0);
    }
  }

  const handleChangePage = (e, newPage) => {
    e.preventDefault();
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
  };

  const Del = async (value) => {
    let res = await UpdateStatusEmpService(value);
    if (res.status === 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      fetchData();
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };

  function filter() {
    setData([]);
    fetchData();
  }

  function clear() {
    setData([]);
    setSearch("");
    setRoleType("");
    SetErrorMassNoData(0);
    setCurrentPage(1);
    setPageSize(10);
    fetchData();
  }

  return (
    <div>
      <div className="text-center text-title-color">
        <h2>ข้อมูลพนักงาน</h2>
      </div>
      <br />
      <div class="row" style={{ marginLeft: "5px", marginRight: "5px" }}>
        <div class="col-sm-4 px-md-1 mt-1">
          <span>ค้นหา</span>
          <input
            value={keyword}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            type="text"
            class="form-control"
            placeholder="ชื่อผู้ใช้"
            aria-label="ชื่อผู้ใช้"
            aria-describedby="basic-addon1"
          />
        </div>
        <div class="col-sm-2 px-md-1 mt-1">
          <span>สิทธิ์การใช้งาน</span>
          <select
            value={roleType}
            class="form-control"
            onChange={(e) => {
              setRoleType(e.target.value);
            }}
            aria-label="Default select example"
          >
            <option value="">-- สิทธิ์การใช้งาน --</option>
            {usertype.map((value) => (
              <option value={value.id}>{value.name}</option>
            ))}
          </select>
        </div>
        <div className="col-sm-2 px-md-3 mt-1">
          <br />
          <button
            type="button"
            disabled={loading === 0 ? false : true}
            className={"btn btn-primary"}
            onClick={filter}
          >
            <Icon.Search /> ค้นหา
          </button>{" "}
          <button type="reset" className="btn btn-secondary" onClick={clear}>
            <Icon.ArrowClockwise /> ล้างค่า
          </button>
        </div>
        <div className="col-sm-4 px-md-1 mt-1" align="right">
          <br />
          <button
            className="btn btn-primary"
            onClick={(e) => {
              history.push("/mis/FormEmp");
            }}
          >
            <Icon.Plus /> เพิ่มข้อมูล
          </button>
        </div>
      </div>
      <br />
      <div className="d-flex justify-content-between mb-2">
        <div></div>
        <div>
          <select
            id="ddlPageSize"
            className="form-control"
            onChange={handlePageSizeChange}
            value={perPage}
          >
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <div style={{ overflow: "Auto" }}>
          <table className="table table-bordered">
            <thead style={{ backgroundColor: "#3f75c1" }}>
              <tr style={{ color: "#FFFFFF" }}>
                <td>ลำดับ</td>
                <td style={{ width: "30%" }}>ชื่อ-นามสกุล</td>
                <td>ชื่อผู้ใช้งาน</td>
                <td>สิทธิ์การใช้งาน</td>
                <td>จัดการ</td>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr key={value.id}>
                  <td>{(page.current_page - 1) * perPage + (index + 1)}</td>
                  <td>{value.fullname}</td>
                  <td>{value.username}</td>
                  <td>{value.role_type_name}</td>
                  {/**<td>
                    <DateTH date={value.create_date} />
                  </td> */}
                  <td>
                    <button
                      className="btn btn-warning"
                      onClick={(e) => {
                        history.push("/mis/FormEmp", { value: value });
                      }}
                    >
                      <Icon.PencilSquare style={{ color: "#ffff" }} />
                    </button>{" "}
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        swal({
                          className: "bg-modal-red",
                          icon: "warning",
                          dangerMode: true,
                          text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                          buttons: {
                            cancel: "ยกเลิก",
                            confirm: {
                              text: "ใช่",
                              value: value.id,
                            },
                          },
                        }).then((value) => {
                          if (value !== null) {
                            Del({
                              id: value,
                            });
                          }
                        });
                      }}
                    >
                      <Icon.Trash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <center>
            {setErrorMassNoData === 1 ? (
              <font color="red" size="3">
                {" "}
                ไม่พบข้อมูล!{" "}
              </font>
            ) : (
              ""
            )}
          </center>
        </div>
        <center>
          {loading === 1 ? (
            <div className="m-5">
              <div className="text-center">
                <div className="spinner-border  text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                {" ..."}
                กำลังโหลดข้อมูล
              </div>
            </div>
          ) : (
            ""
          )}
        </center>
        <div className="d-flex justify-content-between">
          <div>จำนวนทั้งหมด {page.total} รายการ</div>
          <div>
            <Pagination
              count={parseInt(page.last_page)}
              page={pageNo}
              onChange={handleChangePage}
              color="primary"
              size="small"
              defaultPage={6}
              siblingCount={1}
            />
          </div>
        </div>
      </div>

      <br />
      <br />
    </div>
  );
}
