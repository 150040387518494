import React, { Component } from "react";
import logoNavbar from "../../../Assets/images/logo/logoNavbar.png";
import logo_social from "../../../Assets/imgReceipt/logo_social.png";
import * as Icon from "react-bootstrap-icons";
import { bank } from "../../../Data/bank.json";

export default class FormatReceipt extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        style={{
          fontFamily: "Kanit",
          fontSize: "13px",
          position: "relative",
          marginTop: "5px",
          marginBottom: "5px",
          marginLeft: "10px",
          marginRight: "10px",
        }}
        className="page-break"
      >
        <center>
          <img alt="" src={logoNavbar} width="140" height="70" className="d-inline-block align-top" />
        </center>
        <center className="text-center" style={{ fontSize: "15px", marginTop: "10px" }}>
          <div>
            <b>ใบเสร็จรับเงิน&nbsp;{this.props.supplies.receiptCode}</b>
          </div>

          <div>
            <b>บริษัท ปอลอ เอ็กซ์เพรส จำกัด</b>
          </div>

          <div>
            <b>เลขประจำตัวผู้เสียภาษี</b>
          </div>

          <div>
            <b>0105549112735</b>
          </div>
        </center>
        <p>
          <div>
            วันที่ {this.props.supplies.date}/{this.props.supplies.month}/{this.props.supplies.year + 543} เวลา{" "}
            {this.props.supplies.time} น.
          </div>
          <div>ชื่อ-นามสกุล ผู้ส่ง : {this.props.supplies.sender_name}</div>
          <div>โทร : {this.props.supplies.sender_phone}</div>
        </p>
        <p>
          <div>
            1. รับผิดชอบ สูญหาย เสียหาย ตามความเป็นจริง แต่ไม่เกิน 3,000 บาท ภายใน 1 เดือน นับแต่ บริษัทได้รับสิ่งของ
            เว้นแต่มีการลงชื่อรับแล้ว
          </div>
          <div>
            2. ไม่รับผิดชอบใดๆ เกี่ยวกับ เงินตรา ทองคำ เอกสารสำคัญ และของมีค่าอื่นๆ รวมถึงสิ่งของที่มี ส่วนประกอบเป็นน้ำ
            สิ่งมีชีวิต กระจก หรือสิ่งของ ที่แตกหักง่าย
          </div>
          <div>
            3. ติดต่อผู้รับไม่ได้ภายใน 7 วัน นับแต่บริษัทได้รับสิ่งของ ให้มารับของคืน ณ สาขาที่ได้ส่ง ภายใน 1
            เดือนมิฉะนั้นถือว่าอนุญาตให้ทำลาย โดย บริษัท สงวน สิทธิ์ไม่คืนเงินค่าขนส่งดังกล่าว
          </div>
          <div>4. รับผิดชอบเฉพาะตามเงื่อนไขข้างต้นเท่านั้น</div>
        </p>
        <p>
          <div>
            <b>
              <div>
                <b>ประเภทสิ่งของ : กล่องพัสดุ จำนวน {this.props.supplies.amont_parcel} ชิ้น</b>
              </div>
              {parseInt(this.props.supplies.cod_cost) !== 0 ? (
                <>
                  <div>
                    <b>
                      COD&nbsp;&nbsp;
                      <span style={{ border: "2px solid #000000" }}>
                        &nbsp;
                        {parseInt(this.props.supplies.cod_cost).toLocaleString("en")}
                        &nbsp;
                      </span>
                      &nbsp;&nbsp;
                      {bank
                        .filter((x) => x.bank_id === this.props.supplies.bank_id)
                        .map((value, index) => (
                          <span key={index}>{value.bankName}</span>
                        ))}
                    </b>
                  </div>
                  <div>
                    <b>
                      ชื่อบัญชี : {this.props.supplies.bookbank_name} / บริการเก็บค่าสินค้า&nbsp;&nbsp;
                      {parseInt(this.props.supplies.service_cod).toLocaleString("en")}
                      &nbsp;&nbsp;บาท
                    </b>
                  </div>
                  <div>
                    <b>เลขบัญชี : {this.props.supplies.bookbank_code}</b>
                  </div>
                </>
              ) : (
                ""
              )}
            </b>
          </div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div></div>
            <div>
              <div align="right">
                <b>รวมค่าขนส่ง :&nbsp;&nbsp;</b>
              </div>
              <div align="right">
                <b>รับเงิน :&nbsp;&nbsp;</b>
              </div>
              <div align="right">
                <b>ทอนเงิน :&nbsp;&nbsp;</b>
              </div>
            </div>
            <div>
              <div align="right">
                <b>
                  {parseInt(this.props.supplies.freightPrice).toLocaleString("en")}
                  &nbsp;&nbsp;บาท
                </b>
              </div>
              <div align="right">
                <b>
                  {parseInt(this.props.supplies.getMoney).toLocaleString("en")}
                  &nbsp;&nbsp;บาท
                </b>
              </div>
              <div align="right">
                <b>
                  {parseInt(this.props.supplies.changePrice).toLocaleString("en")}
                  &nbsp;&nbsp;บาท
                </b>
              </div>
            </div>
          </div>
        </p>
        <center>
          <div style={{ fontSize: "15px", marginTop: "15px" }}>
            ลงชื่อ(ตัวบรรจง).............................................ผู้ส่ง
          </div>
          <div>ตรวจสอบรายละเอียดการขายและติดตาม</div>
          <div>สภานะการขนส่งพัสดุฯย้อนหลัง 3 เดือนได้ที่</div>
          <div>www.porlor.co.th</div>
        </center>
        {this.props.supplies.printLebel.map((value, index) => (
          <p key={index}>
            <div>
              {index + 1}. ชื่อผู้รับ : {value.receiver_name}/{value.shipment_cost} บาท
            </div>
            <div style={{ justifyContent: "space-between", display: "flex", fontSize: "12.5px" }}>
              <div>
                {value.deposit_type === "2" ? (
                  <b>
                    <Icon.Check2Square style={{ color: "#000" }} />
                    &nbsp;ฝาก
                  </b>
                ) : value.deposit_type === "3" ? (
                  <b>
                    <Icon.Check2Square style={{ color: "#000" }} />
                    &nbsp;ส่งตรงตัว/ฝาก
                  </b>
                ) : (
                  <b>
                    <Icon.Check2Square style={{ color: "#000" }} />
                    &nbsp;ส่งตรงตัว
                  </b>
                )}
              </div>
              <div>
                <div align="right">/เลขพัสดุ : {value.awb_code}</div>
              </div>
            </div>
          </p>
        ))}
        <center>
          <div>ค้นหาเป็นชุดได้โดยใส่เลขบัตรประชาชน</div>
        </center>
        <div>
          <div>
            <span style={{ marginLeft: "10px" }}>***</span>&nbsp;
            <span style={{ border: "1px solid #000000", fontSize: "12px" }}>
              &nbsp;ระยะเวลาจัดส่งกรุณาตรวจสอบหน้าเว็บ&nbsp;
            </span>
          </div>
        </div>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            marginTop: "10px",
          }}
        >
          <div></div>
          <div></div>
          <div>
            <center>
              <img alt="" src={logo_social} width="80" height="50" className="d-inline-block align-top" />
            </center>
          </div>
          <div>
            <div style={{ fontSize: "20px", marginTop: "10px" }}>@Porlor</div>
          </div>
          <div></div>
          <div></div>
        </div>
        <center>
          <div>Tel. 1423</div>
          <div>Email : service@porlor.co.th</div>
          <div>99/153 ซอยกรุงเทพกรีฑา 39</div>
          <div>แขวงทับช้าง เขตสะพานสูง กทม. 10250</div>
        </center>
      </div>
    );
  }
}
