import React, { useState, useEffect } from 'react';
import printJS from 'print-js';
import Modal from 'react-bootstrap/Modal';
import { shipment_status } from '../../../Data/shipment_status';
import { Formik, Form, ErrorMessage, getIn } from 'formik';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import LabelFormSingle from './LabelFormSingle';
import { bank } from '../../../Data/bank';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import swal from 'sweetalert';
import {updateParcelsRemark, updateReceiverDateService} from "../../../Service/service.Parcels";
import PrintContent from "../printLabel/conoteModal/printContentsOLD";
import {getIndividualConoteData,deleteImageService,retrieveImageService} from "../../../Service/service.Barcode";
import {swalError} from "../../../Components/sweetAlert";
import {update_ref_no_status} from "../printLabel/conoteModal/conoteService";
import * as Icon from 'react-bootstrap-icons';
import {DatePicker, DateTimePicker } from '@material-ui/pickers';
import {addYears, format, getDate, getMonth, getYear, isAfter, isBefore, isSameDay} from "date-fns";
import ModalsMGImagePicker from './ModalsMGImagePicker';

function ModalsMG({ Upload, notImg, setNotImg, data, printLabelSingle,setPrintLabelSingle, dataSelect, UpdateBarcode, UpdateCOD, modalMG, setModalMG, finishUpdate }) {
  const [remark, setRemark] = useState('');
  const [receiverDate, setReceiverDate] = useState(null);
  const [receiverDate1, setReceiverDate1] = useState(null);
  const [conoteData, setConoteData] = useState(null);
  useEffect(() => {
    if (printLabelSingle) {
      setRemark(printLabelSingle.remarkMG);
    }
  }, [printLabelSingle]);
  const Schema = Yup.object().shape({
    bookbank_code: Yup.string().required('กรุณาระบุข้อมูล'),
    bookbank_name: Yup.string().required('กรุณาระบุข้อมูล'),
    bank_id: Yup.string().required('กรุณาระบุข้อมูล'),
    cod_cost: Yup.string().required('กรุณาระบุข้อมูล'),
  });

    let timerId = null;
    const yearsToAdd = 543;
    const refreshYears = (date) => {
        timerId = setTimeout(() => {
            const rootElement = document.querySelector('.MuiPickersCalendarHeader-switchHeader');
            if(!rootElement){
                return;
            }
            const element = rootElement
                .querySelector('p');
            const text = element.textContent.split(' ');
            const year = (date ? getYear(date) : parseInt(text[1])) + yearsToAdd;
            element.textContent = `${text[0]} ${year}`;
        }, 50);
    };
    const dateFormat = (date) => {
        if(!date){
            return "";
        }
        const newDate = addYears(date,yearsToAdd);
        return format(newDate, 'yyyy/MM/dd HH:mm:ss');
    };
    const onYearChange = (date) => {
        refreshYears(date);
    };
    const onHandleDatePickerFocus = () => {
        refreshYears();
    };

  const handleFileChange = async (event) => {
    var form_data = new FormData();
    form_data.append('file', event.target.files[0]);
    form_data.append('code', printLabelSingle.code);
    form_data.append('awb_code', printLabelSingle.awb_code !== null ? printLabelSingle.awb_code : printLabelSingle.ref_no);
    form_data.append('cust_code', localStorage.getItem('username'));
    setModalMG(false);
    Upload(form_data);
  };

  const hideImg = (event) => {
    setNotImg(false);
  };

  const retrieveImage = async (value) => {
    try{
      // alert(JSON.stringify(printLabelSingle));
      const result = await retrieveImageService(printLabelSingle.awb_code,value);
//      alert(JSON.stringify(result));
      swal({
        title: "คืนค่าลายเซ็น",
        text: "",
        icon: "success",
        showConfirmButton: true,
        button: "ปิด",
        timer: 750,
      });
      console.log(JSON.stringify(result));
      setModalMG(false);
      finishUpdate();
      //window.location.reload(false);
    }
    catch(e){
      console.log('exception = ', e.message);
      swal({
        title: "เกิดข้อผิดพลาด !",
        text: "",
        icon: "warning",
        showConfirmButton: true,
        button: "ปิด",
        timer: 1500,
      })
    }
  }

  const deleteImage = async (value) => {
    try{
      // alert(JSON.stringify(printLabelSingle));
      const result = await deleteImageService(printLabelSingle.awb_code,value);
      //alert(JSON.stringify(result));
      swal({
        title: "ลบรูปลายเซ็น",
        text: "",
        icon: "success",
        showConfirmButton: true,
        button: "ปิด",
        timer: 750,
      });
      console.log(JSON.stringify(result));
      setModalMG(false);
      finishUpdate();
      //window.location.reload(false);
    }
    catch(e){
      console.log('exception = ', e.message);
      swal({
        title: "เกิดข้อผิดพลาด !",
        text: "",
        icon: "warning",
        showConfirmButton: true,
        button: "ปิด",
        timer: 1500,
      })
    }
  }

  const updateBarCodeRemark = async () => {
    try {
      const result = await updateParcelsRemark(printLabelSingle.code, remark);
      swal({
        title: "ความสำเร็จ !",
        text: "",
        icon: "success",
        showConfirmButton: true,
        button: "ปิด",
        timer: 750,
      });
      finishUpdate();
    } catch (e) {
      console.log('exception = ', e.message);
      swal({
        title: "เกิดข้อผิดพลาด !",
        text: "",
        icon: "warning",
        showConfirmButton: true,
        button: "ปิด",
        timer: 1500,
      })
    }
  };

  const updateReceiverDate = async () => {
    try {
      const result = await updateReceiverDateService(printLabelSingle.code, receiverDate);
      swal({
        title: "ความสำเร็จ !",
        text: "",
        icon: "success",
        showConfirmButton: true,
        button: "ปิด",
        timer: 750,
      });
      finishUpdate();
    } catch (e) {
      console.log('exception = ', e.message);
      swal({
        title: "เกิดข้อผิดพลาด !",
        text: "",
        icon: "warning",
        showConfirmButton: true,
        button: "ปิด",
        timer: 1500,
      })
    }
  };

  useEffect(() => {
    if(conoteData){
      printJS({
        printable: "printReport",
        type: "html",
        scanStyles: false,
        css: [
          window.location.protocol + "//" + window.location.host + "/static/Conote.css",
          "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
        ],
        style: "@page { size: 20cm 14cm; margin:0cm; } ",
        onLoadingStart: () => {
          if (conoteData.conote_print_status == 1) {
            return;
          }
          update_ref_no_status([conoteData.ref_no])
              .then(result => {
                if (result.data.res) {
                  setConoteData(null);
                }
              }, error => {
                console.log({error});
                setConoteData(null);
              });
        }
      })
    }
}, [conoteData]);

  return (
    <>
      {/* <-------------------------------------------------จัดการ---------------------------------------------> */}
      <Modal
        show={modalMG}
        onHide={() => {
          setPrintLabelSingle(null);
          setModalMG(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            จัดการ <br /> บาร์โค้ด : {printLabelSingle.awb_code !== null ? printLabelSingle.awb_code : printLabelSingle.ref_no}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '17px', color: '#000' }}>
          <Tabs defaultActiveKey="1" id="uncontrolled-tab-example">
            <Tab eventKey="1" title=" อัพเดทสถานะ">
              <br />
              <Formik
                initialValues={{
                  shipment_status: printLabelSingle.shipment_status,
                }}
                enableReinitialize={true}
                onSubmit={(value) => {
                  if (value.shipment_status !== '') {
                    if(localStorage.getItem('superkey')==="service" || localStorage.getItem('superkey')==="admin"){
                      UpdateBarcode([
                      {
                        code: printLabelSingle.code,
                        awb_code: printLabelSingle.awb_code !== null ? printLabelSingle.awb_code : printLabelSingle.ref_no,
                        cust_code: localStorage.getItem('username'),
                        shipment_status: value.shipment_status,
                        old_shipment_status: printLabelSingle.shipment_status,
                      }
                      ]);
                    }
                    else{
                      if(printLabelSingle.shipment_status==='5' || printLabelSingle.shipment_status===5 || printLabelSingle.shipment_status==='15' || printLabelSingle.shipment_status===15){
                        alert("You do not have the permission to scan a parcel that is received")
                      }
                      else{
                        UpdateBarcode([
                          {
                            code: printLabelSingle.code,
                            awb_code: printLabelSingle.awb_code !== null ? printLabelSingle.awb_code : printLabelSingle.ref_no,
                            cust_code: localStorage.getItem('username'),
                            shipment_status: value.shipment_status,
                            old_shipment_status: printLabelSingle.shipment_status,
                          }
                        ]);
                      }
                    }
                    setModalMG(false);
                  }
                }}
              >
                {({ errors, touched, handleBlur, setFieldValue, values }) => (
                  <Form>
                    <div className="form-check">
                      <input
                        checked={values.shipment_status === 'C' ? true : false}
                        style={{ width: '20px', height: '20px' }}
                        value={'C'}
                        onChange={(e) => {
                          setFieldValue('shipment_status', e.target.value);
                        }}
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefaultC"
                        // disabled={printLabelSingle.shipment_status === "5"}
                        disabled = {localStorage.getItem("superkey") ==="operator"}
                      />
                      &nbsp; &nbsp;
                      <label className="form-check-label" htmlFor="flexRadioDefaultC" >
                        1. ยกเลิก
                      </label>
                    </div>
                    {/* <div className="form-check">
                      <input
                        checked={values.shipment_status === '0' ? true : false}
                        style={{ width: '20px', height: '20px' }}
                        value={'0'}
                        onChange={(e) => {
                          setFieldValue('shipment_status', e.target.value);
                        }}
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefaultC"
                        // disabled={printLabelSingle.shipment_status === "5"}
                        disabled = {localStorage.getItem("superkey") ==="operator"}
                      />
                      &nbsp; &nbsp;
                      <label className="form-check-label" htmlFor="flexRadioDefaultC" >
                        0. back to 0
                      </label>
                    </div> */}
                    {shipment_status.map((item, index) => (
                      <div className="form-check" key={index}>
                        <input
                          checked={parseInt(values.shipment_status) === item.id ? true : false}
                          style={{ width: '20px', height: '20px' }}
                          value={item.id}
                          onChange={(e) => {
                            setFieldValue('shipment_status', e.target.value);
                          }}
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id={'flexRadioDefault' + index}
                          // disabled={printLabelSingle.shipment_status === "5"}
                          disabled = {localStorage.getItem("superkey") ==="operator"}
                        />
                        &nbsp; &nbsp;
                        <label className="form-check-label" htmlFor={'flexRadioDefault' + index}>
                          {index + 2 + '. ' + item.StatusName}
                        </label>
                      </div>
                    ))}
                    <hr />
                    <div align="right">
                      <button
                        type="button"
                        className="btn btn-danger ml-1"
                        onClick={(e) => {
                          setModalMG(false);
                        }}
                      >
                        ยกเลิก
                      </button>
                      <button 
                        type="submit" 
                        // className={localStorage.getItem("superkey") !=="service" ? 'btn btn-primary ml-1' : 'btn btn-secondary ml-1'}
                        className= "btn btn-primary ml-1"
                        // disabled = {localStorage.getItem("superkey") ==="service"}
                        onClick={(e) => {}}>
                        บันทึก
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Tab>
            <Tab eventKey="2" title="จัดการ COD">
              <br />
              <Formik
                validationSchema={Schema}
                initialValues={{
                  bookbank_code: printLabelSingle.bookbank_code !== null ? printLabelSingle.bookbank_code : '',
                  bookbank_name: printLabelSingle.bookbank_name !== null ? printLabelSingle.bookbank_name : '',
                  bank_id: printLabelSingle.bank_id !== null ? printLabelSingle.bank_id : '',
                  cod_cost: printLabelSingle.cod_cost === '0' ? '' : printLabelSingle.cod_cost,
                }}
                enableReinitialize={true}
                onSubmit={(value) => {
                  UpdateCOD({
                    awb_code: printLabelSingle.awb_code !== null ? printLabelSingle.awb_code : printLabelSingle.ref_no,
                    cod_cost: value.cod_cost,
                    bank_id: value.bank_id,
                    bookbank_code: value.bookbank_code,
                    bookbank_name: value.bookbank_name,
                  });
                  setModalMG(false);
                }}
              >
                {({ errors, touched, handleBlur, setFieldValue, values }) => (
                  <div>
                    {localStorage.getItem("superkey") !=="service" ? (
                      <Form>
                      <div className="form-group">
                        <label>เลขบัญชี</label>
                        <input
                          value={values.bookbank_code}
                          onChange={(e) => {
                            setFieldValue('bookbank_code', e.target.value);
                          }}
                          type="text"
                          name="bookbank_code"
                          className={'form-control ' + (getIn(touched, 'bookbank_code') ? (getIn(errors, 'bookbank_code') ? 'is-invalid' : '') : '')}
                          placeholder="เลขบัญชี"
                        />
                        <ErrorMessage component="div" name="bookbank_code" className="invalid-feedback" />
                      </div>
                      <div className="form-group">
                        <label>ชื่อบัญชี</label>
                        <input
                          value={values.bookbank_name}
                          onChange={(e) => {
                            setFieldValue('bookbank_name', e.target.value);
                          }}
                          type="text"
                          name="bookbank_name"
                          className={'form-control ' + (getIn(touched, 'bookbank_name') ? (getIn(errors, 'bookbank_name') ? 'is-invalid' : '') : '')}
                          placeholder="ชื่อบัญชี"
                        />
                        <ErrorMessage component="div" name="bookbank_name" className="invalid-feedback" />
                      </div>
                      <div className="form-group">
                        <label>ธนาคาร</label>
                        <select
                          name="bank_id"
                          className={'form-control ' + (getIn(touched, 'bank_id') ? (getIn(errors, 'bank_id') ? 'is-invalid' : '') : '')}
                          value={values.bank_id}
                          onChange={(e) => {
                            setFieldValue('bank_id', e.target.value);
                          }}
                        >
                          <option value={''}>{'--กรุณาเลือกธนาคาร--'}</option>
                          {bank.map((value) => (
                            <option value={value.bank_id} key={value.bank_id}>
                              {value.bankName}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage component="div" name="bank_id" className="invalid-feedback" />
                      </div>
                      <div className="form-group">
                        <label>จำนวนเงิน</label>
                        <input
                          value={values.cod_cost}
                          onChange={(e) => {
                            setFieldValue('cod_cost', e.target.value);
                          }}
                          type="number"
                          name="cod_cost"
                          className={'form-control ' + (getIn(touched, 'cod_cost') ? (getIn(errors, 'cod_cost') ? 'is-invalid' : '') : '')}
                          placeholder="จำนวนเงิน"
                        />
                        <ErrorMessage component="div" name="cod_cost" className="invalid-feedback" />
                      </div>
                      <hr />
                      <div align="right">
                        <button
                          type="button"
                          className="btn btn-danger ml-1"
                          onClick={(e) => {
                            setModalMG(false);
                          }}
                        >
                          ยกเลิก
                        </button>
                        <button 
                          type="submit" 
                          className={localStorage.getItem("superkey") !=="service" ? 'btn btn-primary ml-1' : 'btn btn-secondary ml-1'} 
                          disabled = {localStorage.getItem("superkey") ==="service"}
                          onClick={(e) => {}}>
                          บันทึก
                        </button>
                      </div>
                    </Form>
                    ):(                  
                      <Form>
                      <div className="form-group">
                        <label>เลขบัญชี</label>
                        <input
                          value={values.bookbank_code}
                          onChange={(e) => {
                            setFieldValue('bookbank_code', e.target.value);
                          }}
                          type="text"
                          name="bookbank_code"
                          className={'form-control ' + (getIn(touched, 'bookbank_code') ? (getIn(errors, 'bookbank_code') ? 'is-invalid' : '') : '')}
                          placeholder="เลขบัญชี"
                          readOnly
                        />
                        <ErrorMessage component="div" name="bookbank_code" className="invalid-feedback" />
                      </div>
                      <div className="form-group">
                        <label>ชื่อบัญชี</label>
                        <input
                          value={values.bookbank_name}
                          onChange={(e) => {
                            setFieldValue('bookbank_name', e.target.value);
                          }}
                          type="text"
                          name="bookbank_name"
                          className={'form-control ' + (getIn(touched, 'bookbank_name') ? (getIn(errors, 'bookbank_name') ? 'is-invalid' : '') : '')}
                          placeholder="ชื่อบัญชี"
                          readOnly
                        />
                        <ErrorMessage component="div" name="bookbank_name" className="invalid-feedback" />
                      </div>
                      <div className="form-group">
                        <label>ธนาคาร</label>
                        <select
                          name="bank_id"
                          className={'form-control ' + (getIn(touched, 'bank_id') ? (getIn(errors, 'bank_id') ? 'is-invalid' : '') : '')}
                          value={values.bank_id}
                          disabled
                          onChange={(e) => {
                            setFieldValue('bank_id', e.target.value);
                          }}
                        >
                          <option value={''}>{'--กรุณาเลือกธนาคาร--'}</option>
                          {bank.map((value) => (
                            <option value={value.bank_id} key={value.bank_id}>
                              {value.bankName}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage component="div" name="bank_id" className="invalid-feedback" />
                      </div>
                      <div className="form-group">
                        <label>จำนวนเงิน</label>
                        <input
                          value={values.cod_cost}
                          onChange={(e) => {
                            setFieldValue('cod_cost', e.target.value);
                          }}
                          type="number"
                          name="cod_cost"
                          className={'form-control ' + (getIn(touched, 'cod_cost') ? (getIn(errors, 'cod_cost') ? 'is-invalid' : '') : '')}
                          placeholder="จำนวนเงิน"
                          readOnly
                        />
                        <ErrorMessage component="div" name="cod_cost" className="invalid-feedback" />
                      </div>
                      <hr />
                      <div align="right">
                        <button
                          type="button"
                          className="btn btn-danger ml-1"
                          onClick={(e) => {
                            setModalMG(false);
                          }}
                        >
                          ยกเลิก
                        </button>
                        <button 
                          type="submit" 
                          className={localStorage.getItem("superkey") !=="service" ? 'btn btn-primary ml-1' : 'btn btn-secondary ml-1'} 
                          disabled = {localStorage.getItem("superkey") ==="service"}
                          onClick={(e) => {}}>
                          บันทึก
                        </button>
                      </div>
                    </Form>)}
                  </div>
                )}
              </Formik>
            </Tab>
            <Tab eventKey="3" title="พิมพ์ลาเบล">
              <div>
                <LabelFormSingle awbCode={printLabelSingle.awb_code !== null ? printLabelSingle.awb_code : printLabelSingle.ref_no} data={data} />
                <div className="row justify-content-md-center mt-5 mb-5">
                  <div className="col-sm-8">
                    <button
                      disabled={printLabelSingle.awb_code === null ? true : false}
                      type="button"
                      className="btn btn-success btn-lg mgTop-ct-20"
                      style={{ width: '100%' }}
                      onClick={() => {
                        setModalMG(false);
                        printJS({
                          printable: 'labelAddressSing',
                          type: 'html',
                          scanStyles: false,
                          css: ['https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap'],
                          style: '@page {size: 10cm 15cm;margin: 0.3cm;}',
                        });
                      }}
                    >
                      พิมพ์ลาเบล/ที่อยู่
                    </button>
                  </div>
                  <div className="col-sm-8 mt-3">
                    <button
                      disabled={printLabelSingle.awb_code === null ? true : false}
                      type="button"
                      className="btn btn-warning btn-lg mgTop-ct-20"
                      style={{ width: '100%' }}
                      onClick={() => {
                        setModalMG(false);
                        printJS({
                          printable: 'labelNormalSing',
                          type: 'html',
                          scanStyles: false,
                          css: ['https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap'],
                          style: '@page {size: 10cm 7cm;margin: 0cm;}',
                        });
                      }}
                    >
                      พิมพ์ลาเบล
                    </button>
                  </div>
                  {printLabelSingle && printLabelSingle.ref_no != '0' && printLabelSingle.ref_no != '' && localStorage.getItem('show_conote') === '1' &&
                  <div className="col-sm-8 mt-3">
                    <button
                        onClick={async () => {
                          try{
                            const result = await getIndividualConoteData(printLabelSingle.ref_no);
                            const dataResult = result?.data?.data;
                            if (dataResult) {
                              dataResult.conoteGroup = result.data.groupData;
                              setConoteData(dataResult);
                            }
                            else{
                              swalError({
                                text: "เไม่สามารถเรียกข้อมูล co note ได้"
                              });
                            }
                          }
                          catch(error){
                            console.log(error);
                            swalError({
                              text: "เไม่สามารถเรียกข้อมูล co note ได้"
                            });
                          }
                        }}
                        className="btn btn-primary btn-lg mgTop-ct-20"
                        style={{ width: '100%' }}>
                         ปริ้นใบ co-note
                        </button>
                  </div>}
                    </div>
                  </div>
            </Tab>
            <Tab eventKey="4" title="อัพโหลดลายเซ็น">
              <div className="row justify-content-md-center mt-5 mb-4">
                <div className="col-sm-8">
                  {notImg === true && printLabelSingle.image !== null && printLabelSingle.shipment_status === '5' ? (
                    <center>
                      <img className="image-border-barcode" src={`${printLabelSingle.image}?${nanoid()}`} width="200" height="200" alt="" onError={hideImg} />
                    </center>
                  ) : (
                    <center>
                      <img src="https://dummyimage.com/400x400" width="200" height="200" alt="notImg" />
                    </center>
                  )}
                  <div className="text-center pt-4 pb-2 pr-4 pl-4">
                    <label className="btn btn-info pr-5 pl-5 rounded-pill btn-candidate">
                      เลือกไฟล์ <input type="file" hidden onChange={handleFileChange} name="image" accept="image/*" />
                    </label>
                  </div>
                  <div>
                    <span>
                      <center>
                        <button
                          type="button"
                          className={notImg === true && printLabelSingle.image !== null && printLabelSingle.shipment_status === '5' ?  'btn btn-danger' : 'btn btn-secondary' }
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={(e) => {
                            //rename picture
                            deleteImage({
                              cust_code: localStorage.getItem("username")
                            });
                            
                          }}
                          disabled={notImg === true && printLabelSingle.image !== null  && printLabelSingle.shipment_status === '5' ? false : true}
                        >
                          <Icon.Trash /> ลบรูปลายเซ็น
                        </button> {' '}
                        <button
                          type="button"
                          className={(notImg === false || printLabelSingle.image === null) && printLabelSingle.shipment_status === '5' ?  'btn btn-primary' : 'btn btn-warning' }
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={(e) => {
                            //rename picture
                            retrieveImage({
                              cust_code: localStorage.getItem("username")
                            });
                            
                          }}
                          disabled={(notImg === false || printLabelSingle.image === null || printLabelSingle.image === 'https://porlor.co.th/mobile_upload/notImg.jpg') && printLabelSingle.shipment_status === '5' ? false : true}
                        >
                          <Icon.Recycle /> คืนค่าลายเซ็น
                        </button>
                      </center>
                    </span>{' '}
                  </div>
                  <small className="text-muted">รองรับไฟล์ *image เท่านั้น</small>
                </div>
              </div>
            </Tab>
            <Tab eventKey="5" title="หมายเหตุ">
              <br />
              <form>
                <div className="">
                  <label>หมายเหตุ</label>
                  <br/>
                  <input type={"hidden"}
                    name={"code"}
                  />
                  {
                    localStorage.getItem("superkey") ==="service" ? (
                      <div>
                        <textarea style={{ width: "100%",}}
                        value={remark}
                        name={"remark"}
                        onChange={(e) => {
                            setRemark(e.target.value);
                        }} />
                        <div align="right">
                          <button
                            type="button"
                            className={
                              "btn btn btn-primary ml-1"
                            }
                            onClick={(e) => {
                              updateBarCodeRemark(remark);
                            }}
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    ):(
                      <div>
                        {printLabelSingle.remarkMG !== null ? (
                          <p>{printLabelSingle.remarkMG}</p>) : (
                            <p>ไม่มีข้อสังเกต</p>
                          )}
                        <br />
                        {/* {printLabelSingle.dateRemarkMG !== null ? (
                          <p>เพิ่มหมายเหตุเมื่อ: {printLabelSingle.dateRemarkMG}</p>
                        ): " "} */}
                      </div>  
                    )
                  }
                </div>
              </form>
              <hr />
            </Tab>
            <Tab eventKey="6" title="แก้ไขวันรับพัสดุฯ">
              <br />
              <form>
                <div className="">
                  <label>แก้ไขวันรับพัสดุฯ</label>
                  <br/>
                  <input type={"hidden"}
                    name={"code"}
                  />
                  <div className="col-md-6" style={{display: 'flex', alignItems: 'center', paddingLeft: 0}}>
                    <DateTimePicker
                        label="แก้ไขวันรับพัสดุฯ"
                        value={receiverDate}
                        ampm={false}
                        onOpen={() => onHandleDatePickerFocus()}
                        onClose={() => clearTimeout(timerId)}
                        onChange={(receiverDate) => {
                            // receiverDate.setHours(receiverDate.getHours() + 3)
                            setReceiverDate(receiverDate)
                            setReceiverDate1(receiverDate)

                        }}
                        onYearChange={onYearChange}
                        onMonthChange={onYearChange}
                        labelFunc={dateFormat}
                    />
                  </div>
                  <br/>
                  <button
                    type="button"
                    className={
                      "btn btn btn-primary ml-1"
                    }
                    onClick={(e) => {
                      receiverDate1.setHours(receiverDate1.getHours() + 7)
                      updateReceiverDate(receiverDate1);
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </form>
              <hr />
            </Tab>
            <Tab eventKey="7" title="ภาพหลักฐาน">
              <br />
              <ModalsMGImagePicker code={printLabelSingle.code} onFinish={() => finishUpdate()} />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
      {conoteData &&
        (
            <div
              style={{display: "none"}}
          >
            <div id="printReport">
              <PrintContent
                data={conoteData}
                conoteGroups={conoteData.conoteGroup}
              />
            </div>
          </div>
        )
      }
    </>
  );
}


export default ModalsMG;
