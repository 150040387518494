import {useState, useCallback} from "react";
import {getHubZips} from "../services/hub-users.service";

const useGetHubZip = () => {

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [data, setData] = useState(null);

    const fetch = useCallback(
        async (id,) => {
            setData(null);
            setLoading(true);
            setErrorMessage(null);
            try {
                const result = await getHubZips(id);
                setData(result.data.result);
            }
            catch(error){
                console.error(error);
                setErrorMessage(error.message);
            }
            finally {
                setLoading(false);
            }
        },
        [],
    );


    return {
        fetch,
        loading,
        errorMessage,
        data,
    }
};

export default useGetHubZip;