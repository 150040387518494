import React from 'react';
import Modal from 'react-bootstrap/Modal';
import DateTH from '../../../Helper/date-th';
import * as Icon from "react-bootstrap-icons";
import {MultipleUploadProgress} from "../../../utility/MultipleUploadProgress";

function ModalsReceivedImg({ modalReceived, setModalReceived, deliverData, onDelete }) {
  return (
    <>
      <Modal
        className={'receivedImgModal'}
        show={modalReceived}
        onHide={() => {
         setModalReceived(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>รูปหลักฐานการส่ง</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '15px' }}>
          <div>
            <table className="table table-bordered">
              <thead style={{ backgroundColor: '#3f75c1' }}>
                <tr style={{ color: '#FFFFFF' }}>
                  <th scope="col" style={{ width: '8%' }}>วันที่/เวลาที่สแกน</th>
                  <th scope="col" style={{ width: '5%' }}>รหัสพนักงานขนส่ง</th>
                  <th scope="col" style={{ width: '30%' }}></th>
                  <th scope="col" style={{ width: '5%' }}></th>
                </tr>
              </thead>
              <tbody>
                {deliverData.map((value, index) => (
                  <tr key={index + 'Key' + value.awb_code}>
                    <td><DateTH date={value.receiver_date} /></td>
                    <td>{value.driver_code}</td>
                    {/* <td><img className="d-block w-100" src={value.image} height="500px" alt={value.image} /></td> */}
                    <td><img className="d-block w-100" src={value.image} height="500px" alt={value.image} /></td>
                    {onDelete && <td>
                      <button
                          type="submit"
                          className="btn btn-danger"
                          onClick={() => {
                            onDelete(value);
                            setModalReceived(false);
                            // finishUpdate();
                          }}
                      >
                        <Icon.TrashFill />
                      </button>
                    </td>}
                  </tr>
                ))}
              </tbody>
            </table>
            {deliverData.length === 0 ? <div style={{ color: 'red', textAlign: 'center', padding: '50px' }}>ไม่พบข้อมูล</div> : ''}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalsReceivedImg;
