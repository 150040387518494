import React from 'react'
import styles from './Image.module.css';

export default function Image({
    image,
    className
}) {
  return (
    <div style={{backgroundImage: `url(${image})`}} className={`${styles.image} ${className || ''}`}></div>
  )
}
