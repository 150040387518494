import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { nanoid } from 'nanoid';
import DateTH from '../../../Helper/date-th';
import Carousel from "react-bootstrap/Carousel";

function ModalImage({ modalImg, setModalImg, failImgData , setFailImgData}) {
  return (
    <>
      <Modal
        show={modalImg}
        onHide={() => {
          setModalImg(false);
          setFailImgData([]);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>หลักฐานการโทร</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <center>
            {failImgData.map((value, index) => (
                
                    <tr key={index + 'Key' + value.awb_code}> 
                        <Carousel>
                            {value.image.map((valueImg,indexImg)=> (
                                <Carousel.Item key={indexImg}>
                                    <img src={valueImg} width="300" height="500" alt={valueImg} />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <br />
                        <DateTH date={value.create_date} />
                    </tr>
                    ))}
            </center>
            {failImgData.length === 0 ? <div style={{ color: 'red', textAlign: 'center', padding: '50px' }}>ไม่พบข้อมูล</div> : ''}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalImage;
