import React, {useState} from 'react'
import {getHubEmployees} from "../service/HubService";
export const useFetchEmployees = () => {
    const [loading,setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [noData, setNoData] = useState(false);

    const fetchData = async (keyword, page) => {
        setLoading(true);
        setError(null);
        setData(null);
        setNoData(false);
        try {
            const result = await getHubEmployees(keyword,page);
            console.log({
                data: result.data,
            });
            if(result.data['status'] === 1){
                setNoData(true);
                return;
            }
            setData(result.data.rows);
        }
        catch (error){
            console.error(error);
            setError(error.message);
        }
        finally {
            setLoading(false);
        }
    };

    return {
        loading,
        noData,
        error,
        data,
        fetchData,
    }
};