import React, { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import FormatLabel from "../../mis/formatLabel";
import FormatLabelCod from "../../mis/formatLabelCod";
import FormatLabelAddress from "../../mis/formatLabelAddress";
import FormatLabelAddressCod from "../../mis/formatLabelAddressCod";
import ShowData from "./showData";
import { nanoid } from "nanoid";
import { getPickupParcelMobile } from "../../../Service/service.PickupParcel";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";

export default function PrintMobile() {
  const schema = Yup.object().shape({
    callPickupName: Yup.string().test(
      "is-callPickupName",
      "กรุณากรอกชื่อผู้เรียกรถ หรือเลขเรียกรถ",
      function (value) {
        const { booking_code } = this.parent;
        if (booking_code !== undefined) {
          return true;
        }
        if (value === undefined) {
          return false;
        } else {
          return true;
        }
      }
    ),

    // booking_code: Yup.string().test(
    //   "is-bookingCode",
    //   "กรุณากรอกเลขเรียกรถ",
    //   function (value) {
    //     const { callPickupName } = this.parent;
    //     if (callPickupName !== undefined) {
    //       return true;
    //     }
    //     if (value === undefined) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
  });

  var year = new Date().getFullYear();
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  const [data, setData] = useState([]);
  const [dataSum, setDataSum] = useState("");
  const [dataLabel, setDataLabel] = useState({});
  const [pageNo, setCurrentPage] = useState(1);
  const [last_page, setLast_page] = useState(1);
  const [page, setPage] = useState({
    current_page: 1,
    last_page: 1,
    total: 0,
  });
  const [search, setSearch] = useState("");
  const [typeLabel, setTypeLabel] = useState(0);
  const [loading, Loading] = useState(0);
  const [errorMassNoData, setErrorMassNoData] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function loadData(data) {
    Loading(1);
    let res = await getPickupParcelMobile(data);
    if (res.data !== false && res.data !== undefined) {
      setData(res.data.result);
      setPage({
        current_page: res.data.pageNo,
        last_page: res.data.maxPage,
        total: res.data.numRowsAll,
      });
      setLast_page(res.data.maxPage);
      setDataSum(res.sum);
      setErrorMassNoData(0);
    } else {
      setData([]);
      setCurrentPage(1);
      setLast_page(1);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      setErrorMassNoData(1);
    }
    Loading(0);
  }

  const handleChangePage = (e, newPage) => {
    e.preventDefault();
    loadData({
      callPickupName: search !== "" ? search : "",
      booking_code: "",
      createDate: "",
      pageNo: newPage,
      perPage: "5",
    });
    setCurrentPage(newPage);
  };

  return (
    <div>
      <div className="text-center text-title-color">
        <h2>พิมพ์ลาเบลรับพัสดุ</h2>
      </div>
      <br />
      <Formik
        validationSchema={schema}
        initialValues={{
          callPickupName: search !== "" ? search : "",
          booking_code: "",
          createDate: "",
          pageNo: "1",
          perPage: "5",
        }}
        onSubmit={(value) => {
          loadData(value);
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-sm-6">
                  <span>ค้นหาชื่อผู้เรียกรถ/เลขเรียกรถ</span>
                  <input
                    value={values.callPickupName}
                    type="text"
                    name="callPickupName"
                    id="callPickupName"
                    onBlur={handleBlur}
                    className={
                      "form-control " +
                      (getIn(touched, "callPickupName")
                        ? getIn(errors, "callPickupName")
                          ? "is-invalid"
                          : "is-valid"
                        : "") +
                      ""
                    }
                    onChange={(e) => {
                      setFieldValue("callPickupName", e.target.value);
                      setSearch(e.target.value);
                    }}
                  />
                  <ErrorMessage
                    component="div"
                    name="callPickupName"
                    className="invalid-feedback"
                  />
                </div>
                {/* <div className="col-sm">
                  <span>ค้นหาเลขเรียกรถ</span>
                  <input
                    value={values.booking_code}
                    type="text"
                    name="booking_code"
                    id="booking_code"
                    onBlur={handleBlur}
                    className={
                      "form-control " +
                      (getIn(touched, "booking_code")
                        ? getIn(errors, "booking_code")
                          ? "is-invalid"
                          : "is-valid"
                        : "") +
                      ""
                    }
                    onChange={(e) => {
                      setFieldValue("booking_code", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    component="div"
                    name="booking_code"
                    className="invalid-feedback"
                  />
                </div> */}
              </div>
              <div className="text-center">
                <br />
                <button className="btn btn-primary" type="submit">
                  <Icon.Search /> ค้นหา
                </button>{" "}
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setFieldValue("callPickupName", "");
                    setFieldValue("booking_code", "");
                    setSearch("");
                    setData([]);
                  }}
                >
                  <Icon.ArrowClockwise /> ล้างค่า
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ShowData
        data={data}
        page={page}
        last_page={last_page}
        pageNo={pageNo}
        loading={loading}
        errorMassNoData={errorMassNoData}
        handleShow={handleShow}
        setDataLabel={setDataLabel}
        dataSum={dataSum}
        setTypeLabel={setTypeLabel}
        handleChangePage={handleChangePage}
      />

      <div name="modalPrintLabel">
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          dialogClassName="modal-content"
        >
          <Modal.Header closeButton>
            <Modal.Title>บาร์โค้ด : {dataLabel.awb_code}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className={typeLabel === "1" ? "" : "hidden-ct"}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "90%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {dataLabel.codCost === "0.00" ||
              dataLabel.codCost === null ||
              dataLabel.codCost === "" ? (
                <FormatLabel
                  key={nanoid()}
                  supplies={{
                    custCode: dataLabel.cust_code,
                    number: "-",
                    receiverName: dataLabel.receiver_name,
                    awbCode: dataLabel.awb_code,
                    date: date,
                    month: month,
                    year: year,
                    weight: dataLabel.weight,
                    codCost: dataLabel.codCost,
                    ref_no: dataLabel.ref_no,
                  }}
                />
              ) : (
                <FormatLabelCod
                  key={nanoid()}
                  supplies={{
                    custCode: dataLabel.cust_code,
                    number: "-",
                    receiverName: dataLabel.receiver_name,
                    awbCode: dataLabel.awb_code,
                    date: date,
                    month: month,
                    year: year,
                    weight: dataLabel.weight,
                    codCost: dataLabel.codCost,
                      ref_no: dataLabel.ref_no,
                  }}
                />
              )}
            </div>

            <div
              className={typeLabel === "2" ? "" : "hidden-ct"}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "90%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {dataLabel.codCost === "0.00" ||
              dataLabel.codCost === null ||
              dataLabel.codCost === "" ? (
                <FormatLabelAddress
                  key={nanoid()}
                  supplies={{
                    receiver_name: dataLabel.receiver_name,
                    receiver_address: dataLabel.receiver_address,
                    receiver_amphur: dataLabel.receiver_amphur,
                    receiver_province: dataLabel.receiver_province,
                    receiver_phone: dataLabel.receiver_phone,
                    cust_Code: dataLabel.cust_code,
                    number: "-",
                    awb_code: dataLabel.awb_code,
                    date: date,
                    month: month,
                    year: year,
                    weight: dataLabel.weight,
                    codCost: dataLabel.codCost,
                      ref_no: dataLabel.ref_no,
                  }}
                />
              ) : (
                <FormatLabelAddressCod
                  key={nanoid()}
                  supplies={{
                    receiver_name: dataLabel.receiver_name,
                    receiver_address: dataLabel.receiver_address,
                    receiver_amphur: dataLabel.receiver_amphur,
                    receiver_province: dataLabel.receiver_province,
                    receiver_phone: dataLabel.receiver_phone,
                    cust_Code: dataLabel.cust_code,
                    number: "-",
                    awb_code: dataLabel.awb_code,
                    date: date,
                    month: month,
                    year: year,
                    weight: dataLabel.weight,
                    codCost: dataLabel.codCost,
                      ref_no: dataLabel.ref_no,
                  }}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
