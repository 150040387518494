import Instance from '../Helper/axios';
import { URL, URLTEST, urlLocal } from '../Helper/baseURL';

export async function GetPrintLabel(param) {
  try {
    const formData = new FormData();
    Object.entries(param).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await Instance.post(URL + 'printLabel/PrintLabel/showPrintLabel', formData);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function UpdateLabel(data) {
  try {
    const response = await Instance.post(URL + 'printLabel/PrintLabel/updateLabel', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function UpdateStatus(data) {
  try {
    const response = await Instance.post(URL + 'printLabel/PrintLabel/updateShipmentStatus', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function print_genAwbCode(data) {
  try {
    const response = await Instance.post(URL + 'printLabel/PrintLabel/print_genAwbCode', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export function refreshParcelStatus(parcelIds){
  return Instance.post(URL + 'printLabel/PrintLabel/updateMultiplePrintStatus', {
    codes: parcelIds,
  });
}