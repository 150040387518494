import React, {useState, useEffect} from 'react'
import GenericTable from '../Shared/GenericTable';
import HubPageTitle from '../Shared/HubPageTitle';
import SearchInput from '../Shared/SearchInput';
import { useHistory } from 'react-router';
import {useTranslation} from "react-i18next";
import useDebouncedState from "../../../utility/hooks/useDebouncedState";
import {useFetchEmployees} from "./hooks/useFetchEmployees";
import {Spinner} from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import {Alert} from "react-bootstrap";

const EmployeesList = ({hubZip}) => {
    const {t} = useTranslation();
    const [search,currentValue, setSearch] = useDebouncedState('', 300);
    const [page, setPage] = useState(0);
    const {fetchData,data,loading,error,noData} = useFetchEmployees();

    const history = useHistory();
    const employeesMetaData = [
        {
            text: 'name',
            width: '100%',
            render: (data) => <span className='w-100 text-center d-block'>{data.name} {data.lastname}</span>,
            rowClick: (data) => history.push(`/hub/General/${data.id}`),
        }
    ];

    useEffect(() => {
        setPage(0);
        fetchData(search,0);
    }, [search]);

    const handleChangePage = (e,newPage) => {
        setPage(newPage);
        fetchData(search,newPage);
    };
  return (
    <>
      <HubPageTitle className="">{t('hub.employees.title')}</HubPageTitle>
      <SearchInput submit={() => {}} placeholder={t('hub.employees.placeholder')} value={currentValue} setValue={(e) => setSearch(e.target.value)} />
      {data &&
          <>
              <GenericTable
                  metadata={employeesMetaData}
                  showHeader={false}
                  roundedBorders={true}
                  data={data.result}
                  keyExtractor={(item) => item.id}
              />
              <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '5px'}}>
                  <Pagination
                      count={data.maxPage}
                      page={parseInt(data.pageNo)}
                      onChange={handleChangePage}
                      color="primary"
                      size="small"
                      siblingCount={1}
                  />
              </div>
          </>
      }
      {noData &&
        <div className="spinner-global-content">
            <p className="text-center">{t('common.no_data')}</p>
        </div>
      }
      {loading &&
          <div className="spinner-global-content">
              <Spinner variant="success" animation="border" />
          </div>
      }
        {error &&
                <Alert style={{width: '100%'}} variant="danger">
                    {error}
                </Alert>
        }
    </>
  )
}

export default EmployeesList;