import * as Yup from "yup";
// import { checkCitizen } from "../../../Helper/shareFunction";

const parcelSchema = Yup.object().shape({
  sender: Yup.object().shape({
    citizenID: Yup.string()
        .required("กรุณากรอก เลขประจำตัวประชาชน")
        // .min(13, 'โปรดตรวจสอบหมายเลขบัตรประจำตัวประชาชนของคุณอีกครั้ง\n' +
        //     'ความยาวควรต่ำกว่า13')
        // .max(13, 'โปรดตรวจสอบหมายเลขบัตรประจำตัวประชาชนของคุณอีกครั้ง\n' +
        //     'ความยาวควรต่ำกว่า13'),
        .max(13, 'กรุณาตรวจสอบ เลขประจำตัวประชาชน อีกครั้ง'),
    fullName: Yup.string()
      .required("กรุณากรอก ชื่อ-นามสกุล")
      .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
    phoneNumber: Yup.string().when("customerGroup", (value,schema) => {
        if(localStorage.getItem('superkey') === 'credit'){
            return schema.notRequired();
        }
        else{
            return schema
                .required("กรุณากรอก เบอร์โทรศัพท์")
                .matches(/^(?=[0-9]*$)(?:.{4,10}|.{30})$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง");
        }
    }),
    return_doc: Yup.boolean(),
    num_return_doc: Yup.string().when("return_doc", (return_doc,schema) => {
        if(return_doc){
            return schema
                .required("ฟิลด์นี้จำเป็นต้องระบุ")
                .max(3,"ขนาดของฟิลด์นี้ต้องน้อยกว่าหรือเท่ากับ3")
                .matches(/^[0-9]+$/, "ช่องนี้ควรเป็นตัวเลขที่ถูกต้อง");
        }
        else {
            schema.notRequired();
        }
    }),
    materialAccountName: Yup.string().nullable(true),
    materialAccountNumber: Yup.string().nullable(true),
    bankName: Yup.string().nullable(true),
  }),
  recipient: Yup.array().of(
    Yup.object().shape({
      fullName: Yup.string()
        .required("กรุณากรอก ชื่อ-นามสกุล")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      phoneNumber: Yup.string()
        .required("กรุณากรอก เบอร์โทรศัพท์ ")
        .matches(/^(?=[0-9]*$)(?:.{9}|.{10}|.{30})$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง"),
      homeNumber: Yup.string()
        .required("กรุณากรอก เลขที่อยู่"),
        // .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "กรุณาตรวจสอบ เลขที่อยู่ อีกครั้ง"),
      district: Yup.string().required("กรุณากรอก ตำบล "),
      amphoe: Yup.string().required("กรุณากรอก อำเภอ "),
      province: Yup.string().required("กรุณากรอก จังหวัด "),
      zipcode: Yup.string().required("กรุณากรอก รหัสไปรษณีย์ "),

      materialAccountName: Yup.string().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณากรอก ชื่อบัญชี ");
        } else {
          return schema.notRequired();
        }
      }),
      materialAccountNumber: Yup.string().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณากรอก เลขที่บัญชี ").matches(/^\d{9,18}$/, "กรุณาตรวจสอบ เลขที่บัญชี");
        } else {
          return schema.notRequired();
        }
      }),
      materialPriceCode: Yup.number().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณากรอก จำนวนเงิน COD ").min(1, "กรุณาระบุ จำนวนเงิน COD มากกว่า หรือเท่ากับ 1");
        } else {
          return schema.notRequired();
        }
      }),
      bankName: Yup.string().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณาระบุบ ธนาคาร");
        } else {
          return schema.notRequired();
        }
      }),
      // total: Yup.string().required("กรุณาระบุ จำนวนเงิน"),
    })
  ),
});
export default parcelSchema;
