import React, { useState, useEffect } from 'react';
import { ProgressModal } from "../../../Components/ProgressModal";
import ReactDOM from "react-dom";
import MultipleImagePicker from '../../../Components/MultipleImagePicker/MultipleImagePicker';
import useProofImagesUpload from './hooks/useProofImagesUpload';
import { Spinner } from "react-bootstrap";
import { swalError, swalSuccess } from '../../../Components/sweetAlert';
 
export default function ModalsMGImagePicker({
  onFinish,
  code,
}) {
  const [images, setImage] = useState([]);
  const handleChange = (images) => {
    setImage((currentImages) => [...images,...currentImages]);
  };
  const {loading, message, progress, error, upload } = useProofImagesUpload();

  const handleDelete = (deleteIndex) => {
    setImage((currentImage) => currentImage.filter((item, index) => index !== deleteIndex))
  };

  const page = (
    <>
        <MultipleImagePicker onDelete={handleDelete} title={"ภาพหลักฐาน"} images={images} onChange={handleChange}  />
        <button onClick={() => upload(images,code)}  className={!!loading ? "btn btn-secondary" : "btn btn-primary"} style={{width: '100%'}} disabled={!!loading}>
          ที่อัพโหลด
        </button>
    </>
  );

  const loader = (
        <div className="spinner-global-content">
            <Spinner variant="success" animation="border" />
        </div>
  );

  useEffect(() => {
    if (message) {
      swalSuccess({
        text: message
      });
      onFinish();
    }
  }, [message,onFinish]);

  useEffect(() => {
    if (error) {
        swalError({
          text: error
        })
    }
  }, [error]);

  return (
    <div style={{ width: '100%' }}>
      {loading && loader}
      {!loading && page}
      {loading && progress && ReactDOM.createPortal(
        <ProgressModal show={true} current={progress} total={100} label={'ความคืบหน้าในการอัปโหลด'}  />,
        document.getElementById('root'),
      )}
    </div>
  )
}