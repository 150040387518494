import {useState} from "react";
import Instance from '../../../../Helper/axios';
const useHubAssignmentManagement = () => {
    const [employeesZip, setEmployeesZip] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const fetchEmployeesZip = async (idEmp) => {
        setEmployeesZip(null);
        setErrorMessage(null);
        setLoading(true);
        try{
            console.log({
                idEmp
            });
            const result = await Instance.get(`/hub/HubController/empZipCodes?emp=${idEmp}`);
            setEmployeesZip(result.data.result);
        }
        catch (error){
            setErrorMessage(error);
        }
        finally {
            setLoading(false);
        }
    }

    const deleteEmployeeZip = async (idEmp,id) => {
        setEmployeesZip(null);
        setErrorMessage(null);
        setLoading(true);
        try{
            const result = await Instance.delete(`/hub/HubController/removeEmpZip?id=${id}`);
            await fetchEmployeesZip(idEmp);
        }
        catch (error){
            setErrorMessage(error);
        }
        finally {
            setLoading(false);
        }
    }

    const addEmployeeZip = async (idEmp,idZip) => {
        setEmployeesZip(null);
        setErrorMessage(null);
        setLoading(true);
        try{
            const result = await Instance.post(`/hub/HubController/addZipCodeToEmp`, {
                emp: idEmp,
                zipId: idZip
            });
            await fetchEmployeesZip(idEmp);
        }
        catch (error){
            setErrorMessage(error);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        employeesZip,
        loading,
        errorMessage,
        fetchEmployeesZip,
        addEmployeeZip,
        deleteEmployeeZip
    }
};

export default useHubAssignmentManagement;