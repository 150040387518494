import React, { useState } from 'react';
import { address } from '../Data/addressData.json';
import * as Icon from 'react-bootstrap-icons';
import {nanoid} from "nanoid";

export default function InputAutocompleteV2(props) {
  const [search, SetSearch] = useState([]);
  const [showAll, SetshowAll] = useState(false);
  return (
    <>
      <input
        style={{ fontFamily: 'Kanit, FontAwesome', border: '2px solid' }}
        placeholder="&#xF002; ค้นหา"
        autocomplete="off"
        type="text"
        className={props.className !== undefined ? props.className : 'form-control search'}
        aria-label={props.id}
        onKeyUp={(e) => {
          if (e.target.value.length >= 1) {
            let result = address.filter((value) => (value.subdistrictName + ' ' + value.districtName + ' ' + value.provinceName + ' ' + value.postCode).includes(e.target.value));
            SetSearch(result);
          } else {
            SetSearch([]);
          }
          props.retrunValue({
            districtName: '',
            postCode: '',
            provinceCode: 0,
            provinceName: '',
            subdistrictName: '',
          });
        }}
        id={props.id}
        onBlur={(e) => {
          if (props.retrunValueOnBlur !== undefined) {
            props.retrunValueOnBlur(e);
          }
          if (document.getElementById(props.id).value.length !== 0) {
            let result = address.filter((value) => value.subdistrictName + ' ' + value.districtName + ' ' + value.provinceName + ' ' + value.postCode === document.getElementById(props.id).value);
            if (result.length !== 0) {
              SetSearch(result);
              document.getElementById(props.id).value = result[0].subdistrictName + ' ' + result[0].districtName + ' ' + result[0].provinceName + ' ' + result[0].postCode;

              props.retrunValue(result[0]);
              SetSearch([]);
            } else {
              document.getElementById(props.id).value = '';
              props.retrunValue({
                districtName: '',
                postCode: '',
                provinceCode: 0,
                provinceName: '',
                subdistrictName: '',
              });
            }
          }
        }}
      />
      <ul data-bs-spy="scroll" className={`list-group table-wrapper-scroll-y my-custom-scrollbar  ${search.length >= 1 ? ' showList' : ' hidden-ct '}`}>
        {search.map((value, i) => (
          <li
            style={{ height: '40px' }}
            className="list-group-item list-group-item-action cursor-pointer"
            onClick={() => {
              document.getElementById(props.id).value = '';
              props.retrunValue(value);
              SetSearch([]);
            }}
            key={nanoid()}
          >
            {value.subdistrictName + ' ' + value.districtName + '  ' + value.provinceName + ' ' + value.postCode}
          </li>
        ))}
      </ul>
    </>
  );
}
