import React from "react";
import HubHeader from "./HubHeader";
import HubFooter from "./HubFooter";
import {useHistory} from "react-router-dom";

export default function PublicLayout(props) {
  const history = useHistory();
  if(localStorage.getItem('superkey') !== 'hub'){
      history.push('/');
  }
  return (
    <>
      <HubHeader {...props} />
      <div
        style={{ minHeight: "calc(100vh - 200px)" }}
      >
        {props.children}
      </div>
      <HubFooter {...props} />
    </>
  );
}
