import * as Yup from "yup";
// import { checkCitizen } from "../../../Helper/shareFunction";

const applySchema = Yup.object().shape({
  information: Yup.object().shape({
    titleName: Yup.string().required("กรุณาระบุ คำนำหน้าชื่อ"),
    name: Yup.string()
      .required("กรุณากรอก ชื่อ")
      .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
    lastName: Yup.string()
      .required("กรุณากรอก นามสกุล")
      .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
    salary: Yup.string()
      .required("กรุณากรอก เงินเดือน")
      .matches(/^[0-9]+$/, "กรุณากรอกข้อมูลเป็นตัวเลข"),
    addresNum: Yup.string()
      .required("กรุณากรอก ที่อยู่บ้านเลขที่")
      .matches(/^[0-9/]+$/, "กรุณาตรวจสอบ ที่อยู่บ้านเลขที่ อีกครั้ง"),
    // moo: Yup.string()
    //   .required("กรุณากรอก หมู่")
    //   .matches(/^[0-9]+$/, "กรุณาตรวจสอบ หมู่ อีกครั้ง"),
    road: Yup.string().required("กรุณากรอก ถนน"),
    // alley: Yup.string().required("กรุณากรอก ซอย"),
    subdistrict: Yup.string().required("กรุณากรอก ตำบล/แขวง"),
    district: Yup.string().required("กรุณากรอก อำเภอ/เขต"),
    province: Yup.string().required("กรุณากรอก จังหวัด"),
    postCode: Yup.string().required("กรุณากรอก รหัสไปรษณีย์"),
    // phone: Yup.string()
    //   .required("กรุณากรอก เบอร์โทรศัพท์")
    //   .matches(/^[0-9]{9,10}$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง"),
    mobilePhone: Yup.string()
      .required("กรุณากรอก เบอร์โทรศัพท์")
      .matches(/^[0-9]{9,10}$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง"),
    lineID: Yup.string().required("กรุณากรอก ไลน์ไอดี"),
    email: Yup.string()
      .required("กรุณากรอก อีเมล์")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "กรุณาตรวจสอบ อีเมล์ อีกครั้ง"
      ),
    liveWith: Yup.string().required("กรุณาระบุ ว่าอาศัยกับครอบครัว"),
    birthYear: Yup.string().required("กรุณาระบุ ปี"),
    birthMonth: Yup.string().required("กรุณาระบุ เดือน"),
    birthDay: Yup.string().required("กรุณาระบุ วัน"),
    age: Yup.string().required("กรุณากรอก อายุ"),
    race: Yup.string().required("กรุณาระบุ เชื้อชาติ"),
   
    nationality: Yup.string().required("กรุณาระบุ สัญชาติ"),
   
    religion: Yup.string().required("กรุณาระบุ ศาสนา"),
    
    thaiID: Yup.string()
      .required("กรุณากรอก หมายเลขบัตรประชาชน")
      .test(
        "is-thaiID",
        "กรุณาตรวจสอบเลขบัตรประชาชนอีกครั้ง",
        function checkCitizen(value) {
          if (value === undefined) {
            return false;
          }
          if (value.length !== 13) {
            return false;
          }
          let sum = 0;
          for (let i = 0; i < 12; i++) {
            sum += parseInt(value.charAt(i)) * (13 - i);
          }
          let mod = sum % 11;
          let check = (11 - mod) % 10;
          if (check === parseInt(value.charAt(12))) {
            return true;
          } else {
            return false;
          }
        }
      ),
    exDate_ThaiDay: Yup.string().required("กรุณาระบุ วัน"),
    exDate_ThaiMonth: Yup.string().required("กรุณาระบุ เดือน"),
    exDate_ThaiYear: Yup.string().required("กรุณาระบุ ปี"),
    military: Yup.string().when("titleName", (value, schema) => {
      if (value === "นาย") {
        return schema.required("กรุณาระบุ ภาวะทางทหาร");
      } else {
        return schema.notRequired();
      }
    }),
    status: Yup.string().required("กรุณาระบุ สถานภาพ"),

    // titleNameSpouse: Yup.string().when("status", (value, schema) => {
    //   if (value === "แต่งงาน") {
    //     return schema.required("กรุณากรอก คำนำหน้าชื่อ");
    //   } else {
    //     return schema.notRequired();
    //   }
    // }),
    //  .required("กรุณาระบุ คำนำหน้าชื่อ"),
    nameSpouse: Yup.string().when("status", (value, schema) => {
      if (value === "แต่งงาน") {
        return schema
          .required("กรุณากรอก ชื่อ")
          .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ");
      } else {
        return schema.notRequired();
      }
    }),
    // .required("กรุณากรอก ชื่อ")
    // .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
    lastNameSpouse: Yup.string().when("status", (value, schema) => {
      if (value === "แต่งงาน") {
        return schema
          .required("กรุณากรอก นามสกุล")
          .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ");
      } else {
        return schema.notRequired();
      }
    }),
    // .required("กรุณากรอก นามสกุล")
    // .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
    workplaceSpouse: Yup.string().when("status", (value, schema) => {
      if (value === "แต่งงาน") {
        return schema.required("กรุณากรอก สถานที่ทำงาน");
      } else {
        return schema.notRequired();
      }
    }),
    // .required("กรุณากรอก สถานที่ทำงาน"),
    positionSpouse: Yup.string().when("status", (value, schema) => {
      if (value === "แต่งงาน") {
        return schema.required("กรุณากรอก ตำแหน่ง");
      } else {
        return schema.notRequired();
      }
    }),
    // .required("กรุณากรอก ตำแหน่ง"),
    childAmontSpouse: Yup.string().when("status", (value, schema) => {
      if (value === "แต่งงาน") {
        return schema
          .required("กรุณากรอก จำนวนบุตร")
          .matches(/^[0-9]+$/, "กรุณาตรวจสอบ จำนวนบุตร อีกครั้ง");
      } else {
        return schema.notRequired();
      }
    }),
    // .required("กรุณากรอก จำนวนบุตร")
    // .matches(/^[0-9]+$/, "กรุณาตรวจสอบ จำนวนบุตร อีกครั้ง"),
  }),

  ///////////////////////////////////////////////////////////////////////////////////

  familyHistory: Yup.object().shape({
      father: Yup.object().shape({
      // titleName: Yup.string().required("กรุณาระบุ คำนำหน้าชื่อ"),
      name: Yup.string()
        .required("กรุณากรอก ชื่อ")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      lastName: Yup.string()
        .required("กรุณากรอก นามสกุล")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      age: Yup.string()
        .required("กรุณากรอก อายุ")
        .matches(/^[0-9]+$/, "กรุณาตรวจสอบ อายุ อีกครั้ง"),
      career: Yup.string().required("กรุณากรอก อาชีพ"),
    }),
      mother: Yup.object().shape({
      // titleName: Yup.string().required("กรุณาระบุ คำนำหน้าชื่อ"),
      name: Yup.string()
        .required("กรุณากรอก ชื่อ")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      lastName: Yup.string()
        .required("กรุณากรอก นามสกุล")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      age: Yup.string()
        .required("กรุณากรอก อายุ")
        .matches(/^[0-9]+$/, "กรุณาตรวจสอบ อายุ อีกครั้ง"),
      career: Yup.string().required("กรุณากรอก อาชีพ"),
    }),

    brethrenAmont: Yup.string()
      .required("กรุณากรอก จำนวนพี่น้อง")
      .matches(/^[0-9]+$/, "กรุณาตรวจสอบ จำนวนพี่น้อง อีกครั้ง"),
    male: Yup.string().required("กรุณากรอก พี่น้องผูู้ชาย"),
    female: Yup.string().required("กรุณากรอก พี่น้องผู้หญิง"),
    personWho: Yup.string().required("กรุณาระบุ เป็นบุตรคนที่"),
  }),

  ///////////////////////////////////////////////////////////////////////////////////

  familyAmont: Yup.array().of(
    Yup.object().shape({
      titleName: Yup.string().required("กรุณาระบุ คำนำหน้าชื่อ"),
      name: Yup.string()
        .required("กรุณากรอก ชื่อ")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      lastName: Yup.string()
        .required("กรุณากรอก นามสกุล")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      age: Yup.string()
        .required("กรุณากรอก อายุ")
        .matches(/^[0-9]+$/, "กรุณาตรวจสอบ อายุ อีกครั้ง"),
      career: Yup.string().required("กรุณากรอก อาชีพ"),
    })
  ),

  ///////////////////////////////////////////////////////////////////////////////////

  education: Yup.object().shape({
    educationLevel: Yup.string().required("กรุณาระบุ ระดับการศึกษา"),
    institution: Yup.string()
      .required("กรุณากรอก ชื่อสถาบันการศึกษา")
      .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
  }),

  ///////////////////////////////////////////////////////////////////////////////////

  experience: Yup.array().of(
    Yup.object().shape({
      company: Yup.string().required("กรุณากรอก ชื่อสถานที่ทำงาน"),
      start_year: Yup.string().required("กรุณากรอก ปีเริ่มทำงาน"),
      end_year: Yup.string().required("กรุณากรอก  ปีสิ้นสุดทำงาน "),
      position: Yup.string().required("กรุณากรอก ตำแหน่ง"),
      jobDescription: Yup.string().required("กรุณากรอก ลักษณะงาน"),
      salary: Yup.string().required("กรุณากรอก เงินเดือน"),
      reasonsOfResignation: Yup.string().required("กรุณากรอก เหตุที่ออก"),
    })
  ),

  ///////////////////////////////////////////////////////////////////////////////////

  languages: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required("กรุณากรอก ภาษา"),
      speaking: Yup.string().required("กรุณาระบุ ระดับทักษะทางด้านการพูด"),
      read: Yup.string().required("กรุณาระบุ ระดับทักษะทางด้านการอ่าน"),
      write: Yup.string().required("กรุณาระบุ ระดับทักษะทางด้านการเขียน"),
    })
  ),

  ///////////////////////////////////////////////////////////////////////////////////

  ability: Yup.object().shape({
    useComputer: Yup.string().required("กรุณาระบุ "),
    driveCar: Yup.string().required("กรุณาระบุ "),
    driverLicense: Yup.string().when("driveCar", (value, schema) => {
      if (value === "1") {
        return schema.required("กรุณาระบุเลขที่ใบขับขี่");
      } else {
        return schema.notRequired();
      }
    }),
    workingProvinces: Yup.string().required("กรุณาระบุ "),
    // AbilityOther: Yup.string().required("กรุณาระบุ "),
  }),

  other: Yup.object().shape({
    emergencyContact: Yup.object().shape({
      // titleName: Yup.string().required("กรุณาระบุ คำนำหน้าชื่อ"),
      name: Yup.string()
        .required("กรุณากรอก ชื่อ")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      lastName: Yup.string()
        .required("กรุณากรอก นามสกุล")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      phone: Yup.string()
      .required("กรุณากรอก เบอร์โทรศัพท์")
      .matches(/^[0-9]{9,10}$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง"),
      relationship: Yup.string().required("กรุณาระบุ ความสัมพันธ์"),
    }),
    sick: Yup.string().required("กรุณาระบุ "),
    sickDetail : Yup.string().when("sick", (value, schema) => {
      if (value === "1") {
        return schema.required("กรุณาระบุโรคหรืออาการป่วยที่เคยเป็น ");
      } else {
        return schema.notRequired();
      }
    }),
    applyThisCompany: Yup.string().required("กรุณาระบุ "),
    applyDate: Yup.string().when("applyThisCompany", (value, schema) => {
      if (value === "1") {
        return schema.required("กรุณาระบุ ปีที่ทำงาน");
      } else {
        return schema.notRequired();
      }
    }),
    applyPosition: Yup.string().when("applyThisCompany", (value, schema) => {
      if (value === "1") {
        return schema.required("กรุณาระบุ ตำแหน่ง");
      } else {
        return schema.notRequired();
      }
    }),
  }),
});
export default applySchema;
