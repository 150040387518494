import Instance from "../Helper/axios";
import { URL, urlLocal , URLTEST } from "../Helper/baseURL";

export async function ConoteService(data) {
  // console.log("data:" + JSON.stringify(data));
  try {
    const response = await Instance.post(
      URL + "manage/ManageConote/getParcels",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateConoteStatusService(data) {
  // console.log("data:" + JSON.stringify(data));

  try {
    const response = await Instance.post(
      URL + "manage/ManageConote/updateConoteStatus",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

