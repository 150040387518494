import {useState} from 'react';
import Instance from '../../../../Helper/axios';
import {URL} from "../../../../Helper/baseURL";

export const useGetScanInOut = (onError) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const fetch = async (page, status, dateFilters) => {
        setLoading(true);
        try{
            const result = await Instance.post(URL + `hub/HubStatus/getHubScanList/${localStorage.getItem('id')}/${page}/${status}`, {
                ...(dateFilters ?  {dateFilters} : {})
            })
            setData(result.data.result);
        }
        catch(error){
            onError(error);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, fetch};
}