import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import swal from "sweetalert";
import LongDateTH from "../../../utility/LongDateTH";
import { monthTH } from "../../../Data/month-th.json";
import {
  getPickupParcel,
  AssignTask,
  GetEmp,
  CancelAssignTask,
} from "../../../Service/service.PickupParcel";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
// import Select from "react-dropdown-select";
// import ShowModal from "./assignModal";

function CallCar(props) {
  const { user } = props;
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [dataEmp, setDataEmp] = useState([]);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });
  const [dateNow, setDateNow] = useState(1);
  const [valueYear, setYear] = useState("");
  const [valueMonth, setMonth] = useState("");
  const [valueDay, setDay] = useState("");
  const [errorDate, setErrorDate] = useState(0);
  const pageSizes = [10, 20, 50, 100];
  const [setErrorMassNoData, SetErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);
  const [callPickupName, setCallPickupName] = useState("");
  const [placePickup, setPlacePickup] = useState("");
  const [statusPickup, setStatusPickup] = useState("2");
  const [pageNo, setCurrentPage] = useState(1);
  const [perPage, setPageSize] = useState(10);

  const [transportWorker, setTransportWorker] = useState("");
  const [pickupParcelId, setPickupParcelId] = useState("");

  const [reasonCancel, setReasonCancel] = useState("");
  const [typeSender, setTypeSender] = useState("2");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showC, setShowC] = useState(false);
  const handleCloseC = () => setShowC(false);
  const handleShowC = () => setShowC(true);

  const [coust, setCoust] = useState("");
  const [coustCadit, setCoustCadit] = useState("");

  useEffect(() => {
    fetchData();
    getEmpData();
  }, [pageNo, perPage]);

  async function fetchData(data) {
    let criteria = {
      callPickupName: callPickupName !== "" ? callPickupName : "",
      createdDate:
        valueYear !== "" && valueMonth !== "" && valueDay !== ""
          ? valueYear + "-" + valueMonth + "-" + valueDay
          : "",
      placePickup: placePickup !== "" ? placePickup : "",
      statusPickup: statusPickup !== "" ? statusPickup : "",
      pageNo: pageNo !== "" ? pageNo : "",
      perPage: perPage !== "" ? perPage : "",
      pickupType: typeSender !== "" ? typeSender : "",
    };
    Loading(1);
    let res = await getPickupParcel(data !== undefined ? data : criteria);
    setCoust(res.totalRow);
    setCoustCadit(res.totalRowCredit);
    if (res.data.numRowsAll > 0) {
      setData(res.data.result);
      setPage({
        current_page: res.data.pageNo,
        last_page: res.data.maxPage,
        total: res.data.numRowsAll,
      });
      SetErrorMassNoData(0);
    } else {
      setData([]);
      setCurrentPage(1);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      SetErrorMassNoData(1);
    }
    Loading(0);
  }

  async function getEmpData() {
    const result = await GetEmp();
    setDataEmp(result.data);
  }

  // async function CancelAssign() {
  //   let data = {
  //     reasonCancelPickupParcel: reasonCancel !== "" ? reasonCancel : "",
  //     assignTaskBy: user.username,
  //     pickupParcelID: pickupParcelId !== "" ? pickupParcelId : "",
  //   };
  //   const result = await CancelAssignTask(data);
  //   if (result.status === 1) {
  //     swal({
  //       title: "ยกเลิกการมอบหมายงานสำเร็จ!",
  //       text: "",
  //       icon: "success",
  //       showConfirmButton: false,
  //       button: "ปิด",
  //       timer: 1500,
  //     });
  //     setData([]);
  //     fetchData();
  //   } else {
  //     swal({
  //       title: "ยกเลิกการมอบหมายงานไม่สำเร็จ",
  //       text: "",
  //       icon: "warning",
  //       showConfirmButton: false,
  //       button: "ปิด",
  //       timer: 1500,
  //     });
  //   }
  // }

  // async function SaveAssignTask() {
  //   let data = {
  //     transportWorker: transportWorker !== "" ? transportWorker : "",
  //     pickupParcelID: pickupParcelId !== "" ? pickupParcelId : "",
  //     assignTaskBy: user.username,
  //   };

  //   const result = await AssignTask(data);
  //   if (result.status === 1) {
  //     swal({
  //       title: "มอบหมายงานสำเร็จ!",
  //       text: "",
  //       icon: "success",
  //       showConfirmButton: false,
  //       button: "ปิด",
  //       timer: 1500,
  //     });
  //     setData([]);
  //     fetchData();
  //   } else {
  //     swal({
  //       title: "มอบหมายงานไม่สำเร็จ",
  //       text: "",
  //       icon: "warning",
  //       showConfirmButton: false,
  //       button: "ปิด",
  //       timer: 1500,
  //     });
  //   }
  // }

  const handleChangePage = (e, newPage) => {
    e.preventDefault();
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (e) => {
    e.preventDefault();
    setPageSize(e.target.value);
    setCurrentPage(1);
  };

  const filter = (e) => {
    e.preventDefault();
    if (valueYear === "" && valueMonth === "" && valueDay === "") {
      setData([]);
      fetchData();
      setErrorDate(0);
    } else if (valueYear === "" || valueMonth === "" || valueDay === "") {
      setErrorDate(1);
    } else {
      setData([]);
      fetchData();
      setErrorDate(0);
    }
  };

  const clear = (e) => {
    e.preventDefault();
    setStatusPickup("2");
    setCallPickupName("");
    setDateNow(1);
    setYear("");
    setMonth("");
    setDay("");
    setErrorDate(0);
    setPageSize(10);
    setCurrentPage(1);
  };

  function handleChangeTap(value) {
    // setStatusPickup("2");
    setTypeSender(value);
    fetchData({
      callPickupName: callPickupName !== "" ? callPickupName : "",
      createdDate:
        valueYear !== "" && valueMonth !== "" && valueDay !== ""
          ? valueYear + "-" + valueMonth + "-" + valueDay
          : "",
      placePickup: placePickup !== "" ? placePickup : "",
      statusPickup: statusPickup !== "" ? statusPickup : "",
      pageNo: pageNo !== "" ? pageNo : "",
      perPage: perPage !== "" ? perPage : "",
      pickupType: value,
    });
  }

  return (
    <div>
      <div className="card-sender">
        <div className="text-center text-title-color">
          <h2>เรียกรถเข้ารับพัสดุ</h2>
        </div>
        <br />
        <div className="row" style={{ marginLeft: "5px", marginRight: "5px" }}>
          <div className="col-sm-4 px-md-1 mt-1">
            <span>ค้นหา</span>
            <input
              value={callPickupName}
              onChange={(e) => {
                setCallPickupName(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="ชื่อผู้เรียกรถ"
              aria-label="ค้นหา"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="col-sm-2 px-md-1 mt-1">
            <span>สถานะ</span>
            <select
              value={statusPickup}
              className="form-control"
              aria-label="Default select example"
              onChange={(e) => {
                setStatusPickup(e.target.value);
              }}
            >
              <option value="2">เรียกรถเข้ารับพัสดุ</option>
              <option value="3">ขนส่งรับพัสดุแล้ว</option>
              <option value="C">ยกเลิก</option>
            </select>
          </div>
          <div className="col-sm-4 px-md-3 mt-1">
            <div className="row">
              <div className="col-sm-4 px-md-1">
                <span>วันที่เรียกรถ</span>
                <select
                  className={"form-control"}
                  value={valueYear}
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                >
                  <option value=""> -- ปี -- </option>
                  {years.map((year, index) => (
                    <option key={`year${index}`} value={year}>
                      {year + 543}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4 px-md-1">
                <br />
                <select
                  className={"form-control"}
                  value={valueMonth}
                  onChange={(e) => {
                    setMonth(e.target.value);
                  }}
                >
                  <option value=""> -- เดือน -- </option>
                  {monthTH.map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.monthName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4 px-md-1">
                <br />
                <select
                  className={"form-control"}
                  value={valueDay}
                  onChange={(e) => {
                    setDay(e.target.value);
                  }}
                >
                  <option value=""> -- วัน -- </option>
                  {Array.from(Array(31), (e, i) => (
                    <option value={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </div>
            </div>
            {errorDate === 1 ? (
              <font color="red" size="2">
                {" "}
                กรุณาเลือก ปี, เดือน, วัน{" "}
              </font>
            ) : (
              ""
            )}
          </div>
          <div className="col-sm-2 px-md-4">
            <div className="form-check" style={{ marginTop: "35px" }}>
              <input
                checked={dateNow === 1 ? false : true}
                style={{ width: "15px", height: "15px" }}
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                onChange={(e) => {
                  if (dateNow === 1) {
                    setDateNow(0);
                    setYear(year);
                    setMonth(("0" + month).slice(-2));
                    setDay(date);
                  } else {
                    setDateNow(1);
                    setYear("");
                    setMonth("");
                    setDay("");
                  }
                }}
              />
              <label
                className="form-check-label"
                htmlFor="flexCheckDefault"
                style={{ marginLeft: "10px" }}
              >
                วันที่ปัจจุบัน
              </label>
            </div>
          </div>
          <center className="col-sm-12 px-md-2 mt-1">
            <br />
            <button className={"btn btn-primary"} onClick={filter}>
              <Icon.Search /> ค้นหา
            </button>{" "}
            <button className="btn btn-secondary" onClick={clear}>
              <Icon.ArrowClockwise /> ล้างค่า
            </button>
          </center>
        </div>
        <br />

        <div style={{ boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)" }}>
          <div className="mb-5 tabs">
            <div className="tab ">
              <div
                className={
                  "tab-header" + (typeSender === "2" ? " active " : "")
                }
                onClick={() => {
                  setTypeSender("2");
                  handleChangeTap("2");
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <p className="mt-1">ลูกค้าทั่วไป ({coust})</p>
                </div>
              </div>
              <div
                className={
                  "tab-header" + (typeSender === "1" ? "  active " : " ")
                }
                onClick={() => {
                  setTypeSender("1");
                  handleChangeTap("1");
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <p className="mt-1">ลูกค้าเครดิต ({coustCadit})</p>
                </div>
              </div>
            </div>
            <div
              className={
                "tab-contents" + (typeSender !== "1" ? "  hidden-ct " : " ")
              }
            >
              {/* ////////////////////////////////start tap ทั่วไป///////////////////////////////////// */}
              <div className="d-flex justify-content-between mb-2">
                <div></div>
                <div>
                  <select
                    className="form-control"
                    onChange={handleChangePageSize}
                    value={perPage}
                  >
                    {pageSizes.map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className={""}>
                <div
                  style={{ overflow: "Auto" }}
                  className={loading === 1 ? "hidden-ct" : ""}
                >
                  <table className="table table-bordered">
                    <thead style={{ backgroundColor: "#3f75c1" }}>
                      <tr style={{ color: "#FFFFFF" }}>
                        <td>ลำดับ</td>
                        <td style={{ width: "20%" }}>ชื่อผู้เรียกรถ</td>
                        <td style={{ width: "30%" }}>สถานที่ไปรับพัสดุ</td>
                        <td>จำนวน(ชิ้น)</td>
                        <td>สถานะ</td>
                        <td>วันที่เรียกรถ</td>
                        {/* <td>มอบหมายงาน</td> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((value, index) => (
                        <tr key={value.id}>
                          <td>
                            {(page.current_page - 1) * perPage + (index + 1)}
                          </td>
                          <td>{value.cust_code}</td>
                          <td>{value.address}</td>
                          <td>{value.num_parcels}</td>
                          <td>{value.status}</td>
                          <td>
                            {LongDateTH(value.created_date)[0]}
                            <br />
                            {LongDateTH(value.created_date)[1]}
                          </td>
                          {/* <td>
                            <button
                              className="btn btn-warning"
                              onClick={() => {
                                handleShow();
                                setPickupParcelId(value.id);
                              }}
                            >
                              <Icon.PencilSquare style={{ color: "#ffff" }} />
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                handleShowC();
                                setPickupParcelId(value.id);
                              }}
                            >
                              <Icon.Trash />
                            </button>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <center>
                    {setErrorMassNoData === 1 && loading !== 1 ? (
                      <font color="red" size="3">
                        {" "}
                        ไม่พบข้อมูล!{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </center>
                </div>

                <center>
                  {loading === 1 ? (
                    <div className="m-5">
                      <div className="text-center">
                        <div
                          className="spinner-border  text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        {" ..."}
                        กำลังโหลดข้อมูล
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </center>
                <div className="d-flex justify-content-between">
                  <div>จำนวนทั้งหมด {page.total} รายการ</div>
                  <div>
                    <Pagination
                      count={parseInt(page.last_page)}
                      page={pageNo}
                      onChange={handleChangePage}
                      color="primary"
                      size="small"
                      defaultPage={6}
                      siblingCount={1}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                "tab-contents" + (typeSender !== "2" ? "  hidden-ct " : " ")
              }
            >
              {/* ///////////////////////////////////start tap เครดิต////////////////////////////////// */}
              <div className="d-flex justify-content-between mb-2">
                <div></div>
                <div>
                  <select
                    className="form-control"
                    onChange={handleChangePageSize}
                    value={perPage}
                  >
                    {pageSizes.map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className={""}>
                <div
                  style={{ overflow: "Auto" }}
                  className={loading === 1 ? "hidden-ct" : ""}
                >
                  <table className="table table-bordered">
                    <thead style={{ backgroundColor: "#3f75c1" }}>
                      <tr style={{ color: "#FFFFFF" }}>
                        <td>ลำดับ</td>
                        <td style={{ width: "20%" }}>ชื่อผู้เรียกรถ</td>
                        <td style={{ width: "30%" }}>สถานที่ไปรับพัสดุ</td>
                        <td>จำนวน(ชิ้น)</td>
                        <td>สถานะ</td>
                        <td>วันที่เรียกรถ</td>
                        {/* <td>มอบหมายงาน</td> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((value, index) => (
                        <tr key={value.id}>
                          <td>
                            {(page.current_page - 1) * perPage + (index + 1)}
                          </td>
                          <td>{value.cust_code}</td>
                          <td>{value.address}</td>
                          <td>{value.num_parcels}</td>
                          <td>{value.status}</td>
                          <td>
                            {LongDateTH(value.created_date)[0]}
                            <br />
                            {LongDateTH(value.created_date)[1]}
                          </td>
                          {/* <td>
                            <button
                              className="btn btn-warning"
                              onClick={() => {
                                handleShow();
                                setPickupParcelId(value.id);
                              }}
                            >
                              <Icon.PencilSquare style={{ color: "#ffff" }} />
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                handleShowC();
                                setPickupParcelId(value.id);
                              }}
                            >
                              <Icon.Trash />
                            </button>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <center>
                    {setErrorMassNoData === 1 && loading !== 1 ? (
                      <font color="red" size="3">
                        {" "}
                        ไม่พบข้อมูล!{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </center>
                </div>

                <center>
                  {loading === 1 ? (
                    <div className="m-5">
                      <div className="text-center">
                        <div
                          className="spinner-border  text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        {" ..."}
                        กำลังโหลดข้อมูล
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </center>
                <div className="d-flex justify-content-between">
                  <div>จำนวนทั้งหมด {page.total} รายการ</div>
                  <div>
                    <Pagination
                      count={parseInt(page.last_page)}
                      page={pageNo}
                      onChange={handleChangePage}
                      color="primary"
                      size="small"
                      defaultPage={6}
                      siblingCount={1}
                    />
                  </div>
                </div>
              </div>

              {/* /////////////////////////////////end tap เครดิต//////////////////////////////////// */}
            </div>
          </div>
        </div>

        {/* <ShowModal value={show}/> */}

        {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>มอบหมายงาน</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "19px" }}>
            <div className="row">
              <div className="col col-sm-12">
                <span>พนักงานขนสง</span>
                <select
                  class="form-control"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setTransportWorker(e.target.value);
                  }}
                >
                  <option selected>-- กรุณาเลือกพนักงาน --</option>
                  {dataEmp.map((value) => (
                    <option value={value.emp_code}>
                      {value.emp_code + " " + value.empName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={handleClose}>
              ยกเลิก
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                SaveAssignTask();
                handleClose();
              }}
            >
              บันทึก
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={showC} onHide={handleCloseC}>
          <Modal.Header closeButton>
            <Modal.Title>ยกเลิกมอบหมายงาน</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "19px" }}>
            <div className="row">
              <div className="col col-sm-12"></div>

              <div className="col col-sm-12" style={{ marginTop: "20px" }}>
                <span>หมายเหตุ</span>
                <textarea
                  className="form-control"
                  id="story"
                  name="story"
                  rows="5"
                  cols="33"
                  onChange={(e) => {
                    setReasonCancel(e.target.value);
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={handleCloseC}>
              ยกเลิก
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                CancelAssign();
                handleCloseC();
              }}
            >
              บันทึก
            </button>
          </Modal.Footer>
        </Modal> */}
      </div>
      <br />
      <br />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(CallCar);
