import React from "react";
import GenericTable from "../../Shared/GenericTable";
import Pagination from "@material-ui/lab/Pagination";
import {useTranslation} from "react-i18next";
const metadata = [
    {
        text: 'barcode',
        width: '80%',
        render: (data) => (
            <div className='flex flex-column'>
                <p style={{ color: 'var(--blue)', fontWeight: 'bold', marginBottom: '.2rem' }}>{data.awb_code}</p>
            </div>
        ),
    }
]


export const ScanOutRemainingParcels = ({ data, totalItems, remainingItems, handleChangePage}) => {
    const {t} = useTranslation();
    return (
        <>
            <div className="w-full p-2">
                <p>
                    {t('hub.scanList.remaining')} :  {remainingItems} <span>/</span> {totalItems}
                </p>
                <p>

                </p>
                <div>
                    <GenericTable
                        metadata={metadata}
                        data={data.result}
                        keyExtractor={(item) => item.code}
                        roundedBorders={true}
                        showHeader={false} />

                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '5px'}}>
                        <Pagination
                            count={data.maxPage}
                            page={parseInt(data.pageNo)}
                            onChange={handleChangePage}
                            color="primary"
                            size="small"
                            siblingCount={1}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}