import * as Yup from 'yup';

const printLabelSchema = Yup.object().shape({
  receiver_name: Yup.string()
    .required('กรุณากรอก ชื่อ-นามสกุล')
    .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, 'ไม่อนุญาตให้ใช้อักขระพิเศษ'),
  receiver_address: Yup.string()
    .required('กรุณากรอก เลขที่อยู่')
    .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, 'กรุณาตรวจสอบ เลขที่อยู่ อีกครั้ง'),
  receiver_amphur: Yup.string().required('กรุณากรอก อำเภอ '),
  receiver_province: Yup.string().required('กรุณากรอก จังหวัด '),
  weight: Yup.number()
      .when('zipcode', (value,schema) =>{
        if(localStorage.getItem('superkey') === 'credit' && localStorage.getItem('show_shipment_cost') === '0' 
        && localStorage.getItem('requireDimension') === '0' ){
          return schema.notRequired();
        }
        else{
            return schema.required('กรุณากรอก น้ำหนัก ')
        }
      })
      .when('zipcode', (value,schema) =>{
        if((localStorage.getItem('superkey') === 'credit' && localStorage.getItem('show_shipment_cost') === '0') 
              || localStorage.getItem('requireDimension') === '0' ){
          return schema.notRequired();
        }
        else{
          return schema.min(0.1, 'กรุณากรอก น้ำหนักมากกว่า หรือเท่ากับ 0.1')
        }
      })
      .when('zipcode', (value,schema) =>{
        if((localStorage.getItem('superkey') === 'credit' && localStorage.getItem('show_shipment_cost') === '0') 
            || localStorage.getItem('requireDimension') === '0' ){
          return schema.notRequired();
        }
        else{
          return schema.max(30, "ขออภัย พัสดุฯมีน้ำหนักเกิน30กิโลกรัม")
        }
      }),
      
  box_width: Yup.number(),
  // .required('กรุณากรอก ขนาดความกว้าง ').min(1, 'กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1'),
  box_length: Yup.number(),
  // .required('กรุณากรอก ขนาดความยาว ').min(1, 'กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1'),
  box_height: Yup.number(),
  // .required('กรุณากรอก ขนาดความสูง ').min(1, 'กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1'),
  box_size: Yup.number().max(210,"ขออภัย ขนาดพัสดุฯเกิน210ซม"),
  cod_cost: Yup.number().when('cod_Status', (value, schema) => {
    if (value === 1) {
      return schema.required('กรุณากรอก จำนวนเงิน COD ').min(1, 'กรุณาระบุ จำนวนเงิน COD มากกว่า หรือเท่ากับ 1');
    } else {
      return schema.notRequired();
    }
  }),
});

export default printLabelSchema;
