import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ThaiDatepicker} from "../../../../Components/Datepicker/thai-datepicker";
import * as Icon from "react-bootstrap-icons";

export const ParcelStatusFilters = ({initialFilters, setFilters}) => {
    const {t} = useTranslation();

    const [componentFilters, setComponentFilters] = useState({
        ...initialFilters
    });

    const updateFilter = (key,value) => {
        setComponentFilters((filters) => ({
            ...filters,
            [key]: value,
        }))
    }

    return (
        <>
            <div className="row">
                <div className="col col-12 col-md-4">
                    <div className="form-group d-flex flex-column justify-content-between h-100">
                        <label>{t('hub.status.filter.keyword.label')}</label>
                        <input
                            type="text"
                            value={componentFilters.keyword}
                            onChange={(e) => updateFilter('keyword', e.target.value)}
                            placeholder={t('hub.status.filter.keyword.placeholder')}
                            className={'form-control'}
                        />
                    </div>
                </div>
                <div className="col col-12 col-md-4">
                    <div className="form-group d-flex flex-column justify-content-between h-100">
                        <label>{t('hub.status.filter.status.label')}</label>
                        <select
                            value={componentFilters.shipment_status}
                            onChange={(e) => updateFilter('shipment_status', e.target.value)}
                            className={'form-control'}
                            placeholder={`--  ${t('hub.status.filter.status.placeholder')} --`}
                        >
                            <option value={''}>{t('hub.status.filter.status.all')}</option>
                            <option value={'C'}>1. {t('hub.status.filter.status.canceled')}</option>
                            {[...Array(13).keys()].map((item, index) => (
                                <option value={index} key={index}>
                                    {index + 2 + '. ' + t('hub.status.filter.status.' + index)}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col col-12 col-md-4 d-flex justify-content-center align-items-end">
                    <div className="d-flex m-2 justify-content-center align-items-center">
                        <div className="form-check form-check-inline">
                            <input
                                checked={componentFilters.cod === "1"}
                                style={{ width: "15px", height: "15px" }}
                                className="form-check-input"
                                type="checkbox"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="1"
                                onChange={(e) => {
                                    if (componentFilters.cod !== "1") {
                                        updateFilter('cod',e.target.value);
                                    } else {
                                        updateFilter('cod',"");
                                    }
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                            >
                                {t('hub.status.filter.cod.with_cod')}
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                checked={componentFilters.cod === "0"}
                                style={{ width: "15px", height: "15px" }}
                                className="form-check-input"
                                type="checkbox"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="0"
                                onChange={(e) => {
                                    if (componentFilters.cod !== "0") {
                                        updateFilter('cod',e.target.value);
                                    } else {
                                        updateFilter('cod',"");
                                    }
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="inlineRadio2"
                            >
                                {t('hub.status.filter.cod.no_cod')}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col col-12 col-md-6">
                    <div className="form-group d-flex flex-column justify-content-between h-100">
                        <ThaiDatepicker
                            label={t('hub.filters.startDate')}
                            date={componentFilters.dateFilters.startDate}
                            onChange={(date) => updateFilter('dateFilters', {
                                ...componentFilters.dateFilters,
                                startDate: date,
                            })}
                        />
                    </div>
                </div>
                <div className="col col-12 col-md-6">
                    <div className="form-group d-flex flex-column justify-content-between h-100">
                        <ThaiDatepicker
                            label={t('hub.filters.endDate')}
                            date={componentFilters.dateFilters.endDate}
                            onChange={(date) => updateFilter('dateFilters', {
                                ...componentFilters.dateFilters,
                                endDate: date,
                            })}
                        />
                    </div>
                </div>
            </div>
            <div className="col col-12 col-md-12 w-full">
                <button className='btn btn-primary mt-2 w-full' onClick={() => setFilters({
                    ...componentFilters
                })}>
                    <Icon.Search/>  {t('common.search')}
                </button>
            </div>
        </>
    );
}