import React, { useEffect, useState } from 'react';
import ShowData from './showData';
import { useHistory } from 'react-router-dom';
import { GetApplyData, deleteApplyData } from '../../../Service/service.Career.js';
import { Formik, Form } from 'formik';
import * as Icon from 'react-bootstrap-icons';
import swal from 'sweetalert';

export default function ApplyData() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });

  const pageSizes = [10, 20, 50, 100];
  const [errorMassNoData, setErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);

  const [pageNo, setCurrentPage] = useState(1);
  const [perPage, setPageSize] = useState(10);

  useEffect(() => {
    fetchData({
      pageNo: pageNo,
      perPage: perPage,
    });
  }, [pageNo, perPage]);

  async function fetchData(data) {
    Loading(1);
    setData([]);
    let res = await GetApplyData(data);
    if (res !== false) {
      setData(res.applicants.result);
      setPage({
        current_page: res.applicants.pageNo,
        last_page: res.applicants.maxPage,
        total: res.applicants.numRowsAll,
      });
      setErrorMassNoData(0);
      Loading(0);
    } else {
      setCurrentPage(1);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      setErrorMassNoData(1);
      Loading(0);
    }
  }

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
    setData([]);
    setErrorMassNoData(0);
    fetchData({
      pageNo: '1',
      perPage: e.target.value,
      ano_status: '1',
      position_name: '',
    });
  };

  const handleChangePage = (e, newPage) => {
    setData([]);
    setErrorMassNoData(0);
    fetchData({
      pageNo: newPage,
      perPage: perPage,
    });
    setCurrentPage(newPage);
  };

  async function Del(data) {
    let res = await deleteApplyData(data);
    if (res !== undefined) {
      if (res.status === 1) {
        swal({
          title: res.message,
          icon: 'success',
        });
        fetchData({
          pageNo: 1,
          perPage: 10,
        });
        setCurrentPage(1);
        setPageSize(10);
      } else {
        swal({
          title: res.message,
          icon: 'warning',
        });
      }
    }
  }

  return (
    <>
      <Formik
        // validationSchema={schema}
        initialValues={{
          pageNo: pageNo,
          perPage: perPage,
        }}
        onSubmit={(value) => {
          fetchData(value);
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div>
              <div className="container-fluid mt-2 mb-2">
                <div className="text-center text-title-color">
                  <h2>ข้อมูลสมัครงาน</h2>
                </div>
                <br />
                {/* <div
                  className="row"
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                >
                  <div className="col-sm-4 px-md-1 mt-1">
                    <span>ค้นหาตำแหน่งที่เปิดรับ</span>
                    <input
                      value={values.position_name}
                      type="text"
                      className={"form-control"}
                      onChange={(e) => {
                        setFieldValue("position_name", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <span>สถานะเปิดรับสมัคร</span>
                    <select
                      value={values.ano_status}
                      className="form-control"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setFieldValue("ano_status", e.target.value);
                      }}
                    >
                      <option value="1">เปิดรับสมัคร</option>
                      <option value="0">ปิดรับสมัคร</option>
                    </select>
                  </div>
                  <div className="col-sm-3 px-md-3 mt-1">
                    <br />
                    <button
                      name="search"
                      disabled={loading === 0 ? false : true}
                      className={"btn btn-primary"}
                      type="submit"
                    >
                      <Icon.Search /> ค้นหา
                    </button>{" "}
                    <button
                      name="clear"
                      className="btn btn-secondary"
                      onClick={() => {
                        setFieldValue("position_name", "");
                        setFieldValue("ano_status", "1");
                      }}
                      type="submit"
                    >
                      <Icon.ArrowClockwise /> ล้างค่า
                    </button>
                  </div>
                  <div className="col-sm-3 px-md-1 mt-1" align="right">
                    <br />
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        history.push("/mis/FormRecruit");
                      }}
                    >
                      <Icon.Plus /> เพิ่มข้อมูล
                    </button>
                  </div>
                </div> */}
                <br />
                <div className="d-flex justify-content-between mb-2">
                  <div className="col-md-11"></div>
                  <div className="col-md-1">
                    <select className="form-control" onChange={handlePageSizeChange} value={perPage}>
                      {pageSizes.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <ShowData
                  data={data}
                  page={page}
                  perPage={perPage}
                  history={history}
                  loading={loading}
                  errorMassNoData={errorMassNoData}
                  handleChangePage={handleChangePage}
                  pageNo={pageNo}
                  Del={Del}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
