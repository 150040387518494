import React from "react";
import { nanoid } from "nanoid";
import imgConote from "../../../../Assets/images/imagesMis/conoteEmpty.png";
import * as Icon from 'react-bootstrap-icons';
import GenberCode from "../../genberCode";
import logo2 from "../../../../Assets/images/logo/logo2.png";

export default function PrintContent({ data, additionalStyles = {}, conoteGroups = [] }) {
    console.log("data :" + JSON.stringify(data));
    var custID = data.cust_code; 
    var refNo = data.ref_no;
    var senderName = data.sender_name;
    var senderPhone = data.sender_phone;
    var senderAddress = data.sender_address + " " + data.sender_amphur;
    var senderAddress2 = data.sender_province + " " +data.sender_zipcode;
    var receiverName = data.receiver_name;
    var receiverAddress = data.receiver_address + " ";
    var receiverAddress2 = data.receiver_amphur + " " + data.receiver_province + " " +data.receiver_zipcode;
    var receiverProvince = data.receiver_province;
    var receiverAmphur = data.receiver_amphur;
    var receiverPhone = data.receiver_phone;
    var paymentMethod = data.payment_type;
    var payer = data.payer;
    var numPcs = data.pcs;
    var totalWeight = data.total_weight;
    var desrci = "";
    var numReturnDoc = data.num_returned_doc;

  return (
    <div
        style={{
          fontFamily: "Kanit",
          fontSize: "13px",
          position: "relative",
          marginTop: "1px",
          marginBottom: "1px",
        }}
        className="page-break"
      >
        <div style={{ paddingTop: "20px" }}></div>
        <div>
          <div>ผู้รับ : {receiverName}</div>
          <div>ที่อยู่ : {receiverAddress}</div>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex"
            }}
          >
            <div>
              <b>{"อำเภอ/เขต " + receiverAmphur}</b>
            </div>
            <div>
              <b>{"จังหวัด" + receiverProvince}</b>
            </div>
          </div>
          <center>{"Tel :" + " " + receiverPhone}</center>
          <br/>
          <div
            style={{
                justifyContent: "space-between",
                display: "flex",
              }}
          >
                 <div>ผู้ส่ง : {senderName}</div>
                 <div> จำนวนพัสดุฯรวม {numPcs} ชิ้น </div>
          </div>
        </div>
        
        <hr />
        <div>
          <div
              style={{
                justifyContent: "space-between",
                display: "flex",
              }}>
                <div>รหัส : {custID}</div>
                <div> เอกสารนำกลับ {data.num_return_doc} ใบ</div>
          </div>             
          <div style={{ paddingTop: "10px" }}>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <div style={{ marginTop: "45px" }}>ลําดับ {senderPhone}</div>
            </div>
            <center>
              <img alt="" src={logo2} width="120" height="80" className="d-inline-block align-top" />
            </center>
            <div style={{ textAlign: "center", color: "black", }}>
              <GenberCode awbCode={data.ref_no} />
            </div>
          </div>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
              <span>Kt./Kg.{" "}
                {totalWeight != null
                  ? totalWeight
                  : "-"}
              </span>
              {receiverName}
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                ลงชื่อ..................................................ผู้รับ
            </div>
          </div>
        </div>
      </div>
  );
}
