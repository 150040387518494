import * as Yup from "yup";

const Schema = Yup.object().shape({
    tracking_number: Yup.string().required("กรุณากรอกเลขพัสดุ"),
    startYear: Yup.string().required("กรุณาระบุ ปี"),
    startMonth: Yup.string().required("กรุณาระบุ เดือน"),
    startDay: Yup.string().required("กรุณาระบุ วัน"),
    endYear: Yup.string().required("กรุณาระบุ ปี"),
    endMonth: Yup.string().required("กรุณาระบุ เดือน"),
    endDay: Yup.string().required("กรุณาระบุ วันที่"),
});

export default Schema;