import React, { useState } from 'react';
import DateTH from '../../../Helper/date-th';
import { bank } from '../../../Data/bank.json';
import notImg from '../../../Assets/images/imagesMis/notImg.png';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '@material-ui/lab/Pagination';
import ReactExport from 'react-data-export';
import Modal from 'react-bootstrap/Modal';
import { GetDeliverFailImg, GetDeliverImg } from '../../../Service/service.TrackingReport';
import Carousel from 'react-bootstrap/Carousel';
import { nanoid } from 'nanoid';
import FormatLabelAddress from '../formatLabelAddress';
import FormatLabelAddressCod from '../formatLabelAddressCod';
import FormatLabelAddressPornor from '../formatLabelPornor/formatLabelAddressPornor';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function ShowData({
  data,
  dataExport,
  page,
  pageSizes,
  errorMassNoData,
  currentPage,
  values,
  fetchData,
  fetchExportdata,
  setData,
  setDataExport,
  setcurrentPage,
  loading,
  user,
  codTotal,
  PageSizes,
  shipmentTotal,
  setLabelData,
  printLael,
}) {
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  const [dataDeliverFail, setDataDeliverFail] = useState([]);
  const [loadingDeliverFail, LoadingDeliverFail] = useState(0);
  const [dataDeliverImg, setDataDeliverImg] = useState([]);
  const [loadingDeliverImg, LoadingDeliverImg] = useState(0);
  const [notData, setNotData] = useState(0);
  const [awb_Code, setAwb_Code] = useState('');

  const [index2, setIndex2] = useState('');
  const [printShow, setPrintShow] = useState(false);
  const handlePrintClose = () => setPrintShow(false);
  const handlePrintShow = () => setPrintShow(true);

  const [modalReceived, setModalReceived] = useState(false);
  const modalReceivedClose = () => setModalReceived(false);
  const modalReceivedShow = () => setModalReceived(true);
  const handleShowDeliverImg = (value) => {
    loadDeliverImg(value);
    setModalReceived(true);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (value) => {
    loadDeliverFail(value);
    setShow(true);
  };
 

  async function loadDeliverFail(value) {
    LoadingDeliverFail(1);
    setAwb_Code(value);
    setNotData(0);
    const result = await GetDeliverFailImg(value);
    if (result !== undefined) {
      if (result.length > 0) {
        setDataDeliverFail(result);
      } else {
        setDataDeliverFail([]);
        setNotData(1);
      }
    }
    LoadingDeliverFail(0);
  }

  async function loadDeliverImg(value) {
    LoadingDeliverImg(1);
    setAwb_Code(value);
    setNotData(0);
    const result = await GetDeliverImg(value);
    if (result !== undefined) {
      if (result.length > 0) {
        setDataDeliverImg(result);
      } else {
        setDataDeliverImg([]);
        setNotData(1);
      }
    }
    LoadingDeliverImg(0);
  }

  return (
    <>
      <div>
        <ExcelFile
          element={
            <button type="button" style={{ display: 'none' }} id="downloadExShowPrice">
              Download Data
            </button>
          }
          filename={'รายงานติดตามพัสดุ'}
        >
          <ExcelSheet data={dataExport} name={'รายงานติดตามพัสดุ'}>
            <ExcelColumn label="เลขลาเบล" value="awb_code" />
            <ExcelColumn label="สำหรับลูกค้า / เลขที่co note" value="ref_no" />
            <ExcelColumn label="หมายเลขinvoice" value="invoice" />
            <ExcelColumn label="รหัสลกค้า" value="cust_code" />
            <ExcelColumn label="ชื่อผู้ส่ง" value="sender_name" />
            <ExcelColumn label="เบอร์โทรผู้ส่ง" value="sender_phone" />
            <ExcelColumn label="บัตรประชาชนผู้ส่ง" value="citizenID" />
            <ExcelColumn label="ชื่อผู้รับ" value="receiver_name" />
            <ExcelColumn label="เบอร์โทรผู้รับ" value="receiver_phone" />
            <ExcelColumn label="ที่อยู่" value="receiver_address" />
            <ExcelColumn label="ตำบล" value="receiver_district" />
            <ExcelColumn label="อำเภอ" value="receiver_amphur" />
            <ExcelColumn label="จังหวัด" value="receiver_province" />
            <ExcelColumn label="น้ำหนัก" value="weight" />
            <ExcelColumn label="ความกว้าง (ซม.)" value="box_width" />
            <ExcelColumn label="ความยาว (ซม.)" value="box_length" />
            <ExcelColumn label="ความสูง (ซม.)" value="box_height" />
            <ExcelColumn label="ขนาด(ซ.ม.)" value="box_size" />
            <ExcelColumn label="ค่าขนส่ง (บาท)" value={(row) => (parseInt(row.shipment_cost))} />
            <ExcelColumn label="ค่าขนส่ง COD (บาท)" value={(row) => (parseInt(row.service_cod))} />
            <ExcelColumn label="ค่าขนส่ง (บาท)" value={(row) => (parseInt(row.shipment_cost)+parseInt(row.service_cod))} />
            <ExcelColumn label="COD (บาท)" value={(row) => parseInt(row.cod_cost)} />
            <ExcelColumn label="ชื่อธนาคาร" value="bank_id" />
            <ExcelColumn label="ชื่อบัญชี" value="bookbank_name" />
            <ExcelColumn label="เลขที่บัญชี" value="bookbank_code" />
            <ExcelColumn label="วันที่" value="stockin_date" />
            <ExcelColumn label="สถานะ" value="shipment_name" />
            <ExcelColumn label="วันที่เซ็นรับ" value="receiver_date" />
            <ExcelColumn label="เลขสำหรับลูกค้า 1" value="ref_no2" />
            <ExcelColumn label="เลขสำหรับลูกค้า 2" value="item_desc" />
            <ExcelColumn label="เลขสำหรับลูกค้า 3" value="item_sku" />
            <ExcelColumn label="จำนวนเอกสารนำกลับ" value="num_return_doc" />
          </ExcelSheet>
        </ExcelFile>
      </div>

      <div>
        <ExcelFile
          element={
            <button type="button" style={{ display: 'none' }} id="downloadExHidePrice">
              Download Data
            </button>
          }
          filename={'รายงานติดตามพัสด'}
        >
          <ExcelSheet data={dataExport} name={'รายงานติดตามพัสดุ'}>
            <ExcelColumn label="เลขลาเบล" value="awb_code" />
            <ExcelColumn label="สำหรับลูกค้า / เลขที่co note" value="ref_no" />
            <ExcelColumn label="หมายเลขinvoice" value="invoice" />
            <ExcelColumn label="รหัสลกค้า" value="cust_code" />
            <ExcelColumn label="ชื่อผู้ส่ง" value="sender_name" />
            <ExcelColumn label="เบอร์โทรผู้ส่ง" value="sender_phone" />
            <ExcelColumn label="บัตรประชาชนผู้ส่ง" value="citizenID" />
            <ExcelColumn label="ชื่อผู้รับ" value="receiver_name" />
            <ExcelColumn label="เบอร์โทรผู้รับ" value="receiver_phone" />
            <ExcelColumn label="ที่อยู่" value="receiver_address" />
            <ExcelColumn label="ตำบล" value="receiver_district" />
            <ExcelColumn label="อำเภอ" value="receiver_amphur" />
            <ExcelColumn label="จังหวัด" value="receiver_province" />
            <ExcelColumn label="น้ำหนัก" value="weight" />
            <ExcelColumn label="ความกว้าง (ซม.)" value="box_width" />
            <ExcelColumn label="ความยาว (ซม.)" value="box_length" />
            <ExcelColumn label="ความสูง (ซม.)" value="box_height" />
            <ExcelColumn label="ขนาด(ซ.ม.)" value="box_size" />
            <ExcelColumn label="COD (บาท)" value={(row) => parseInt(row.cod_cost)} />
            <ExcelColumn label="ชื่อธนาคาร" value="bank_id" />
            <ExcelColumn label="ชื่อบัญชี" value="bookbank_name" />
            <ExcelColumn label="เลขที่บัญชี" value="bookbank_code" />
            <ExcelColumn label="วันที่" value="stockin_date" />
            <ExcelColumn label="สถานะ" value="shipment_name" />
            <ExcelColumn label="วันที่เซ็นรับ" value="receiver_date" />
            <ExcelColumn label="เลขสำหรับลูกค้า 1" value="ref_no2" />
            <ExcelColumn label="เลขสำหรับลูกค้า 2" value="item_desc" />
            <ExcelColumn label="เลขสำหรับลูกค้า 3" value="item_sku" />
            <ExcelColumn label="จำนวนเอกสารนำกลับ" value="num_return_doc" />
          </ExcelSheet>
        </ExcelFile>
      </div>

      <div>
        <ExcelFile
          element={
            <button type="button" style={{ display: 'none' }} id="downloadEx2">
              Download Data
            </button>
          }
          filename={'รายงานติดตามพัสดุ'}
        >
          <ExcelSheet data={dataExport} name={'รายงานติดตามพัสดุ'}>
            <ExcelColumn label="เลขลาเบล" value="awb_code" />
            <ExcelColumn label="สำหรับลูกค้า" value="ref_no" />
            <ExcelColumn label="รหัสลกค้า" value="cust_code" />
            <ExcelColumn label="ชื่อผู้ส่ง" value="sender_name" />
            <ExcelColumn label="เบอร์โทรผู้ส่ง" value="sender_phone" />
            <ExcelColumn label="บัตรประชาชนผู้ส่ง" value="citizenID" />
            <ExcelColumn label="ชื่อผู้รับ" value="receiver_name" />
            <ExcelColumn label="เบอร์โทรผู้รับ" value="receiver_phone" />
            <ExcelColumn label="ที่อยู่" value="receiver_address"/> 
            <ExcelColumn label="ตำบล" value="receiver_district" />
            <ExcelColumn label="อำเภอ" value="receiver_amphur" />
            <ExcelColumn label="จังหวัด" value="receiver_province" />
            <ExcelColumn label="ประเภท" value="กล่องพัสดุ" />
            <ExcelColumn label="น้ำหนัก" value="weight" />
            <ExcelColumn label="ความกว้าง (ซม.)" value="box_width" />
            <ExcelColumn label="ความยาว (ซม.)" value="box_length" />
            <ExcelColumn label="ความสูง (ซม.)" value="box_height" />
            <ExcelColumn label="ขนาด(ซ.ม.)" value="box_size" />
            <ExcelColumn label="ค่าขนส่ง (บาท)" value={(row) => parseInt(row.shipment_cost)} />
            <ExcelColumn label="COD (บาท)" value={(row) => parseInt(row.cod_cost)} />
            <ExcelColumn label="ค่าบริการ COD (บาท)" value={(row) => parseInt(row.service_cod)} />
            <ExcelColumn label="ชื่อธนาคาร" value="" />
            <ExcelColumn label="ชื่อบัญชี" value="bookbank_name" />
            <ExcelColumn label="เลขที่บัญชี" value="bookbank_code" />
            <ExcelColumn label="วันที่" value="created_date" />
            <ExcelColumn label="สถานะ" value="shipment_name" />
            <ExcelColumn label="วันที่เซ็นรับ" value="receiver_date" />
          </ExcelSheet>
        </ExcelFile>
      </div>

      <div className={data.length > 0 ? '' : 'hidden-ct'}>
        <div className="row text-center">
          <div className="col col-sm-4" style={{ fontSize: '25px' }}>
            ยอดรวม : {page.total.toLocaleString('en')} ชิ้น
          </div>
          {localStorage.getItem('superkey') === 'credit' && localStorage.getItem('show_shipment_cost')  === '0' ? "":(
              <div className="col col-sm-4" style={{ fontSize: '25px' }}>
                ค่าขนส่งสุทธิ : {parseInt(shipmentTotal).toLocaleString('en')} บาท
              </div>
              )}
          
          <div className="col col-sm-4" style={{ fontSize: '25px' }}>
            COD : {parseInt(codTotal).toLocaleString('en')} บาท
          </div>
        </div>
        <br />
        <div className="row mb-2" style={{ justifyContent: 'flex-end'}}>
          <div style={{gap: '5px'}} className={localStorage.getItem('superkey') !== '1947' ? 'col-sm-11' : 'hidden-ct'}>
          {localStorage.getItem('show_shipment_cost')  === '0' && localStorage.getItem('superkey') === 'credit' ? (
            <>
              <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => {
                      document.getElementById('downloadExHidePrice').click();
                    }}
                  >
                    <Icon.FileEarmarkArrowUp /> export
                </button>
                <button
                    type="button"
                    className="btn btn-info"
                    style={{marginLeft: '5px'}}
                    onClick={() => {
                      fetchExportdata({
                        search: values.search,
                        shipment_status: values.shipment_status,
                        start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
                        end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
                        cust_code: user.username,
                        cod_status: values.cod_status,
                      });
                    }}
                  >
                    <Icon.FileEarmarkArrowUp /> export/pic 
                </button>
            </>
            ) : (
                <>
                  
            <button
                type="button"
                className="btn btn-info"
                
                onClick={() => {
                  document.getElementById('downloadExShowPrice').click();
                }}
              >
                <Icon.FileEarmarkArrowUp /> export
              </button>
                </>
          )}  
          </div>
          <div className={localStorage.getItem('superkey') === '1947' ? 'col-sm-11' : 'hidden-ct'}>
            <button
              type="button"
              className="btn btn-info"
              onClick={() => {
                document.getElementById('downloadEx2').click();
              }}
            >
              <Icon.FileEarmarkArrowUp /> export
            </button>
          </div>
          <div className="col-sm-1">
            <select
              value={pageSizes}
              className="form-control "
              aria-label="Default select example"
              onChange={(e) => {
                setData([]);
                setDataExport([]);
                setcurrentPage(1);
                PageSizes(e.target.value);
                fetchData({
                  search: values.search,
                  shipment_status: values.shipment_status,
                  start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
                  end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
                  page: 1,
                  number_list: e.target.value,
                  cust_code: user.username,
                  cod_status: values.cod_status,
                  isCredit: localStorage.getItem('superkey')==='credit'?true:false
                });
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        {localStorage.getItem('superkey')==='credit'? (
          <div style={{ overflow: 'Auto', fontSize: '14px' }}>
          <table className="table table-bordered">
            <thead style={{ backgroundColor: '#3f75c1' }}>
              <tr style={{ color: '#FFFFFF' }}>
                <td>ลำดับ</td>
                <td>วันเปิดบิล</td>
                <td>บาร์โค้ด/สำหรับลูกค้า</td>
                <td>รหัสร้านค้า</td>
                <td>ผู้ส่ง</td>
                <td>ผู้รับ/ที่อยู่</td>
                <td>ขนาด (ซ.ม.)</td>
                <td>น้ำหนัก (กก.)</td>
                {localStorage.getItem('show_shipment_cost')  === '0' && localStorage.getItem('superkey') === 'credit' ?
                "":( <td>ค่าขนส่ง(บาท)</td>)}
                {localStorage.getItem('show_shipment_cost')  === '0' && localStorage.getItem('superkey') === 'credit' ?
                "":( <td>ค่าบริการ COD(บาท)</td>)}
                {localStorage.getItem('show_shipment_cost')  === '0' && localStorage.getItem('superkey') === 'credit' ?
                "":( <td>ยอดสุทธิ (บาท)</td>)}
                <td>COD (บาท)</td>
                <td>บัญชีธนาคาร</td>
                <td>จำนวนเอกสารนำกลับ</td>
                <td>สถานะ</td>
                <td>ลายเซ็น</td>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr key={value.awb_code}>
                  <td>{(page.current_page - 1) * pageSizes + (index + 1)}</td>
                  <td style={{ width: '150px' }}>{value.stockin_date !== null && value.stockin_date !== '' && value.stockin_date !== undefined ? <DateTH date={value.stockin_date} /> : ''}</td>
                  <td style={{ width: '150px' }}>
                    <div className="text-center"> {value.ref_no === null || value.ref_no === '0' ? " " : value.ref_no} </div>
                    <div className="text-center"> {value.ref_no2 === null || value.ref_no2 === '0' ? " " : value.ref_no2} </div>
                    <div className="text-center"> {value.item_desc === null ? " " : value.item_desc} </div>
                    <div className="text-center"> {value.awb_code}</div>
                    <div className={value.shipment_status === '5' ? 'hidden-ct' : 'text-center'} style={{ display: 'flex' }}>
                      <div className={localStorage.getItem('superkey') !== '1947' ? '' : 'hidden-ct'}>
                        <button
                          type="button"
                          disabled={value.awb_code !== '' ? false : true}
                          className="btn btn-warning"
                          onClick={() => {
                            setLabelData(value);
                            printLael({
                              typeLabel: 1,
                            });
                          }}
                        >
                          <Icon.Printer />
                        </button>
                      </div>
                      <div className={localStorage.getItem('superkey') === '1947' ? '' : 'hidden-ct'}>
                        <button
                          type="button"
                          disabled={value.awb_code !== '' ? false : true}
                          className="btn btn-warning"
                          onClick={async () => {
                            setIndex2(index);
                            setLabelData(value);
                            handlePrintShow();
                          }}
                        >
                          <Icon.Printer />
                        </button>
                      </div>
                      &nbsp;
                      <button
                        type="button"
                        disabled={value.awb_code !== '' ? false : true}
                        className="btn btn-success"
                        onClick={() => {
                          setLabelData(value);
                          printLael({
                            typeLabel: 2,
                          });
                        }}
                      >
                        <Icon.Printer />
                      </button>
                    </div>
                  </td>
                  <td>{value.cust_code}</td>
                  <td style={{ width: '130px' }}>
                    <div>
                      <b>{value.sender_name}</b>
                    </div>
                    <div>โทร : {value.sender_phone}</div>
                  </td>
                  <td style={{ width: '150px' }}>
                    <div>{value.receiver_name}</div>
                    <div>ที่อยู่ : {value.receiver_address + ' '}{value.receiver_district!== null ? value.receiver_district : ""}{' '+ value.receiver_amphur + ', ' + value.receiver_province}</div>
                  </td>
                  <td style={{ textAlign: 'right' }}>{value.box_size} <br/> {value.parcel_size}</td>
                  <td style={{ textAlign: 'right' }}>{value.weight}</td>
                  {/* <td style={{ textAlign: "center" }}>{value.num_parcel}</td> */}
                  {localStorage.getItem('show_shipment_cost')  === '0' && localStorage.getItem('superkey') === 'credit' ?
                "":( <td style={{ textAlign: 'right' }}>{parseInt(value.shipment_cost || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>)}
                {localStorage.getItem('show_shipment_cost')  === '0' && localStorage.getItem('superkey') === 'credit' ?
                "":( <td style={{ textAlign: 'right' }}>{parseInt(value.service_cod || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>)}
                {localStorage.getItem('show_shipment_cost')  === '0' && localStorage.getItem('superkey') === 'credit' ?
                "":( <td style={{ textAlign: 'right' }}>{(parseInt(value.shipment_cost) +parseInt(value.service_cod)).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>)}
                  <td style={{ textAlign: 'right' }}>{parseInt(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                  <td style={{ width: '150px' }}>
                    {value.bookbank_code === '' || value.bookbank_code === null || value.bookbank_name === '' || value.bookbank_name === null || value.bank_id === '' || value.bank_id === null ? (
                      ''
                    ) : (
                      <div>
                        {bank
                          .filter((x) => x.bank_id === value.bank_id)
                          .map((value, index) => (
                            <b key={index}>{value.bankName}</b>
                          ))}
                        <div>{value.bookbank_name}</div>
                        <div>{value.bookbank_code !== '' ? 'เลขที่บัญชี : ' + value.bookbank_code : ''}</div>
                      </div>
                    )}
                  </td>
                  <td>{value.num_return_doc}</td>
                  <td style={{ width: '160px' }}>
                    {value.shipment_status === '7' || value.shipment_status === '6' ? (
                      <p
                        type="button"
                        className=" btn-link"
                        style={{ textAlign: 'left', color: '#fa0909' }}
                        onClick={() => {
                          handleShow(value.awb_code);
                        }}
                      >
                        {value.shipment_name}
                      </p>
                    ) :(
                      value.shipment_status === '5' && localStorage.getItem('showImage')==='1'?(
                        <p
                        type="button"
                        className=" btn-link"
                        style={{ textAlign: 'left' }}
                        onClick={() => {
                          handleShowDeliverImg(value.awb_code);
                        }}
                      >
                        {value.shipment_name}
                      </p>
                      ):(
                        <p>{value.shipment_name}</p>
                      )
                    )}
                  </td>
                  <td align="center" style={{ width: '130px', fontSize: '12px' }}>
                    {value.shipment_status === '5' ? (
                      <center>
                        <img src={value.image} width="100" height="100" alt="" />
                        <br />
                        {value.receiver_date !== null && value.receiver_date !== '' 
                        && value.receiver_date !== undefined ? <DateTH date={value.receiver_date} /> : ''}
                      </center>
                    ) : (
                      <center>
                        <img src={notImg} width="80" height="80" alt="notImg" />
                      </center>
                    )}
                    {localStorage.getItem('showReturn')=== '1' && value.is_returned === "1" ?
                         <p style={{ color: '#fa0909' }}>(พัสดุถูกส่งกลับ)</p> : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        ):(
          <div style={{ overflow: 'Auto', fontSize: '14px' }}>
          <table className="table table-bordered">
            <thead style={{ backgroundColor: '#3f75c1' }}>
              <tr style={{ color: '#FFFFFF' }}>
                <td>ลำดับ</td>
                <td>วันเปิดบิล</td>
                <td>บาร์โค้ด/สำหรับลูกค้า</td>
                <td>รหัสร้านค้า</td>
                <td>ผู้ส่ง</td>
                <td>ผู้รับ/ที่อยู่</td>
                <td>ขนาด (ซ.ม.)</td>
                <td>น้ำหนัก (กก.)</td>
                {/* <td>จำนวน(ชิ้น)</td> */}
                <td>ค่าขนส่ง (บาท)</td>
                <td>ค่าบริการ COD(บาท)</td>
                <td>ยอดสุทธิ (บาท)</td>
                <td>COD (บาท)</td>
                <td>บัญชีธนาคาร</td>
                <td>สถานะ</td>
                <td>ลายเซ็น</td>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr key={value.awb_code}>
                  <td>{(page.current_page - 1) * pageSizes + (index + 1)}</td>
                  <td style={{ width: '150px' }}>{value.stockin_date !== null && value.stockin_date !== '' && value.stockin_date !== undefined ? <DateTH date={value.stockin_date} /> : ''}</td>
                  <td style={{ width: '150px' }}>
                    <div className="text-center"> {value.ref_no === null || value.ref_no === '0' ? " " : value.ref_no} </div>
                    <div className="text-center"> {value.ref_no2 === null || value.ref_no2 === '0' ? " " : value.ref_no2} </div>
                    <div className="text-center"> {value.awb_code}</div>
                    <div className={value.shipment_status === '5' ? 'hidden-ct' : 'text-center'} style={{ display: 'flex' }}>
                      <div className={localStorage.getItem('superkey') !== '1947' ? '' : 'hidden-ct'}>
                        <button
                          type="button"
                          disabled={value.awb_code !== '' ? false : true}
                          className="btn btn-warning"
                          onClick={() => {
                            setLabelData(value);
                            printLael({
                              typeLabel: 1,
                            });
                          }}
                        >
                          <Icon.Printer />
                        </button>
                      </div>
                      <div className={localStorage.getItem('superkey') === '1947' ? '' : 'hidden-ct'}>
                        <button
                          type="button"
                          disabled={value.awb_code !== '' ? false : true}
                          className="btn btn-warning"
                          onClick={async () => {
                            setIndex2(index);
                            setLabelData(value);
                            handlePrintShow();
                          }}
                        >
                          <Icon.Printer />
                        </button>
                      </div>
                      &nbsp;
                      <button
                        type="button"
                        disabled={value.awb_code !== '' ? false : true}
                        className="btn btn-success"
                        onClick={() => {
                          setLabelData(value);
                          printLael({
                            typeLabel: 2,
                          });
                        }}
                      >
                        <Icon.Printer />
                      </button>
                    </div>
                  </td>
                  <td>{value.cust_code}</td>
                  <td style={{ width: '130px' }}>
                    <div>
                      <b>{value.sender_name}</b>
                    </div>
                    <div>โทร : {value.sender_phone}</div>
                  </td>
                  <td style={{ width: '150px' }}>
                    <div>{value.receiver_name}</div>
                    <div>ที่อยู่ : {value.receiver_address + ' '} {value.receiver_district!== null ? value.receiver_district : ""} {' '+ value.receiver_amphur + ', ' + value.receiver_province}</div>
                  </td>
                  <td style={{ textAlign: 'right' }}>{value.box_size}</td>
                  <td style={{ textAlign: 'right' }}>{value.weight}</td>
                  {/* <td style={{ textAlign: "center" }}>{value.num_parcel}</td> */}
                  <td style={{ textAlign: 'right' }}>{parseInt(value.shipment_cost || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                  <td style={{ textAlign: 'right' }}>{parseInt(value.service_cod || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                  <td style={{ textAlign: 'right' }}>{(parseInt(value.shipment_cost) +parseInt(value.service_cod)).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                  <td style={{ textAlign: 'right' }}>{parseInt(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                  <td style={{ width: '150px' }}>
                    {value.bookbank_code === '' || value.bookbank_code === null || value.bookbank_name === '' || value.bookbank_name === null || value.bank_id === '' || value.bank_id === null ? (
                      ''
                    ) : (
                      <div>
                        {bank
                          .filter((x) => x.bank_id === value.bank_id)
                          .map((value, index) => (
                            <b key={index}>{value.bankName}</b>
                          ))}
                        <div>{value.bookbank_name}</div>
                        <div>{value.bookbank_code !== '' ? 'เลขที่บัญชี : ' + value.bookbank_code : ''}</div>
                      </div>
                    )}
                  </td>
                  <td style={{ width: '160px' }}>
                    {value.shipment_status === '7' || value.shipment_status === '6' ? (
                      <p
                        type="button"
                        className=" btn-link"
                        style={{ textAlign: 'left', color: '#fa0909' }}
                        onClick={() => {
                          handleShow(value.awb_code);
                        }}
                      >
                        {value.shipment_name}
                      </p>
                    ) : (
                      value.shipment_name
                    )}
                  </td>
                  <td align="center" style={{ width: '130px', fontSize: '12px' }}>
                    {value.shipment_status === '5' ? (
                      <center>
                        <img src={value.image} width="100" height="100" alt="" />
                        <br />
                        {value.receiver_date !== null && value.receiver_date !== '' && value.receiver_date !== undefined ? <DateTH date={value.receiver_date} /> : ''}
                        {value.is_returned === '1' ? <p style={{ color: '#fa0909' }}>(พัสดุถูกส่งกลับ)</p> : ''}
                      </center>
                    ) : (
                      <center>
                        <img src={notImg} width="80" height="80" alt="notImg" />
                      </center>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        )}
        

        <Modal show={printShow} onHide={handlePrintClose} size="xl" dialogClassName="modal-content">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div
              // className={typeLabel === "1" ? "" : "hidden-ct"}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '90%',
                transform: 'translate(-50%, -50%)',
              }}
            ></div>
            {data.map((value, index) => (
              <div>
                {index === index2 ? (
                  <div key={nanoid()}>
                    {value.platform === '5' ? (
                      <FormatLabelAddressPornor
                        key={nanoid()}
                        supplies={{
                          cust_code: value.cust_code,
                          /////////////////
                          receiver_name: value.receiver_name,
                          receiver_phone: value.receiver_phone,
                          receiver_address: value.receiver_address,
                          receiver_district: value.receiver_district,
                          receiver_amphur: value.receiver_amphur,
                          receiver_province: value.receiver_province,
                          receiver_zipcode: value.receiver_zipcode,
                          /////////////////
                          sender_name: value.sender_name,
                          sender_phone: value.sender_phone,
                          sender_address: value.sender_address,
                          sender_amphoe: value.sender_amphoe,
                          sender_province: value.sender_province,
                          sender_zipcode: value.sender_zipcode,
                          /////////////////
                          cod_cost: value.cod_cost,
                          /////////////////
                          awb_code: value.awb_code,
                        }}
                      />
                    ) : value.cod_cost === '0' ? (
                      <FormatLabelAddress
                        key={nanoid()}
                        supplies={{
                          receiver_name: value.receiver_name,
                          receiver_address: value.receiver_address,
                          receiver_district: value.receiver_district,
                          receiver_amphur: value.receiver_amphur,
                          receiver_province: value.receiver_province,
                          receiver_phone: value.receiver_phone,
                          cust_Code: value.cust_code,
                          number: 1,
                          awb_code: value.awb_code,
                          date: date,
                          month: month,
                          year: year,
                          weight: value.weight,
                          codCost: value.cod_cost,
                          deposit_type: value.deposit_type,
                          deposit_fullname: value.deposit_fullname,
                          deposit_phone: value.deposit_phone,
                          sender_name: value.sender_name,
                        }}
                      />
                    ) : (
                      <FormatLabelAddressCod
                        key={nanoid()}
                        supplies={{
                          receiver_name: value.receiver_name,
                          receiver_address: value.receiver_address,
                          receiver_district: value.receiver_district,
                          receiver_amphur: value.receiver_amphur,
                          receiver_province: value.receiver_province,
                          receiver_phone: value.receiver_phone,
                          cust_Code: value.cust_code,
                          number: 1,
                          awb_code: value.awb_code,
                          date: date,
                          month: month,
                          year: year,
                          weight: value.weight,
                          codCost: value.cod_cost,
                          deposit_type: value.deposit_type,
                          deposit_fullname: value.deposit_fullname,
                          deposit_phone: value.deposit_phone,
                          sender_name: value.sender_name,
                        }}
                      />
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            ))}
          </Modal.Body>
        </Modal>

        <Modal
        show={modalReceived}
        onHide={modalReceivedClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>รูปหลักฐานการส่ง</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '15px' }}>
          <div style={{ overflow: 'auto' }}>
            <table className="table table-bordered">
              <thead style={{ backgroundColor: '#3f75c1' }}>
                <tr style={{ color: '#FFFFFF' }}>
                  <td style={{ width: '8%' }}>วันที่/เวลาที่สแกน</td>
                  <td style={{ width: '5%' }}>รหัสพนักงานขนส่ง</td>
                  <td style={{ width: '30%' }}></td>
                </tr>
              </thead>
              <tbody>
                {dataDeliverImg.map((value, index) => (
                  <tr key={index + 'Key' + value.awb_code}>
                    <td><DateTH date={value.receiver_date} /></td>
                    <td>{value.driver_code}</td>
                    {/* <td><img className="d-block w-100" src={value.image} height="500px" alt={value.image} /></td> */}
                    <td><img className="d-block w-100" src={value.image} height="500px" alt={value.image} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
            {dataDeliverImg.length === 0 ? <div style={{ color: 'red', textAlign: 'center', padding: '50px' }}>ไม่พบข้อมูล</div> : ''}
          </div>
        </Modal.Body>
      </Modal>

        <div style={{ overflow: 'Auto' }}>
          <div className="d-flex justify-content-between">
            <div>จำนวนทั้งหมด {page.total} รายการ</div>
            <div>
              <Pagination
                count={parseInt(page.last_page)}
                page={currentPage}
                onChange={(e, newPage) => {
                  setData([]);
                  setDataExport([]);
                  setcurrentPage(newPage);
                  fetchData({
                    search: values.search,
                    shipment_status: values.shipment_status,
                    start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
                    end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
                    page: newPage,
                    number_list: pageSizes,
                    cust_code: user.username,
                    cod_status: values.cod_status,
                    isCredit: localStorage.getItem('superkey')==='credit'?true:false
                  });
                }}
                color="primary"
                size="small"
                defaultPage={6}
                siblingCount={1}
              />
            </div>
          </div>
        </div>
      </div>
      <center>
        {errorMassNoData === 1 && loading !== 1 ? (
          <font color="red" size="3">
            {' '}
            ไม่พบข้อมูล!{' '}
          </font>
        ) : (
          ''
        )}
      </center>
      {loading === 1 ? (
        <div className="m-5">
          <div className="text-center">
            <div className="spinner-border  text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            {' ...'}
            กำลังโหลดข้อมูล
          </div>
        </div>
      ) : (
        ''
      )}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>หมายเลขพัสดุ : {awb_Code}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '19px' }}>
          <div className="row">
            <div class={notData === 1 ? 'hidden-ct' : 'container'}>
              <div style={{ overflow: 'Auto' }}>
                <table class="table table-bordered">
                  <thead style={{ backgroundColor: '#3f75c1' }}>
                    <tr style={{ color: '#FFFFFF' }}>
                      <td scope="col">วันที่/เวลาที่สแกน</td>
                      {localStorage.getItem('superkey') === 'credit' ? "" : (
                        <td scope="col">หมายเหตุ</td>
                      )}
                      <td scope="col">รูปภาพ</td>
                    </tr>
                  </thead>
                  <tbody>
                    {dataDeliverFail.map((value, index) => (
                      <tr key={value.awb_code}>
                        <td style={{ width: '25%' }}>
                          <DateTH date={value.create_date} />
                        </td>
                        {localStorage.getItem('superkey') === 'credit' ? "" : (
                          <td>{value.note}</td>
                      )}
                        <td style={{ width: '35%' }}>
                          <Carousel>
                            {value.image.map((img, key) => (
                              <Carousel.Item>
                                <img className="d-block w-100" src={img} alt={'img Number' + key} height="500px" />
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <center className={notData === 1 ? '' : 'hidden-ct'}>
            <div style={{ marginTop: '100px', marginBottom: '100px' }}>
              <font color="red" size="3">
                -- ไม่พบข้อมูล! --
              </font>
            </div>
          </center>
          <div className={loadingDeliverFail === 1 ? 'm-5' : 'hidden-ct'}>
            <div className="text-center" style={{ marginTop: '100px', marginBottom: '100px' }}>
              <div className="spinner-border  text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              {' ...'}
              กำลังโหลดข้อมูล
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" class="btn btn-secondary" onClick={handleClose}>
            ปิด
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShowData;
