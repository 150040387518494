import React from "react";
import box from "../../../Assets/images/about/box.jpg";
import images from "./ClientsData";
import { connect } from "react-redux";

function About(props) {
  const { lang } = props;
  return lang.language === "th" ? <AboutTH /> : <AboutEN />;
}

const mapStateToProps = (state) => ({
  lang: state.LanguageAction,
});

export default connect(mapStateToProps)(About);

function AboutTH() {
  return (
    <div className="container">
      <br />
      <div className="text-center text-title-color">
        <h2>บริษัท ปอลอ เอ็กซ์เพรส จำกัด</h2>
      </div>
      <div>
        <h4 className="text-center text-subtitle">บริษัทเอกชนรายแรก ในปัจจุบันของธุรกิจทางด้านรับส่งพัสดุภัณฑ์ทั่วประเทศ</h4>
      </div>
      <br />
      <div className="row">
        <div className="col-4 img-align-center">
          <img className="img-align-center" src={box} alt="กล่องพัสดุ" width="50%" />
        </div>
        <div className="col-8">
          บริษัท ปอลอ เอ็กเพรส จำกัด เป็นบริษัทในเครือของ บริษัท อาร์เอฟอี เชอวีส (RFE) ซึ่งทำธุรกรรมด้านการกระจายสินค้า(B2B)
          ตรงเวลามานานกว่า20ปีจนถึงปัจจุบัน โดยมีลูกค้าที่ไว้วางใจใช้บริการกว่า300บริษัท
          ทีมงานRFEจึงมองเห็นความสำคัญในการให้บริการแก่สาธารณชนทั่วไป (B2C, C2C) เพื่อให้เป็นทางเลือกใหม่ที่ดีกว่า
          เน้นเรื่องตรงเวลา ปลอดภัย และตามเงื่อนไข RFE จึงจัดตั้งบริษัท ปอลอ เอ็กเพรส จำกัด ขึ้นมาโดยใช้ฐานปฏิบัตการขนส่ง
          การกระจายสินค้า ตลอดจนระบบการลำเลียงสิ่งของถึงจุดหมายปลายทางที่ปลอดภัยโดยมีRFE เป็นตัวขับเคลื่อน
          ทั้งนี้เพื่อให้เกิดความแม่นยำในการขนส่งปอลอ เอ็กเพรสจึงใช้บุคลากรที่มีความชำนาญพื้นที่ในประเทศไทยมากกว่า20ปี
          ของบริษัทRFE service จำกัด (RFE)ในการกระจายพัสดุภัณณ์และสินค้าออนไลน์
          จึงมั่นใจได้ว่าพัสดุภัณฑ์และสินค้าออนไลน์ของท่านจะได้รับการจัดส่งได้ตรงเวลา ปลอดภัยตามเงื่อนไข ทั่วประเทศไทย
          ด้วยบุคลากรที่มีความเชี่ยวชาญและมีประสบการณ์ด้านการขนส่งโดยแท้จริง
        </div>
      </div>
      <br />
      <br />
      <br />
      <div>
        <h4 className="text-center">บริษัทที่ให้ความไว้วางใจใช้บริการของ RFE/Porlor express</h4>
        <div className="row">
          {images.map(({ id, src, title, description }) => (
            <div className="col-2 img-align-center" key={id}>
              <img key={id} src={src} title={title} alt={description} width="100%" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function AboutEN() {
  return (
    <div className="container">
      <br />
      <div className="text-center text-title-color">
        <h2>Porlor Express Limited.</h2>
      </div>
      <div>
        <h4 className="text-center text-subtitle">
          The first and only private logistics company operating in documents and parcels’ delivery, all over Thailand.
        </h4>
      </div>
      <br />
      <div className="row">
        <div className="col-4 img-align-center">
          <img className="img-align-center" src={box} alt="กล่องพัสดุ" width="50%"/>
        </div>
        <div className="col-8">
          Porlor Express is the first private company that delivers documents and parcels, for retailers, individual
          customers and ecommerce all over Thailand. It is a new logistics choice for Thai citizens to choose from. We
          deliver and distribute the documents and parcels on time and 100% safely to the hands of the receiver. The company
          responsibly delivers the parcels on the next day anywhere in Thailand, thanks to Porlor’s distribution hubs located
          in every Thai province. We have experience delivering parcels and documents for over 20 years using advanced
          methods and for reasonable prices.
          <center>On time, Safe delivery, All over Thailand</center>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div>
        <h4 className="text-center">Customers who use Porlor & RFE services</h4>
        <div className="row">
          {images.map(({ id, src, title, description }) => (
            <div className="col-2 img-align-center" key={id}>
              <img key={id} src={src} title={title} alt={description} width="100%" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
