import * as Icon from "react-bootstrap-icons";
import swal from "sweetalert";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";

const HubUsersList = React.memo(({data,page,perPage,onDelete,onUpdate}) => {
    return (
        <>
            <div style={{ overflow: "Auto" }}>
                <table className="table table-bordered">
                    <thead style={{ backgroundColor: "#3f75c1" }}>
                    <tr style={{ color: "#FFFFFF" }}>
                        <td>ลำดับ</td>
                        <td style={{ width: "30%" }}>ชื่อ-นามสกุล</td>
                        <td>ชื่อผู้ใช้งาน</td>
                        <td>จัดการ</td>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((value, index) => (
                        <tr key={value.id}>
                            <td>{(page - 1) * perPage + (index + 1)}</td>
                            <td>{value.name}</td>
                            <td>{value.username}</td>
                            <td>
                                <button
                                    className="btn btn-warning"
                                    onClick={(e) => {
                                        onUpdate(value.id);
                                    }}
                                >
                                    <Icon.PencilSquare style={{ color: "#ffff" }} />
                                </button>{" "}
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        swal({
                                            className: "bg-modal-red",
                                            icon: "warning",
                                            dangerMode: true,
                                            text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                                            buttons: {
                                                cancel: "ยกเลิก",
                                                confirm: {
                                                    text: "ใช่",
                                                    value: value.id,
                                                },
                                            },
                                        }).then((value) => {
                                            if (value !== null) {
                                                onDelete(value);
                                            }
                                        });
                                    }}
                                >
                                    <Icon.Trash />
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <center>
                    {!data ? (
                        <font color="red" size="3">
                            {" "}
                            ไม่พบข้อมูล!{" "}
                        </font>
                    ) : (
                        ""
                    )}
                </center>
            </div>
        </>
    )
})

export default HubUsersList;