import {useEffect, useState} from "react";
import {hubCODParcelsCount} from "../../../../Service/service.Parcels";
import {setRef} from "@material-ui/core";

const useRemainingParcels = () => {
    const [result,setResult] = useState(null);
    const [error,setError] = useState(null);
    const [loading,setLoading] = useState(false);

    const fetchData = async () => {
        setError(null);
        setResult(null);
        setLoading(true);
        try{
            const result = await hubCODParcelsCount();
            setResult(result.data.data);
        }
        catch (error){
            setError(error.message);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    return {
        error,
        loading,
        result
    }
};

export default useRemainingParcels;