import {useState} from "react";
import Instance from "../../../../Helper/axios";
import {URL} from "../../../../Helper/baseURL";

export const useScanStatusUpdate = (status, onError, onSuccess) => {
    const [loading, setLoading] = useState(false);

    const updateStatus = async (awb_code) => {
        setLoading(true);
        try{
            const result = await Instance.post(URL + `hub/HubStatus/updateHubScanStatus`, {
                awb_code,
                id_hub: localStorage.getItem('id'),
                status: status,
            });
            onSuccess(result.data.message);
        }
        catch (error){
            onError(error.response.data.message);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading,
        updateStatus,
    }
}