import React from "react";
import { nanoid } from "nanoid";
import imgConote from "../../../../Assets/images/imagesMis/conoteEmpty.png";
import * as Icon from 'react-bootstrap-icons';

export default function PrintContent({ data, additionalStyles = {}, conoteGroups = [] }) {
    var custID = data.cust_code;
    var senderName = data.sender_name;
    var senderAddress = data.sender_address + " " + data.sender_amphur;
    var senderAddress2 = data.sender_province + " " +data.sender_zipcode;
    var receiverName = data.receiver_name;
    var receiverAddress = data.receiver_address + " ";
    var receiverAddress2 = data.receiver_amphur + " " + data.receiver_province + " " +data.receiver_zipcode;
    var receiverPhone = data.receiver_phone;
    var paymentMethod = data.payment_type;
    var payer = data.payer;
    var numPcs = data.pcs;
    var totalWeight = data.total_weight;
    var desrci = "";

  return (
    <div style={{ 
        margin: "0px",
        ...additionalStyles,
        // backgroundImage: `url(${imgConote})`, backgroundRepeat: 'no-repeat'
        }}>
        <div style={{ display: "flex", height: "75.59px", width: "755.90px"}}>
            <div style={{ marginLeft: "631.18px", marginTop: "40px"}}>
                {data.cust_code ? data.cust_code:" "}
            </div>
        </div>
        <div style={{ display: "flex"}}>
            {/* <div style={{ marginLeft: "49.13px" , height: "188.97px", width: "302.36px" }}> */}
            <div style={{ height: "188.97px", width: "250px", marginTop: "30px"}}>
                {data.sender_name?data.sender_name:" "}
                <div style={{ marginTop: "15.11px"}}>
                    {senderAddress ? senderAddress: " " }
                </div>
                <div style={{ marginTop: "15.11px"}}>
                    {senderAddress2 ? senderAddress2 : " "}
                </div>
                <div style={{ marginTop: "15.11px"}}>
                    {" "}
                </div>
            </div>
            {/* <div style={{ marginLeft: "11px" , marginTop: "19px", height: "188.97px", width: "75.59px" }}> */}
            <div style={{ height: "188.97px", width: "75.59px", marginLeft: "18px"  }}>
                {paymentMethod === "1" ? (
                    payer === "1" ? (
                        <span>
                            <Icon.Check size={"30px"} />
                            <div style={{ marginTop: "0px"}}>
                                <Icon.Check size={"30px"} />
                            </div> 
                        </span>
                         
                    ):(
                        <span>
                            <Icon.Check size={"30px"} />
                            <div style={{ marginTop: "30px" }}>
                                <Icon.Check size={"30px"} />
                            </div>
                        </span>  
                    )
                ):(
                    payer === "1" ? (
                        <div style={{ marginTop: "95px" }}>
                            <Icon.Check size={"30px"} />
                            <div style={{ marginTop: "0px"}}>
                                <Icon.Check size={"30px"} />
                            </div> 
                        </div> 
                    ):(
                        <div style={{ marginTop: "95px" }}>
                            <Icon.Check size={"30px"} />
                            <div style={{ marginTop: "30px"}}>
                                <Icon.Check size={"30px"} />
                            </div>  
                        </div>
                    )
                )}
            </div>
            <div style={{ marginTop: "30px", marginLeft: "78px" , height: "188.97px", width: "302.36px" }}>
                {receiverName ? receiverName : " " }
                <div style={{ marginTop: "15.11px"}}>
                    {receiverAddress ? receiverAddress : " "}
                    {data.receiver_district ? data.receiver_district : " "}    
                </div>
                <div style={{ marginTop: "15.11px"}}>
                    {receiverAddress2 ? receiverAddress2 : " "}
                </div>
                <div style={{ marginTop: "36px"}}>
                    {data.receiver_phone?data.receiver_phone:" "}
                </div>
                
            </div>
        </div>
        <div style={{ display: "flex", marginTop: "20px", height: "105.82px", width: "377.95px" }}>
            <div style={{ height: "105.82px", width: "45.35px" }}>
                {data.pcs ? data.pcs : " "}
            </div>
            <div style={{ height: "105.82px", width: "75.59px" }}>
                <div style={{ marginLeft: "20px"}}>
                    {data.total_weight ? data.total_weight : " "}
                </div>
            </div>
            <div style={{ height: "105.82px", width: "257px" }}>
                <div style={{ marginTop: "-35px", marginLeft: "0px", display: 'flex', flexDirection: 'column'}}>
                      <p style={{marginBottom: 0}}>{data.invoice ? data.invoice : " "}</p>
                      <p style={{marginBottom: 0}}>{data.item_desc ? data.item_desc : " "}</p>
                      {/* {conoteGroups && conoteGroups.map((value, index) => <p key={index} style={{marginBottom: 0}}>{value.box_size} = {value.parcel_number}</p>)} */}
                </div>
                {/* <div style={{ marginTop: "-25px", display: 'flex', flexDirection: 'column'}}>
                      {conoteGroups && conoteGroups.map((value, index) => <p key={index} style={{marginBottom: 0}}>{value.box_size} = {value.parcel_number}</p>)}
                </div> */}
            </div>
        </div>
        { data.return_doc === "1" ? (
                <div style={{ display: "flex", marginTop: "-40px"}}>
                    <div style={{ marginLeft: "690px"}}>
                        <Icon.Check size={"30px"} />
                    </div>
                    <div style={{ marginLeft: "10px", marginTop: "5px"}}>
                        {data.num_return_doc ? data.num_return_doc : " "}
                    </div>  
                </div>
                ): " "}
        
                {
                data.image !== null ? (
                    <div style={{ height: "60px", width: "257px" }}>
                        <div style={{ marginTop: "10px", display: 'flex', flexDirection: 'column'}}>
                            <img src={`${data.image}?${nanoid()}`} width="70" height="70" alt={data.image} />
                        </div>
                    </div>
                ):''  
            }
               
        
    </div>
  );
}
