import React from "react";
import {useTranslation} from "react-i18next";
import {Spinner} from "react-bootstrap";
import GenericTable from "./GenericTable";
import Pagination from "@material-ui/lab/Pagination";
import {scanInOutMetadata} from "./data/scanInOutMetadata";
import {ScanDateFilter} from "./ScanDateFilter";

export const ScanInOut = React.memo( ({
                                          dateFilters ,
                                          setDateFilters ,
                                          loading,
                                          data,
                                          handleChangePage
}) => {
    const {t} = useTranslation();

    return (
        <>
            {
                loading &&
                (
                    <div className="spinner-global-content">
                        <Spinner variant="success" animation="border" />
                    </div>
                )
            }
            {!loading && (
                <>
                    <div className="w-full d-flex justify-content-center align-items-center">
                        <ScanDateFilter dates={dateFilters} setDates={setDateFilters} />
                    </div>
                    {data && (
                        <>
                            <GenericTable
                                metadata={scanInOutMetadata(t)}
                                data={data.result}
                                keyExtractor={(item) => item.code}
                            />
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '5px'}}>
                                <Pagination
                                    count={data.maxPage}
                                    page={parseInt(data.pageNo)}
                                    onChange={handleChangePage}
                                    color="primary"
                                    size="small"
                                    siblingCount={1}
                                />
                            </div>
                        </>
                    )}
                </>
                )
            }
        </>
    )
});