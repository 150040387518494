import Modal from "react-bootstrap/Modal";
import React from "react";
import {getValidationRowStatus} from '../utils/MgBarcodes.utils';
export const MgBarcodeErrorList = ({validationData, onHide, children}) => {
    return (
        <Modal show={true}>
            <Modal.Header onHide={onHide} closeButton>
                <Modal.Title> รายการข้อผิดพลาด  </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-bordered">
                    <thead className="table-blue">
                        <tr>
                            <th style={{ textAlign: "center" }} className="text-white">awb_code</th>
                            <th style={{ textAlign: "center" }} className="text-white">ref_no</th>
                            <th style={{ textAlign: "center" }} className="text-white">สถานะ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {validationData.map(row => {
                            const validationStatusWithColor = getValidationRowStatus(row);
                            return (
                                <tr key={row.code}>
                                    <td  style={{ textAlign: "center" }}>{row.awb_code}</td>
                                    <td  style={{ textAlign: "center" }}>{row.ref_no}</td>
                                    <td style={{ textAlign: "center" , color: validationStatusWithColor.color}}>{validationStatusWithColor.text}</td>
                                </tr>)
                            }
                        )}
                    </tbody>
                </table>
                {children}
            </Modal.Body>
        </Modal>
    )
}