import React, { useState, useRef, useEffect } from "react";
import ShowData from "./showData";
import LabelForm from "./labelForm";
import { UpdatePrintStatus } from "../../../Service/service.Parcels";
import { GetRecoveryBarcode } from "../../../Service/service.TrackingReport";
import { Formik, Form} from "formik";
import { monthTH } from "../../../Data/month-th.json";
import * as Icon from "react-bootstrap-icons";
import { connect } from "react-redux";
import printJS from "print-js";
import { swalError } from "../../../Components/sweetAlert";
import { ExportProgressStatus } from "../../../enums/ExportProgressStatus";
import { saveAs } from "file-saver";
import * as ExcelJS from "exceljs";
import { ExportProgress } from "../../../Components/ExportProgress/ExportProgress";
import { addImage } from "../../../utility/tableToExcel";

const getPaginatedExcelData = (params) => {
    return GetRecoveryBarcode({
      ...params,
      number_list: 240,
    });
};

const initialProgression = {
  total: 0,
  current: 0,
  totalPages: 0,
  currentPage: 0,
};

function TrackingReport(props) {
  const { user } = props;
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  const [data, setData] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [pageSizes, PageSizes] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });
  const [errorMassNoData, setErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);
  const [codTotal, setCodTotal] = useState(0);
  const [shipmentTotal, setShipmentTotal] = useState(0);
  const [labelData, setLabelData] = useState([]);
  const [exportData, setExportData] = useState(null);
  const [isExporting, setIsExporting] = useState(false);
  const [exportProgression, setExportProgression] = useState(initialProgression);
  const excelObjectRef = useRef(null);
  const [currentPicExportFilters, setCurrentPickExportFilters] = useState(null);
  
  async function fetchData(value) {
    Loading(1);
    const result = await GetRecoveryBarcode(value);
    if (result !== undefined) {
      if (result.data !== false) {
        setData(result.data.result);
        setDataExport(result.dataExport);
        setPage({
          current_page: result.data.pageNo,
          last_page: result.data.maxPage,
          total: result.data.numRowsAll,
        });
        setCodTotal(result.sumCod);
        setShipmentTotal(result.sumShipment);
        setErrorMassNoData(0);
      } else {
        setData([]);
        setDataExport([]);
        setcurrentPage(1);
        setPage({
          current_page: 1,
          last_page: 1,
          total: 0,
        });
        setErrorMassNoData(1);
      }
      Loading(0);
    }
  }

  async function handleExportDataServerFetch(params) {
    try {
      const res = await getPaginatedExcelData(params);
      const serverResults = res.data.result.map(value => ({
            'วันเปิดบิล':value.created_date.substring(0, 10),
            'รหัสลูกค้า':value.cust_code,
            'เลขลาเบล':value.awb_code,
            'conote': value.ref_no === null || value.ref_no === '' || value.ref_no === "0"  ? "" : value.ref_no,
            'ผู้ส่ง':value.sender_name,
            'ผู้รับ':value.receiver_name,
            'ผู้รับที่อยู่': value.receiver_address,
            'district': value.receiver_district,
            'amphur': value.receiver_amphur,
            'province': value.receiver_province,
            'เบอร์โทรผู้รับ': value.receiver_phone,
            'จำนวน(ชิ้น)': value.num_parcel,
            'ค่าขนส่ง(บาท)': (parseFloat(value.shipment_cost) + parseFloat(value.service_cod)).toLocaleString(undefined, { minimumFractionDigits: 2 }),
            'COD': parseFloat(value.cod_cost),
            'สถานะ': value.shipment_name,
            'weight': value.weight,
            'box_size': value.box_size,
            'box_width': value.box_width,
            'box_length': value.box_length,
            'box_height': value.box_height,
            'ชื่อธนาคาร': value.bank_id,
            'ชื่อบัญชี': value.bookbank_name, 
            'เลขที่บัญชี': value.bookbank_code,
            'หมายเหตุ': value.remarkMG,
            'item_sku': value.item_sku,
            'item_desc': value.item_desc,
            'invoice': value.invoice,
            'ref_no2': value.ref_no2,
            'return_doc': value.num_return_doc,
            'receiver_date': value.receiver_date,
            'image': value.image,
      }));
      setExportData({
        data: serverResults,  
        page: params.page,  
        lastPage: res.data.maxPage,  
      });
      setExportProgression((state) => ({
        ...state,
        current: 0,
        totalPages: res.data.maxPage,
        total: res.data.result.length,
      }));
    }
    catch (error) {
      console.log(error);
      setIsExporting(false);
      excelObjectRef.current = null;
        swalError({title: '', text: 'เกิดข้อผิดพลาดระหว่างการส่งออก'})
            .then(value => {
                setIsExporting(false);
        });
    }
  }

  async function fetchExportdata(params) {
    setIsExporting(true);
    setCurrentPickExportFilters(params);
    handleExportDataServerFetch({
      ...params,
      page: 1,
    });
  }

  const handleImageFetch = (workbook,worksheet) => {
          const promiseArray = [];
          let progression = 0;
          console.log({
            data: exportData.data,
          });
          exportData.data.forEach((data, index) => {
            const row = worksheet.addRow({
                'วันเปิดบิล': data['วันเปิดบิล'],
                'รหัสลูกค้า': data['รหัสลูกค้า'],
                'เลขลาเบล': data['เลขลาเบล'],
                'เลขที่co note': data['conote'],
                'ผู้ส่ง': data['ผู้ส่ง'],
                'ผู้รับ': data['ผู้รับ'],
                'เบอร์โทรผู้รับ': data["เบอร์โทรผู้รับ"],
                'ที่อยู่': data["ผู้รับที่อยู่"], 
                'ตำบล' : data["district"], 
                'อำเภอ' : data["amphur"], 
                'จังหวัด': data["province"],
                'ผู้รับที่อยู่': data['ผู้รับที่อยู่'],
                'น้ำหนัก': data['weight'],
                'ความกว้าง (ซม.)': data['box_width'],
                'ความยาว (ซม.)': data['box_length'], 
                'ความสูง (ซม.)': data['box_height'], 
                'ขนาด(ซ.ม.)': data['box_size'],
                'จำนวน(ชิ้น)': data['จำนวน(ชิ้น)'],
                'COD (บาท)': data['COD'],
                // 'ค่าขนส่ง(บาท)': data['ค่าขนส่ง(บาท)'],
                'ชื่อธนาคาร' : data['ชื่อธนาคาร'],
                'ชื่อบัญชี' : data['ชื่อบัญชี'],
                'เลขที่บัญชี' : data['เลขที่บัญชี'],  
                'เลขสำหรับลูกค้า 1' : data['ref_no2'],
                'เลขสำหรับลูกค้า 2' : data['item_desc'],
                'เลขสำหรับลูกค้า 3': data['item_sku'],
                'จำนวนเอกสารนำกลับ' : data['num_return_doc'],
                'สถานะ': data['สถานะ'],
                'วันที่เซ็นรับ' : data['receiver_date'],
            });
            if(data['image'] !== '' && data['image'] !== undefined){
                const imagePromise = addImage(data['image'], workbook, worksheet,24,row);
                imagePromise.then(image => {
                    progression++;
                    setExportProgression((state) => ({
                        ...state,
                        current: progression
                    }));
                });
                imagePromise.catch(error => {
                    console.error(error);
                });
                promiseArray.push(
                    imagePromise
                );
            }
            else{
                    progression++;
                    setExportProgression((state) => ({
                        ...state,
                        current: progression
                    }));
                }
          });
          return Promise.all(promiseArray);
  };

    useEffect(() => {
    ( async () => {
    if (exportData) {
        if (!excelObjectRef.current) {
          const workBook = new ExcelJS.Workbook();
          const worksheet = workBook.addWorksheet('รายงานติดตามพัสดุ');
          excelObjectRef.current = {
              workBook,
              worksheet,
          };
          worksheet.columns = [
                { header: 'วันเปิดบิล', key: 'วันเปิดบิล', width: 10 },
                { header: 'รหัสลูกค้า', key: 'รหัสลูกค้า', width: 20 },
                { header: 'เลขลาเบล', key: 'เลขลาเบล', width: 50 },
                { header: 'เลขที่co note', key: 'เลขที่co note', width: 50 },
                { header: 'ผู้ส่ง', key: 'ผู้ส่ง', width: 50 },
                { header: 'ผู้รับ', key: 'ผู้รับ', width: 50 },
                { header: 'จำนวน(ชิ้น)', key: 'จำนวน(ชิ้น)', width: 50 },
                { header: 'เบอร์โทรผู้รับ', key: 'เบอร์โทรผู้รับ', width: 50 },
                { header: 'ผู้รับที่อยู่', key: 'ผู้รับที่อยู่', width: 50 },
                { header: 'ตำบล', key: 'ตำบล', width: 50 },
                { header: 'อำเภอ', key: 'อำเภอ', width: 50 },
                { header: 'จังหวัด', key: 'จังหวัด', width: 50 },
                // { header: 'ผู้รับที่อยู่', key: 'ผู้รับที่อยู่', width: 50 },
                { header: 'น้ำหนัก', key: 'น้ำหนัก', width: 50 },
                { header: 'ความกว้าง (ซม.)', key: 'ความกว้าง (ซม.)', width: 50 },
                { header: 'ความยาว (ซม.)', key: 'ความยาว (ซม.)', width: 50 },
                { header: 'ความสูง (ซม.)', key: 'ความสูง (ซม.)', width: 50 },
                { header: 'ขนาด(ซ.ม.)', key: 'ขนาด(ซ.ม.)', width: 50 },
                { header: 'COD (บาท)', key: 'COD (บาท)', width: 50 },
                // { header: 'ชื่อธนาคาร', key: 'ชื่อธนาคาร', width: 50 },
                // { header: 'ชื่อบัญชี', key: 'ชื่อบัญชี', width: 50 },
                // { header: 'เลขที่บัญชี', key: 'เลขที่บัญชี', width: 50 },
                { header: 'เลขสำหรับลูกค้า 1', key: 'เลขสำหรับลูกค้า 1', width: 50 },
                { header: 'เลขสำหรับลูกค้า 2', key: 'เลขสำหรับลูกค้า 2', width: 50 },
                { header: 'เลขสำหรับลูกค้า 3', key: 'เลขสำหรับลูกค้า 3', width: 50 },
                { header: 'จำนวนเอกสารนำกลับ', key: 'จำนวนเอกสารนำกลับ', width: 50 },
                { header: 'สถานะ', key: 'สถานะ', width: 50 },
                { header: 'วันที่เซ็นรับ', key: 'วันที่เซ็นรับ', width: 50 },
                { header: 'ลายเซ็น', key: 'ลายเซ็น', width: 125 },
          ];
        }

        await handleImageFetch(excelObjectRef.current.workBook,excelObjectRef.current.worksheet);
        setExportProgression((state) => ({
            ...state,
            currentPage: state.currentPage + 1,
        }));
        if (exportData.page !== exportData.lastPage) {
          handleExportDataServerFetch({
            ...currentPicExportFilters,
            page: exportData.page + 1,
          });
        }
        else {
            setExportProgression((state) => ({
              ...state,
              currentPage: state.totalPages,
            }));
            const result = await excelObjectRef.current.workBook.xlsx.writeBuffer();
            const blob = new Blob([result], { type: "application/xlsx" });
            setExportData(null);
            saveAs(blob, `รายงานติดตามพัสดุ.xlsx`);
            setIsExporting(false);
                      setExportData(initialProgression);
        }
    }
    })();
  }, [exportData]);

  
  const getStatus = () => {
    if(!exportData){
      return ExportProgressStatus.DATA_DOWNLOADING;
    }
    return ExportProgressStatus.PROCESSING_GENERATION;
  };

  async function UpdatePrint(value) {
    const res = await UpdatePrintStatus(value);
    if (res.status === 1) {
      if (value.typeLabel === 1) {
        printJS({
          printable: "labelNormal",
          type: "html",
          scanStyles: false,
          css: [
            window.location.protocol + "//" + window.location.host + "/static/PrinterCOD.css",
            "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
          ],
          style: "@page {size: 10cm 7cm;margin: 0cm;}",
        });
      } else {
        printJS({
          printable: "labelAddress",
          type: "html",
          scanStyles: false,
          css: [
            window.location.protocol + "//" + window.location.host + "/static/Printer.css",
            "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
          ],
          style: "@page {size: 10cm 15cm;margin: 0.3cm;}",
        });
      }
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  }

  return (
    <div>
      {isExporting && <ExportProgress status={getStatus()} progression={exportProgression} />}
      <Formik
        // validationSchema={schema}
        initialValues={{
          search: "",
          shipment_status: "",
          dateNow: 0,
          startDay: "",
          startMonth: "",
          startYear: "",
          endDay: "",
          endMonth: "",
          endYear: "",
          cod_status: "",
          page: 1,
        }}
        onSubmit={(value) => {
          setcurrentPage(1);
          setData([]);
          setDataExport([]);
          setPage({
            current_page: 0,
            last_page: 1,
            total: 0,
          });
          fetchData({
            search: value.search,
            shipment_status: value.shipment_status,
            start_date:
              value.startYear !== "" && value.startMonth !== "" && value.startDay !== ""
                ? value.startYear + "-" + value.startMonth + "-" + value.startDay
                : "",
            end_date:
              value.endYear !== "" && value.endMonth !== "" && value.endDay !== ""
                ? value.endYear + "-" + value.endMonth + "-" + value.endDay
                : "",
            page: 1,
            number_list: pageSizes,
            cust_code: user.username,
            cod_status: value.cod_status,
            isCredit: localStorage.getItem('superkey')==='credit'?true:false
          });
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className="card mb-5">
              <div className="card-body">
                <div className="container-fluid mt-2 mb-2">
                  <div className="text-center text-title-color">
                    <h2>รายงานติดตามพัสดุ</h2>
                  </div>
                  <br />
                  <div className="row justify-content-md-center">
                    <div className="col col-sm-4">
                      <input
                        type="text"
                        value={values.search}
                        onChange={(e) => {
                          setFieldValue("search", e.target.value);
                        }}
                        placeholder="ค้นหาบาร์โค้ด"
                        className={"form-control "}
                      />
                    </div>
                    <div className="col col-sm-3">
                      <select
                        value={values.shipment_status}
                        onChange={(e) => {
                          setFieldValue("shipment_status", e.target.value);
                        }}
                        className={"form-control"}
                        placeholder="-- กรุณาเลือกสถานะ --"
                      >
                        <option style={{ color: "#000000" }} value={""}>
                          -- ทั้งหมด --
                        </option>
                        <option value={1} key={1}>
                          1. รับเข้าระบบ
                        </option>
                        <option value={2} key={2}>
                          2. ศูนย์กระจายคัดแยกสินค้า
                        </option>
                        <option value={3} key={3}>
                          3. อยู่ระหว่างขนส่ง
                        </option>
                        
                        <option value={6} key={6}>
                          4. ติดต่อผู้รับไม่ได้
                        </option>
                        <option value={5} key={5}>
                          5. รับแล้ว
                        </option>
                        {/* {localStorage.getItem('showReturn') === '1' ? (
                          <option value={7} key={7}>
                          6. พัสดุถูกส่งกลับต้นทาง
                        </option>
                        ):""} */}
                        <option value={7} key={7}>
                          6. พัสดุถูกส่งกลับต้นทาง
                        </option>
                        <option value={8} key={8}>
                          7. ที่อยู่/เบอร์โทรไม่ถูกต้อง
                        </option>
                        <option value={9} key={9}>
                          8. ลูกค้าปฏิเสธรับ
                        </option>
                        {localStorage.getItem('superkey') !== 'credit' ? (
                          <option value={11} key={11}>
                          9. สินค้าสูญหาย
                        </option>
                        ):""}
                        {localStorage.getItem('superkey') !== 'credit' ? (
                          <option value={12} key={12}>
                          10. สินค้าเสียหาย
                        </option>
                        ):""}
                        {localStorage.getItem('superkey') !== 'credit' ? (
                          <option value={13} key={13}>
                          11. พื้นที่ผิด
                        </option>
                        ):""}
                        {localStorage.getItem('superkey') !== 'credit' ? (
                          <option value={10} key={10}>
                          12. ลูกค้าเลื่อนรับ
                        </option>
                        ):(
                          <option value={10} key={10}>
                          9. ลูกค้าเลื่อนรับ
                        </option>
                        )}
                        
                      </select>
                    </div>
                  </div>
                  <br />
                  <div className="row justify-content-md-center">
                    <div className="col-12 col-lg-2">
                      <br />
                      <center style={{ marginTop: "10px" }}>
                        <div className="form-check">
                          <input
                            checked={values.dateNow === 0 ? false : true}
                            style={{ width: "15px", height: "15px" }}
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            onChange={(e) => {
                              if (values.dateNow === 0) {
                                setFieldValue("dateNow", 1);
                                setFieldValue("startDay", date);
                                setFieldValue("startMonth", ("0" + month).slice(-2));
                                setFieldValue("startYear", year);
                                setFieldValue("endDay", date);
                                setFieldValue("endMonth", ("0" + month).slice(-2));
                                setFieldValue("endYear", year);
                              } else {
                                setFieldValue("dateNow", 0);
                                setFieldValue("startDay", "");
                                setFieldValue("startMonth", "");
                                setFieldValue("startYear", "");
                                setFieldValue("endDay", "");
                                setFieldValue("endMonth", "");
                                setFieldValue("endYear", "");
                              }
                            }}
                          />
                          <label className="form-check-label" htmlFor="flexCheckDefault" style={{ marginLeft: "10px" }}>
                            วันที่ปัจจุบัน
                          </label>
                        </div>
                      </center>
                    </div>
                    <div className="col-12 col-lg-4">
                      <span>ตั้งแต่วันที่เปิดบิล</span>
                      <div className="row">
                        <div className="col-12 col-sm-4 col-md-4 px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.startYear}
                            onChange={(e) => {
                              setFieldValue("startYear", e.target.value);
                            }}
                          >
                            <option value=""> -- ปี -- </option>
                            {years.map((year, index) => (
                              <option key={`year${index}`} value={year}>
                                {year + 543}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.startMonth}
                            onChange={(e) => {
                              setFieldValue("startMonth", e.target.value);
                            }}
                          >
                            <option value=""> -- เดือน -- </option>
                            {monthTH.map((item, index) => (
                              <option value={item.id} key={item.id}>
                                {item.monthName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.startDay}
                            onChange={(e) => {
                              setFieldValue("startDay", e.target.value);
                            }}
                          >
                            <option value=""> -- วัน -- </option>
                            {Array.from(Array(31), (e, i) => (
                              <option value={i + 1}>{i + 1}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <span>ถึงวันที่เปิดบิล</span>
                      <div className="row">
                        <div className="col-12 col-sm-4 px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.endYear}
                            onChange={(e) => {
                              setFieldValue("endYear", e.target.value);
                            }}
                          >
                            <option value=""> -- ปี -- </option>
                            {years.map((year, index) => (
                              <option key={`year${index}`} value={year}>
                                {year + 543}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-4  px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.endMonth}
                            onChange={(e) => {
                              setFieldValue("endMonth", e.target.value);
                            }}
                          >
                            <option value=""> -- เดือน -- </option>
                            {monthTH.map((item, index) => (
                              <option value={item.id} key={item.id}>
                                {item.monthName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-4  px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.endDay}
                            onChange={(e) => {
                              setFieldValue("endDay", e.target.value);
                            }}
                          >
                            <option value=""> -- วัน -- </option>
                            {Array.from(Array(31), (e, i) => (
                              <option value={i + 1} key={i}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg px-md-1 mt-0">
                      <br />
                      <center style={{ marginTop: "10px" }}>
                        <div className="form-check form-check-inline">
                          <input
                            checked={values.cod_status === "1" ? true : false}
                            style={{ width: "15px", height: "15px" }}
                            className="form-check-input"
                            type="checkbox"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="1"
                            onChange={(e) => {
                              if (values.cod_status !== "1" ? true : false) {
                                setFieldValue("cod_status", e.target.value);
                              } else {
                                setFieldValue("cod_status", "");
                              }
                            }}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio1">
                            มี COD
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            checked={values.cod_status === "0" ? true : false}
                            style={{ width: "15px", height: "15px" }}
                            className="form-check-input"
                            type="checkbox"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="0"
                            onChange={(e) => {
                              if (values.cod_status !== "0" ? true : false) {
                                setFieldValue("cod_status", e.target.value);
                              } else {
                                setFieldValue("cod_status", "");
                              }
                            }}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio2">
                            ไม่มี COD
                          </label>
                        </div>
                      </center>
                    </div>
                  </div>
                  <br />
                  <div className="row justify-content-md-center">
                    <div className="col col-sm-4">
                      <button
                        disabled={loading === 1 ? true : false}
                        type="submit"
                        className={loading === 1 ? "btn btn-secondary" : "btn btn-primary"}
                      >
                        <Icon.Search /> ค้นหา
                      </button>
                      <span>&nbsp;</span>
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => {
                          setData([]);
                          setDataExport([]);
                          setPage({
                            current_page: 0,
                            last_page: 1,
                            total: 0,
                          });
                          setcurrentPage(1);
                          PageSizes(10);
                          fetchData({
                            search: "",
                            shipment_status: "",
                            start_date: "",
                            end_date: "",
                            page: 1,
                            number_list: 10,
                            cust_code: user.username,
                            cod_status: "",
                            isCredit: localStorage.getItem('superkey')==='credit'?true:false
                          });
                        }}
                      >
                        <Icon.ArrowClockwise /> ล้างค่า
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ShowData
              data={data}
              dataExport={dataExport}
              page={page}
              pageSizes={pageSizes}
              errorMassNoData={errorMassNoData}
              currentPage={currentPage}
              values={values}
              fetchData={fetchData}
              fetchExportdata={fetchExportdata}
              setData={setData}
              setDataExport={setDataExport}
              setcurrentPage={setcurrentPage}
              loading={loading}
              user={user}
              codTotal={codTotal}
              shipmentTotal={shipmentTotal}
              PageSizes={PageSizes}
              setLabelData={setLabelData}
              printLael={UpdatePrint}
            />
            <br />
            <br />
            <LabelForm data={labelData} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(TrackingReport);
