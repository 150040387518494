import React, { useState, useEffect } from "react";
import ShowData from "./showData";
import { connect } from "react-redux";
import { GetReceipt, CancelReceipt, reprintReceipt, getPrintReceipt,updateParcel } from "../../../Service/service.Receipt";
import { UpdateReceiptPrint } from "../../../Service/service.Parcels";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import printJS from "print-js";
import FormatReceipt from "../formatLabel/formatReceipt";
import Modal from "react-bootstrap/Modal";
import PagSize from "../../../Data/pagSize.json";
import FormatReceiptPornor from "../formatLabelPornor/formatReceiptPornor";
import { Formik, Form, FieldArray, ErrorMessage, getIn } from 'formik';
import { PriceCalculation } from '../../../Components/PriceCalculation';
import InputAutocompleteV2 from '../../../Components/inputAutocompleteV2';


function Receipt(props) {
  const { user } = props;
  var year = new Date().getFullYear();
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  var today = new Date(),
    time = today.getHours() + ":" + today.getMinutes();
  const [perPage, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });
  const [dateReceipt, setDateReceipt]=useState('');
  const [pageNo, setCurrentPage] = useState(1);
  const [loading, Loading] = useState(0);
  const [errorMassNoData, setErrorMassNoData] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("1");
  const [dataReceipt, setDataReceipt] = useState({
    freightPrice: "",
    getMoney: "",
    changePrice: "",
    amont: "",
  });
  const [handleBlur,setHandleBlur] = useState(false);
  const [dataArrayReceipt, setDataArrayReceipt] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const [receiptCode, setReceiptCode] = useState("");
  const [remark, setRemark] = useState("");
  const [remarkError, setRemarkError] = useState(0);
  const [bankId, setBankId] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [freightPrice, setFreightPrice] = useState(0);
  const [getMoney, setGetMoney] = useState("");
  const [changePrice, setChangePrice] = useState(0);
  const [weight,setWeight] = useState("");
  const [box_width, setBox_width]= useState("");
  const [box_length, setBox_length] = useState("");
  const [box_height, setBox_height] = useState("");
  const [box_size, setBox_size] = useState("");
  const [shipment_cost,setShipment_cost] = useState("");
  const [cod_cost, setCod_cost] = useState("");
  const [awb_code,setAwb_code] = useState("");
  const [receiver_province, setReceiver_province] = useState("");
  const [receiver_amphur,setReceiver_amphur] = useState("");
  const [sender_type,setSender_type] = useState("");
  const [total, setTotal] = useState(0);
  const [service_cod,setService_cod] = useState(0);
  const [dataEdit, setDataEdit] = useState({});

  useEffect(() => {
    fetchData({
      pageNo: 1,
      perPage: perPage,
      search: "",
      cust_code: user.roleType === "1" || user.roleType === "3" ? "" : user.username,
      status: "1",
    });
  }, []);

  async function fetchData(data) {
    Loading(1);
    let res = await GetReceipt(data);
    if (res !== false) {
      setData(res.result);
      setPage({
        current_page: res.pageNo,
        last_page: res.maxPage,
        total: res.numRowsAll,
      });
      setErrorMassNoData(0);
      Loading(0);
    } else {
      setData([]);
      setCurrentPage(1);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      setErrorMassNoData(1);
      Loading(0);
    }
  }

  async function Reprint(data) {
    let res = await reprintReceipt(data);
    if (res.status === 1) {
      setDataReceipt(res.data);
      setDataArrayReceipt(res.dataArray);
      res.dataArray.filter((x) => x.bank_id !== "").map((value) => setBankId(value.bank_id));
      res.dataArray.filter((x) => x.bookbank_code !== "").map((value) => setBankCode(value.bookbank_code));
      res.dataArray.filter((x) => x.bookbank_name !== "").map((value) => setBankName(value.bookbank_name));
      setRemark("");
      receiptPrint();
    } else {
      swal({
        title: "ไม่สามารถพิมพ์ได้!",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
  }

  async function receiptPrintUpdateStatus() {
    var daraArray = {
      receiptCode: receiptCode,
      freightPrice: freightPrice,
      getMoney: getMoney,
      changePrice: changePrice,
    };
    console.log(daraArray);
    const res = await getPrintReceipt(daraArray);
    if (res.status === 1) {
      setDataReceipt(res.data);
      setDataArrayReceipt(res.dataArray);
      res.dataArray.filter((x) => x.bank_id !== "").map((value) => setBankId(value.bank_id));
      res.dataArray.filter((x) => x.bookbank_code !== "").map((value) => setBankCode(value.bookbank_code));
      res.dataArray.filter((x) => x.bookbank_name !== "").map((value) => setBankName(value.bookbank_name));
      fetchData({
        pageNo: 1,
        perPage: 10,
        search: "",
        cust_code: user.roleType === "1" || user.roleType === "3" ? "" : user.username,
        status: "1",
      });
      setFreightPrice(0);
      setGetMoney("");
      setChangePrice(0);
      setStatus("1");
      setSearch("");
      handleClose2();
      receiptPrint();
    } else {
      swal({
        title: "ไม่สามารถพิมพ์ได้!",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
  }
  async function updateParcelInfo(data){
    // alert(JSON.stringify(data));
    let res = await updateParcel(data);
    // alert(JSON.stringify(res));
    // alert(res.status);
    // alert(JSON.stringify(res.res));
    if (res.res[0].status === 1) {
      swal({
        title: "success",
        text: "",
        icon: "success",
        showConfirmButton: true,
        button: "ปิด",
        timer: 15000,
      });
    } else {
      swal({
        title: "ไม่สามารถพิมพ์ได้!",
        text: res.message,
        icon: "warning",
        showConfirmButton: true,
        button: "ปิด",
        timer: 15000,
      });
    }
  }


  async function receiptPrint() {
    printJS({
      printable: "printReceipt",
      type: "html",
      scanStyles: false,
      css: [
        window.location.protocol + "//" + window.location.host + "/newporlor/static/Printer.css",
        "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
      ],
      style: "@page {size: 8cm 30cm;margin: 0cm;}",
    });
  }

  const Del = async (value) => {
    let res = await CancelReceipt(value);
    if (res.status === 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      fetchData({
        pageNo: 1,
        perPage: perPage,
        search: search,
        cust_code: user.roleType === "1" || user.roleType === "3" ? "" : user.username,
        status: status,
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };

  const submit = () => {
    setData([]);
    setCurrentPage(1);
    setPage({
      current_page: 1,
      last_page: 1,
      total: 0,
    });
    setErrorMassNoData(1);
    fetchData({
      pageNo: 1,
      perPage: perPage,
      search: search,
      cust_code: user.roleType === "1" || user.roleType === "3" ? "" : user.username,
      status: status,
    });
  };

  const clearfilter = () => {
    setSearch("");
    setStatus("1");
    setData([]);
    setCurrentPage(1);
    setPage({
      current_page: 1,
      last_page: 1,
      total: 0,
    });
    setPageSize(10);
    setErrorMassNoData(1);
    fetchData({
      pageNo: 1,
      perPage: 10,
      search: "",
      cust_code: user.roleType === "1" || user.roleType === "3" ? "" : user.username,
      status: "1",
    });
  };

  const handleChangePageSize = (e) => {
    setData([]);
    setErrorMassNoData([]);
    setPageSize(e);
    setCurrentPage(1);
    fetchData({
      pageNo: 1,
      perPage: e,
      search: search,
      cust_code: user.roleType === "1" || user.roleType === "3" ? "" : user.username,
      status: status,
    });
  };

  const handleChangePage = (e, newPage) => {
    setData([]);
    setErrorMassNoData([]);
    setCurrentPage(newPage);
    fetchData({
      pageNo: newPage,
      perPage: perPage,
      search: search,
      cust_code: user.roleType === "1" || user.roleType === "3" ? "" : user.username,
      status: status,
    });
  };

  return (
    <div>
      <div className="text-center text-title-color">
        <h2>ประวัติการพิมพ์ใบเสร็จ</h2>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-4">
          <label>สถานะการพิมพ์</label>
          <br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              checked={status === "1" ? true : false}
              value="1"
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            />
            <label class="form-check-label" for="inlineRadio1">
              พิมพ์ใบเสร็จแล้ว
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              checked={status === "0" ? true : false}
              value="0"
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            />
            <label class="form-check-label" for="inlineRadio2">
              ยังไม่ได้พิมพ์ใบเสร็จ
            </label>
          </div>
        </div>
        <div className="col-sm-4">
          <span>ค้นหาหมายเลขใบเสร็จ</span>
          <input
            value={search}
            type="text"
            className="form-control"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="col-sm-2">
          <br />
          <button
            className="btn btn-primary"
            onClick={() => {
              submit();
            }}
          >
            ค้นหา
          </button>{" "}
          <button
            className="btn btn-secondary"
            onClick={() => {
              clearfilter();
            }}
          >
            ล้าง
          </button>
        </div>
        <div className="col-sm-2">
          <br />
          <select
            name="pageSize"
            id="pageSize"
            className="form-control float-right col-6"
            value={perPage}
            onChange={(e) => {
              handleChangePageSize(e.target.value);
            }}
          >
            {PagSize.map((v) => (
              <option value={v.key} key={v.key}>
                {v.value}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <ShowData
        setWeight = {setWeight}
        setBox_width = {setBox_width}
        setBox_length = {setBox_length}
        setBox_height = {setBox_height}
        setBox_size = {setBox_size}
        setShipment_cost = {setShipment_cost}
        setCod_cost = {setCod_cost}
        setAwb_code = {setAwb_code}
        setReceiver_province = {setReceiver_province}
        setReceiver_amphur = {setReceiver_amphur}
        setSender_type = {setSender_type}
        setService_cod = {setService_cod}
        setDataEdit = {setDataEdit}
        setDateReceipt={setDateReceipt}
        status={status}
        data={data}
        page={page}
        loading={loading}
        errorMassNoData={errorMassNoData}
        pageNo={pageNo}
        handleChangePage={handleChangePage}
        perPage={perPage}
        Del={Del}
        swal={swal}
        Reprint={Reprint}
        handleShow={handleShow}
        handleShow2={handleShow2}
        handleShowEdit={handleShowEdit}
        setReceiptCode={setReceiptCode}
        setRemarkError={setRemarkError}
        setFreightPrice={setFreightPrice}
      />
      <br />
      <br />
      <div className="contentPrint2" style={{ display: "none" }}>
        <div id="printReceipt">
          <div key={nanoid()}>
            {dataArrayReceipt.length > 0 ? (
              dataArrayReceipt[0].cust_type === "1" ? (
                <FormatReceipt
                  key={nanoid()}
                  supplies={{
                    receiptCode: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].receipt_code : "",
                    date: date,
                    month: month,
                    year: year,
                    time: time,
                    sender_name: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].sender_name : "",
                    sender_phone: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].sender_phone : "",
                    amont_parcel: dataReceipt.amont,
                    freightPrice: dataReceipt.freightPrice,
                    getMoney: dataReceipt.getMoney,
                    changePrice: dataReceipt.changePrice,
                    printLebel: dataArrayReceipt,
                    /////////////////
                    cod_cost: dataReceipt.sumCod,
                    service_cod: dataReceipt.sumSerCod,
                    bank_id: bankId,
                    bookbank_name: bankName,
                    bookbank_code: bankCode,
                  }}
                />
              ) : (
                <FormatReceiptPornor
                  key={nanoid()}
                  supplies={{
                    receiptCode: receiptCode,
                    date: date,
                    month: month,
                    year: year,
                    time: time,
                    sender_name: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].sender_name : "",
                    receiver_name: "",
                    weight: "",
                    shipment_cost: "",
                    freightPrice: dataReceipt.freightPrice,
                    getMoney: dataReceipt.getMoney,
                    changePrice: dataReceipt.changePrice,
                    shipmentPrice: dataReceipt.shipmentPrice,
                    serviceOrter: dataReceipt.serviceOrter,
                    printLebel: dataArrayReceipt,
                  }}
                />
              )
            ) : (
              <div>ไม่สามารถสร้างใบเสร็จได้</div>
            )}
          </div>
        </div>
      </div>
      <div className="contentPrint2" style={{ display: "none" }}>
        <div id="printReceipt2">
          <div key={nanoid()}>
            {dataArrayReceipt.length > 0 ? (
              dataArrayReceipt[0].platform === "5" ? (
                <FormatReceiptPornor
                  key={nanoid()}
                  supplies={{
                    receiptCode: receiptCode,
                    date: date,
                    month: month,
                    year: year,
                    time: time,
                    sender_name: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].sender_name : "",
                    receiver_name: "",
                    weight: "",
                    shipment_cost: "",
                    freightPrice: dataReceipt.freightPrice,
                    getMoney: dataReceipt.getMoney,
                    changePrice: dataReceipt.changePrice,
                    shipmentPrice: dataReceipt.shipmentPrice,
                    serviceOrter: dataReceipt.serviceOrter,
                    printLebel: dataArrayReceipt,
                  }}
                />
              ) : (
                <FormatReceipt
                  key={nanoid()}
                  supplies={{
                    receiptCode: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].receipt_code : "",
                    date: date,
                    month: month,
                    year: year,
                    time: time,
                    sender_name: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].sender_name : "",
                    sender_phone: dataArrayReceipt.length > 0 ? dataArrayReceipt[0].sender_phone : "",
                    amont_parcel: dataReceipt.amont,
                    freightPrice: freightPrice,
                    getMoney: getMoney,
                    changePrice: changePrice,
                    printLebel: dataArrayReceipt,
                    /////////////////
                    cod_cost: dataReceipt.sumCod,
                    service_cod: dataReceipt.sumSerCod,
                    bank_id: bankId,
                    bookbank_name: bankName,
                    bookbank_code: bankCode,
                  }}
                />
              )
            ) : (
              <div>ไม่สามารถสร้างใบเสร็จได้</div>
            )}
          </div>
        </div>
      </div>
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>พิมพ์ใบเสร็จ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mgButton-ct30">
              <div className="col col-sm-12">
                <label>เหตุผล</label>
                <textarea
                  className={remarkError === 1 ? "form-control is-invalid" : "form-control"}
                  id="exampleFormControlTextarea1"
                  rows="3"
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                ></textarea>
                {remarkError === 1 ? (
                  <font color="red" size="2">
                    กรุณากรอกเหตุผล
                  </font>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={handleClose}>
              ยกเลิก
            </button>
            <button
              className="btn btn-info"
              onClick={() => {
                if (remark === "") {
                  setRemarkError(1);
                } else {
                  Reprint({
                    receipt_code: receiptCode,
                    remark: remark,
                    cust_code: user.username,
                  });
                  handleClose();
                }
              }}
            >
              พิมพ์
            </button>
          </Modal.Footer>
        </Modal>
      </>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>พิมพ์ใบเสร็จ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
          localStorage.getItem('superkey') !== '1947' ? (
            <div className="text-center">
            <br />
            <div className="row">
              <div className="col-sm-12 mt-3">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">รวมค่าขนส่ง</label>
                  <div class="col-sm-9">
                    <input readOnly value={freightPrice} type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mt-3">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">รับเงิน</label>
                  <div class="col-sm-9">
                    <input
                      autoComplete="off"
                      value={getMoney}
                      type="number"
                      class="form-control"
                      onChange={(e) => {
                        setGetMoney(e.target.value);
                        if (e.target.value >= freightPrice && e.target.value !== "") {
                          setChangePrice(e.target.value - freightPrice);
                        } else {
                          setChangePrice(0);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mt-3">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">ทอนเงิน</label>
                  <div class="col-sm-9">
                    <input value={changePrice} readOnly type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                  <button
                  disabled={getMoney !== "" && getMoney >= freightPrice ? false : true}
                  className={
                    getMoney !== "" && getMoney >= freightPrice
                      ? "btn btn-info btn-lg mgTop-ct-20"
                      : "btn btn-secondary btn-lg mgTop-ct-20"
                  }
                  style={{ width: "100%" }}
                  onClick={() => {
                    receiptPrintUpdateStatus();
                  }}
                >
                  พิมพ์ใบเสร็จ
                  </button>
              </div>
              <div className="col-sm-6">
                <button
                  // disabled={statusPrint === 1 ? false : true}
                  className="btn btn-secondary btn-lg mgTop-ct-20"
                  style={{ width: "100%" }}
                  onClick={() => {
                    handleClose2();
                    setFreightPrice(0);
                    setGetMoney("");
                    setChangePrice(0);
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
            <br />
            <br />
          </div>
          ):(
            <div className="text-center">
            <br />
            <p>
            ใบเสร็จนี้ท่านสามารถปริ้นได้ภายใน30นาทีหลังจากปริ้นลาเบลแล้วเท่านั้น หากท่านต้องการที่จะปริ้นใบเสร็จหลังจากเวลาดังกล่าวท่านสามรถขอปริ้นได้ที่สาขาใกล้เคียงค่ะ
            </p>
            <div className="row">
              <div className="col-sm-6">
                <button
                  disabled={(new Date(dateReceipt).getTime()+30*60000-Date.now()<0) && localStorage.getItem('superkey')==='1947'?true:false}
                  className={(new Date(dateReceipt).getTime()+30*60000-Date.now()<0) && localStorage.getItem('superkey')==='1947'
                  ?"btn btn-secondary btn-lg mgTop-ct-20"
                  :"btn btn-info btn-lg mgTop-ct-20"}
                  style={{ width: "100%" }}
                  onClick={() => {
                    receiptPrintUpdateStatus();
                  }}
                >
                  พิมพ์ใบเสร็จ
                </button>
              </div>
              <div className="col-sm-6">
                <button
                  // disabled={statusPrint === 1 ? false : true}
                  className="btn btn-secondary btn-lg mgTop-ct-20"
                  style={{ width: "100%" }}
                  onClick={() => {
                    handleClose2();
                    setFreightPrice(0);
                    setGetMoney("");
                    setChangePrice(0);
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
            <br />
            <br />
          </div>
          )
        }
        </Modal.Body>
      </Modal>

      <Modal show={showEdit} onHide={handleCloseEdit} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
        แก้ไขข้อมูล
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: '19px' }}>
              <div className="row" style={{ justifyContent: 'center', margin: '10px' }}>
                <div className="col col-sm-3 px-md-1 mt-1">
                  <div className="form-group">
                    <label>น้ำหนัก (กก.)</label>
                    <input
                        // autocomplete="off"
                        value={weight}
                        type="number"
                        name={'weight'}
                        className={'form-control  '}
                        onBlur={handleBlur}
                        placeholder={weight}
                        onChange={async (e) => {
                          setWeight(e.target.value);
                          // setFieldValue('weight', e.target.value);
                          if (e.target.value > 0) {
                            var totalvar = await PriceCalculation(box_size, e.target.value, receiver_province,receiver_amphur, sender_type);
                            setShipment_cost(totalvar);
                            // setFieldValue('shipment_cost', total);
                            setTotal(parseFloat(totalvar)+parseFloat(service_cod));
                            // setFieldValue('total', total + values.service_cod);
                          }
                        }}
                      />
                  </div>
                </div>
                <div className="col col-sm-3 px-md-1 mt-1">
                  <div className="form-group">
                    <label>ความกว้าง (ซม.)</label>
                    <input
                      className={'form-control '}
                      type="number"
                      name="box_width"
                      onBlur={handleBlur}
                      value={box_width}
                      onChange={async (e) => {
                        setBox_width(e.target.value);
                        // setFieldValue('box_width', e.target.value);
                        if (e.target.value !== '' && box_length !== '' && box_height !== '') {
                          var sizeTotal = 0;
                          sizeTotal = parseInt(e.target.value) + parseInt(box_length) + parseInt(box_height);
                          setBox_size(sizeTotal);
                          // setFieldValue('box_size', sizeTotal);
                          var totalvar = await PriceCalculation(sizeTotal, weight, receiver_province, receiver_amphur, sender_type);
                          setShipment_cost(totalvar);
                          // setFieldValue('shipment_cost', total);
                          setTotal(parseFloat(totalvar) +parseFloat(service_cod));
                          // setFieldValue('total', totalvar + values.service_cod);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col col-sm-3 px-md-1 mt-1">
                  <div className="form-group">
                    <label>ความยาว (ซม.)</label>
                    <input
                      className={'form-control ' }
                      type="number"
                      name="box_length"
                      onBlur={handleBlur}
                      value={box_length}
                      onChange={async (e) => {
                        setBox_length(e.target.value);
                        // setFieldValue('box_length', e.target.value);
                        if (e.target.value !== '' && box_width !== '' && box_height !== '') {
                          var sizeTotal = 0;
                          sizeTotal = parseInt(e.target.value) + parseInt(box_width) + parseInt(box_height);
                          setBox_size(sizeTotal);
                          // setFieldValue('box_size', sizeTotal);
                          var totalvar = await PriceCalculation(sizeTotal, weight, receiver_province, receiver_amphur, sender_type);
                          setShipment_cost(totalvar);
                          // setFieldValue('shipment_cost', totalvar);
                          setTotal(parseFloat(totalvar)+parseFloat(service_cod));
                          // setFieldValue('total', total + values.service_cod);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col col-sm-3 px-md-1 mt-1">
                  <div className="form-group">
                    <label>ความสูง (ซม.)</label>
                    <input
                      className={'form-control ' }
                      type="number"
                      name="box_height"
                      onBlur={handleBlur}
                      value={box_height}
                      onChange={async (e) => {
                        setBox_height(e.target.value);
                        // setFieldValue('box_height', e.target.value);
                        if (e.target.value !== '' && box_width !== '' && box_length !== '') {
                          var sizeTotal = 0;
                          sizeTotal = parseInt(e.target.value) + parseInt(box_width) + parseInt(box_length);
                          setBox_size(sizeTotal);
                          // setFieldValue('box_size', sizeTotal);
                          var totalvar = await PriceCalculation(sizeTotal, weight, receiver_province, receiver_amphur, sender_type);
                          setShipment_cost(totalvar);
                          // setFieldValue('shipment_cost', total);
                          setTotal(parseFloat(totalvar)+parseFloat(service_cod));
                          // setFieldValue('total', total + values.service_cod);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col col-sm-4 px-md-1 mt-1">
                  <sppan>กว้าง + ยาว + สูง (ซม.)</sppan>
                  <input className="form-control" value={box_size} readOnly />
                </div>
                <div className="col col-sm-4 px-md-1 mt-1">
                  <sppan>ค่าขนส่ง (บาท)</sppan>
                  <input className="form-control" value={shipment_cost} readOnly />
                </div>
                <div className="col col-sm-4 px-md-1 mt-1">
                  <sppan>ยอดสุทธิ (บาท)</sppan>
                  <input className="form-control" value={total} readOnly />
                </div>
              </div>
              <br />
              <center>
                <button className="btn btn-primary" type="submit" onClick={()=>{
                  updateParcelInfo({
                    "awb_code":awb_code,
                    "weight":weight,
                    "box_size":box_size,
                    "box_length":box_length,
                    "box_width":box_width,
                    "box_height":box_height,
                    "shipment_cost":shipment_cost,
                    "cod_cost":cod_cost!=="" && cod_cost!==null?cod_cost:"",
                    "total":total,
                    "receipt_code":receiptCode
                  });
                  handleCloseEdit();
                }}>
                  บันทึก
                </button>
                &nbsp;&nbsp;
                <button className="btn btn-secondary" type="button" onClick={handleClose2}>
                  ยกเลิก
                </button>
              </center>
              <br />
      </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(Receipt);
