import * as Yup from "yup";

const stockSchema = Yup.object().shape({
  startYear: Yup.string().required("กรุณาระบุ ปี"),
  startMonth: Yup.string().required("กรุณาระบุ เดือน"),
  startDay: Yup.string().required("กรุณาระบุ วัน"),
  endYear: Yup.string().required("กรุณาระบุ ปี"),
  endMonth: Yup.string().required("กรุณาระบุ เดือน"),
  endDay: Yup.string().required("กรุณาระบุ วันที่"),
  // startDate: Yup.string().required("กรุณาระบุ วันที่เริ่มต้น"),
  startTime: Yup.string().required("กรุณาระบุ เวลาเริ่มต้น"),
  // endDate: Yup.string().required("กรุณาระบุ วันที่สิ้นสุด"),
  endTime: Yup.string().required("กรุณาระบุ เวลาสิ้นสุด"),
  parcelstutus: Yup.string().required("กรุณาระบุ สถานะพัสดุ"),
  category: Yup.string().when("stockType", (value, schema) => {
    if (value === "2") {
      return schema.notRequired();
    } else {
      return schema.required("กรุณาระบุ ประเภทลูกค้า");
    }
  }),
  branch: Yup.string().test("is-branch", "กรุณาระบุ สาขา", function (value) {
    const { category, stockType } = this.parent;

    if (stockType === "2") {
      return true;
    }
    if (category === "3") {
      return true;
    }
    if(value === undefined) {
      return false
    }
    else {
      return true;
    }
  }),

  // .when("stockType", (value, schema) => {
  //   if (value === "2") {
  //     return schema.notRequired();
  //   } else {
  //     return schema.required("กรุณาระบุ สาขา");
  //   }
  // }).when("category", (value, schema) => {
  //   if (value === "3") {
  //     return schema.notRequired();
  //   } else {
  //     return schema.required("กรุณาระบุ สาขา");
  //   }
  // }),
  transportWorker: Yup.string().when("stockType", (value, schema) => {
    if (value === "2") {
      return schema.required("กรุณาระบุ พนักงานขนส่ง");
    } else {
      return schema.notRequired();
    }
  }),
});

export default stockSchema;
