import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import LongDateTH from "../../../utility/LongDateTH";
import { useHistory, useLocation } from "react-router-dom";

import Form from "../../public/apply/manager/form";

export default function EditAndDetail() {

  return (
    <>
      <div>
        <Form />
      </div>
    </>
  );
}
