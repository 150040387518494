import React, {useEffect, useState} from "react";
import HubBodyLayout from '../../../Layouts/Hub/components/hub-body-layout';
import { HubPresentation } from '../Shared/HubPresentation';
import BarcodeInput from '../Shared/BarcodeInput';
import {Spinner} from "react-bootstrap";
import {useScanStatusUpdate} from "../Shared/hooks/use-scan-status-update";
import {swalError, swalSuccess} from "../../../Components/sweetAlert";
import {useTranslation} from "react-i18next";
import {format, parse} from "date-fns";
import {useGetScanInOut} from "../Shared/hooks/use-get-scan-in-out";
import {ScanInOut} from "../Shared/ScanInOut";
import useScanOutRemainingItems from "./hooks/use-scan-out-remaining-items";
import {ScanOutRemainingParcels} from "./components/scan-out-remaining-parcels";


const getDateFilters = (dates) => {
  return {
    startDate: parse(format(dates.startDate, 'yyyy/MM/dd 00:00:00'), 'yyyy/MM/dd HH:mm:ss', new Date()),
    endDate: parse(format(dates.endDate, 'yyyy/MM/dd 23:59:00'), 'yyyy/MM/dd HH:mm:ss', new Date())
  }
};

const getAPIDates = (dates) => {
  return {
    startDate: format(dates.startDate, 'yyyy-MM-dd 00:00:00'),
    endDate: format(dates.endDate, 'yyyy-MM-dd 23:59:00')
  }
}

const HubScanOut = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [remainingItemsCurrentPage, setRemainingItemsCurrentPage] = useState(1);

  const [awb_code, setAwb_code] = useState('');
  const [dateFilters, setDateFilters] = useState(getDateFilters({
    startDate: new Date(),
    endDate: new Date()
  }));

  const  {loading: dataLoading, data, fetch} = useGetScanInOut((error) => {
    void swalError({
      text: error.message
    })
  });

  const {loading: remainingItemsLoading, data: remainingItemsData, fetch : fetchRemainingItems,remainingItems,totalItems} = useScanOutRemainingItems((error) =>  {
    void swalError({
      text: error.message
    })
  });

  const initialFetch = () => {
    setCurrentPage(1);
    setRemainingItemsCurrentPage(1);
    fetch(1, 1, getAPIDates(dateFilters));
    fetchRemainingItems(1);
  }

  useEffect(() => {
    initialFetch();
  }, []);

  const {t} = useTranslation();
  const {loading: updateStatusLoading, updateStatus} = useScanStatusUpdate(
      2,
      (error) => {
        void swalError({
          text: error
        })
      },
      (message) => {
        void swalSuccess({
          text: message,
        });
        initialFetch();
      }
  );

  const loading = dataLoading || updateStatusLoading;


  const handleDateFiltersChanged = (filters) => {
    setDateFilters(filters);
    setCurrentPage(1);
    void fetch(currentPage, 2, getAPIDates(filters))
  }

  const handleChangePage = (event,page) => {
    setCurrentPage(page);
    void fetch(page, 1, getAPIDates(dateFilters))
  }

  const handleRemainingItemsPageChange = (event,page) => {
    setRemainingItemsCurrentPage(page);
    void fetchRemainingItems(page)
  }

  const leftContent = (
      <>
        <HubPresentation />
        {loading &&
            <div className="spinner-global-content">
              <Spinner variant="success" animation="border" />
            </div>
        }
        {!loading && (
            <>
              <BarcodeInput
                  value={awb_code}
                  setValue={setAwb_code}
                  placeholder={t('hub.scan.barcode_input')}
                  submit={updateStatus.bind(null,awb_code)} />
              <ScanInOut
                  dateFilters={dateFilters}
                  setDateFilters={handleDateFiltersChanged}
                  loading={loading}
                  data={data}
                  handleChangePage={handleChangePage}
              />
            </>
        )}
      </>
  );

  const rightContent = (
      <>
        {
          remainingItemsLoading && (
                <div className="spinner-global-content">
                  <Spinner variant="success" animation="border" />
                </div>
            )
        }
        {
          !remainingItemsLoading && remainingItemsData && (
              <ScanOutRemainingParcels
                  data={remainingItemsData}
                  remainingItems={remainingItems}
                  totalItems={totalItems}
                  handleChangePage={handleRemainingItemsPageChange}
              />
            )
        }
      </>
  );

  return (
      <HubBodyLayout leftContent={leftContent} rightContent={rightContent}/>
  )
}
export default HubScanOut;
