import React, { useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { nanoid } from "nanoid";
import * as Icon from "react-bootstrap-icons";
import ReactExport from "react-data-export";
import { ReportStockIn } from "../../../Service/service.reportStock";
import PrintContent from "./printContent";
import printJS from "print-js";
import DateTH from '../../../Helper/date-th';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ShowData({
  data,
  values,
  loadData,
  page,
  currentPage,
  setcurrentPage,
  errorMassNoData,
  loading,
  pageSizes,
  PageSizes,
  setData,
}) {
  const [exportData, SetExportData] = useState([]);

  async function LoadDataExport(action) {
    let res = await ReportStockIn({
      pageNo: 1,
      perPage: 10,
      custCode: values.branch,
      stockType: values.stockType,
      startDate: values.startYear + "-" + values.startMonth + "-" + values.startDay,
      startTime: values.startTime,
      endDate: values.endYear + "-" + values.endMonth + "-" + values.endDay,
      endTime: values.endTime,
      transportWorker: values.transportWorker,
      custType: values.category,
      action: "print",
      businessType: values.businessType,
    });
    SetExportData(res.data);
    if (action === "downloadEx")  {
      document.getElementById("downloadEx").click();
    } 
    else if (action === "downloadExShop") {
      document.getElementById("downloadExShop").click();
    }
    else {
      printJS({
        printable: "printReport",
        type: "html",
        scanStyles: false,
        css: [
          window.location.protocol+"//" + window.location.host + "/static/printerReport.css",
          "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
        ],
        style: "@page { size: A4 Landscape !important; margin: 2.5mm !important; } ",
      });
    }
  }

  return (
    <div>
      <>
        <br />
        <div className="d-flex justify-content-between mb-2">
          <div>
            <div style={{ display: "none" }}>
              <div id="printReport">
                <PrintContent
                  data={exportData}
                  branchs={values.branch}
                  startDate={values.startYear + "-" + values.startMonth + "-" + values.startDay}
                  endDate={values.endYear + "-" + values.endMonth + "-" + values.endDay}
                />
              </div>
            </div>
            <ExcelFile
              element={
                <button style={{ display: "none" }} id="downloadExShop">
                  Download Data
                </button>
              }
              filename="ทะเบียนคุมสินค้า"
            >
              <ExcelSheet data={exportData} name={"ทะเบียนคุมสินค้า"}>
                <ExcelColumn label="รหัส ID" value="cust_code" width={{ wpx: 80 }} />
                <ExcelColumn label="สำหรับลูกค้า" value="ref_no" />
                <ExcelColumn label="AWB" value="awb_code" />
                <ExcelColumn label="ชื่อผู้รับ" value="receiver_name" />
                <ExcelColumn label="เขต/อำเภอ" value="receiver_amphur" />
                <ExcelColumn label="จังหวัด" value="receiver_province" />
                <ExcelColumn label="ชิ้น" value="num_parcel" />
                <ExcelColumn label="น้ำหนัก" value="weight" />
                <ExcelColumn label="ความกว้าง (ซม.)" value="box_width" />
                <ExcelColumn label="ความยาว (ซม.)" value="box_length" />
                <ExcelColumn label="ความสูง (ซม.)" value="box_height" />
                <ExcelColumn label="ขนาด(ซ.ม.)" value="box_size" />
                <ExcelColumn label="ค่าขนส่ง" value={(row) => (parseInt(row.shipment_cost))} />
                <ExcelColumn label="ค่าขนส่ง COD" value={(row) => (parseInt(row.service_cod))} />
                <ExcelColumn label="ค่าขนส่ง" value={(row) => (parseInt(row.shipment_cost)+parseInt(row.service_cod))} />
                <ExcelColumn label="COD (บาท)" value={(row) => parseInt(row.cod_cost)} />
                <ExcelColumn label="ชื่อธนาคาร" value="bank_id" />
                <ExcelColumn label="ชื่อบัญชี" value="bookbank_name" />
                <ExcelColumn label="เลขที่บัญชี" value="bookbank_code" />
                <ExcelColumn label="หมายเหตุ" value="remarkMG" />
              </ExcelSheet>
            </ExcelFile>
            <ExcelFile
              element={
                <button style={{ display: "none" }} id="downloadEx">
                  Download Data
                </button>
              }
              filename="ทะเบียนคุมสินค้า"
            >
              <ExcelSheet data={exportData} name={"ทะเบียนคุมสินค้า"}>
                <ExcelColumn label="รหัส ID" value="cust_code" width={{ wpx: 80 }} />
                <ExcelColumn label="สำหรับลูกค้า" value="ref_no" />
                <ExcelColumn label="AWB" value="awb_code" />
                <ExcelColumn label="ชื่อผู้รับ" value="receiver_name" />
                <ExcelColumn label="เขต/อำเภอ" value="receiver_amphur" />
                <ExcelColumn label="จังหวัด" value="receiver_province" />
                <ExcelColumn label="ชิ้น" value="num_parcel" />
                <ExcelColumn label="น้ำหนัก" value="weight" />
                <ExcelColumn label="ความกว้าง (ซม.)" value="box_width" />
                <ExcelColumn label="ความยาว (ซม.)" value="box_length" />
                <ExcelColumn label="ความสูง (ซม.)" value="box_height" />
                <ExcelColumn label="ขนาด(ซ.ม.)" value="box_size" />
                <ExcelColumn label="ค่าขนส่ง COD" value={(row) => (parseInt(row.service_cod))} />
                <ExcelColumn label="COD (บาท)" value={(row) => parseInt(row.cod_cost)} />
                <ExcelColumn label="ชื่อธนาคาร" value="bank_id" />
                <ExcelColumn label="ชื่อบัญชี" value="bookbank_name" />
                <ExcelColumn label="เลขที่บัญชี" value="bookbank_code" />
                <ExcelColumn label="หมายเหตุ" value="remarkMG" />
                <ExcelColumn label="หมายเลขinvoice" value="invoice" />
                <ExcelColumn label="เลขสำหรับลูกค้า 1" value="ref_no2" />
                <ExcelColumn label="เลขสำหรับลูกค้า 2" value="item_desc" />
                <ExcelColumn label="เลขสำหรับลูกค้า 3" value="item_sku" />
                <ExcelColumn label="เอกสารนำกลับ" value="num_return_doc" />
              </ExcelSheet>
            </ExcelFile>
            <button
              type="button"
              className="btn btn-info mr-2"
              onClick={() => {
                LoadDataExport("btnPrint");
              }}
            >
              <Icon.Printer /> พิมพ์
            </button>
            <button
              type="button"
              className="btn btn btn-info"
              onClick={() => {
                {localStorage.getItem('superkey') !== 'shop' ? (
                  LoadDataExport("downloadEx")
                ):(
                  LoadDataExport("downloadExShop")
                )}
              }}
            >
              <Icon.FileEarmarkArrowUp /> export
            </button>
          </div>
          <div>
            <>
              <select
                value={pageSizes}
                className="form-control"
                aria-label="Default select example"
                onChange={(e) => {
                  setcurrentPage(1);
                  PageSizes(e.target.value);
                  loadData({
                    pageNo: 1,
                    perPage: e.target.value,
                    custCode: values.branch,
                    stockType: values.stockType,
                    startDate: values.startYear + "-" + values.startMonth + "-" + values.startDay,
                    startTime: values.startTime,
                    endDate: values.endYear + "-" + values.endMonth + "-" + values.endDay,
                    endTime: values.endTime,
                    transportWorker: values.transportWorker,
                    custType: values.category,
                    action: "show",
                    businessType: values.businessType,
                  });
                }}
              >
                <option value="5" key="5">
                  5
                </option>
                <option value="10" key="10">
                  10
                </option>
                <option value="20" key="20">
                  20
                </option>
                <option value="50" key="50">
                  50
                </option>
                <option value="100" key="100">
                  100
                </option>
              </select>
            </>
          </div>
        </div>
        <div style={{ overflow: "Auto" }}>
        {localStorage.getItem('superkey') !== 'shop' ? (
          <table className="table table-bordered">
            <thead style={{ backgroundColor: "#3f75c1" }}>
              <tr style={{ color: "#FFFFFF" }}>
                <th>ลำดับ</th>
                <th>วันที่รับสินค้า</th>
                <th>ลาเบล</th>
                <th>เลขที่co note</th>
                <th>ชื่อผู้ส่ง</th>
                <th>ชื่อผู้รับ</th>
                <th>น้ำหนัก</th>
                <th>จำนวน(ชิ้น)</th>
                <th>COD(บาท)</th>
                <th>จำนวนเอกสารนำกลับ</th>
                <th>หมายเลขinvoice</th>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr key={index}>
                  <td>{(page.current_page - 1) * pageSizes + (index + 1)}</td>
                  <td><DateTH date={value.created_date} /></td>
                  <td>{value.awb_code}</td>
                  <td>{value.ref_no}</td>
                  <td>{value.cust_code}</td>
                  <td>{value.receiver_name}</td>
                  <td>{value.weight}</td>
                  <td>{value.num_parcel}</td>
                  <td>
                    {parseInt(value.cod_cost) === 0 || parseInt(value.cod_cost) > 0
                      ? parseInt(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : parseInt(0).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                  <td>{value.num_return_doc}</td>
                  <td>{value.invoice}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ): 
        (
          <table className="table table-bordered">
            <thead style={{ backgroundColor: "#3f75c1" }}>
              <tr style={{ color: "#FFFFFF" }}>
                <th>ลำดับ</th>
                <th>วันที่รับสินค้า</th>
                <th>ลาเบล</th>
                <th>ชื่อผู้ส่ง</th>
                <th>ชื่อผู้รับ</th>
                <th>น้ำหนัก</th>
                <th>จำนวน(ชิ้น)</th>
                <th>COD(บาท)</th>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr key={index}>
                  <td>{(page.current_page - 1) * pageSizes + (index + 1)}</td>
                  <td><DateTH date={value.created_date} /></td>
                  <td>
                  {value.awb_code}
                  </td>
                  <td>{value.cust_code}</td>
                  <td>{value.receiver_name}</td>
                  <td>{value.weight}</td>
                  <td>{value.num_parcel}</td>
                  <td>
                    {parseInt(value.cod_cost) === 0 || parseInt(value.cod_cost) > 0
                      ? parseInt(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })
                      : parseInt(0).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
          
        </div>
        <div className="d-flex justify-content-between">
          <div>จำนวนทั้งหมด {page.total} รายการ</div>
          <div>
            <Pagination
              count={parseInt(page.last_page)}
              page={currentPage}
              onChange={(e, newPage) => {
                setData([]);
                setcurrentPage(newPage);
                loadData({
                  pageNo: newPage,
                  perPage: pageSizes,
                  custCode: values.branch,
                  stockType: values.stockType,
                  startDate: values.startYear + "-" + values.startMonth + "-" + values.startDay,
                  startTime: values.startTime,
                  endDate: values.endYear + "-" + values.endMonth + "-" + values.endDay,
                  endTime: values.endTime,
                  transportWorker: values.transportWorker,
                  custType: values.category,
                  action: "show",
                  businessType: values.businessType,
                });
              }}
              color="primary"
              size="small"
              defaultPage={6}
              siblingCount={1}
            />
          </div>
        </div>
      </>
      <br />
    </div>
  );
}
