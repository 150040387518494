import {useEffect, useState} from "react";
import Instance from "../../../../Helper/axios";

const useScanOutRemainingItems = (onError) => {
    const [data, setData] = useState(null);
    const [remainingItems, setRemainingItems] = useState(null);
    const [totalItems, setTotalItems] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetch = async (page) => {
        setData(null);
        setRemainingItems(null);
        setTotalItems(null);
        setLoading(true);
        try{
            const result = await Instance.get(`/hub/HubStatus/getRemainingScanOutList/${localStorage.getItem('id')}/${page}`);
            setData(result.data.result);
            setTotalItems(result.data.total_scan_number);
            setRemainingItems(result.data.remaining_scan_number);
        }
        catch (error){
            onError(error);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        data,
        totalItems,
        remainingItems,
        loading,
        fetch
    }
};

export default useScanOutRemainingItems;