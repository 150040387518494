import React from "react";
import { connect } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import profileSchema from "./validateProfile";
import { resetPassword } from "../../../Service/service.Profile"
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

function Profile(props) {
  const history = useHistory();

  async function ResetPassword(value) {
    const res = await resetPassword(value);
    if (res.status === 1) {
      swal({
        title: "บันทึกสำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      history.push("/mis/Login");
    } else {
      swal({
        title: "บันทึกไม่สำเร็จ!",
        text: "",
        icon: "warning",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
    }
  }

  return (
    <>
      <Formik
        validationSchema={profileSchema}
        initialValues={{
          cust_type: props.roleType,
          custCode: props.username,
          password: "",
          confirmPassword: "",
          showPassword: false,
        }}
        onSubmit={(value) => {
          ResetPassword({
            custCode: value.custCode,
            password: value.password,
            cust_type: value.cust_type
          })
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className="justifyContentCenter displayFlex mb-5">
              <div className="w-50-custom card-sender p-5">
                <div className="text-center">
                  <h3> แก้ไขข้อมูลส่วนตัว</h3>
                </div>
                <div className="form-group row mt-4">
                  <label htmlFor="user" className="col-sm-3 col-12 col-form-label">
                    ผู้ใช้งาน
                  </label>
                  <div className="col-sm-9  col-12 ">
                    <input type="text" readOnly className="form-control-plaintext" id="user" defaultValue={props.name} />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="password" className="col-sm-3  col-12 col-form-label">
                    รหัสผ่าน
                  </label>
                  <div className="col-sm-9  col-12 ">
                    <input
                      type={values.showPassword === false ? "password" : "text"}
                      id="password"
                      name="password"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("password", e.target.value);
                      }}
                      className={
                        " form-control " + (touched.password ? (errors.password ? " is-invalid" : " is-valid") : " ")
                      }
                    />
                    <ErrorMessage component="div" name="password" className="invalid-feedback" />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="confirmPassword" className="col-sm-3  col-12  col-form-label">
                    ยืนยันรหัสผ่าน
                  </label>
                  <div className="col-sm-9  col-12 ">
                    <input
                      type={values.showPassword === false ? "password" : "text"}
                      className={
                        "form-control " +
                        (touched.confirmPassword ? (errors.confirmPassword ? " is-invalid" : " is-valid") : "")
                      }
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("confirmPassword", e.target.value);
                      }}
                      id="confirmPassword"
                      name="confirmPassword"
                    />
                    <ErrorMessage component="div" name="confirmPassword" className="invalid-feedback" />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="confirmPassword" className="col-sm-3  col-12  col-form-label"></label>
                  <div className="col-sm-9  col-12 ">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="showPassword"
                        onChange={(e) => {
                          if (values.showPassword === false) {
                            setFieldValue("showPassword", true)
                          } else {
                            setFieldValue("showPassword", false)
                          }
                        }}
                      />
                      <label className="form-check-label" for="showPassword">
                        แสดงรหัสผ่าน
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="text-center">
                    <button type="submit" className="btn btn-success">
                      ยืนยัน
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => ({
  name: state.Authentication.fullname,
  username: state.Authentication.username,
  roleType: state.Authentication.roleType,
});

export default connect(mapStateToProps)(Profile);
