import * as Yup from "yup";


const regex = /^[a-zA-Z0-9]+$/;
const getHubUserSchema = (update) => {
    console.log({
        update,
    });
    return Yup.object().shape({
        username: Yup.string().required("ช่องนี้ต้องไม่เว้นว่าง"),
        password: Yup.string().when('username', (username,schema) => {
            if(!update){
                return schema.required('ต้องใช้รหัสผ่าน').matches(regex, "รหัสผ่านไม่ตรงกัน").test('len','ความยาวต้องมีอย่างน้อย 6', (value) => ((value || '').length >= 6));
            }
            else {
                return schema
                    .test('value','"รหัสผ่านไม่ตรงกัน"', (value) => {
                        return !value || regex.test(value)
                    }).test('len','ความยาวต้องมีอย่างน้อย 6', (value) => !value || (value || "").length >= 6);
            }
        }),
        confirmPassword: Yup.string().when('username', (username,schema) => {
            if(!update){
                return schema.required('ต้องใช้รหัสผ่าน').oneOf([Yup.ref('password')], 'การยืนยันรหัสผ่านควรเหมือนกับรหัสผ่าน');
            }
            else {
                return schema.oneOf([Yup.ref('password')], 'การยืนยันรหัสผ่านควรเหมือนกับรหัสผ่าน');
            }
        }),
        name: Yup.string().required("ช่องนี้ต้องไม่เว้นว่าง"),
    });
}

export default getHubUserSchema;