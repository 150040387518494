import * as Yup from "yup";
// import { checkCitizen } from "../../../Helper/shareFunction";

const parcelSchema = Yup.object().shape({
  sender: Yup.object().shape({
    citizenID: Yup.string()
        .required("กรุณากรอก เลขประจำตัวประชาชน")
        // .min(13, 'โปรดตรวจสอบหมายเลขบัตรประจำตัวประชาชนของคุณอีกครั้ง\n' +
        //     'ความยาวควรต่ำกว่า13')
        // .max(13, 'โปรดตรวจสอบหมายเลขบัตรประจำตัวประชาชนของคุณอีกครั้ง\n' +
        //     'ความยาวควรต่ำกว่า13'),
        .max(13, 'กรุณาตรวจสอบ เลขประจำตัวประชาชน อีกครั้ง'),
    custCode: Yup.string()
        .required("กรุณากรอกรหัสประจำตัว"),    
    fullName: Yup.string()
      .required("กรุณากรอก ชื่อ-นามสกุล")
      .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
    phoneNumber: Yup.string().when("customerGroup", (value,schema) => {
        if(localStorage.getItem('superkey') === 'credit'){
            return schema.notRequired();
        }
        else{
            return schema
                .required("กรุณากรอก เบอร์โทรศัพท์")
                .matches(/^(?=[0-9]*$)(?:.{4,10}|.{30})$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง");
        }
    }),
    return_doc: Yup.boolean(),
    num_return_doc: Yup.string().nullable(true).when("return_doc", (return_doc,schema) => {
        if(return_doc){
            return schema
                .required("ฟิลด์นี้จำเป็นต้องระบุ")
                .max(3,"ขนาดของฟิลด์นี้ต้องน้อยกว่าหรือเท่ากับ3")
                .matches(/^[0-9]+$/, "ช่องนี้ควรเป็นตัวเลขที่ถูกต้อง");
        }
        else {
            schema.notRequired();
        }
    }),
    materialAccountName: Yup.string().nullable(true),
    materialAccountNumber: Yup.string().nullable(true),
    bankName: Yup.string().nullable(true),
  }),
  recipient: Yup.array().of(
    Yup.object().shape({
      fullName: Yup.string()
        .required("กรุณากรอก ชื่อ-นามสกุล")
        .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      phoneNumber: Yup.string()
        .required("กรุณากรอก เบอร์โทรศัพท์ ")
        .matches(/^(?=[0-9]*$)(?:.{9}|.{10}|.{30})$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง"),
      homeNumber: Yup.string()
        .required("กรุณากรอก เลขที่อยู่"),
        // .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "กรุณาตรวจสอบ เลขที่อยู่ อีกครั้ง"),
      ref_no: Yup.string()
        .required("จำเป็นต้องมีเลขลาเบล"),

      deposit_type: Yup.string().required("กรุณาระบุ ประเภทการส่ง"),
      deposit_fullname: Yup.string().when("deposit_type", (value, schema) => {
        if (value === "2" || value === "3") {
          return schema
            .required("กรุณากรอก ชื่อ-นามสกุล").max(20, "กรุณากรอกตัวอักษรน้อยกว่า 20 ตัว")
            .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ");
        } else {
          return schema.notRequired();
        }
      }),
      // .required("กรุณากรอก ชื่อ-นามสกุล")
      // .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, "ไม่อนุญาตให้ใช้อักขระพิเศษ"),
      // deposit_phone: Yup.string().when("deposit_type", (value, schema) => {
      //   if (value === "2" || value === "3") {
      //     return schema.required("กรุณากรอก เบอร์โทรศัพท์ ").matches(/^[0-9]{9,10}$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง");
      //   } else {
      //     return schema.notRequired();
      //   }
      // }),
      // .required("กรุณากรอก เบอร์โทรศัพท์ ")
      // .matches(/^[0-9]{9,10}$/, "กรุณาตรวจสอบ เบอร์โทรศัพท์ อีกครั้ง"),

      // district: Yup.string().required("กรุณากรอก ตำบล "),
      amphoe: Yup.string().required("กรุณากรอก อำเภอ "),
      province: Yup.string().required("กรุณากรอก จังหวัด "),
      zipcode: Yup.string().required("กรุณากรอก รหัสไปรษณีย์ "),
      // materialWeight: Yup.number()
      //   .required("กรุณากรอก น้ำหนัก ")
      //   .min(0, "กรุณากรอก น้ำหนัก >= 0"),
      materialWeight: Yup.number().when('zipcode', (value,schema) =>{
        if(localStorage.getItem('superkey') === 'credit' && localStorage.getItem('show_shipment_cost') === '0' 
        && localStorage.getItem('requireDimension') === '0' ){
          return schema.notRequired();
        }
        else{
            return schema.required("กรุณากรอก น้ำหนัก")
        }
      })
        .when('zipcode', (value ,schema) => {
        if(localStorage.getItem('superkey') !== 'credit'){
            return schema.min(0.1, "กรุณากรอก น้ำหนักมากกว่า หรือเท่ากับ 0.1")
        }})
        .when('zipcode', (value ,schema) => {
          if(localStorage.getItem('superkey') !== 'credit'){
              return schema.max(30, "ขออภัย พัสดุฯมีน้ำหนักเกิน30กิโลกรัม")
          }}),
      materialSizeWide: Yup.number().when('zipcode', (value,schema) =>{
        if(localStorage.getItem('superkey') === 'credit' && localStorage.getItem('show_shipment_cost') === '0' 
        && localStorage.getItem('requireDimension') === '0' ){
          return schema.notRequired();
        }
        else{
            return schema.required("กรุณากรอก ขนาดความกว้าง ")
        }
      })
      
      .min(1, "กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1")
      .when('zipcode', (value ,schema) => {
        if(localStorage.getItem('superkey') === 'shop'){
            return schema.notRequired();
        }
      }),
      materialSizeLong: Yup.number().when('zipcode', (value,schema) =>{
            if(localStorage.getItem('superkey') === 'credit' && localStorage.getItem('show_shipment_cost') === '0' 
            && localStorage.getItem('requireDimension') === '0' ){
              return schema.notRequired();
            }
            else{
                return schema.required("กรุณากรอก ขนาดความยาว")
            }
          })
          .min(1, "กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1")
          .when('zipcode', (value ,schema) => {
            if(localStorage.getItem('superkey') === 'shop'){
                return schema.notRequired();
            }
          }),
      materialSizeHigh: Yup.number().when('zipcode', (value,schema) =>{
        if(localStorage.getItem('superkey') === 'credit' && localStorage.getItem('show_shipment_cost') === '0' 
        && localStorage.getItem('requireDimension') === '0' ){
          return schema.notRequired();
        }
        else{
            return schema.required("กรุณากรอก ขนาดความสูง ")
        }
      })
      .min(1, "กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1")
      .when('zipcode', (value ,schema) => {
        if(localStorage.getItem('superkey') === 'shop'){
            return schema.notRequired();
        }
      }),
      materialSize: Yup.number().required("กรุณาใส่ขนาด")
      .when('zipcode', (value ,schema) => {
          if((localStorage.getItem('superkey') === 'credit' && localStorage.getItem('show_shipment_cost') === '0') || 
          (localStorage.getItem('superkey') === 'credit' && localStorage.getItem('requireDimension') === '0')){
              return schema.notRequired();
          }
          else{
              return schema.max(210,"ขออภัย ขนาดพัสดุฯเกิน210ซม")
          }
      }),
      //   .required("กรุณากรอก ขนาด ")
      //   .min(1, "กรุณากรอก ขนาดมากกว่า หรือเท่ากับ 1"),

      materialAccountName: Yup.string().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณากรอก ชื่อบัญชี ");
        } else {
          return schema.notRequired();
        }
      }),
      materialAccountNumber: Yup.string().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณากรอก เลขที่บัญชี ").matches(/^\d{9,18}$/, "กรุณาตรวจสอบ เลขที่บัญชี");
        } else {
          return schema.notRequired();
        }
      }),
      materialPriceCode: Yup.number().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณากรอก จำนวนเงิน COD ").min(1, "กรุณาระบุ จำนวนเงิน COD มากกว่า หรือเท่ากับ 1");
        } else {
          return schema.notRequired();
        }
      }),
      bankName: Yup.string().when("materialCode", (value, schema) => {
        if (value) {
          return schema.required("กรุณาระบุบ ธนาคาร");
        } else {
          return schema.notRequired();
        }
      }),
      // total: Yup.string().required("กรุณาระบุ จำนวนเงิน"),
    })
  ),
});
export default parcelSchema;
