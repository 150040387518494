import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { GetHistoryUploadOP, RemoveFileUploadOP,InsertValueToDBOP } from "../../../../Service/service.PickupParcel";
import Pagination from "@material-ui/lab/Pagination";
import { swalSuccess, swalError } from "../../../../Components/sweetAlert";

export default function ShowData(props) {
  const [schemaData, setSchemaData] = useState({
    data: [],
    loading: false,
  });
  const [pagin, setPagin] = useState({
    pageSize: "10",
    currentPage: "1",
    totalPage: "1",
    totalRow: "1",
  });

  useEffect(() => {
    LoadData(props.filter, pagin.currentPage, props.pageSize);
  }, [props.enterSh, props.pageSize, props.filter]);

  async function LoadData(filter, pageNo, perPage) {
    setSchemaData({
      ...schemaData,
      loading: true,
    });
    let respons = await GetHistoryUploadOP({
      custCode: props.custCode,
      keyword: filter.keyword,
      pageNo,
      perPage,
      importDate: filter !== "" ? filter.importDate : "",
      importStatus: filter !==   "" ? filter.importStatus : "",
    });
    if (respons) {
      setSchemaData({
        data: respons.result,
        loading: false,
      });
      setPagin({
        ...pagin,
        totalRow: respons.numRowsAll,
        totalPage: respons.maxPage,
        currentPage: pageNo,
      });
    } else {
      setSchemaData({
        data: [],
        loading: false,
      });
    }
  }

  function handleChangePage(e, number) {
    LoadData(props.filter, number, pagin.pageSize);
  }

  async function removeFile(params) {
    let result = await RemoveFileUploadOP(params);
    if(result.status === 1 || result.status==='1'){
      swalSuccess();
    }
    else{
      swalError();
    }
    LoadData(props.filter, pagin.currentPage, props.pageSize);
  }

  async function insertDB(params) {
    let result = await InsertValueToDBOP(params);
    console.log(result)
    if(result.status === 1 || result.status==='1'){
      swalSuccess();
    }
    else{
      swalError({
        text: result.message
      });
    }
    
    LoadData(props.filter, pagin.currentPage, props.pageSize);
  }


  return (
    <>
      {schemaData.loading ? (
        <center>
          <div className="m-5">
            <div className="text-center">
              <div className="spinner-border  text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              {" ..."}
              กำลังโหลดข้อมูล
            </div>
          </div>
        </center>
      ) : (
        <>
          {schemaData.data.length === 0 ? (
            <center>
              <div className="m-5">
                <div className="text-center text-danger">ไม่พบข้อมูล</div>
              </div>
            </center>
          ) : (
            <>
              <table className="table table-bordered table-responsive-xl table-responsive-sm  table-responsive-md">
                <thead className="table-blue">
                  <tr>
                    <th width="50px">ลำดับ</th>
                    <th>ชื่อไพล์</th>
                    <th width="200px">วันที่อัพโหลดไพล์</th>
                    <th width="200px">สถานะ</th>
                    <th width="50px">จำนวน(รายการ)</th>
                    <th width="250px">จัดการ</th>
                  </tr>
                </thead>
                <tbody>
                  {schemaData.data.map((v, index) => (
                    <tr className="table-blue-tr-td " key={v.id}>
                      <td align="center">{(parseInt(pagin.currentPage) - 1) * parseInt(pagin.pageSize) + (index + 1)}</td>
                      <td>{v.filename}</td>
                      <td>{v.created_date}</td>
                      <td align="center">
                        {v.status === "1" ? (
                          <center className="text-danger">ยังไม่ได้นำเข้าฐานข้อมูล</center>
                        ) : (
                          <center className="text-success">นำเข้าฐานข้อมูลแล้ว</center>
                        )}
                      </td>
                      <td align="center">{v.num_rows.toLocaleString()}</td>
                        <td align="center">
                        {v.status === "1" ? (
                          <>
                            <button
                              className="btn btn-success mr-2"
                              type="button"
                              onClick={() => {
                                insertDB(v.id);
                              }}
                            >
                              <Icon.CloudUploadFill size="20" /> นำเข้า
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                removeFile(v.id);
                              }}
                            >
                              <Icon.TrashFill size="20" /> ยกเลิก
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                      
                      
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-between">
                <div>จำนวนทั้งหมด {pagin.totalRow.toLocaleString()} รายการ</div>
                <div>
                  <Pagination
                    count={parseInt(pagin.totalPage)}
                    page={parseInt(pagin.currentPage)}
                    onChange={handleChangePage}
                    color="primary"
                    size="small"
                    defaultPage={6}
                    siblingCount={1}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
