import {DatePicker, DateTimePicker} from '@material-ui/pickers';
import React from "react";
import {addYears, format, getYear} from "date-fns";

export const ThaiDatepicker = ({label,date, onChange, isTimePicker = false}) => {
    let timerId = null;
    const yearsToAdd = 543;
    const refreshYears = (date) => {
        timerId = setTimeout(() => {
            const rootElement = document.querySelector('.MuiPickersCalendarHeader-switchHeader');
            if(!rootElement){
                return;
            }
            const element = rootElement
                .querySelector('p');
            const text = element.textContent.split(' ');
            const year = (date ? getYear(date) : parseInt(text[1])) + yearsToAdd;
            element.textContent = `${text[0]} ${year}`;
        }, 50);
    };

    const onHandleDatePickerFocus = () => {
        refreshYears();
    };

    const onYearChange = (date) => {
        refreshYears(date);
    };

    const dateFormat = (date) => {
        if(!date){
            return "";
        }
        const newDate = addYears(date,yearsToAdd);
        return isTimePicker ?  format(newDate, 'yyyy/MM/dd HH:mm:ss')
             : format(newDate, 'yyyy/MM/dd');
    };

    return (
        <>
            {isTimePicker && (
                <DateTimePicker
                    ampm={false}
                    disableToolbar={true}
                    variant="inline"
                    margin="normal"
                    label={label}
                    value={date}
                    onOpen={() => onHandleDatePickerFocus()}
                    onClose={() => clearTimeout(timerId)}
                    onChange={onChange}
                    onYearChange={onYearChange}
                    onMonthChange={onYearChange}
                    labelFunc={dateFormat}
                />
            ) }
            {!isTimePicker && (
                <DatePicker
                    disableToolbar={true}
                    variant="inline"
                    margin="normal"
                    label={label}
                    value={date}
                    onOpen={() => onHandleDatePickerFocus()}
                    onClose={() => clearTimeout(timerId)}
                    onChange={onChange}
                    onYearChange={onYearChange}
                    onMonthChange={onYearChange}
                    labelFunc={dateFormat}
                />
            )}
        </>
    );
}