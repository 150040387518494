import React from "react";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Themes/CustomTheme.css";
import { Provider } from "react-redux";
import store from "./stores/store";
import { render } from "react-dom";
import "./global.css";
import DateFnsUtils from '@date-io/date-fns';
import reportWebVitals from "./reportWebVitals";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import { th } from "date-fns/locale";
import { translator } from "./i18n";

render(
  //<React.StrictMode>
  <Provider store={store}>
    <MuiPickersUtilsProvider  locale={th} utils={DateFnsUtils}>
        <App />
    </MuiPickersUtilsProvider>
  </Provider>,

  //</React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
