import React, { useState, useEffect } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import { career } from '../../../Data/career.json';
import { GetJobAnnouncement } from '../../../Service/service.Career.js';
import LongDateTH from '../../../utility/LongDateTH';
import noVacancies from '../../../Assets/images/career/noVacancies.jpeg';

export default function Career() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });

  const pageSizes = [10, 20, 50, 100];
  const [setErrorMassNoData, SetErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);

  const [pageNo, setCurrentPage] = useState(1);
  const [perPage, setPageSize] = useState(10);

  useEffect(() => {
    fetchData({
      pageNo: '1',
      perPage: '10',
      ano_status: '1',
      position_name: '',
    });
  }, [pageNo, perPage]);

  async function fetchData(data) {
    Loading(1);
    let res = await GetJobAnnouncement(data);
    console.log('res', res);
    if (res.numRowsAll > 0) {
      setData(res.result);
      setPage({
        current_page: res.pageNo,
        last_page: res.maxPage,
        total: res.numRowsAll,
      });
      SetErrorMassNoData(0);
      Loading(0);
    } else {
      setCurrentPage(1);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      SetErrorMassNoData(1);
      Loading(0);
    }
  }

  return (
    <>
      <br />
      <br />
      <div className="col-12">
        <div className="row">
          <div className="container">
            <div>
              <div className="container">
                <div className="text-center text-title-color">
                  <h2>ตำแหน่งที่เปิดรับสมัคร</h2>
                </div>
                <br />
                <div className={loading === 0 ? 'row' : 'hidden-ct'}>
                  {data.map((value) => (
                    <div className="container">
                      <div
                        type="button"
                        className="card-custom-apply"
                        onClick={(e) => {
                          history.push('/Apply', { value: value });
                        }}
                      >
                        <div className="card-body">
                          <h4 className="text-subtitle-career">
                            รับสมัคร{value.positionName} &nbsp;&nbsp;
                            {value.amount}&nbsp; ตำแหน่ง
                          </h4>
                          <div className="row" style={{ marginTop: '10px' }}>
                            <div class="col-sm-8 px-md-2 mt-1">
                              <h6 className="card-subtitle mb-2 text-subtitle">
                                <Icon.GeoAlt size="25px" />
                                &nbsp;สถานที่ปฏิบัติงาน :&nbsp;&nbsp;
                                {value.work_location}
                              </h6>
                            </div>
                            <div class="col-sm-4 px-md-2 mt-1">
                              <h6 className="card-subtitle mb-2 text-subtitle" align="right">
                                <Icon.CalendarEvent size="25px" />
                                &nbsp;&nbsp;วันที่ประกาศ :&nbsp;&nbsp;
                                {LongDateTH(value.ano_date)[0]}
                              </h6>
                            </div>
                          </div>
                          <hr />
                          <h5 class="card-subtitle text-title-color mt-custom">คุณสมบัติ</h5>
                          <p
                            class="card-subtitle mb-2  card-text-custom text-subtitle-property"
                            dangerouslySetInnerHTML={{
                              __html: value.qualification_description,
                            }}
                          ></p>
                          <br />
                          <div className="row">
                            <div className="col-sm">
                              <b class="card-subtitle mb-2 text-subtitle"></b>
                            </div>
                            <div className="col-sm">
                              <div align="right">
                                <button type="button" className="btn btn-primary">
                                  รายละเอียด
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  ))}
                </div>
                <center>
                  {loading === 1 ? (
                    <div className="m-5">
                      <div className="text-center">
                        <div className="spinner-border  text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        {' ...'}
                        กำลังโหลดข้อมูล
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </center>
                <div className={loading === 0 && data.length > 0 ? 'd-flex justify-content-between' : 'hidden-ct'}>
                  <div>จำนวนทั้งหมด {parseInt(page.total)} รายการ</div>
                  <div>
                    <Pagination count={parseInt(page.last_page)} page={parseInt(page.current_page)} color="primary" size="small" defaultPage={6} />
                  </div>
                </div>
                <div className={loading === 0 && data.length === 0 ? 'm-3 text-center' : 'hidden-ct'}>
                  <center>
                    <img alt="" src={noVacancies} height="500" className="d-inline-block align-top" />
                  </center>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
