import React from 'react';
import newLogoNavbar from '../../Assets/images/logo/newLogoNavbar.png';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import HubMenuRoutes from './menu/hub-menu-route.enum';
import * as Icon from "react-bootstrap-icons";
import {CHANGE_LANGUAGE} from "../../actions/authen";
import {useTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";

const HubHeader = (props) => {
    const { t } = useTranslation();
    const logout = () => {
        localStorage.clear();
        props.history.push("../mis/Login");
    } 
    const handleClick = (lang) => {
        props.CHANGE_LANGUAGE(lang);
        props.i18n.changeLanguage(lang);
    }
    return (
        <>
            <div id="containerTop" className="px-0 justify-content-end" style={{ display: 'flex', backgroundColor: 'rgb(231, 244, 255)', paddingTop: '1.5%' }}>
                <div style={{ marginRight: '5%' }}>
                    <Icon.Globe />
                    <a
                        href="#th"
                        onClick={() => {
                            handleClick('th');
                        }}
                    >
                        TH
                    </a>{' '}
                    <span>|</span>{' '}
                    <a
                        href="#en"
                        onClick={() => {
                            handleClick('en');
                        }}
                    >
                        EN
                    </a>{' '}
                </div>
            </div>
            <Navbar collapseOnSelect expand="lg" className="dg-white-aliceblue justify-content-center py-0.5" sticky="top">
                <Navbar.Brand href="/" className="mr-100 justify-content-center">
                    <img alt="" src={newLogoNavbar} width="140" className="d-inline-block align-top" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="navbar-collapse-remove-flex-grow ">
                    <Nav className="mr-auto">
                        {Object.entries(HubMenuRoutes).filter(([, data]) => Boolean(data.text)).map(([, data]) => (
                            <NavLink to={`/hub/${data.path}`} className="nav-link">
                                {data.translate ? t(data.text) : data.text}
                            </NavLink>
                        ))}
                    </Nav>
                </Navbar.Collapse>
                <Button
                variant="primary"
                name="tacking"
                className="radius"
                onClick={(e) => {
                  logout();
                }}
              >
                {t('hub.logout')}
              </Button>
            </Navbar>
        </>
    )
}

const mapStateToProps = (state) => ({
    lan: state.LanguageAction,
});

const mapDispatchToProps = (dispatch) => {
    return {
        CHANGE_LANGUAGE: (language) => dispatch(CHANGE_LANGUAGE(language)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(HubHeader));