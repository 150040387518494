import React, { useState, useEffect } from "react";
import logoNavbar from "../../Assets/images/logo/logoNavbar.png";
import { LoginService } from "../../Service/service.Authentication";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AUTHEN, USERINFO } from "../../actions/authen";

function Login(Authentication) {
  const history = useHistory();
  const [user, username] = useState("");
  const [pass, password] = useState("");
  const [userType, SetUserType] = useState("");
  const [setErrorUser, SetErrorUser] = useState("form-control");
  const [setErrorMassUser, SetErrorMassUser] = useState(0);
  const [setErrorPass, SetErrorPass] = useState("form-control");
  const [setErrorMassPass, SetErrorMassPass] = useState(0);
  const [setErrorUserType, SetErrorUserType] = useState(0);
  const [loading, Loading] = useState(0);
  //ทดสอบ
  useEffect(() => {
    document.body.style.backgroundColor = "#193b6b";
    return () => {
      document.body.style.backgroundColor = "#ffff";
    };
  }, []);

  async function Login(value) {
    Loading(1);
    const result = await LoginService(value);
    if (result.statusCode === "001") {
      Loading(0);
      document.body.style.backgroundColor = "#ffff";
      Authentication.AUTHEN(
        result.data["username"],
        result.data["role_type"],
        result.data["fullname"],
        result.data["province_code"],
        result.data["amphur_code"],
        result.data["show_shipment_cost"],
        result.data["show_address_receiver"]
      );
      Authentication.USERINFO();
      localStorage.setItem('username',result.data["username"]);
      localStorage.setItem('id',result.data["id"]);
      localStorage.setItem('role_type',result.data["role_type"]);
      localStorage.setItem('employee_number',result.data["employee_ID"]);
      localStorage.setItem('citizen_id',result.data["citizen_id"]);
      localStorage.setItem('phone',result.data["phone"]);
      localStorage.setItem('show_shipment_cost',result.data["show_shipment_cost"]);
      localStorage.setItem('show_address_receiver',result.data["show_address_receiver"]);
      localStorage.setItem('show_conote',result.data["show_conote"]?result.data["show_conote"]:"");
      localStorage.setItem('requireDimension',result.data["require_dimension"]?result.data["require_dimension"]:"1");
      localStorage.setItem('showAds',result.data["show_ads"]?result.data["show_ads"]:"0");
      localStorage.setItem('showReturn',result.data["show_return"]?result.data["show_return"]:"1");
      localStorage.setItem('showImage',result.data["show_image"]?result.data["show_image"]:"1");
      localStorage.setItem('showPickUp',result.data["show_pickup"]?result.data["show_pickup"]:"0");
      localStorage.setItem('showGenerateRefNo',result.data["show_generate_ref_no"]?result.data["show_generate_ref_no"]:"0");
      localStorage.setItem('bank_id',result.data["bank_id"]?result.data["bank_id"]:"");
      localStorage.setItem('bookbank_code',result.data["bookbank_code"]?result.data["bookbank_code"]:"");
      localStorage.setItem('bookbank_name',result.data["bookbank_name"]?result.data["bookbank_name"]:"");
      
      if (result.data["role_type"] === "5") {
        history.push("/mis/StockIn");
      } else if (result.data["role_type"] === "2" || result.data["role_type"] === "3") {
        if(result.data["role_type"] === "2"){
          localStorage.setItem("superkey","credit");
        }
        else if(result.data["role_type"] === "3"){
          localStorage.setItem("superkey","shop");
        }
        else{
          localStorage.removeItem("superkey");
        }
        history.push("/mis/Parcel");
      } else if (result.data["role_type"] === "21"){
        localStorage.removeItem("superkey");
        history.push("/mis/manageCOD");
      }else if(result.data["role_type"] === "22"){
        localStorage.setItem("superkey","service");
        history.push("/mis/MGBarcodes");
      }else if(result.data["role_type"] === "13"){
        localStorage.setItem("superkey","staff");
        history.push("/mis/MGBarcodes");
      }
      else if(result.data["role_type"] === "1"){
        localStorage.setItem("superkey","admin");
        history.push("/mis/MGBarcodes");
      }
      else if(result.data["role_type"] === "23"){
        localStorage.setItem("superkey","operator");
        history.push("/mis/MGBarcodes");
      }
      else if(result.data["role_type"] === "24"){
        localStorage.setItem("superkey","conoteCollector");
        history.push("/mis/MGConote");
      }
      else if(result.data["role_type"] === "hub"){
        localStorage.setItem("superkey","hub");
        history.push('/hub/General');
      }
      else {
        localStorage.removeItem("superkey");
        history.push("/mis/MGBarcodes");
      }
    } else {
      Loading(0);
      swal({
        title: "เข้าสู่ระบบไม่สำเร็จ",
        text: "ชื่อผู้ใช้ หรือรหัสผ่านไม่ถูกต้อง",
        icon: "warning",
        button: "ปิด",
        //timer: 1500,
      });
    }
  }

  const handleSubmit = () => {
    SetErrorPass("form-control");
    SetErrorMassPass(0);
    SetErrorUser("form-control");
    SetErrorMassUser(0);
    if (user !== "" && pass !== "" && userType !== "") {
      Login({
        username: user !== "" ? user : "",
        password: pass !== "" ? pass : "",
        userType: userType,
      });
    }
    if (pass === "") {
      SetErrorPass("form-control is-invalid");
      SetErrorMassPass(1);
    }
    if (user === "") {
      SetErrorUser("form-control is-invalid");
      SetErrorMassUser(1);
    }

    if (userType === "") {
      SetErrorUserType(1);
    }
  };

  return (
    <div className="dg-login">
      <div className="container ">
        <div className="row " align="center">
          <div className="container">
            <div className="col-sm-6 px-md-1 mt-1">
              <div
                className="card"
                style={{
                  borderTop: "4px solid #ff9900",
                  boxShadow: "0 4px 8px 0 #131212",
                  transition: "0.3s",
                }}
                align="left"
              >
                <div className="card-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="row justify-content-md-center">
                      <div className="col-sm-9 px-md-1 mt-1">
                        <div align="center">
                          <img alt="" src={logoNavbar} width="140" className="d-inline-block align-top" />
                        </div>
                        {/* <h3>เข้าสู่ระบบ</h3> */}
                        <br />
                        <div className="form-group">
                          <label htmlFor="usernamePolor">ชื่อผู้ใช้</label>
                          <input
                            type="text"
                            name="usernamePolor"
                            id="usernamePolor"
                            onChange={(e) => username(e.target.value)}
                            className={setErrorUser}
                          />
                          {setErrorMassUser === 1 ? (
                            <font color="red" size="2">
                              {" "}
                              กรุณากรอกชื่อผู้ใช้{" "}
                            </font>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="passwordPolor">รหัสผ่าน</label>
                          <input
                            type="password"
                            onChange={(e) => password(e.target.value)}
                            className={setErrorPass}
                            id="passwordPolor"
                            name="passwordPolor"
                          />
                          {setErrorMassPass === 1 ? (
                            <font color="red" size="2">
                              {" "}
                              กรุณากรอกรหัสผ่าน{" "}
                            </font>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="userType">ประเภทผู้ใช้งาน</label>
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="userType"
                                  id="userType1"
                                  value="3"
                                  onChange={(e) => {
                                    SetUserType(e.target.value);
                                  }}
                                />
                                <label className="form-check-label" htmlFor="userType1">
                                  หน้าร้าน
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="userType"
                                  id="userType2"
                                  value="2"
                                  onChange={(e) => {
                                    SetUserType(e.target.value);
                                  }}
                                />
                                <label className="form-check-label" htmlFor="userType2">
                                  ลูกค้าเครดิต
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="userType"
                                  id="userType3"
                                  value="1"
                                  onChange={(e) => {
                                    SetUserType(e.target.value);
                                  }}
                                />
                                <label className="form-check-label" htmlFor="userType3">
                                  สำนักงานใหญ่
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="userType"
                                    id="userType6"
                                    value="6"
                                    onChange={(e) => {
                                      SetUserType(e.target.value);
                                    }}
                                />
                                <label className="form-check-label" htmlFor="userType3">
                                ศูนย์กระจายสินค้า
                                </label>
                              </div>
                            </div>
                          </div>
                          {setErrorUserType === 1 ? (
                            <font color="red" size="2">
                              {" "}
                              กรุณาระบุ ประเภทผู้ใช้งาน{" "}
                            </font>
                          ) : (
                            ""
                          )}
                        </div>
                        <button
                          disabled={loading === 1 ? true : false}
                          type="submit"
                          className={
                            loading === 1 ? "btn btn-secondary btn-lg btn-block" : "btn btn-primary btn-lg btn-block"
                          }
                        >
                          {loading === 1 ? (
                            <center>
                              <div className="spinner-border" role="status"></div>
                            </center>
                          ) : (
                            "เข้าสู่ระบบ"
                          )}
                        </button>
                        <br />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => {
  return {
    AUTHEN: (username,
             roleType,
             fullname,
             province_code,
             amphur_code,
             show_shipment_cost,
             show_address_receiver) => {
      dispatch(AUTHEN(username,
          roleType,
          fullname,
          province_code,
          amphur_code,
          show_shipment_cost,
          show_address_receiver));
    },
    USERINFO: () => dispatch(USERINFO()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
