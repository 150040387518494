import * as Icon from "react-bootstrap-icons";
import React from "react";

export const MgImageSelection = ({images, onRemove, onImageAdd}) => {
    return (
        <div className="row" style={{gap: "3px"}}>
            <div className="col-md-3">
                <div style={styles.filePicker} onClick={(e) => onImageAdd()}
                     className={`d-flex justify-content-center align-items-center`}>
                        : <Icon.CloudUploadFill size={64} color="white" />
                </div>
            </div>
            {images.map((image,index) => (
                <div key={index} className="col-md-3">
                    <div style={styles.filePicker} onClick={(e) => onRemove(index)}>
                        <div style={{...styles.image, backgroundImage: `url(${image})`}} />
                        <Icon.Trash style={styles.deleteIcon} />
                    </div>
                </div>
            ))}
        </div>
    );
}

const styles = {
    filePicker: {
        height: '16rem',
        maxHeight: '16rem',
        borderRadius: '2rem',
        backgroundColor: 'rgb(235, 244, 255)',
        cursor: "pointer",
        transition: "all .3s ease-in-out",
        position: 'relative',
        marginBottom: "15px",
    },
    image: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: "100%",
        height: "100%",
        backgroundSize: 'contain',
        margin: "5px",
    },
    deleteIcon: {
        position: "absolute",
        right: "10px",
        bottom: "10px",
        color: "red"
    }
}