import img1 from "../../../Assets/images/about/clients/1.png";
import img2 from "../../../Assets/images/about/clients/2.png";
import img3 from "../../../Assets/images/about/clients/3.png";
import img4 from "../../../Assets/images/about/clients/4.png";
import img5 from "../../../Assets/images/about/clients/5.png";
import img6 from "../../../Assets/images/about/clients/6.png";
import img7 from "../../../Assets/images/about/clients/7.png";
import img8 from "../../../Assets/images/about/clients/8.png";
import img9 from "../../../Assets/images/about/clients/9.png";
import img10 from "../../../Assets/images/about/clients/10.png";
import img11 from "../../../Assets/images/about/clients/11.png";
import img12 from "../../../Assets/images/about/clients/12.png";
import img13 from "../../../Assets/images/about/clients/13.png";
import img14 from "../../../Assets/images/about/clients/14.png";
import img15 from "../../../Assets/images/about/clients/15.png";
import img16 from "../../../Assets/images/about/clients/16.png";
import img17 from "../../../Assets/images/about/clients/17.png";
import img18 from "../../../Assets/images/about/clients/18.png";
import img19 from "../../../Assets/images/about/clients/19.png";
import img20 from "../../../Assets/images/about/clients/20.png";
import img21 from "../../../Assets/images/about/clients/21.png";
import img22 from "../../../Assets/images/about/clients/22.png";
import img23 from "../../../Assets/images/about/clients/23.png";
import img24 from "../../../Assets/images/about/clients/24.png";

const images = [
  { id: 1, src: img1, title: "clients", description: "" },
  { id: 2, src: img2, title: "clients", description: "" },
  { id: 3, src: img3, title: "clients", description: "" },
  { id: 4, src: img4, title: "clients", description: "" },
  { id: 5, src: img5, title: "clients", description: "" },
  { id: 6, src: img6, title: "clients", description: "" },
  { id: 7, src: img7, title: "clients", description: "" },
  { id: 8, src: img8, title: "clients", description: "" },
  { id: 9, src: img9, title: "clients", description: "" },
  { id: 10, src: img10, title: "clients", description: "" },
  { id: 11, src: img11, title: "clients", description: "" },
  { id: 12, src: img12, title: "clients", description: "" },
  { id: 13, src: img13, title: "clients", description: "" },
  { id: 14, src: img14, title: "clients", description: "" },
  { id: 15, src: img15, title: "clients", description: "" },
  { id: 16, src: img16, title: "clients", description: "" },
  { id: 17, src: img17, title: "clients", description: "" },
  { id: 18, src: img18, title: "clients", description: "" },
  { id: 19, src: img19, title: "clients", description: "" },
  { id: 20, src: img20, title: "clients", description: "" },
  { id: 21, src: img21, title: "clients", description: "" },
  { id: 22, src: img22, title: "clients", description: "" },
  { id: 23, src: img23, title: "clients", description: "" },
  { id: 24, src: img24, title: "clients", description: "" },
];

export default images;
