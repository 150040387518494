import React, { Component } from "react";

export default class DateTH extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var day = this.props.date.substring(8, 10);
    var month = this.props.date.substring(5, 7);
    var year = this.props.date.substring(0, 4);
    var time = this.props.date.substring(11, 16);
    var month_name = "";
    if (month === "01") {
      month_name = "มกราคม";
    } else if (month === "02") {
      month_name = "กุมภาพันธ์";
    } else if (month === "03") {
      month_name = "มีนาคม";
    } else if (month === "04") {
      month_name = "เมษายน";
    } else if (month === "05") {
      month_name = "พฤษภาคม";
    } else if (month === "06") {
      month_name = "มิถุนายน";
    } else if (month === "07") {
      month_name = "กรกฎาคม";
    } else if (month === "08") {
      month_name = "สิงหาคม";
    } else if (month === "09") {
      month_name = "กันยายน";
    } else if (month === "10") {
      month_name = "ตุลาคม";
    } else if (month === "11") {
      month_name = "พฤศจิกายน";
    } else if (month === "12") {
      month_name = "ธันวาคม";
    }
    return (
      <span style={{ color: "#000" }}>
        {day} {month_name} {parseInt(year) + 543}
        {/* บรรทัดที่ 44 ต้องการทำอะไร
        this.props.br ? <br /> : " " 
        
        */}
        <br />
        {time != "" ? "เวลา " + time + " น." : ""}
      </span>
    );
  }
}
