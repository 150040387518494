import React, { useState } from 'react';
import PagSize from '../../../../Data/pagSize.json';
import * as Icon from 'react-bootstrap-icons';
import ShowData from './showData';
import { UploadFileOP } from '../../../../Service/service.PickupParcel';
import { connect } from 'react-redux';
import { swalSuccess, swalError } from '../../../../Components/sweetAlert';
import ReactDOMServer from 'react-dom/server';
import IMPORT_DATA_OP from '../../../../Assets/excel/IMPORT_DATA_OP.xlsx';
import IMPORT_DATA_SHOP from '../../../../Assets/excel/IMPORT_DATA_SHOP.xlsx';
import IMPORT_DATA_PUBLIC from '../../../../Assets/excel/IMPORT_DATA_PUBLIC.xlsx';
import { nanoid } from 'nanoid';
import { monthTH } from '../../../../Data/month-th.json';
import { Formik, Form, ErrorMessage, getIn } from 'formik';
import filterSchema from './validate';

function ImportExcelOP(props) {
  let { user } = props;
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = ('0' + (new Date().getMonth() + 1)).slice(-2);
  var date = ('0' + new Date().getDate()).slice(-2);
  const [pageSize, setPageSize] = useState('10');
  const [filter, setFilter] = useState({
    year: '',
    month: '',
    day: '',
    keyword: '',
    importStatus: '',
    importDate: '',
  });
  const [enterSh, setEnterSh] = useState(nanoid());

  async function headleUpload(e) {
    let size = e.target.files[0].size;
    document.getElementById('btn-up').innerHTML = ' <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> กำลังอัพโหลดข้อมูล...';
    if (Math.round(size) / 1024 / 1024 <= 50) {
      let result = await UploadFileOP({
        custCode: localStorage.getItem('superkey') == '1947' || localStorage.getItem('superkey') == '1947' ? localStorage.getItem('username') : user.username,
        file: e.target.files[0],
      });
      console.log(result);
      if (result.status === 1) {
        swalSuccess();
        //อัพโหลดข้อมูล
      } else {
        swalError({ text: 'ไพล์ ' + e.target.files[0].name + ' มีอยู่ในระบบแล้ว' });
      }
    } else {
      swalError({ text: 'ขนาดไพล์ขนาดเกิน 50 MB' });
    }
    setEnterSh(nanoid());
    document.getElementById('upfile').value = null;
    document.getElementById('btn-up').innerHTML = ReactDOMServer.renderToString(<Icon.CloudUploadFill className="mr-2" size="15" />) + 'อัพโหลดข้อมูล';
  }

  function handlePageSizeChange(params) {
    setPageSize(params);
  }

  return (
    <Formik
      validationSchema={filterSchema}
      initialValues={{
        year: '',
        month: '',
        day: '',
        keyword: '',
        importStatus: '',
      }}
      onSubmit={(value) => {
        setFilter({
          ...value,
          importDate: value.year !== '' ? value.year + '-' + value.month + '-' + value.day : '',
        });
        //   handleSubmit();
      }}
    >
      {({ errors, touched, handleBlur, setFieldValue, values }) => (
        <Form>
          <div className="mb-5">
            <div className="row">
              <div className="col-12  col-md-12">
                <div className="card-sender">
                  <div className="text-center text-title-color mb-4">
                    <h2>อัพโหลดข้อมูลพัสดุ</h2>
                  </div>
                  <div className=" displayFlex  justifyContentEnd">
                    <div className="form-group">
                      <a className="btn btn-info mr-2" href={IMPORT_DATA_OP} download="IMPORT_DATA_OP.xlsx">
                        <Icon.CloudDownloadFill className="mr-2" size="15" />
                        ดาวน์โหลดเทมเพลด
                      </a>
                      <button
                        className="btn btn-success"
                        type="button"
                        id="btn-up"
                        onClick={() => {
                          document.getElementById('upfile').click();
                          //UploadFile();
                        }}
                      >
                        <Icon.CloudUploadFill className="mr-2" size="15" />
                        อัพโหลดข้อมูล
                      </button>
                      <input
                        type="file"
                        name="upfile"
                        id="upfile"
                        hidden
                        onChange={(e) => {
                          headleUpload(e);
                        }}
                        accept=".xlsx"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-11  ">
                      <div className="row">
                        <div className="col-12 col-md-2 pr-1">
                          <div className="form-group">
                            <input
                              onBlur={handleBlur}
                              type="text"
                              className={'form-control   ' + (getIn(touched, 'keyword') ? (getIn(errors, 'keyword') ? 'is-invalid' : 'is-valid') : '') + ''}
                              placeholder="ค้นหา ชื่อไพล์"
                              name="keyword"
                              id="keyword"
                              onChange={(e) => {
                                setFieldValue('keyword', e.target.value);
                              }}
                              value={values.keyword}
                            />
                            <ErrorMessage component="div" name="keyword" className="invalid-feedback" />
                          </div>
                        </div>
                        <div className="col-12  col-md-2 px-md-1 ">
                          <div className="form-group">
                            <select
                              name="importStatus"
                              id="importStatus"
                              className="form-control"
                              onBlur={handleBlur}
                              // className="form-control "
                              onChange={(e) => {
                                setFieldValue('importStatus', e.target.value);
                              }}
                            >
                              <option value="">ทั้งหมด</option>
                              <option value="1">ยังไม่ได้นำเข้าฐานข้อมูล</option>
                              <option value="2">นำเข้าฐานข้อมูลแล้ว</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12  col-md-2  px-md-1">
                          <div className="form-group">
                            <select
                              className={'form-control    dropdown-custom2   ' + (getIn(touched, 'year') ? (getIn(errors, 'year') ? 'is-invalid' : 'is-valid') : '') + ''}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('year', e.target.value);
                              }}
                              name="year"
                              id="year"
                              value={values.year}
                            >
                              <option value={''} key={nanoid()}>
                                {' '}
                                -- กรุณาระบุ ปี --{' '}
                              </option>
                              {years.map((year, index) => (
                                <option key={nanoid()} value={year}>
                                  {year + 543}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage component="div" name="year" className="invalid-feedback" />
                          </div>
                        </div>
                        <div className="col-12  col-md-2  px-md-1">
                          <div className="form-group">
                            <select
                              className={'form-control    dropdown-custom2   ' + (getIn(touched, 'month') ? (getIn(errors, 'month') ? 'is-invalid' : 'is-valid') : '') + ''}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('month', e.target.value);
                              }}
                              name="month"
                              value={values.month}
                            >
                              <option value={''} key={nanoid()}>
                                {' '}
                                -- กรุณาระบุ เดือน --{' '}
                              </option>
                              {monthTH.map((item, index) => (
                                <option value={item.id} key={nanoid()}>
                                  {item.monthName}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage component="div" name="month" className="invalid-feedback" />
                          </div>
                        </div>
                        <div className="col-12  col-md-2  px-md-1">
                          <div className="form-group">
                            <select
                              className={'form-control    dropdown-custom2   ' + (getIn(touched, 'day') ? (getIn(errors, 'day') ? 'is-invalid' : 'is-valid') : '') + ''}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('day', e.target.value);
                              }}
                              name="day"
                              value={values.day}
                            >
                              <option value={''}> -- กรุณาระบุ วัน -- </option>
                              {Array.from(Array(31), (e, i) => (
                                <option value={(i < 9 ? '0' : '') + (i + 1)} key={nanoid()}>
                                  {(i < 9 ? '0' : '') + (i + 1)}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage component="div" name="day" className="invalid-feedback" />
                          </div>
                        </div>
                        <div className="col-12 col-md-1  px-md-1 text-center">
                          <div className="form-group mt-1">
                            <input
                              type="checkbox"
                              name="datenow"
                              id="datenow"
                              className="mr-1"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue('day', date);
                                  setFieldValue('month', month);
                                  setFieldValue('year', year);
                                } else {
                                  setFieldValue('day', '');
                                  setFieldValue('month', '');
                                  setFieldValue('year', '');
                                }
                              }}
                            />
                            <label htmlFor="datenow">{'  วันที่ปัจจุบัน'}</label>
                          </div>
                        </div>
                        <div className="col-12  col-md-1  px-md-1 text-center">
                          <button type="submit" className="btn btn-primary ">
                            ค้นหา
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-1 ">
                      <div className="form-group float-right  ">
                        <select
                          name="pageSize"
                          id="pageSize"
                          className="form-control"
                          value={pageSize}
                          onChange={(e) => {
                            handlePageSizeChange(e.target.value);
                          }}
                        >
                          {PagSize.map((v) => (
                            <option value={v.key.toString()} key={v.key.toString()}>
                              {v.value}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ShowData custCode={user.username} roleType={user.roleType} pageSize={pageSize} filter={filter} enterSh={enterSh} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(ImportExcelOP);
