import "./style.css";
import React, {forwardRef} from "react";
import logoLabel from "../../Assets/images/logo/logoLabel.png";
export const ConotePrintModel = forwardRef((data,ref) => {
    const Header = (
        <div className="row header">
            <div className="col-md-6" style={{border: '1px solid black'}}>
                <div className="row">
                    <div className="col-md-4" style={{padding: 0}}>
                        <img alt="" src={logoLabel} style={{width: '100%'}} className="d-inline-block align-top" />
                    </div>
                    <div className="col-md-8">
                        <div className="row header-fields"  style={{height: '100%'}}>
                            <div className="col-md-12">
                                <p>Field 1 : lorem ispum</p>
                            </div>
                            <div className="col-md-12">
                                <p>Field 2 : lorem ispum</p>
                            </div>
                            <div className="col-md-12">
                                <p>Field 3 : lorem ispum</p>
                            </div>
                            <div className="col-md-12">
                                <p>Field 4 : lorem ispum</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6" style={{padding: 0}}>
                <div className="row second-header">
                    <div  className="col-md-6 fill-fields delete-left-border delete-right-border"  style={{paddingLeft: '10px', paddingRight: '10px'}}>
                            <div className="one-field">
                                <div className="label">
                                    Field 1 :
                                </div>
                                <div className="field">

                                </div>
                            </div>
                            <div className="one-field">
                                <div className="label">
                                    Field 2 :
                                </div>
                                <div className="field">

                                </div>
                            </div>
                    </div>
                    <div className="col-md-6 code-header"  style={{height: '100%',paddingLeft: '5px',paddingRight: '5px'}}>
                            <div className="full-code">
                                <div className="label">
                                    Field 1 :
                                </div>
                                <div className="field">
                                    123456789
                                </div>
                            </div>
                            <div className="one-field">
                                <div className="label">
                                    Field 2 :
                                </div>
                                <div className="field">
                                    T-383
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
    const firstLine = (
      <div className="row">
          <div className='col-md-6 black-bordered' style={{paddingRight: 0}}>
              <div className="row">
                  <div className="col-md-10">
                      <div className="row">
                          <p className="custom-title">My Custom Title <span className="bold-title">bold-title</span></p>
                      </div>
                      <div className="row multiple-fields">
                          <div className="col-md-12">
                              <div className="one-field">
                                  <div className="label">
                                      Field 1 :
                                  </div>
                                  <div className="field">

                                  </div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="one-field">
                                  <div className="label">
                                      Field 2 :
                                  </div>
                                  <div className="field">

                                  </div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="one-field" style={{paddingBottom: '60px'}}>
                                  <div className="label" />
                                  <div className="field" style={{paddingTop: '30px'}}></div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6" style={{paddingLeft: 0}}>
                                    <div className="one-field">
                                        <div className="label">
                                            Tel :
                                        </div>
                                        <div className="field">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" style={{paddingLeft: 0, paddingRight: 0}}>
                                    <div className="one-field">
                                        <div className="label">
                                            Fax :
                                        </div>
                                        <div className="field">

                                        </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-2 black-bordered delete-right-border checkboxes">
                        <div className="custom-checkbox">
                            <div className="checkbox">

                            </div>
                            <p className="label">
                                check 1
                            </p>
                        </div>
                      <div className="custom-checkbox">
                          <div className="checkbox">

                          </div>
                          <p className="label">
                              check 1
                          </p>
                      </div>
                      <div className="custom-checkbox">
                          <div className="checkbox">

                          </div>
                          <p className="label">
                              check 1
                          </p>
                      </div>
                      <div className="custom-checkbox">
                          <div className="checkbox">

                          </div>
                          <p className="label">
                              check 1
                          </p>
                      </div>
                      <div className="custom-checkbox">
                          <div className="checkbox">

                          </div>
                          <p className="label">
                              check 1
                          </p>
                      </div>
                      <div className="custom-checkbox">
                          <div className="checkbox">

                          </div>
                          <p className="label">
                              check 1
                          </p>
                      </div>
                  </div>
              </div>
          </div>
          <div className='col-md-6 black-bordered delete-left-border'>
              <p className="custom-title">My Custom Title <span className="bold-title">bold-title</span></p>
              <div className="one-field">
                <div className="label">Field 1 : </div>
                <div className="field" />
              </div>
              <div className="one-field">
                  <div className="label">Field 2 : </div>
                  <div className="field" />
              </div>
              <div className="one-field" style={{paddingTop: '30px'}}>
                  <div className="label" />
                  <div className="field" />
              </div>
              <div className="row">
                  <div className="col-md-4">
                      <div className="one-field" style={{height: '100%'}}>
                          <div className="label" />
                          <div className="field" />
                      </div>
                  </div>
                  <div className="col-md-8">
                      <div className="one-field">
                          <div className="label">Label 1 : </div>
                          <div className="field" />
                      </div>
                  </div>
              </div>
              <div className="col-md-12 col-field">
                  <div className="row">
                      <div className="col-md-6" style={{paddingLeft: 0}}>
                          <div className="one-field col-field">
                              <div className="label">
                                  Tel :
                              </div>
                              <div className="field">
                              </div>
                          </div>
                      </div>
                      <div className="col-md-6" style={{paddingLeft: 0, paddingRight: 0}}>
                          <div className="one-field col-field">
                              <div className="label">
                                  Fax :
                              </div>
                              <div className="field">

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
    const secondLine = (
      <div className="row">
          <div className="col-md-6" style={{padding: 0, borderLeft: '1px solid black', borderBottom: '1px solid black'}}>
              <div className="row tabledivs"  style={{borderBottom: '1px solid black'}}>
                  <div className="col-md-3" >
                      <div className="custom-tab-header" style={{borderLeft: 'none'}}>
                        header1
                      </div>
                      <div className="custom-tab-body"  style={{borderLeft: 'none'}}>

                      </div>
                  </div>
                  <div className="col-md-4">
                      <div className="custom-tab-header">
                          header2

                      </div>
                      <div className="custom-tab-body">

                      </div>
                  </div>
                  <div className="col-md-5">
                      <div className="custom-tab-header" style={{borderRight: 'none'}}>
                          header3
                      </div>
                      <div className="custom-tab-body" style={{borderRight: 'none'}}>

                      </div>
                  </div>
              </div>
          </div>
          <div className="col-md-6 black-bordered" style={{padding: 0}}>
              <div className="row" style={{height: '100%'}}>
                  <div className="col-md-10 barcode-container black-bordered" style={{padding: '5px'}}>
                    <div className="barcode-top">
                        <div className="barCode">

                        </div>
                        <div className="barCode-code">
                            528952568
                        </div>
                    </div>
                    <div className="custom-title">
                        Received By
                    </div>
                    <div className="one-field">
                            <div className="label">Field :</div>
                            <div className="field"></div>
                    </div>
                    <div className="one-field">
                        <div className="label">
                            lorem ipsum dolor test1 test2
                        </div>
                    </div>
                      <div className="row">
                        <div className="col-md-6 col-field">
                            <div className="one-field col-field">
                                <div className="label">Date : </div>
                                <div className="field"></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-field">
                            <div className="one-field col-field">
                                <div className="label">Time : </div>
                                <div className="field"></div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-2 right-tab" style={{padding: 0}}>
                        <div className="tab-header">lorem <br/> lorem</div>
                        <div className="tab-header" style={{flex: 1}}>&nbsp;&nbsp;</div>
                        <div className="tab-header">lorem lorem</div>
                        <div className="tab-header">
                            <div className="custom-checkbox">
                                <div className="checkbox">

                                </div>
                                <p className="label">
                                    check 1
                                </p>
                            </div>
                            <div className="custom-checkbox">
                                <div className="checkbox">

                                </div>
                                <p className="label">
                                    check 2
                                </p>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
    );
    const thirdLine = (
      <div className="row " style={{padding: 0}}>
          <div className="col-md-6 black-bordered" style={{position: "relative", padding: 15}}>
            <div className="row">
                <div className="col-md-6 signature-zone">
                        <p>Sender's Signature: lorem</p>
                </div>
                <div className="col-md-6" >
                    <div className="one-field">
                        <div className="label">
                            Date :
                        </div>
                        <div className="field">

                        </div>
                    </div>
                    <div className="one-field">
                        <div className="label">
                            Time :
                        </div>
                        <div className="field">

                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div className="col-md-6" style={{padding: 0}}>
              <div className="row">
                  <div className="col-md-10">
                      <div className="row" style={{position: "relative", padding: 15}}>
                          <div className="col-md-6 signature-zone">
                              <p>Courrier's Signature: lorem</p>
                          </div>
                          <div className="col-md-6" >
                              <div className="one-field">
                                  <div className="label">
                                      Date :
                                  </div>
                                  <div className="field">

                                  </div>
                              </div>
                              <div className="one-field">
                                  <div className="label">
                                      Time :
                                  </div>
                                  <div className="field">

                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-2 black-bordered">
                    <p style={{textAlign: 'center', width: '100%'}}>Route No.</p>
                  </div>
              </div>
          </div>
      </div>
    );
    const footer =  (
      <div className="row footer" style={{position: 'relative', border: '1px solid black'}}>
          <div className="col-md-8">
              lorem
          </div>
          <div className="col-md-2">
              lorem
          </div>
          <div className="col-md-2">
              lorem
          </div>
      </div>
    );
    return (
        <div className="conote-wrapper">
            <div ref={ref} className="Conote">
                {Header}
                {firstLine}
                {secondLine}
                {thirdLine}
                {footer}
            </div>
        </div>
    )
});
