import React from "react";
import { FieldArray, ErrorMessage, getIn } from "formik";
import { nanoid } from "nanoid";
import * as Icon from "react-bootstrap-icons";

export default function Tap3({
  errors,
  touched,
  handleBlur,
  setFieldValue,
  values,
  param,
  step,
  Del,
}) {
  return (
    <>
      <div className={step === 3 ? "" : "hidden-ct"}>
        <br />
        <div align="left" className={"text-title-color"}>
          <b>การศึกษา (Education)</b>
        </div>
        <br />
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-3 px-md-2 mt-1">
                <span>ระดับการศึกษาสูงสุด</span>
                <span className="important-ct">*</span>
                <select
                  name={"education.educationLevel"}
                  className={
                    "form-control  " +
                    (getIn(touched, "education.educationLevel")
                      ? getIn(errors, "education.educationLevel")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  aria-label="Default select example"
                  value={values.education.educationLevel}
                  onChange={(e) => {
                    setFieldValue("education.educationLevel", e.target.value);
                  }}
                >
                  <option value="">-- กรุณาระบุระดับการศึกษา --</option>
                  <option value="มัธยมศึกษาตอนปลาย">มัธยมศึกษาตอนปลาย</option>
                  <option value="ปวช.">ปวช.</option>
                  <option value="ปวท./ปวส.">ปวท./ปวส.</option>
                  <option value="ปริญญาตรี">ปริญญาตรี</option>
                  <option value="สูงกว่าปริญญาตรี">สูงกว่าปริญญาตรี</option>
                </select>
                <ErrorMessage
                  component="div"
                  name={"education.educationLevel"}
                  className="invalid-feedback"
                />
              </div>
              <div className="col-sm-9 px-md-2 mt-1">
                <span>สถาบันการศึกษา</span>
                <span className="important-ct">*</span>
                <input
                  type="text"
                  name={"education.institution"}
                  className={
                    "form-control  " +
                    (getIn(touched, "education.institution")
                      ? getIn(errors, "education.institution")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  value={values.education.institution}
                  onChange={(e) => {
                    setFieldValue("education.institution", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"education.institution"}
                  className="invalid-feedback"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <p> หมายเหตุ: ระดับการศึกษาสูงสุด</p> */}
        <br />
        <div align="left" className={"text-title-color"}>
          <b>ประสบการณ์ (Experience)</b>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-sm-12 px-md-3">
            <FieldArray name="experience">
              {({ push, remove }) =>
                values.experience.map((value, index) => (
                  <div key={value.key}>
                    <span>
                      สถานที่ทำงานที่
                      {" " + (index + 1)}
                    </span>
                    <div className="card">
                      <div className="card-body">
                        <div>
                          <div className="row">
                            <div className="col-sm-8 px-md-2 mt-1">
                              <span>ชื่อสถานที่ทำงาน</span>
                              <span className="important-ct">*</span>
                              <input
                                value={values.experience[index].company}
                                type="text"
                                className={
                                  "form-control  " +
                                  (getIn(
                                    touched,
                                    `experience[${index}].company`
                                  )
                                    ? getIn(
                                        errors,
                                        `experience[${index}].company`
                                      )
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "")
                                }
                                onBlur={handleBlur}
                                name={`experience[${index}].company`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `experience[${index}].company`,
                                    e.target.value
                                  );
                                }}
                              />
                              <ErrorMessage
                                component="div"
                                name={`experience[${index}].company`}
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-sm-4 px-md-2 mt-1">
                              <span>ตำแหน่ง</span>
                              <span className="important-ct">*</span>
                              <input
                                value={values.experience[index].position}
                                type="text"
                                className={
                                  "form-control  " +
                                  (getIn(
                                    touched,
                                    `experience[${index}].position`
                                  )
                                    ? getIn(
                                        errors,
                                        `experience[${index}].position`
                                      )
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "")
                                }
                                onBlur={handleBlur}
                                name={`experience[${index}].position`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `experience[${index}].position`,
                                    e.target.value
                                  );
                                }}
                              />
                              <ErrorMessage
                                component="div"
                                name={`experience[${index}].position`}
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-sm-4 px-md-2 mt-1">
                              <span>เงินเดือน</span>
                              <span className="important-ct">*</span>
                              <input
                                value={values.experience[index].salary}
                                type="text"
                                className={
                                  "form-control  " +
                                  (getIn(touched, `experience[${index}].salary`)
                                    ? getIn(
                                        errors,
                                        `experience[${index}].salary`
                                      )
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "")
                                }
                                onBlur={handleBlur}
                                name={`experience[${index}].salary`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `experience[${index}].salary`,
                                    e.target.value
                                  );
                                }}
                              />
                              <ErrorMessage
                                component="div"
                                name={`experience[${index}].salary`}
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-sm-4 px-md-2 mt-1">
                              <span>ปีเริ่มทำงาน (พ.ศ.)</span>
                              <span className="important-ct">*</span>
                              <input
                                value={values.experience[index].start_year}
                                type="text"
                                className={
                                  "form-control  " +
                                  (getIn(
                                    touched,
                                    `experience[${index}].start_year`
                                  )
                                    ? getIn(
                                        errors,
                                        `experience[${index}].start_year`
                                      )
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "")
                                }
                                onBlur={handleBlur}
                                name={`experience[${index}].start_year`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `experience[${index}].start_year`,
                                    e.target.value
                                  );
                                }}
                              />
                              <ErrorMessage
                                component="div"
                                name={`experience[${index}].start_year`}
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-sm-4 px-md-2 mt-1">
                              <span>ปีวิ้นสุดทำงาน (พ.ศ.)</span>
                              <span className="important-ct">*</span>
                              <input
                                value={values.experience[index].end_year}
                                type="text"
                                className={
                                  "form-control  " +
                                  (getIn(
                                    touched,
                                    `experience[${index}].end_year`
                                  )
                                    ? getIn(
                                        errors,
                                        `experience[${index}].end_year`
                                      )
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "")
                                }
                                onBlur={handleBlur}
                                name={`experience[${index}].end_year`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `experience[${index}].end_year`,
                                    e.target.value
                                  );
                                }}
                              />
                              <ErrorMessage
                                component="div"
                                name={`experience[${index}].end_year`}
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-sm-6 px-md-2 mt-1">
                              <span>ลักษณะงาน</span>
                              <span className="important-ct">*</span>
                              <textarea
                                value={values.experience[index].jobDescription}
                                type="text"
                                className={
                                  "form-control  " +
                                  (getIn(
                                    touched,
                                    `experience[${index}].jobDescription`
                                  )
                                    ? getIn(
                                        errors,
                                        `experience[${index}].jobDescription`
                                      )
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "")
                                }
                                onBlur={handleBlur}
                                name={`experience[${index}].jobDescription`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `experience[${index}].jobDescription`,
                                    e.target.value
                                  );
                                }}
                                rows="4"
                                cols="50"
                              />
                              <ErrorMessage
                                component="div"
                                name={`experience[${index}].jobDescription`}
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-sm-6 px-md-2 mt-1">
                              <span>เหตุที่ออก</span>
                              <span className="important-ct">*</span>
                              <textarea
                                value={
                                  values.experience[index].reasonsOfResignation
                                }
                                type="text"
                                className={
                                  "form-control  " +
                                  (getIn(
                                    touched,
                                    `experience[${index}].reasonsOfResignation`
                                  )
                                    ? getIn(
                                        errors,
                                        `experience[${index}].reasonsOfResignation`
                                      )
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "")
                                }
                                onBlur={handleBlur}
                                name={`experience[${index}].reasonsOfResignation`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `experience[${index}].reasonsOfResignation`,
                                    e.target.value
                                  );
                                }}
                                rows="4"
                                cols="50"
                              />
                              <ErrorMessage
                                component="div"
                                name={`experience[${index}].reasonsOfResignation`}
                                className="invalid-feedback"
                              />
                            </div>
                            <div
                              className="col-sm-12 px-md-2 mt-1"
                              align="right"
                            >
                              <br />
                              <button
                                className="btn btn-success"
                                type="button"
                                onClick={() => {
                                  push({
                                    key: nanoid(),
                                    code: "",
                                    company: "",
                                    start_year: "",
                                    end_year: "",
                                    position: "",
                                    jobDescription: "",
                                    salary: "",
                                    reasonsOfResignation: "",
                                  });
                                }}
                              >
                                <Icon.PlusCircleFill />
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                  if (value.code === "") {
                                    remove(index);
                                  } else {
                                    remove(index);
                                    Del({
                                      code: value.code,
                                      table: "applicants_experience",
                                    });
                                  }
                                }}
                                disabled={values.experience.length === 1}
                              >
                                <Icon.TrashFill />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </FieldArray>
            {/* <p> หมายเหตุ: เรียงลำดับก่อน-หลัง</p> */}
          </div>
        </div>
      </div>
    </>
  );
}
