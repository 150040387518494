import Instance from "../Helper/axios";
import { URL, URLTEST, urlLocal } from "../Helper/baseURL";

export async function LoginService(data) {
  //console.log("data:" + JSON.stringify(data));
  try {
    const response = await Instance.post(URL + "ApiAuthen/login", data);
    console.log(response.data);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function PublicLoginService(data){
  try {
    const response = await Instance.post(URL + "Authentication/loginClient", data);
    console.log(response.data);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
export async function publicSignUpService(data){
  try {
    const response = await Instance.post(URL + "Authentication/signUp", data);
    console.log(response.data);
    return await response.data;
  } catch (error) {

    console.log("error", error);
  }
}
