import React, { useState } from "react";
import images from "../about/ClientsData";
import inactive from "../../../Assets/images/logo/INACTIVE.png";
import inactive2 from "../../../Assets/images/logo/INACTIVE-2.png";
import inactive3 from "../../../Assets/images/logo/INACTIVE-3.png";
import imgLine from "../../../Assets/images/home/line.png";
import safe from "../../../Assets/images/home/safe.png";
import Asset from '../../../Assets/images/home/Asset.png';
import guy from "../../../Assets/images/home/guy.png";
import Icon from "../../../Assets/icons/icons";
import * as Icon2 from 'react-bootstrap-icons';
import { connect } from "react-redux";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


function Partservice(props) {
  const { lang } = props;
  return lang.language === "th" ? <PartserviceTH /> : <PartserviceEN />;
}

const mapStateToProps = (state) => ({
  lang: state.LanguageAction,
});

export default connect(mapStateToProps)(Partservice);

function PartserviceTH() {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const [isHovering2, setIsHovering2] = useState(false);
  const handleMouseEnter2 = () => {
    setIsHovering2(true);
  };
  const handleMouseLeave2 = () => {
    setIsHovering2(false);
  };

  const [isHovering3, setIsHovering3] = useState(false);
  const handleMouseEnter3 = () => {
    setIsHovering3(true);
  };
  const handleMouseLeave3 = () => {
    setIsHovering3(false);
  };

  const [isHovering4, setIsHovering4] = useState(false);
  const handleMouseEnter4 = () => {
    setIsHovering4(true);
  };
  const handleMouseLeave4 = () => {
    setIsHovering4(false);
  };

  const [isHovering5, setIsHovering5] = useState(false);
  const handleMouseEnter5 = () => {
    setIsHovering5(true);
  };
  const handleMouseLeave5 = () => {
    setIsHovering5(false);
  };

  const [isHovering6, setIsHovering6] = useState(false);
  const handleMouseEnter6 = () => {
    setIsHovering6(true);
  };
  const handleMouseLeave6= () => {
    setIsHovering6(false);
  };

  return (
    <div>
      <br />
      <div className="col-12 text-center img-align-center">
        <h1 className="color-blue text-center ">บริการของเรา</h1>
      </div>
      <br/>
      <div className="col-12 text-center img-align-center">
        <h2 className="color-yellow text-center ">ตรงเวลา ปลอดภัย ทั่วไทย</h2>
      </div>
      <br/>
      <div className="container mb-3 ">
        <div className="row ">
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
              style={{
              width: '100%',
              height: '100%',
              backgroundColor: isHovering6 ? 'rgb(231, 244, 255)' : '',
              color: isHovering6 ? '#193b6b' : '',
              boxShadow: isHovering6 ? "1px 3px 1px #9E9E9E" : '',
              backgroundImage:  isHovering6 ? `url(${Asset})`: '',
              backgroundRepeat: 'no-repeat',
              }}
              onMouseEnter={handleMouseEnter6}
              onMouseLeave={handleMouseLeave6}
            >
              <Card.Body>
                <Icon2.Truck style={{ width: '40px', height: '40px' }}/>
                <Card.Title>การตรวจสอบพัสดุฯ</Card.Title>
                <Card.Text>
                 สามารถตรวจสอบพัสดุฯขของท่านได้อย่างReal time
                </Card.Text>
                <a href="Tracking">
                  <Icon2.ArrowBarRight 
                    style={{ width: '26px', height: '26px' }}
                  />
                </a>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: isHovering ? 'rgb(231, 244, 255)' : '',
                  color: isHovering ? '#193b6b' : '',
                  boxShadow: isHovering ? "1px 3px 1px #9E9E9E" : '',
                  backgroundImage:  isHovering ? `url(${Asset})`: '',
                  backgroundRepeat: 'no-repeat',
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Card.Body>
                  <Icon2.ClockHistory style={{ width: '40px', height: '40px' }}/>
                  <Card.Title>การบริการแบบnext dayนั้น</Card.Title>
                  <Card.Text>
                  พัสดุฯของท่านสามารถถูกส่งไปถึงปลายทางในวันรุ่งขึ้น 
                  <br/> สามารถเช็คหน้าค้าที่ให้บริการได้ดังนี้
                  </Card.Text>
                  <a href="Branch">
                    <Icon2.ArrowBarRight 
                      style={{ width: '26px', height: '26px' }}
                    />
                  </a>
                </Card.Body>
              </Card>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: isHovering2 ? 'rgb(231, 244, 255)' : '',
                color: isHovering2 ? '#193b6b' : '',
                boxShadow: isHovering2 ? "1px 3px 1px #9E9E9E" : '',
                backgroundImage:  isHovering2 ? `url(${Asset})`: '',
                backgroundRepeat: 'no-repeat',
              }}
              onMouseEnter={handleMouseEnter2}
              onMouseLeave={handleMouseLeave2}
            >
              <Card.Body>
                <Icon2.PersonCircle style={{ width: '40px', height: '40px' }}/>
                <Card.Title>การใช้งานที่เข้าถึงง่าย</Card.Title>
                <Card.Text>
                คุณสามารถสร้างพัสดุฯของตนได้ล้วงหน้าและเช็คการโอนคืนยอดเก็บเงินปลายทางและอื่นๆ 
                </Card.Text>
                <a href="login">
                  <Icon2.ArrowBarRight 
                    style={{ width: '26px', height: '26px' }}
                  />
                </a>
              </Card.Body>
            </Card>
          </div>
        {/* </div>
      </div>
      <div className="container mb-3 mt-3">
        <div className="row "> */}
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
              style={{
              width: '100%',
              height: '100%',
              backgroundColor: isHovering3 ? 'rgb(231, 244, 255)' : '',
              color: isHovering3 ? '#193b6b' : '',
              boxShadow: isHovering3 ? "1px 3px 1px #9E9E9E" : '',
              backgroundImage:  isHovering3 ? `url(${Asset})`: '',
              backgroundRepeat: 'no-repeat',
              }}
              onMouseEnter={handleMouseEnter3}
              onMouseLeave={handleMouseLeave3}
            >
              <Card.Body>
                <Icon2.FilePost style={{ width: '40px', height: '40px' }}/>
                <Card.Title>Pick up Service</Card.Title>
                <Card.Text>
                คุณสามารถติดต่อฝ่ายบริการลูกค้าและใช้บริกาเขข้ารับพัสดุฯถึงบ้านได้ฟรี
                  <br/>
                </Card.Text>
                <a href="ContactUS">
                  <Icon2.ArrowBarRight 
                    style={{ width: '26px', height: '26px' }}
                  />
                </a>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
                style={{
                width: '100%',
                height: '100%',
                backgroundColor: isHovering4 ? 'rgb(231, 244, 255)' : '',
                color: isHovering4 ? '#193b6b' : '',
                boxShadow: isHovering4 ? "1px 3px 1px #9E9E9E" : '',
                backgroundImage:  isHovering4 ? `url(${Asset})`: '',
                backgroundRepeat: 'no-repeat',
                }}
                onMouseEnter={handleMouseEnter4}
                onMouseLeave={handleMouseLeave4}
              >
                <Card.Body>
                  <Icon2.Calculator style={{ width: '40px', height: '40px' }}/>
                  <Card.Title>คำนวณค่าจัดส่งพัสดุ</Card.Title>
                  <Card.Text>
                  คุณสามารถเช็คราคาค่าขนขส่งพัสดุฯได้ใน1คล็ก
                  </Card.Text>
                  <a href="PriceEstimation">
                    <Icon2.ArrowBarRight 
                      style={{ width: '26px', height: '26px' }}
                    />
                  </a>
                </Card.Body>
              </Card>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
              style={{
              width: '100%',
              height: '100%',
              backgroundColor: isHovering5 ? 'rgb(231, 244, 255)' : '',
              color: isHovering5 ? '#193b6b' : '',
              boxShadow: isHovering5 ? "1px 3px 1px #9E9E9E" : '',
              backgroundImage:  isHovering5 ? `url(${Asset})`: '',
              backgroundRepeat: 'no-repeat',
              }}
              onMouseEnter={handleMouseEnter5}
              onMouseLeave={handleMouseLeave5}
            >
              <Card.Body>
                <Icon2.CashCoin style={{ width: '40px', height: '40px' }}/>
                <Card.Title>COD บริการเก็บเงินปลายทาง</Card.Title>
                <Card.Text>
                การบริการเก็บเงินปลายาทงเริ่มต้นที่ราคาย่อมเยา 
                </Card.Text>
                <a href="login">
                  <Icon2.ArrowBarRight 
                    style={{ width: '26px', height: '26px' }}
                  />
                </a>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <br/>
      <div className="col-12 text-center img-align-center">
          <h1 className="color-blue text-center ">เราให้ความสำคัญแก่การจัดส่งที่ปลอดภัย</h1>
      </div>
      <div className="container mb-3 mt-3 item-align-center"
      style = {{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}>
        <div className="row"
        style = {{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}>
          <div className="d-none col-lg-5 d-lg-block" >
            <img src={guy} alt="กล่องพัสดุ" width="40%" 
            style={{marginLeft:"50%"}}/>
          </div>
          <div className="d-block col-lg-7 col-md-12 col-sm-12 col-xs-12 mt-5 "
          style = {{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}>
            <Card variant="outlined" 
                style={{
                width: '60%',
                marginLeft: 'auto',
                marginRight: 'auto',
                // height: '50%',
                boxShadow: "3px 3px 3px 3px #9E9E9E",
                marginTop: "30px",
                }}
              >
                <Card.Body>
                  <Card.Text>
                  ทีมงานปอลอ เอ็กซ์เพรสให้ความสำคัญกับการบริการและคำนึงถึงการตอบรับจากผู้ใช้บริการเป็นอย่างมาก
                   จึงสามารถการันตีได้ว่าพัสดุฯของท่านจะไปถึงปลายทางอย่างปลอดภัยและตรงเวลา
                   ทั้งนี้ระบบการตรวจสอบพัสดุฯของปอลอ
                   เอ็กซ์เพรสท่านสามารถตรวจสอบพัสดุฯของท่านได้ตลอดเวลา
                  </Card.Text>
                </Card.Body>
              </Card>
             {/* <img className="img-align-center" src={safe} alt="กล่องพัสดุ" width="80%"/> */}
          </div>
        </div>
      </div>
      <br/>
      <h1 className="color-blue text-center ">ติดต่อเรา</h1>
      <div className="col-12 text-center img-align-center">
        <div>
          <h1 className="color-yellow">
            <Icon icon="telephone" size={36} color="#e68a00" />
            1423
          </h1>
          <h2 className="color-blue text-center ">ปอลอ เอ็กซ์เพรส</h2>
          <div className="text-center color-yellow">ตรงเวลา ปลอดภัย ทั่วไทย</div>
        </div>
        <div>
          <img src={imgLine} width="150px" height="150px" style={{ padding: "10px" }} />
          
          <div>
            <a href="https://lin.ee/2QNYZt2bO">
              <img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0"/>
            </a>
          </div>
        </div>
      </div>
      <h2 className="text-center color-blue mt-7">รูปแบบการบริการ</h2>
      <div className="container mb-3 mt-3">
        <div className="row ">
          <div className="col-4">
            <img className="d-block img-respon" src={inactive} alt="inactive" />
            <p className="text-center color-blue detail-ser">B2B</p>
          </div>
          <div className="col-4">
            <img className="d-block img-respon " src={inactive2} alt="inactive2" />
            <p className="text-center color-blue detail-ser">C2C</p>
          </div>
          <div className="col-4">
            <img className="d-block   img-respon" src={inactive3} alt="inactive3" />
            <p className="text-center color-blue detail-ser">B2C</p>
          </div>
        </div>
      </div>
      <br/>
    </div>
  );
}

function PartserviceEN() {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const [isHovering2, setIsHovering2] = useState(false);
  const handleMouseEnter2 = () => {
    setIsHovering2(true);
  };
  const handleMouseLeave2 = () => {
    setIsHovering2(false);
  };

  const [isHovering3, setIsHovering3] = useState(false);
  const handleMouseEnter3 = () => {
    setIsHovering3(true);
  };
  const handleMouseLeave3 = () => {
    setIsHovering3(false);
  };

  const [isHovering4, setIsHovering4] = useState(false);
  const handleMouseEnter4 = () => {
    setIsHovering4(true);
  };
  const handleMouseLeave4 = () => {
    setIsHovering4(false);
  };

  const [isHovering5, setIsHovering5] = useState(false);
  const handleMouseEnter5 = () => {
    setIsHovering5(true);
  };
  const handleMouseLeave5 = () => {
    setIsHovering5(false);
  };

  const [isHovering6, setIsHovering6] = useState(false);
  const handleMouseEnter6 = () => {
    setIsHovering6(true);
  };
  const handleMouseLeave6= () => {
    setIsHovering6(false);
  };
  

  return (
    <div>
      <br/>
      <div className="col-12 text-center img-align-center">
        <h1 className="color-blue text-center ">Offered Features</h1>
      </div>
      <br/>
      <div className="col-12 text-center img-align-center">
        <h2 className="color-yellow text-center ">Express delivery of documents and parcels.</h2>
      </div>
      <br/>

      <div className="container mb-3 mt-3">
        <div className="row ">
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
              style={{
              width: '100%',
              height: '100%',
              backgroundColor: isHovering6 ? 'rgb(231, 244, 255)' : '',
              color: isHovering6 ? '#193b6b' : '',
              boxShadow: isHovering6 ? "1px 3px 1px #9E9E9E" : '',
              backgroundImage:  isHovering6 ? `url(${Asset})`: '',
              }}
              onMouseEnter={handleMouseEnter6}
              onMouseLeave={handleMouseLeave6}
            >
              <Card.Link href="Tracking">
                <Card.Body>
                  <Icon2.Truck style={{ width: '40px', height: '40px' }}/>
                  <Card.Title>Tracking</Card.Title>
                  <Card.Text>
                    You are able to track your parcel at any given time.
                  </Card.Text>
                  <a href="Tracking">
                    <Icon2.ArrowBarRight 
                      style={{ width: '26px', height: '26px' }}
                    />
                  </a>
                </Card.Body>
              </Card.Link>
            </Card>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: isHovering ? 'rgb(231, 244, 255)' : '',
                  color: isHovering ? '#193b6b' : '',
                  boxShadow: isHovering ? "1px 3px 1px #9E9E9E" : '',
                  backgroundImage:  isHovering ? `url(${Asset})`: '',
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Card.Body>
                  <Icon2.ClockHistory style={{ width: '40px', height: '40px' }}/>
                  <Card.Title>Overnight Delivery</Card.Title>
                  <Card.Text>
                    Parcels could be delivered overnight.
                    <br/> 
                    Visit our shops for more information.
                  </Card.Text>
                  <a href="Branch">
                    <Icon2.ArrowBarRight 
                      style={{ width: '26px', height: '26px' }}
                    />
                  </a>
                </Card.Body>
              </Card>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: isHovering2 ? 'rgb(231, 244, 255)' : '',
                color: isHovering2 ? '#193b6b' : '',
                boxShadow: isHovering2 ? "1px 3px 1px #9E9E9E" : '',
                backgroundImage:  isHovering2 ? `url(${Asset})`: '',
              }}
              onMouseEnter={handleMouseEnter2}
              onMouseLeave={handleMouseLeave2}
            >
              <Card.Body>
                <Icon2.PersonCircle style={{ width: '40px', height: '40px' }}/>
                <Card.Title>Availability</Card.Title>
                <Card.Text>
                  You can sign up to create your own parcel, track your COD and so on. 
                </Card.Text>
                <a href="login">
                  <Icon2.ArrowBarRight 
                    style={{ width: '26px', height: '26px' }}
                  />
                </a>
              </Card.Body>
            </Card>
          </div>
        {/* </div>
      </div>
      
      <div className="container mb-3 mt-3">
        <div className="row justify-center"> */}
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
              style={{
              width: '100%',
              height: '100%',
              backgroundColor: isHovering3 ? 'rgb(231, 244, 255)' : '',
              color: isHovering3 ? '#193b6b' : '',
              boxShadow: isHovering3 ? "1px 3px 1px #9E9E9E" : '',
              backgroundImage:  isHovering3 ? `url(${Asset})`: '',
              }}
              onMouseEnter={handleMouseEnter3}
              onMouseLeave={handleMouseLeave3}
            >
              <Card.Body>
                <Icon2.FilePost style={{ width: '40px', height: '40px' }}/>
                <Card.Title>Pick up Service</Card.Title>
                <Card.Text>
                  Contact the customer service to request a pick up service for free.
                  <br/>
                </Card.Text>
                <a href="ContactUS">
                  <Icon2.ArrowBarRight 
                    style={{ width: '26px', height: '26px' }}
                  />
                </a>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
                style={{
                width: '100%',
                height: '100%',
                backgroundColor: isHovering4 ? 'rgb(231, 244, 255)' : '',
                color: isHovering4 ? '#193b6b' : '',
                boxShadow: isHovering4 ? "1px 3px 1px #9E9E9E" : '',
                backgroundImage:  isHovering4 ? `url(${Asset})`: '',
                }}
                onMouseEnter={handleMouseEnter4}
                onMouseLeave={handleMouseLeave4}
              >
                <Card.Body>
                  <Icon2.Calculator style={{ width: '40px', height: '40px' }}/>
                  <Card.Title>Price Estimation</Card.Title>
                  <Card.Text>
                    Determine your delivery fee with one simple click.
                  </Card.Text>
                  <a href="PriceEstimation">
                    <Icon2.ArrowBarRight 
                      style={{ width: '26px', height: '26px' }}
                    />
                  </a>
                </Card.Body>
              </Card>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 text-center mt-3">
            <Card variant="outlined"
              style={{
              width: '100%',
              height: '100%',
              backgroundColor: isHovering5 ? 'rgb(231, 244, 255)' : '',
              color: isHovering5 ? '#193b6b' : '',
              boxShadow: isHovering5 ? "1px 3px 1px #9E9E9E" : '',
              backgroundImage:  isHovering5 ? `url(${Asset})`: '',
              }}
              onMouseEnter={handleMouseEnter5}
              onMouseLeave={handleMouseLeave5}
            >
              <Card.Body>
                <Icon2.CashCoin style={{ width: '40px', height: '40px' }}/>
                <Card.Title>COD</Card.Title>
                <Card.Text>
                  Cash On Delivery service is offered at the lowest rate for our customers. 
                </Card.Text>
                <a href="login">
                  <Icon2.ArrowBarRight 
                    style={{ width: '26px', height: '26px' }}
                  />
                </a>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <br />
      <div className="col-12 text-center img-align-center">
          <h1 className="color-blue text-center ">Safe Delivery Is Our Priority</h1>
      </div>
      <div className="container mb-3 mt-3 item-align-center"
      style = {{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}>
        <div className="row"
        style = {{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}>
          <div className="d-none col-lg-5 d-lg-block" >
            <img src={guy} alt="กล่องพัสดุ" width="40%" 
            style={{marginLeft:"50%"}}/>
          </div>
          <div className="d-block col-lg-7 col-md-12 col-sm-12 col-xs-12 mt-5 "
          style = {{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}>
            <Card variant="outlined"
                style={{
                width: '60%',
                marginLeft: 'auto',
                marginRight: 'auto',
                // height: '50%',
                boxShadow: "3px 3px 3px 3px #9E9E9E",
                marginTop: "30px",
                }}
              >
                <Card.Body>
                  <Card.Text>
                  Porlor Express staff hold the company's values and mission at heart when at work. 
                  This guarantees the safety and care each package receive while being handled and transported.
                  <br/> 
                  Additionally, 
                  each individual parcel is fit with a
                  <a href="Tracking"> <b>
                  tracking code
                    </b> </a>  so 
                  that you can keep an eye on where exactly your delivery is.
                  </Card.Text>
                </Card.Body>
              </Card>
             {/* <img className="img-align-center" src={safe} alt="กล่องพัสดุ" width="80%"/> */}
          </div>
        </div>
      </div>
      <br />
      <h1 className="color-blue text-center ">Contact us</h1>
      <div className="col-12 text-center img-align-center">
        <div>
          <h1 className="color-yellow">
            <Icon icon="telephone" size={36} color="#e68a00" />
            1423
          </h1>
          <h2 className="color-blue text-center ">Porlor Express</h2>
          <div className="text-center color-yellow">Express delivery of documents and parcels. </div>
        </div>
        <div>
          <img src={imgLine} width="150px" height="150px" style={{ padding: "10px" }} />
          <div>
            <a href="https://lin.ee/2QNYZt2bO">
              <img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0"/>
            </a>
          </div>
        </div>
      </div>
      <br/>
      <h1 className="text-center color-blue mt-7">Our services</h1>
      <div className="container mb-3 mt-3">
        <div className="row ">
          <div className="col-4">
            <img className="d-block img-respon" src={inactive} alt="inactive" />
            <p className="text-center color-blue detail-ser">B2B</p>
          </div>
          <div className="col-4">
            <img className="d-block img-respon " src={inactive2} alt="inactive2" />
            <p className="text-center color-blue detail-ser">C2C</p>
          </div>
          <div className="col-4">
            <img className="d-block   img-respon" src={inactive3} alt="inactive3" />
            <p className="text-center color-blue detail-ser">B2C</p>
          </div>
        </div>
      </div>
      <br/>
    </div>
  );
}
