import React, { useRef } from 'react'
import * as styles from './MultipleImagePicker.module.css';
import * as Icon from "react-bootstrap-icons";
import { previewFile } from '../../utility/files';
import Image from '../Image/Image';

export default function MultipleImagePicker({
    images,
    onChange,
    title,
    onDelete
}) {
  const fileInputRef = useRef(null);
  const currentImages = images || [];
  console.log(styles);
  const handleFileChanges = async  (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const results = [];
      for (const item of files) {
       console.log(item);
        const url = await previewFile(item);
        results.push(url);
      }
      onChange(results);
    }
    fileInputRef.current.value = '';
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      <div className={`${styles.container} row`}>
        <div className='col-12 col-md-3'>
          <input accept="image/*" onChange={handleFileChanges} multiple style={{ display: 'none' }} type="file" ref={fileInputRef} />
          <div onClick={() => fileInputRef.current.click()} className={styles['link-btn']}>
            <Icon.FileArrowUp></Icon.FileArrowUp>
          </div>
        </div>
        {currentImages.map((row, index) => (
          <div key={index} className={`col-12 col-md-3 ${styles['image-container']}`}>
            <Image className={`${styles.image}`} image={row} />
            <div className={styles['delete-icon-container']}>
              <Icon.Trash className={styles['delete-icon']} onClick={() => onDelete(index)}></Icon.Trash>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
