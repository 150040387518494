import Instance from "../Helper/axios";
import { URL, URLTEST, urlLocal } from "../Helper/baseURL";


export async function SaveParcel(data){
  try {
    const response = await Instance.post(URL + 'parcel/ParcelPickUp/saveParcels', data);
    return await response.data;
  } catch (error) {
      console.log('error', error);
  }

}
export async function GetParcelsPickUp(data) {
  try {
      const response = await Instance.post(URL + 'parcel/ParcelPickUp/getParcelsPickUp', data);
      return await response.data;
  } catch (error) {
      console.log('error', error);
  }
}

export async function UpdateLabel(data) {
  try {
    const response = await Instance.post(URL + 'parcel/ParcelPickUp/updateLabel', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function CancelParcelPickUp(data) {
  try {
    const response = await Instance.post(URL + 'parcel/ParcelPickUp/cancelParcelPickUp', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}


//แสดงข้อมูลการเรียกรถไปรับพัสดุ
export async function getPickupParcel(param) {
  try {
    const formData = new FormData();
    Object.entries(param).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await Instance.post(
      URL + "manage/AssignTask/getPickupParcel",
      formData
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//แสดงข้อมูลพิมลาเบล Mobile
export async function getPickupParcelMobile(param) {
  try {
    const formData = new FormData();
    Object.entries(param).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await Instance.post(
      URL + "manage/AssignTask/getPickupParcelMobile",
      formData
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function getNotification() {
  try {
    const response = await Instance.post(
      URL + "manage/Notification/getNotification"
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function AssignTask(param) {
  try {
    const formData = new FormData();
    Object.entries(param).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await Instance.post(
      URL + "manage/AssignTask/saveAssignTask",
      formData
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function GetEmp(empCode) {
  try {
    const response = await Instance.get(
      URL + "settings/Employees/getAllemp"
    );

    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function CancelAssignTask(param) {
  try {
    const formData = new FormData();
    Object.entries(param).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await Instance.post(
      URL + "manage/AssignTask/cancelPickupParcel",
      formData
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

// api อัพโหลด label ด้วยไพล์ excel
export async function UploadFile(params) {
  try {
    let data = new FormData();
    data.append("custCode", params.custCode);
    data.append("files", params.file);
    const response = await Instance.post(URL + "parcel/ParcelPickUp/UploadFile", data);
    return await response.data;
  } catch (error) {}
}

// ดึงข้อมูลประวัติ upload file
export async function GetHistoryUpload(params) {
  try {
    const respons = await Instance.post(URL + "parcel/ParcelPickUp/getUploadFile", params);
    return await respons.data;
  } catch (error) {}
}

// ลบไพล์ upload
export async function RemoveFileUpload(params) {
  try {
    let data = new FormData();
    data.append("fileID", params);
    const respons = await Instance.post(URL + "parcel/ParcelPickUp/deleteData", data);
    return await respons.data;
  } catch (error) {}
}

// นำข้อมูลใน Excel เขาฐานข้อมูล
export async function InsertValueToDB(params) {
  try {
    let data = new FormData();
    data.append("fileID", params);
    const respons = await Instance.post(URL + "parcel/ParcelPickUp/ImportDataFromFile", data);
    return respons.data;
  } catch (error) {}
}

// // นำข้อมูลใน Excel เขาฐานข้อมูล
// export async function InsertValueToDBNoDimension(params) {
//   try {
//     let data = new FormData();
//     data.append("fileID", params);
//     const respons = await Instance.post(URL + "parcel/ParcelPickUp/ImportDataFromFileNoDimension", data);
//     return respons.data;
//   } catch (error) {}
// }

//OP part
// api อัพโหลด label ด้วยไพล์ excel
export async function UploadFileOP(params) {
  try {
    let data = new FormData();
    data.append("custCode", params.custCode);
    data.append("files", params.file);
    const response = await Instance.post(URL + "parcel/ParcelPickUp/UploadFileOP", data);
    return await response.data;
  } catch (error) {}
}

// ดึงข้อมูลประวัติ upload file
export async function GetHistoryUploadOP(params) {
  try {
    const respons = await Instance.post(URL + "parcel/ParcelPickUp/getUploadFileOP", params);
    return await respons.data;
  } catch (error) {}
}

// ลบไพล์ upload
export async function RemoveFileUploadOP(params) {
  try {
    let data = new FormData();
    data.append("fileID", params);
    const respons = await Instance.post(URL + "parcel/ParcelPickUp/deleteDataOP", data);
    return await respons.data;
  } catch (error) {}
}

// นำข้อมูลใน Excel เขาฐานข้อมูล
export async function InsertValueToDBOP(params) {
  try {
    let data = new FormData();
    data.append("fileID", params);
    const respons = await Instance.post(URL + "parcel/ParcelPickUp/ImportDataFromFileOP", data);
    return respons.data;
  } catch (error) {}
}