import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

//import slide1 from "../../../Assets/images/home/pl_online.jpg";
import slide2 from "../../../Assets/images/home/pl_thailand_08092564.jpg";
import slide3 from "../../../Assets/images/home/pl_cod_08092564.jpg";

export default function ImgCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  let imgName = [slide2, slide3];

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {imgName.map((imgIdx) => {
        return (
          <Carousel.Item interval={2000} key={imgIdx}>
            <img className="d-block w-100" src={imgIdx} alt={imgIdx} />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
