import React, {useState} from 'react';
import DateTH from '../../../Helper/date-th';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '@material-ui/lab/Pagination';
import printJS from 'print-js';
import swal from 'sweetalert';
import {print_genAwbCode, refreshParcelStatus} from '../../../Service/service.PrintLael';
import Modal from "react-bootstrap/Modal";
import {ConoteModal} from "./conoteModal/conoteModal";
import {ConoteModalOld} from "./conoteModal/conoteModalOld";
import { useTranslation } from 'react-i18next';
import EditRefNo from './editRef_no_modal';
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function ShowData({
  printLabel2,
  data,
  dataArray,
  // addPrints,
  page,
  pageSizes,
  setDataExport,
  dataExport,
  checked,
  setPageSizes,
  currentPage,
  data2,
  Data2,
  setData,
  setcurrentPage,
  fetchData,
  fetchDataExcel,
  user,
  values,
  handlePrintShow2,
  handleShow,
  handleShow2,
  setAwbCode,
  UpdateBarcode,
  setDataEdit,
  printLebel,
  setPrintLebel,
}) {
  const [ref_no_edit_mode, set_ref_no_edit_mode] = useState(false);
  const [conote, setConote] = useState(false);
  const [conoteOld, setConoteOld] = useState(false);
  const { t } = useTranslation();
  async function addPrints(param) {
    if (param.receiver_zipcode !== null) {
      var dataParam = {
        code: param.code,
        awb_code: param.awb_code,
        zipcode: param.receiver_zipcode,
      };
      const result = await print_genAwbCode(dataParam);
      if (result !== undefined) {
        if (printLebel.length === 0 || printLebel.filter((a) => a.awb_code === result.awb_code).length === 0) {
          let dataPrint = [...printLebel, { ...result }];
          setPrintLebel(dataPrint);
        } else {
          let dataPrint = [...printLebel];
          setPrintLebel([...dataPrint.filter((a) => a.awb_code !== result.awb_code)]);
        }
        fetchData({
          search: values.search,
          printStatus: values.printStatus,
          printType: values.printType,
          pageNo: currentPage,
          perPage: pageSizes,
          cust_code: localStorage.getItem('username'),
        });
      }
    }
  }

  function dataArrayAll(check) {
    // setDataSelect([]);
    // setPrintLabel([]);
    if (check) {
      data
        // .filter((a) => a.awb_code !== '' && a.awb_code !== null && a.awb_code !== undefined)
        .map((item) => {
          if (document.getElementById('flexCheckDefault' + item.code) != null) {
            document.getElementById('flexCheckDefault' + item.code).checked = true;

          }
          // setDataSelect((t) =>
          //   t.concat({
          //     code: item.code,
          //     awb_code: item.awb_code !== null ? item.awb_code : item.ref_no,
          //     cust_code: localStorage.getItem('username'),
          //     shipment_status: '',
          //   }),
          // );
          // setPrintLabel((t) => t.concat(item));
        });
        setPrintLebel(data);
        Data2(data);
    } else {

      data.map((item) => {
        if (document.getElementById('flexCheckDefault' + item.code) != null) {
          document.getElementById('flexCheckDefault' + item.code).checked = false;
        }
      });
      Data2([]);
      setPrintLebel([]);

    }
  }

  return (
    <>
      <div>
        <ExcelFile
          element={
            <button type="button" style={{ display: 'none' }} id="downloadCredit">
              Download Data
            </button>
          }
          filename={'ปริ้นลาเบล'}
        >
          <ExcelSheet data={dataExport} name={'ปริ้นลาเบล'}>
            <ExcelColumn label="วันเปิดบิล" value="created_date" />
            <ExcelColumn label="เลขลาเบล" value="awb_code" />
            <ExcelColumn label="เลขที่co note" value="ref_no" />
            <ExcelColumn label="หมายเลขinvoice" value="invoice" />
            <ExcelColumn label="รหัสลกค้า" value="cust_code" />
            <ExcelColumn label="ชื่อผู้ส่ง" value="sender_name" />
            <ExcelColumn label="เบอร์โทรผู้ส่ง" value="sender_phone" />
            <ExcelColumn label="บัตรประชาชนผู้ส่ง" value="citizenID" />
            <ExcelColumn label="ชื่อผู้รับ" value="receiver_name" />
            <ExcelColumn label="เบอร์โทรผู้รับ" value="receiver_phone" />
            <ExcelColumn label="ที่อยู่" value="receiver_address" />
            <ExcelColumn label="ตำบล" value="receiver_district" />
            <ExcelColumn label="อำเภอ" value="receiver_amphur" />
            <ExcelColumn label="จังหวัด" value="receiver_province" />
            <ExcelColumn label="น้ำหนัก" value="weight" />
            <ExcelColumn label="ความกว้าง (ซม.)" value="box_width" />
            <ExcelColumn label="ความยาว (ซม.)" value="box_length" />
            <ExcelColumn label="ความสูง (ซม.)" value="box_height" />
            <ExcelColumn label="ขนาด(ซ.ม.)" value="box_size" />
            <ExcelColumn label="COD (บาท)" value={(row) => parseInt(row.cod_cost)} />
            <ExcelColumn label="ชื่อธนาคาร" value="bank_id" />
            <ExcelColumn label="ชื่อบัญชี" value="bookbank_name" />
            <ExcelColumn label="เลขที่บัญชี" value="bookbank_code" />
            <ExcelColumn label="เลขสำหรับลูกค้า 1" value="ref_no2" />
            <ExcelColumn label="เลขสำหรับลูกค้า 2" value="item_desc" />
            <ExcelColumn label="เลขสำหรับลูกค้า 3" value="item_sku" />
            <ExcelColumn label="จำนวนเอกสารนำกลับ" value="num_return_doc" />
          </ExcelSheet>
        </ExcelFile>
      </div>

      <div>
        <ExcelFile
          element={
            <button type="button" style={{ display: 'none' }} id="downloadEx">
              Download Data
            </button>
          }
          filename={'ปริ้นลาเบล'}
        >
          <ExcelSheet data={dataExport} name={'ปริ้นลาเบล'}>
            <ExcelColumn label="วันเปิดบิล" value="created_date" />
            <ExcelColumn label="เลขลาเบล" value="awb_code" />
            <ExcelColumn label="สำหรับลูกค้า" value="ref_no" />
            <ExcelColumn label="รหัสลกค้า" value="cust_code" />
            <ExcelColumn label="ชื่อผู้ส่ง" value="sender_name" />
            <ExcelColumn label="เบอร์โทรผู้ส่ง" value="sender_phone" />
            <ExcelColumn label="บัตรประชาชนผู้ส่ง" value="citizenID" />
            <ExcelColumn label="ชื่อผู้รับ" value="receiver_name" />
            <ExcelColumn label="เบอร์โทรผู้รับ" value="receiver_phone" />
            <ExcelColumn label="ที่อยู่" value="receiver_address" />
            <ExcelColumn label="ตำบล" value="receiver_district" />
            <ExcelColumn label="อำเภอ" value="receiver_amphur" />
            <ExcelColumn label="จังหวัด" value="receiver_province" />
            <ExcelColumn label="น้ำหนัก" value="weight" />
            <ExcelColumn label="ความกว้าง (ซม.)" value="box_width" />
            <ExcelColumn label="ความยาว (ซม.)" value="box_length" />
            <ExcelColumn label="ความสูง (ซม.)" value="box_height" />
            <ExcelColumn label="ขนาด(ซ.ม.)" value="box_size" />
            <ExcelColumn label="ค่าขนส่ง (บาท)" value={(row) => (parseInt(row.shipment_cost))} />
            <ExcelColumn label="ค่าขนส่ง COD (บาท)" value={(row) => (parseInt(row.service_cod))} />
            <ExcelColumn label="ค่าขนส่ง (บาท)" value={(row) => (parseInt(row.shipment_cost)+parseInt(row.service_cod))} />
            <ExcelColumn label="COD (บาท)" value={(row) => parseInt(row.cod_cost)} />
            <ExcelColumn label="ชื่อธนาคาร" value="bank_id" />
            <ExcelColumn label="ชื่อบัญชี" value="bookbank_name" />
            <ExcelColumn label="เลขที่บัญชี" value="bookbank_code" />
          </ExcelSheet>
        </ExcelFile>
      </div>

      <div>
      <div className="d-flex justify-content-between mb-2">
        <div>
          <span>
            <button
              type="button"
              className={data2.length === 0 ? 'btn btn-secondary' : 'btn btn-danger'}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={(e) => {
                UpdateBarcode(data2);
              }}
              disabled={data2.length === 0 ? true : false}
            >
              <Icon.Trash /> ยกเลิกลาเบลที่เลือกทั้งหมด
            </button>
          </span>{' '}
          <span>
            {localStorage.getItem('superkey') !== '1947' ? (
              <button
                type="button"
                disabled={printLebel.length === 0 ? true : false}
                className={printLebel.length === 0 ? 'btn btn-secondary' : 'btn btn-warning'}
                onClick={() => {
                  printJS({
                    printable: 'labelNormal',
                    type: 'html',
                    scanStyles: false,
                    css: [
                      window.location.protocol + '//' + window.location.host + '/static/PrinterCOD.css',
                      'https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap',
                    ],
                    style: '@page {size: 10cm 7cm;margin: 0cm;}',
                    onLoadingEnd: () => {
                      refreshParcelStatus(printLebel.map(label => label.code))
                          .then(result => console.log('status updated'));
                    },
                  });
                }}
              >
                <Icon.Printer /> พิมพ์ลาเบลเป็นชุด
              </button>
            ) : (
              ''
            )}
          </span>{' '}
          <span>
            {localStorage.getItem('superkey') === '1947' || localStorage.getItem('superkey') === 'credit'? (
              <button
                type="button"
                disabled={data2.length === 0 ? true : false}
                className={data2.length === 0 ? 'btn btn-secondary' : 'btn btn-warning'}
                onClick={() => {
                  handlePrintShow2();
                }}
              >
                <Icon.Printer /> พิมพ์ลาเบล (mobile)
              </button>
            ) : (
              ''
            )}
          </span>
          <span>
            <button
              type="button"
              disabled={printLebel.length === 0 ? true : false}
              className={printLebel.length === 0 ? 'btn btn-secondary ml-1' : 'btn btn-success ml-1'}
              onClick={() => {
                printJS({
                  printable: 'labelAddress',
                  type: 'html',
                  scanStyles: false,
                  css: [
                    window.location.protocol + '//' + window.location.host + '/static/Printer.css',
                    'https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap',
                  ],
                  style: '@page {size: 10cm 15cm;margin: 0.3cm;}',
                  onLoadingEnd: () => {
                    refreshParcelStatus(printLebel.map(label => label.code))
                        .then(result => console.log('status updated'));
                  },
                });
              }}
            >
              <Icon.Printer /> พิมพ์ลาเบล/ที่อยู่เป็นชุด
            </button>
          </span>{' '}

          <span>
            {localStorage.getItem('superkey') === 'credit' && localStorage.getItem('show_conote') === '1' && (
              <>
                <button
                    style={{marginLeft: '5px', marginRight: '5px'}}
                    type="button"
                  className={printLebel.length === 0 ? 'btn btn-secondary ml-1' : 'btn btn-primary ml-1'}
                    disabled={printLebel.length === 0 ? true : false}
                    onClick={() => set_ref_no_edit_mode(true)}>
                  {t('parcel.multiple_edit.ref_no')}
                </button>
                {localStorage.getItem('showGenerateRefNo') === "1" ? (
                  <button
                    style={{marginLeft: '5px', marginRight: '5px'}}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setConote(true)}>
                  ปริ้นใบ co-note
                </button>
                ):(
                  <button
                    style={{marginLeft: '5px', marginRight: '5px'}}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setConoteOld(true)}>
                  ปริ้นใบ co-note
                </button>
                )}
                
              </>
            )}
          </span>
          <span>
          {localStorage.getItem('superkey') === 'credit' ? (
            <button
                type="button"
                className="btn btn-info"
                onClick={() => {
                  fetchDataExcel({
                    search: values.search,
                    printStatus: values.printStatus,
                    printType: values.printType,
                    pageNo: '',
                    perPage: '',
                    cust_code: localStorage.getItem('username'),
                  }).then(
                    (value)=>{
                      document.getElementById('downloadCredit').click();
                    }
                  );
                }}
              >
                <Icon.FileEarmarkArrowUp /> export
            </button>
          ):(
            <button
                type="button"
                className="btn btn-info"
                onClick={() => {
                  fetchDataExcel({
                    search: values.search,
                    printStatus: values.printStatus,
                    printType: values.printType,
                    pageNo: '',
                    perPage: '',
                    cust_code: localStorage.getItem('username'),
                  }).then(
                    (value)=>{
                      document.getElementById('downloadEx').click();
                    }
                  );
                }}
              >
                <Icon.FileEarmarkArrowUp /> export
            </button>
          )}
          </span>{' '}
        </div>
        <div>
          <select
            value={pageSizes}
            className="form-control "
            aria-label="Default select example"
            onChange={(e) => {
              setPageSizes(e.target.value);
              setcurrentPage(1);
              setData([]);
              setDataExport([]);
              fetchData({
                search: values.search,
                printStatus: values.printStatus,
                printType: values.printType,
                pageNo: 1,
                perPage: e.target.value,
                cust_code: localStorage.getItem('username'),
              });
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div style={{ overflow: 'Auto' }}>
      {localStorage.getItem('superkey') !== 'credit' ? (
        <table className="table table-bordered">
          <thead style={{ backgroundColor: '#3f75c1' }}>
            <tr style={{ color: '#FFFFFF' }}>
            <td style={{ width: '5%', textAlign: 'center' }}>
                <input
                  checked={
                    parseInt(data2.length) !== 0 && parseInt(printLebel.length) !== 0
                    // && parseInt(data2.length) === parseInt(data.filter((a) => a.awb_code !== '' && a.awb_code !== null && a.awb_code !== undefined).length)
                      ? true
                      : false
                  }
                  style={{ width: '18px', height: '18px' }}
                  type="checkbox"
                  onChange={(e) => {
                    dataArrayAll(e.target.checked);
                  }}
                />
              </td>
              <td>ลำดับ</td>
              <td>วันเปิดบิล</td>
              <td>ลาเบล/สำหรับลูกค้า</td>
              <td>ผู้ส่ง</td>
              <td>ผู้รับ/ที่อยู่</td>
              <td>ประเภทพัสดุ</td>
              <td>น้ำหนัก</td>
              <td>ขนาด(ซ.ม.)</td>
              <td>จำนวน(ชิ้น)</td>
              <td>ค่าขนส่ง(บาท)</td>
              <td>COD</td>
              {/* <td>บริการ COD</td> */}
              {/* <td>ทั้งหมด</td> */}
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={value.code}>
                <td>
                  <center>
                    <div className="form-check">
                      <input
                        // disabled={value.shipment_status === 5 || value.awb_code === null ? true : false}
                        checked={printLebel.filter((a) => a.awb_code === value.awb_code).length !== 0}
                        style={{ width: '18px', height: '18px' }}
                        onChange={(e) => {
                          if (value.receiver_zipcode !== null) {
                            dataArray(value.code,value.awb_code);
                            addPrints(value);
                            if (e.target.checked) {
                              printLabel2.push(value);
                            } else {
                              printLabel2.map((value2, index2) => {
                                if (value.code === value2.code) {
                                  printLabel2 = printLabel2.splice(index2, 1);
                                }
                              });
                            }
                          } else {
                            swal({
                              title: 'ไม่พบรหัสไปรษณีย์',
                              text: 'กรุณาติดต่อผู้ดูแลระบบ',
                              icon: 'warning',
                              showConfirmButton: false,
                              button: 'ปิด',
                              timer: 1500,
                            });
                          }
                        }}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id={'flexCheckDefault' + value.code}
                      />
                    </div>
                  </center>
                </td>
                <td>{(page.current_page - 1) * pageSizes + (index + 1)}</td>
                <td>
                  <DateTH date={value.created_date.substring(0, 10)} />
                </td>
                <td>
                  <div className="text-center"> {value.ref_no === null || value.ref_no === '0' ? " " : value.ref_no} </div>
                  <div className="text-center">
                    {value.awb_code != null ? value.awb_code : value.ref_no}
                  </div>
                </td>
                <td>
                  <div>{value.sender_name}</div>
                </td>
                <td>
                  <div>{value.receiver_name}</div>
                  <div>ที่อยู่ : {value.receiver_address + ' ' }{value.receiver_district!== null ? value.receiver_district : ""}{' '+ value.receiver_amphur + ', ' + value.receiver_province} </div>
                </td>
                <td>กล่อง</td>
                <td>{value.weight}</td>
                <td>{value.box_size} 
                  {/* <br/> {value.parcel_size}  */}
                  </td>
                <td>{value.num_parcel}</td>
                <td>{(parseFloat(value.service_cod) + parseFloat(value.shipment_cost)).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td> 
                <td>{parseInt(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                <td align="center"  style={{ width: '170px' }}>
                  <button
                    type="button"
                    disabled={value.awb_code !== '' && value.awb_code !== null ? false : true}
                    className={value.awb_code !== '' && value.awb_code ? 'btn btn-info' : 'btn btn-secondary'}
                    onClick={() => {
                      setAwbCode(value.awb_code);
                      setDataEdit(value);
                      handleShow2();
                    }}
                  >
                    <Icon.PencilSquare />
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    disabled={value.awb_code !== '' && value.awb_code ? false : true}
                    className={value.awb_code !== '' && value.awb_code ? 'btn btn-primary' : 'btn btn-secondary'}
                    onClick={() => {
                      setAwbCode(value.awb_code);
                      handleShow();
                    }}
                  >
                    <Icon.Printer />
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      UpdateBarcode([
                        {
                          cust_code: localStorage.getItem("username"),
                          code: value.code,
                          awb_code: value.awb_code
                        },
                      ]);
                    }}
                  >
                    <Icon.Trash />
                  </button>
                </td>
                {data.length === index + 1 ? checked() : ''}
              </tr>
            ))}
          </tbody>
        </table>
      ):(
        <table className="table table-bordered">
          <thead style={{ backgroundColor: '#3f75c1' }}>
            <tr style={{ color: '#FFFFFF' }}>
            <td style={{ width: '5%', textAlign: 'center' }}>
                <input
                  checked={
                    parseInt(data2.length) !== 0 && parseInt(printLebel.length) !== 0
                    // && parseInt(data2.length) === parseInt(data.filter((a) => a.awb_code !== '' && a.awb_code !== null && a.awb_code !== undefined).length)
                      ? true
                      : false
                  }
                  style={{ width: '18px', height: '18px' }}
                  type="checkbox"
                  onChange={(e) => {
                    dataArrayAll(e.target.checked);
                  }}
                />
              </td>
              <td>ลำดับ</td>
              <td>วันเปิดบิล</td>
              <td>ลาเบล/สำหรับลูกค้า</td>
              <td>หมายเลขinvoice</td>
              <td>ผู้ส่ง</td>
              <td>ผู้รับ/ที่อยู่</td>
              {localStorage.getItem('requireDimension') === "1" ? (
                <td>น้ำหนัก</td>
              ):""}
              {localStorage.getItem('requireDimension') === "1" ? (
                <td>ขนาด(ซ.ม.)</td>
              ):""}
              
              <td>จำนวนเอกสารนำกลับ</td>
              { localStorage.getItem('show_shipment_cost') === "1" ? (
                <td>ค่าขนส่ง(บาท)</td> ) : "" }
              <td>COD</td>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={value.code}>
                <td>
                  <center>
                    <div className="form-check">
                      <input
                        // disabled={value.shipment_status === 5 || value.awb_code === null ? true : false}
                        checked={printLebel.filter((a) => a.awb_code === value.awb_code).length !== 0}
                        style={{ width: '18px', height: '18px' }}
                        onChange={(e) => {
                          if (value.receiver_zipcode !== null) {
                            dataArray(value.code,value.awb_code);
                            addPrints(value);
                            if (e.target.checked) {
                              printLabel2.push(value);
                            } else {
                              printLabel2.map((value2, index2) => {
                                if (value.code === value2.code) {
                                  printLabel2 = printLabel2.splice(index2, 1);
                                }
                              });
                            }
                          } else {
                            swal({
                              title: 'ไม่พบรหัสไปรษณีย์',
                              text: 'กรุณาติดต่อผู้ดูแลระบบ',
                              icon: 'warning',
                              showConfirmButton: false,
                              button: 'ปิด',
                              timer: 1500,
                            });
                          }
                        }}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id={'flexCheckDefault' + value.code}
                      />
                    </div>
                  </center>
                </td>
                <td>{(page.current_page - 1) * pageSizes + (index + 1)}</td>
                <td>
                  <DateTH date={value.created_date.substring(0, 10)} />
                </td>
                <td>
                  <div className="text-center"> {value.ref_no === null || value.ref_no === '0' ? " " : value.ref_no} </div>
                  <div className="text-center"> {value.ref_no2 === null || value.ref_no2 === " " ? " " : value.ref_no2} </div>
                  <div className="text-center"> {value.item_desc === null || value.item_desc === " " ? " " : value.item_desc} </div>
                  <div className="text-center"> {value.conote === null || value.conote === '' ? " " : <p>เลขที่co note: {value.conote} </p>} </div>
                  <div className="text-center">
                    {value.awb_code != null ? value.awb_code : value.ref_no}
                  </div>
                  {value.product_code === null || value.product_code === "" ? "" : 
                      (<div className="text-center">
                      <b>Product code: </b> {value.product_code} </div>)}
                </td>
                <td><div className="text-center">
                    {value.invoice === null || value.invoice === "" ? "" : value.invoice }
                  </div></td>
                <td>
                  <div>{value.sender_name}</div>
                </td>
                <td>
                  <div>{value.receiver_name}</div>
                  <div>ที่อยู่ : {value.receiver_address + ' ' }{value.receiver_district!== null ? value.receiver_district : ""}{' ' + value.receiver_amphur + ', ' + value.receiver_province}</div>
                </td>
                {localStorage.getItem('requireDimension') === "1" ? (
                  <td>{value.weight}</td>
                ):""}
                {localStorage.getItem('requireDimension') === "1" ? (
                  <td>{value.box_size} 
                    <br/> {value.parcel_size}  </td>
                ):""}
                { localStorage.getItem('show_shipment_cost') === "1" ? (
                  <td>{(parseFloat(value.service_cod) + parseFloat(value.shipment_cost)).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td> ) : "" }
                <td>{value.num_return_doc}</td>
                <td>{parseInt(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>

                <td align="center"  style={{ width: '170px' }}>
                  <button
                    type="button"
                    disabled={value.awb_code !== '' && value.awb_code !== null ? false : true}
                    className={value.awb_code !== '' && value.awb_code ? 'btn btn-info' : 'btn btn-secondary'}
                    onClick={() => {
                      setAwbCode(value.awb_code);
                      setDataEdit(value);
                      handleShow2();
                    }}
                  >
                    <Icon.PencilSquare />
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    disabled={value.awb_code !== '' && value.awb_code ? false : true}
                    className={value.awb_code !== '' && value.awb_code ? 'btn btn-primary' : 'btn btn-secondary'}
                    onClick={() => {
                      setAwbCode(value.awb_code);
                      handleShow();
                    }}
                  >
                    <Icon.Printer />
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      UpdateBarcode([
                        {
                          cust_code: localStorage.getItem("username"),
                          code: value.code,
                          awb_code: value.awb_code
                        },
                      ]);
                    }}
                  >
                    <Icon.Trash />
                  </button>
                </td>
                {data.length === index + 1 ? checked() : ''}
              </tr>
            ))}
          </tbody>
        </table>
      )}
        
      </div>
      <div className="d-flex justify-content-between">
        <div>จำนวนทั้งหมด {page.total} รายการ</div>
        <div>
          <Pagination
            count={parseInt(page.last_page)}
            page={currentPage}
            onChange={(e, newPage) => {
              setData([]);
              setDataExport([]);
              setcurrentPage(newPage);
              fetchData({
                search: values.search,
                printStatus: values.printStatus,
                printType: values.printType,
                pageNo: newPage,
                perPage: pageSizes,
                cust_code: localStorage.getItem('username'),
              });
            }}
            color="primary"
            size="small"
            defaultPage={6}
            siblingCount={1}
          />
        </div>
      </div>
      {conote && (
        <ConoteModal onModalHide={() => setConote(false)} />
      )}
      {conoteOld && (
        <ConoteModalOld onModalHide={() => setConoteOld(false)} />
      )}
      {ref_no_edit_mode && (
        <EditRefNo onClose={(reload) => {
          if(reload){
            fetchData({
                search: values.search,
                printStatus: values.printStatus,
                printType: values.printType,
                pageNo: currentPage,
                perPage: pageSizes,
                cust_code: localStorage.getItem('username'),
            });
          }
          set_ref_no_edit_mode(false)
        }} data={printLebel} />
      )}
    </div>
    </>
    
  );
}

export default ShowData;
