import React from "react";
import * as Icon from "react-bootstrap-icons";
import TrackingDetailEN from "./TrackingDetailEN";
import DateTH from "../../../Helper/date-thV2";

function ShowDataEn({ data }) {

  function Date(value,params) {
    let values = value.porlor_staff_deliver.find((a) => a.shipment_name === params);
    return values !== undefined ? values.Receiver_Date + ' ' + values.Receiver_Time : '';
  }

  function step1(value) {
    let step = (value.shipment_name === "รับเข้าระบบ" ||
      value.shipment_name === "ศูนย์กระจายคัดแยกสินค้า" ||
      value.shipment_name === "อยู่ระหว่างขนส่ง" ||
      value.shipment_name === "ติดต่อผู้รับไม่ได้" ||
      value.shipment_name === "รับแล้ว" ||  
      value.shipment_name === "พัสดุถูกส่งกลับต้นทาง" ||
      value.shipment_name === "ติดต่อผู้รับไม่ได้" || 
      value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ||
      value.shipment_name === "ลูกค้าปฏิเสธรับ" ||
      value.shipment_name === "ลูกค้าปฏิเสธรับ" || value.shipment_name === "ลูกค้าเลื่อนรับ"
      || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step2(value) {
    let step = (value.shipment_name === "ศูนย์กระจายคัดแยกสินค้า" ||
      value.shipment_name === "อยู่ระหว่างขนส่ง" ||
      value.shipment_name === "ติดต่อผู้รับไม่ได้" ||
      value.shipment_name === "รับแล้ว" ||
      value.shipment_name === "พัสดุถูกส่งกลับต้นทาง" ||
      value.shipment_name === "ติดต่อผู้รับไม่ได้" ||
      value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ||
      value.shipment_name === "ลูกค้าปฏิเสธรับ"  || value.shipment_name === "ลูกค้าเลื่อนรับ"
      || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3(value) {
    let step = (value.shipment_name === "อยู่ระหว่างขนส่ง" ||
      value.shipment_name === "รับแล้ว" ||
      value.shipment_name === "พัสดุถูกส่งกลับต้นทาง" ||
      value.shipment_name === "ติดต่อผู้รับไม่ได้" ||
      value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ||
      value.shipment_name === "ลูกค้าปฏิเสธรับ" || value.shipment_name === "ลูกค้าเลื่อนรับ" 
      || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3Returned(value) {
    let step = (
      value.shipment_name === "รับแล้ว" ||
      value.shipment_name === "พัสดุถูกส่งกลับต้นทาง"
      || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3FailedCall(value) {
    let step = (value.shipment_name === "ติดต่อผู้รับไม่ได้" ||
      value.shipment_name === "รับแล้ว" 
      ? " completed"
      : "")
    return step;
  }

  function step3InvalidAddress(value) {
    let step = (value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ||
      value.shipment_name === "รับแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3Refused(value) {
    let step = (value.shipment_name === "ลูกค้าปฏิเสธรับ" ||
      value.shipment_name === "รับแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3InvalidAddress(value) {
    let step = (value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ||
      value.shipment_name === "รับแล้ว"
      ? " completed"
      : "")
    return step;
  }

  function step3Postponed(value) {
    let step = (value.shipment_name === "ลูกค้าปฏิเสธรับ" ||
      value.shipment_name === "รับแล้ว"  || value.shipment_name === "ลูกค้าเลื่อนรับ"
      ? " completed"
      : "")
    return step;
  }

  function step5(value) {
    let step = (value.shipment_name === "รับแล้ว" || value.shipment_name === "ผู้ส่งรับพัสดุฯแล้ว" ? " completed" : "")
    return step;
  }

  return (
    <>
      {data.map((value, index) => (
        <div className="container padding-bottom-3x mb-3" key={"detail" + index}>
          <div className="text-title-color">
            <h3>
              <Icon.Box /> # {value.awb_code === "" || value.awb_code === null ? value.ref_no : value.awb_code}
            </h3>
          </div>
          <div className="row container text-heard">
            <div className="col-lg-6">
              <b>Sender : </b>
              <span>{value.sender_name}</span>
            </div>
            <div className="col-lg-6">
              <b>Sender address : </b>
              <span>{value.sender_address === "" ? "-" : value.sender_address}</span>
            </div>
            <div className="col-lg-6">
              <b>Receiver : </b>
              <span>{value.receiver_name}</span>
            </div>
            <div className="col-lg-6">
              <b>Receiver address : </b>
              <span>{value.receiver_address}</span>
            </div>
            <div className="col-lg-6">
              <b>Order : </b>
              <span>{value.num_parcel}</span>
              <b> Parcel(s) </b>
            </div>
            <div className="col-lg-6">
              <b>Weight : </b>
              <span>{value.weight}</span>
              <b> Kg.</b>
            </div>
            <div className="col-lg-6">
              <b>Type : </b>
              <span>{"Package box"}</span>
            </div>
            <div className="col-lg-6">
              <b>COD : </b>
              <span>{parseInt(value.cod).toLocaleString("en")}</span>
              <b> Baht</b>
            </div>
            <div className="col-lg-6">
              <b>Created date : </b>
                {value.created_date} 
            </div>
            <div className="col-lg-6">
                <b>Reference number / Conote : </b>
                <span>{value.ref_no}</span>
            </div>
          </div>
          <hr style={{ borderBottom: "3px solid #d8d8d8" }} />
          <br />
          <br />
          <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
            <div
              className={"step " + step1(value) + (value.shipment_name === "ขนส่งเข้ารับพัสดุฯ" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.Clipboard size="70%" />
                </div>
              </div>
              <h4 className="step-title">Parcel picked up by driver</h4>
            </div>
            <div className={"line-ct" + step2(value)}>&nbsp;</div>
            <div
              className={"step " + step2(value) + (value.shipment_name === "ศูนย์กระจายคัดแยกสินค้า" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.Box size="70%" />
                </div>
              </div>
              <h4 className="step-title">In distribution center</h4>
            </div>
            <div className={"line-ct" + step3(value)}>&nbsp;</div>
            <div
              className={"step " + step3(value) + (value.shipment_name === "อยู่ระหว่างขนส่ง" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.Truck size="70%" />
                </div>
              </div>
              <h4 className="step-title">In transit</h4>
            </div>
            {value.shipment_name === "พัสดุถูกส่งกลับต้นทาง" || Date(value,'พัสดุถูกส่งกลับต้นทาง') !== '' ? (
              <div
              className={"step " + step3Returned(value) + (value.shipment_name === "พัสดุถูกส่งกลับต้นทาง" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.Person size="70%" />
                </div>
              </div>
              <h4 className="step-title">Returned to origin </h4>
            </div>    
            ) : "" }
            {value.shipment_name === "ติดต่อผู้รับไม่ได้" || Date(value,'ติดต่อผู้รับไม่ได้') !== '' ? (
              <div
              className={"step " + step3FailedCall(value) + (value.shipment_name === "ติดต่อผู้รับไม่ได้" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.PhoneVibrate size="70%" />
                </div>
              </div>
              <h4 className="step-title">Unable to contact recipient</h4>
            </div>    
            ) : "" }
            {value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" || Date(value,'ที่อยู่/เบอร์โทรไม่ถูกต้อง') !== '' ? (
              <div
              className={"step " + step3InvalidAddress(value) + (value.shipment_name === "ที่อยู่/เบอร์โทรไม่ถูกต้อง" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.PersonX size="70%" />
                </div>
              </div>
              <h4 className="step-title">Invalid address/phone number</h4>
            </div>    
            ) : "" }
            {value.shipment_name === "ลูกค้าปฏิเสธรับ" || Date(value,'ลูกค้าปฏิเสธรับ') !== '' ? (
              <div
              className={"step " + step3Refused(value) + (value.shipment_name === "ลูกค้าปฏิเสธรับ" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.PersonX size="70%" />
                </div>
              </div>
              <h4 className="step-title">Customer refuses parcel</h4>
            </div>    
            ) : "" }
            {value.shipment_name === "ลูกค้าเลื่อนรับ" || Date(value,'ลูกค้าเลื่อนรับ') !== '' ? (
              <div
              className={"step " + step3Postponed(value) + (value.shipment_name === "ลูกค้าเลื่อนรับ" ? " now" : "")}
            >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.PersonX size="70%" />
                </div>
              </div>
              <h4 className="step-title">Customer postponed reception</h4>
            </div>    
            ) : "" }
            <div className={"line-ct" + step5(value)}>&nbsp;</div>
            <div className={"step " + step5(value) + (value.shipment_name === "รับแล้ว" ? " now" : "")}  >
              <div className="step-icon-wrap">
                <div className="step-icon">
                  <Icon.House size="70%" />
                </div>
              </div>
              <h4 className="step-title">Received</h4>
            </div>
          </div>
          <br />
          <TrackingDetailEN value={value} />
        </div>
      ))}
      <br />
    </>
  );
}

export default ShowDataEn;
