import React, { useState, useEffect } from "react";
import FilterData from "./filterData";
import ShowData from "./showData";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { ConoteService,UpdateConoteStatusService } from "../../../Service/service.Conote";
import { InsertData } from "../../../Service/service.goods";
import { swalSuccess, swalError } from "../../../Components/sweetAlert";
import { monthTH } from "../../../Data/month-th.json";
import { useTranslation } from "react-i18next";

function ScanConote(props) {
  const { user } = props;
  const schema = Yup.object().shape({
    ref_no: Yup.string()
        .required("กรุณาระบุเลขบาร์โค้ด")
        // .min(16, 'ความยาวของบาร์โค้ดควรเป็น 16')
        // .max(16, 'ความยาวของบาร์โค้ดควรเป็น 16'),
  });
  const [shipmentStatus, setShipmentStatus] = useState("1");
  const [perPage, setPageSize] = useState(
    10);
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();

  const [ref_no,setRef_no] = useState("");

  const [pageNo, setCurrentPage] = useState(1);
  const [loading, Loading] = useState(0);
  const [errorMassNoData, setErrorMassNoData] = useState(0);
  const [startdate1, SetStart1] = useState(year);
  const [startdate2, SetStart2] = useState(("0" + month).slice(-2));
  const [startdate3, SetStart3] = useState(date);
  const [enddate1, SetEnd1] = useState(year);
  const [enddate2, SetEnd2] = useState(("0" + month).slice(-2));
  const [enddate3, SetEnd3] = useState(date);
  const [errorStartdate, SetErrorStart] = useState("form-control ");
  const [errorEnddate, SetErrorEnd] = useState("form-control ");  
  const [errorStartMass, SetErrorStartMass] = useState(0);
  const [errorEndMass, SetErrorEndMass] = useState(0);
  const [errorMassSta, ErrorMassSta] = useState(0);
  const [dateNow, setDateNow] = useState(1);
  const [exportData, SetExportData] = useState([]);

  

  useEffect(() => {
    fetchData({
      isManage: "",
      search: "",
      conote_status: shipmentStatus,
      start_date: startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
      end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
      page: pageNo,
      number_list: perPage,
      cust_code: user.username,
      cod_status: "",
      search_name: "",
      national_id: "",
      sender_phone: ""
    });
    // fetchData({
    //   shipmentStatus: shipmentStatus,
    //   empCode: user.username,
    //   pageNo: pageNo,
    //   perPage: perPage,
    // });
  }, []);

  async function fetchData(params) {
    Loading(1);
    let result = await ConoteService(params);
    if (result != undefined) {
      if (result.data.length !== 0) {
      setData(result.data);
        setPage({
          current_page: result.pg[0].current_page??0,
          last_page: result.pg[0].last_page??0,
          total: result.pg[0].total??0,
        });
      }
      if (result.data.length !== 0) {
        // SetError("form-control");
        // SetErrorMass(0);
        setErrorMassNoData(0);
      }
      if (result.data.length === 0) {
        setPage({
          current_page: 1,
          last_page: 1,
          total: 0,
        });
        setErrorMassNoData(1);
      }
      if (result != null) {
        setData(result.data);
      }
    } else {
      alert("Server Error");
    }
    Loading(0);
    
    // if (res.numRowsAll > 0) {
    //   // const dataMerged = 
    //   // alert(res.result.map(item1 => ({ ...item1, ...res.sizes.find(item2 => item2.awb_code === item1.awb_code) })))
    //   // setData(res.result.map(item1 => ({ ...item1, ...res.sizes.find(item2 => item2.awb_code === item1.AWB_CODE) })));
    //   setData(res.result);
    //   setPage({
    //     current_page: res.pageNo,
    //     last_page: res.maxPage,
    //     total: res.numRowsAll,
    //   });
    //   setErrorMassNoData(0);
    //   Loading(0);
    // } else {
    //   setCurrentPage(1);
    //   setPage({
    //     current_page: 1,
    //     last_page: 1,
    //     total: 0,
    //   });
    //   setErrorMassNoData(1);
    //   Loading(0);
    // }
  }

  async function fetchDataExport(params) {
    Loading(1);
    let result = await ConoteService(params);
    if (result !== undefined) {
      if (result.data.length !== 0) {
        SetExportData(result.data);
        setErrorMassNoData(0);
      }
      if (result != null) {
        SetExportData(result.data);
      }
    } else {
      alert("Server Error");
    }
    Loading(0);
  }

  const handleChangeShipmentStatus = (e) => {
    setData([]);
    setErrorMassNoData([])
    setShipmentStatus(e);
    fetchData({
      isManage: "",
      search: "",
      conote_status: e,
      start_date: startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
      end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
      page: pageNo,
      number_list: perPage,
      cust_code: user.username,
      cod_status: "",
      search_name: "",
      national_id: "",
      sender_phone: ""
    });
  };

  const handleChangePageSize = (e) => {
    setData([]);
    setErrorMassNoData([])
    setPageSize(e);
    fetchData({
      isManage: "",
      search: "",
      conote_status: shipmentStatus,
      start_date: startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
      end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
      page: pageNo,
      number_list: e,
      cust_code: user.username,
      cod_status: "",
      search_name: "",
      national_id: "",
      sender_phone: ""
    });
  };

  const handleChangePage = (e, newPage) => {
    setData([]);
    setErrorMassNoData([])
    setCurrentPage(newPage);
    fetchData({
      isManage: "",
      search: "",
      conote_status: shipmentStatus,
      start_date: startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
      end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
      page: newPage,
      number_list: perPage,
      cust_code: user.username,
      cod_status: "",
      search_name: "",
      national_id: "",
      sender_phone: ""
    });
  };
  const { t } = useTranslation();
  async function insertData(params) {
    console.log("params", params)
      try {
        let result = await UpdateConoteStatusService(params);
        if (result.status === 0) {
          swalError();
        } else {
          setData([]);
          setErrorMassNoData([])
          fetchData({
            isManage: "",
            search: "",
            conote_status: shipmentStatus,
            start_date: startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
            end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
            page: pageNo,
            number_list: perPage,
            cust_code: user.username,
            cod_status: "",
            search_name: "",
            national_id: "",
            sender_phone: ""
          });
      }
    }
    catch (e) {
      swalError({
        text: t(e.response.data.message),
      });
    }
  }

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{ 
          ref_no: "",
          status: shipmentStatus,
          empCode: user.username,
        }}
        onSubmit={(value) => {
          value.empCode = user.username;
          insertData(value);
          value.ref_no = ""
          document.getElementById("ref_no").value = "";
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className="card mb-5">
              <div className="card-body">
                <div className="container-fluid mt-2 mb-2">
                  <div className="text-center text-title-color">
                    <h2>แสกนคอนโน๊ต</h2>
                  </div>
                  <br />
                  <div className="row ">
                    <div className="col-12 col-md-7">
                      {" "}
                      <div className="form-group ">
                        <label>สถานะพัสดุ</label>
                        <br />
                        {/* <div className="form-check form-check-inline ">
                          <input
                            style={{ width: "20px", height: "20px" }}
                            className="form-check-input"
                            type="radio"
                            name="shipment_status"
                            id="shipment_status1"
                            defaultValue="0"
                            checked={shipmentStatus === "1" ? false : true}
                            onChange={(e) => {
                              // setFieldValue("shipmentStatus", "0");
                              // handleChangeShipmentStatus("0");
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="shipment_status1"
                          >
                            0. Not yet received
                          </label>
                        </div> */}
                        <div className="form-check form-check-inline ">
                          <input
                            style={{ width: "20px", height: "20px" }}
                            className="form-check-input"
                            type="radio"
                            name="shipment_status"
                            id="shipment_status1"
                            defaultValue="1"
                            checked={shipmentStatus === "1" ? true : false}
                            onChange={(e) => {
                              setFieldValue("shipmentStatus", e.target.value);
                              handleChangeShipmentStatus(e.target.value);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="shipment_status1"
                          >
                            1. รับเข้าระบบแล้ว
                          </label>
                        </div>
                        
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="form-group">
                        <label htmlFor="awbCode">เลขบาร์โค้ด</label>
                        <input
                          value={values.awbCode}
                          type="text"
                          name="awbCode"
                          id="awbCode"
                          onBlur={handleBlur}
                          className={
                            "form-control " +
                            (getIn(touched, "awbCode")
                              ? getIn(errors, "awbCode")
                                ? "is-invalid"
                                : "is-valid"
                              : "") +
                            ""
                          }
                          autoComplete="offf"
                          onChange={(e) => {
                            setFieldValue("ref_no", e.target.value);
                            setRef_no(e.target.value);
                          }}
                        />
                        <ErrorMessage
                          component="div"
                          name="awbCode"
                          className="invalid-feedback"
                        />
                      </div>
                      
                    </div>
                    <div className="col-12 col-md-1 text-center mt-2">
                      <div className="form-group mt-4 ">
                        <button className="btn btn-success" type="submit">
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-12 col-lg-4">
                    <span>ตั้งแต่วันที่แสกน</span>
                    <div className="row">
                      <div className="col-12 col-sm-4 col-md-4 px-md-1 mt-1">
                        <select
                          className={errorStartdate}
                          value={startdate1}
                          onChange={(e) => {
                            SetStart1(e.target.value);
                          }}
                        >
                          <option value=""> -- ปี -- </option>
                          {years.map((year, index) => (
                            <option key={`year${index}`} value={year}>
                              {year + 543}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                        <select
                          className={errorStartdate}
                          value={startdate2}
                          onChange={(e) => {
                            SetStart2(e.target.value);
                          }}
                        >
                          <option value=""> -- เดือน -- </option>
                          {monthTH.map((item, index) => (
                            <option value={item.id} key={item.id}>
                              {item.monthName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                        <select
                          className={errorStartdate}
                          value={startdate3}
                          onChange={(e) => {
                            SetStart3(e.target.value);
                          }}
                        >
                          <option value=""> -- วัน -- </option>
                          {Array.from(Array(31), (e, i) => (
                            <option value={i + 1}>{i + 1}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {errorStartMass == 1 && errorMassSta != 1 && errorMassSta != 2 && errorMassSta != 3 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณาเลือก ปี, เดือน, วัน{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    </div>

                    <div className="col-12 col-lg-4">
                      <span>ถึงวันที่แสกน</span>
                      <div className="row">
                        <div className="col-12 col-sm-4 px-md-1 mt-1">
                          <select
                            className={errorEnddate}
                            value={enddate1}
                            onChange={(e) => {
                              SetEnd1(e.target.value);
                            }}
                          >
                            <option value=""> -- ปี -- </option>
                            {years.map((year, index) => (
                              <option key={`year${index}`} value={year}>
                                {year + 543}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-4  px-md-1 mt-1">
                          <select
                            className={errorEnddate}
                            value={enddate2}
                            onChange={(e) => {
                              SetEnd2(e.target.value);
                            }}
                          >
                            <option value=""> -- เดือน -- </option>
                            {monthTH.map((item, index) => (
                              <option value={item.id} key={item.id}>
                                {item.monthName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-4  px-md-1 mt-1">
                          <select
                            className={errorEnddate}
                            value={enddate3}
                            onChange={(e) => {
                              SetEnd3(e.target.value);
                            }}
                          >
                            <option value=""> -- วัน -- </option>
                            {Array.from(Array(31), (e, i) => (
                              <option value={i + 1} key={i}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {errorEndMass == 1 && errorMassSta != 1 && errorMassSta != 2 && errorMassSta != 3 ? (
                        <font color="red" size="2">
                          {" "}
                          กรุณาเลือก ปี, เดือน, วัน{" "}
                        </font>
                      ) : (
                        ""
                      )}
                    </div>

                  </div>

                  <hr className="mt-4 mb-4" />
                  <h4>รายการรับสินค้า</h4>
                  <FilterData
                    perPage={perPage}
                    handleChangePageSize={handleChangePageSize}
                  />
                  <ShowData
                    data={data}
                    page={page}
                    loading={loading}
                    errorMassNoData={errorMassNoData}
                    pageNo={pageNo}
                    handleChangePage={handleChangePage}
                    perPage={perPage}
                    fetchDataExport={fetchDataExport}
                    user={user}
                    shipmentStatus={shipmentStatus}
                    startdate1={startdate1}
                    startdate2={startdate2}
                    startdate3={startdate3}
                    enddate1={enddate1}
                    enddate2={enddate2}
                    enddate3={enddate3}
                    exportData={exportData}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(ScanConote);
