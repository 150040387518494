import React from "react";
import { nanoid } from "nanoid";
import LOGO from "../../../Assets/images/logo/logoNavbar.png";
import DateTH from '../../../Helper/date-th';
import {connect} from "react-redux";

function PrintContent({ data, startDate, endDate, branchs, username }) {
  const hideCost = localStorage.getItem('show_shipment_cost') === "0" && localStorage.getItem('superkey') === 'credit';
  const service = localStorage.getItem('superkey') === 'service';
  const staff = localStorage.getItem('superkey') === 'staff';
  var date = new Date();
  var amont = 0;
  var shipmentSum = 0;
  var codSum = 0;
  var servicSum = 0;
  data.map((value) => {
    amont = amont + parseInt(value.num_parcel);
    shipmentSum = shipmentSum + parseInt(value.shipment_cost);
    codSum = parseInt(value.cod_cost) === 0 || parseInt(value.cod_cost) > 0 ? codSum + parseInt(value.cod_cost) : codSum + 0;
    servicSum = servicSum + parseInt(value.service_cod);
  });

  return (
    <div style={{ margin: "15px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <div style={{ justifyContent: "start" }}>
          <img src={LOGO} alt="logo porlor" style={{ width: "150px", height: "70px" }} />
          {/* <b>วันที่พิมพ์ : </b>{" "}
          {(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
            "/" +
            (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
            "/" +
            date.getFullYear()}{" "}
          <br />
          <b>วันที่รับสินค้า : </b> {startDate} ถึงวันที่ {endDate}
          <br />
          <b>รหัสลูกค้า : {branchs} </b> */}
        </div>
        <div style={{ justifyContent: "center" }}></div>
        <div style={{ textAlign: "center" }}>
        <h1>{data[0]?.sender_name}</h1>
      </div>
        <div style={{ justifyContent: "end" }}>
          <div style={{ marginTop: "10px" }}>
            <h2>{username}</h2></div>
        </div>
      </div>
      <table className="table">
          <thead className="table-blue">
            <tr>
            <th style={{ textAlign: "center" }}>ลำดับ</th>
                  <th style={{ textAlign: "center" }}>ว/ด/ป</th>
                  <td>บาร์โค้ด/สำหรับลูกค้า</td>
                  <td>CONOTE</td>
                  <td>เอกสาร</td>
                  <td>บรษดทผรบ</td>
                  <td>จดงหวด</td>
                  <td>วนทททรบ</td>
                  <td>หมายเหต/ลกคา</td>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={index+"printConote"}>
                    <td>{index + 1}</td>
                    <td><DateTH date={value.date_conote_status??""} /></td> 
                    <td>{value.array_awb_codes.map((value2)=> (
                      <p>{value2}</p>
                ))}</td>
                    <td>
                      <p>{value.ref_no === null || value.ref_no === '0' ? " " : value.ref_no}</p>
                      {/* <p>{value.parcel_number} parcels</p> */}
                    </td>
                    <td>{value.num_return_doc}</td>
                    <td>{value.receiver_name}</td>
                    <td>{value.receiver_province}</td>
                    <td><DateTH date={value.receiver_date} /></td>
                    <td>{value.remarkMg}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "70px" }}>
        <div style={{ marginRight: "50%", textAlign: "center" }}>
        พนกงาน PORLOR ผนาสงเอกสาร
        </div>
        <div style={{ textAlign: "center" }}>
        กรณาลงชอแลวสงกลบ PORLOR 1 ชด
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ marginRight: "50%", textAlign: "center" }}>
          ลงชื่อ​ผู้ส่ง​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.
          <p>........../........../..........</p>
        </div>
        <div style={{ textAlign: "center" }}>
          ลงชื่อ​ผู้​ขน​ส่ง​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.​.
          <p>........../........../..........</p>​
        </div>
      </div>
      <div style={{ display: "flex" }}>
          
      </div>
      
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(PrintContent);
