import React, { useState, useEffect } from "react";
import { nanoid } from "nanoid";
import * as Icon from "react-bootstrap-icons";
import { monthTH } from "../../../Data/month-th.json";
import Select from "react-dropdown-select";
import { GetAllShop } from "../../../Service/service.Shops";
import { GetAllEmpService } from "../../../Service/service.Employee";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import stockSchema from "./validateStock";
import ShowData from "./showData";
import { connect } from "react-redux";
import { ReportStockIn } from "../../../Service/service.reportStock";
import DropdownFilter from "../../../Components/DropdowSearch"
import FormReactSelect from "../../../Components/FormReactSelect"
import {updateRetriever} from "./reportStock.service";
import swal from "sweetalert";
import { Typeahead } from 'react-bootstrap-typeahead';

function FilterData({ user }) {
  const [ref_no_retrieved, setRef_no_retrieved] = useState('');
  const [retriever_update,setRetriever_update] = useState(false);
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = ("0" + (new Date().getMonth() + 1)).slice(-2);
  var date = ("0" + new Date().getDate()).slice(-2);
  const [branch, setBranch] = useState([]);
  const [param, SetParam] = useState({});
  const [emp, SetEmp] = useState([]);
  const [data, setData] = useState([]);
  const [pageSizes, PageSizes] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });
  const [errorMassNoData, setErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);

  useEffect(() => {
    LoadEmp();
    if (localStorage.getItem("roleType") === "3") {
      filterCategoryCus("");
    }
    if (localStorage.getItem("roleType") === "2") {
      filterCategoryCus("");
    }
  }, []);

  async function LoadEmp() {
    let result = await GetAllEmpService("");
    if (result.statusCode === "001") {
      SetEmp(result.data);
    } else {
      SetEmp([]);
    }
  }

  async function filterCategoryCus(id) {
    let result = await GetAllShop(id);
    if (result.statusCode === "001") {
      setBranch(result.data);
    } else {
      setBranch([]);
    }
  }

  async function loadData(value) {
    Loading(1);
    setData([]);
    let res = await ReportStockIn(value);
    if (res !== false) {
      setData(res.result);
      setPage({
        current_page: res.pageNo,
        last_page: res.maxPage,
        total: res.numRowsAll,
      });
      setErrorMassNoData(0);
    } else {
      setData([]);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      setErrorMassNoData(1);
    }
    Loading(0);
  }
  const saveRetriever = () => {
    setRetriever_update(true);
    updateRetriever(localStorage.getItem("id"),ref_no_retrieved)
        .then(result => {
          setRetriever_update(false);
          if(result.data.data > 0){
            swal({
              title: 'พัสดุได้รับการปรับปรุง',
              text: '',
              icon: 'success',
              showConfirmButton: false,
              button: 'ปิด',
              timer: 1500,
            });
          }
          else{
            swal({
              title: 'ไม่มีแถวที่อัปเดต',
              text: '',
              icon: 'warning',
              showConfirmButton: false,
              button: 'ปิด',
              timer: 1500,
            });
          }
        })
        .catch(error => {
          setRetriever_update(false);
          swal({
            title: 'เกิดข้อผิดพลาด',
            text: '',
            icon: 'warning',
            showConfirmButton: false,
            button: 'ปิด',
            timer: 1500,
          });
        })
  };
  return (
    <div>
      <Formik
        validationSchema={stockSchema}
        initialValues={{
          startDay: date,
          startMonth: month,
          startYear: year,
          // startDate: year + "-" + month + "-" + date,
          startTime: "00:00",
          endDay: date,
          endMonth: month,
          endYear: year,
          // endDate: year + "-" + month + "-" + date,
          endTime: "00:00",
          parcelstutus: "1",
          category: localStorage.getItem("roleType") === "3" ? "1" : localStorage.getItem("roleType") === "2" ? "4" : "",
          branch: localStorage.getItem("roleType") === "3" ? user.username : localStorage.getItem("roleType") === "2" ? user.username : "",
          stockType: localStorage.getItem("roleType") === "5" ? "2" : "1",
          transportWorker: localStorage.getItem("roleType") === "5" ? user.username : "",
          businessType: "1",
        }}
        onSubmit={(value) => {
          loadData({
            pageNo: 1,
            perPage: pageSizes,
            custCode: value.branch,
            stockType: value.stockType,
            startDate: value.startYear + "-" + value.startMonth + "-" + value.startDay,
            startTime: value.startTime,
            endDate: value.endYear + "-" + value.endMonth + "-" + value.endDay,
            endTime: value.endTime,
            transportWorker: value.transportWorker,
            custType: value.category,
            action: "show",
            businessType: value.businessType,
          });
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className={localStorage.getItem("superkey") !=="credit" ? "row" : "hidden-ct"}>
              <div className={localStorage.getItem("superkey") !=="credit" ? "col-sm-6 px-md-3" : "hidden-ct"}>
                <span>ประเภททะเบียนคุม</span>
                <br />

                <div className="form-check form-check-inline ">
                  <input
                    disabled={localStorage.getItem("roleType") === "5" ? true : false}
                    style={{ width: "20px", height: "20px" }}
                    className={`form-check-input ${getIn(touched, "stockType") ? (getIn(errors, "stockType") ? "is-invalid" : "is-valid") : ""
                      }`}
                    type="radio"
                    name="stockType"
                    id="stockType1"
                    value="1"
                    checked={values.stockType === "1"}
                    onChange={(e) => {
                      setFieldValue("stockType", e.target.value);
                    }}
                  />
                  <label className="form-check-label" htmlFor="stockType1">
                    ทะเบียนคุมขาเข้า
                  </label>
                </div>
                <div className="form-check form-check-inline ">
                  <input
                    disabled={localStorage.getItem("roleType") === "3" || localStorage.getItem("roleType") === "2" || localStorage.getItem("superkey") ==="1947"? true : false}
                    style={{ width: "20px", height: "20px" }}
                    className={
                      "form-check-input " +
                      (getIn(touched, "stockType") ? (getIn(errors, "stockType") ? "is-invalid" : "is-valid") : "") +
                      ""
                    }
                    type="radio"
                    name="stockType"
                    value="2"
                    id="stockType2"
                    checked={values.stockType === "2"}
                    onChange={(e) => {
                      setFieldValue("stockType", e.target.value);
                      setFieldValue("category", "");
                      setFieldValue("branch", "");
                    }}
                  />
                  <label className="form-check-label" htmlFor="stockType2">
                    ทะเบียนคุมขาออก
                  </label>
                </div>
                <ErrorMessage component="div" name="stockType" className="invalid-feedback" />
              </div>
              {/* <div  className={localStorage.getItem("superkey") ==="1947" || localStorage.getItem("superkey") ==="credit" || localStorage.getItem("superkey") ==="shop"  ? "hidden-ct" : "col-sm-6 px-md-3"}>
                <span>ประเภทธุรกิจ</span>
                <br />
                <div className="form-check form-check-inline ">
                  <input
                    style={{ width: "20px", height: "20px" }}
                    className={`form-check-input ${getIn(touched, "businessType") ? (getIn(errors, "businessType") ? "is-invalid" : "is-valid") : ""
                      }`}
                    type="radio"
                    name="businessType"
                    id="businessType1"
                    value="1"
                    checked={values.businessType === "1"}
                    onChange={(e) => {
                      setFieldValue("businessType", e.target.value);
                    }}
                  />
                  <label className="form-check-label" htmlFor="businessType1">
                    ปอลอ เอ็กเพรส
                  </label>
                </div>
                <div className="form-check form-check-inline ">
                  <input
                    style={{ width: "20px", height: "20px" }}
                    className={
                      "form-check-input " +
                      (getIn(touched, "businessType") ? (getIn(errors, "businessType") ? "is-invalid" : "is-valid") : "") +
                      ""
                    }
                    type="radio"
                    name="businessType"
                    value="2"
                    id="businessType2"
                    checked={values.businessType === "2"}
                    onChange={(e) => {
                      setFieldValue("businessType", e.target.value);
                    }}
                  />
                  <label className="form-check-label" htmlFor="businessType2">
                    ไปรษณีย์ไทย
                  </label>
                </div>
                <ErrorMessage component="div" name="businessType" className="invalid-feedback" />
              </div> */}
            </div>
            <br />
            <div className="row">
              <div className={values.stockType === "1" ? " hidden-ct " : "col-sm-6 px-md-3"}>
                <span>พนักงานขนส่ง</span>
                <div className={localStorage.getItem("roleType") === "5" ? "hidden-ct" : ""}>
                  <FormReactSelect
                    touched={touched}
                    errors={errors}
                    data={emp}
                    values={values.transportWorker}
                    onChange={(value) => {
                      setFieldValue(`transportWorker`, value);
                    }}
                    name="transportWorker"
                    labelKey="empName"
                    keyValue="emp_code"
                    errorsMess="กรุณาเลือกพนักงานขนส่ง"
                    placeholder="-- กรุณาเลือกพนักงานขนส่ง --"
                  />
                </div>
                <select
                  className={
                    localStorage.getItem("roleType") === "5" ? "form-control  dropdown-custom2 " +
                      (getIn(touched, "transportWorker")
                        ? getIn(errors, "transportWorker")
                          ? "is-invalid"
                          : "is-valid"
                        : "") +
                      "" : "hidden-ct"
                  }
                  onBlur={handleBlur}
                  name="transportWorker"
                  id="transportWorker"
                  disabled={localStorage.getItem("roleType") === "5"}
                  value={values.transportWorker}
                  onChange={(e) => {
                    setFieldValue("transportWorker", e.target.value);
                  }}
                >
                  <option value="" key={""}>
                    -- กรุณาระบุพนักงานขนส่ง --
                  </option>
                  {emp.map((v, i) => (
                    <option value={v.emp_code} key={i}>
                      {v.empName}
                    </option>
                  ))}
                </select>
                <ErrorMessage component="div" name="transportWorker" className="invalid-feedback" />
              </div>
              {/* <div className={values.stockType !== "2" || localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "22" ? "col-sm-6 px-md-3":" hidden-ct " }> */}
              <div className={ localStorage.getItem("roleType") === "1" || localStorage.getItem("roleType") === "22" || localStorage.getItem("roleType") === "13" ? "col-sm-6 px-md-3" : "hidden-ct "}>
                <span>ประเภทลูกค้า</span>
                <select
                  disabled={localStorage.getItem("roleType") === "3" || localStorage.getItem("roleType") === "2" ? true : false}
                  value={values.category}
                  className={
                    "form-control  dropdown-custom2 px-md-1 " +
                    (getIn(touched, "category") ? (getIn(errors, "category") ? "is-invalid" : "is-valid") : "") +
                    ""
                  }
                  onBlur={handleBlur}
                  name="category"
                  id="category"
                  onChange={(e) => {
                    filterCategoryCus(e.target.value);
                    setFieldValue("category", e.target.value);
                    setFieldValue("branch", "");
                  }}
                >
                  <option value="" key={"0"}>
                    -- กรุณาเลือกประเภท --
                  </option>
                  <option value="1" key={"1"}>
                    หน้าร้าน
                  </option>
                  <option value="2" key={"2"}>
                    หน้าร้าน (ศูนย์กระจายสินค้า)
                  </option>
                  <option value="3" key={"3"}>
                    แฟรนไซส์
                  </option>
                  <option value="4" key={"4"}>
                    ลูกค้าเครดิต
                  </option>
                </select>
                <ErrorMessage component="div" name="category" className="invalid-feedback" />
              </div>
              <div className={values.stockType === "2" || localStorage.getItem("superkey")==="1947" ? " hidden-ct " : "col-sm-6 px-md-3"}>
                <span>สาขา </span>
                <div className={localStorage.getItem("roleType") === "3" || localStorage.getItem("roleType") === "2" ? "hidden-ct" : ""}>
                {/* <select
                  className={"form-control  dropdown-custom2 px-md-1 " +
                      (getIn(touched, "branch") ? (getIn(errors, "branch") ? " is-invalid" : " is-valid") : "") +
                      "" }
                  value={values.branch}
                  onBlur={handleBlur}
                  name="branch"
                  id="branch"
                  onChange={(e) => {
                    setFieldValue("branch", e.target.value);
                  }}
                >
                  <option value="" key={"c"}>
                    -- กรุณาเลือกสาขา--
                  </option>
                  {branch.map((data, index) => (
                    <option value={data.shop_code} key={index}>
                    {data.shop_code} {data.shop_name}
                    </option>
                  ))}
                </select> */}
                <Typeahead
                  id="branch"
                  name="branch"
                  labelKey={(branch) => `${branch.shop_code} ${branch.shop_name}`}
                  onChange={(e) => {
                    if (e.length > 0) {
                      let value = e[0].shop_code;
                      setFieldValue('branch', value);
                    }
                  }}
                  onInputChange={(text, e) => {
                    setFieldValue('branch', '');
                  }}
                  options={branch}
                  placeholder="-- กรุณาระบุสาขา --"
                />
                </div>
                <select
                  disabled={localStorage.getItem("roleType") === "3" || localStorage.getItem("roleType") === "2" ? true : false}
                  className={
                    localStorage.getItem("roleType") === "3" || localStorage.getItem("roleType") === "2" ? "form-control  dropdown-custom2 px-md-1 " +
                      (getIn(touched, "branch") ? (getIn(errors, "branch") ? " is-invalid" : " is-valid") : "") +
                      "" : "hidden-ct"
                  }
                  value={values.branch}
                  onBlur={handleBlur}
                  name="branch"
                  id="branch"
                  onChange={(e) => {
                    setFieldValue("branch", e.target.value);
                  }}
                >
                  <option value="" key={"c"}>
                    -- กรุณาเลือกสาขา--
                  </option>
                  {user.username === "Test" ? (
                    <option value="Test" key={"test"}>
                      Test
                    </option>
                  ) : (
                    ""
                  )}
                  {branch.map((data, index) => (
                    <option value={data.shop_code} key={index}>
                    {data.shop_code} {data.shop_name}
                    </option>
                  ))}
                </select>
              </div>
              {localStorage.getItem('superkey') === 'shop' && <div className="col-sm-6 px-md-1">
                <div className="form-group">
                  <span>เลขบาร์โค้ดลูกค้าทั่วไป</span>
                  <div className="row">
                    <div className="col-md-10">
                      <input className="form-control"
                             disabled={retriever_update}
                             id="shop_retriever"
                             value={ref_no_retrieved}
                             onChange={(e) => setRef_no_retrieved(e.target.value)}/>
                    </div>
                    <div className="col-md-2">
                      <button
                          disabled={retriever_update}
                          onClick={(e) => saveRetriever()}
                          type="button" className="btn btn-success">
                         บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </div>}
            </div>
            <br />

            <div class="row">
              <div className="col-12 col-lg-6 px-md-4">
                <span>ตั้งแต่วันที่เปิดบิล</span>
                <div className="row">
                  <div className="col-12 col-sm-3 col-md-3 px-md-1 mt-1">
                    <select
                      name="startYear"
                      className={
                        "form-control dropdown-custom2 px-md-1 " +
                        (getIn(touched, "startYear") ? (getIn(errors, "startYear") ? "is-invalid" : "is-valid") : "") +
                        ""
                      }
                      value={values.startYear}
                      onChange={(e) => {
                        setFieldValue("startYear", e.target.value);
                      }}
                    >
                      <option value=""> -- ปี -- </option>
                      {years.map((year, index) => (
                        <option key={`year${index}`} value={year}>
                          {year + 543}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage component="div" name="startYear" className="invalid-feedback" />
                  </div>
                  <div className="col-12 col-sm-3 col-md-3  px-md-1 mt-1">
                    <select
                      name="startMonth"
                      className={
                        "form-control dropdown-custom2 px-md-1 " +
                        (getIn(touched, "startMonth") ? (getIn(errors, "startMonth") ? "is-invalid" : "is-valid") : "") +
                        ""
                      }
                      value={values.startMonth}
                      onChange={(e) => {
                        setFieldValue("startMonth", e.target.value);
                      }}
                    >
                      <option value=""> -- เดือน -- </option>
                      {monthTH.map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.monthName}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage component="div" name="startMonth" className="invalid-feedback" />
                  </div>
                  <div className="col-12 col-sm-3 col-md-3  px-md-1 mt-1">
                    <select
                      name="startDay"
                      className={
                        "form-control dropdown-custom2 px-md-1 " +
                        (getIn(touched, "startDay") ? (getIn(errors, "startDay") ? "is-invalid" : "is-valid") : "") +
                        ""
                      }
                      value={values.startDay}
                      onChange={(e) => {
                        setFieldValue("startDay", e.target.value);
                      }}
                    >
                      <option value=""> -- วัน -- </option>
                      {Array.from(Array(31), (e, i) => (
                        <option value={i + 1 < 10 ? "0" + (i + 1) : i + 1}>{i + 1}</option>
                      ))}
                    </select>
                    <ErrorMessage component="div" name="startDay" className="invalid-feedback" />
                  </div>
                  <div className="col-12 col-sm-3 col-md-3  px-md-1 mt-1">
                    <input
                      className={
                        "form-control dropdown-custom2 px-md-1 " +
                        (getIn(touched, "startTime") ? (getIn(errors, "startTime") ? "is-invalid" : "is-valid") : "") +
                        ""
                      }
                      type="time"
                      id="startTime"
                      name="startTime"
                      value={values.startTime}
                      onChange={(e) => {
                        setFieldValue("startTime", e.target.value);
                      }}
                    />
                    <ErrorMessage component="div" name="startTime" className="invalid-feedback" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 px-md-4">
                <span>ถึงวันที่เปิดบิล</span>
                <div className="row">
                  <div className="col-12 col-sm-3 px-md-1 mt-1">
                    <select
                      name="endYear"
                      className={
                        "form-control dropdown-custom2 px-md-1 " +
                        (getIn(touched, "endYear") ? (getIn(errors, "endYear") ? "is-invalid" : "is-valid") : "") +
                        ""
                      }
                      value={values.endYear}
                      onChange={(e) => {
                        setFieldValue("endYear", e.target.value);
                      }}
                    >
                      <option value=""> -- ปี -- </option>
                      {years.map((year, index) => (
                        <option key={`year${index}`} value={year}>
                          {year + 543}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage component="div" name="endYear" className="invalid-feedback" />
                  </div>
                  <div className="col-12 col-sm-3  px-md-1 mt-1">
                    <select
                      name="endMonth"
                      className={
                        "form-control dropdown-custom2 px-md-1 " +
                        (getIn(touched, "endMonth") ? (getIn(errors, "endMonth") ? "is-invalid" : "is-valid") : "") +
                        ""
                      }
                      value={values.endMonth}
                      onChange={(e) => {
                        setFieldValue("endMonth", e.target.value);
                      }}
                    >
                      <option value=""> -- เดือน -- </option>
                      {monthTH.map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.monthName}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage component="div" name="endMonth" className="invalid-feedback" />
                  </div>
                  <div className="col-12 col-sm-3  px-md-1 mt-1">
                    <select
                      name="endDay"
                      className={
                        "form-control dropdown-custom2 px-md-1 " +
                        (getIn(touched, "endDay") ? (getIn(errors, "endDay") ? "is-invalid" : "is-valid") : "") +
                        ""
                      }
                      value={values.endDay}
                      onChange={(e) => {
                        setFieldValue("endDay", e.target.value);
                      }}
                    >
                      <option value=""> -- วัน -- </option>
                      {Array.from(Array(31), (e, i) => (
                        <option value={i + 1 < 10 ? "0" + (i + 1) : i + 1} key={i}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage component="div" name="endDay" className="invalid-feedback" />
                  </div>
                  <div className="col-12 col-sm-3  px-md-1 mt-1">
                    <input
                      className={
                        "form-control dropdown-custom2 px-md-1 " +
                        (getIn(touched, "endTime") ? (getIn(errors, "endTime") ? "is-invalid" : "is-valid") : "") +
                        ""
                      }
                      type="time"
                      id="endTime"
                      name="endTime"
                      value={values.endTime}
                      onChange={(e) => {
                        setFieldValue("endTime", e.target.value);
                      }}
                    />
                    <ErrorMessage component="div" name="endTime" className="invalid-feedback" />
                  </div>
                </div>
              </div>
            </div>

            {/* /////////////////////////////////////////////////////////////////////////////////////// */}
            <br />
            <div className="mt-1  d-flex  justify-content-center">
              <div className="p-3">
                <button type="submit" className="btn btn-primary">
                  <Icon.Search /> ค้นหา
                </button>{" "}
                <button
                  type="reset"
                  className="btn btn-secondary"
                  onClick={() => {
                    setData([]);
                    setErrorMassNoData(0);
                    if (localStorage.getItem("roleType") === "3" || localStorage.getItem("roleType") === "2") {
                      setFieldValue("startDay", date);
                      setFieldValue("startMonth", month);
                      setFieldValue("startYear", year);
                      // setFieldValue("startDate", year + "-" + month + "-" + date);
                      setFieldValue("startTime", "00:00");
                      setFieldValue("endDay", date);
                      setFieldValue("endMonth", month);
                      setFieldValue("endYear", year);
                      // setFieldValue("endDate", year + "-" + month + "-" + date);
                      setFieldValue("endTime", "00:00");
                      setFieldValue("stockType", "1");
                      setFieldValue("businessType", "1");
                      setFieldValue("transportWorker", localStorage.getItem("roleType") === "5" ? user.username : "");
                    } else {
                      setFieldValue("category", "");
                      setFieldValue("branch", "");
                      setFieldValue("startDay", date);
                      setFieldValue("startMonth", month);
                      setFieldValue("startYear", year);
                      // setFieldValue("startDate", year + "-" + month + "-" + date);
                      setFieldValue("startTime", "00:00");
                      setFieldValue("endDay", date);
                      setFieldValue("endMonth", month);
                      setFieldValue("endYear", year);
                      // setFieldValue("endDate", year + "-" + month + "-" + date);
                      setFieldValue("endTime", "00:00");
                      setFieldValue("stockType", "1");
                      setFieldValue("businessType", "1");
                      setFieldValue("transportWorker", localStorage.getItem("roleType") === "5" ? user.username : "");
                    }
                  }}
                >
                  <Icon.ArrowClockwise /> ล้างค่า
                </button>
              </div>
            </div>
            <br />
            <div>
              {data.length > 0 ? (
                <ShowData
                  data={data}
                  values={values}
                  loadData={loadData}
                  page={page}
                  currentPage={currentPage}
                  setcurrentPage={setcurrentPage}
                  errorMassNoData={errorMassNoData}
                  loading={loading}
                  pageSizes={pageSizes}
                  PageSizes={PageSizes}
                  setData={setData}
                />
              ) : (
                <>
                  <center>
                    {errorMassNoData === 1 && loading !== 1 ? (
                      <font color="red" size="4">
                        <br />
                        ไม่พบข้อมูล!
                        <br />
                      </font>
                    ) : (
                      ""
                    )}
                  </center>
                  {loading === 1 ? (
                    <div className="m-2">
                      <div className="text-center">
                        <div className="spinner-border  text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        {" ..."}
                        กำลังโหลดข้อมูล
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div >
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(FilterData);
