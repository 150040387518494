import {useState} from "react";
import Instance from "../../../../Helper/axios";

const useHubById = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const fetch = async (idEmp) => {
        setData(null);
        setErrorMessage(null);
        setLoading(true);
        try{
            const result = await Instance.get(`/hub/HubController/getHubById?emp=${idEmp}`);
            setData(result.data.result);
        }
        catch (error){
            setErrorMessage(error);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        data,
        loading,
        errorMessage,
        fetch
    }
}

export default useHubById;