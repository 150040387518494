import Instance from '../Helper/axios';
import { URL, URLTEST } from '../Helper/baseURL';

export async function ParcelService(data) {
    try {
        const response = await Instance.post(URL + 'parcel/Parcel/saveParcels', data);
        return await response.data;
    } catch (error) {
        console.log('error', error);
    }
}

export async function ParcelServicePickUp(data) {
    try {
        const response = await Instance.post(URL + 'parcel/Parcel/saveParcelsPickUp', data);
        return await response.data;
    } catch (error) {
        console.log('error', error);
    }
}

export async function UpdatePrintStatus(data) {
    try {
        const response = await Instance.post(URL + 'parcel/Parcel/UpdatePrintStatus', data);
        return await response.data;
    } catch (error) {
        console.log('error', error);
    }
}

export async function UpdateReceiptPrint(data) {
    try {
        const response = await Instance.post(URL + 'parcel/Parcel/UpdateReceiptPrint', data);
        return await response.data;
    } catch (error) {
        console.log('error', error);
    }
}

export async function CheckCitizen(data) {
    try {
        const response = await Instance.post(URL + 'parcel/Parcel/checkCitizen', data);
        return await response.data;
    } catch (error) {
        console.log('error', error);
    }
}

export async function CheckReceiver(phoneNumber) {
    try {
        const response = await Instance.post(URL + 'parcel/Parcel/checkReceiver', {
            phoneNumber,
        });
        return await response.data;
    } catch (error) {
        console.log('error', error);
    }
}

export async function CheckSize(cust_code,size_name) {
    try {
        const response = await Instance.post(URL + 'parcel/Parcel/checkSize', {
            cust_code,
            size_name,
        });
        return await response.data;
    } catch (error) {
        console.log('error', error);
    }
}

export async function updateParcelsRemark(parcel_id, remark) {
    const response = await Instance.post(URL + 'parcel/Parcel/updateRemark', {
        id: parcel_id,
        remark,
        cust_code: localStorage.getItem('username'),
    });
    return response.data;
}

export function remarkHistoryList(parcel_id,page) {
    return Instance.post(URL + 'remark_history/RemarkHistory/listRemarks', {
        parcel_id,
        page,
    });
}

export async function updateReceiverDateService(parcel_id, receiverDate) {
    const response = await Instance.post(URL + 'parcel/Parcel/updateReceiverDate', {
        id: parcel_id,
        receiverDate,
    });
    return response.data;
}


export const calculatedPrice = (size, weight, cod, district) => {
    return Instance.post(URL + 'priceCalculation/PriceCalculation/calculatedPorlorPrice', {
        size,
        weight,
        cod,
        district,
    });
}
export const calculatedPostPrice = (weight, province1, province2) => {
    return Instance.post(URL + 'priceCalculation/PriceCalculation/postPriceCalculation', {
        weight,
        province1,
        province2
    });
};
export const multiplePriceCalculation = (senderProvince, recipients) => {
    return Instance.post(URL + 'priceCalculation/PriceCalculation/multiplePostPriceCalculation', {
        senderProvince,
        recipients
    });
};
export const updateParcelsRef_nos = (codes,ref_no) => {
    return Instance.post(URL + 'manage/Parcels/update_ref_nos', {
        codes,
        ref_no,
    });
};

export const editHistory = (parcel_code,page) => {
    return Instance.get(URL + `manage/EditHistory/getEditHistory/${parcel_code}/${page}`);
}

export const hubCODParcelsCount = () => {
    return Instance.get(URL + `hub/HubCOD/deliveredCOD?distribution_center=${localStorage.getItem('id')}`)
}

export const hubRemainingParcels = (page,keyword) => {
    return Instance.get(URL + `hub/HubCOD/remainingParcels?distribution_center=${localStorage.getItem('id')}&page=${page}&keyword=${keyword}`)
}