import {useCallback, useState} from "react";
import {editHistory} from "../../../../Service/service.Parcels";

const useUpdateHistoryData = () => {
    const [loading, setLoading] = useState(false);
    const [error , setError] = useState(null);
    const [data, setData] = useState(null);

    const fetchData = useCallback(
        async (code,page) => {
            try{
                setLoading(true);
                let result = await editHistory(code,page);
                setData(result.data);
            }
            catch (e) {
                console.error(e);
                setError('An error occured');
            }
            finally {
                setLoading(false);
            }
        },
        [],
    );


    return {
        data,
        error,
        loading,
        fetchData
    }
}

export default useUpdateHistoryData;