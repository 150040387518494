import React, {useState, useEffect} from "react";
import styles from "./style.module.css";
import OneAd from "../ui/one-ad";
import { AdsService } from "../../../../Service/service.ads";
import { swalError } from "../../../../Components/sweetAlert";

const AdList = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        AdsService.activeAds()
            .then(result => {
                setData(result.data.data);
            })
            .catch(error => {
                console.log(error);
                swalError({
                    text: error.error.message
                });
            })
    }, []);

    return (
        <div className={`p-2 ${styles['body']} container`}>
            <div className="row">
                {data && data.map(row => (
                    <div key={row.id} className="col-sm-12 col-lg-4">
                        <OneAd data={row} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdList;