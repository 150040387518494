import Instance from '../Helper/axios';
import { URL, URLTEST } from '../Helper/baseURL';

export async function GetJobAnnouncement(data) {
  try {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await Instance.post(URL + 'career/Career/getJobAnnouncement', formData);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function SaveJobAnnouncement(data) {
  try {
    const response = await Instance.post(URL + 'career/Career/saveJobAnnouncement', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function DeleteJobAnnouncement(data) {
  try {
    const response = await Instance.post(URL + 'career/Career/deleteJobAnnouncement', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function GetPosition() {
  try {
    const response = await Instance.post(URL + 'career/Career/getPosition');
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function GetCental() {
  try {
    const response = await Instance.post(URL + 'career/Career/getCental');
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function SaveCareer(data) {
  try {
    const response = await Instance.post(URL + 'career/Career/saveCareer', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function DeleteCareer(data) {
  try {
    const response = await Instance.post(URL + 'career/Career/deleteData', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function GetApplyData(data) {
  try {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await Instance.post(URL + 'career/Career/getApplyData', formData);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function GetApplyDetail(data) {
  try {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await Instance.post(URL + 'career/Career/getApplyData_detail', formData);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function deleteApplyData(data) {
  try {
    const response = await Instance.post(URL + 'career/Career/deleteApplyData', data);
    return await response.data;
  } catch (error) {
    console.log('error', error);
  }
}
