import Instance from "../Helper/axios";
import { URL, URLTEST, urlLocal } from "../Helper/baseURL";

export async function getParcelsSendBack(param) {
  try {
    const formData = new FormData();
    Object.entries(param).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await Instance.post(URL + "manage/Notification/getParcelsSendBack", formData);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function updateStatusSendBack(data) {
  try {
    const response = await Instance.post(URL + "manage/Notification/updateStatusSendBack", data);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}
