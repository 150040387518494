import React from 'react'


/**
 * @param metadata: example : 
 * {
 *    text: string,
 *    width: string //in pourcentage,
 *    render: (data: T) => ReactNode, 
 * }[]
 * @param keyExtractor: (data: T) => string
 * @param data: T[]
 * @param showHeader: boolean
 * @param roundedBorders: boolean
 * @param transparentHeader: boolean
 * @returns Component
 */
export const GenericTable = ({
    metadata,
    data,
    keyExtractor,
    showHeader = true,
    roundedBorders = false,
    transparentHeader = false,
}) => {

  const emptyData = (
        <></>
  );

  return (
      <div style={{
          overflow: "Auto",
          width: '100%',
          borderRadius: roundedBorders ? '10px' : '0px', overflowX: 'hidden', overflowY: 'auto',
          border: '1px solid #dee2e6',
          maxHeight: '100vh',
          marginTop: '10px'
      }}>
          <table style={{backgroundColor: 'white'}} className="table mb-0">
              {showHeader &&
                  (
                  <thead style={{ backgroundColor: transparentHeader ? "transparent" : "#3f75c1" }}>
                      <tr style={{ color: "#FFFFFF" }}>   
                          {metadata.map(({ text, width }, index) => {
                              const props = {
                                  key: index,
                                  ...(width ? { style: { width } } : {})
                              };
                              return <th {...props}>{text}</th>;
                          })}
                      </tr>    
                    </thead>
                  )
              }
              {data.length &&
                  <tbody>
                    {data.map(row => (
                        <tr key={keyExtractor(row)}>
                            {metadata.map(({ width, ...property }, index) => {
                                    return (
                                        <td onClick={() => property.rowClick && property.rowClick(row)} style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none', borderBottom: '1px solid #dee2e6', width }} key={index}>
                                            {property.render(row)}
                                        </td>
                                    )
                                })
                            }
                        </tr>
                    ))}
                  </tbody>
              }
              {!data.length && emptyData}
          </table>
      </div>
  )
}

export default GenericTable;