import React from "react";
import Button from "react-bootstrap/Button";
import * as Icon from "react-bootstrap-icons";
import { connect } from "react-redux";

function Footer(props) {
  const { lang } = props;
  return lang.language === "th" ? <FooterTH routeTO={props.history} /> : <FooterEN routeTO={props.history} />;
}
const mapStateToProps = (state) => ({
  lang: state.LanguageAction,
});

export default connect(mapStateToProps)(Footer);

function FooterEN(props) {
  const {routeTO} = props;
  return (
    <footer className="footer ">
      <div className="footer-blue border-top-yellow">
        <div className="container">
          <div className="row footer-contact">
            <div className="col-12 col-sm-4 col-lg-4 col-xl-4">
              <h3 className="title-footer">Porlor Express</h3>
              <p>On time, safe in Thailand</p>
              <p>Monday-Saturday: 8.30-18.00</p>
              <div className=" form-group">
                <Button
                  variant="primary-one"
                  onClick={(e) => {
                    routeTO.push("Tracking");
                  }}
                  className="radius"
                >
                  Track Shipments
                </Button> 
              </div>
              <div className=" form-group">
                <Button
                  variant="primary-one"
                  onClick={(e) => {
                    routeTO.push("PriceEstimation");
                  }}
                  className="radius"
                >
                  Price Estimation
                </Button> 
              </div>
              
            </div>
            <div className="col-12 col-sm-4 col-lg-4 col-xl-4">
              <h3 className="title-footer">Our information</h3>
              <ul>
                <li>Our services</li>
                <li>Rates</li>
                <li>About us</li>
              </ul>
            </div>
            <div className="col-12 col-sm-4 col-lg-4 col-xl-4">
              <h3 className="title-footer">Contact us</h3>
              <p>
                99/153 Moo 15 High Bridge Road, <br />
                High Bridge area, Bangkok 10250
              </p>
              <p>
                <Icon.Headset style={{ fontSize: "22px" }} />
                &nbsp;&nbsp; 1423
              </p>
              <p>
                <Icon.Envelope style={{ fontSize: "22px" }} />
                &nbsp;&nbsp; service@porlor.co.th <br />
                <Icon.Envelope style={{ fontSize: "22px" }} />
                &nbsp;&nbsp; express@porlor.co.th <br /> <Icon.Envelope style={{ fontSize: "22px" }} />
                &nbsp;&nbsp; 023682111@porlor.co.th
              </p>
              <div>
                <a href="https://lin.ee/2QNYZt2bO">
                  <img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-detail">
        Copyright © 2018 PorLor Express. All Rights Reserved.{" "}
        <a
          type="button"
          className="btn-link"
          style={{ color: "#ffffff" }}
          onClick={(e) => {
            routeTO.push("mis/Login");
          }}
        >
          staff only
        </a>
      </div>
    </footer>
  );
}

function FooterTH(props) {
  const {routeTO} = props;
  return (
    <footer className="footer ">
      <div className="footer-blue border-top-yellow">
        <div className="container">
          <div className="row footer-contact">
            <div className="col-12 col-sm-4 col-lg-4 col-xl-4">
              <h3 className="title-footer">ปอลอ เอ็กซ์เพรส</h3>
              <p>ตรงเวลา ปลอดภัย ทั่วไทย</p>
              <p> จันทร์–เสาร์: 8.30-18.00 น.</p>
              <div className=" form-group">
                <Button
                  variant="primary-one"
                  onClick={(e) => {
                    routeTO.push("Tracking");
                  }}
                  className="radius"
                >
                  ตรวจสอบสถานะการจัดส่ง
                </Button>
              </div>
              <div className=" form-group">
                <Button
                  variant="primary-one"
                  onClick={(e) => {
                    routeTO.push("PriceEstimation");
                  }}
                  className="radius"
                >
                  คำนวณค่าจัดส่งพัสดุ
                </Button>
              </div>
            </div>
            <div className="col-12 col-sm-4 col-lg-4 col-xl-4">
              <h3 className="title-footer">ข้อมูลของเรา</h3>
              <ul>
                <li>บริการของเรา</li>
                <li>อัตราบริการ</li>
                <li>เกี่ยวกับเรา</li>
              </ul>
            </div>
            <div className="col-12 col-sm-4 col-lg-4 col-xl-4">
              <h3 className="title-footer">ติดต่อเรา</h3>
              <p>
                เลขที่ 99/153 ซอยกรุงเทพกรีฑา 39 แขวงทับช้าง <br /> เขตสะพานสูง กรุงเทพมหานคร
              </p>
              <p>
                <Icon.Headset style={{ fontSize: "22px" }} />
                &nbsp;&nbsp; 1423
              </p>
              
              <p>
                <Icon.Envelope style={{ fontSize: "22px" }} />
                &nbsp;&nbsp; service@porlor.co.th <br />
                <Icon.Envelope style={{ fontSize: "22px" }} />
                &nbsp;&nbsp; express@porlor.co.th <br /> <Icon.Envelope style={{ fontSize: "22px" }} />
                &nbsp;&nbsp; 023682111@porlor.co.th
              </p>
              <div>
                <a href="https://lin.ee/2QNYZt2bO">
                  <img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-detail">
        Copyright © 2018 PorLor Express. All Rights Reserved.{" "}
        <a
          type="button"
          className="btn-link"
          style={{ color: "#ffffff" }}
          onClick={(e) => {
            routeTO.push("mis/Login");
          }}
        >
          staff only
        </a>
      </div>
    </footer>
  );
}
