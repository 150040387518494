import React from "react";
import { connect } from "react-redux";

function FormServiceCust(props) {
  const { lang } = props;
  return lang.language === "th" ? <FormServiceCustTH /> : <FormServiceCustEN />;
}

const mapStateToProps = (state) => ({
  lang: state.LanguageAction,
});

export default connect(mapStateToProps)(FormServiceCust);

function FormServiceCustTH() {
  return (
    <div className="container">
      <h4>ติดต่อสอบถาม (ฝ่ายบริการลูกค้า)</h4>
      <br />
      <div>
        <form>
          <div className="form-group">
            <label>หัวข้อ</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>อีเมล</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>ข้อความ</label>
            <textarea className="form-control" rows="6"></textarea>
          </div>
          <button type="submit" className="btn btn-primary">
            ส่งข้อความ
          </button>
        </form>
      </div>
    </div>
  );
}

function FormServiceCustEN() {
  return (
    <div className="container">
      <h4>ASK FOR INFORMATIONS</h4>
      <br />
      <div>
        <form>
          <div className="form-group">
            <label>Subject</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>E-mail</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea className="form-control" rows="6"></textarea>
          </div>
          <button type="submit" className="btn btn-primary">
            SEND
          </button>
        </form>
      </div>
    </div>
  );
}
