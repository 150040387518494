import Instance from "../Helper/axios";
import { URL, URLTEST, urlLocal } from "../Helper/baseURL";

export async function resetPassword(data) {
    try {
        const response = await Instance.post(
            URL + "profile/SettingProfile/resetPassword",
            data
        );
        return await response.data;
    } catch (error) {
        console.log("error", error);
    }
}