import swal from "sweetalert";

export function swalSuccess({ title, text } = {}) {
  return swal({
    title: title !== undefined ? title : "สำเร็จ!",
    text: text !== undefined ? text : "",
    icon: "success",
    button: "ปิด",
  });
}

export function swalError({ title, text } = {}) {
  return swal({
    title: title !== undefined ? title : "กรุณาลองอีกครั้ง!",
    text: text !== undefined ? text : "",
    icon: "error",
    button: "ปิด",
  });
}
