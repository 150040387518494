import Instance from "../Helper/axios";
import { URL, URLTEST, urlLocal } from "../Helper/baseURL";

export async function updateParcel(data){
  try {
    const response = await Instance.post(
      URL + "/parcel/Parcel/UpdateParcelsInfo",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }  
}

export async function GetReceipt(data) {
  try {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await Instance.post(
      URL + "receipt/Receipt/showReceipt",
      formData
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function GetMyReceipt(data) {
  try {
    const response = await Instance.post(
      URL + "receipt/Receipt/getMyReceipt",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function GetParcelsFromReceipt(data) {
  try {
    const response = await Instance.post(
      URL + "receipt/Receipt/getParcelsFromReceipt",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function CancelReceipt(data) {
    try {
      const response = await Instance.post(
        URL + "receipt/Receipt/cancelReceipt",
        data
      );
      return await response.data;
    } catch (error) {
      console.log("error", error);
    }
}

export async function reprintReceipt(data) {
  try {
    const response = await Instance.post(
      URL + "receipt/Receipt/get_receipt",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function getPrintReceipt(data) {
  try {
    const response = await Instance.post(
      URL + "receipt/Receipt/get_printReceipt",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}