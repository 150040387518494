import axios from "../Helper/axios";
import { URL } from "../Helper/baseURL";
export const AdsService =  {
    add: (data, shop_id) => {
        data = {
            ...data,
            id_shop: shop_id,
        }
        return axios.post(URL + 'ads/AdsController/add', data);
    },
    activeAds: () => {
        return axios.get(URL + 'ads/AdsController/active');
    },
    load: () => {
        return axios.get(URL + 'ads/AdsController/load', {
            params: {
                shop: localStorage.getItem("id"),
            }
        })
    },
};