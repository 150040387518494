import {useState, useCallback} from "react";
import {updateHubUsers} from "../services/hub-users.service";

const useHubUserAdd = (onSuccess) => {

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const update = useCallback(
        async (id,{name, username, password}) => {
            setLoading(true);
            setErrorMessage(null);
            try {
                const result = await updateHubUsers(id, name, username, password);
                onSuccess();
            }
            catch(error){
                console.error(error);
                setErrorMessage(error.message);
            }
            finally {
                setLoading(false);
            }
        },
        [onSuccess],
    );


    return {
        update,
        loading,
        errorMessage,
    }
};

export default useHubUserAdd;