import HubBodyLayout from '../../../Layouts/Hub/components/hub-body-layout';
import GenericTable from '../Shared/GenericTable';
import { HubPresentation } from '../Shared/HubPresentation';
import HubPageTitle from '../Shared/HubPageTitle';
import { HubTableTitle } from '../Shared/HubTableTitle';
import EmployeesList from '../Shared/EmployeesList';
import { useHistory } from 'react-router';
import SearchInput from '../Shared/SearchInput';
import React, {useEffect, useState} from 'react';
import { RemainingParcels } from '../Shared/RemainingParcels';
import {useTranslation} from "react-i18next";
import useDebouncedState from "../../../utility/hooks/useDebouncedState";
import useRemainingParcels from "./hooks/useRemainingParcels";
import {Spinner} from "react-bootstrap";
import useHubRemainingCOD from "./hooks/useHubRemainingCOD";
import Pagination from "@material-ui/lab/Pagination";

const HubCod = () => {
  const [debouncedSearch, search, setSearch] = useDebouncedState('', 500);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(null);
  const {error,loading,result} = useRemainingParcels();
  const {error: parcelListError,loading: parcelListloading,result: parcelListResult, fetch} = useHubRemainingCOD();
  const {t} = useTranslation();
  const metadata = [
  {
    text: 'Barcode',
    width: '30%',
    render: (data) => <p className='mb-0' style={{fontWeight: 'bold', color: 'var(--blue)'}}>{data.awb_code}</p>,
  },
  {
    text: 'COD',
    width: '30%',
    render: (data) => data.cod_cost,
  },
];

  useEffect(() => {
    return () => {
      setPage(0);
    };
  }, [debouncedSearch]);


  useEffect(() => {
    void fetch(debouncedSearch, page + 1);
  }, [debouncedSearch, page]);

  
  const leftContent = (
    <>
      <HubPresentation />
      <HubPageTitle className="">{t('hub.cod.title')}</HubPageTitle>
      <div className='d-flex flex-column justify-content-center align-items-center'>
        {
          loading &&
            <div className="spinner-global-content">
              <Spinner variant="success" animation="border" />
            </div>
        }
        {
            result && <>
              <RemainingParcels remaining={result.cod_collected} total={result.cod_total} text={t('hub.cod.parcel_delivery')} />
              <RemainingParcels remaining={result.cod_amount_collected} total={result.cod_amount_total} text={t('hub.cod.parcel_count')} unit=' Baht ' />
            </>
        }
      </div>
    </>
  );

  const rightContent = (
    <>
      <h4 className='w-100 text-center' style={{ color: 'var(--blue)' }}>{t('hub.cod.remaining_cods')}</h4>
      <SearchInput value={search} setValue={(e) => setSearch(e.target.value)} placeholder={t('hub.cod.remaining_cods.placeholder')} />
      {parcelListloading &&
          <div className="spinner-global-content">
            <Spinner variant="success" animation="border" />
          </div>
      }
      {
        parcelListResult &&
          <>
            <GenericTable
                showHeader={false}
                roundedBorders={true}
                metadata={metadata}
                data={parcelListResult.result}
                keyExtractor={(item) => item.code}
            />
            <div style={{width: '100%'}} className="flex flex-row justify-content-center">
              <Pagination
                  count={parseInt(parcelListResult.maxPage)}
                  page={page + 1}
                  onChange={async (e, newPage) => {
                    setPage(newPage - 1);
                  }}
                  color="primary"
                  size="small"
                  defaultPage={6}
                  siblingCount={1}
              />
            </div>
          </>
      }
    </>
  );
  return (
    <>
      <HubBodyLayout leftContent={leftContent} rightContent={rightContent}/>
    </>
  )
}
export default HubCod;
