import React, { useState } from "react";
import ShowData from "./showData";
import LabelForm from "./labelForm";
import { UpdatePrintStatus } from "../../../Service/service.Parcels";
import { GetRecoveryBarcode } from "../../../Service/service.Invoice";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import { shipment_status } from "../../../Data/shipment_status";
import { monthTH } from "../../../Data/month-th.json";
import * as Icon from "react-bootstrap-icons";
import { connect } from "react-redux";
import printJS from "print-js";

function InvoiceReport(props) {
  const { user } = props;
  var year = new Date().getFullYear();
  var years = Array.from(new Array(3), (e, index) => year - index);
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  const [data, setData] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [pageSizes, PageSizes] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });
  const [errorNoDate, setErrorNoDate] = useState(0);
  const [errorMassNoData, setErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);
  const [codTotal, setCodTotal] = useState(0);
  const [shipmentTotal, setShipmentTotal] = useState(0);
  const [rate, setRate] = useState(0);
  const [rate2, setRate2] = useState(0);
  const [moneyDue, setMoneyDue] = useState(0);
  const [moneyDue2, setMoneyDue2] = useState(0);
  const [labelData, setLabelData] = useState([]);

  async function fetchData(value) {
    Loading(1);
    if(value.start_date===""){
      setErrorNoDate("กรุณาระบุ วันเปิดบิล");
      Loading(0);
    }
    else{
      setErrorNoDate("");
      const result = await GetRecoveryBarcode(value);
      if (result !== undefined) {
        if (result.data !== false) {
          setData(result.data.result);
          setDataExport(result.dataExport);
          setPage({
            current_page: result.data.pageNo,
            last_page: result.data.maxPage,
            total: result.data.numRowsAll,
          });
          setCodTotal(result.sumCod);
          setShipmentTotal(result.sumShipment);
          setRate(result.rate);
          setRate2(result.rate2);
          setMoneyDue(result.moneyDue);
          setMoneyDue2(result.moneyDue2);
          setErrorMassNoData(0);
        } else {
          setData([]);
          setDataExport([]);
          setcurrentPage(1);
          setPage({
            current_page: 1,
            last_page: 1,
            total: 0,
          });
          setErrorMassNoData(1);
        }
        Loading(0);
      }
    }
    
  }

  async function UpdatePrint(value) {
    const res = await UpdatePrintStatus(value);
    if (res.status === 1) {
      if (value.typeLabel === 1) {
        printJS({
          printable: "labelNormal",
          type: "html",
          scanStyles: false,
          css: [
            window.location.protocol + "//" + window.location.host + "/static/PrinterCOD.css",
            "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
          ],
          style: "@page {size: 10cm 7cm;margin: 0cm;}",
        });
      } else {
        printJS({
          printable: "labelAddress",
          type: "html",
          scanStyles: false,
          css: [
            window.location.protocol + "//" + window.location.host + "/static/Printer.css",
            "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
          ],
          style: "@page {size: 10cm 15cm;margin: 0.3cm;}",
        });
      }
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  }

  return (
    <div>
      <Formik
        // validationSchema={schema}
        initialValues={{
          search: "",
          shipment_status: "",
          dateNow: 0,
          startDay: "",
          startMonth: "",
          startYear: "",
          endDay: "",
          endMonth: "",
          endYear: "",
          cod_status: "",
          page: 1,
        }}
        onSubmit={(value) => {
          setcurrentPage(1);
          setData([]);
          setDataExport([]);
          setPage({
            current_page: 0,
            last_page: 1,
            total: 0,
          });
          fetchData({
            search: value.search,
            shipment_status: value.shipment_status,
            start_date:
              value.startYear !== "" && value.startMonth !== "" && value.startDay !== ""
                ? value.startYear + "-" + value.startMonth + "-" + value.startDay
                : "",
            end_date:
              value.startYear !== "" && value.startMonth !== "" && value.startDay !== ""
                ? value.startYear + "-" + value.startMonth + "-" + value.startDay
                : "",
            // end_date:
            //   value.endYear !== "" && value.endMonth !== "" && value.endDay !== ""
            //     ? value.endYear + "-" + value.endMonth + "-" + value.endDay
            //     : "",
            page: 1,
            number_list: pageSizes,
            cust_code: user.username,
            cod_status: value.cod_status,
          });
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className="card mb-5">
              <div className="card-body">
                <div className="container-fluid mt-2 mb-2">
                  <div className="text-center text-title-color">
                    <h2>Invoice Report</h2>
                  </div>
                  <br />
                  <div className="row justify-content-md-center">
                    <div className="col-12 col-lg-2">
                      <br />
                      <center style={{ marginTop: "10px" }}>
                        <div className="form-check">
                          <input
                            checked={values.dateNow === 0 ? false : true}
                            style={{ width: "15px", height: "15px" }}
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            onChange={(e) => {
                              if (values.dateNow === 0) {
                                setFieldValue("dateNow", 1);
                                setFieldValue("startDay", date);
                                setFieldValue("startMonth", ("0" + month).slice(-2));
                                setFieldValue("startYear", year);
                                setFieldValue("endDay", date);
                                setFieldValue("endMonth", ("0" + month).slice(-2));
                                setFieldValue("endYear", year);
                              } else {
                                setFieldValue("dateNow", 0);
                                setFieldValue("startDay", "");
                                setFieldValue("startMonth", "");
                                setFieldValue("startYear", "");
                                setFieldValue("endDay", "");
                                setFieldValue("endMonth", "");
                                setFieldValue("endYear", "");
                              }
                            }}
                          />
                          <label className="form-check-label" htmlFor="flexCheckDefault" style={{ marginLeft: "10px" }}>
                            วันที่ปัจจุบัน
                          </label>
                        </div>
                      </center>
                    </div>
                    <div className="col-12 col-lg-4">
                      <span>วันเปิดบิล</span>
                      <div className="row">
                        <div className="col-12 col-sm-4 col-md-4 px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.startYear}
                            onChange={(e) => {
                              setFieldValue("startYear", e.target.value);
                            }}
                          >
                            <option value=""> -- ปี -- </option>
                            {years.map((year, index) => (
                              <option key={`year${index}`} value={year}>
                                {year + 543}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.startMonth}
                            onChange={(e) => {
                              setFieldValue("startMonth", e.target.value);
                            }}
                          >
                            <option value=""> -- เดือน -- </option>
                            {monthTH.map((item, index) => (
                              <option value={item.id} key={item.id}>
                                {item.monthName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.startDay}
                            onChange={(e) => {
                              setFieldValue("startDay", e.target.value);
                            }}
                          >
                            <option value=""> -- วัน -- </option>
                            {Array.from(Array(31), (e, i) => (
                              <option value={i + 1}>{i + 1}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 text-danger">{errorNoDate===0?"":errorNoDate}</div>
                    </div>

                    {/* <div className="col-12 col-lg-4">
                      <span>ถึงวันที่เปิดบิล</span>
                      <div className="row">
                        <div className="col-12 col-sm-4 px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.endYear}
                            onChange={(e) => {
                              setFieldValue("endYear", e.target.value);
                            }}
                          >
                            <option value=""> -- ปี -- </option>
                            {years.map((year, index) => (
                              <option key={`year${index}`} value={year}>
                                {year + 543}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-4  px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.endMonth}
                            onChange={(e) => {
                              setFieldValue("endMonth", e.target.value);
                            }}
                          >
                            <option value=""> -- เดือน -- </option>
                            {monthTH.map((item, index) => (
                              <option value={item.id} key={item.id}>
                                {item.monthName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12 col-sm-4  px-md-1 mt-1">
                          <select
                            className={"form-control"}
                            value={values.endDay}
                            onChange={(e) => {
                              setFieldValue("endDay", e.target.value);
                            }}
                          >
                            <option value=""> -- วัน -- </option>
                            {Array.from(Array(31), (e, i) => (
                              <option value={i + 1} key={i}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <br />
                  <div className="row justify-content-md-center">
                    <div>
                      <button
                        disabled={loading === 1 ? true : false}
                        type="submit"
                        className={loading === 1 ? "btn btn-secondary" : "btn btn-primary"}
                      >
                        <Icon.Search /> ค้นหา
                      </button>
                      <span>&nbsp;</span>
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => {
                          setData([]);
                          setDataExport([]);
                          setPage({
                            current_page: 0,
                            last_page: 1,
                            total: 0,
                          });
                          setcurrentPage(1);
                          PageSizes(10);
                          fetchData({
                            search: "",
                            shipment_status: "",
                            start_date: "",
                            end_date: "",
                            page: 1,
                            number_list: 10,
                            cust_code: user.username,
                            cod_status: "",
                          });
                        }}
                      >
                        <Icon.ArrowClockwise /> ล้างค่า
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ShowData
              data={data}
              dataExport={dataExport}
              page={page}
              pageSizes={pageSizes}
              errorMassNoData={errorMassNoData}
              currentPage={currentPage}
              values={values}
              fetchData={fetchData}
              setData={setData}
              setDataExport={setDataExport}
              setcurrentPage={setcurrentPage}
              loading={loading}
              user={user}
              codTotal={codTotal}
              shipmentTotal={shipmentTotal}
              rate={rate}
              rate2={rate2}
              moneyDue={moneyDue}
              moneyDue2={moneyDue2}
              PageSizes={PageSizes}
              setLabelData={setLabelData}
              printLael={UpdatePrint}
            />
            <br />
            <br />
            <LabelForm data={labelData} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(InvoiceReport);
