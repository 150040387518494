import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import swal from "sweetalert";
import {
  ShowShopsService,
  UpdateStatusShopsService,
} from "../../../Service/service.Shops";

export default function ShippingPrices() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizes, setPageSizes] = useState(10);
  const [setErrorMassNoData, SetErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");

  async function fetchData(value) {
    Loading(1);
    const result = await ShowShopsService(value);
    console.log(result);
    if (result !== undefined) {
      if (result.data.length !== 0) {
        setData(result.data);
        setPage({
          current_page: result.pg[0].current_page,
          last_page: result.pg[0].last_page,
          total: result.pg[0].total,
        });
      }
      if (result.data.length !== 0) {
        SetErrorMassNoData(0);
      }
      if (result.data.length === 0) {
        setCurrentPage(1);
        setPage({
          current_page: 1,
          last_page: 1,
          total: 0,
        });
        SetErrorMassNoData(1);
      }
      if (result != null) {
        setData(result.data);
      }
    } else {
      alert("Server Error");
    }
    Loading(0);
  }

  const checked = () => {
    data
      .filter((a) => a.status === "1")
      .map((item, i) => {
        if (document.getElementById("flexCheckDefault" + item.id) != null) {
          document.getElementById("flexCheckDefault" + item.id).checked = true;
        }
      });
  };

  useEffect(() => {
    fetchData({
      search: "",
      user_type: "",
      status: "",
      page: "1",
      number_list: "10",
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setData([]);
    setCurrentPage(newPage);
    fetchData({
      search: search !== "" ? search : "",
      user_type: type !== "" ? type : "",
      status: status !== "" ? status : "",
      page: newPage,
      number_list: pageSizes !== "" ? pageSizes : "",
    });
  };
  function handleChangePageSize(data) {
    setData([]);
    setCurrentPage(1);
    setPageSizes(data);
    fetchData({
      search: search !== "" ? search : "",
      user_type: type !== "" ? type : "",
      status: status !== "" ? status : "",
      page: currentPage !== "" ? currentPage : "",
      number_list: data,
    });
  }
  function filter() {
    setData([]);
    fetchData({
      search: search !== "" ? search : "",
      user_type: type !== "" ? type : "",
      status: status !== "" ? status : "",
      page: currentPage !== "" ? currentPage : "",
      number_list: pageSizes !== "" ? pageSizes : "",
    });
  }
  function clear() {
    setData([]);
    setSearch("");
    setType("");
    setStatus("");
    SetErrorMassNoData(0);
    setCurrentPage(1);
    setPageSizes(10);
    fetchData({
      search: "",
      user_type: "",
      status: "",
      page: "1",
      number_list: "10",
    });
  }

  return (
    <div>
      <div className="text-center text-title-color">
        <h2>ตั้งค่าแสดงราคาขนส่ง</h2>
      </div>
      <br />
      <div class="row" style={{ marginLeft: "5px", marginRight: "5px" }}>
        <div class="col-sm-4 px-md-1 mt-1">
          <span>ค้นหา</span>
          <input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            type="text"
            class="form-control"
            placeholder="รหัสร้านค้า/ชื่อร้านค้า"
            aria-label="รหัสร้านค้า/ชื่อร้านค้า"
            aria-describedby="basic-addon1"
          />
        </div>
        <div class="col-sm-2 px-md-1 mt-1">
          <span>ประเภท</span>
          <select
            value={type}
            class="form-control"
            aria-label="Default select example"
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <option value="">ทั้งหมด</option>
            <option value="9">ลูกค้าทั่วไป</option>
            <option value="2">ลูกค้าเครดิต</option>
            <option value="3">หน้าร้านทั่วไป</option>
            <option value="10">หน้าร้าน(ศูนย์กระจายสินค้า)</option>
            <option value="11">แฟรนไซส์</option>
          </select>
        </div>
        <div class="col-sm-2 px-md-1 mt-1">
          <span>สถานะ</span>
          <select
            value={status}
            class="form-control"
            aria-label="Default select example"
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="">ทั้งหมด</option>
            <option value="1">แสดงราคาค่าขนส่ง</option>
            <option value="0">ไม่แสดงราคาค่าขนส่ง</option>
          </select>
        </div>
        <div className="col-sm-4 px-md-3 mt-1">
          <br />
          <button
            disabled={loading === 0 ? false : true}
            className={"btn btn-primary"}
            onClick={filter}
          >
            <Icon.Search /> ค้นหา
          </button>{" "}
          <button
            className="btn btn-secondary"
            onClick={clear}
            disabled={
              search !== "" || type !== "" || status !== "" ? false : true
            }
          >
            <Icon.ArrowClockwise /> ล้างค่า
          </button>
        </div>
      </div>
      <br />
      <div className="d-flex justify-content-between mb-2">
        <div></div>
        <div>
          <select
            value={pageSizes}
            className="form-control"
            aria-label="Default select example"
            onChange={(e) => {
              handleChangePageSize(e.target.value);
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      <div>
        <div style={{ overflow: "Auto" }}>
          <table className="table table-bordered">
            <thead style={{ backgroundColor: "#3f75c1" }}>
              <tr style={{ color: "#FFFFFF" }}>
                <td style={{ width: "10%" }}>ลำดับ</td>
                <td style={{ width: "15%" }}>รหัสร้านค้า</td>
                <td style={{ width: "60%" }}>ชื่อร้านค้า</td>
                {/* <td>ประเภท</td>
                <td>สถานะ</td> */}
                <td>จัดการ</td>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr key={value.id}>
                  <td>{(page.current_page - 1) * pageSizes + (index + 1)}</td>
                  <td>{value.username}</td>
                  <td>{value.cust_name}</td>
                  {/* <td>{value.user_type_name}</td>
                  <td>{value.status_name}</td> */}
                  <td>
                    <center>
                      <div className="form-check">
                        <input
                          style={{ width: "20px", height: "20px" }}
                          onChange={(e) => {}}
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={"flexCheckDefault" + value.id}
                        />
                      </div>
                    </center>
                  </td>
                  {data.length === index + 1 ? checked() : ""}
                </tr>
              ))}
            </tbody>
          </table>
          <center>
            {setErrorMassNoData === 1 ? (
              <font color="red" size="3">
                {" "}
                ไม่พบข้อมูล!{" "}
              </font>
            ) : (
              ""
            )}
          </center>
        </div>
        <center>
          {loading === 1 ? (
            <div className="m-5">
              <div className="text-center">
                <div className="spinner-border  text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                {" ..."}
                กำลังโหลดข้อมูล
              </div>
            </div>
          ) : (
            ""
          )}
        </center>
        <div className="d-flex justify-content-between">
          <div>จำนวนทั้งหมด {page.total} รายการ</div>
          <div>
            <Pagination
              count={parseInt(page.last_page)}
              page={currentPage}
              onChange={handleChangePage}
              color="primary"
              size="small"
              defaultPage={6}
              siblingCount={1}
            />
          </div>
        </div>
      </div>

      <br />
      <br />
    </div>
  );
}
