import {useState, useCallback} from "react";
import {addHubZip, updateHubZip} from "../services/hub-users.service";

const useAddHubZip = (onSuccess) => {

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const add = useCallback(
        async (id,zip) => {
            setLoading(true);
            setErrorMessage(null);
            try {
                const result = await addHubZip(id, zip);
                onSuccess();
            }
            catch(error){
                setErrorMessage(error);
            }
            finally {
                setLoading(false);
            }
        },
        [onSuccess],
    );

    const update = useCallback(
        async (id,zip) => {
            setLoading(true);
            setErrorMessage(null);
            try {
                const result = await updateHubZip(id, zip);
                onSuccess();
            }
            catch(error){
                setErrorMessage(error);
            }
            finally {
                setLoading(false);
            }
        },
        [onSuccess],
    );


    return {
        add,
        loading,
        errorMessage,
        update,
    }
};

export default useAddHubZip;