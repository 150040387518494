import Instance from "../Helper/axios";
import { URLTEST, urlLocal,URL } from "../Helper/baseURL";

export async function ReportStockIn(data) {
  try {
    const response = await Instance.post(URL + "report/ControlParcel/getControlParcel", {
      ...data,
      id: localStorage.getItem('superkey') === 'shop' ? localStorage.getItem('id') : '',
    });
    //console.log("res:" + JSON.stringify(response.data));
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

