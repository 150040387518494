import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import {
  ShowSizeService,
  UpdateStatusSizeService,
  SaveSizeService,
} from "../../../Service/service.Credit";
import { Typeahead } from 'react-bootstrap-typeahead';
import { CustomerTypeService} from '../../../Service/service.Barcode';

export default function Size() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });

  const pageSizes = [10, 20, 50, 100];
  const [setErrorMassNoData, SetErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);
  const [custcode, setCustcode] = useState("");
  const [status, setStatus] = useState("1");

  const [pageNo, setCurrentPage] = useState(1);
  const [perPage, setPageSize] = useState(10);
  const [keyword, setSearch] = useState("");

  const [showEditSize, setShowEditSize] = useState(false);
  const handleCloseEditSize = () => setShowEditSize(false);
  const handleShowEditSize = () => setShowEditSize(true);

  const [showAddSize, setShowAddSize] = useState(false);
  const handleCloseAddSize = () => setShowAddSize(false);
  const handleShowAddSize = () => setShowAddSize(true);


  const [sizeName, setSizeName] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [brand, setBrand] = useState("");
  const [cust_code, setCust_code] = useState("");
  const [sizeId, setSizeId] = useState("");
  const [boxLength, setBoxLength] = useState("");
  const [boxWidth, setBoxWidth] = useState("");
  const [boxHeight, setBoxHeight] = useState("");
  const [boxSize, setBoxSize] = useState("");
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    fetchData();
  }, [pageNo, perPage]);

  async function fetchData() {
    let criteria = {
      keyword: keyword !== "" ? keyword : "",
      custcode: localStorage.getItem('username'),
      pageNo: pageNo !== "" ? pageNo : "",
      perPage: perPage !== "" ? perPage : "",
    };

    Loading(1);
    let res = await ShowSizeService(criteria);
    if (res.numRowsAll > 0) {
      setData(res.result);
      setPage({
        current_page: res.pageNo,
        last_page: res.maxPage,
        total: res.numRowsAll,
      });
      SetErrorMassNoData(0);
      Loading(0);
    } else {
      setCurrentPage(1);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      SetErrorMassNoData(1);
      Loading(0);
    }
  }

  const handleChangePage = (e, newPage) => {
    e.preventDefault();
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
  };

  async function CustomerType(data) {
    const result = await CustomerTypeService(data);
    if (data !== '') {
      setCustomers(result.data);
    }
  }

  async function SaveData(value) {
    const res = await SaveSizeService(value);
    console.log("save data:", res);
    if (res.status === 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      fetchData();
    } else if (res.status === 2) {
      swal("แจ้งเตือน", res.message, "warning");
    } else {
      swal("แจ้งเตือน", res.message, "warning");
    }
  }

  const handleSubmit = () => {
    if (
      boxHeight !== "" &&
      boxLength !== "" &&
      boxWidth !== "" &&
      boxSize !== "" &&
      sizeName !== ""
    ){
      const res = SaveData({
        size_id: sizeId !== "" ? sizeId : "",
        cust_code: brand !== "" ? brand : "",
        size_name: sizeName !== "" ? sizeName : "",
        box_width: boxWidth !== "" ? boxWidth : "",
        box_height: boxHeight !== "" ? boxHeight : "",
        box_length: boxLength !== "" ? boxLength : "",
        box_size: boxSize !== "" ? boxSize : ""
      });
    
      
    }
  };

  const deleteSize = async (value) => {
    let res = await UpdateStatusSizeService(value);
    if (res.status === 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      fetchData();
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  };

  const filter = (e) => {
    e.preventDefault();
    setData([]);
    fetchData();
  };

  const clear = (e) => {
    e.preventDefault();
    setSearch("");
    setCustcode("");
    setStatus("1");
    setPageSize(10);
    setCurrentPage(1);
    fetchData();
  };

  return (
    <div>
      <div className="text-center text-title-color">
        <h2>ขนาด</h2>
      </div>
      <br />
      
      <div className="row" style={{ marginLeft: "5px", marginRight: "5px" }}>
        <div className="col-sm-4 px-md-1 mt-1">
          <span>ค้นหา</span>
          <input
            value={keyword}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            type="text"
            className="form-control"
            placeholder="รหัสร้านค้า/ชื่อขนาด"
            aria-label="รหัสร้านค้า/ชื่อขนาด"
            aria-describedby="basic-addon1"
          />
        </div>
        <div className="col-sm-2 px-md-3 mt-1">
          <br />
          <button
            name="search"
            disabled={loading === 0 ? false : true}
            className={"btn btn-primary"}
            onClick={filter}
          >
            <Icon.Search /> ค้นหา
          </button>{" "}
          <button
            name="clear"
            className="btn btn-secondary"
            onClick={clear}
            disabled={
              keyword !== "" ? false : true
            }
          >
            <Icon.ArrowClockwise /> ล้างค่า
          </button>
        </div>
        <div className="col-sm-6 px-md-1 mt-1" align="right">
          <br />
          <button
            className="btn btn-primary"
            onClick={(e) => {
              setBoxSize('');
              handleShowAddSize();
            }}
          >
            <Icon.Plus /> เพิ่มขนาด
          </button>
        </div>
      </div>
      <br />
      <div>
        <div style={{ overflow: "Auto" }}>
          <table className="table table-bordered">
            <thead style={{ backgroundColor: "#3f75c1" }}>
              <tr style={{ color: "#FFFFFF" }}>
                <td>ลำดับ</td>
                <td>รหัสร้านค้า</td>
                <td>ชื่อขนาด </td>
                <td>ความกว้าง (ซม.)</td>
                <td>ความสูง (ซม.)</td>
                <td>ความยาว (ซม.)</td>
                <td>ขนาด(ซ.ม.)</td>
                <td>จัดการ</td>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr key={value.size_id}>
                  <td>{(page.current_page - 1) * perPage + (index + 1)}</td>
                  <td>{value.cust_code}</td>
                  <td>{value.size_name}</td>
                  <td>{value.box_width}</td>
                  <td>{value.box_height}</td>
                  <td>{value.box_length}</td>
                  <td>{value.box_size}</td>
                  <td>
                    <button
                      className="btn btn-warning"
                      onClick={(e) => {
                        handleShowEditSize();
                        setBrand(value.cust_code);
                        setCustomerType('2');
                        setBoxHeight(value.box_height);
                        setCust_code(value.cust_code);
                        setBoxLength(value.box_length);
                        setBoxWidth(value.box_width);
                        setSizeName(value.size_name);
                        setSizeId(value.size_id);
                        setBoxSize(value.box_size);
                      }}
                    >
                      <Icon.PencilSquare style={{ color: "#ffff" }} />
                    </button>{" "}
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        swal({
                          className: "bg-modal-red",
                          icon: "warning",
                          dangerMode: true,
                          text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                          buttons: {
                            cancel: "ยกเลิก",
                            confirm: {
                              text: "ใช่",
                              value: value.size_id,
                            },
                          },
                        }).then(() => {
                          if (value !== null) {
                            deleteSize({
                              id: value.size_id,
                            });
                            // alert(JSON.stringify(value));
                          }
                        });
                      }}
                    >
                      <Icon.Trash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <center>
            {setErrorMassNoData === 1 ? (
              <font color="red" size="3">
                {" "}
                ไม่พบข้อมูล!{" "}
              </font>
            ) : (
              ""
            )}
          </center>
        </div>
        <center>
          {loading === 1 ? (
            <div className="m-5">
              <div className="text-center">
                <div className="spinner-border  text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                {" ..."}
                กำลังโหลดข้อมูล
              </div>
            </div>
          ) : (
            ""
          )}
        </center>
        <div className="d-flex justify-content-between">
          <div>จำนวนทั้งหมด {page.total} รายการ</div>
          <div>
            <Pagination
              count={parseInt(page.last_page)}
              page={pageNo}
              onChange={handleChangePage}
              color="primary"
              size="small"
              defaultPage={6}
              siblingCount={1}
            />
          </div>
        </div>
      </div>

      <br />
      <br />
      <Modal show={showEditSize} onHide={handleCloseEditSize}>
        <Modal.Header closeButton>
          <Modal.Title> แก้ไขขนาด  </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "19px" }}>
          <div className="form-check">
              <label>สาขา</label>
                <input
                value={brand}
                onChange={(e) => {
                  setBrand(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="ชื่อขนาด "
                readOnly
              /> 
              <br/>
          <label>ชื่อขนาด</label>
            <input
              value={sizeName}
              onChange={(e) => {
                setSizeName(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="ชื่อขนาด "
            /> 
            <br />
            <label>ความกว้าง (ซม.)</label>
            <input
              value={boxWidth}
              onChange={(e) => {
                if (e.target.value !== '' && boxLength !== '' && boxHeight !== '') {
                  var sizeTotal = 0;
                  sizeTotal = parseFloat(e.target.value) + parseFloat(boxLength) + parseFloat(boxHeight);
                  setBoxSize(sizeTotal);
                }
                setBoxWidth(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="ความกว้าง (ซม.)"
            />
            <br />
            <label>ความยาว (ซม.)</label>
            <input
              value={boxLength}
              onChange={(e) => {
                if (e.target.value !== '' && boxHeight !== '' && boxWidth !== '') {
                  var sizeTotal = 0;
                  sizeTotal = parseFloat(e.target.value) + parseFloat(boxHeight) + parseFloat(boxWidth);
                  setBoxSize(sizeTotal);
                }
                setBoxLength(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="ความยาว (ซม.)"
            />  
            <br />
            <label>ความสูง (ซม.)</label>
            <input
              value={boxHeight}
              onChange={(e) => {
                if (e.target.value !== '' && boxLength !== '' && boxWidth !== '') {
                  var sizeTotal = 0;
                  sizeTotal = parseFloat(e.target.value) + parseFloat(boxLength) + parseFloat(boxWidth);
                  setBoxSize(sizeTotal);
                }
                setBoxHeight(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="ความสูง (ซม.)"
            />
            <br />
            <label>กว้าง + ยาว + สูง (ซม.)</label>
            <input
              value={boxSize}
              onChange={(e) => {
                setBoxSize(e.target.value);
              }}
              readOnly
              type="text"
              className="form-control"
              // placeholder="กว้าง + ยาว + สูง (ซม.)"
            /> 
          </div>
          <hr />
          <div align="right">
            <button
              type="button"
              className="btn btn-danger ml-1"
              onClick={(e) => {
                setShowEditSize(false);
                setCust_code('');
                setBrand('');
                setCustomerType('');
                setSizeName('');
                setSizeId('');
                setBoxLength('');
                setBoxWidth('');
                setBoxHeight('');
                setBoxSize('');
              }}
            >
              ยกเลิก
            </button>
            <button 
              type="submit" 
              className= "btn btn-primary ml-1"
              disabled= {boxHeight !== "" &&
                      boxLength !== "" &&
                      boxWidth !== "" &&
                      boxSize !== "" &&
                      sizeName !== "" ? false:true}
                      onClick={(e) => {
                setShowEditSize(false);
                handleSubmit();
                setBrand('');
                setCustomerType('');
                setSizeName('');
                setCust_code('');
                setSizeId('');
                setBoxLength('');
                setBoxWidth('');
                setBoxHeight('');
                setBoxSize('');
              }}>
              บันทึก
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showAddSize} onHide={handleCloseAddSize}>
        <Modal.Header closeButton>
          <Modal.Title> เพิ่มขนาด </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "19px" }}>
                        
          <div className="form-check">
          {/* <div className="col col-sm-4 px-md-1 mt-1"> */}
          <label>ประเภทลูกค้า</label>
              <select
                // value={customerType}
                onChange={(e) => {
                  CustomerType({ customer_type: e.target.value });
                  setCustomerType( e.target.value);
                }}
                className="form-control dropdown-custom"
              >
                <option value={''}>-- กรุณาระบุประเภทลูกค้า --</option>
                <option value={'2'}>เครดิต</option>
                {/* <option value={'3'}>หน้าร้าน</option>
                <option value={'6'}>ลูกค้าทั่วไป</option> */}
              </select>
          {/* </div> */}
          {/* <div className="col col-sm-4 px-md-1 mt-1"> */}
          <label>สาขา</label>
              <Typeahead
                defaultSelected={customers.filter((a) => a.cust_code === brand)}
                id="brand"
                name="brand"
                labelKey={(option) => `${option.cust_code} ${option.name}`}
                onChange={(e) => {
                  if (e.length > 0) {
                    let value = e[0].cust_code;
                    setBrand(value);
                  }
                }}
                onInputChange={(text, e) => {
                  setBrand('');
                }}
                options={customers}
                placeholder="-- กรุณาระบุสาขา --"
                selected={brand === '' ? [] : customers.filter((a) => a.cust_code === brand)}
              />
          {/* </div> */}
          <label>ชื่อขนาด</label>
            <input
              // value={sizeName}
              onChange={(e) => {
                setSizeName(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="ชื่อขนาด"
            /> 
            <br />
            <label>ความกว้าง (ซม.)</label> 
            <input
              // value={boxWidth}
              onChange={(e) => {
                if (e.target.value !== '' && boxLength !== '' && boxHeight !== '') {
                  var sizeTotal = 0;
                  sizeTotal = parseFloat(e.target.value) + parseFloat(boxLength) + parseFloat(boxHeight);
                  setBoxSize(sizeTotal);
                }
                setBoxWidth(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="ความกว้าง (ซม.)"
            /> 
            <br />
            <label>ความยาว (ซม.)</label>
            <input
              // value={boxLength}
              onChange={(e) => {
                if (e.target.value !== '' && boxHeight !== '' && boxWidth !== '') {
                  var sizeTotal = 0;
                  sizeTotal = parseFloat(e.target.value) + parseFloat(boxHeight) + parseFloat(boxWidth);
                  setBoxSize(sizeTotal);
                }
                setBoxLength(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="ความยาว (ซม.)"
            />  
            <br />
            <label>ความสูง (ซม.)</label>
            <input
              // value={boxHeight}
              onChange={(e) => {
                if (e.target.value !== '' && boxLength !== '' && boxWidth !== '') {
                  var sizeTotal = 0;
                  sizeTotal = parseFloat(e.target.value) + parseFloat(boxLength) + parseFloat(boxWidth);
                  setBoxSize(sizeTotal);
                }
                setBoxHeight(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="ความสูง (ซม.)"
            /> 
            <br />
            <label>กว้าง + ยาว + สูง (ซม.)</label>
            <input
              value={boxSize}
              // onChange={(e) => {
              //   setBoxSize(e.target.value);
              // }}
              readOnly
              type="text"
              className="form-control"
              // placeholder="กว้าง + ยาว + สูง (ซม.)"
            /> 
          </div>
          <hr />
          <div align="right">
            <button
              type="button"
              className="btn btn-danger ml-1"
              onClick={(e) => {
                setShowAddSize(false);
                setBrand('');
                setCustomerType('');
                setCust_code('');
                setSizeName('');
                setSizeId('');
                setBoxLength('');
                setBoxWidth('');
                setBoxHeight('');
                setBoxSize('');
              }}
            >
              ยกเลิก
            </button>
            <button 
              type="submit" 
              // className={localStorage.getItem("superkey") !=="service" ? 'btn btn-primary ml-1' : 'btn btn-secondary ml-1'}
              className= "btn btn-primary ml-1"
              // disabled = {localStorage.getItem("superkey") ==="service"}
              disabled= {boxHeight !== "" &&
                      boxLength !== "" &&
                      boxWidth !== "" &&
                      boxSize !== "" &&
                      sizeName !== "" ? false:true}
              // onClick={handleSubmit}
              onClick={(e) => {
                setShowAddSize(false);
                handleSubmit();
                setCust_code('');
                setBrand('');
                setCustomerType('');
                setSizeName('');
                setSizeId('');
                setBoxLength('');
                setBoxWidth('');
                setBoxHeight('');
                setBoxSize('');
              }}>
              บันทึก
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>

    
  );
}
