import * as Yup from "yup";

const filterSchema = Yup.object().shape({
  year: Yup.string().test("is-month-day", "กรุณาระบุ ปี", function (value) {
    const { month, day } = this.parent;
    if (month !== undefined || day !== undefined) {
      if (value === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }),
  month: Yup.string().test("is-day-year", "กรุณาระบุ เดือน", function (value) {
    const { day, year } = this.parent;
    if (year !== undefined || day !== undefined) {
      if (value === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }),
  day: Yup.string().test("is-month-year", "กรุณาระบุ วัน", function (value) {
    const { month, year } = this.parent;
    if (year !== undefined || month !== undefined) {
      if (value === undefined) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }),
});

export default filterSchema;
/*    .when("day", (value, schema) => {
      if (value === "" || value === undefined) {
        return schema.notRequired();
      } else {
        return schema.required("กรุณาระบุ ปี");
      }
    })
    .when("month", (value, schema) => {
      if (value === "" || value === undefined) {
        return schema.notRequired();
      } else {
        return schema.required("กรุณาระบุ ปี");
      }
    }),
    
      keyword: Yup.string().test("is-month-year", "กรุณาระบุ ชื่อไพล์", function (value) {
    const { month, year, day, importStatus } = this.parent;
    console.log( (importStatus === undefined || importStatus !== undefined))
    console.log(importStatus)
    if (year !== undefined || month !== undefined || day !== undefined || (importStatus === undefined || importStatus !== undefined)) {
      return true;
    } else {
      if (value === undefined) {
        return false;
      } else {
        return true;
      }
    }
  })
    
    
    .when("year", (value, schema) => {
      if (value === "" || value === undefined) {
        return schema.notRequired();
      } else {
        return schema.required("กรุณาระบุ เดือน");
      }
    })
    .when("month", (value, schema) => {
      if (value === "" || value === undefined) {
        return schema.notRequired();
      } else {
        return schema.required("กรุณาระบุ เดือน");
      }
    }),*/
