import {useState} from "react";
import Instance from "../Helper/axios"
import {dataURItoBlob} from "./files";
import {URL} from "../Helper/baseURL";
import {nanoid} from "nanoid";

const uploadFile = (data, onProgress) => {
    const formData = new FormData();
    formData.append('file',dataURItoBlob(data), nanoid()+'.png');
    return Instance.post(URL + '/files/FileController/addToS3',formData,{
        onUploadProgress: (progressEvent) => onProgress(progressEvent)
    })
};

export const useMultipleFileUpload = (onFinish, onError) => {
    const [uploadStates, setUploadStates] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const launchUpload = async (urls) => {
        const promiseList = [];
        const uploadStates = [];
        urls.forEach((row, index) => {
            const uploadState = {
              finalUrl: null,
              progression: 0,
              hasError: false,
            };
            const promise = uploadFile(row,({loaded,total}) => {
                uploadState.progression = Math.floor((loaded * 100) / total);
                setUploadStates([...uploadStates]);
            });
            promise.then((result) => {
                uploadState.finalUrl = result.data.data;
            });
            promise.catch((error) => {
               uploadState.hasError = true;
            });
            promiseList.push(promise);
            uploadStates.push(uploadState);
        });
        try{
            const results = await Promise.all(promiseList);
            onFinish(uploadStates.map(url => url.finalUrl));
            setUploadStates(null);
        }
        catch (error){
            console.error(error);
            setError(error.message);
            onError(error.message);
        }
    };
    return {
        launchUpload,
        isLoading,
        uploadStates,
        error
    }
}