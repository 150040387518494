import React from "react";
import Head from "./Header";
import Footer from "./Footer";

export default function misLayout(props) {
  return (
    <>
      <Head {...props} />
      <div
        className={localStorage.getItem("superkey")=="1947"||localStorage.getItem("superkey")=='1947'?"mt-2 container-fluid-custom ":"mt-5 container-fluid-custom "}
        style={{ minHeight: "calc(100vh - 215px)" }}
      >
        {props.children}
      </div>
      <Footer {...props} />
    </>
  );
}
