import React, { useState, useEffect } from 'react';
import ShowData from './showData';
import LabelForm from './labelForm';
import LabelFormSingle from './labelFormSingle';
import FormatLabelAddress from '../formatLabelAddress';
import FormatLabelAddressCod from '../formatLabelAddressCod';
import FormatLabelAddressPornor from '../formatLabelPornor/formatLabelAddressPornor';
import * as Icon from 'react-bootstrap-icons';
import { Formik, Form, FieldArray, ErrorMessage, getIn } from 'formik';
import {
  GetPrintLabel,
  UpdateLabel,
  UpdateStatus,
  print_genAwbCode,
  refreshParcelStatus
} from '../../../Service/service.PrintLael';
import { BarcodeUpdateService } from '../../../Service/service.Barcode';
import { connect } from 'react-redux';
import { ratePorlor, ratePorlorFarmer } from '../../../Data/rateService.json';
import Modal from 'react-bootstrap/Modal';
import printJS from 'print-js';
import swal from 'sweetalert';
import InputAutocompleteV2 from '../../../Components/inputAutocompleteV2';
import { bank } from '../../../Data/bank.json';
import printLabelSchema from './validationPrintLabel';
import { nanoid } from 'nanoid';
import { PriceCalculation } from '../../../Components/PriceCalculation';
import {calculatedPrice} from "../../../Service/service.Parcels";
import login from "../login";

function PrintLabel(props) {
  const { user } = props;
  var year = new Date().getFullYear();
  var month = new Date().getMonth() + 1;
  var date = new Date().getDate();
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  var [data2, Data2] = useState([]);
  const [pageSizes, setPageSizes] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [page, setPage] = useState({
    current_page: 0,
    last_page: 1,
    total: 0,
  });
  const [errorMassNoData, setErrorMassNoData] = useState(0);
  const [loading, Loading] = useState(0);
  const [printLebel, setPrintLebel] = useState([]);
  const [awbCode, setAwbCode] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [dataExport, setDataExport] = useState([]);

  const [printLabel2, setPrintLabel2] = useState([]);

  const [printShow, setPrintShow] = useState(false);
  const handlePrintClose = () => setPrintShow(false);
  const handlePrintShow = () => setPrintShow(true);

  const [printShow2, setPrintShow2] = useState(false);
  const handlePrintClose2 = () => setPrintShow2(false);
  const handlePrintShow2 = () => setPrintShow2(true);

  const hideCost = localStorage.getItem('show_shipment_cost') === "0" && localStorage.getItem('superkey') === 'credit';
  const isShop = localStorage.getItem('superkey') === 'shop';

  const [errorWeight,setErrorWeight] = useState("");
  const [errorBox_size,setErrorBox_size] = useState("");

  useEffect(() => {
    fetchData({
      search: '',
      printStatus: 'ALL',
      printType: '0',
      pageNo: 1,
      perPage: pageSizes,
      cust_code: localStorage.getItem('username'),
    });
  }, []);

  async function fetchData(value) {
    Loading(1);
    // setData([]);
    const result = await GetPrintLabel(value);
    if (result !== false) {
      setData(result.result);
      // setDataExport(result.dataExport);
      setPage({
        current_page: result.pageNo,
        last_page: result.maxPage,
        total: result.numRowsAll,
      });
      setErrorMassNoData(0);
    } else {
      setData([]);
      // setDataExport([]);
      setcurrentPage(1);
      setPage({
        current_page: 1,
        last_page: 1,
        total: 0,
      });
      setErrorMassNoData(1);
    }
    Loading(0);
  }
  
  async function fetchDataExcel(value) {
    Loading(1);
    const result = await GetPrintLabel(value);
    if (result !== false) {
      setDataExport(result.result);
      setErrorMassNoData(0);
    } else {
      setDataExport([]);
      setErrorMassNoData(1);
    }
    Loading(0);
  }

  async function UpdateBarcode(value) {
    const resultUpdate = await UpdateStatus(value);
    if (resultUpdate.status === 1) {
      swal({
        title: 'ยกเลิกสำเร็จ!',
        text: '',
        icon: 'success',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
      setData([]);
      setDataExport([]);
      fetchData({
        search: '',
        printStatus: 'ALL',
        printType: '0',
        pageNo: 1,
        perPage: pageSizes,
        cust_code: localStorage.getItem('username'),
      });
      Data2([]);
    } else {
      swal({
        title: 'ยกเลิกไม่สำเร็จ',
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
    }
  }

  async function UpdateDataLabel(value) {
    const result = await UpdateLabel(value);
    if (result.status === 1) {
      swal({
        title: 'แก้ไขสำเร็จ!',
        text: '',
        icon: 'success',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
      setData([]);
      setDataExport([]);
      fetchData({
        search: '',
        printStatus: 'ALL',
        printType: '0',
        pageNo: 1,
        perPage: pageSizes,
        cust_code: localStorage.getItem('username'),
      });
      handleClose2();
    } else {
      swal({
        title: 'แก้ไขไม่สำเร็จ',
        text: '',
        icon: 'warning',
        showConfirmButton: false,
        button: 'ปิด',
        timer: 1500,
      });
    }
  }

  const checked = () => {
    data2.map((item, i) => {
      if (document.getElementById('flexCheckDefault' + item.code) != null) {
        document.getElementById('flexCheckDefault' + item.code).checked = true;
      }
    });
  };

  function dataArray(data,awb_code) {
    let check = document.getElementById('flexCheckDefault' + data).checked;
    if (check) {
      Data2((t) =>
        t.concat({
          code: data,
          awb_code: awb_code
        }),
      );
    } else {
      let array = data2;
      Data2([]);
      array.map((row) => {
        if (row.code !== data) {
          Data2((t) =>
            t.concat({
              code: row.code,
              awb_code: row.awb_code,
              cust_code: localStorage.getItem('username'),
            }),
          );
        }
      });
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          search: '',
          printStatus: 'ALL',
          printType: '0',
        }}
        onSubmit={(value) => {
          setcurrentPage(1);
          setData([]);
          setDataExport([]);
          setPage({
            current_page: 0,
            last_page: 1,
            total: 0,
          });
          fetchData({
            search: value.search,
            printStatus: value.printStatus,
            printType: value.printType,
            pageNo: 1,
            perPage: pageSizes,
            cust_code: localStorage.getItem('username'),
          });
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className="card mb-5">
              <div className="card-body">
                <div className="container-fluid mt-2 mb-2">
                  <div className="text-center text-title-color">
                    <h2>พิมพ์ลาเบล</h2>
                  </div>
                  <br />
                  <div className={localStorage.getItem('superkey') !== '1947' ? 'row' : 'hidden-ct'}>
                    <div className="col col-sm-12">
                      <span>ประเภทลูกค้า</span>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          checked={values.printType === '0' ? true : false}
                          className="form-check-input"
                          type="radio"
                          name="printType"
                          id="printType1"
                          value="0"
                          onChange={(e) => {
                            setFieldValue('printType', e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="printType1">
                          ลูกค้าหน้าร้าน
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          checked={values.printType === '1' ? true : false}
                          className="form-check-input"
                          type="radio"
                          name="printType"
                          id="printType2"
                          value="1"
                          onChange={(e) => {
                            setFieldValue('printType', e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="printType2">
                          ลูกค้า Mobile
                        </label>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col col-sm-4">
                      <span>สถานะการพิมพ์</span>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          checked={values.printStatus === 'ALL' ? true : false}
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="ALL"
                          onChange={(e) => {
                            setFieldValue('printStatus', e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="inlineRadio1">
                          ทั้งหมด
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          checked={values.printStatus === '' ? true : false}
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value=""
                          onChange={(e) => {
                            setFieldValue('printStatus', e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="inlineRadio2">
                          ยังไม่ได้พิมพ์
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          checked={values.printStatus === '1' ? true : false}
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          value="1"
                          onChange={(e) => {
                            setFieldValue('printStatus', e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="inlineRadio3">
                          พิมพ์แล้ว
                        </label>
                      </div>
                    </div>
                    <div className="col col-sm-4">
                      <span>ค้นหาเลขลาเบล/เลขบุ๊คกิ้ง/ชื่อผู้รับ/ชื่อผู้ส่ง</span>
                      <input
                        className="form-control"
                        value={values.search}
                        onChange={(e) => {
                          setFieldValue('search', e.target.value);
                        }}
                      />
                    </div>
                    <div className="col col-sm-4">
                      <br />
                      <button disabled={loading === 1 ? true : false} type="submit" className={loading === 1 ? 'btn btn-secondary' : 'btn btn-primary'}>
                        <Icon.Search /> ค้นหา
                      </button>
                      <span>&nbsp;</span>
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => {
                          setData([]);
                          setDataExport([]);
                          setPage({
                            current_page: 0,
                            last_page: 1,
                            total: 0,
                          });
                          setcurrentPage(1);
                          setPageSizes(10);
                          Data2([]);
                          setPrintLebel([]);
                          fetchData({
                            search: '',
                            printStatus: 'ALL',
                            printType: '0',
                            pageNo: 1,
                            perPage: 10,
                            cust_code: localStorage.getItem('username'),
                          });
                        }}
                      >
                        <Icon.ArrowClockwise /> ล้างค่า
                      </button>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            {data.length > 0 ? (
              <ShowData
                printLabel2={printLabel2}
                printLebel={printLebel}
                data={data}
                dataArray={dataArray}
                // addPrints={addPrints}
                setPrintLebel={setPrintLebel}
                page={page}
                pageSizes={pageSizes}
                checked={checked}
                setPageSizes={setPageSizes}
                currentPage={currentPage}
                data2={data2}
                Data2={Data2}
                setData={setData}
                dataExport={dataExport}
                setDataExport={setDataExport}
                setcurrentPage={setcurrentPage}
                fetchData={fetchData}
                fetchDataExcel={fetchDataExcel}
                user={user}
                values={values}
                handlePrintShow2={handlePrintShow2}
                handleShow={handleShow}
                setAwbCode={setAwbCode}
                UpdateBarcode={UpdateBarcode}
                handleShow2={handleShow2}
                setDataEdit={setDataEdit}
              />
            ) : (
              <>
                <center>
                  {errorMassNoData === 1 && loading !== 1 ? (
                    <font color="red" size="4">
                      {' '}
                      ไม่พบข้อมูล!{' '}
                    </font>
                  ) : (
                    ''
                  )}
                </center>
                {loading === 1 && data.length === 0 ? (
                  <div className="m-5">
                    <div className="text-center">
                      <div className="spinner-border  text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      {' ...'}
                      กำลังโหลดข้อมูล
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
            <LabelForm printLebel={printLebel} />
            <LabelFormSingle awbCode={awbCode} data={data} />
            <br />
            <br />
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>พิมพ์ลาเบล</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ fontSize: '19px' }}>
                <div>
                  <div className={dataArray === '' ? 'hidden-ct' : 'row'}>
                    <div className="col-sm-12">
                      <button
                        type="button"
                        className="btn btn-success btn-lg mgTop-ct-20"
                        style={{ width: '100%' }}
                        onClick={() => {
                          handleClose();
                          printJS({
                            printable: 'labelAddressSing',
                            type: 'html',
                            scanStyles: false,
                            css: ['https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap'],
                            style: '@page {size: 10cm 15cm;margin: 0.3cm;}',
                            onLoadingEnd: () => {
                              const filteredData = data.find((x) => x.awb_code === awbCode);
                              refreshParcelStatus([filteredData.code])
                                  .then(resultat => console.log('print status updated'));
                            }
                          });
                        }}
                      >
                        พิมพ์ลาเบล/ที่อยู่
                      </button>
                    </div>
                    <div className={`col-sm-12 ${localStorage.getItem('superkey') !== '1947' ? '' : 'hidden-ct'}`}>
                      <button
                        type="button"
                        className="btn btn-warning btn-lg mgTop-ct-20"
                        style={{ width: '100%' }}
                        onClick={() => {
                          handleClose();
                          printJS({
                            printable: 'labelNormalSing',
                            type: 'html',
                            scanStyles: false,
                            css: ['https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap'],
                            style: '@page {size: 10cm 7cm;margin: 0cm;}',
                            onLoadingEnd: () => {
                              const filteredData = data.find((x) => x.awb_code === awbCode);
                              refreshParcelStatus([filteredData.code])
                                  .then(resultat => console.log('print status updated'));
                            }
                          });
                        }}
                      >
                        พิมพ์ลาเบล
                      </button>
                    </div>
                    <div className={`col-sm-12 ${localStorage.getItem('superkey') === '1947' || localStorage.getItem('superkey') === 'credit' ? '' : 'hidden-ct'}`}>
                      <button
                        type="button"
                        className="btn btn-warning btn-lg mgTop-ct-20"
                        style={{ width: '100%' }}
                        onClick={() => {
                          handleClose();
                          handlePrintShow();
                        }}
                      >
                        พิมพ์ลาเบล (mobile)
                      </button>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </Modal.Body>
            </Modal>

            <div name="modalPrintLabel">
              <Modal show={printShow} onHide={handlePrintClose} size="xl" dialogClassName="modal-content">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <div
                    // className={typeLabel === "1" ? "" : "hidden-ct"}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      width: '90%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  ></div>
                  {data
                    .filter((x) => x.awb_code === awbCode)
                    .map(
                      (
                        value,
                        index, ////ใช้สำหรับพิมพ์ลาเบล/ที่อยู่เป็นชุด
                      ) => (
                        <div key={nanoid()}>
                          {value.platform === '5' ? (
                            <FormatLabelAddressPornor
                              key={nanoid()}
                              supplies={{
                                cust_code: value.cust_code,
                                /////////////////
                                receiver_name: value.receiver_name,
                                receiver_phone: value.receiver_phone,
                                receiver_address: value.receiver_address,
                                receiver_district: value.receiver_district,
                                receiver_amphur: value.receiver_amphur,
                                receiver_province: value.receiver_province,
                                receiver_zipcode: value.receiver_zipcode,
                                /////////////////
                                sender_name: value.sender_name,
                                sender_phone: value.sender_phone,
                                sender_address: value.sender_address,
                                sender_amphoe: value.sender_amphoe,
                                sender_province: value.sender_province,
                                sender_zipcode: value.sender_zipcode,
                                /////////////////
                                cod_cost: value.cod_cost,
                                /////////////////
                                awb_code: value.awb_code,
                                /////////////////
                                weight: value.weight,
                                box_height:value.box_height,
                                box_length: value.box_length,
                                box_size: value.box_size ,
                                box_width: value.box_width,
                                ref_no: value.ref_no,
                                invoice: value.invoice,
                                return_doc: value.return_doc,
                                num_return_doc: value.num_return_doc,
                                ref_no2: value.ref_no2,
                                item_desc: value.item_desc,
                              }}
                            />
                          ) : value.cod_cost === '0' ? (
                            <FormatLabelAddress
                              key={nanoid()}
                              supplies={{
                                receiver_name: value.receiver_name,
                                receiver_address: value.receiver_address,
                                receiver_district: value.receiver_district,
                                receiver_amphur: value.receiver_amphur,
                                receiver_province: value.receiver_province,
                                receiver_phone: value.receiver_phone,
                                cust_Code: value.cust_code,
                                number: index + 1,
                                awb_code: value.awb_code,
                                date: date,
                                month: month,
                                year: year,
                                weight: value.weight,
                                box_height:value.box_height,
                                box_length: value.box_length,
                                box_size: value.box_size ,
                                box_width: value.box_width,
                                codCost: value.cod_cost,
                                deposit_type: value.deposit_type,
                                deposit_fullname: value.deposit_fullname,
                                deposit_phone: value.deposit_phone,
                                sender_name: value.sender_name,
                                ref_no: value.ref_no,
                                invoice: value.invoice,
                                return_doc: value.return_doc,
                                num_return_doc: value.num_return_doc,
                                ref_no2: value.ref_no2,
                                receiver_zipcode: value.receiver_zipcode,
                                item_desc: value.item_desc,
                              }}
                            />
                          ) : (
                            <FormatLabelAddressCod
                              key={nanoid()}
                              supplies={{
                                receiver_name: value.receiver_name,
                                receiver_address: value.receiver_address,
                                receiver_district: value.receiver_district,
                                receiver_amphur: value.receiver_amphur,
                                receiver_province: value.receiver_province,
                                receiver_phone: value.receiver_phone,
                                cust_Code: value.cust_code,
                                number: index + 1,
                                awb_code: value.awb_code,
                                date: date,
                                month: month,
                                year: year,
                                weight: value.weight,
                                box_height:value.box_height,
                                box_length: value.box_length,
                                box_size: value.box_size ,
                                box_width: value.box_width,
                                codCost: value.cod_cost,
                                deposit_type: value.deposit_type,
                                deposit_fullname: value.deposit_fullname,
                                deposit_phone: value.deposit_phone,
                                sender_name: value.sender_name,
                                ref_no: value.ref_no,
                                invoice: value.invoice,
                                return_doc: value.return_doc,
                                num_return_doc: value.num_return_doc,
                                ref_no2: value.ref_no2,
                                receiver_zipcode: value.receiver_zipcode,
                                item_desc: value.item_desc,
                              }}
                            />
                          )}
                        </div>
                      ),
                    )}
                </Modal.Body>
              </Modal>
            </div>

            <div name="modalPrintLabel2">
              <Modal scrollable={true} show={printShow2} onHide={handlePrintClose2} size="xl" dialogClassName="modal-content">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <div
                    // className={typeLabel === "1" ? "" : "hidden-ct"}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      width: '90%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  ></div>
                  {printLebel.map(
                    (
                      value,
                      index, ////ใช้สำหรับพิมพ์ลาเบล/ที่อยู่เป็นชุด
                    ) => (
                      <div key={nanoid()}>
                        {value.platform === '5' ? (
                          <FormatLabelAddressPornor
                            key={nanoid()}
                            supplies={{
                              cust_code: value.cust_code,
                              /////////////////
                              receiver_name: value.receiver_name,
                              receiver_phone: value.receiver_phone,
                              receiver_address: value.receiver_address,
                              receiver_district: value.receiver_district,
                              receiver_amphur: value.receiver_amphur,
                              receiver_province: value.receiver_province,
                              receiver_zipcode: value.receiver_zipcode,
                              /////////////////
                              sender_name: value.sender_name,
                              sender_phone: value.sender_phone,
                              sender_address: value.sender_address,
                              sender_amphoe: value.sender_amphoe,
                              sender_province: value.sender_province,
                              sender_zipcode: value.sender_zipcode,
                              /////////////////
                              cod_cost: value.cod_cost,
                              /////////////////
                              awb_code: value.awb_code,
                              /////////////////
                              weight: value.weight,
                              box_height:value.box_height,
                                box_length: value.box_length,
                                box_size: value.box_size ,
                                box_width: value.box_width,
                              ref_no: value.ref_no,
                              invoice: value.invoice,
                              return_doc: value.return_doc,
                              num_return_doc: value.num_return_doc,
                              ref_no2: value.ref_no2,
                              item_desc: value.item_desc,
                            }}
                          />
                        ) : value.cod_cost === '0' ? (
                          <FormatLabelAddress
                            key={nanoid()}
                            supplies={{
                              receiver_name: value.receiver_name,
                              receiver_address: value.receiver_address,
                              receiver_district: value.receiver_district,
                              receiver_amphur: value.receiver_amphur,
                              receiver_province: value.receiver_province,
                              receiver_phone: value.receiver_phone,
                              cust_Code: value.cust_code,
                              number: index + 1,
                              awb_code: value.awb_code,
                              date: date,
                              month: month,
                              year: year,
                              weight: value.weight,
                              box_height:value.box_height,
                                box_length: value.box_length,
                                box_size: value.box_size ,
                                box_width: value.box_width,
                              codCost: value.cod_cost,
                              deposit_type: value.deposit_type,
                              deposit_fullname: value.deposit_fullname,
                              deposit_phone: value.deposit_phone,
                              sender_name: value.sender_name,
                              ref_no: value.ref_no,
                              invoice: value.invoice,
                              return_doc: value.return_doc,
                              num_return_doc: value.num_return_doc,
                              ref_no2: value.ref_no2,
                              receiver_zipcode: value.receiver_zipcode,
                              item_desc: value.item_desc,
                            }}
                          />
                        ) : (
                          <FormatLabelAddressCod
                            key={nanoid()}
                            supplies={{
                              receiver_name: value.receiver_name,
                              receiver_address: value.receiver_address,
                              receiver_district: value.receiver_district,
                              receiver_amphur: value.receiver_amphur,
                              receiver_province: value.receiver_province,
                              receiver_phone: value.receiver_phone,
                              cust_Code: value.cust_code,
                              number: index + 1,
                              awb_code: value.awb_code,
                              date: date,
                              month: month,
                              year: year,
                              weight: value.weight,
                              box_height:value.box_height,
                                box_length: value.box_length,
                                box_size: value.box_size ,
                                box_width: value.box_width,
                              codCost: value.cod_cost,
                              deposit_type: value.deposit_type,
                              deposit_fullname: value.deposit_fullname,
                              deposit_phone: value.deposit_phone,
                              sender_name: value.sender_name,
                              ref_no: value.ref_no,
                              invoice: value.invoice,
                              return_doc: value.return_doc,
                              num_return_doc: value.num_return_doc,
                              ref_no2: value.ref_no2,
                              receiver_zipcode: value.receiver_zipcode,
                              item_desc: value.item_desc,
                            }}
                          />
                        )}
                      </div>
                    ),
                  )}
                </Modal.Body>
              </Modal>
            </div>

            <Modal show={show2} onHide={handleClose2} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>
                  แก้ไขข้อมูล <br /> เลขลาเบล : {awbCode}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ fontSize: '19px' }}>
                <Formik
                  validationSchema={printLabelSchema}
                  initialValues={{
                    awb_code: awbCode,
                    sender_type: dataEdit.sender_type,
                    receiver_name: dataEdit.receiver_name,
                    receiver_address: dataEdit.receiver_address,
                    receiver_amphur: dataEdit.receiver_amphur,
                    receiver_province: dataEdit.receiver_province,
                    receiver_phone: dataEdit.receiver_phone,
                    box_size: parseInt(dataEdit.box_size),
                    box_width: parseInt(dataEdit.box_width),
                    box_length: parseInt(dataEdit.box_length),
                    box_height: parseInt(dataEdit.box_height),
                    weight: parseFloat(dataEdit.weight),
                    shipment_cost: parseInt(dataEdit.shipment_cost),
                    cod_Status: parseInt(dataEdit.cod_cost) > 0 ? 1 : 0,
                    cod_cost: parseInt(dataEdit.cod_cost),
                    service_cod: parseInt(dataEdit.service_cod),
                    bank_id: bank.filter((x) => x.bank_id === dataEdit.bank_id).map((value) => value.bankName)[0],
                    bookbank_code: dataEdit.bookbank_code,
                    bookbank_name: dataEdit.bookbank_name,
                    total: parseInt(dataEdit.shipment_cost) + parseInt(dataEdit.service_cod),
                    invoice: dataEdit.invoice,
                    ref_no: dataEdit.ref_no,
                    num_return_doc: dataEdit.num_return_doc,
                  }}
                  onSubmit={(value) => {
                    UpdateDataLabel(value);
                  }}
                >
                  {({ errors, touched, handleBlur, setFieldValue, values }) => (
                    <Form>
                      <div className="row" style={{ justifyContent: 'flex-end', margin: '10px' }}>
                        <div className="col col-sm-12 px-md-1 mt-1">
                          <sppan>ชื่อผู้รับ</sppan>
                          <input
                            className={'form-control ' + (getIn(touched, `receiver_name`) ? (getIn(errors, `receiver_name`) ? 'is-invalid' : 'is-valid') : '')}
                            type="text"
                            name="receiver_name"
                            onBlur={handleBlur}
                            value={values.receiver_name}
                            onChange={(e) => {
                              setFieldValue('receiver_name', e.target.value);
                            }}
                          />
                          <ErrorMessage component="div" name="receiver_name" className="invalid-feedback" />
                        </div>
                        <div className="col col-sm-12 px-md-1 mt-1">
                          <sppan>ที่อยู่ผู้รับ</sppan>
                          <input
                            className={'form-control ' + (getIn(touched, `receiver_address`) ? (getIn(errors, `receiver_address`) ? 'is-invalid' : 'is-valid') : '')}
                            type="text"
                            name="receiver_address"
                            onBlur={handleBlur}
                            value={values.receiver_address}
                            onChange={(e) => {
                              setFieldValue('receiver_address', e.target.value);
                            }}
                          />
                          <ErrorMessage component="div" name="receiver_address" className="invalid-feedback" />
                        </div>
                        <div className="col col-sm-12 px-md-1 mt-1">
                          <span>ค้นหาี่อยู่ (ตำบล, อำเภอ, จังหวัด, รหัสไปรณีย์)</span>
                          <InputAutocompleteV2
                            id={`searchAddess ${1}`}
                            retrunValue={async (v) => {
                              var result = await calculatedPrice(values.box_size, values.weight, 0, v.districtName);
                              const total = result.data.price + result.data.zipCode_fee;
                              setFieldValue('receiver_province', v.provinceName);
                              setFieldValue('receiver_amphur', v.districtName);
                              setFieldValue('shipment_cost', total);
                              setFieldValue('total', total + values.service_cod);
                            }}
                            retrunValueOnBlur={(e) => {
                              handleBlur(e);
                            }}
                            className={'form-control'}
                          />
                        </div>
                        <div className="col col-sm-4 px-md-1 mt-1">
                          <sppan>อำเภอ</sppan>
                          <input
                            className={'form-control ' + (getIn(touched, `receiver_amphur`) ? (getIn(errors, `receiver_amphur`) ? 'is-invalid' : 'is-valid') : '')}
                            type="text"
                            name="receiver_amphur"
                            onBlur={handleBlur}
                            value={values.receiver_amphur}
                            readOnly
                          />
                          <ErrorMessage component="div" name="receiver_amphur" className="invalid-feedback" />
                        </div>
                        <div className="col col-sm-4 px-md-1 mt-1">
                          <sppan>จังหวัด</sppan>
                          <input
                            className={'form-control ' + (getIn(touched, `receiver_province`) ? (getIn(errors, `receiver_province`) ? 'is-invalid' : 'is-valid') : '')}
                            type="text"
                            name="receiver_province"
                            onBlur={handleBlur}
                            value={values.receiver_province}
                            readOnly
                          />
                          <ErrorMessage component="div" name="receiver_province" className="invalid-feedback" />
                        </div>
                        {isShop || (localStorage.getItem('requireDimension') === "0" && localStorage.getItem('superkey') === 'credit') ?
                        "" : (
                          <div className="col-sm-4 px-md-1 ">
                          <div className="form-group">
                            <span>น้ำหนัก (กก.)</span>
                            {localStorage.getItem('superkey') === '1947' ? (
                              <p style={{ border: '1px solid #ced4da', borderRadius: '5px', padding: '5px' }}>{values.weight + ' '} </p>
                            ) : (
                              <input
                                // autocomplete="off"
                                value={values.weight}
                                type="number"
                                name={'weight'}
                                className={'form-control  ' + (getIn(touched, `weight`) ? (getIn(errors, `weight`) ? 'is-invalid' : 'is-valid') : '')}
                                onBlur={handleBlur}
                                onChange={async (e) => {
                                    setFieldValue('weight', e.target.value);                  
                                    var result = await calculatedPrice(values.box_size, e.target.value, 0, values.receiver_amphur);
                                    const total = result.data.price + result.data.zipCode_fee;
                                    setFieldValue('shipment_cost', total);
                                    setFieldValue('total', total + values.service_cod);
                                }}
                              />
                            )}
                            <ErrorMessage component="div" name={`weight`} className="invalid-feedback" />
                          </div>
                        </div>
                        )}
                        
                        {isShop || (localStorage.getItem('requireDimension') === "0" && localStorage.getItem('superkey') === 'credit') ? "": (
                          <div className="col col-sm-4 px-md-1 mt-1">
                          <sppan>ความกว้าง (ซม.)</sppan>
                          {localStorage.getItem('superkey') === '1947' ? (
                            <p style={{ border: '1px solid #ced4da', borderRadius: '5px', padding: '5px' }}>{values.box_width + ' '} </p>
                          ) : (
                            <input
                              className={'form-control ' + (getIn(touched, `box_width`) ? (getIn(errors, `box_width`) ? 'is-invalid' : 'is-valid') : '')}
                              type="number"
                              name="box_width"
                              onBlur={handleBlur}
                              value={values.box_width}
                              onChange={async (e) => {
                                setFieldValue('box_width', e.target.value);
                                if (e.target.value !== '' && values.box_length !== '' && values.box_height !== '') {
                                  var sizeTotal = 0;
                                  sizeTotal = parseInt(e.target.value) + parseInt(values.box_length) + parseInt(values.box_height);
                                  setFieldValue('box_size', sizeTotal);
                                  var result = await calculatedPrice(sizeTotal, values.weight, 0, values.receiver_amphur);
                                  const total = result.data.price + result.data.zipCode_fee;
                                  setFieldValue('shipment_cost', total);
                                  setFieldValue('total', total + values.service_cod);
                                }
                              }}
                            />
                          )}
                          <ErrorMessage component="div" name="box_width" className="invalid-feedback" />
                        </div>
                        )}
                        {isShop || (localStorage.getItem('requireDimension') === "0" && localStorage.getItem('superkey') === 'credit') ? "": (
                        <div className="col col-sm-4 px-md-1 mt-1">
                          <sppan>ความยาว (ซม.)</sppan>
                          {localStorage.getItem('superkey') === '1947' ? (
                            <p style={{ border: '1px solid #ced4da', borderRadius: '5px', padding: '5px' }}>{values.box_length + ' '}</p>
                          ) : (
                            <input
                              className={'form-control ' + (getIn(touched, `box_length`) ? (getIn(errors, `box_length`) ? 'is-invalid' : 'is-valid') : '')}
                              type="number"
                              name="box_length"
                              onBlur={handleBlur}
                              value={values.box_length}
                              onChange={async (e) => {
                                setFieldValue('box_length', e.target.value);
                                if (e.target.value !== '' && values.box_width !== '' && values.box_height !== '') {
                                  var sizeTotal = 0;
                                  sizeTotal = parseInt(e.target.value) + parseInt(values.box_width) + parseInt(values.box_height);
                                  setFieldValue('box_size', sizeTotal);
                                  var result = await calculatedPrice(sizeTotal, values.weight, 0, values.receiver_amphur);
                                  const total = result.data.price + result.data.zipCode_fee;
                                  setFieldValue('shipment_cost', total);
                                  setFieldValue('total', total + values.service_cod);
                                }
                              }}
                            />
                          )}
                          <ErrorMessage component="div" name="box_length" className="invalid-feedback" />
                        </div>)}
                        {isShop || (localStorage.getItem('requireDimension') === "0" && localStorage.getItem('superkey') === 'credit') ? "": (
                          <div className="col col-sm-4 px-md-1 mt-1">
                          <sppan>ความสูง (ซม.)</sppan>
                          {localStorage.getItem('superkey') === '1947' ? (
                            <p style={{ border: '1px solid #ced4da', borderRadius: '5px', padding: '5px' }}>{values.box_height + ' '}</p>
                          ) : (
                            <input
                              className={'form-control ' + (getIn(touched, `box_height`) ? (getIn(errors, `box_height`) ? 'is-invalid' : 'is-valid') : '')}
                              type="number"
                              name="box_height"
                              onBlur={handleBlur}
                              value={values.box_height}
                              onChange={async (e) => {
                                setFieldValue('box_height', e.target.value);
                                if (e.target.value !== '' && values.box_width !== '' && values.box_length !== '') {
                                  var sizeTotal = 0;
                                  sizeTotal = parseInt(e.target.value) + parseInt(values.box_width) + parseInt(values.box_length);
                                  setFieldValue('box_size', sizeTotal);
                                  var result = await calculatedPrice(sizeTotal, values.weight, 0, values.receiver_amphur);
                                  const total = result.data.price + result.data.zipCode_fee;
                                  setFieldValue('shipment_cost', total);
                                  setFieldValue('total', total + values.service_cod);
                                }
                              }}
                            />
                          )}
                          <ErrorMessage component="div" name="box_height" className="invalid-feedback" />
                          </div>
                        )}
                        {(localStorage.getItem('requireDimension') === "0" && localStorage.getItem('superkey') === 'credit') ? "" :
                        (
                          <div className="col col-sm-4 px-md-1 mt-1">
                          <sppan>กว้าง + ยาว + สูง (ซม.)</sppan>
                          <input
                              name="box_size"
                              className={'form-control ' + (getIn(touched, `box_height`) ? (getIn(errors, `box_size`) ? 'is-invalid' : 'is-valid') : '')}
                              value={values.box_size} readOnly />
                          <ErrorMessage component="div" name="box_size" className="invalid-feedback" />
                        </div>
                        )}
                        { isShop ? (
                          <div className="col col-sm-4 px-md-1 mt-1">
                          <sppan>กว้าง + ยาว + สูง (ซม.)</sppan>
                          <input
                              name="box_size"
                              className={'form-control ' + (getIn(touched, `box_size`) ? (getIn(errors, `box_size`) ? 'is-invalid' : 'is-valid') : '')}
                              value={values.box_size}
                              onChange={async (e) => {
                                setFieldValue('box_size', e.target.value);
                                var result = await calculatedPrice(e.target.value, values.weight, 0, values.receiver_amphur);
                                const total = result.data.price + result.data.zipCode_fee;
                                setFieldValue('shipment_cost', total);
                                setFieldValue('total', total + values.service_cod);
                              }}
                               />
                          <ErrorMessage component="div" name="box_size" className="invalid-feedback" />
                        </div>
                        ):" "}
                        
                        {localStorage.getItem('superkey')==='credit' ? (
                          <div className="col col-sm-4 px-md-1 mt-1">
                          <span>หมายเลขinvoice</span>
                          <input
                              name="invoice"
                              className={'form-control ' + (getIn(touched, `invoice`) ? (getIn(errors, `invoice`) ? 'is-invalid' : 'is-valid') : '')}
                              value={values.invoice}
                              onChange={async (e) => {
                                setFieldValue('invoice', e.target.value);
                                }}
                          />
                          <ErrorMessage component="div" name="invoice" className="invalid-feedback" />
                        </div>
                        ):""}
                        {localStorage.getItem('superkey')==='credit' ? (
                          <div className="col col-sm-4 px-md-1 mt-1">
                          <span>เลขที่co note</span>
                          <input
                              name="ref_no"
                              className={'form-control ' + (getIn(touched, `ref_no`) ? (getIn(errors, `ref_no`) ? 'is-invalid' : 'is-valid') : '')}
                              value={values.ref_no}
                              onChange={async (e) => {
                                setFieldValue('ref_no', e.target.value);
                                }}
                          />
                          <ErrorMessage component="div" name="ref_no" className="invalid-feedback" />
                        </div>
                        ):""}
                        {localStorage.getItem('superkey')==='credit' ? (
                          <div className="col col-sm-4 px-md-1 mt-1">
                          <span>จำนวนเอกสารนำกลับ</span>
                          <input
                              name="num_return_doc"
                              className={'form-control ' + (getIn(touched, `num_return_doc`) ? (getIn(errors, `num_return_doc`) ? 'is-invalid' : 'is-valid') : '')}
                              value={values.num_return_doc}
                              onChange={async (e) => {
                                setFieldValue('num_return_doc', e.target.value);
                                }}
                               />
                          <ErrorMessage component="div" name="num_return_doc" className="invalid-feedback" />
                        </div>
                        ):""}
                        {hideCost ? (
                          ""
                        ):(
                          <div className="col col-sm-4 px-md-1 mt-1">
                          <sppan>ค่าขนส่ง (บาท)</sppan>
                          <input className="form-control" value={values.shipment_cost} readOnly />
                          </div>
                        )}
                        <div className={values.cod_cost === 0 ? 'hidden-ct' : 'col col-sm-4 px-md-1 mt-1'}>
                          <sppan>ธนาคาร</sppan>
                          <input readOnly className="form-control" value={values.bookbank_name} />
                        </div>
                        <div className={values.cod_cost === 0 ? 'hidden-ct' : 'col col-sm-4 px-md-1 mt-1'}>
                          <sppan>ชื่อบัญชี</sppan>
                          <input readOnly className="form-control" value={values.bookbank_code} />
                        </div>
                        <div className='col col-sm-4 px-md-1 mt-1'>
                          <span>หมายเลขโทรศัพท์</span>
                          <input
                              onBlur={handleBlur}
                              name="receiver_phone"
                              onChange={(e) => {
                                setFieldValue('receiver_phone', e.target.value);
                              }}
                              className={'form-control ' + (getIn(touched, `receiver_phone`) ? (getIn(errors, `receiver_phone`) ? 'is-invalid' : 'is-valid') : '')}
                              value={values.receiver_phone} />
                          <ErrorMessage component="div" name="receiver_phone" className="invalid-feedback" />
                        </div>
                        <div className={values.cod_cost === 0 ? 'hidden-ct' : 'col col-sm-4 px-md-1 mt-1'}>
                          <sppan>COD (บาท)</sppan>
                          <input
                            className={'form-control ' + (getIn(touched, `cod_cost`) ? (getIn(errors, `cod_cost`) ? 'is-invalid' : 'is-valid') : '')}
                            type="number"
                            name="cod_cost"
                            onBlur={handleBlur}
                            value={values.cod_cost}
                            onChange={(e) => {
                              setFieldValue('cod_cost', e.target.value);
                              var serviceCod = 0;
                              serviceCod = Math.round((e.target.value / 100) * 3);
                              if (serviceCod < 5) {
                                serviceCod = 5;
                              }
                              if (e.target.value.length === 0) {
                                serviceCod = 0;
                              }
                              setFieldValue('service_cod', serviceCod);
                              setFieldValue('total', serviceCod + values.shipment_cost);
                            }}
                          />
                          <ErrorMessage component="div" name="cod_cost" className="invalid-feedback" />
                        </div>
                        {hideCost ? (
                          ""
                        ): (
                          <div className={values.cod_cost === 0 ? 'hidden-ct' : 'col col-sm-4 px-md-1 mt-1'}>
                            <sppan>ค่าบริการ COD (บาท)</sppan>
                            <input className="form-control" value={values.service_cod} readOnly />
                          </div>
                        )}
                        {hideCost ? (
                          ""
                        ): (
                          <div className={values.cod_cost === 0 ? 'col col-sm-6 px-md-1 mt-1' : 'col col-sm-4 px-md-1 mt-1'}>
                            <sppan>ยอดสุทธิ (บาท)</sppan>
                            <input className="form-control" value={values.total} readOnly />
                          </div>
                        )}
                      </div>
                      <br />
                      <center>
                        <button className="btn btn-primary" type="submit">
                          บันทึก
                        </button>
                        &nbsp;&nbsp;
                        <button className="btn btn-secondary" type="button" onClick={handleClose2}>
                          ยกเลิก
                        </button>
                      </center>
                      <br />
                    </Form>
                  )}
                </Formik>
              </Modal.Body>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.Authentication,
});

export default connect(mapStateToProps)(PrintLabel);
