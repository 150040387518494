var initialState = {
  language: localStorage.getItem("language"),
};

export default function languageAction(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_LANGUAGE":
      localStorage.setItem("language", action.language);
      return {
        ...state,
        language: localStorage.getItem("language"),
      };
    case "SELECT_LANGUAGE":
      return {
        ...state,
        language: localStorage.getItem("language"),
      };
    default:
      return {
        ...state,
        language: localStorage.getItem("language"),
      };
  }
}
