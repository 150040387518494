import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import LongDateTH from "../../../utility/LongDateTH";

export default function ShowData({
  data,
  page,
  loading,
  errorMassNoData,
  pageNo,
  handleChangePage,
  perPage,
}) {
  return (
    <>
      <div style={{ overflow: "Auto" }}>
        <table className="table table-bordered">
          <thead className="table-blue">
            <tr>
              <th>ลำดับ</th>
              <th>วันที่เปิดบิล</th>
              <th>วันที่สแกนพัสดุ</th>
              <th>ลาเบล</th>
              <th>ชื่อผู้ส่ง</th>
              <th>ชื่อผู้รับ</th>
              <th>สถานะล่าสุด</th>
              <th>น้ำหนัก</th>
              <th>COD (บาท)</th>
              <th>แสกนสถานะ</th>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => (
              <tr key={index}>
                <td>{(page.current_page - 1) * perPage + (index + 1)}</td>
                <td>{LongDateTH(value.created_date)[0]}</td>
                <td>{LongDateTH(value.Receiver_Date)[0]}{" " + value.Receiver_Time}</td> 
                <td>
                  <p> {value.ref_no === null || value.ref_no === '' ? '': value.ref_no} </p>
                  <p> {value.awb_code} </p>
                </td>
                <td>
                  <p>{value.sender_name}</p>
                  <p>{value.sender_phone}</p>
                </td>
                <td>
                  <p>{value.receiver_name}</p>
                  <p>ที่อยู่ : {value.receiver_address + " " + value.receiver_amphur + " " 
                  + value.receiver_amphur + " " + value.receiver_province }</p>
                  <p>โทร : {value.receiver_phone}</p>
                </td>
                <td> {value.shipment_status_name}
                  {/* {value.shipment_status === "1"
                    ? "รับเข้าระบบ"
                    : value.shipment_status === "2"
                    ? "ศูนย์กระจายคัดแยกสินค้า"
                    : value.shipment_status === "3"
                    ? "อยู่ระหว่างขนส่ง"
                    : value.shipment_status === "4"
                    ? "ส่งภายในวันนี้"
                    : value.shipment_status === '5' 
                    ? 'รับแล้ว'

                    :value.shipment_status === '6' 
                    ? 'ติดต่อผู้รับไม่ได้'

                    :value.shipment_status === '7' 
                    ? 'พัสดุถูกส่งกลับต้นทาง'

                    :value.shipment_status === '8'
                     ? 'ที่อยู่/เบอร์โทรไม่ถูกต้อง'

                    :value.shipment_status === '9' 
                    ? 'ลูกค้าปฏิเสธรับ'

                    :value.shipment_status === '10' 
                    ? 'ลูกค้าเลื่อนรับ'

                    :value.shipment_status === '11' 
                    ? 'สินค้าสูญหาย'

                    :value.shipment_status === '12' 
                    ? 'สินค้าเสียหาย'

                    :value.shipment_status === '13' 
                    ? 'พื้นที่ผิด'

                    :value.shipment_status = 'C' 
                    ? 'ยกเลิก หรือ หมดอายุ'
                    : ""} */}
                </td>
                <td>{value.weight}</td>
                <td>{value.cod_cost?parseFloat(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 }):"0.00"}</td>
                <td>{value.scanned_status}</td>
                
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <center>
          {errorMassNoData === 1 && loading !== 1 ? (
            <font color="red" size="3">
              {" "}
              ไม่พบข้อมูล!{" "}
            </font>
          ) : (
            ""
          )}
        </center>
      </div>
      <center>
        {loading === 1 ? (
          <div className="m-5">
            <div className="text-center">
              <div className="spinner-border  text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              {" ..."}
              กำลังโหลดข้อมูล
            </div>
          </div>
        ) : (
          ""
        )}
      </center>
      <div className="d-flex justify-content-between">
        <div>จำนวนทั้งหมด {page.total} รายการ</div>
        <div>
          <Pagination
            count={parseInt(page.last_page)}
            page={pageNo}
            onChange={handleChangePage}
            color="primary"
            size="small"
            defaultPage={6}
            siblingCount={1}
          />
        </div>
      </div>
      <br />
      <br />
    </>
  );
}
