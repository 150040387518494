import {useState} from "react";
import {hubRemainingParcels} from "../../../../Service/service.Parcels";

const useHubRemainingCOD = () => {
    const [result,setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetch = async (keyword, page) => {
        setError(null);
        setResult(null);
        setLoading(true);
        try{
            const result = await hubRemainingParcels(page,keyword);
            setResult(result.data.data);
        }
        catch (error){
            setError(error.message);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        result,
        error,
        loading,
        fetch
    }
}

export default useHubRemainingCOD;