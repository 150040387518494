import * as Yup from 'yup';

const printLabelSchema = Yup.object().shape({
  receiver_name: Yup.string()
    .required('กรุณากรอก ชื่อ-นามสกุล')
    .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, 'ไม่อนุญาตให้ใช้อักขระพิเศษ'),
  receiver_address: Yup.string()
    .required('กรุณากรอก เลขที่อยู่')
    .matches(/^[ก-๏a-zA-Z0-9./()_-\s]+$/, 'กรุณาตรวจสอบ เลขที่อยู่ อีกครั้ง'),
  receiver_amphur: Yup.string().required('กรุณากรอก อำเภอ '),
  receiver_province: Yup.string().required('กรุณากรอก จังหวัด '),
  cod_cost: Yup.number(),
});

export default printLabelSchema;
