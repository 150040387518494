import {useTranslation} from "react-i18next";
import {ThaiDatepicker} from "../../../Components/Datepicker/thai-datepicker";
import React, {useState} from "react";
import * as Icon from "react-bootstrap-icons";

export const  ScanDateFilter = ({dates, setDates}) => {

    const {t} = useTranslation();
    const [dateFilters, setDateFilters] = useState({
        ...dates,
    });

    return <>
        <div className="w-full">
            <div className="w-full d-flex flex-row" style={{gap: '5px'}}>
                <div className="form-group">
                    <ThaiDatepicker isTimePicker={true} label={t('hub.filters.startDate')} date={dateFilters.startDate} onChange={(date) =>  {
                        setDateFilters({
                            ...dateFilters,
                            startDate: date,
                        });
                    }}/>
                </div>
                <div className="form-group">
                    <ThaiDatepicker isTimePicker={true} label={t('hub.filters.endDate')} date={dateFilters.endDate} onChange={(date) =>  {
                        setDateFilters({
                            ...dateFilters,
                            endDate: date,
                        });
                    }}/>
                </div>
            </div>
            <button
                type="button"
                className={"btn btn-primary w-full"}
                onClick={() => setDates({...dateFilters})}
            >
                <Icon.Search/>  {t('common.search')}
            </button>
        </div>
    </>
};