import {addImage} from "../../../../utility/tableToExcel";
import {ExportProgressStatus} from "../../../../enums/ExportProgressStatus";

export const handleImageFetch = (workbook,worksheet, exportData, setExportProgression) => {
    const promiseArray = [];
    let progression = 0;
    exportData.forEach((data, index) => {
        const row = worksheet.addRow({
            'วันเปิดบิล': data['วันเปิดบิล'],
            'รหัสลูกค้า': data['รหัสลูกค้า'],
            'เลขลาเบล': data['เลขลาเบล'],
            'เลขที่conote': data['conote'],
            'ผู้ส่ง': data['ผู้ส่ง'],
            'ผู้รับ': data['ผู้รับ'],
            'เบอร์โทรผู้รับ': data["เบอร์โทรผู้รับ"],
            'ที่อยู่': data["ผู้รับที่อยู่"],
            'ตำบล' : data["district"],
            'อำเภอ' : data["amphur"],
            'จังหวัด': data["province"],
            'ผู้รับที่อยู่': data['ผู้รับที่อยู่'],
            'น้ำหนัก': data['weight'],
            'ความกว้าง (ซม.)': data['box_width'],
            'ความยาว (ซม.)': data['box_length'],
            'ความสูง (ซม.)': data['box_height'],
            'ขนาด(ซ.ม.)': data['box_size'],
            'จำนวน(ชิ้น)': data['จำนวน(ชิ้น)'],
            'COD (บาท)': data['COD'],
            'ค่าขนส่ง(บาท)': data['ค่าขนส่ง(บาท)'],
            'ชื่อธนาคาร' : data['ชื่อธนาคาร'],
            'ชื่อบัญชี' : data['ชื่อบัญชี'],
            'เลขที่บัญชี' : data['เลขที่บัญชี'],
            'เลขสำหรับลูกค้า 1' : data['ref_no2'],
            'เลขสำหรับลูกค้า 2' : data['item_desc'],
            'เลขสำหรับลูกค้า 3': data['item_sku'],
            'จำนวนเอกสารนำกลับ' : data['num_return_doc'],
            'สถานะ': data['สถานะ'],
            'วันที่เซ็นรับ' : data['receiver_date'],
            'รหัสพนักงานขนส่ง': data['latest_scan'],
            'ชื่อพนักงานขนส่ง': data['scan_name'],
        });
        if(data['image'] !== '' && data['image'] !== undefined){
            const imagePromise = addImage(data['image'], workbook, worksheet,30,row);
            imagePromise.then(image => {
                progression++;
                setExportProgression((state) => ({
                    ...state,
                    current: progression
                }));
            });
            imagePromise.catch(error => {
                console.error(error);
                progression++;
                setExportProgression((state) => ({
                    ...state,
                    current: progression
                }));
            });
            promiseArray.push(
                imagePromise
            );
        }
        else{
            progression++;
            setExportProgression((state) => ({
                ...state,
                current: progression
            }));
        }
    });
    return Promise.all(promiseArray);
};

export const getExportStatus = (exportData) => {
    if(!exportData){
        return ExportProgressStatus.DATA_DOWNLOADING;
    }
    return ExportProgressStatus.PROCESSING_GENERATION;
};