import React from 'react';
import { Formik, Form, FieldArray, ErrorMessage, getIn } from 'formik';
import { nanoid } from 'nanoid';
import * as Icon from 'react-bootstrap-icons';
import InputAutocompleteV2 from '../../../Components/inputAutocompleteV2';
import { bank } from '../../../Data/bank.json';
import parcelPornorValidate from './parcelPornorValidate';
import { PriceCalculation } from '../../../Components/PriceCalculation';
import FormReactSelect from '../../../Components/FormReactSelect';
import {calculatedPostPrice, multiplePriceCalculation} from "../../../Service/service.Parcels";

export default function Pornor({ SaveData, printLebel, loading, handleShow, setDataArray, setStatusPrint, checkCitizen, dataCheck }) {
  return (
    <>
      <Formik
        validationSchema={parcelPornorValidate}
        initialValues={{
          sender: {
            citizenID: '',
            fullName: '',
            phoneNumber: '',
            district: '',
            amphoe: '',
            province: '',
            zipcode: '',
            typeSender: '2',
            customerGroup: '3',
            homeNumber: '',
            custCode: localStorage.getItem('username'),
            province_shop: localStorage.getItem('province_code') === 'กรุงเทพฯ' ? 'กรุงเทพมหานคร' : localStorage.getItem('province_code'),
            amphur_shop: localStorage.getItem('amphur_code'),
          },
          recipient: [
            {
              key: nanoid(),
              fullName: '',
              phoneNumber: '',
              homeNumber: '',
              deposit_type: '1',
              deposit_fullname: '',
              deposit_phone: '',
              district: '',
              amphoe: '',
              province: '',
              zipcode: '',
              materialSizeWide: '0',
              materialSizeLong: '0',
              materialSizeHigh: '0',
              materialSize: '0',
              materialWeight: '',
              materialCode: false,
              materialAccountName: '',
              materialAccountNumber: '',
              materialPriceCode: '0',
              bankName: '',
              total: 0,
              serviceCod: 0,
              totalNet: 0,
            },
          ],
        }}
        onSubmit={(value, { resetForm }) => {
          // SaveData(value);
          setDataArray(value);
          handleShow();
          resetForm();
        }}
      >
        {({ errors, touched, handleBlur, setFieldValue, values }) => (
          <Form>
            <div className="card-supplies  mb-3  px-md-1 ">
              <div className="card-body">
                <b className="mb-5 ">ข้อมูลผู้ส่ง</b>
                <div className="row mt-1">
                  <div className="col-md-2 col-12  px-md-1 ">
                    <div className="form-group">
                      <label htmlFor="citizenID">เลขประจำตัวประชาชน</label>
                      <span className="important-ct">*</span>
                      <input
                        // autocomplete="off"
                        value={values.sender.citizenID}
                        type="text"
                        name="sender.citizenID"
                        className={'form-control ' + (getIn(touched, `sender.citizenID`) ? (getIn(errors, `sender.citizenID`) ? 'is-invalid' : 'is-valid') : '')}
                        onChange={async (e) => {
                          e.preventDefault();
                          setFieldValue(`sender.citizenID`, e.target.value);
                          if (e.target.value.length === 13) {
                            let result = await checkCitizen({
                              citizen: e.target.value,
                            });
                            setFieldValue(`sender.fullName`, result.sender_name);
                            setFieldValue(`sender.phoneNumber`, result.sender_phone);
                          } else {
                            setFieldValue(`sender.fullName`, '');
                            setFieldValue(`sender.phoneNumber`, '');
                          }
                        }}
                        aria-label="citizenID"
                        onBlur={handleBlur}
                      />
                      <ErrorMessage component="div" name="sender.citizenID" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-md-2 col-12  px-md-1 ">
                    <div className="form-group">
                      <label htmlFor="sender.fullName">ชื่อ-นามสกุล</label>
                      <span className="important-ct">*</span>
                      <input
                        // autocomplete="off"
                        value={values.sender.fullName}
                        type="text"
                        name="sender.fullName"
                        className={'form-control ' + (getIn(touched, `sender.fullName`) ? (getIn(errors, `sender.fullName`) ? 'is-invalid' : 'is-valid') : '')}
                        onChange={(e) => {
                          setFieldValue(`sender.fullName`, e.target.value);
                        }}
                        onBlur={handleBlur}
                        aria-label="sender.fullName"
                      />
                      <ErrorMessage component="div" name="sender.fullName" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-md-2 col-12  px-md-1 ">
                    <div className="form-group">
                      <label htmlFor="sender.phoneNumber">เบอร์โทรศัพท์</label>
                      <span className="important-ct">*</span>
                      <input
                        // autocomplete="off"
                        value={values.sender.phoneNumber}
                        type="text"
                        onBlur={handleBlur}
                        name="sender.phoneNumber"
                        className={'form-control ' + (getIn(touched, `sender.phoneNumber`) ? (getIn(errors, `sender.phoneNumber`) ? 'is-invalid' : 'is-valid') : '')}
                        onChange={(e) => {
                          setFieldValue(`sender.phoneNumber`, e.target.value);
                        }}
                        aria-label="sender.phoneNumber"
                      />
                      <ErrorMessage component="div" name="sender.phoneNumber" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-md-1 ">
                    <div className="form-group">
                      <label>ค้นหาที่อยู่ (ตำบล อำเภอ จังหวัด รหัสไปรณีย์)</label>
                      <InputAutocompleteV2
                        id={`searchAddress1`}
                        retrunValue={async (v) => {
                          setFieldValue(`sender.district`, v.subdistrictName);
                          setFieldValue(`sender.amphoe`, v.districtName);
                          setFieldValue(`sender.province`, v.provinceName);
                          setFieldValue(`sender.zipcode`, v.postCode);
                          if(v.provinceName != ''){
                            const recipients = values.recipient.map((value,index) => ({
                              weight: value.materialWeight,
                              province: value.province,
                              index,
                            })).filter(row => row.weight>.1 && row.province!="");
                            if(recipients.length > 0){
                              const result = await multiplePriceCalculation(v.provinceName,recipients);
                              console.log(result);
                              result.data?.data?.forEach((row) => {
                                setFieldValue(`recipient[${row.index}].total`, row.value);
                                setFieldValue(`recipient[${row.index}].totalNet`, row.value + values.recipient[row.index].serviceCod);
                              });
                            }
                          }
                        }}
                        retrunValueOnBlur={(e) => {
                          handleBlur(e);
                        }}
                        className={'form-control'}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-12 px-md-1 ">
                    <div className="form-group">
                      <label htmlFor={`homeNumber`}>เลขที่อยู่</label>
                      <span className="important-ct">*</span>
                      <input
                        // autocomplete="off"
                        value={values.sender.homeNumber}
                        type="text"
                        className={'form-control  ' + (getIn(touched, `sender.homeNumber`) ? (getIn(errors, `sender.homeNumber`) ? 'is-invalid' : 'is-valid') : '')}
                        onBlur={handleBlur}
                        aria-describedby="sender.homeNumber"
                        name={`sender.homeNumber`}
                        defaultValue={values.sender.homeNumber}
                        onChange={(e) => {
                          setFieldValue(`sender.homeNumber`, e.target.value);
                        }}
                      />
                      <ErrorMessage component="div" name={`sender.homeNumber`} className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-md-2 px-md-1 ">
                    <div className="form-group">
                      <label>ตำบล</label>
                      <span className="important-ct">*</span>
                      <input value={values.sender.district} readOnly className={'form-control  ' + (getIn(touched, `sender.district`) ? (getIn(errors, `sender.district`) ? 'is-invalid' : 'is-valid') : '')} onBlur={handleBlur} />
                      <ErrorMessage component="div" name={`sender.district`} className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-md-2 px-md-1 ">
                    <div className="form-group">
                      <label>อำเภอ</label>
                      <span className="important-ct">*</span>
                      <input className={'form-control  ' + (getIn(touched, `sender.amphoe`) ? (getIn(errors, `sender.amphoe`) ? 'is-invalid' : 'is-valid') : '')} onBlur={handleBlur} value={values.sender.amphoe} readOnly />
                      <ErrorMessage component="div" name={`sender.amphoe`} className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-md-2 px-md-1 ">
                    <div className="form-group">
                      <label>จังหวัด</label>
                      <span className="important-ct">*</span>
                      <input className={'form-control  ' + (getIn(touched, `sender.province`) ? (getIn(errors, `sender.province`) ? 'is-invalid' : 'is-valid') : '')} onBlur={handleBlur} value={values.sender.province} readOnly />
                      <ErrorMessage component="div" name={`sender.province`} className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-md-2 px-md-1 ">
                    <div className="form-group">
                      <label>รหัสไปรณีย์</label>
                      <span className="important-ct">*</span>
                      <input className={'form-control  ' + (getIn(touched, `sender.zipcode`) ? (getIn(errors, `sender.zipcode`) ? 'is-invalid' : 'is-valid') : '')} onBlur={handleBlur} value={values.sender.zipcode} readOnly />
                      <ErrorMessage component="div" name={`sender.zipcode`} className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="col-md-2 px-md-1 "></div>
                </div>
              </div>
            </div>
            <div className="row">
              <FieldArray name="recipient">
                {({ push, remove }) =>
                  values.recipient.map((value, index) => (
                    <div className="col-12 col-md-12  mb-3" key={value.key}>
                      <div className="card-supplies">
                        <div className="card-body">
                          <div className="flex-space-between">
                            <b>
                              ข้อมูลผู้รับ
                              {values.recipient.length === 1 ? ' ' : ' ' + (index + 1)}
                            </b>
                            <div>
                              <div className=" text-right">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                  <button
                                    className="btn btn-success"
                                    type="button"
                                    onClick={() => {
                                      push({
                                        key: nanoid(),
                                        fullName: '',
                                        phoneNumber: '',
                                        homeNumber: '',
                                        deposit_type: '1',
                                        deposit_fullname: '',
                                        deposit_phone: '',
                                        district: '',
                                        amphoe: '',
                                        province: '',
                                        zipcode: '',
                                        materialSizeWide: '0',
                                        materialSizeLong: '0',
                                        materialSizeHigh: '0',
                                        materialSize: '0',
                                        materialWeight: '',
                                        materialCode: false,
                                        materialAccountName: '',
                                        materialAccountNumber: '',
                                        materialPriceCode: '0',
                                        bankName: '',
                                        total: 0,
                                        serviceCod: 0,
                                        totalNet: 0,
                                      });
                                    }}
                                  >
                                    <Icon.PlusCircleFill />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                    disabled={values.recipient.length === 1}
                                  >
                                    <Icon.TrashFill />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 col-12 px-md-1 ">
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].fullName`}>ชื่อ -นามสกุล</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.fullName}
                                  type="text"
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].fullName`) ? (getIn(errors, `recipient[${index}].fullName`) ? 'is-invalid' : 'is-valid') : '')}
                                  aria-describedby={`recipient[${index}].fullName`}
                                  name={`recipient[${index}].fullName`}
                                  defaultValue={value.fullName}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].fullName`, e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].fullName`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2 col-12 px-md-1 ">
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].phoneNumber`}>เบอร์โทรศัพท์</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.phoneNumber}
                                  type="text"
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].phoneNumber`) ? (getIn(errors, `recipient[${index}].phoneNumber`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  aria-describedby={`recipient[${index}].phoneNumber`}
                                  name={`recipient[${index}].phoneNumber`}
                                  defaultValue={value.phoneNumber}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].phoneNumber`, e.target.value);
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].phoneNumber`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-6 col-12 px-md-1 ">
                              <div className="form-group">
                                <label>ค้นหาที่อยู่ (ตำบล อำเภอ จังหวัด รหัสไปรณีย์)</label>
                                <InputAutocompleteV2
                                  id={`searchAddress2`}
                                  retrunValue={async (v) => {
                                    if(parseFloat(value.materialWeight)>.1 && values.sender.province!="" && v.provinceName!=""){
                                      console.log('getting total');
                                      var total = (await calculatedPostPrice(value.materialWeight, values.sender.province,v.provinceName)).data.data;
                                      setFieldValue(`recipient[${index}].total`, total);
                                      setFieldValue(`recipient[${index}].totalNet`, total + value.serviceCod);
                                    }
                                    setFieldValue(`recipient[${index}].district`, v.subdistrictName);
                                    setFieldValue(`recipient[${index}].amphoe`, v.districtName);
                                    setFieldValue(`recipient[${index}].province`, v.provinceName);
                                    setFieldValue(`recipient[${index}].zipcode`, v.postCode);

                                  }}
                                  retrunValueOnBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                  className={'form-control'}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 col-12 px-md-1 ">
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].homeNumber`}>เลขที่อยู่</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.homeNumber}
                                  type="text"
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].homeNumber`) ? (getIn(errors, `recipient[${index}].homeNumber`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  aria-describedby="homeNumber"
                                  name={`recipient[${index}].homeNumber`}
                                  defaultValue={value.homeNumber}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].homeNumber`, e.target.value);
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].homeNumber`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2 px-md-1 ">
                              <div className="form-group">
                                <label>ตำบล</label>
                                <span className="important-ct">*</span>
                                <input value={value.district} readOnly className={'form-control  ' + (getIn(touched, `recipient[${index}].district`) ? (getIn(errors, `recipient[${index}].district`) ? 'is-invalid' : 'is-valid') : '')} onBlur={handleBlur} />
                                <ErrorMessage component="div" name={`recipient[${index}].district`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2 px-md-1 ">
                              <div className="form-group">
                                <label>อำเภอ</label>
                                <span className="important-ct">*</span>
                                <input className={'form-control  ' + (getIn(touched, `recipient[${index}].amphoe`) ? (getIn(errors, `recipient[${index}].amphoe`) ? 'is-invalid' : 'is-valid') : '')} onBlur={handleBlur} value={value.amphoe} readOnly />
                                <ErrorMessage component="div" name={`recipient[${index}].amphoe`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2 px-md-1 ">
                              <div className="form-group">
                                <label>จังหวัด</label>
                                <span className="important-ct">*</span>
                                <input className={'form-control  ' + (getIn(touched, `recipient[${index}].province`) ? (getIn(errors, `recipient[${index}].province`) ? 'is-invalid' : 'is-valid') : '')} onBlur={handleBlur} value={value.province} readOnly />
                                <ErrorMessage component="div" name={`recipient[${index}].province`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2 px-md-1 ">
                              <div className="form-group">
                                <label>รหัสไปรณีย์</label>
                                <span className="important-ct">*</span>
                                <input className={'form-control  ' + (getIn(touched, `recipient[${index}].zipcode`) ? (getIn(errors, `recipient[${index}].zipcode`) ? 'is-invalid' : 'is-valid') : '')} onBlur={handleBlur} value={value.zipcode} readOnly />
                                <ErrorMessage component="div" name={`recipient[${index}].zipcode`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2 px-md-1 "></div>
                          </div>
                          <div className="row">
                            <div className="col-md-2 px-md-1 ">
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialWeight`}>น้ำหนัก (กก.)</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialWeight}
                                  type="number"
                                  name={`recipient[${index}].materialWeight`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialWeight`) ? (getIn(errors, `recipient[${index}].materialWeight`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialWeight`, e.target.value);
                                    if (e.target.value >= 0.1 && values.sender.province!='' && value.province !='') {
                                      var total = (await calculatedPostPrice(e.target.value, values.sender.province,value.province)).data.data;
                                      setFieldValue(`recipient[${index}].total`, total);
                                      setFieldValue(`recipient[${index}].totalNet`, total + value.serviceCod);
                                    }
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialWeight`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group checkbox-control mt-4">
                                <input
                                  // autocomplete="off"
                                  type="checkbox"
                                  name={`recipient[${index}].materialCode`}
                                  id={`recipient[${index}].materialCode`}
                                  className="checkbox-custom"
                                  checked={value.materialCode === true ? true : false}
                                  onChange={async (e) => {
                                    setFieldValue(`recipient[${index}].materialCode`, e.target.checked);
                                    setFieldValue(`recipient[${index}].bankName`, '');
                                    setFieldValue(`recipient[${index}].materialAccountName`, '');
                                    setFieldValue(`recipient[${index}].materialAccountNumber`, '');
                                    setFieldValue(`recipient[${index}].materialPriceCode`, '0');
                                    setFieldValue(`recipient[${index}].serviceCod`, 0);
                                    setFieldValue(`recipient[${index}].totalNet`, value.total);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <label htmlFor="cod">COD (เก็บเงินปลายทาง)</label>
                              </div>
                            </div>
                            <div className={` col-md-2 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].bankName`}>ธนาคาร</label>
                                <span className="important-ct">*</span>
                                <FormReactSelect
                                  touched={touched}
                                  errors={errors}
                                  data={bank}
                                  values={value.bankName}
                                  onChange={(value) => {
                                    setFieldValue(`recipient[${index}].bankName`, value);
                                  }}
                                  name={`recipient[${index}].bankName`}
                                  labelKey="bankName"
                                  keyValue="bank_id"
                                  errorsMess="กรุณาเลือกธนาคาร"
                                  placeholder="-- กรุณาเลือกธนาคาร --"
                                />
                              </div>
                            </div>
                            <div className={` col-md-2 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor="materialAccountName">ชื่อบัญชี</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialAccountName}
                                  type="text"
                                  name={`recipient[${index}].materialAccountName`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialAccountName`) ? (getIn(errors, `recipient[${index}].materialAccountName`) ? 'is-invalid' : 'is-valid') : '')}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].materialAccountName`, e.target.value);
                                  }}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialAccountName`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={` col-md-2 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor="materialAccountNumber">เลขที่บัญชี</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"
                                  value={value.materialAccountNumber}
                                  type="text"
                                  name={`recipient[${index}].materialAccountNumber`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialAccountNumber`) ? (getIn(errors, `recipient[${index}].materialAccountNumber`) ? 'is-invalid' : 'is-valid') : '')}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].materialAccountNumber`, e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialAccountNumber`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={` col-md-2 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].materialPriceCode`}>จำนวนเงิน COD</label>
                                <span className="important-ct">*</span>
                                <input
                                  // autocomplete="off"

                                  value={value.materialPriceCode === '0' ? '' : value.materialPriceCode}
                                  type="number"
                                  name={`recipient[${index}].materialPriceCode`}
                                  className={'form-control  ' + (getIn(touched, `recipient[${index}].materialPriceCode`) ? (getIn(errors, `recipient[${index}].materialPriceCode`) ? 'is-invalid' : 'is-valid') : '')}
                                  onChange={(e) => {
                                    setFieldValue(`recipient[${index}].materialPriceCode`, e.target.value);
                                    var serviceCod = 0;
                                    serviceCod = Math.round((e.target.value / 100) * 3);
                                    if (serviceCod < 5) {
                                      serviceCod = 5;
                                    }
                                    if (e.target.value.length === 0) {
                                      serviceCod = 0;
                                    }
                                    setFieldValue(`recipient[${index}].serviceCod`, serviceCod);
                                    setFieldValue(`recipient[${index}].totalNet`, serviceCod + value.total);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage component="div" name={`recipient[${index}].materialPriceCode`} className="invalid-feedback" />
                              </div>
                            </div>
                            <div className={` col-md-2 px-md-1   ${value.materialCode ? '' : 'hidden-ct'}`}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].serviceCod`}>ค่าบริการ COD</label>
                                <input name={`recipient[${index}].serviceCod`} value={value.serviceCod.toLocaleString(undefined, { minimumFractionDigits: 2 })} className={'form-control'} readOnly={true} />
                              </div>
                            </div>
                            <div className={` col-md-2 px-md-1 `}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].total`}>ค่าขนส่ง</label>
                                <input name={`recipient[${index}].total`} value={value.total.toLocaleString(undefined, { minimumFractionDigits: 2 })} className={'form-control  '} readOnly={true} />
                              </div>
                            </div>
                            <div className={'col-md-2 px-md-1'}>
                              <div className="form-group">
                                <label htmlFor={`recipient[${index}].totalNet`}>ยอดสุทธิ (บาท)</label>
                                <input name={`recipient[${index}].totalNet`} value={value.totalNet.toLocaleString(undefined, { minimumFractionDigits: 2 })} className={'form-control'} readOnly={true} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
            </div>
            <div className="text-center mt-2">
              <button
                className="btn btn-success"
                type="submit"
                onClick={() => {
                  setStatusPrint(0);
                }}
              >
                ส่งพัสดุ
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
