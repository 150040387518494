import Home from "../Views/public/home/Home";
import About from "../Views/public/about/About";
import Servies from "../Views/public/services/Services";
import Branch from "../Views/public/branch/Branch";
import ServiceRates from "../Views/User/ServiceRates";
import Contactus from "../Views/public/contactus/Contactus";
import PublicLayout from "../Layouts/Public/PublicLayout";
import signUpLayout from "../Layouts/Public/SignUpLayout";
import misLayout from "../Layouts/mis/misLayout";
import LoginLayout from "../Layouts/mis/LoginLayout";
import Tracking from "../Views/public/trackingV2/TrackingV2";
import MGBarcodes from "../Views/mis/manageBarcodesV2/ManageBarcodeV2";
import Login from "../Views/mis/login";
import Parcel from "../Views/mis/Parcel/parcel"; 
import ParcelPickUp from "../Views/mis/ParcelPickUp/parcelPickUp";
import ParcelCollect from "../Views/mis/ParcelCollect/parcelCollect";
import Shops from "../Views/mis/shop/showdata";
import FormShop from "../Views/mis/shop/manager/form";
import Employees from "../Views/mis/employee/showdata";
import FormEmp from "../Views/mis/employee/manager/form";
import ShippingPrices from "../Views/mis/shippingPrices/setViewShippingPrices";
import CallCar from "../Views/mis/callCar/callCar";
import Career from "../Views/public/apply/career";
import Apply from "../Views/public/apply/apply";
import FormApply from "../Views/public/apply/manager/form";
import Error from "../Views/error/error";
import Profile from "../Views/mis/profile/profile";
import ImportExcel from "../Views/mis/importLabel/ImportExcel/importExcel";
import ImportExcelOP from "../Views/mis/importLabel/ImportExcelOP/importExcelOP";
import ImportExcelParcelPickUp from "../Views/mis/importLabel/ImportExcelParcelPickUp/importExcelParcelPickUp";
import SaveGoods from "../Views/mis/saveGoods/saveGoods";
import FilterData from "../Views/mis/recruit/filterData";
import FormRecruit from "../Views/mis/recruit/manager/form";
import PrintMobile from "../Views/mis/printLabelMobile/printMobile";
import ApplyData from "../Views/mis/career/career";
import Detail from "../Views/mis/career/showDatail";
import EditAndDetail from "../Views/mis/career/edit_and_detail";
import Receipt from "../Views/mis/receipt/receipt";
import TrackingReport from "../Views/mis/trackingReport/trackingReport";
import ReportStockIn from "../Views/mis/reportStockV2/reportStock";
import InvoiceReport from "../Views/mis/invoiceReport/invoiceReport";
import PrintLabel from "../Views/mis/printLabel/printLabel";
import PickUpList from "../Views/mis/pickUpList/pickUpList";
import CollectList from "../Views/mis/collectList/collectList";
import ManageCOD from "../Views/mis/finance/manageCOD";
import HistoryCOD from "../Views/mis/finance/historyCOD";
import PublicLogin from "../Views/public/myCOD/publicLogin";
import SignUp from "../Views/public/myCOD/publicSignUp";
import MyCOD from "../Views/public/myCOD/myCOD";
import MyReceipt from "../Views/mis/receipt/myReceipt";
import {ConotePrintModel} from "../Components/ConotePrintModel/ConotePrintModel";
import scanConote from "../Views/mis/scanConote/scanConote";
import PrintContent from "../Views/mis/printLabel/conoteModal/printContentsOLD";
import PriceEstimation from "../Views/public/priceEstimation/priceEstimation";
import ReportStockOut from "../Views/mis/reportStockOut/reportStockOut";
import Size from "../Views/mis/size/size";
import HubMenuRoute from "../Layouts/Hub/menu/hub-menu.route";

import UpdateHistory from "../Views/mis/updateHistory/update-history";
import HubUsersListPage from "../Views/mis/HubUsers/pages/hub-users-list-page";
import HubUsersEditPage from "../Views/mis/HubUsers/pages/hub-users-edit-page";
import ManageConote from "../Views/mis/manageConote/manageConote";

const Routes = [
  {
    path: "/",
    role: 0,
    layout: PublicLayout,
    children: [
      { path: "", element: Home, protect: 0 },
      { path: "Home", element: Home, protect: 0 },
      { path: "About", element: About, protect: 0 },
      { path: "Branch", element: Branch, protect: 0 },
      { path: "Services", element: Servies, protect: 0 },
      { path: "ServiceRates", element: ServiceRates, protect: 0 },
      { path: "Contactus", element: Contactus, protect: 0 },
      { path: "Tracking", element: Tracking, protect: 0 },
      { path: "Career", element: Career, protect: 0 },
      { path: "Apply", element: Apply, protect: 0 },
      { path: "FormApply", element: FormApply, protect: 0 },
      { path: "PriceEstimation", element: PriceEstimation, protect: 0 },
      { path: "PrintContent", element: PrintContent, protect: 0 },
    ],
    protect: 0,
  },
  {
    path: "/",
    role: 0,
    layout: signUpLayout,
    children: [{ path: "signUp", element: SignUp, protect: 0 }],
    protect: 0,
  },
  // {
  //   path: "/",
  //   role: 0,
  //   layout: MyCODLayout,
  //   children: [{ path: "myCOD", element: MyCOD, protect: 0 }],
  //   protect: 0,
  // },
  {
    path: "/",
    role: 0,
    layout: LoginLayout,
    children: [{ path: "login", element: PublicLogin, protect: 0 }],
    protect: 0,
  },
  {
    path: "/mis/",
    layout: misLayout,
    role: 0,
    children: [
      { path: "MGConote", element: ManageConote, protect: 1},
      { path: "ScanConote", element: scanConote, protect: 1},
      { path: "Conote", element: ConotePrintModel, protect: 1},
      { path: "MGBarcodes", element: MGBarcodes, protect: 1 },
      { path: "UpdateHistory/:id", element: UpdateHistory, protect: 1},
      { path: "Employees", element: Employees, protect: 1 },
      { path: "FormEmp", element: FormEmp, protect: 1 },
      { path: "ShippingPrices", element: ShippingPrices, protect: 1 },
      { path: "CallCar", element: CallCar, protect: 1 },
      { path: "Profile", element: Profile, protect: 1 },
      { path: "ImportExcel", element: ImportExcel, protect: 1 },
      { path: "ImportExcelOP", element: ImportExcelOP, protect: 1 },
      { path: "ImportExcelParcelPickUp", element: ImportExcelParcelPickUp, protect: 1 },
      { path: "StockIn", element: SaveGoods, protect: 1 },
      { path: "ReportStockOut", element: ReportStockOut, protect: 1 },
      { path: "FilterData", element: FilterData, protect: 1 },
      { path: "FormRecruit", element: FormRecruit, protect: 1 },
      { path: "PrintMobile", element: PrintMobile, protect: 1 },
      { path: "ApplyData", element: ApplyData, protect: 1 },
      { path: "Detail", element: Detail, protect: 1 },
      { path: "EditAndDetail", element: EditAndDetail, protect: 1 },
      { path: "Receipt", element: Receipt, protect: 1 },
      { path: "TrackingReport", element: TrackingReport, protect: 1 },
      { path: "ReportStockIn", element: ReportStockIn, protect: 1 },
      { path: "InvoiceReport", element: InvoiceReport, protect: 1 },
      { path: "PrintLabel", element: PrintLabel, protect: 1 },
      { path: "PickUpList", element: PickUpList, protect: 1 },
      { path: "CollectList", element: CollectList, protect: 1 },
      { path: "Parcel", element: Parcel, protect: 1 },
      { path: "ParcelPickUp", element: ParcelPickUp, protect: 1 }, 
      { path: "ParcelCollect", element: ParcelCollect, protect: 1 }, 
      { path: "ManageCOD", element: ManageCOD, protect: 1 },
      { path: "HistoryCOD", element: HistoryCOD, protect: 1 },
      { path: "Shops", element: Shops, protect: 1 },
      { path: "FormShop", element: FormShop, protect: 1 },
      { path: "Size", element: Size, protect: 1 },
      // { path: "EmployeesSetting", element: EmployeesSetting, protect: 1 },
      // { path: "EditEmp", element: EditEmp, protect: 1 },
      { path: "Profile", element: Profile, protect: 1 },
      { path: "myCOD", element: MyCOD, protect: 1 },
      { path: "MyReceipt", element: MyReceipt, protect: 1 },
      {
        path: "HubEmployees",
        element: HubUsersListPage,
        protect: 1
      },
      {
        path: "EditHubEmployees/:id",
        element: HubUsersEditPage,
        protect: 1
      },
      {
        path: "AddHubEmployee",
        element: HubUsersEditPage,
        protect: 1
      }
    ],
    protect: 1,
  },
  {
    path: "/mis/",
    role: 0,
    layout: LoginLayout,
    children: [{ path: "Login", element: Login, protect: 0 }],
    protect: 0,
  },
  HubMenuRoute,
  {
    path: "/",
    role: 0,
    layout: LoginLayout,
    children: [{ path: "*", element: Error, protect: 0 }],
    protect: 0,
  },
];
export default Routes;
