import React from "react";
// import page1 from "../../Assets/images/logo/page1.jpg";
// import page2 from "../../Assets/images/logo/page2.jpg";
// import page3 from "../../Assets/images/logo/page3.jpg";
// import page4 from "../../Assets/images/logo/page4.jpg";
// import page5 from "../../Assets/images/logo/page5.jpg";
import page6 from "../../Assets/images/logo/rate_th.jpg";
// import page7 from "../../Assets/images/logo/page7.jpg";
import page1 from "../../Assets/images/logo/p1.png";
import page2 from "../../Assets/images/logo/p2.png";
import page3 from "../../Assets/images/logo/p3.png";

import t1 from "../../Assets/images/servicerate_eng/t1.png";
import t2 from "../../Assets/images/servicerate_eng/t2.png";
import t3 from "../../Assets/images/servicerate_eng/t3.png";
import t4 from "../../Assets/images/servicerate_eng/t4.png";
import t5 from "../../Assets/images/servicerate_eng/t5.png";
import t6 from "../../Assets/images/servicerate_eng/rate_eg2.jpg";

import { connect } from "react-redux";

function ServiceRates(props) {
  const { lang } = props;
  return lang.language === "th" ? <ServiceRatesTH /> : <ServiceRateENG />;
}

const mapStateToProps = (state) => ({
  lang: state.LanguageAction,
});

export default connect(mapStateToProps)(ServiceRates);

function ServiceRatesTH() {
  return (
    <div className=" container">
      <img className="d-block w-100" src={page6} alt="silde1" />
      <img className="d-block w-100" src={page1} alt="silde1" />
      <img className="d-block w-100" src={page2} alt="silde1" />
      <img className="d-block w-100" src={page3} alt="silde1" />
      {/* <img className="d-block w-100" src={page4} alt="silde1" />
      <img className="d-block w-100" src={page5} alt="silde1" />
      <img className="d-block w-100" src={page7} alt="silde1" /> */}
    </div>
  );
}

function ServiceRateENG() {
  return (
    <div className=" container">
      <img className="d-block w-100" src={t6} alt="silde1" />
      <img className="d-block w-100" src={t1} alt="silde1" />
      <img className="d-block w-100" src={t2} alt="silde1" />
      <img className="d-block w-100" src={t3} alt="silde1" />
      <img className="d-block w-100" src={t4} alt="silde1" />
      <img className="d-block w-100" src={t5} alt="silde1" />
    </div>
  );
}
