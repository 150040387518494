import Instance from "../Helper/axios";
import { URL, URLTEST, urlLocal } from "../Helper/baseURL";

export async function ShowEmpService(data) {
  try {
    const res = await Instance.post(URL + "settings/Employees/getEmp", data);
    return await res.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function SaveEmpService(data) {
  try {
    const response = await Instance.post(
      URL + "settings/Employees/saveEmp",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateStatusEmpService(data) {
  try {
    const response = await Instance.post(
      URL + "settings/Employees/deleteEmp",
      data
    );
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

// ดึงข้อมูลพนักงานขนส่ง สามารถส่ง empCode ได้
export async function GetAllEmpService(empCode) {
  try {
    const response = await Instance.get(
      URL + "/settings/Employees/getAllEmp?empCode=" + empCode
    );
    return await response.data;
  } catch (error) {}
}
