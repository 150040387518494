import * as Yup from 'yup';

const profileSchema = Yup.object().shape({
  password: Yup.string()
    .required('กรุณากรอกรหัสผ่าน')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/, 'รหัสผ่านต้องประกอบไปด้วย A-Z a-z 0-9 และอักษรพิเศษรวมกันอย่างน้อย 6 ตัว')
    .max(60, 'จำนวนตัวอักษรเกินจำนวนที่กำหนด')
    .min(6, 'ตัวอักษรภาษาอังกฤษและตัวเลข รวมกันอย่างน้อย 6 ตัวอักษร'),
  confirmPassword: Yup.string()
    .required('กรุณากรอกรหัสผ่าน')
    .oneOf([Yup.ref('password')], 'รหัสผ่านไม่ตรงกัน กรุณาตรวจสอบอีกครั้ง')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/, 'รหัสผ่านต้องประกอบไปด้วย A-Z a-z 0-9 และอักษรพิเศษรวมกันอย่างน้อย 6 ตัว')
    .max(60, 'จำนวนตัวอักษรเกินจำนวนที่กำหนด')
    .min(6, 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร'),
});

export default profileSchema;
