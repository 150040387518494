import Modal from "react-bootstrap/Modal";
import React, {useEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {Formik, getIn, useFormik} from "formik";
import {useFilePicker} from "use-file-picker";
import * as Icon from "react-bootstrap-icons";
import {MgImageSelection} from "./MgImageSelection";
import {useMultipleFileUpload} from "../../../../utility/useMultipleFileUpload";
import {MultipleUploadProgress} from "../../../../utility/MultipleUploadProgress";
import {swalError, swalSuccess} from "../../../../Components/sweetAlert";
import {scanToReceived} from "../../../../Service/service.Barcode";
import {Spinner} from "react-bootstrap";

const MgBarcodeSchema = Yup.object().shape({
    signature: Yup.string().nullable().required("จำเป็นต้องมีลายเซ็น"),
    images: Yup.array().of(
      Yup.string(),
    ),
    description: Yup.string().nullable(),
});
const initialValues = {
    signature: null,
    images: [],
    description: "",
}

export const MgBarcodeUpload = ({selectData, onHide, onBarcodeErrors}) => {

    const firstChange = useRef(null);
    const [loading, setLoading] = useState(false);

    const [openSignatureFileSelector, { filesContent: SignatureFileContent, loading: SignatureFileLoading, clear: SignatureFileClear }] = useFilePicker({
        readAs: "DataURL",
        accept: ['.jpg', '.jpeg', '.png'],
        multiple: false,
    });

    const [openProofsFileSelector, { filesContent: ProofFileContent, loading: ProofFileLoading, clear: ProofFileClear }] = useFilePicker({
        readAs: "DataURL",
        accept: ['.jpg', '.jpeg', '.png'],
        multiple: true,
    });

    const {
        launchUpload,
        isLoading,
        uploadStates,
        error
    } = useMultipleFileUpload(async (results) => {
        const [signature,...images] = results;
        setLoading(true);
        try{
            const result = await scanToReceived(selectData.map(row => row.awb_code), signature,images, values.description);
            swalSuccess({
                text: 'การสแกนเสร็จสมบูรณ์',
            });
            setLoading(false);
            onHide();
        }
        catch (error) {
            if(error.response?.data?.data){
                onBarcodeErrors(error.response?.data?.data);
            }
            else{
                swalError({
                    text: 'ไม่สามารถทำการสแกนได้ โปรดปิดโมดอลแล้วลองอีกครั้ง'
                });
                onHide();
            }
        }
    }, (error) => {
        swalError({
            text: 'เกิดข้อผิดพลาดขณะอัปโหลดไฟล์'
        })
    });

    const {
        errors, touched, handleBlur, setFieldValue, handleSubmit, values, isValid, setTouched
    } = useFormik({
        initialValues: {
            signature: null,
            images: [],
            description: ""
        },
        validationSchema: MgBarcodeSchema,
        onSubmit: values => {
            launchUpload([
                values.signature,
                ...values.images,
            ])
        }
    });

    useEffect(() => {
        setFieldValue('signature', SignatureFileContent[0]?.content ?? null)
    }, [SignatureFileContent]);

    useEffect(() => {
        setFieldValue('images',[...values.images,...ProofFileContent.map(image => image.content)])
    }, [ProofFileContent])


    return (
        <>
            {uploadStates && <MultipleUploadProgress states={uploadStates} />}
            {!uploadStates && (<Modal size="xl" show={true}>
                <Modal.Header onHide={onHide} closeButton>
                    <Modal.Title>สแกนเพื่อรับ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!loading && <form style={{display: 'flex', flexDirection: 'column'}}>
                        <label>ลายเซ็น</label>
                        <div className="image_content flex-column align-items-center d-flex">
                            <div style={styles.filePicker} onClick={(e) => {
                                setTouched({...touched, signature: true}, true);
                                openSignatureFileSelector();
                            }}
                                 className={`d-flex justify-content-center align-items-center`}>
                                {values.signature !== null ? <div style={{...styles.image, backgroundImage: `url(${values.signature})`}} />
                                    : <Icon.CloudUploadFill size={64} color="white" />}
                            </div>
                            {(getIn(touched, "signature") &&
                                getIn(errors, "signature")
                                && (
                                    <div className="invalid-feedback text-center" style={{display: 'block'}}>
                                        {errors.signature}
                                    </div>
                                )
                            )}
                            {values.signature && <button
                                name="search"
                                type="button"
                                className={"btn btn-danger"}
                                onClick={() => {
                                    SignatureFileClear();
                                }}
                            >ลบ</button>}
                        </div>
                        <label>ภาพหลักฐาน</label>
                        <MgImageSelection images={values.images} onImageAdd={() => openProofsFileSelector()} onRemove={(deleteIndex) => setFieldValue('images', values.images.filter((_,index) => index!== deleteIndex))} />
                        <label>คำอธิบาย</label>
                        <textarea value={values.description} onChange={(e) => setFieldValue('description', e.target.value)}></textarea>
                        <button onClick={() => handleSubmit()} className={`btn mt-1 ${isValid ? 'btn-primary' : 'btn-secondary'}`} disabled={!isValid}>ในการตรวจสอบ</button>
                    </form>}
                    {loading && <div className="spinner-global-content">
                        <Spinner variant="success" animation="border" />
                    </div> }
                </Modal.Body>
            </Modal>)}
        </>
    );
}

const styles = {
    filePicker: {
        height: '16rem',
        maxHeight: '16rem',
        width: '300px',
        borderRadius: '2rem',
        backgroundColor: 'rgb(235, 244, 255)',
        cursor: "pointer",
        transition: "all .3s ease-in-out",
        position: 'relative',
        marginBottom: "15px",
    },
    image: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: "100%",
        height: "100%",
        backgroundSize: 'contain',
    },
}