import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavDropdown } from "react-bootstrap";
import logoNavbar from "../../Assets/images/logo/logoNavbar.png";
import newLogoNavbar from '../../Assets/images/logo/newLogoNavbar.png';
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { getNotification } from "../../Service/service.PickupParcel";
import {
  getParcelsSendBack,
  updateStatusSendBack,
} from "../../Service/service.Notification";
import Modal from "react-bootstrap/Modal";
import DateTH from "../../Helper/date-th";
import notImg from "../../Assets/images/imagesMis/notImg.png";
import Pagination from "@material-ui/lab/Pagination";
import AdminMenu from "./menu/AdminMenu";
import ShopMenu from "./menu/ShopMenu";
import ClientPublicMenu from "./menu/ClientPublicMenu";
import CreditCustMenu from "./menu/CreditCustMenu";
import FinanceMenu from "./menu/FinanceMenu";
import ServiceMenu from "./menu/ServiceMenu";
import DriverMenu from "./menu/DriverMenu";
import StaffMenu from "./menu/StaffMenu";
import OperatorMenu from "./menu/OperatorMenu";
import ConoteMenu from "./menu/ConoteMenu";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathnames: this.props.history.location.pathname,
      notification: "",
      show: false,
      data: [],
      loading: 0,
      notData: 0,
      page: {
        current_page: 0,
        last_page: 1,
        total: 0,
      },
      currentPage: 1,
      notImg: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.history.pathname !== this.props.history.location.pathname) {
      this.setState({ pathname: nextProps.history.pathname });
    }
  }

  componentDidMount() {
    this.loadData();
    this.loadDataSendBack({
      cust_code: this.props.username,
      pageNo: 1,
      perPage: 5,
    });
  }

  async loadData() {
    let result = await getNotification();
    if (result.status === 1) {
      this.setState({
        notification:
          parseInt(result.totalRow) + parseInt(result.totalRowCredit),
      });
    } else {
      alert("server error");
    }
  }

  async loadDataSendBack(value) {
    this.setState({
      data: [],
      loading: 1,
    });
    let result = await getParcelsSendBack(value);
    if (result.status === 1) {
      this.setState({
        data: result.data.result,
        notData: 0,
        page: {
          current_page: result.data.pageNo,
          last_page: result.data.maxPage,
          total: result.data.numRowsAll,
        },
      });
      // if (result.data.result.length > 0) {
      //   this.setState({
      //     show: true,
      //   });
      // }
    } else {
      this.setState({
        notData: 1,
        page: {
          current_page: 0,
          last_page: 1,
          total: 0,
        },
      });
    }
    this.setState({
      loading: 0,
    });
  }

  async updateSendBack(value) {
    this.setState({
      data: [],
    });
    let result = await updateStatusSendBack(value);
    if (result.status === 1) {
      this.loadDataSendBack({
        cust_code: this.props.username,
        pageNo: 1,
        perPage: 5,
      });
    } else {
      alert("เกิดข้อผิดพลาด");
    }
  }

  logout() {
    if (localStorage.getItem("superkey") === "1947") {
      localStorage.clear();
      this.props.history.replace("/login");
    } else {
      localStorage.clear();
      this.props.history.push("/mis/Login");
    }
  }

  render() {
    const handleClose = () => {
      this.setState({
        show: false,
      });
    };
    const handleShow = () => {
      this.setState({
        show: true,
      });
    };
    const hideImg = (event) => {
      this.setState({
        notImg: false,
      });
    };

    return (
      <>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="dg-white-aliceblue justify-content-center"
          sticky="top"
        >
          <Navbar.Brand href="" className="mr-100 justify-content-center">
            <img
              alt=""
              src={newLogoNavbar}
              width="140"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="navbar-collapse-remove-flex-grow "
          >
            <Nav
              className="mr-auto"
              variant="test"
              defaultActiveKey="/home"
              activeKey={this.state.pathnames}
              onSelect={(selectedKey) => {}}
            >
              {this.props.roleType === "1" ? (
                <AdminMenu notification={this.state.notification} />
              ) : null}
              {this.props.roleType === "2" ? <CreditCustMenu handleShow={handleShow} /> : null}
              {this.props.roleType === "3" ? (
                <ShopMenu handleShow={handleShow} />
              ) : null}
              {this.props.roleType === "6" ? (
                <ClientPublicMenu handleShow={handleShow} />
              ) : null}
              {this.props.roleType === "21" ? <FinanceMenu /> : null}
              {this.props.roleType === "22" ? <ServiceMenu /> : null}
              {this.props.roleType === "5" ? <DriverMenu /> : null}
              {this.props.roleType === "13" ? <StaffMenu /> : null}
              {this.props.roleType === "23" ? <OperatorMenu /> : null}
              {this.props.roleType === "24" ? <ConoteMenu /> : null}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Nav.Item>
                <Nav.Link>
                  <Icon.PeopleFill style={{ fontSize: "22px" }} />{" "}
                  {this.props.name.split(" ")[0]}
                </Nav.Link>
                
                {/* <NavDropdown
                  title={
                    <>
                      <Icon.PeopleFill style={{ fontSize: "22px" }} />{" "}
                      {this.props.name.split(" ")[0]}
                    </>
                  }
                  // id="basic-nav-dropdown"
                >
                  {localStorage.getItem("superkey") === "1947" ? (
                    ""
                  ) : (
                    <NavDropdown.Item
                      onClick={(e) => {
                        this.props.history.push("Profile");
                      }}
                    >
                      - แก้ไขข้อมูลส่วนตัว
                    </NavDropdown.Item>
                  )}
                </NavDropdown> */}
              </Nav.Item>
              <Button
                variant="primary"
                name="tacking"
                className="radius"
                onClick={(e) => {
                  this.logout();
                }}
              >
                ออกจากระบบ
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Modal show={this.state.show} onHide={handleClose} size="lg">
        {/* <Modal onHide={handleClose} size="lg"> */}
          <Modal.Header closeButton>
            <Modal.Title>พัสดุถูกส่งกลับ</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "14px" }}>
            <div className="row">
              <div
                class={this.state.data.length > 0 ? "container" : "hidden-ct"}
              >
                <div style={{ overflow: "Auto" }}>
                  <table class="table table-bordered">
                    <thead style={{ backgroundColor: "#3f75c1" }}>
                      <tr style={{ color: "#FFFFFF" }}>
                        <td>ลำดับ</td>
                        <td>วันที่</td>
                        <td>ลาเบล</td>
                        <td>ข้อมูลพัสดุ</td>
                        {/* <td>ลายเซ็น</td> */}
                        {/* <td>จัดการ</td> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((value, index) => (
                        <tr key={index}>
                          <td>
                            {(this.state.page.current_page - 1) * 5 +
                              (index + 1)}
                          </td>
                          <td style={{ width: "20%" }}>
                            <DateTH date={value.create_date} />
                          </td>
                          <td style={{ width: "20%" }}>{value.awb_code}</td>
                          <td style={{ width: "170px" }}>
                            <div>ผู้ส่ง : {value.sender_name}</div>
                            <div>{value.sender_phone}</div>
                            <div>ผู้รับ : {value.receiver_name}</div>
                            <div>{value.receiver_phone}</div>
                          </td>
                          {/* <td>
                            {this.state.notImg === true ? (
                              <center>
                                <img
                                  src={value.image}
                                  width="80"
                                  height="80"
                                  alt={"img" + index}
                                  onError={hideImg}
                                />
                              </center>
                            ) : (
                              <center>
                                <img
                                  src={notImg}
                                  width="80"
                                  height="80"
                                  alt="notImg"
                                />
                              </center>
                            )}
                          </td> */}
                          {/* <td style={{ width: "120px" }}>
                            <center>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  this.updateSendBack({
                                    awb_code: value.awb_code,
                                  });
                                }}
                              >
                                ได้รับแล้ว
                              </button>
                            </center>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between">
                  <div>จำนวนทั้งหมด {this.state.page.total} รายการ</div>
                  <div>
                    <Pagination
                      count={parseInt(this.state.page.last_page)}
                      page={this.state.currentPage}
                      onChange={(e, newPage) => {
                        this.setState({
                          currentPage: newPage,
                        });
                        this.loadDataSendBack({
                          cust_code: this.props.username,
                          pageNo: newPage,
                          perPage: 5,
                        });
                      }}
                      color="primary"
                      size="small"
                      defaultPage={6}
                      siblingCount={1}
                    />
                  </div>
                </div>
              </div>
            </div>
            <center
              className={
                this.state.notData === 1 && this.state.loading !== 1
                  ? ""
                  : "hidden-ct"
              }
            >
              <div style={{ marginTop: "100px", marginBottom: "100px" }}>
                <font color="red" size="3">
                  -- ไม่พบข้อมูล! --
                </font>
              </div>
            </center>
            <div className={this.state.loading === 1 ? "m-5" : "hidden-ct"}>
              <div
                className="text-center"
                style={{ marginTop: "100px", marginBottom: "100px" }}
              >
                <div className="spinner-border  text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                {" ..."}
                กำลังโหลดข้อมูล
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  name: state.Authentication.fullname,
  roleType: state.Authentication.roleType,
  username: state.Authentication.username,
});

export default connect(mapStateToProps)(Header);
