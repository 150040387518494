import React, { useState } from "react";
import { address } from "../../../../Data/addressData.json";
import { Formik, Form, ErrorMessage, getIn } from "formik";
import { monthTH } from "../../../../Data/month-th.json";

export default function Tap1({
  errors,
  touched,
  handleBlur,
  setFieldValue,
  values,
  position,
  step,
}) {
  var year = new Date().getFullYear();
  var years = Array.from(new Array(60), (e, index) => year - index);
  var yearsUp = Array.from(new Array(10), (e, index) => year + index);
  var month = ("0" + (new Date().getMonth() + 1)).slice(-2);
  var date = ("0" + new Date().getDate()).slice(-2);
  const [showAll, setShowAll] = useState(10);
  const [search, setSearch] = useState("");

  return (
    <>
      <div className={step === 1 ? "" : "hidden-ct"}>
        {/* เริ่ม Tap1 */}
        <br />
        <div align="left" className="text-title-color">
          <b>ประวัติส่วนตัว (Personal information)</b>
        </div>
        <br />
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-2 px-md-2 mt-1">
                <span>คำนำหน้าชื่อ</span>
                <span className="important-ct">*</span>
                <select
                  value={values.information.titleName}
                  name={"information.titleName"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.titleName")
                      ? getIn(errors, "information.titleName")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.titleName", e.target.value);
                  }}
                >
                  <option value="">-- ระบุคำนำหน้า --</option>
                  <option value="นาย">นาย</option>
                  <option value="นาง">นาง</option>
                  <option value="นางสาว">นางสาว</option>
                </select>
                <ErrorMessage
                  component="div"
                  name={"information.titleName"}
                  className="invalid-feedback"
                />
              </div>
              <div className="col-sm-4 px-md-2 mt-1">
                <span>ชื่อ</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.name}
                  type="text"
                  name={"information.name"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.name")
                      ? getIn(errors, "information.name")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.name", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"information.name"}
                  className="invalid-feedback"
                />
              </div>
              <div className="col-sm-4 px-md-2 mt-1">
                <span>นามสกุล</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.lastName}
                  type="text"
                  name={"information.lastName"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.lastName")
                      ? getIn(errors, "information.lastName")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.lastName", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"information.lastName"}
                  className="invalid-feedback"
                />
              </div>
              <div className="col-sm-2 px-md-2 mt-1">
                <span>เงินเดือนที่คาดหวัง</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.salary}
                  type="text"
                  name={"information.salary"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.salary")
                      ? getIn(errors, "information.salary")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.salary", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"information.salary"}
                  className="invalid-feedback"
                />
              </div>
              <div className="col-sm-6 px-md-3 mt-2">
                <span>วัน เดือน ปี เกิด</span>
                <span className="important-ct">*</span>
                <div className="row">
                  <div className="col-sm-4 px-md-2">
                    <select
                      onBlur={handleBlur}
                      className={
                        "form-control  dropdown-custom2  " +
                        (getIn(touched, "information.birthYear")
                          ? getIn(errors, "information.birthYear")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      name={"information.birthYear"}
                      onChange={(e) => {
                        setFieldValue("information.birthYear", e.target.value);
                        if (
                          values.information.birthDay !== "" &&
                          values.information.birthMonth !== "" &&
                          e.target.value !== ""
                        ) {
                          setFieldValue(
                            "information.age",
                            parseInt(year) - parseInt(e.target.value)
                          );
                        }
                      }}
                      value={values.information.birthYear}
                    >
                      <option value={""} key={"123"}>
                        {" "}
                        -- ระบุปี --{" "}
                      </option>
                      {years.map((year, index) => (
                        <option key={index} value={year}>
                          {year + 543}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      component="div"
                      name={"information.birthYear"}
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-sm-4 px-md-2">
                    <select
                      onBlur={handleBlur}
                      className={
                        "form-control  dropdown-custom2  " +
                        (getIn(touched, "information.birthMonth")
                          ? getIn(errors, "information.birthMonth")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      name={"information.birthMonth"}
                      onChange={(e) => {
                        setFieldValue("information.birthMonth", e.target.value);
                        if (
                          values.information.birthDay !== "" &&
                          e.target.value !== "" &&
                          values.information.birthYear !== ""
                        ) {
                          setFieldValue(
                            "information.age",
                            parseInt(year) -
                              parseInt(values.information.birthYear)
                          );
                        }
                      }}
                      value={values.information.birthMonth}
                    >
                      <option value={""} key={"151"}>
                        {" "}
                        -- ระบุเดือน --{" "}
                      </option>
                      {monthTH.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.monthName}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      component="div"
                      name={"information.birthMonth"}
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-sm-4 px-md-2">
                    <select
                      onBlur={handleBlur}
                      className={
                        "form-control  dropdown-custom2  " +
                        (getIn(touched, "information.birthDay")
                          ? getIn(errors, "information.birthDay")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      name={"information.birthDay"}
                      onChange={(e) => {
                        setFieldValue("information.birthDay", e.target.value);
                        if (
                          e.target.value !== "" &&
                          values.information.birthMonth !== "" &&
                          values.information.birthYear !== ""
                        ) {
                          setFieldValue(
                            "information.age",
                            parseInt(year) -
                              parseInt(values.information.birthYear)
                          );
                        }
                      }}
                      value={values.information.birthDay}
                    >
                      <option value={""}> -- ระบุวัน -- </option>
                      {Array.from(Array(31), (e, i) => (
                        <option value={(i < 9 ? "0" : "") + (i + 1)} key={i}>
                          {(i < 9 ? "0" : "") + (i + 1)}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      component="div"
                      name={"information.birthDay"}
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div>

              {/* ///////////////////////////////////////////////////////////////////////////////////// */}
              <div className="col-sm-2 px-md-2 mt-2">
                <span>อายุ (ปี)</span>
                <span className="important-ct">*</span>
                <input
                  name={"information.age"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.age")
                      ? getIn(errors, "information.age")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  value={values.information.age}
                />
                <ErrorMessage
                  component="div"
                  name={"information.age"}
                  className="invalid-feedback"
                />
              </div>

              <div className="col-sm-4 px-md-2 mt-2">
                <span>หมายเลขบัตรประชาชน</span>
                <span className="important-ct">*</span>
                <input
                  type="text"
                  name="information.thaiID"
                  className={
                    "form-control " +
                    (getIn(touched, `information.thaiID`)
                      ? getIn(errors, `information.thaiID`)
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  value={values.information.thaiID}
                  onChange={(e) => {
                    setFieldValue("information.thaiID", e.target.value);
                  }}
                  aria-label="information.thaiID"
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="div"
                  name="information.thaiID"
                  className="invalid-feedback"
                />
              </div>
              {/* ///////////////////////////////////////////////////////////////////////////////////// */}

              <div className="col-sm-6 px-md-3 mt-2">
                <span>วันที่หมดอายุบัตรประชาชน</span>
                <span className="important-ct">*</span>
                <div className="row">
                  <div className="col-sm-4 px-md-2">
                    <select
                      onBlur={handleBlur}
                      className={
                        "form-control  dropdown-custom2  " +
                        (getIn(touched, "information.exDate_ThaiYear")
                          ? getIn(errors, "information.exDate_ThaiYear")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      name="information.exDate_ThaiYear"
                      onChange={(e) => {
                        setFieldValue(
                          "information.exDate_ThaiYear",
                          e.target.value
                        );
                      }}
                      value={values.information.exDate_ThaiYear}
                    >
                      <option value={""} key={"123"}>
                        {" "}
                        -- ระบุปี --{" "}
                      </option>
                      {yearsUp.map((year, index) => (
                        <option key={index} value={year}>
                          {year + 543}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      component="div"
                      name="information.exDate_ThaiYear"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-sm-4 px-md-2">
                    <select
                      onBlur={handleBlur}
                      className={
                        "form-control  dropdown-custom2  " +
                        (getIn(touched, "information.exDate_ThaiMonth")
                          ? getIn(errors, "information.exDate_ThaiMonth")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      name="information.exDate_ThaiMonth"
                      onChange={(e) => {
                        setFieldValue(
                          "information.exDate_ThaiMonth",
                          e.target.value
                        );
                      }}
                      value={values.information.exDate_ThaiMonth}
                    >
                      <option value={""} key={"151"}>
                        {" "}
                        -- ระบุเดือน --{" "}
                      </option>
                      {monthTH.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.monthName}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      component="div"
                      name="information.exDate_ThaiMonth"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-sm-4 px-md-2">
                    <select
                      onBlur={handleBlur}
                      className={
                        "form-control  dropdown-custom2  " +
                        (getIn(touched, "information.exDate_ThaiDay")
                          ? getIn(errors, "information.exDate_ThaiDay")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      name="information.exDate_ThaiDay"
                      onChange={(e) => {
                        setFieldValue(
                          "information.exDate_ThaiDay",
                          e.target.value
                        );
                      }}
                      value={values.information.exDate_ThaiDay}
                    >
                      <option value={""}> -- ระบุวัน -- </option>
                      {Array.from(Array(31), (e, i) => (
                        <option value={(i < 9 ? "0" : "") + (i + 1)} key={i}>
                          {(i < 9 ? "0" : "") + (i + 1)}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage
                      component="div"
                      name="information.exDate_ThaiDay"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div>

              {/* ///////////////////////////////////////////////////////////////////////////////////// */}

              <div className="col-sm-2 px-md-2 mt-2">
                <span>เชื้อชาติ</span>
                <span className="important-ct">*</span>
                <select
                  name={"information.race"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.race")
                      ? getIn(errors, "information.race")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.race", e.target.value);
                  }}
                  value={values.information.race}
                >
                  <option value="">-- ระบุเชื้อชาติ --</option>
                  <option value="ไทย">ไทย</option>
                </select>
                <ErrorMessage
                  component="div"
                  name={"information.race"}
                  className="invalid-feedback"
                />
              </div>
             
              <div className="col-sm-2 px-md-2 mt-2">
                <span>สัญชาติ</span>
                <span className="important-ct">*</span>
                <select
                  name={"information.nationality"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.nationality")
                      ? getIn(errors, "information.nationality")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.nationality", e.target.value);
                  }}
                  value={values.information.nationality}
                >
                  <option value="">-- ระบุสัญชาติ --</option>
                  <option value="ไทย">ไทย</option>
                </select>
                <ErrorMessage
                  component="div"
                  name={"information.nationality"}
                  className="invalid-feedback"
                />
              </div>
             
              <div className="col-sm-2 px-md-2 mt-2">
                <span>ศาสนา</span>
                <span className="important-ct">*</span>
                <select
                  name={"information.religion"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.religion")
                      ? getIn(errors, "information.religion")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.religion", e.target.value);
                  }}
                  value={values.information.religion}
                >
                  <option value="">-- ระบุศาสนา --</option>
                  <option value="พุทธ">พุทธ</option>
                  <option value="คริสต์">คริสต์</option>
                  <option value="อิสลาม">อิสลาม</option>
                </select>
                <ErrorMessage
                  component="div"
                  name={"information.religion"}
                  className="invalid-feedback"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 px-md-2 mt-2">
                <span>ที่อยู่</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.addresNum}
                  type="text"
                  name={"information.addresNum"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.addresNum")
                      ? getIn(errors, "information.addresNum")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.addresNum", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"information.addresNum"}
                  className="invalid-feedback"
                />
              </div>
              {/* <div className="col-sm-3 px-md-2 mt-2">
                <span>หมู่ที่</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.moo}
                  type="text"
                  name={"information.moo"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.moo")
                      ? getIn(errors, "information.moo")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.moo", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"information.moo"}
                  className="invalid-feedback"
                />
              </div> */}
              <div className="col-sm-6 px-md-2 mt-2">
                <span>ถนน</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.road}
                  type="text"
                  name={"information.road"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.road")
                      ? getIn(errors, "information.road")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.road", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"information.road"}
                  className="invalid-feedback"
                />
              </div>
              {/* <div className="col-sm-3 px-md-2 mt-2">
                <span>ซอย</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.alley}
                  type="text"
                  name={"information.alley"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.alley")
                      ? getIn(errors, "information.alley")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.alley", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"information.alley"}
                  className="invalid-feedback"
                />
              </div> */}
              <div className="col-sm-12 px-md-2 mt-2">
                <span>ค้นหาที่อยู่</span>
                <input
                  value={search}
                  id="demoaa"
                  placeholder="ค้นหาตำบล, อำเภอ, จังหวัด, รหัสไปรษณีย์"
                  className="form-control"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <div
                  data-bs-spy="scroll"
                  className="list-group table-wrapper-scroll-y my-custom-scrollbar"
                  style={
                    search !== "" && search.length >= 3
                      ? {
                          position: "absolute",
                          width: "99%  ",
                          zIndex: "1",
                          overflow: "Auto",
                        }
                      : { display: "none" }
                  }
                >
                  {address
                    .filter(
                      (value, i) =>
                        (value.subdistrictName.includes(search) ||
                          value.districtName.includes(search) ||
                          value.provinceName.includes(search) ||
                          value.postCode.toString().includes(search)) &&
                        search.length >= 3
                    )
                    .slice(0, showAll)
                    .map((value, i) => (
                      <div>
                        <button
                          style={{ height: "40px" }}
                          type="button"
                          className="list-group-item list-group-item-action btn-light"
                          onClick={() => {
                            setSearch("");
                            setFieldValue(
                              "information.subdistrict",
                              value.subdistrictName
                            );
                            setFieldValue(
                              "information.district",
                              value.districtName
                            );
                            setFieldValue(
                              "information.province",
                              value.provinceName
                            );
                            setFieldValue(
                              "information.postCode",
                              value.postCode
                            );
                          }}
                        >
                          {value.subdistrictName +
                            ", " +
                            value.districtName +
                            ", " +
                            value.provinceName +
                            ", " +
                            value.postCode}
                        </button>
                      </div>
                    ))}
                  <button
                    style={{ height: "40px" }}
                    type="button"
                    className="list-group-item list-group-item-action"
                    onClick={() => {
                      if (showAll === 10) {
                        setShowAll();
                      } else {
                        setShowAll(10);
                      }
                    }}
                  >
                    {showAll === 10 ? "-- แสดงทั้งหมด --" : "-- แสดงน้อยลง --"}
                  </button>
                </div>
              </div>
              <div className="col-sm-3 px-md-2 mt-2">
                <span>ตำบล/แขวง</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.subdistrict}
                  name={"information.subdistrict"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.subdistrict")
                      ? getIn(errors, "information.subdistrict")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="div"
                  name={"information.subdistrict"}
                  className="invalid-feedback"
                />
              </div>
              <div className="col-sm-3 px-md-2 mt-2">
                <span>อำเภอ/เขต</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.district}
                  name={"information.district"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.district")
                      ? getIn(errors, "information.district")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="div"
                  name={"information.district"}
                  className="invalid-feedback"
                />
              </div>
              <div className="col-sm-3 px-md-2 mt-2">
                <span>จังหวัด</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.province}
                  name={"information.province"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.province")
                      ? getIn(errors, "information.province")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="div"
                  name={"information.province"}
                  className="invalid-feedback"
                />
              </div>
              <div className="col-sm-3 px-md-2 mt-2">
                <span>รหัสไปรษณีย์</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.postCode}
                  name={"information.postCode"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.postCode")
                      ? getIn(errors, "information.postCode")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  component="div"
                  name={"information.postCode"}
                  className="invalid-feedback"
                />
              </div>
              {/* <div className="col-sm-6 px-md-2 mt-1">
                <span>โทรศัพท์</span>
                <input
                  value={values.information.phone}
                  type="number"
                  name={"information.phone"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.phone")
                      ? getIn(errors, "information.phone")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.phone", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"information.phone"}
                  className="invalid-feedback"
                />
              </div> */}
              <div className="col-sm-4 px-md-2 mt-2">
                <span>เบอร์โทรศัพท์</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.mobilePhone}
                  type="number"
                  name={"information.mobilePhone"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.mobilePhone")
                      ? getIn(errors, "information.mobilePhone")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.mobilePhone", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"information.mobilePhone"}
                  className="invalid-feedback"
                />
              </div>
              <div className="col-sm-4 px-md-2 mt-2">
                <span>ไลน์ไอดี</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.lineID}
                  type="text"
                  name={"information.lineID"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.lineID")
                      ? getIn(errors, "information.lineID")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.lineID", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"information.lineID"}
                  className="invalid-feedback"
                />
              </div>
              <div className="col-sm-4 px-md-2 mt-2">
                <span>อีเมล์</span>
                <span className="important-ct">*</span>
                <input
                  value={values.information.email}
                  type="text"
                  name={"information.email"}
                  className={
                    "form-control  " +
                    (getIn(touched, "information.email")
                      ? getIn(errors, "information.email")
                        ? "is-invalid"
                        : "is-valid"
                      : "")
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("information.email", e.target.value);
                  }}
                />
                <ErrorMessage
                  component="div"
                  name={"information.email"}
                  className="invalid-feedback"
                />
              </div>
              {/* /////////////////////////////////////////////////////////////////////////////// */}
              <div className="col-sm-12  px-md-2 mt-2">
                <span>การเป็นอยู่อาศัย</span>
                <span className="important-ct">*</span>
                <div className="card">
                  <div
                    className="card-body"
                    style={
                      getIn(touched, "information.liveWith")
                        ? getIn(errors, "information.liveWith")
                          ? {
                              border: "1px solid #f32626",
                              backgroundColor: "#faefec",
                            }
                          : { border: "1px solid #5ec236" }
                        : { border: "1px solid #eeeeee" }
                    }
                  >
                    <div
                      className="form-check form-check-inline"
                      style={{ marginRight: "10%" }}
                    >
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className={
                          "form-check-input " +
                          (getIn(touched, "information.liveWith")
                            ? getIn(errors, "information.liveWith")
                              ? "is-invalid"
                              : "is-valid"
                            : "") +
                          ""
                        }
                        type="radio"
                        name="liveWith"
                        id="liveWith1"
                        value="อาศัยกับครอบครัว"
                        checked={
                          values.information.liveWith === "อาศัยกับครอบครัว"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setFieldValue("information.liveWith", e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="liveWith1">
                        อาศัยกับครอบครัว
                      </label>
                    </div>
                    <div
                      className="form-check form-check-inline"
                      style={{ marginRight: "10%" }}
                    >
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className={
                          "form-check-input " +
                          (getIn(touched, "information.liveWith")
                            ? getIn(errors, "information.liveWith")
                              ? "is-invalid"
                              : "is-valid"
                            : "") +
                          ""
                        }
                        type="radio"
                        name="liveWith"
                        id="liveWith2"
                        value="บ้านตัวเอง"
                        checked={
                          values.information.liveWith === "บ้านตัวเอง"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setFieldValue("information.liveWith", e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="liveWith2">
                        บ้านตัวเอง
                      </label>
                    </div>
                    <div
                      className="form-check form-check-inline"
                      style={{ marginRight: "10%" }}
                    >
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className={
                          "form-check-input " +
                          (getIn(touched, "information.liveWith")
                            ? getIn(errors, "information.liveWith")
                              ? "is-invalid"
                              : "is-valid"
                            : "") +
                          ""
                        }
                        type="radio"
                        name="liveWith"
                        id="liveWith3"
                        value="บ้านเช่า"
                        checked={
                          values.information.liveWith === "บ้านเช่า"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setFieldValue("information.liveWith", e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="liveWith3">
                        บ้านเช่า
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className={
                          "form-check-input " +
                          (getIn(touched, "information.liveWith")
                            ? getIn(errors, "information.liveWith")
                              ? "is-invalid"
                              : "is-valid"
                            : "") +
                          ""
                        }
                        type="radio"
                        name="liveWith"
                        id="liveWith4"
                        value="หอพัก"
                        checked={
                          values.information.liveWith === "หอพัก" ? true : false
                        }
                        onChange={(e) => {
                          setFieldValue("information.liveWith", e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="liveWith4">
                        หอพัก
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* ///////////////////////////////////////////////////////////////////////////////////// */}

              <div
                className={
                  values.information.titleName === "นาย"
                    ? "col-sm-12  px-md-2 mt-2"
                    : "hidden-ct"
                }
              >
                <span>ภาวะทางทหาร</span>
                <span className="important-ct">*</span>
                <div className="card">
                  <div
                    className="card-body"
                    style={
                      getIn(touched, "information.military")
                        ? getIn(errors, "information.military")
                          ? {
                              border: "1px solid #f32626",
                              backgroundColor: "#faefec",
                            }
                          : { border: "1px solid #5ec236" }
                        : { border: "1px solid #eeeeee" }
                    }
                  >
                    <div
                      className="form-check form-check-inline"
                      style={{ marginRight: "10%" }}
                    >
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className={
                          "form-check-input " +
                          (getIn(touched, "information.military")
                            ? getIn(errors, "information.military")
                              ? "is-invalid"
                              : "is-valid"
                            : "") +
                          ""
                        }
                        type="radio"
                        name="military"
                        id="military1"
                        value="ได้รับการยกเว้น"
                        checked={
                          values.information.military === "ได้รับการยกเว้น"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setFieldValue("information.military", e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="military1">
                        ได้รับการยกเว้น
                      </label>
                    </div>
                    <div
                      className="form-check form-check-inline"
                      style={{ marginRight: "10%" }}
                    >
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className={
                          "form-check-input " +
                          (getIn(touched, "information.military")
                            ? getIn(errors, "information.military")
                              ? "is-invalid"
                              : "is-valid"
                            : "") +
                          ""
                        }
                        type="radio"
                        name="military"
                        id="military2"
                        value="ปลดเป็นทหารกองหนุน"
                        checked={
                          values.information.military === "ปลดเป็นทหารกองหนุน"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setFieldValue("information.military", e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="military2">
                        ปลดเป็นทหารกองหนุน
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className={
                          "form-check-input " +
                          (getIn(touched, "information.military")
                            ? getIn(errors, "information.military")
                              ? "is-invalid"
                              : "is-valid"
                            : "") +
                          ""
                        }
                        type="radio"
                        name="military"
                        id="military3"
                        value="ยังไม่ได้รับการเกณฑ์"
                        checked={
                          values.information.military === "ยังไม่ได้รับการเกณฑ์"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setFieldValue("information.military", e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="military3">
                        ยังไม่ได้รับการเกณฑ์
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* ///////////////////////////////////////////////////////////////////////////////////// */}
              <div className={"col-sm-12  px-md-2 mt-2"}>
                <span>สถานภาพ </span>
                <span className="important-ct">*</span>
                <div className="card">
                  <div
                    className="card-body"
                    style={
                      getIn(touched, "information.status")
                        ? getIn(errors, "information.status")
                          ? {
                              border: "1px solid #f32626",
                              backgroundColor: "#faefec",
                            }
                          : { border: "1px solid #5ec236" }
                        : { border: "1px solid #eeeeee" }
                    }
                  >
                    <div
                      className="form-check form-check-inline"
                      style={{ marginRight: "10%" }}
                    >
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className={
                          "form-check-input " +
                          (getIn(touched, "information.status")
                            ? getIn(errors, "information.status")
                              ? "is-invalid"
                              : "is-valid"
                            : "") +
                          ""
                        }
                        type="radio"
                        name="status"
                        id="status1"
                        value="โสด"
                        checked={
                          values.information.status === "โสด" ? true : false
                        }
                        onChange={(e) => {
                          setFieldValue("information.status", e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="status1">
                        โสด
                      </label>
                    </div>
                    <div
                      className="form-check form-check-inline"
                      style={{ marginRight: "10%" }}
                    >
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className={
                          "form-check-input " +
                          (getIn(touched, "information.status")
                            ? getIn(errors, "information.status")
                              ? "is-invalid"
                              : "is-valid"
                            : "") +
                          ""
                        }
                        type="radio"
                        name="status"
                        id="status2"
                        value="แต่งงาน"
                        checked={
                          values.information.status === "แต่งงาน" ? true : false
                        }
                        onChange={(e) => {
                          setFieldValue("information.status", e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="status2">
                        แต่งงาน
                      </label>
                    </div>
                    <div
                      className="form-check form-check-inline"
                      style={{ marginRight: "10%" }}
                    >
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className={
                          "form-check-input " +
                          (getIn(touched, "information.status")
                            ? getIn(errors, "information.status")
                              ? "is-invalid"
                              : "is-valid"
                            : "") +
                          ""
                        }
                        type="radio"
                        name="status"
                        id="status3"
                        value="หม้าย"
                        checked={
                          values.information.status === "หม้าย" ? true : false
                        }
                        onChange={(e) => {
                          setFieldValue("information.status", e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="status3">
                        หม้าย
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className={
                          "form-check-input " +
                          (getIn(touched, "information.status")
                            ? getIn(errors, "information.status")
                              ? "is-invalid"
                              : "is-valid"
                            : "") +
                          ""
                        }
                        type="radio"
                        name="status"
                        id="status4"
                        value="หย่าล้าง"
                        checked={
                          values.information.status === "หย่าล้าง"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setFieldValue("information.status", e.target.value);
                        }}
                      />
                      <label className="form-check-label" for="status4">
                        หย่าล้าง
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* ///////////////////////////////////////////////////////////////////////////////////// */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
