import {useEffect, useRef} from "react";

/**
 * useEffect when you don't want the function be triggered on first render
 * @param func
 * @param deps
 */
const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

export default useDidMountEffect;