import Instance from "../Helper/axios";
import { URL, URLTEST, urlLocal } from "../Helper/baseURL";

export async function saveShopsService(data) {
  //console.log("data:" + JSON.stringify(data));
  try {
    const response = await Instance.post(URL + "settings/Shop/saveShop", data);
    //console.log("res:" + JSON.stringify(response.data));
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function uploadSignatureShop(data){
  try {
    const response = await Instance.post(
      URL + "settings/Shop/uploadSignature",
      data
    );
    return await response.data;

  } catch (error) {
    alert("error : ", JSON.stringify(error));
  }

}

export async function ShowShopsService(data) {
  try {
    const response = await Instance.post(URL + "settings/Shop/getShop", data);
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function UpdateStatusShopsService(data) {
  //console.log("dataUpdate:" + JSON.stringify(data));
  try {
    const response = await Instance.post(
      URL + "settings/Shop/deleteShop",
      data
    );
    //console.log("response:" + JSON.stringify(response.data));
    return await response.data;
  } catch (error) {
    console.log("error", error);
  }
}

//filter shop ตามประเภท ประเภทลูกค้า เช้น ลูกค้าเครคิต
export async function GetAllShop(id) {
  try {
    const response = await Instance.post(URL + "settings/Shop/getAllShop", {
      shopType: id,
    });
    return await response.data;
  } catch (error) {}
}
