import React, { useState } from "react";
import { address } from "../../../../Data/addressData.json";
import { Formik, Form, FieldArray, ErrorMessage, getIn } from "formik";
import { monthTH } from "../../../../Data/month-th.json";
import { nanoid } from "nanoid";
import * as Icon from "react-bootstrap-icons";

export default function Tap5({
  errors,
  touched,
  handleBlur,
  setFieldValue,
  values,
  param,
  step,
  setAccept,
  accept,
}) {
  var year = new Date().getFullYear();
  var years = Array.from(new Array(60), (e, index) => year - index);
  var yearsUp = Array.from(new Array(10), (e, index) => year + index);
  var month = ("0" + (new Date().getMonth() + 1)).slice(-2);
  var date = ("0" + new Date().getDate()).slice(-2);
  const [showAll, setShowAll] = useState(10);
  const [search, setSearch] = useState("");

  return (
    <>
      <div className={step === 5 ? "" : "hidden-ct"}>
        <br />
        <div align="left" className={"text-title-color"}>
          <b>อื่น ๆ (Other)</b>
        </div>
        <br />
        <div className="mt-1">
          <span>กรณีฉุกเฉินติดต่อได้ที่</span>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 px-md-2 mt-1">
                  <span>ชื่อ</span>
                  <span className="important-ct">*</span>
                  <input
                    value={values.other.emergencyContact.name}
                    type="text"
                    className={
                      "form-control  " +
                      (getIn(touched, "other.emergencyContact.name")
                        ? getIn(errors, "other.emergencyContact.name")
                          ? "is-invalid"
                          : "is-valid"
                        : "")
                    }
                    onBlur={handleBlur}
                    name={"other.emergencyContact.name"}
                    onChange={(e) => {
                      setFieldValue(
                        "other.emergencyContact.name",
                        e.target.value
                      );
                    }}
                  />
                  <ErrorMessage
                    component="div"
                    name={"other.emergencyContact.name"}
                    className="invalid-feedback"
                  />
                </div>
                <div className="col-sm-6 px-md-2 mt-1">
                  <span>นามสกุล</span>
                  <span className="important-ct">*</span>
                  <input
                    value={values.other.emergencyContact.lastName}
                    type="text"
                    className={
                      "form-control  " +
                      (getIn(touched, "other.emergencyContact.lastName")
                        ? getIn(errors, "other.emergencyContact.lastName")
                          ? "is-invalid"
                          : "is-valid"
                        : "")
                    }
                    onBlur={handleBlur}
                    name={"other.emergencyContact.lastName"}
                    onChange={(e) => {
                      setFieldValue(
                        "other.emergencyContact.lastName",
                        e.target.value
                      );
                    }}
                  />
                  <ErrorMessage
                    component="div"
                    name={"other.emergencyContact.lastName"}
                    className="invalid-feedback"
                  />
                </div>
                <div className="col-sm-3 px-md-2 mt-1">
                  <span>เบอร์โทรศัพท์</span>
                  <span className="important-ct">*</span>
                  <input
                    value={values.other.emergencyContact.phone}
                    type="text"
                    className={
                      "form-control  " +
                      (getIn(touched, "other.emergencyContact.phone")
                        ? getIn(errors, "other.emergencyContact.phone")
                          ? "is-invalid"
                          : "is-valid"
                        : "")
                    }
                    onBlur={handleBlur}
                    name={"other.emergencyContact.phone"}
                    onChange={(e) => {
                      setFieldValue(
                        "other.emergencyContact.phone",
                        e.target.value
                      );
                    }}
                  />
                  <ErrorMessage
                    component="div"
                    name={"other.emergencyContact.phone"}
                    className="invalid-feedback"
                  />
                </div>
                <div className="col-sm-4 px-md-2 mt-1">
                  <span>ความสัมพันธ์</span>
                  <span className="important-ct">*</span>
                  <input
                    value={values.other.emergencyContact.relationship}
                    type="text"
                    className={
                      "form-control  " +
                      (getIn(touched, "other.emergencyContact.relationship")
                        ? getIn(errors, "other.emergencyContact.relationship")
                          ? "is-invalid"
                          : "is-valid"
                        : "")
                    }
                    onBlur={handleBlur}
                    name={"other.emergencyContact.relationship"}
                    onChange={(e) => {
                      setFieldValue(
                        "other.emergencyContact.relationship",
                        e.target.value
                      );
                    }}
                  />
                  <ErrorMessage
                    component="div"
                    name={"other.emergencyContact.relationship"}
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <span>เคยป่วยหนักหรือเป็นโรคติดต่อร้ายแรงหรือไม่</span>
          <span className="important-ct">*</span>
          <div className="card">
            <div
              className="card-body"
              style={
                getIn(touched, "other.sick")
                  ? getIn(errors, "other.sick")
                    ? {
                        border: "1px solid #f32626",
                        backgroundColor: "#faefec",
                        color: "#f32626",
                      }
                    : { border: "1px solid #5ec236", color: "#5ec236" }
                  : { border: "1px solid #eeeeee" }
              }
            >
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className="form-check form-check-inline"
                    style={{ marginRight: "10%" }}
                  >
                    <input
                      style={{ width: "20px", height: "20px" }}
                      className={
                        "form-check-input " +
                        (getIn(touched, "other.sick")
                          ? getIn(errors, "other.sick")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      type="radio"
                      name="sick1"
                      id="sick1"
                      value="1"
                      checked={values.other.sick === "1" ? true : false}
                      onChange={(e) => {
                        setFieldValue("other.sick", e.target.value);
                      }}
                    />
                    <label className="form-check-label" for="sick1">
                      เคย
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      style={{ width: "20px", height: "20px" }}
                      className={
                        "form-check-input " +
                        (getIn(touched, "other.sick")
                          ? getIn(errors, "other.sick")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      type="radio"
                      name="sick2"
                      id="sick2"
                      value="0"
                      checked={values.other.sick === "0" ? true : false}
                      onChange={(e) => {
                        setFieldValue("other.sick", e.target.value);
                      }}
                    />
                    <label className="form-check-label" for="sick2">
                      ไม่เคย
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className={
              values.other.sick === "1" ? "col-sm-12 px-md-3 mt-2" : "hidden-ct"
            }
          >
            <span>ระบุโรคหรืออาการป่วยที่เคยเป็น</span>
            <span className="important-ct">*</span>
            <textarea
              value={values.other.sickDetail}
              type="text"
              className={
                "form-control  " +
                (getIn(touched, "other.sickDetail")
                  ? getIn(errors, "other.sickDetail")
                    ? "is-invalid"
                    : "is-valid"
                  : "")
              }
              onBlur={handleBlur}
              name={"other.sickDetail"}
              onChange={(e) => {
                setFieldValue("other.sickDetail", e.target.value);
              }}
              rows="4"
              cols="50"
            />
            <ErrorMessage
              component="div"
              name={"other.sickDetail"}
              className="invalid-feedback"
            />
          </div>
        </div>
        <div className="mt-2">
          <span>เคยสมัครกับบริษัทนี้มาก่อนหรือไม่</span>
          <span className="important-ct">*</span>
          <div className="card">
            <div
              className="card-body"
              style={
                getIn(touched, "other.applyThisCompany")
                  ? getIn(errors, "other.applyThisCompany")
                    ? {
                        border: "1px solid #f32626",
                        backgroundColor: "#faefec",
                        color: "#f32626",
                      }
                    : { border: "1px solid #5ec236", color: "#5ec236" }
                  : { border: "1px solid #eeeeee" }
              }
            >
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className="form-check form-check-inline"
                    style={{ marginRight: "10%" }}
                  >
                    <input
                      style={{ width: "20px", height: "20px" }}
                      className="form-check-input"
                      type="radio"
                      name="applyThisCompany1"
                      id="applyThisCompany1"
                      value="1"
                      checked={
                        values.other.applyThisCompany === "1" ? true : false
                      }
                      onChange={(e) => {
                        setFieldValue("other.applyThisCompany", e.target.value);
                      }}
                    />
                    <label className="form-check-label" for="applyThisCompany1">
                      เคย
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      style={{ width: "20px", height: "20px" }}
                      className="form-check-input"
                      type="radio"
                      name="applyThisCompany2"
                      id="applyThisCompany2"
                      value="0"
                      checked={
                        values.other.applyThisCompany === "0" ? true : false
                      }
                      onChange={(e) => {
                        setFieldValue("other.applyThisCompany", e.target.value);
                      }}
                    />
                    <label className="form-check-label" for="applyThisCompany2">
                      ไม่เคย
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className={
              values.other.applyThisCompany === "1"
                ? "col-sm-6 px-md-3 mt-2"
                : "hidden-ct"
            }
          >
            <span>ปีที่ทำงาน</span>
            <span className="important-ct">*</span>
            <input
              value={values.other.applyDate}
              type="text"
              className={
                "form-control  " +
                (getIn(touched, "other.applyDate")
                  ? getIn(errors, "other.applyDate")
                    ? "is-invalid"
                    : "is-valid"
                  : "")
              }
              onBlur={handleBlur}
              name={"other.applyDate"}
              onChange={(e) => {
                setFieldValue("other.applyDate", e.target.value);
              }}
            />
            <ErrorMessage
              component="div"
              name={"other.applyDate"}
              className="invalid-feedback"
            />
          </div>
          <div
            className={
              values.other.applyThisCompany === "1"
                ? "col-sm-6 px-md-3 mt-2"
                : "hidden-ct"
            }
          >
            <span>ตำแหน่ง</span>
            <span className="important-ct">*</span>
            <input
              value={values.other.applyPosition}
              type="text"
              className={
                "form-control  " +
                (getIn(touched, "other.applyPosition")
                  ? getIn(errors, "other.applyPosition")
                    ? "is-invalid"
                    : "is-valid"
                  : "")
              }
              onBlur={handleBlur}
              name={"other.applyPosition"}
              onChange={(e) => {
                setFieldValue("other.applyPosition", e.target.value);
              }}
            />
            <ErrorMessage
              component="div"
              name={"other.applyPosition"}
              className="invalid-feedback"
            />
          </div>
        </div>
        <br />
        {/* <div className="row">
          <div className="col-sm-6 px-md-3 mt-1">
            <span>อัพโหลดรูปภาพ</span>
            <br />
            <input type="file" className="btn btn-info" />
          </div>
          <div className="col-sm-6 px-md-3 mt-1">
            <span>อัพโหลดเอกสารเพิ่มเติม</span>
            <br />
            <input type="file" className="btn btn-info" />
          </div>
        </div>
        <br /> */}
        <div className="form-check">
          <input
            style={{ width: "20px", height: "20px" }}
            className="form-check-input"
            type="checkbox"
            value="0"
            id="flexCheckChecked"
            checked={accept === 0 ? true : false}
            onChange={(e) => {
              if (accept === 1) {
                setAccept(0);
              } else {
                setAccept(1);
              }
            }}
          />
          <label
            className="form-check-label text-center"
            for="flexCheckChecked"
          >
            ข้าพเจ้าขอรับรองว่าข้อความดังกล่าวทั้งหมดในใบสมัครนี้เป็นความจริงทุกประการ
            หลังจากบริษัทจ้างเข้ามา ทำงานแล้วปรากฏว่า
            ข้อความในใบสมัครงานเอกสารที่นำมาแสดง
            หรือรายละเอียดที่ให้ไว้ไม่เป็นความจริง บริษัทฯ มี
            สิทธิ์ที่จะเลิกจ้างข้าพเจ้าได้โดยไม่ต้องจ่ายเงินชดเชยหรือค่าเสียหายใดๆ
            ทั้งสิ้น
          </label>
        </div>
      </div>
    </>
  );
}
