import React, { useState } from 'react';
import DateTH from '../../../Helper/date-th';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '@material-ui/lab/Pagination';
import ReactExport from 'react-data-export';
import PrintContent from "./printContent";
import printJS from "print-js";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function ShowData({
  data,
  dataExport,
  page,
  pageSizes,
  errorMassNoData,
  currentPage,
  values,
  fetchData,
  setData,
  setDataExport,
  setcurrentPage,
  loading,
  user,
  codTotal,
  PageSizes,
  shipmentTotal,
  moneyDue,
  moneyDue2,
  rate,
  rate2,
  setLabelData,
  printLael,
}) {

  return (
    <>
      <div>
      <div style={{ display: "none" }}>
          <div id="printReport">
            <PrintContent
              data={dataExport}
              branchs={values.branch}
              startDate={values.startYear + "-" + values.startMonth + "-" + values.startDay}
              endDate={values.endYear + "-" + values.endMonth + "-" + values.endDay}
            />
          </div>
        </div>
        <ExcelFile
          element={
            <button type="button" style={{ display: 'none' }} id="downloadExShowPrice">
              Download Data
            </button>
          }
          filename={'Invoice report'}
        >
          <ExcelSheet data={dataExport} name={'Invoice report'}>
            <ExcelColumn label="เลขลาเบล" value="awb_code" />
            <ExcelColumn label="สำหรับลูกค้า" value="ref_no" />
            <ExcelColumn label="หมายเลขinvoice" value="invoice" />
            <ExcelColumn label="รหัสลกค้า" value="cust_code" />
            <ExcelColumn label="ชื่อผู้ส่ง" value="sender_name" />
            <ExcelColumn label="เบอร์โทรผู้ส่ง" value="sender_phone" />
            <ExcelColumn label="บัตรประชาชนผู้ส่ง" value="citizenID" />
            <ExcelColumn label="ชื่อผู้รับ" value="receiver_name" />
            <ExcelColumn label="เบอร์โทรผู้รับ" value="receiver_phone" />
            <ExcelColumn label="ที่อยู่" value={(row) => `${row.receiver_address} ${row.receiver_amphur} , ${row.receiver_province}`} />
            <ExcelColumn label="น้ำหนัก" value="weight" />
            <ExcelColumn label="ความกว้าง (ซม.)" value="box_width" />
            <ExcelColumn label="ความยาว (ซม.)" value="box_length" />
            <ExcelColumn label="ความสูง (ซม.)" value="box_height" />
            <ExcelColumn label="ขนาด(ซ.ม.)" value="box_size" />
            <ExcelColumn label="COD (บาท)" value={(row) => parseInt(row.cod_cost)} />
            <ExcelColumn label="ค่าขนส่ง COD (บาท)" value={(row) => (parseInt(row.service_cod))} />
            <ExcelColumn label="ค่าขนส่ง (บาท)" value={(row) => (parseInt(row.shipment_cost))} />
            <ExcelColumn label="ค่าขนส่ง (บาท)" value={(row) => (parseInt(row.shipment_cost)+parseInt(row.service_cod))} />
            <ExcelColumn label="Money Due" value = "" />
          </ExcelSheet>
        </ExcelFile>
      </div>

      <div className={data.length > 0 ? '' : 'hidden-ct'}>
        <div className="row text-center">
          <div className="col col-sm-3" style={{ fontSize: '25px' }}>
            ยอดรวม : {page.total.toLocaleString('en')} ชิ้น
          </div>
          {localStorage.getItem('superkey') === 'credit' && user.show_shipment_cost === "0" ? (
              <div className="col col-sm-3" style={{ fontSize: '25px' }}>
                ค่าขนส่งสุทธิ : _
              </div>
              ):(
              <div className="col col-sm-3" style={{ fontSize: '25px' }}>
                ค่าขนส่งสุทธิ : {parseInt(shipmentTotal).toLocaleString('en')} บาท
              </div>
              )}
              {page.total >= 50 && rate2 != '0'? (
                <div className="col col-sm-3" style={{ fontSize: '25px' }}>
                  เปอร์เซ็นที่ได้รับ : {parseFloat(rate2).toLocaleString('en')} %
                </div>
              ):(
                <div className="col col-sm-3" style={{ fontSize: '25px' }}>
                  เปอร์เซ็นที่ได้รับ : {parseFloat(rate).toLocaleString('en')} %
                </div>
              )}
              {
                page.total >= 50 && rate2 != '0' ? (
                  <div className="col col-sm-3" style={{ fontSize: '25px' }}>
                  กำหนดชำระเงิน : {parseFloat(moneyDue2).toLocaleString('en')} บาท
                  </div>
                ):(
                  <div className="col col-sm-3" style={{ fontSize: '25px' }}>
                  กำหนดชำระเงิน : {parseFloat(moneyDue).toLocaleString('en')} บาท
                  </div>
                )
              }
          
        </div>
        <br />
        <div className="row mb-2" style={{ justifyContent: 'flex-end' }}>
          <div className={localStorage.getItem('superkey') !== '1947' ? 'col-sm-11' : 'hidden-ct'}>
          <button
              type="button"
              className="btn btn-info mr-2"
              onClick={() => {
                printJS({
                  printable: "printReport",
                  type: "html",
                  scanStyles: false,
                  css: [
                    window.location.protocol+"//" + window.location.host + "/static/printerReport.css",
                    "https://fonts.googleapis.com/css2?family=Roboto&family=Kanit:wght@200;300;400;500;600&display=swap",
                  ],
                  style: "@page { size: A4 Landscape !important; margin: 2.5mm !important; } ",
                });
              }}
            >
              <Icon.Printer /> พิมพ์
            </button>
          {/* <button
              type="button"
              className="btn btn-info"
              onClick={() => {
                document.getElementById('downloadExShowPrice').click();
              }}
            >
            <Icon.FileEarmarkArrowUp /> export
          </button> */}
          </div>
          <div className="col-sm-1">
            <select
              value={pageSizes}
              className="form-control "
              aria-label="Default select example"
              onChange={(e) => {
                setData([]);
                setDataExport([]);
                setcurrentPage(1);
                PageSizes(e.target.value);
                fetchData({
                  search: values.search,
                  shipment_status: values.shipment_status,
                  start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
                  end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
                  page: 1,
                  number_list: e.target.value,
                  cust_code: user.username,
                  cod_status: values.cod_status,
                });
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div style={{ overflow: 'Auto', fontSize: '14px' }}>
          <table className="table table-bordered">
            <thead style={{ backgroundColor: '#3f75c1' }}>
              <tr style={{ color: '#FFFFFF' }}>
                <td>ลำดับ</td>
                <td>วันเปิดบิล</td>
                <td>บาร์โค้ด/สำหรับลูกค้า</td>
                <td>รหัสร้านค้า</td>
                <td>ผู้ส่ง</td>
                <td>ผู้รับ/ที่อยู่</td>
                <td>COD (บาท)</td>
                <td>ค่าบริการ COD(บาท)</td>
                <td>ค่าขนส่ง (บาท)</td>
                <td>ยอดสุทธิ (บาท)</td>
                <td>กำหนดชำระเงิน (บาท)</td>

              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr key={value.awb_code}>
                  <td>{(page.current_page - 1) * pageSizes + (index + 1)}</td>
                  <td style={{ width: '150px' }}>{value.stockin_date !== null && value.stockin_date !== '' && value.stockin_date !== undefined ? <DateTH date={value.stockin_date} /> : ''}</td>
                  <td style={{ width: '150px' }}>
                    <div className="text-center"> {value.ref_no === null || value.ref_no === '0' ? " " : value.ref_no} </div>
                    <div className="text-center"> {value.ref_no2 === null || value.ref_no2 === '0' ? " " : value.ref_no2} </div>
                    <div className="text-center"> {value.awb_code}</div>
                  </td>
                  <td>{value.cust_code}</td>
                  <td style={{ width: '130px' }}>
                    <div>
                      <b>{value.sender_name}</b>
                    </div>
                    <div>โทร : {value.sender_phone}</div>
                  </td>
                  <td style={{ width: '150px' }}>
                    <div>{value.receiver_name}</div>
                    <div>ที่อยู่ : {value.receiver_address + ' ' + value.receiver_amphur + ', ' + value.receiver_province}</div>
                  </td>
                  <td style={{ textAlign: 'right' }}>{parseInt(value.cod_cost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                  <td style={{ textAlign: 'right' }}>{parseInt(value.service_cod || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                  <td style={{ textAlign: 'right' }}>{parseInt(value.shipment_cost || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                  <td style={{ textAlign: 'right' }}>{(parseInt(value.shipment_cost) +parseInt(value.service_cod)).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                  {
                    page.total >= 50 && value.rate2 != '0' ? (
                      <td style={{ textAlign: 'right' }}>
                        {((parseInt(value.shipment_cost) +parseInt(value.service_cod))
                        *(100-(parseFloat(value.rate2)))/100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                      </td>
                    ):(
                      <td style={{ textAlign: 'right' }}>
                        {((parseInt(value.shipment_cost) +parseInt(value.service_cod))
                        *(100-(parseFloat(value.rate)))/100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                      </td>
                    )
                  }
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div style={{ overflow: 'Auto' }}>
          <div className="d-flex justify-content-between">
            <div>จำนวนทั้งหมด {page.total} รายการ</div>
            <div>
              <Pagination
                count={parseInt(page.last_page)}
                page={currentPage}
                onChange={(e, newPage) => {
                  setData([]);
                  setDataExport([]);
                  setcurrentPage(newPage);
                  fetchData({
                    search: values.search,
                    shipment_status: values.shipment_status,
                    start_date: values.startYear !== '' && values.startMonth !== '' && values.startDay !== '' ? values.startYear + '-' + values.startMonth + '-' + values.startDay : '',
                    end_date: values.endYear !== '' && values.endMonth !== '' && values.endDay !== '' ? values.endYear + '-' + values.endMonth + '-' + values.endDay : '',
                    page: newPage,
                    number_list: pageSizes,
                    cust_code: user.username,
                    cod_status: values.cod_status,
                  });
                }}
                color="primary"
                size="small"
                defaultPage={6}
                siblingCount={1}
              />
            </div>
          </div>
        </div>
      </div>
      <center>
        {errorMassNoData === 1 && loading !== 1 ? (
          <font color="red" size="3">
            {' '}
            ไม่พบข้อมูล!{' '}
          </font>
        ) : (
          ''
        )}
      </center>
      {loading === 1 ? (
        <div className="m-5">
          <div className="text-center">
            <div className="spinner-border  text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            {' ...'}
            กำลังโหลดข้อมูล
          </div>
        </div>
      ) : (
        ''
      )}

    </>
  );
}

export default ShowData;
