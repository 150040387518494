import {useState, useCallback} from 'react';
import {getHubUser} from "../services/hub-users.service";
const useHubUserGet = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const get = useCallback(
        async (id) => {
            setLoading(true);
            setErrorMessage(null);
            try {
                const data = await getHubUser(id);
                setData(data.data.data);
            }
            catch(error){
                setErrorMessage(error.message);
            }
            finally {
                setLoading(false);
            }
        },
        [],
    );

    return {
        get,
        loading,
        errorMessage,
        data,
    }
}

export default useHubUserGet;