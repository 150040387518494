import {useCallback, useState ,useEffect} from "react";
import {getHubUsers} from "../services/hub-users.service";

const useHubUserList = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data , setData] = useState(null);

    const fetch = useCallback(
        async (keyword,page, perPages) => {
            setLoading(true);
            setError(null);
            try{
                const result = await getHubUsers(keyword,page,perPages);
                setData(result.data.data)
            }
            catch(error){
                setError(error.message);
                setData(null);
            }
            finally {
                setLoading(false);
            }
        },
        [],
    );

    return {
        loading,
        data,
        error,
        fetch
    }
};

export default useHubUserList;