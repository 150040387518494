import { porlorPrice, porlorFarmersPrice, thailandPostPrice } from '../Service/service.Rate';
import PropTypes from 'prop-types';

async function GetRate(group) {
  if (group === '1') {
    return await porlorPrice();
  } else if (group === '2') {
    return await porlorFarmersPrice();
  } else {
    return await thailandPostPrice();
  }
}

export async function PriceCalculation(size, weight, recipientProvince, recipientAmphoe, cusGroup) {
  var total = 0;
  var address = ['เกาะสีชัง', 'เกาะยาว', 'เกาะพะงัน', 'เกาะสมุย', 'เกาะลันตา', 'เกาะกูด', 'เกาะช้าง'];
  let shopProvince = localStorage.getItem('province_code');
  shopProvince = shopProvince === 'กรุงเทพฯ' ? 'กรุงเทพมหานคร' : shopProvince;
  if (size !== '' && weight !== '' && recipientProvince !== '' && recipientAmphoe !== '' && cusGroup !== '') {
    // ดึงข้อมูล rate ราคา
    var rate = await GetRate(cusGroup);

    // หาราคา ตามขนาด
    let sizePrice = rate.find((a) => parseFloat(a.size) >= parseFloat(size));

    // หาราคา ตามน้ำหนัก
    let weightPrice = rate.find((a) => parseFloat(a.weight) >= parseFloat(weight));

    // เช็คว่าเป็นเกาะในเงือนไขบวกค่าขนส่ง 100 บาทหรือไม่
    let checkAddress = false;
    address.filter((value) => value === recipientAmphoe).map((value) => (checkAddress = recipientAmphoe === value));

    if (sizePrice !== undefined && weightPrice !== undefined) {
      // เช็คว่าอยู่ในจังหวัดเดียวกันหรืออยู่ในตัวเมืองกรุงเทพ หรือไม่
      if (shopProvince === 'กรุงเทพมหานคร' || recipientProvince === shopProvince) {
        if (parseFloat(sizePrice.sameProvince) > parseFloat(weightPrice.sameProvince)) {
          total = sizePrice.sameProvince;
        } else {
          total = weightPrice.sameProvince;
        }
      } else {
        if (parseFloat(sizePrice.upcountry) > parseFloat(weightPrice.upcountry)) {
          total = sizePrice.upcountry;
        } else {
          total = weightPrice.upcountry;
        }
      }
    } else {
      // เช็คว่าอยู่ในจังหวัดเดียวกันหรืออยู่ในตัวเมืองกรุงเทพ หรือไม่
      if (shopProvince === 'กรุงเทพมหานคร' || recipientProvince === shopProvince) {
        total = Math.max(
          ...rate.map((v) => {
            return v.sameProvince;
          }),
        );
      } else {
        total = Math.max(
          ...rate.map((v) => {
            return v.upcountry;
          }),
        );
      }
    }
    return checkAddress ? parseFloat(total) + 100 : parseFloat(total);
  } else {
    return parseFloat(total);
  }
}

PriceCalculation.defaultProps = {
  size: '',
  weight: '',
  recipientProvince: '',
  recipientAmphoe: '',
  cusGroup: '',
};

PriceCalculation.propTypes = {
  size: PropTypes.string,
  weight: PropTypes.string,
  recipientProvince: PropTypes.string,
  recipientAmphoe: PropTypes.string,
  cusGroup: PropTypes.string,
};
