import React from "react";
import { FieldArray, ErrorMessage, getIn } from "formik";
import { nanoid } from "nanoid";
import * as Icon from "react-bootstrap-icons";

export default function Tap4({
  errors,
  touched,
  handleBlur,
  setFieldValue,
  values,
  param,
  step,
  Del,
}) {
  return (
    <>
      <div className={step === 4 ? "" : "hidden-ct"}>
        <br />
        <div align="left" className={"text-title-color"}>
          <b>ภาษา (language)</b>
        </div>
        <div className="row">
          <div className="col-sm-12 px-md-3">
            <FieldArray name="languages">
              {({ push, remove }) =>
                values.languages.map((value, index) => (
                  <div key={value.key}>
                    <br />
                    <span>
                      ความสามารถทางภาษา (กรณีมีความสามารถทางด้านภาษามากกว่า 1
                      ภาษา กรุณากดปุ่มบวกด้านขวา)
                    </span>
                    <div className="card">
                      <div className="card-body">
                        <div>
                          <div className="row">
                            <div className="col-sm-3 px-md-2 mt-1">
                              <span>ภาษา</span>
                              <span className="important-ct">*</span>
                              <select
                                value={value.language}
                                className={
                                  "form-control  " +
                                  (getIn(
                                    touched,
                                    `languages[${index}].language`
                                  )
                                    ? getIn(
                                        errors,
                                        `languages[${index}].language`
                                      )
                                      ? "is-invalid"
                                      : "is-valid"
                                    : "")
                                }
                                onBlur={handleBlur}
                                name={`languages[${index}].language`}
                                id={`languages[${index}].language`}
                                onChange={(e) => {
                                  setFieldValue(
                                    `languages[${index}].language`,
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="">-- กรุณาระบุภาษา --</option>
                                <option value="ภาษาไทย">ภาษาไทย</option>
                                <option value="ภาษาอังกฤษ">ภาษาอังกฤษ</option>
                                <option value="ภาษาญี่ปุ่น">ภาษาญี่ปุ่น</option>
                              </select>
                              <ErrorMessage
                                component="div"
                                name={`languages[${index}].language`}
                                className="invalid-feedback"
                              />
                            </div>

                            <div
                              className="col-sm-9 px-md-2 mt-1"
                              align="right"
                            >
                              <br />
                              <button
                                className="btn btn-success"
                                type="button"
                                onClick={() => {
                                  push({
                                    key: nanoid(),
                                    code: "",
                                    language: "",
                                    otherLanguage: "",
                                    speaking: "",
                                    read: "",
                                    write: "",
                                  });
                                }}
                              >
                                <Icon.PlusCircleFill />
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                  if (value.code === "") {
                                    remove(index);
                                  } else {
                                    remove(index);
                                    Del({
                                      code: value.code,
                                      table: "applicants_language",
                                    });
                                  }
                                }}
                                disabled={values.languages.length === 1}
                              >
                                <Icon.TrashFill />
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4 px-md-2 mt-1">
                              <span>ระดับทักษะทางด้านการพูด</span>
                              <span className="important-ct">*</span>
                              <div className="card">
                                <div
                                  className="card-body"
                                  style={
                                    getIn(
                                      touched,
                                      `languages[${index}].speaking`
                                    )
                                      ? getIn(
                                          errors,
                                          `languages[${index}].speaking`
                                        )
                                        ? {
                                            border: "1px solid #f32626",
                                            backgroundColor: "#faefec",
                                          }
                                        : { border: "1px solid #5ec236" }
                                      : { border: "1px solid #eeeeee" }
                                  }
                                >
                                  <div className="form-check form-check-inline">
                                    <input
                                      style={{ width: "20px", height: "20px" }}
                                      className={
                                        "form-check-input " +
                                        (getIn(
                                          touched,
                                          `languages[${index}].speaking`
                                        )
                                          ? getIn(
                                              errors,
                                              `languages[${index}].speaking`
                                            )
                                            ? "is-invalid"
                                            : "is-valid"
                                          : "") +
                                        ""
                                      }
                                      type="radio"
                                      name={`languages[${index}].speaking3`}
                                      id={`languages[${index}].speaking3`}
                                      value="3"
                                      checked={
                                        value.speaking === "3" ? true : false
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `languages[${index}].speaking`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for={`languages[${index}].speaking3`}
                                    >
                                      ดี
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      style={{ width: "20px", height: "20px" }}
                                      className={
                                        "form-check-input " +
                                        (getIn(
                                          touched,
                                          `languages[${index}].speaking`
                                        )
                                          ? getIn(
                                              errors,
                                              `languages[${index}].speaking`
                                            )
                                            ? "is-invalid"
                                            : "is-valid"
                                          : "") +
                                        ""
                                      }
                                      type="radio"
                                      name={`languages[${index}].speaking2`}
                                      id={`languages[${index}].speaking2`}
                                      value="2"
                                      checked={
                                        value.speaking === "2" ? true : false
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `languages[${index}].speaking`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for={`languages[${index}].speaking2`}
                                    >
                                      ปานกลาง
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      style={{ width: "20px", height: "20px" }}
                                      className={
                                        "form-check-input " +
                                        (getIn(
                                          touched,
                                          `languages[${index}].speaking`
                                        )
                                          ? getIn(
                                              errors,
                                              `languages[${index}].speaking`
                                            )
                                            ? "is-invalid"
                                            : "is-valid"
                                          : "") +
                                        ""
                                      }
                                      type="radio"
                                      name={`languages[${index}].speaking1`}
                                      id={`languages[${index}].speaking1`}
                                      value="1"
                                      checked={
                                        value.speaking === "1" ? true : false
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `languages[${index}].speaking`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for={`languages[${index}].speaking1`}
                                    >
                                      พอใช้
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 px-md-2 mt-1">
                              <span>ระดับทักษะทางด้านการอ่าน</span>
                              <span className="important-ct">*</span>
                              <div className="card">
                                <div
                                  className="card-body"
                                  style={
                                    getIn(touched, `languages[${index}].read`)
                                      ? getIn(
                                          errors,
                                          `languages[${index}].read`
                                        )
                                        ? {
                                            border: "1px solid #f32626",
                                            backgroundColor: "#faefec",
                                          }
                                        : { border: "1px solid #5ec236" }
                                      : { border: "1px solid #eeeeee" }
                                  }
                                >
                                  <div className="form-check form-check-inline">
                                    <input
                                      style={{ width: "20px", height: "20px" }}
                                      className={
                                        "form-check-input " +
                                        (getIn(
                                          touched,
                                          `languages[${index}].read`
                                        )
                                          ? getIn(
                                              errors,
                                              `languages[${index}].read`
                                            )
                                            ? "is-invalid"
                                            : "is-valid"
                                          : "") +
                                        ""
                                      }
                                      type="radio"
                                      name={`languages[${index}].read3`}
                                      id={`languages[${index}].read3`}
                                      value="3"
                                      checked={
                                        value.read === "3" ? true : false
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `languages[${index}].read`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for={`languages[${index}].read3`}
                                    >
                                      ดี
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      style={{ width: "20px", height: "20px" }}
                                      className={
                                        "form-check-input " +
                                        (getIn(
                                          touched,
                                          `languages[${index}].read`
                                        )
                                          ? getIn(
                                              errors,
                                              `languages[${index}].read`
                                            )
                                            ? "is-invalid"
                                            : "is-valid"
                                          : "") +
                                        ""
                                      }
                                      type="radio"
                                      name={`languages[${index}].read2`}
                                      id={`languages[${index}].read2`}
                                      value="2"
                                      checked={
                                        value.read === "2" ? true : false
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `languages[${index}].read`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for={`languages[${index}].read2`}
                                    >
                                      ปานกลาง
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      style={{ width: "20px", height: "20px" }}
                                      className={
                                        "form-check-input " +
                                        (getIn(
                                          touched,
                                          `languages[${index}].read`
                                        )
                                          ? getIn(
                                              errors,
                                              `languages[${index}].read`
                                            )
                                            ? "is-invalid"
                                            : "is-valid"
                                          : "") +
                                        ""
                                      }
                                      type="radio"
                                      name={`languages[${index}].read3`}
                                      id={`languages[${index}].read3`}
                                      value="1"
                                      checked={
                                        value.read === "1" ? true : false
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `languages[${index}].read`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for={`languages[${index}].read3`}
                                    >
                                      พอใช้
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4 px-md-2 mt-1">
                              <span>ระดับทักษะทางด้านการเขียน</span>
                              <span className="important-ct">*</span>
                              <div className="card">
                                <div
                                  className="card-body"
                                  style={
                                    getIn(touched, `languages[${index}].write`)
                                      ? getIn(
                                          errors,
                                          `languages[${index}].write`
                                        )
                                        ? {
                                            border: "1px solid #f32626",
                                            backgroundColor: "#faefec",
                                          }
                                        : { border: "1px solid #5ec236" }
                                      : { border: "1px solid #eeeeee" }
                                  }
                                >
                                  <div className="form-check form-check-inline">
                                    <input
                                      style={{ width: "20px", height: "20px" }}
                                      className={
                                        "form-check-input " +
                                        (getIn(
                                          touched,
                                          `languages[${index}].write`
                                        )
                                          ? getIn(
                                              errors,
                                              `languages[${index}].write`
                                            )
                                            ? "is-invalid"
                                            : "is-valid"
                                          : "") +
                                        ""
                                      }
                                      type="radio"
                                      name={`languages[${index}].write3`}
                                      id={`languages[${index}].write3`}
                                      value="3"
                                      checked={
                                        value.write === "3" ? true : false
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `languages[${index}].write`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for={`languages[${index}].write3`}
                                    >
                                      ดี
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      style={{ width: "20px", height: "20px" }}
                                      className={
                                        "form-check-input " +
                                        (getIn(
                                          touched,
                                          `languages[${index}].write`
                                        )
                                          ? getIn(
                                              errors,
                                              `languages[${index}].write`
                                            )
                                            ? "is-invalid"
                                            : "is-valid"
                                          : "") +
                                        ""
                                      }
                                      type="radio"
                                      name={`languages[${index}].write2`}
                                      id={`languages[${index}].write2`}
                                      value="2"
                                      checked={
                                        value.write === "2" ? true : false
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `languages[${index}].write`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for={`languages[${index}].write2`}
                                    >
                                      ปานกลาง
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      style={{ width: "20px", height: "20px" }}
                                      className={
                                        "form-check-input " +
                                        (getIn(
                                          touched,
                                          `languages[${index}].write`
                                        )
                                          ? getIn(
                                              errors,
                                              `languages[${index}].write`
                                            )
                                            ? "is-invalid"
                                            : "is-valid"
                                          : "") +
                                        ""
                                      }
                                      type="radio"
                                      name={`languages[${index}].write1`}
                                      id={`languages[${index}].write1`}
                                      value="1"
                                      checked={
                                        value.write === "1" ? true : false
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `languages[${index}].write`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      for={`languages[${index}].write1`}
                                    >
                                      พอใช้
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </FieldArray>
            {/* <p> หมายเหตุ: กรณีได้หลายภาษาสามารถเพิ่มได้</p> */}
          </div>
        </div>
        <br />
        <div align="left" className={"text-title-color"}>
          <b>ความสามารถ (Ability)</b>
        </div>
        <br />

        <div className="mt-2">
          <span>ใช้งานคอมพิวเตอร์ได้หรือไม่</span>
          <span className="important-ct">*</span>
          <div className="card">
            <div
              className="card-body"
              style={
                getIn(touched, "ability.useComputer")
                  ? getIn(errors, "ability.useComputer")
                    ? {
                        border: "1px solid #f32626",
                        backgroundColor: "#faefec",
                        color: "#f32626",
                      }
                    : { border: "1px solid #5ec236", color: "#5ec236" }
                  : { border: "1px solid #eeeeee" }
              }
            >
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className="form-check form-check-inline"
                    style={{ marginRight: "10%" }}
                  >
                    <input
                      style={{ width: "20px", height: "20px" }}
                      className={
                        "form-check-input " +
                        (getIn(touched, "ability.useComputer")
                          ? getIn(errors, "ability.useComputer")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      type="radio"
                      name={"useComputer1"}
                      id={"useComputer1"}
                      value={"1"}
                      checked={
                        values.ability.useComputer === "1" ? true : false
                      }
                      onChange={(e) => {
                        setFieldValue("ability.useComputer", e.target.value);
                      }}
                    />
                    <label className="form-check-label" for="useComputer1">
                      ได้
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      style={{ width: "20px", height: "20px" }}
                      className={
                        "form-check-input " +
                        (getIn(touched, "ability.useComputer")
                          ? getIn(errors, "ability.useComputer")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      type="radio"
                      name={"useComputer2"}
                      id={"useComputer2"}
                      value={"0"}
                      checked={
                        values.ability.useComputer === "0" ? true : false
                      }
                      onChange={(e) => {
                        setFieldValue("ability.useComputer", e.target.value);
                      }}
                    />
                    <label className="form-check-label" for="useComputer2">
                      ไม่ได้
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <span>ขับรถยนต์ได้หรือไม่</span>
          <span className="important-ct">*</span>
          <div className="card">
            <div
              className="card-body"
              style={
                getIn(touched, "ability.driveCar")
                  ? getIn(errors, "ability.driveCar")
                    ? {
                        border: "1px solid #f32626",
                        backgroundColor: "#faefec",
                        color: "#f32626",
                      }
                    : { border: "1px solid #5ec236", color: "#5ec236" }
                  : { border: "1px solid #eeeeee" }
              }
            >
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className="form-check form-check-inline"
                    style={{ marginRight: "10%" }}
                  >
                    <input
                      style={{ width: "20px", height: "20px" }}
                      className={
                        "form-check-input " +
                        (getIn(touched, "ability.driveCar")
                          ? getIn(errors, "ability.driveCar")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      type="radio"
                      name={"driveCar1"}
                      id={"driveCar1"}
                      value={"1"}
                      checked={values.ability.driveCar === "1" ? true : false}
                      onChange={(e) => {
                        setFieldValue("ability.driveCar", e.target.value);
                      }}
                    />
                    <label className="form-check-label" for="driveCar1">
                      ได้
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      style={{ width: "20px", height: "20px" }}
                      className={
                        "form-check-input " +
                        (getIn(touched, "ability.driveCar")
                          ? getIn(errors, "ability.driveCar")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      type="radio"
                      name={"driveCar2"}
                      id={"driveCar2"}
                      value={"0"}
                      checked={values.ability.driveCar === "0" ? true : false}
                      onChange={(e) => {
                        setFieldValue("ability.driveCar", e.target.value);
                      }}
                    />
                    <label className="form-check-label" for="driveCar2">
                      ไม่ได้
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className={
              values.ability.driveCar === "1"
                ? "col-sm-12 px-md-3 mt-2"
                : "hidden-ct"
            }
          >
            <span>ระบุเลขที่ใบขับขี่</span>
            <span className="important-ct">*</span>
            <input
              value={values.ability.driverLicense}
              className={
                "form-control  " +
                (getIn(touched, "ability.driverLicense")
                  ? getIn(errors, "ability.driverLicense")
                    ? "is-invalid"
                    : "is-valid"
                  : "")
              }
              onBlur={handleBlur}
              name={"ability.driverLicense"}
              onChange={(e) => {
                setFieldValue("ability.driverLicense", e.target.value);
              }}
            />
            <ErrorMessage
              component="div"
              name={"ability.driverLicense"}
              className="invalid-feedback"
            />
          </div>
        </div>

        <div className="mt-2">
          <span>สามารถปฏิบัติงานต่างจังหวัดได้หรือไม่</span>
          <span className="important-ct">*</span>
          <div className="card">
            <div
              className="card-body"
              style={
                getIn(touched, "ability.workingProvinces")
                  ? getIn(errors, "ability.workingProvinces")
                    ? {
                        border: "1px solid #f32626",
                        backgroundColor: "#faefec",
                        color: "#f32626",
                      }
                    : { border: "1px solid #5ec236", color: "#5ec236" }
                  : { border: "1px solid #eeeeee" }
              }
            >
              <div className="row">
                <div className="col">
                  <div
                    className="form-check form-check-inline"
                    style={{ marginRight: "10%" }}
                  >
                    <input
                      style={{ width: "20px", height: "20px" }}
                      className={
                        "form-check-input " +
                        (getIn(touched, "ability.workingProvinces")
                          ? getIn(errors, "ability.workingProvinces")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      type="radio"
                      name={"workingProvinces1"}
                      id={"workingProvinces1"}
                      value={"1"}
                      checked={
                        values.ability.workingProvinces === "1" ? true : false
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "ability.workingProvinces",
                          e.target.value
                        );
                      }}
                    />
                    <label className="form-check-label" for="workingProvinces1">
                      ได้
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      style={{ width: "20px", height: "20px" }}
                      className={
                        "form-check-input " +
                        (getIn(touched, "ability.workingProvinces")
                          ? getIn(errors, "ability.workingProvinces")
                            ? "is-invalid"
                            : "is-valid"
                          : "") +
                        ""
                      }
                      type="radio"
                      name={"workingProvinces2"}
                      id={"workingProvinces2"}
                      value={"0"}
                      checked={
                        values.ability.workingProvinces === "0" ? true : false
                      }
                      onChange={(e) => {
                        setFieldValue(
                          "ability.workingProvinces",
                          e.target.value
                        );
                      }}
                    />
                    <label className="form-check-label" for="workingProvinces2">
                      ไม่ได้
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 px-md-3 mt-2">
            <span>ความสามารถด้านอื่นๆ</span>
            <textarea
              className={
                "form-control  " +
                (getIn(touched, "ability.AbilityOther")
                  ? getIn(errors, "ability.AbilityOther")
                    ? "is-invalid"
                    : "is-valid"
                  : "")
              }
              id="w3review"
              name="w3review"
              rows="4"
              cols="50"
              onChange={(e) => {
                setFieldValue("ability.AbilityOther", e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
