import React, {useState, useRef,useEffect} from 'react'
import * as Yup from 'yup';
import { parcelsRef_noGroupUpdate } from '../../../Service/service.Barcode';
import { Spinner } from "react-bootstrap";
import { swalError } from '../../../Components/sweetAlert';
import { Formik, Form} from 'formik';
import { Modal } from 'react-bootstrap';

const schema = Yup.object().shape({
    ref_no: Yup.string(),
    invoice: Yup.string(),
});

export default function EditRefNo({
    barcodes,
    onFinish,
    onClose,
}) {
const [initialValues, setInitialValues] = useState(null);
const [loading, setLoading] = useState(false);
const formRef = useRef(null);
    useEffect(() => {
        setInitialValues({
            ref_no: '',
            invoice: '',
            barcodes: barcodes.map(row => ({
                code: row.code,
            })),
        })
    }, [barcodes]
    );
    const handleUpdate = async (result) => {
      setLoading(true);
        try {
            const resultat = await parcelsRef_noGroupUpdate(result.barcodes.map(row => ({
                code: row.code,
                ref_no: result.ref_no,
                invoice: result.invoice,
            })));
          onFinish();
      }
      catch (e) {
                console.log(e);
                swalError({
                    text: e.error.message
                });
      }
      finally {
          setLoading(false);
      }
    };
    const formBody = (
       <Formik
                    validationSchema={schema}
                    initialValues={initialValues}
                      enableReinitialize={true}
                      innerRef={formRef}
                      onSubmit={(value, { setFieldValue, resetForm }) => {
                        handleUpdate(value);
                    }}
                >
                    {({ errors, touched, handleBlur, setFieldValue, values, handleSubmit }) => (
                          <Form>
                              <div style={{ maxHeight: '500px', width: '100%' }}>
                                <h4>อัพเดทคอนเต้</h4>
                                <div className="row">
                                <div className="col-md-6">
                                       <div className="form-group">
                                            <label htmlFor={`ref_no`}>สำหรับลูกค้า / เลขที่co note</label>
                                            <input
                                                value={values.ref_no}
                                                type="text"
                                                name={`ref_no`}
                                                className={'form-control'}
                                                onBlur={handleBlur}
                                                onChange={async (e) => {
                                                    setFieldValue(`ref_no`, e.target.value);
                                                }}
                                            />
                                       </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor={`invoice`}>หมายเลขinvoice</label>
                                            <input
                                                value={values.invoice}
                                                type="text"
                                                name={`invoice`}
                                                className={'form-control'}
                                                onBlur={handleBlur}
                                                onChange={async (e) => {
                                                    setFieldValue(`invoice`, e.target.value);
                                                }}
                                            />
                                       </div>
                                    </div>
                                </div>
                            </div>
                            <button type='submit'
                                        style={{width: '100%'}}    
                                        className={'btn btn-success mb-1 '}>
                                        บันทึก
                            </button>

                        </Form>
                    )}
       </Formik>
    );
  return (
          <Modal onHide={() => onClose()} show={true} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>แก้ไขข้อมูลพัสดุฯ</Modal.Title>
            </Modal.Header>
              <Modal.Body>
                  {!loading && initialValues && formBody}
                  {loading && (
                    <div className="spinner-global-content">
                            <Spinner variant="success" animation="border" />
                    </div>
                  )}
              </Modal.Body>
          </Modal>
  )
}
