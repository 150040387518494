import React, { useState, useEffect } from "react";
import logoNavbar from "../../../Assets/images/logo/logoNavbar.png";
import { publicSignUpService } from "../../../Service/service.Authentication";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AUTHEN, USERINFO } from "../../../actions/authen";
import { bank } from "../../../Data/bank";

function SignUp(Authentication) {
  const { lang } = Authentication;
  const history = useHistory();
  const [user, username] = useState("");
  const [pass, password] = useState("");
  const [pass2, password2] = useState("");
  const [userType, SetUserType] = useState("");
  const [setErrorUser, SetErrorUser] = useState("form-control");
  const [setErrorMassUser, SetErrorMassUser] = useState(0);
  const [setErrorPass, SetErrorPass] = useState("form-control");
  const [errorNationalNumber, setErrorNationalNumber] = useState("form-control");
  const [setErrorPass2, SetErrorPass2] = useState("form-control");
  const [setErrorMassPass, SetErrorMassPass] = useState(0);
  const [setErrorUserType, SetErrorUserType] = useState(0);
  const [loading, Loading] = useState(0);
  const [fName, setFName] = useState("");
  const [surname, setSurname] = useState("");
  const [nationalNumber, setNationalNumber] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [checkboxConditions, setcheckboxConditions] = useState(false);
  const [bank_id,Bank_id] = useState("");
  const [bookbank_code,Bookbank_code] = useState("");
  const [bookbank_name,Bookbank_name] = useState("");
  const [setErrorBank_id,SetErrorBank_id] = useState(0);
  const [setErrorBookbank_code,SetErrorBookbank_code] = useState(0);
  const [setErrorBookbank_name,SetErrorBookbank_name] = useState(0);


  //ทดสอบ
  useEffect(() => {
    document.body.style.backgroundColor = "#193b6b";
    return () => {
      document.body.style.backgroundColor = "#ffff";
    };
  }, []);

  async function signUp(value) {
    Loading(0);

    const result = await publicSignUpService(value);

    if (result.res[0].status === true) {
      Loading(0);
      history.push("/login");
    } else {
      Loading(0);
      swal({
        title: lang.language === "th" ? "การลงทะเบียนไม่สำเร็จ" : "registration failed",
        text: lang.language === "th" ? "ตรวจสอบข้อมูลของคุณและลองสมัครใหม่อีกครั้ง" : "check your informations and try to sign up again",
        icon: "warning",
        button: lang.language === "th" ? "ปิด" : "close",
        timer: 1500,
      });
    }
  }

  const handleSubmit = () => {
    SetErrorPass("form-control");
    SetErrorMassPass(0);
    SetErrorUser("form-control");
    SetErrorMassUser(0);
    if (user !== "" && 
      pass !== "" && 
      checkboxConditions && 
      pass.length===6 && 
      pass === pass2 && 
      user.length===10 && 
      nationalNumber.length===13) {
        signUp({
          user: user !== "" ? user : "",
          pass : pass,
          f_name : fName,
          surname: surname,
          national_number: nationalNumber,
          address: address,
          email: email,
          bank_id: bank_id,
          bookbank_code: bookbank_code,
          bookbank_name: bookbank_name
        });
    }
    if (pass === "" || pass !== pass2 || user.length !==10 || nationalNumber.length!==13 || pass.length!==6) {
      SetErrorPass("form-control is-invalid");
      SetErrorMassPass(1);
    }
    if (user === "" ) {
      SetErrorUser("form-control is-invalid");
      SetErrorMassUser(1);
    }
    // if (bank_id === "") {
    //   SetErrorBank_id(1);
    // }
    // if (bookbank_code === "") {
    //   SetErrorBookbank_code(1);
    // }
    // if (bookbank_name === "") {
    //   SetErrorBookbank_name(1);
    // }
  };

  return (
    <div className="dg-login">
      <div className="container ">
        <div className="row " align="center">
          <div className="container">
              <div
                className="card"
                style={{
                  borderTop: "4px solid #ff9900",
                  boxShadow: "0 4px 8px 0 #131212",
                  transition: "0.3s",
                  background: 'white',
                  minWidth: "600px",
                }}
                align="left"
              >
                <div className="card-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="row justify-content-md-center">
                      <div className="col-sm-9 px-md-1 mt-1">
                        <div align="center">
                          <a href = {"/Home"}>
                          <img alt="" src={logoNavbar} width="140" className="d-inline-block align-top" />
                          </a>
                        </div>
                        <div align = "center">
                            <br />
                            <h2 style={{ fontWeight: "bold", color: "rgb(25, 59, 107)" }}>{lang.language === "th" ? "สมัครสมาชิก" : "Sign up"}</h2>
                        </div>
                        <br />
                        <div className="form-group">
                          <label> {lang.language === "th" ? "หมายเลขโทรศัพท์" : "Mobile Number"}</label>
                          <input
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            onChange={(e) => {
                              if(e.target.value.length!==10)SetErrorUser("form-control is-invalid");
                              if(e.target.value.length===10)SetErrorUser("form-control is-valid");
                              username(e.target.value);

                            }}
                            className={setErrorUser}
                            placeholder = "0987654321"
                          />
                        </div>

                        <div className="form-group">
                          <label>{lang.language === "th" ? "ชื่อ" : "First Name"}  </label>
                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            onChange={(e) => setFName(e.target.value)}
                            className="form-control"
                            placeholder = {lang.language === "th" ? "ปอลอ" : "First Name"}
                          />
                        </div>

                        <div className="form-group">
                          <label> {lang.language === "th" ? "นามสกุล" : "Surname"} </label>
                          <input
                            type="text"
                            name="surname"
                            id="surname"
                            onChange={(e) => setSurname(e.target.value)}
                            className="form-control"
                            placeholder = {lang.language === "th" ? "เอ็กซ์เพรส" : "Surname"}
                          />
                        </div>

                        <div className="form-group">
                          <label> {lang.language === "th" ? "รหัสผ่าน" : "Password"} </label>
                          <input
                            type="password"
                            name="password"
                            id="password"
                            onChange={(e) => {
                              if(e.target.value.length!==6)SetErrorPass("form-control is-invalid");
                              if(e.target.value.length===6)SetErrorPass("form-control is-valid");
                              password(e.target.value);
                            }}
                            className={setErrorPass}
                            placeholder =  {lang.language === "th" ? "โปรดกรอกรหัสผ่าน6ตัวของท่าน" : "Please enter your 6 digits password"}
                          />
                        </div>

                        <div className="form-group">
                          <label> {lang.language === "th" ? "ยืนยันรหัสผ่าน" : "Confirm Password"} </label>
                          <input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            onChange={(e) => {
                              password2(e.target.value);
                              if( pass!==e.target.value) SetErrorPass2("form-control is-invalid");
                              if( pass === e.target.value && pass.length===6) SetErrorPass2("form-control is-valid");
                              }}
                            className={setErrorPass2}
                            placeholder = {lang.language === "th" ? "โปรดกรอกรหัสผ่าน6ตัวของท่าน" : "Please enter your 6 digits password"}
                          />
                        </div>

                        <div className="form-group">
                          <label> {lang.language === "th" ? "เลขบัตรประจำตัวประชาชน" : "Nationality Number"} </label>
                          <input
                            type="text"
                            name="IDnumber"
                            id="IDnumber"
                            onChange={ (e) => {
                              setNationalNumber(e.target.value);
                              if(e.target.value.length===13){
                                let sum = 0;
                                for (let i = 0; i < 12; i++) {
                                  sum += parseInt(e.target.value.charAt(i)) * (13 - i);
                                }
                                let mod = sum % 11;
                                let check = (11 - mod) % 10;
                                if (check === parseInt(e.target.value.charAt(12))) {
                                  setErrorNationalNumber("form-control is-valid");
                                } else {
                                  setErrorNationalNumber("form-control is-invalid");
                                }
                              }
                              if(e.target.value.length!==13) setErrorNationalNumber("form-control is-invalid");
                              //if(e.target.value.length===13) setErrorNationalNumber("form-control is-valid");

                            }}
                            className={errorNationalNumber}
                            placeholder = "1300000000000"
                          />
                        </div>

                        <div className="form-group">
                        <label> {lang.language === "th" ? "ที่อยู่" : "Address"} </label>
                          <input
                            type="text"
                            name="address"
                            id="address"
                            onChange={(e) => setAddress(e.target.value)}
                            className={setErrorUser}
                            placeholder = {lang.language === "th" ? "บ้านเลขที่/ตำบล/อำเภอ/จังหวัด" : "House number/streets/district/Province"}
                          />
                        </div>

                        <div className="form-group">
                        <label> {lang.language === "th" ? "อีเมล/ไลน์" : "Email / line"}  </label>
                          <input
                            type="text"
                            name="email/line"
                            id="email/line"
                            onChange={(e) => setEmail(e.target.value)}
                            className={setErrorUser}
                            placeholder = "Porlor@Express.co.th/ Line:@porlor"
                          />
                        </div>

                        <div className="form-group">
                            <label>{lang.language === "th" ? "ธนาคาร" : "Bank name"}</label>
                            <select
                              className={setErrorBank_id===0?"form-control":"form-control is-invalid"}
                              value={bank_id}
                              onChange={(e) => {
                                Bank_id(e.target.value);
                              }}
                            >
                              <option value={""}>{"--กรุณาเลือกธนาคาร--"}</option>
                              {bank.map((value) => (
                                <option value={value.bank_id} key={value.bank_id}>
                                  {value.bankName}
                                </option>
                              ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>{lang.language === "th" ? "เลขบัญชี" : "Account number"}</label>
                            <input
                              value={bookbank_code}
                              onChange={(e) => {
                                Bookbank_code(e.target.value);
                              }}
                              type="text"
                              className={setErrorBookbank_code===0?"form-control":"form-control is-invalid"}
                              placeholder="เลขบัญชี"
                            />
                          </div>
                          <div className="form-group">
                            <label>{lang.language === "th" ? "ชื่อบัญชี" : "Account name"}</label>
                            <input
                              value={bookbank_name}
                              onChange={(e) => {
                                Bookbank_name(e.target.value);
                              }}
                              type="text"
                              className={setErrorBookbank_name===0?"form-control":"form-control is-invalid"}
                              placeholder="ชื่อบัญชี"
                            />
                          </div>
                         
                      
                        
                        <br />
                    <div className="form-check form-check-inline">
                      <input
                        style={{ width: "25px", height: "25px"}}
                        className="form-check-input"
                        type="checkbox"
                        value={checkboxConditions}
                        onChange={(e) => {
                          setcheckboxConditions(e.target.checked);
                        }}
                      />
                      <label className="form-check-label"
                             style = {{ fontWeight: "bold", fontSize: "12px", color: "rgb(25, 59, 107)"}}>
                       {lang.language === "th" ? "ข้าพเจ้าได้เข้าใจและตกลงต" : "I agree with Porlor Express"}<a href = ''> {lang.language === "th" ? "าม เงื่อนไขการใช้" : " Terms and condition"}</a>
                      {lang.language === "th" ? "งาน" : " and"} <a href = ''> {lang.language === "th" ? "นโยบายความเป็นส่วนตัว" : " Privacy policy"} </a>
                      </label>
                    </div>

                        <button
                          disabled={loading === 1 ? true : false}
                          type="submit"
                          className={
                            loading === 1 ? "btn btn-secondary btn-lg btn-block" : "btn btn-primary btn-lg btn-block"
                          }
                        >
                          {lang.language === "th" ? "สมัครสมาชิก" : "Sign up"}
                        </button>
                        <br />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  lang: state.LanguageAction,
});

const mapDispatchToProps = (dispatch) => {
  return {
    AUTHEN: (username, roleType, fullname, province_code, amphur_code) =>
      dispatch(AUTHEN(username, roleType, fullname, province_code, amphur_code)),
    USERINFO: () => dispatch(USERINFO()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
