import React, { useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import * as Icon from "react-bootstrap-icons";
import { bank } from "../../../Data/bank";
import { myCODService } from "../../../Service/service.CashOnDelivery";
import { monthTH } from "../../../Data/month-th.json";
import ReactExport from "react-data-export";
import DateTH from "../../../Helper/date-th";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function MyCOD(props) {
    const { lang } = props;
    var year = new Date().getFullYear();
    var [data2, Data2] = useState([]);
    var years = Array.from(new Array(3), (e, index) => year - index);
    var month = new Date().getMonth() + 1;
    var date = new Date().getDate();
  
    const [setData, SetData] = useState([]);
    const [setData2, SetData2] = useState([]);
    const [setPage, SetPage] = useState({
      current_page: 1,
      last_page: 1,
      total: 0,
    });
    const logo = "";
    const [currentPage, SetcurrentPage] = useState(1);
    const [pageSizes, PageSizes] = useState(10);
    const [customer_type, Customer_type] = useState("");
    const [cust_code, Cust_code] = useState([]);
    const [cust, Cust] = useState([]);
    const [barcode, Barcode] = useState("");
    const [shipStatus, SetStatus] = useState("");
    const [startdate1, SetStart1] = useState("");
    const [startdate2, SetStart2] = useState("");
    const [startdate3, SetStart3] = useState("");
    const [enddate1, SetEnd1] = useState("");
    const [enddate2, SetEnd2] = useState("");
    const [enddate3, SetEnd3] = useState("");
    const [cod, COD] = useState("1");
    const [awbCodeSta, setCodStatus] = useState("");
    const [barcodeNumber, BarcodeNumber] = useState("");
    const [shipmentStatus, ShipmentStatus] = useState("");
    const [cod_cost, Cod_cost] = useState("");
    const [search_ID, Search_ID] = useState("");
    const [search_name, Search_name] = useState("");
    const [sender_name, Sender_name] = useState("");
    const [parcel_code, Parcel_Code] = useState("");
    const [bank_id, Bank_id] = useState("");
    const [bookbank_code, Bookbank_code] = useState("");
    const [bookbank_name, Bookbank_name] = useState("");
    const [setError, SetError] = useState("form-control");
    const [setErrorMass, SetErrorMass] = useState(0);
    const [setErrorMassNoData, SetErrorMassNoData] = useState(0);
    const [errorStartdate, SetErrorStart] = useState("form-control ");
    const [errorEnddate, SetErrorEnd] = useState("form-control ");
    const [errorStartMass, SetErrorStartMass] = useState(0);
    const [errorEndMass, SetErrorEndMass] = useState(0);
    const [errorMassSta, ErrorMassSta] = useState(0);
    const [loading, Loading] = useState(0);
    const [dateNow, setDateNow] = useState(1);
    const [printLebel, SetPrintLebel] = useState([]);
    const [sender_phone,Sender_phone] = useState(localStorage.getItem("username"));
    const [national_id,National_id] = useState("");

    async function fetchData(value) {
        Loading(1);
        const result = await myCODService(value);
        if (result !== undefined) {
            if (result.data.length !== 0) {
            SetData(result.data);
            SetPage({
                current_page: result.pg[0].current_page,
                last_page: result.pg[0].last_page,
                total: result.pg[0].total,
            });
            }
            if (result.data.length !== 0) {
            SetError("form-control");
            SetErrorMass(0);
            SetErrorMassNoData(0);
            }
            if (result.data.length === 0) {
            SetcurrentPage(1);
            SetPage({
                current_page: 1,
                last_page: 1,
                total: 0,
            });
            SetErrorMassNoData(1);
            }
            if (result != null) {
                SetData(result.data);
            }
        } else {
            alert("Server Error");
        }
        Loading(0);
    }
    
    async function fetchData2(value) {
        Loading(1);
        const result = await myCODService(value);
        if (result !== undefined) {
            if (result.data.length !== 0) {
            SetPage({
                current_page: result.pg[0].current_page,
                last_page: result.pg[0].last_page,
                total: result.pg[0].total,
            });
            result.data.map((value,n)=>{
                if(value.cod_transaction_status == "10" || value.cod_transaction_status == 10)value.cod_status="โอนแล้ว";
                if(value.due_date===null)value.cod_status="รอจัดส่ง";
                if(value.cod_transaction_status != "10" && value.cod_transaction_status != '10' && value.due_date!==null)value.cod_status="ยังไม่ได้โอน";
                bank.map((value2,i)=>{
                    if(value2.bank_id===value.bank_id)value.bankName=value2.bankName;
                });    
            });
            SetData2(result.data);
            document.getElementById("downloadEx1").click();
            }
            if (result.data.length !== 0) {
            SetError("form-control");
            SetErrorMass(0);
            SetErrorMassNoData(0);
            }
            if (result.data.length === 0) {
            SetcurrentPage(1);
            SetPage({
                current_page: 1,
                last_page: 1,
                total: 0,
            });
            SetErrorMassNoData(1);
            }
            if (result != null) {
                SetData2(result.data);
            }
        } else {
            alert("Server Error");
        }
        Loading(0);
    }
    
    async function handleSubmit2Excel(){
        SetcurrentPage(1);
        SetData2([]);
        SetError("form-control");
        SetErrorMass(0);
        fetchData2({
            search: barcodeNumber != "" ? barcodeNumber : "",
            shipment_status: shipStatus != "" ? shipStatus : "",
            start_date:
                startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
            end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
            page: setPage.current_page,
            number_list: "",
            cust_code: localStorage.getItem("superkey")!=="1947"? (sender_phone != "" ? sender_phone : ""):cust_code != "" ? cust_code[0].cust_code : "",
            cod_status: cod != "" ? cod : "",
            search_name: search_name != "" ? search_name : "",
            national_id: national_id !="" ? national_id : "",
            sender_phone: localStorage.getItem("superkey")==="1947"? (sender_phone != "" ? sender_phone : ""): "",
            cod_cost: cod_cost != "" ? cod_cost : "",
            bookbank_code: bookbank_code != "" ? bookbank_code : "",
            bank_id: bank_id != "" ? bank_id : ""
        });
      };

    async function handleSubmit(){
        fetchData({
            search: barcodeNumber != "" ? barcodeNumber : "",
            shipment_status: shipStatus != "" ? shipStatus : "",
            start_date:
                startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
            end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
            page: setPage.current_page,
            number_list: pageSizes,
            cust_code: localStorage.getItem("superkey")!=="1947"? (sender_phone != "" ? sender_phone : ""):cust_code != "" ? cust_code[0].cust_code : "",
            cod_status: cod != "" ? cod : "",
            search_name: search_name != "" ? search_name : "",
            national_id: national_id !="" ? national_id : "",
            sender_phone: localStorage.getItem("superkey")==="1947"? (sender_phone != "" ? sender_phone : ""):"",
            cod_cost: cod_cost != "" ? cod_cost : "",
            bookbank_code: bookbank_code != "" ? bookbank_code : "",
            bank_id: bank_id != "" ? bank_id : ""
        });
    }
    const handleChangePage = (event, newPage) => {
        SetData([]);
        SetcurrentPage(newPage);
        fetchData({
            search: barcodeNumber != "" ? barcodeNumber : "",
            shipment_status: shipStatus != "" ? shipStatus : "",
            start_date:
                startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
            end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
            page: newPage,
            number_list: pageSizes,
            cust_code: localStorage.getItem("superkey")!=="1947"? (sender_phone != "" ? sender_phone : ""):cust_code != "" ? cust_code[0].cust_code : "",
            cod_status: cod != "" ? cod : "",
            search_name: search_name != "" ? search_name : "",
            national_id: national_id !="" ? national_id : "",
            sender_phone: localStorage.getItem("superkey")==="1947"? (sender_phone != "" ? sender_phone : ""):"",
            cod_cost: cod_cost != "" ? cod_cost : "",
            bookbank_code: bookbank_code != "" ? bookbank_code : "",
            bank_id: bank_id != "" ? bank_id : ""
        });
      };
      function pageSize(data) {
        SetData([]);
        SetcurrentPage(1);
        PageSizes(data);
        fetchData({
          search: barcode != "" ? barcode : "",
          shipment_status: shipStatus != "" ? shipStatus : "",
          start_date:
            startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
          end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
          page: "1",
          number_list: data,
          cust_code: localStorage.getItem("superkey")!=="1947"? (sender_phone != "" ? sender_phone : ""):cust_code != "" ? cust_code[0].cust_code : "",
          cod_status: cod != "" ? cod : "",
          search_name: search_name != "" ? search_name : "",
          national_id: national_id ? national_id : "",
          sender_phone: localStorage.getItem("superkey")==="1947"? (sender_phone != "" ? sender_phone : ""):""
        });
      }
    function clear() {
        BarcodeNumber("");
        Bookbank_code("");
        Bank_id("");
        Cod_cost("");
        setDateNow(1);
        ErrorMassSta(0);
        SetErrorStartMass(0);
        SetErrorEndMass(0);
        SetErrorEnd("form-control");
        SetErrorStart("form-control");
        SetErrorMassNoData(0);
        SetStart1("");
        SetStart2("");
        SetStart3("");
        SetEnd1("");
        SetEnd2("");
        SetEnd3("");
        SetError("form-control");
        SetErrorMass(0);
        SetData([]);
        Data2([]);
        Cust_code([]);
        Cust([]);
        SetPrintLebel([]);
        Customer_type("");
        COD("");
        Barcode("");
        SetStatus("");
        SetcurrentPage(1);
        PageSizes(10);
        SetPage({
          current_page: 1,
          last_page: 1,
          total: 0,
        });
      }
    useEffect(()=>{
        fetchData({
            search: barcodeNumber != "" ? barcodeNumber : "",
            shipment_status: shipStatus != "" ? shipStatus : "",
            start_date:
                startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
            end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
            page: setPage.current_page,
            number_list: pageSizes,
            cust_code: localStorage.getItem("superkey")!=="1947"? (sender_phone != "" ? sender_phone : ""):cust_code != "" ? cust_code[0].cust_code : "",
            cod_status: cod != "" ? cod : "",
            search_name: search_name != "" ? search_name : "",
            national_id: national_id !="" ? national_id : "",
            sender_phone: localStorage.getItem("superkey")==="1947"? (sender_phone != "" ? sender_phone : ""):"",
            cod_cost: cod_cost != "" ? cod_cost : "",
            bookbank_code: bookbank_code != "" ? bookbank_code : "",
            bank_id: bank_id != "" ? bank_id : ""
        });
    },[]);
    return (
        // <div
        // className= "container-fluid" 
        // style={{backgroundColor: "rgb(231, 244, 255)"}}>
            <div className="row"
            style={{backgroundColor: "rgb(231, 244, 255)"}}>
                <div className="col-9 "
                style={{backgroundColor: "#FFF"}}>
                <div className="container-fluid mt-2 mb-2">
                    <div className="text-center text-subtitle">
                        <br/>
                        <h2>{lang.language === "th" ? "เก็บเงินปลายทาง" : "MY COD (Cash on Delivery)"}</h2>
                    </div>
                    <br/>
                    <div>
                        <span>
                        <button className="btn btn btn-info" onClick={ async (e)=>{
                                  await handleSubmit2Excel();

                                }}><Icon.FileEarmarkArrowUp /> export</button>
                        </span>
                    </div>
                    <ExcelFile
                        element={
                            // <button style={{ display: "none" }} id="downloadEx">
                            <button style={{display: "none"}} className="btn btn-primary" id="downloadEx1">
                            Download Data
                            </button>
                        }
                        filename="เก็บเงินปลายทาง"
                        >
                        <ExcelSheet data={setData2} name={"เก็บเงินปลายทาง"}>
                            <ExcelColumn label="หมายเลขแทรคกิ้ง" value="awb_code" />
                            <ExcelColumn label="ยอด (บาท)" value="cod_cost" />
                            <ExcelColumn label="ธนาคาร" value="bankName" />
                            <ExcelColumn label="หมายเลขบัญชี" value="bookbank_code" />
                            <ExcelColumn label="สถานะ" value="cod_status" />
                            <ExcelColumn label="วันที่" value="bank_transfer_date" />
                            <ExcelColumn label="เลขที่โอน" value="bank_transfer_receipt" />
                        </ExcelSheet>
                    </ExcelFile>
                    <div style={{ overflow: "Auto" }}>
                        <table className="table table-bordered">
                        <thead style={{ backgroundColor: "#3f75c1" }}>
                            <tr style={{ color: "#FFFFFF" }}>
                               <td>{lang.language === "th" ? "วันที่เปิดบิล" : "Date"}</td>
                                <td>{lang.language === "th" ? "หมายเลขพัสดุฯ" : "Tracking code"}</td>
                                <td>{lang.language === "th" ? "ผู้รับพัสดุฯ" : "Receiver name"}</td>
                                <td>{lang.language === "th" ? "ยอด (บาท)" : "Amount (THB)"}</td>
                                <td>{lang.language === "th" ? "ธนาคาร" : "Bank"}</td>
                                <td>{lang.language === "th" ? "หมายเลขบัญชี" : "Account number"}</td>
                                <td>{lang.language === "th" ? "สถานะ" : "Status"}</td>
                            </tr>
                        </thead>
                        <tbody>
                        {setData.map((value, index) => (
                            <tr key={index}>
                                <td>
                                    <DateTH date={value.created_date.substring(0, 10)}/>
                                </td>
                                <td>
                                    {value.awb_code}
                                </td>
                                <td>
                                    {value.receiver_name}
                                </td>
                                <td>
                                    {value.cod_cost}
                                </td>
                                <td>
                                    {bank.map((value2,i)=>(
                                        value2.bank_id===value.bank_id?(value2.bankNameEN):("")
                                    ))}
                                </td>
                                <td>
                                    {value.bookbank_code}
                                </td>
                                <td align="">
                                {value.cod_transaction_status == "10" || value.cod_transaction_status == 10  ? (
                                    <div style={{align: "center"}}>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-split-basic">
                                        {lang.language === "th" ? "โอนแล้ว" : "Transferred"}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                {/* <img src={urlLocal+value.bank_transfer_image} width="100%" /> */}
                                                {value.bank_transfer_receipt}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <span>
                                        {value.bank_transfer_date!=null && value.bank_transfer_date!=='' && value.bank_transfer_date!="" && value.shipment_status !== "7"?(value.bank_transfer_date.substring(8, 10)+"-"+value.bank_transfer_date.substring(5, 7)+"-"+value.bank_transfer_date.substring(0, 4)):""}
                                    </span>
                                    </div>
                                    
                                ) : (
                                    ""
                                )}
                                {value.due_date===null && value.shipment_status !== "7"?(
                                    <span>
                                        {lang.language === "th" ? "รอจัดส่ง" : "Awaiting delivery"}
                                    </span>
                                ):("")
                                }
                                {value.cod_transaction_status != "10" && value.cod_transaction_status != '10' && value.due_date!==null && value.shipment_status !== "7"? (
                                    <span>
                                        {lang.language === "th" ? "ยังไม่ได้โอน" : "Not transferred"}
                                    </span>
                                ) : (
                                    ""
                                )}
                                {value.shipment_status === "7"? (
                                    <span>
                                        {lang.language === "th" ? "พัสดุถูกส่งกลับต้นทาง" : "Parcel was returned to origin"}
                                    </span>
                                ) : (
                                    ""
                                )}

                                </td>
                            </tr>
                        ))}
                        </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
            <div>Total of {setPage.total} results</div>
            <div>
              <Pagination
                count={parseInt(setPage.last_page)}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
                size="small"
                defaultPage={6}
                siblingCount={1}
              />
            </div>
          </div>

            </div>
                <div className="col-3 "
                style={{backgroundColor: "#FFF"}}>
                    <>
                        {/* <div className="text-center text-subtitle">
                            <br/>
                            <h3>{lang.language === "th" ? "หาจาก" : "Search by"}</h3>
                        </div>
                        <div className="input-group">
                            <input
                            type="text"
                            value={barcodeNumber}
                            onChange={(e) => BarcodeNumber(e.target.value)}
                            className= "form-control"
                            id="clear"
                            placeholder={lang.language === "th" ? "เลขแทรคกิ้ง" : "Tracking code"}
                            />
                        </div>
                        <br/>
                        <div className="input-group">
                            <input
                            type="text"
                            value={bookbank_code}
                            onChange={(e) => Bookbank_code(e.target.value)}
                            id="clear"
                            placeholder={lang.language === "th" ? "เลขบัญชี" : "Account Number"}
                            className= "form-control"
                            />
                        </div>
                        <br/>
                        <div className="input-group">
                            <input
                            type="text"
                            className= "form-control"
                            value={cod_cost}
                            onChange={(e) => Cod_cost(e.target.value)}
                            id="clear"
                            placeholder={lang.language === "th" ? "ยอดCOD" : "COD amount"}
                            />
                        </div>
                        <br/>
                        <div className="input-group-sm">
                        <div className="col-12">
                            <span>{lang.language === "th" ? "ตั้งแต่วันที่เปิดบิล" : "Start date"}</span>
                            <div className="row">
                                <div className="col-12 col-sm-4 col-md-4 px-md-1 mt-1">
                                <select
                                    className={errorStartdate}
                                    value={startdate1}
                                    onChange={(e) => {
                                    SetStart1(e.target.value);
                                    }}
                                >
                                    <option value=""> -- ปี -- </option>
                                    {years.map((year, index) => (
                                    <option key={`year${index}`} value={year}>
                                        {year + 543}
                                    </option>
                                    ))}
                                </select>
                                </div>
                                <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                                <select
                                    className={errorStartdate}
                                    value={startdate2}
                                    onChange={(e) => {
                                    SetStart2(e.target.value);
                                    }}
                                >
                                    <option value=""> -- เดือน -- </option>
                                    {monthTH.map((item, index) => (
                                    <option value={item.id} key={item.id}>
                                        {item.monthName}
                                    </option>
                                    ))}
                                </select>
                                </div>
                                <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                                <select
                                    className={errorStartdate}
                                    value={startdate3}
                                    onChange={(e) => {
                                    SetStart3(e.target.value);
                                    }}
                                >
                                    <option value=""> -- วัน -- </option>
                                    {Array.from(Array(31), (e, i) => (
                                    <option value={i + 1}>{i + 1}</option>
                                    ))}
                                </select>
                                </div>
                            </div>
                            {errorStartMass == 1 && errorMassSta != 1 && errorMassSta != 2 && errorMassSta != 3 ? (
                                <font color="red" size="2">
                                {" "}
                                กรุณาเลือก ปี, เดือน, วัน{" "}
                                </font>
                            ) : (
                                ""
                            )}
                            </div>
                            <div className="col-12">
                                <span>{lang.language === "th" ? "ตั้งแต่วันที่เปิดบิล" : "End date"}</span>
                                <div className="row">
                                    <div className="col-12 col-sm-4 col-md-4 px-md-1 mt-1">
                                    <select
                                        className={errorStartdate}
                                        value={enddate1}
                                        onChange={(e) => {
                                        SetEnd1(e.target.value);
                                        }}
                                    >
                                        <option value=""> -- ปี -- </option>
                                        {years.map((year, index) => (
                                        <option key={`year${index}`} value={year}>
                                            {year + 543}
                                        </option>
                                        ))}
                                    </select>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                                    <select
                                        className={errorEnddate}
                                        value={enddate2}
                                        onChange={(e) => {
                                        SetEnd2(e.target.value);
                                        }}
                                    >
                                        <option value=""> -- เดือน -- </option>
                                        {monthTH.map((item, index) => (
                                        <option value={item.id} key={item.id}>
                                            {item.monthName}
                                        </option>
                                        ))}
                                    </select>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4  px-md-1 mt-1">
                                    <select
                                        className={errorEnddate}
                                        value={enddate3}
                                        onChange={(e) => {
                                        SetEnd3(e.target.value);
                                        }}
                                    >
                                        <option value=""> -- วัน -- </option>
                                        {Array.from(Array(31), (e, i) => (
                                        <option value={i + 1}>{i + 1}</option>
                                        ))}
                                    </select>
                                    </div>
                                </div>
                                {errorStartMass == 1 && errorMassSta != 1 && errorMassSta != 2 && errorMassSta != 3 ? (
                                    <font color="red" size="2">
                                    {" "}
                                    กรุณาเลือก ปี, เดือน, วัน{" "}
                                    </font>
                                ) : (
                                    ""
                                )}
                                </div>
                        </div>
                        <br />
                        <center>
                            <i className="bi bi-search"></i>
                            <button
                                // disabled={loading == 1 ? true : false}
                                // type="submit"
                                className={loading == 1 ? "btn btn-secondary" : "btn btn-primary"}
                                onClick={(e)=>{
                                    handleSubmit();
                                }}
                                >
                                <Icon.Search /> {lang.language === "th" ? "ค้นหา" : "Search"}
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" 
                            className="btn btn-secondary" 
                            onClick={clear}
                            >
                                <Icon.ArrowClockwise /> {lang.language === "th" ? "ล้างค่า" : "Reset"}
                            </button>
                        </center> */}
                    </>
                    <form>
                        <div className="text-center text-subtitle">
                            <br/>
                            <h3>{lang.language === "th" ? "ตัวช่วย" : "Filters"}</h3>
                        </div>
                        <div className="form-group">
                            <label>{lang.language === "th" ? "ธนาคาร" : "Bank"}</label>
                            <select
                                className="form-control"
                                value={bank_id}
                                onChange={(e) => {
                                    Bank_id(e.target.value);
                                    fetchData({
                                        search: barcodeNumber != "" ? barcodeNumber : "",
                                        shipment_status: shipStatus != "" ? shipStatus : "",
                                        start_date:
                                            startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
                                        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
                                        page: setPage.current_page,
                                        number_list: pageSizes,
                                        cust_code: localStorage.getItem("superkey")!=="1947"? (sender_phone != "" ? sender_phone : ""):cust_code != "" ? cust_code[0].cust_code : "",
                                        cod_status: cod != "" ? cod : "",
                                        search_name: search_name != "" ? search_name : "",
                                        national_id: national_id !="" ? national_id : "",
                                        sender_phone: localStorage.getItem("superkey")==="1947"? (sender_phone != "" ? sender_phone : ""):"",
                                        cod_cost: cod_cost != "" ? cod_cost : "",
                                        bookbank_code: bookbank_code != "" ? bookbank_code : "",
                                        bank_id: e.target.value != "" ? e.target.value : ""
                                    });
                                }}
                             >
                                <option value={""}>{lang.language === "th" ? "--เลือกธนาคาร--" : "--Select bank name--"}</option>
                                {bank.map((value) => (
                                <option value={value.bank_id} key={value.bank_id}>
                                    {value.bankName}
                                </option>
                                ))}
                            </select>
                        </div>
                        <br/>
                        <div className="form-group">
                            <label>{lang.language === "th" ? "สถานะ" : "Status"}</label>
                            <select
                                className="form-control"
                                value={shipStatus}
                                onChange={async(e) => {
                                    SetStatus(e.target.value);
                                    fetchData({
                                        search: barcodeNumber != "" ? barcodeNumber : "",
                                        shipment_status: e.target.value != "" ? e.target.value : "",
                                        start_date:
                                            startdate3 != "" && startdate2 != "" && startdate1 != "" ? startdate1 + "-" + startdate2 + "-" + startdate3 : "",
                                        end_date: enddate3 != "" && enddate2 != "" && enddate1 != "" ? enddate1 + "-" + enddate2 + "-" + enddate3 : "",
                                        page: setPage.current_page,
                                        number_list: pageSizes,
                                        cust_code: localStorage.getItem("superkey")!=="1947"? (sender_phone != "" ? sender_phone : ""):cust_code != "" ? cust_code[0].cust_code : "",
                                        cod_status: cod != "" ? cod : "",
                                        search_name: search_name != "" ? search_name : "",
                                        national_id: national_id !="" ? national_id : "",
                                        sender_phone: localStorage.getItem("superkey")==="1947"? (sender_phone != "" ? sender_phone : ""): "",
                                        cod_cost: cod_cost != "" ? cod_cost : "",
                                        bookbank_code: bookbank_code != "" ? bookbank_code : "",
                                        bank_id: bank_id != "" ? bank_id : ""
                                    });
                                }}
                            >
                                <option value={""}>{lang.language === "th" ? "--เลือกสถานะ--" : "--Select status--"}</option>
                                <option value="1"> {lang.language === "th" ? "ยังไม่ได้โอน" : "Not transferred"} </option>
                                <option value="10"> {lang.language === "th" ? "โอนแล้ว" : "Transferred"} </option>
                                <option value="0"> {lang.language === "th" ? "รอจัดส่ง" : "Awaiting delivery"} </option>
                            </select>
                        </div>          
                    </form>
                </div>
            </div>
        // </div>
      );
}

const mapStateToProps = (state) => ({
  lang: state.LanguageAction,
});

export default connect(mapStateToProps)(MyCOD);