import React from "react";
import * as Icon from "react-bootstrap-icons";
import LongDateTH from "../../../utility/LongDateTH";
import Pagination from "@material-ui/lab/Pagination";

export default function ShowData({
  data,
  page,
  last_page,
  loading,
  errorMassNoData,
  handleChangePage,
  pageNo,
  handleShow,
  setDataLabel,
  dataSum,
  setTypeLabel,
}) {
  return (
    <div className="container">
      <br />
      <div
        className={
          page.total !== 0 && loading === 0 && data.length !== 0
            ? "d-flex justify-content-between flex-wrap-wrap"
            : "hidden-ct"
        }
      >
        <div>
          <h5>จำนวน {page.total} รายการ</h5>
        </div>
        <div>
          <h5>ราคาค่าขนส่งรวม {dataSum} บาท</h5>
        </div>
      </div>

      <br />
      <div className={loading === 0 ? "row" : "hidden-ct"}>
        {data.map((value, index) => (
          <div className="container" key={index}>
            <div className="card-custom-apply">
              <div className="card-body">
                <h4 className="text-subtitle-career">
                  เลขเรียกรถ:&nbsp;&nbsp;{value.id}
                </h4>
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-12 px-md-2 mt-1">
                    <h6 className="card-subtitle mb-2 text-subtitle">
                      <Icon.CalendarEvent size="25px" />
                      &nbsp;&nbsp;วันที่เรียกรถ:&nbsp;&nbsp;
                      {LongDateTH(value.created_date)[0]}
                    </h6>
                  </div>
                </div>
                <hr />
                <div className="col-sm-12 px-md-2 mt-3">
                  <h6 className="card-subtitle text-subtitle">
                    <span className="card-subtitle text-title-color mt-custom">
                      &nbsp;สถานที่ไปรับพัสดุ:&nbsp;&nbsp;
                    </span>
                    {value.address}
                  </h6>
                </div>
                <div className="col-sm-12 px-md-2 mt-3">
                  <h6 className="card-subtitle text-subtitle">
                    <span className="card-subtitle text-title-color mt-custom">
                      &nbsp;ชื่อผู้เรียกรถ:&nbsp;&nbsp;
                    </span>
                    {value.name}
                  </h6>
                </div>
                <div className="col-sm-12 px-md-2 mt-3">
                  <h6 className="card-subtitle text-subtitle">
                    <span className="card-subtitle text-title-color mt-custom">
                      &nbsp;ขนาด:&nbsp;&nbsp;
                    </span>
                    {value.box_size}&nbsp;&nbsp;ซ.ม.
                  </h6>
                </div>
                <div className="col-sm-12 px-md-2 mt-3">
                  <h6 className="card-subtitle text-subtitle">
                    <span className="card-subtitle text-title-color mt-custom">
                      &nbsp;น้ำหนัก:&nbsp;&nbsp;
                    </span>
                    {value.weight}&nbsp;&nbsp;กิโลกรัม
                  </h6>
                </div>
                <div className="col-sm-12 px-md-2 mt-3">
                  <h6 className="card-subtitle text-subtitle">
                    <span className="card-subtitle text-title-color mt-custom">
                      &nbsp;ราคาค่าขนส่ง:&nbsp;&nbsp;
                    </span>
                    {value.shipment_cost}&nbsp;&nbsp;บาท
                  </h6>
                </div>
                <br />
                <div className="row">
                  <div className="col-sm">
                    <b className="card-subtitle mb-2 text-subtitle"></b>
                  </div>
                  <div className="col-sm">
                    <div align="right">
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={(e) => {
                          handleShow();
                          setDataLabel(value);
                          setTypeLabel("1");
                        }}
                      >
                        พิมพ์ลาเบล
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={(e) => {
                          handleShow();
                          setDataLabel(value);
                          setTypeLabel("2");
                        }}
                      >
                        พิมพ์ลาเบล/ที่อยู่
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        ))}
      </div>
      <div
        className={
          errorMassNoData === 1 && loading === 0 ? "text-center" : "hidden-ct"
        }
      >
        <h4 className="text-danger m-5">ไม่พบข้อมูล</h4>
      </div>
      <center>
        <div className={loading === 1 ? "m-5" : "hidden-ct"}>
          <div className="text-center">
            <div className="spinner-border  text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            {" ..."}
            กำลังโหลดข้อมูล
          </div>
        </div>
      </center>
      <div className={loading === 0 && data.length !== 0 ? "" : "hidden-ct"}>
        <Pagination
          count={last_page}
          page={pageNo}
          onChange={handleChangePage}
          color="primary"
          size="small"
          defaultPage={6}
          siblingCount={1}
        />
      </div>
      <br />
      <br />
    </div>
  );
}
