import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { SaveEmpService } from "../../../../Service/service.Employee";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { datacentral, usertype } from "../../../../Data/empData.json";

export default function FormEmp() {
  const history = useHistory();
  const [id, setId] = useState("");
  const [titleName, setTitleName] = useState("");
  const [nameEmp, setNameEmp] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [right, setRight] = useState("");
  const [proCenter, setProCenter] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [checkpassword, setCheckPassword] = useState("");
  const [status, setStatus] = useState("1");
  //////เช็ค Error Messege//////
  const [errorTitleName, setErrorTitleName] = useState(1);
  const [errorNameEmp, setErrorNameEmp] = useState(1);
  const [errorLastName, setErrorLastName] = useState(1);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(1);
  const [errorRight, setErrorRight] = useState(1);
  const [errorProCenter, setErrorProCenter] = useState(1);
  const [errorUserName, setErrorUserName] = useState(1);
  const [errorPassword, setErrorPassword] = useState(1);
  const [errorCheckPassword, setErrorCheckPassword] = useState(1);
  const [errorStatus, setErrorStatus] = useState(1);
  ///////////////////////////////
  const [loading, Loading] = useState(0);
  const param = useLocation();
  const [showPass, setShowPass] = useState(1);
  const [userErorr, setUserErorr] = useState("");
  const formatInput = new RegExp("^[a-zA-Z0-9]+$");
  const formatInputNumber = new RegExp("^[0-9]+$");

  // console.log("user", formatInput.test(userName));
  // console.log("pass", formatInput.test(password));

  useEffect(() => {
    if (param.state !== undefined) {
      console.log("param", param);
      setId(param.state.value.id);
      setTitleName(param.state.value.prefix);
      setNameEmp(param.state.value.name);
      setLastName(param.state.value.lastname);
      setPhoneNumber(param.state.value.phone);
      setRight(param.state.value.role_type);
      setProCenter(param.state.value.central);
      setUserName(param.state.value.username);
      setStatus(param.state.value.status);
      setPassword(param.state.value.pass);
      setCheckPassword(param.state.value.pass);
    }
  }, []);

  async function SaveData(value) {
    Loading(1);
    const res = await SaveEmpService(value);
    if (res.status === 1) {
      swal({
        title: "สำเร็จ!",
        text: "",
        icon: "success",
        showConfirmButton: false,
        button: "ปิด",
        timer: 1500,
      });
      history.push("/mis/Employees");
    } else {
      setErrorUserName(4);
      setUserErorr(res.message);
    }
    Loading(0);
  }

  const validatePassword = (password) => {
    if(id && password === ""){
      setErrorCheckPassword(1);
      return true;
    }
    if(!password){
      setErrorPassword(0);
      return false;
    }
    if(!formatInput.test(password)){
      setErrorPassword(5);
      return false;
    }
    if(password.length < 6){
      setErrorPassword(2);
      return false;
    }
    setErrorPassword(1);
    if(checkpassword !== password){
      setErrorCheckPassword(0);
      return false;
    }
    setErrorCheckPassword(1);
    return true;
  }

  const handleSubmit = () => {
    ///เริ่ม///เช็ค Error Messege//////
    titleName === "" ? setErrorTitleName(0) : setErrorTitleName(1);
    nameEmp === "" ? setErrorNameEmp(0) : setErrorNameEmp(1);
    lastName === "" ? setErrorLastName(0) : setErrorLastName(1);
    phoneNumber === ""
      ? setErrorPhoneNumber(0)
      : formatInputNumber.test(phoneNumber) === false
      ? setErrorPhoneNumber(5)
      : phoneNumber.length < 9 || phoneNumber.length > 10
      ? setErrorPhoneNumber(2)
      : setErrorPhoneNumber(1);
    right === "" ? setErrorRight(0) : setErrorRight(1);
    proCenter === "" ? setErrorProCenter(0) : setErrorProCenter(1);
    userName === ""
      ? setErrorUserName(0)
      : formatInput.test(userName) === false
      ? setErrorUserName(5)
      : userName.length < 4
      ? setErrorUserName(2)
      : setErrorUserName(1);
    const validPassword = validatePassword(password);
    status === "" ? setErrorStatus(0) : setErrorStatus(1);
    ///////////////////////////////

    if (
      titleName !== "" &&
      nameEmp !== "" &&
      lastName !== "" &&
      phoneNumber !== "" &&
      phoneNumber.length >= 9 &&
      phoneNumber.length <= 10 &&
      formatInput.test(phoneNumber) === true &&
      right !== "" &&
      proCenter !== "" &&
      userName !== "" &&
      formatInput.test(userName) === true &&
      userName.length >= 4 &&
      validPassword &&
      status !== ""
    ) {
      SaveData({
        id: id !== "" ? id : "",
        username: userName !== "" ? userName : "",
        password: password !== "" ? password : "",
        prefix: titleName !== "" ? titleName : "",
        name: nameEmp !== "" ? nameEmp : "",
        lastname: lastName !== "" ? lastName : "",
        phone: phoneNumber !== "" ? phoneNumber : "",
        status: status !== "" ? status : "",
        role_type: right !== "" ? right : "",
        central: proCenter !== "" ? proCenter : "",
      });
    }
  };

  function clear() {
    setTitleName("");
    setNameEmp("");
    setLastName("");
    setRight("");
    setProCenter("");
    setPassword("");
    setCheckPassword("");
    setStatus("1");
    setErrorTitleName(1);
    setErrorNameEmp(1);
    setErrorLastName(1);
    setErrorPhoneNumber(1);
    setErrorRight(1);
    setErrorProCenter(1);
    setErrorUserName(1);
    setErrorPassword(1);
    setErrorCheckPassword(1);
    setErrorStatus(1);
    if (id === "") {
      setUserName("");
    }
  }

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style={{ backgroundColor: "#ffffff" }}>
          <li class="breadcrumb-item">
            <buton
              type="button"
              class="btn-link"
              style={{ color: "#000000" }}
              onClick={(e) => {
                history.push("Employees");
              }}
            >
              ข้อมูลพนักงาน
            </buton>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            จัดการข้อมูลพนักงาน
          </li>
        </ol>
      </nav>

      <>
        <div className="card">
          <div className="card-body">
            <div className="container-fluid mt-2 mb-2">
              <div className="text-center text-title-color">
                <h2>จัดการข้อมูลพนักงาน</h2>
              </div>
              <div>
                <div class="row">
                  <div class="col-sm-2 px-md-1 mt-1">
                    <b>คำนำหน้าชื่อ</b>
                    <select
                      value={titleName}
                      class={
                        errorTitleName === 1
                          ? "form-control"
                          : "dropdown-custom2 form-control is-invalid"
                      }
                      onChange={(e) => {
                        setTitleName(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">-- คำนำหน้าชื่อ --</option>
                      <option value="นาย">นาย</option>
                      <option value="นาง">นาง</option>
                      <option value="นางสาว">นางสาว</option>
                    </select>
                    {errorTitleName === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณาเลือกคำนำหน้าชื่อ{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-sm-4 px-md-1 mt-1">
                    <b>ชื่อพนักงาน</b>
                    <input
                      value={nameEmp}
                      type="text"
                      class={
                        errorNameEmp === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="ชื่อพนักงาน"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setNameEmp(e.target.value);
                      }}
                    />
                    {errorNameEmp === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกชื่อพนักงาน{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-sm-4 px-md-1 mt-1">
                    <b>นามสกุล</b>
                    <input
                      value={lastName}
                      type="text"
                      class={
                        errorLastName === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="นามสกุล"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                    {errorLastName === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกนามสกุล{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-2 px-md-1 mt-1">
                    <b>เบอร์โทร</b>
                    <input
                      value={phoneNumber}
                      type="text"
                      className={
                        errorPhoneNumber === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="เบอร์โทร"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                    {errorPhoneNumber === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกเบอร์โทร{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorPhoneNumber === 2 ? (
                      <font color="red" size="2">
                        {" "}
                        เบอร์โทรต้องเป็นตัวเลข 9-10 ตัว{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorPhoneNumber === 5 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกข้อมูลเป็นตัวเลข 0-9 เท่านั้น{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-sm-6 px-md-1 mt-1">
                    <b>สิทธิ์การใช้งาน</b>
                    <select
                      value={right}
                      class={
                        errorRight === 1
                          ? "form-control"
                          : "dropdown-custom2 form-control is-invalid"
                      }
                      onChange={(e) => {
                        setRight(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">-- สิทธิ์การใช้งาน --</option>
                      {usertype.map((value) => (
                        <option value={value.id}>{value.name}</option>
                      ))}
                    </select>
                    {errorRight === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณาเลือกสิทธิ์การใช้งาน{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-sm-6 px-md-1 mt-1">
                    <b>ศูนย์กระจายสินค้า</b>
                    <select
                      value={proCenter}
                      class={
                        errorProCenter === 1
                          ? "form-control"
                          : "dropdown-custom2 form-control is-invalid"
                      }
                      onChange={(e) => {
                        setProCenter(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="">-- ศูนย์กระจายสินค้า --</option>
                      {right=="21" ? (
                        <option value={"26"}>{"กรุงเทพฯ"}</option>
                      )
                      :
                        datacentral.map((value) => (
                        <option value={value.id}>{value.name}</option>
                      )
                      )}
                    </select>
                    {errorProCenter === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณาเลือกศูนย์กระจายสินค้า{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <br />
                <b style={{ fontSize: "22px" }}>ข้อมูลผู้ใช้งาน</b>
                <div class="row">
                  <div class="col-sm-3 px-md-1 mt-1">
                    <br />
                    <b>ชื่อผู้ใช้</b>
                    <input
                      style={id !== "" ? { backgroundColor: "#ffff" } : {}}
                      value={userName}
                      type="text"
                      class={
                        errorUserName === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="ชื่อผู้ใช้"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                    />
                    {errorUserName === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกชื่อผู้ใช้{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorUserName === 2 ? (
                      <font color="red" size="2">
                        {" "}
                        ชื่อผู้ใช้ต้องมากกว่า 4 ตัว{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorUserName === 4 ? (
                      <font color="red" size="2">
                        {" "}
                        {userErorr}{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorUserName === 5 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกข้อมูลเป็น a-z, A-Z, 0-9 เท่านั้น{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-sm-3 px-md-1 mt-1">
                    <br />
                    <b>รหัสผ่าน</b>
                    <input
                      value={password}
                      type={showPass === 1 ? "password" : "text"}
                      class={
                        errorPassword === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="รหัสผ่าน"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    {errorPassword === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกรหัสผ่าน{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorPassword === 2 ? (
                      <font color="red" size="2">
                        {" "}
                        รหัสผ่านต้องมากกว่า 6 ตัว{" "}
                      </font>
                    ) : (
                      ""
                    )}
                    {errorPassword === 5 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณากรอกข้อมูลเป็น a-z, A-Z, 0-9 เท่านั้น{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-sm-3 px-md-1 mt-1">
                    <br />
                    <b>ยืนยันรหัสผ่าน</b>
                    <input
                      disabled={password !== "" ? false : true}
                      value={checkpassword}
                      type={showPass === 1 ? "password" : "text"}
                      class={
                        errorCheckPassword === 1
                          ? "form-control"
                          : "form-control is-invalid"
                      }
                      aria-label="ยืนยันรหัสผ่าน"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setCheckPassword(e.target.value);
                      }}
                    />
                    {errorCheckPassword === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        รหัสผ่านไม่ตรงกัน{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-sm-1 px-md-1 mt-1">
                    <br />
                    <br />
                    <button
                      className="btn btn-secondary"
                      onClick={(e) => {
                        if (showPass === 1) {
                          setShowPass(0);
                        } else {
                          setShowPass(1);
                        }
                      }}
                    >
                      {showPass === 1 ? <Icon.EyeSlash /> : <Icon.Eye />}
                    </button>
                  </div>
                  <div class="col-sm-2 px-md-1 mt-1">
                    <br />
                    <b>สถานะ</b>
                    <div
                      style={
                        errorStatus === 0
                          ? { marginTop: "5px", color: "#ff0000" }
                          : { marginTop: "5px" }
                      }
                    >
                      <div class="form-check form-check-inline">
                        <input
                          checked={status === "1" ? true : false}
                          style={{ width: "20px", height: "20px" }}
                          class="form-check-input"
                          type="radio"
                          name="inlineRadio6"
                          id="inlineRadio6"
                          value="1"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio0">
                          ใช้งาน
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          checked={status === "0" ? true : false}
                          style={{ width: "20px", height: "20px" }}
                          class="form-check-input"
                          type="radio"
                          name="inlineRadio7"
                          id="inlineRadio7"
                          value="0"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          ปิดใช้งาน
                        </label>
                      </div>
                    </div>
                    {errorStatus === 0 ? (
                      <font color="red" size="2">
                        {" "}
                        กรุณาเลือกสถานะ{" "}
                      </font>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <br />
                <br />
                <center>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    {loading === 1 ? (
                      <center>
                        <div
                          style={{ width: "20px", height: "20px" }}
                          class="spinner-border"
                          role="status"
                        ></div>
                      </center>
                    ) : (
                      <div>
                        <Icon.Save /> บันทึก
                      </div>
                    )}
                  </button>{" "}
                  <button className="btn btn-secondary" onClick={clear}>
                    <Icon.ArrowClockwise /> ล้างค่า
                  </button>
                </center>
              </div>
            </div>
          </div>
        </div>
      </>
      <br />
      <br />
    </div>
  );
}
